import { Pipe, PipeTransform } from '@angular/core';
import { ApiHttpService, ApiService } from '@capturum/api';
import { Observable, of } from 'rxjs';
import { FormInputHelperService } from '../services/form-input-helper.service';
import { FormBuilderBaseDataService } from '../services/form-builder-base-data.service';
import { BuilderApiService, BuilderCoreConfigService, responseData } from '@capturum/builders/core';
import { FormBuilderSource } from '../models/form-builder-config.model';
import { MapItem, StorageProvider } from '@capturum/ui/api';
import { FormRendererService } from '../services/form-renderer.service';
import { tap } from 'rxjs/operators';

@Pipe({
  name: 'options',
})
export class OptionsPipe extends ApiService<any> implements PipeTransform {
  constructor(
    apiHttpService: ApiHttpService,
    private readonly baseDataService: FormBuilderBaseDataService,
    private readonly formInputHelperService: FormInputHelperService,
    private builderApiService: BuilderApiService,
    private coreConfigService: BuilderCoreConfigService
  ) {
    super(apiHttpService);
  }

  public transform(source: FormBuilderSource, key?: string): Observable<MapItem[]> {
    let storageClass: StorageProvider;

    if (source?.action?.endpoint) {
      if (source.action.options.cache?.enabled) {
        storageClass = this.coreConfigService.getStorageClassByKey(source.action.options.cache.driver);

        if (storageClass) {
          const data = storageClass.getItem(source?.action?.endpoint);

          if (data) {
            return of(data);
          }
        }
      }

      return this.builderApiService.get(key, `${this.getUrl(source.action.endpoint)}`).pipe(
        responseData,
        tap((data: any) => {
          if (storageClass && source?.action?.options.cache?.enabled) {
            storageClass.setItem(source?.action?.endpoint, data, source?.action?.options.cache?.expiration);
          }
        })
      );
    } else if (source.items) {
      return of(source.items);
    }

    return of([]);
  }

  private getUrl(endpoint: string): string {
    return endpoint
      .split('/')
      .map((path: string) =>
        this.isPathVariable(path) ? this.formInputHelperService.getValueByKeyOrDefault(path.slice(1, -1)) : path
      )
      .join('/');
  }

  private isPathVariable(value: string): boolean {
    return value.startsWith('{') && value.endsWith('}');
  }
}
