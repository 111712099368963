import { ListOptions } from '@capturum/api';
import { FilterMatchMode } from '@capturum/ui/api';
import { DateHelper, InputType, ListRendererFilterConfigField } from '@capturum/builders/core';
import { SEARCH_BAR } from '../configs/filters.config';
import { Params } from '@angular/router';

// @dynamic
export class ListRendererUtils {
  public static setApiOptions(apiOptions: ListOptions, options: Record<string, any>, filterConfigs: ListRendererFilterConfigField[]): ListOptions {
    // Removing global_search from filters
    const filters = { ...options };

    delete filters[SEARCH_BAR];


    return {
      ...apiOptions,
      filters: Object.keys(filters)
        // Adjust form value for API filters
        .map(key => {
          const config = filterConfigs.find(filterConfig => filterConfig.name === key);
          let value = ListRendererUtils.prepareFiltersValue(filters[key], config);
          let operator = config?.operator || FilterMatchMode.LIKE;

          return {
            field: key,
            value: value,
            operator: operator,
          };
        })
        // Exclude empty filters
        .filter(filter => {
          if (Array.isArray(filter.value)) {
            return !!filter.value && filter?.value?.length;
          }

          return filter.value !== null && typeof filter.value !== 'undefined';
        })
        // Add percentage for LIKE operator
        .map(filter => {
          if (filter.operator === FilterMatchMode.LIKE) {
            filter.value = `%${filter.value}%`;
          }

          return filter;
        }),
      search: options?.[SEARCH_BAR] ? [options[SEARCH_BAR]] : null,
    };
  }

  public static prepareFiltersValue(value: unknown, filter: ListRendererFilterConfigField): any {
    if (!value || !filter) {
      return value;
    }

    switch (filter.type) {
      case InputType.date:
        if (filter.options?.timezone) {
          if (filter.operator === FilterMatchMode.BETWEEN || filter.operator === FilterMatchMode.DATEBETWEEN) {
            return DateHelper.getRange(value as string[]);
          }

          return DateHelper.getStartOfDay(value as string);
        }

        return value;

      default:
        return value;
    }
  }

  public static generateQueryUrlFilters(filters: Record<string, any>): Params {
    let queryParams: Params = {};

    Object.keys(filters).forEach((filterKey) => {
      let value = filters[filterKey];

      if (Array.isArray(value)) {
        value = value.join(',');
      }

      queryParams = { ...queryParams, [`[filters][${filterKey}]`]: value };
    });

    return queryParams;
  }
}
