import { PermissionIndexedDbModel } from './domain/permission';
import { SyncConfig } from './shared/services/sync.service';
import { ToastProvider, ToastService } from '@capturum/ui/api';
import { Type } from '@angular/core';
import { ApiService } from '@capturum/api';

export interface CompleteConfigInterface {
  databaseName: string;
  indexedDbModels?: any[];
  version: number;
  userSpecificDb?: boolean;
  syncConfig?: SyncConfig;
  loaderImage?: string;
  loaderText?: string;
  useTranslatedLoaderText?: boolean;
  tableStateKey?: string;
  configPage?: {
    route: string,
    except?: string[]
  };
  environment?: any;
  toastService?: Type<ToastProvider>;

  permissionService?: Type<ApiService<any>>;
  baseDataService?: Type<ApiService<any>>;
  baseDataValueService?: Type<ApiService<any>>;
  baseDataKeyService?: Type<ApiService<any>>;
  translateKeyService?: Type<ApiService<any>>;
  moduleService?: Type<ApiService<any>>;
  settingService?: Type<ApiService<any>>;
}

export class CompleteConfig implements CompleteConfigInterface {
  public databaseName = 'capturum-complete-db';
  public indexedDbModels?: any[] = [];
  public version = 1;
  public userSpecificDb = true;
  public syncConfig?: SyncConfig = {
    syncUrl: 'sync',
    syncModels: [{ modelClass: PermissionIndexedDbModel, include: [] }],
  };
  public loaderImage = '';
  public loaderText = 'Loading...';
  public useTranslatedLoaderText = false;
  public tableStateKey = 'capturum-complete';
  public configPage = {
    route: '/admin/config',
    except: [],
  };
  public environment: any;
  public toastService = ToastService;
}
