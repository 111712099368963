import { Component, Input } from '@angular/core';
import { SprintStates, AccordionTab } from '@capturum/ui/api';

@Component({
  selector: 'cap-accordion',
  templateUrl: './accordion.component.html'
})
export class CapturumAccordionComponent {
  @Input()
  public accordionTabs: AccordionTab[];
  @Input()
  public activeTab: number = 0;

  public determineColor(headerStatus: SprintStates): string {
    switch (headerStatus) {
      case SprintStates.Active:
        return 'warning-color';
      case SprintStates.Finished:
        return 'confirm-color';
      case SprintStates.Planned:
        return 'error-color';
      default:
        return '';
    }
  }

  public onTabOpen(event: any): void {
    this.activeTab = event.index;
  }
}
