import { OutdatedEntityValue } from '../../models/outdated-entity-value.model';
import { OutdatedEntityKey } from '../../enums/outdated-entity-key.enum';
import { LocalStorageKey } from '../../enums/local-storage-key.enum';

export class IndexableServiceHelper {
  public static shouldUpdate(updatedKey: OutdatedEntityKey): boolean {
    const storeValue = IndexableServiceHelper.getStoredValue(LocalStorageKey.UpdatedKeyData);

    return storeValue.hasOwnProperty(updatedKey) ? storeValue[updatedKey] : true;
  }

  public static getStoredValue(storageKey: string): OutdatedEntityValue {
    const storedValue = localStorage.getItem(storageKey);

    return !!storedValue ? JSON.parse(storedValue) : {};
  }

  public static updateStoredValue(storageKey: string, updateKey: OutdatedEntityKey, newValue: boolean): void {
    const storedValue = IndexableServiceHelper.getStoredValue(storageKey);
    const updatedValue: OutdatedEntityValue =  {
      ...storedValue,
      [updateKey]: newValue,
    };

    localStorage.setItem(storageKey, JSON.stringify(updatedValue));
  }
}
