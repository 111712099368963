<ng-container *ngIf="handset$ | async as handset">
  <h5
    (click)="hideFilters.value = !hideFilters.value"
    class="filters-label pointer"
    *ngIf="handset.matches && stackedFilters">
    <i class="filter-toggle mr-1 fas {{ hideFilters?.value ? 'fa-eye' : 'fa-eye-slash' }}"></i>

    {{ 'builders.ui.list.filters' | translate }}
  </h5>

  <div class="table-filters cap-table-filter" #hideFilters [class.stacked]="stackedFilters">
    <div class="filters" *ngIf="dynamicFilterConfig" [class.global-search-enabled]="config?.[SEARCH_BAR]?.enabled">
      <cap-dynamic-filters
        #dynamicFiltersComponent
        [filterStyleClass]="''"
        [containerStyleClass]="''"
        [storageKey]="key"
        [routeFilters]="routeFilters"
        [showResetButton]="true"
        [showRefreshButton]="dynamicFilterConfig?.texts?.refresh"
        [maxFiltersShown]="sidebarFiltering ? 5 : 99"
        [sidebarFiltering]="sidebarFiltering"
        [filterConfig]="dynamicFilterConfig"
        (onResetFilters)="resetFilters()"
        (activeFiltersChange)="onFilterChange($event)">
      </cap-dynamic-filters>
    </div>
  </div>
</ng-container>
