import { Pipe, PipeTransform } from '@angular/core';
import { CtaButtonItem } from '@capturum/ui/cta-button';
import { TableAction } from '@capturum/ui/api';

@Pipe({
  name: 'tableActionToCtaButtonItem'
})
export class TableActionToCtaButtonItemPipe implements PipeTransform {
  public transform(tableActions: TableAction[]): CtaButtonItem[] {
    return tableActions.map((tableAction) => {
      return {
        label: tableAction.label as string,
        callback: () => tableAction.callback(null),
        icon: tableAction.icon,
      }
    });
  }
}
