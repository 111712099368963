import { Injectable, NgZone, OnDestroy, Optional } from '@angular/core';
import {
  BehaviorSubject,
  bufferTime,
  filter,
  from,
  fromEvent,
  interval,
  merge,
  Observable,
  of,
  Subject,
  Subscription,
  tap
} from 'rxjs';
import { distinctUntilChanged, finalize, switchMap, takeUntil, takeWhile } from 'rxjs/operators';

import { UserIdleConfig } from '../configs/user-idle.config';

@Injectable({
  providedIn: 'root',
})
export class IdleUserService implements OnDestroy {

  /** idle buffer in seconds. Default 1 second */
  public idleBufferMillisec = 1000;
  /** idle duration in seconds. Default 10 mins */
  public idleMillisec = 600 * 1000;
  /** timeout in seconds. Default 5 mins */
  public timeout = 300;
  public timer$!: Observable<number>;
  public timerStart$ = new BehaviorSubject<boolean>(null);
  public idleStateKey = 'user.idle.expiry';

  private onIdleStart: Subject<boolean> = new Subject<boolean>();
  private onIdleEnd: Subject<boolean> = new Subject<boolean>();
  private onTimeoutWarning: Subject<number> = new Subject<number>();
  private onTimeout: Subject<boolean> = new Subject<boolean>();
  private _interruptEvents$: Observable<unknown>;
  private _idleSubscription: Subscription;
  private _isIdle: boolean;
  private _isInactivityTimer: boolean;

  private lastTimestamp: number;

  constructor(
    @Optional()
    private config: UserIdleConfig,
    private ngZone: NgZone,
  ) {
    if (this.config) {
      this.setConfig(config);
    }
  }

  public ngOnDestroy(): void {
    this.stopWatching();
  }

  public watchIdle(): void {
    this._interruptEvents$ = merge(
      fromEvent(window, 'mousemove'),
      fromEvent(window, 'resize'),
      fromEvent(document, 'keydown'),
      fromEvent(document, 'click'),
      fromEvent(document, 'mousedown'),
      fromEvent(document, 'DOMMouseScroll'),
      fromEvent(document, 'mousewheel'),
      fromEvent(document, 'touchmove'),
      fromEvent(document, 'MSPointerMove'),
    );

    if (this._idleSubscription) {
      this._idleSubscription.unsubscribe();
    }

    this._idleSubscription = from(this._interruptEvents$).pipe(
      bufferTime(this.idleBufferMillisec), // Milliseconds before detecting idle user
      filter((events) => !events.length && !this._isIdle && !this._isInactivityTimer),
      tap(() => {
        this._isIdle = true;

        this.setIdleExpirationDate();
        this.onIdleStart.next(true);
      }),
      switchMap(() => {
        return this.ngZone.runOutsideAngular(() => {
          return interval(1000).pipe(
            switchMap(() => {
              return this.isIdleExpired();
            }),
            takeUntil(
              merge(
                this._interruptEvents$,
                this.timerStart$.asObservable().pipe(filter(Boolean)),
              )
            ),
            finalize(() => { // user interupts the idle
              this._isIdle = false;

              if (!this._isInactivityTimer) {
                this.setIdleExpirationDate();
              }

              this.onIdleEnd.next(true);
            })
          );
        })
      }),
    ).subscribe(expired => {
      if (expired) {
        this._isInactivityTimer = true;
        this.timerStart$.next(true);
        this.lastTimestamp = new Date().getTime();
      }
    });

    this.setupTimer();
  }

  public stopWatching(): void {
    this.stopTimer();

    if (this._idleSubscription) {
      this._idleSubscription.unsubscribe();
    }

    this.setIdleExpiration();
  }

  public stopTimer(): void {
    this._isInactivityTimer = false;
    this.timerStart$.next(false);
  }

  public resetTimer(): void {
    this.stopTimer();
    this.setupTimer();
  }

  public getIdleTimeout(): Observable<boolean> {
    return this.onTimeout.asObservable();
  }

  public getIdleTimeoutWarning(): Observable<number> {
    return this.onTimeoutWarning.asObservable();
  }

  public getIdleStart(): Observable<boolean> {
    return this.onIdleStart.asObservable();
  }

  public getIdleEnd(): Observable<boolean> {
    return this.onIdleEnd.asObservable();
  }

  private setupTimer(): void {
    this.ngZone.runOutsideAngular(() => {
      this.timer$ = interval(1000).pipe(
        tap((ellapsed) => {
          const now = new Date().getTime();

          if (this.lastTimestamp) {
            const diff = now - this.lastTimestamp;

            if ((diff / 1000 > this.timeout) || this.getForceIdleTimeout()) {
              this.onTimeout.next(true);
              this.lastTimestamp = null;

              this.stopWatching();

              return;
            }
          }

          this.lastTimestamp = now;

          if (ellapsed >= this.timeout) {
            this.onTimeout.next(true);

            this.stopWatching();

            return;
          }

          this.onTimeoutWarning.next(ellapsed);
        }),
        takeWhile(() => Boolean(this.timerStart$.value)),
      );
    });

    this._idleSubscription?.add(
      this.timerStart$.asObservable().pipe(
        distinctUntilChanged(),
        switchMap((start) => (start ? this.timer$ : of(null)))
      ).subscribe()
    );
  }

  private setConfig(config: UserIdleConfig): void {
    if (config.idleDuration) {
      this.idleMillisec = config.idleDuration * 1000;
    }

    if (config.idleBuffer) {
      this.idleBufferMillisec = config.idleBuffer * 1000;
    }

    if (config.timeout) {
      this.timeout = config.timeout;
    }
  }

  private setIdleExpirationDate(): void {
    const expirationDate = this.nowDate().getTime() + this.idleMillisec + this.idleBufferMillisec;

    this.lastIdleExpirationDate(new Date(expirationDate));
  }

  private isIdleExpired(): Observable<boolean> {
    const isExpired = this.lastIdleExpirationDate() <= this.nowDate().getTime();

    return of(isExpired);
  }

  private lastIdleExpirationDate(value?: Date): number {
    if (value) {
      this.setIdleExpiration(value);
    }

    return this.getIdleExpiration();
  }

  private nowDate(): Date {
    return new Date();
  }

  private setIdleExpiration(value?: Date): void {
    if (value) {
      localStorage.setItem(this.idleStateKey, JSON.stringify(value.getTime()));
    } else {
      localStorage.removeItem(this.idleStateKey);
    }
  }

  private getIdleExpiration(): number {
    const expiry: string = localStorage.getItem(this.idleStateKey);

    if (expiry) {
      return new Date(parseInt(expiry, 10)).getTime();
    }

    return null;
  }

  private getForceIdleTimeout(): boolean {
    const timeoutInMilliseconds = this.timeout * 1000;

    return this.nowDate().getTime() >= (this.lastIdleExpirationDate() + timeoutInMilliseconds);
  }
}
