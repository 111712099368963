import { moveItemInArray } from '@angular/cdk/drag-drop';
import { PageBuilderWidget } from '@capturum/builders/core';

const rootDropListId = 'widget-content-list';

export function moveItemInNestedArray<T extends PageBuilderWidget = any>(tree: T[], widgetId: string, containerId: string, fromIndex: number, toIndex: number): void {
  const itemToMove = findItem<T>(tree, widgetId);
  const parentItem = findItem<T>(tree, itemToMove.container_id);
  const destinationItem = findItem<T>(tree, containerId);

  if (parentItem?.id === containerId) { // move item in the same nested array
    moveItemInArray(destinationItem.widgets, fromIndex, toIndex);
  } else if ((parentItem?.id !== containerId) && destinationItem) { // move item to nested array
    removeItemInNestedArray(tree, widgetId);

    // Update the parent of the item to move
    itemToMove.container_id = containerId;

    destinationItem.widgets.splice(toIndex, 0, itemToMove);

  } else if (!destinationItem && parentItem && (containerId === rootDropListId)) { // from nested to root array
    removeItemInNestedArray(tree, widgetId);
    delete itemToMove.container_id;

    tree.splice(toIndex, 0, itemToMove);
  } else { // move item in root array
    moveItemInArray(tree, fromIndex, toIndex);
  }
}

export function removeItemInNestedArray<T extends PageBuilderWidget = any>(tree: T[], widgetId: string): void {
  const nodeToRemove = findItem<T>(tree, widgetId);
  const parentItem = findItem<T>(tree, nodeToRemove.container_id);

  if (parentItem) { // remove item from nested array
    parentItem.widgets = parentItem.widgets.filter((node) => (node.id !== nodeToRemove.id));
  } else { // remove item from root
    const itemIndex = tree.findIndex(node => node.id === widgetId);

    tree.splice(itemIndex, 1);
  }
}

function findItem<N extends PageBuilderWidget = any>(tree: N[], id: string): N {
  let result;

  tree.some(item => {
    if (item.id === id) {
      result = item;

      return true;
    }

    if (item.widgets?.length) {
      const subResult = findItem(item.widgets, id);

      if (subResult) {
        result = subResult;
      }
    }
  });

  return result;
}
