import { FormControl, ValidationErrors } from '@angular/forms';

/**
 * Formly validator for VAT number
 *
 * @param control
 */
export function vatValidator(control: FormControl): ValidationErrors {

  /**
   * Standard vat number validation regular expression
   */
  const vatRegExp: RegExp = new RegExp('(NL)?[0-9]{9}B[0-9]{2}');

  return !control.value || vatRegExp.test(control.value) ? null : { 'vat': true };
}
