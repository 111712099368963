import { Injectable } from '@angular/core';
import { FormBuilderConfig } from '@capturum/builders/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BuilderUiConfigService {
  private config = new BehaviorSubject<FormBuilderConfig>(null);

  public getConfig(): Observable<FormBuilderConfig> {
    return this.config.asObservable();
  }

  public getConfigSnapshot(): FormBuilderConfig {
    return this.config.getValue();
  }

  public patchConfig(config: Partial<FormBuilderConfig>): void {
    this.config.next({ ...this.config.getValue(), ...config });
  }

  public setConfig(config: FormBuilderConfig): void {
    this.config.next(config);
  }

  public resetConfig(): void {
    this.config.next(null);
  }
}
