import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChildren,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, NgControl, NgModel } from '@angular/forms';
import { CapturumTemplateDirective, ValidatorService, ValueAccessorBase } from '@capturum/ui/api';
import { Dropdown } from 'primeng/dropdown';

@Component({
  selector: 'cap-dropdown',
  templateUrl: './dropdown.component.html',
  providers: [ValueAccessorBase.getProviderConfig(CapturumDropdownComponent)],
})
export class CapturumDropdownComponent
  extends ValueAccessorBase<string>
  implements AfterViewInit, AfterContentInit, OnChanges
{
  @ViewChild(NgModel, { static: true }) public model: NgModel;
  @ViewChild(Dropdown) public dropdown: Dropdown;

  /**
   * The label to be displayed with the dropdown
   */
  @Input() public label: string;
  /**
   * An array of objects to display as the available options
   */
  @Input() public options: any;
  /**
   * Sort the dropdown options alphabetically
   */
  @Input() public sortAlphabetically: boolean = true;
  /**
   * Define the property it should sort by
   */
  @Input() public sortBy: string = 'label';
  /**
   * Height of the viewport in pixels, a scrollbar is defined if height of list exceeds this value
   */
  @Input() public scrollHeight = '200px';
  /**
   * When specified, displays an input field to filter the items on keyup
   */
  @Input() public filter: boolean;
  /**
   * Name of the input element
   */
  @Input() public name = '';
  /**
   * Inline style of the element
   */
  @Input() public style: any;
  /**
   * Inline style of the overlay panel element
   */
  @Input() public panelStyle: any;
  /**
   * Style class of the element
   */
  @Input() public styleClass = '';
  /**
   * Style class of the overlay panel element
   */
  @Input() public panelStyleClass = '';
  /**
   * When present, it specifies that the component cannot be edited
   */
  @Input() public readonly: boolean;
  /**
   * When present, it specifies that an input field must be filled out before submitting the form
   */
  @Input() public required: boolean;
  /**
   * When present, custom value instead of predefined options can be entered using the editable input field
   */
  @Input() public editable: boolean;
  /**
   * Target element to attach the overlay, valid values are "body" or a local ng-template variable of another element
   */
  @Input() public appendTo: any;
  /**
   * Index of the element in tabbing order
   */
  @Input() public tabindex: number;
  /**
   * Default text to display when no option is selected
   */
  @Input() public placeholder = '';
  /**
   * Placeholder text to show when filter input is empty
   */
  @Input() public filterPlaceholder = '';
  /**
   * Identifier of the accessible input element
   */
  @Input() public inputId = '';
  /**
   * Identifier of the native element
   */
  @Input() public selectId = '';
  /**
   * A property to uniquely identify a value in options
   */
  @Input() public dataKey = '';
  /**
   * When filtering is enabled, filterBy decides which field or fields (comma separated) to search against
   */
  @Input() public filterBy = 'label';
  /**
   * When present, it specifies that the component should automatically get focus on load
   */
  @Input() public autofocus: boolean;
  /**
   * Clears the filter value when hiding the dropdown
   */
  @Input() public resetFilterOnHide = false;
  /**
   * Icon class of the dropdown icon
   */
  @Input() public dropdownIcon = 'pi pi-chevron-down';
  /**
   * Name of the label field of an option when an arbitrary objects instead of SelectItems are used as options
   */
  @Input() public optionLabel = 'label';
  /**
   * Whether to display the first item as the label if no placeholder is defined and value is null
   */
  @Input() public autoDisplayFirst = true;
  /**
   * Whether to display options as grouped when nested options are provided
   */
  @Input() public group: boolean;
  /**
   * When enabled, a clear icon is displayed to clear the value
   */
  @Input() public showClear: boolean;
  /**
   * Text to display when there is no data.
   */
  @Input() public emptyMessage: string;
  /**
   * Text to display when filtering does not return any results
   */
  @Input() public emptyFilterMessage: string;
  /**
   * Whether to use virtual scroll
   */
  @Input() public virtualScroll = false;
  /**
   * Height of an item in the list
   */
  @Input() public virtualScrollItemSize = 30;
  /**
   * Whether to automatically manage layering
   */
  @Input() public autoZIndex = true;
  /**
   * Base zIndex value to use in layering
   */
  @Input() public baseZIndex = 0;
  /**
   * Transition options of the show animations
   */
  @Input() public showTransitionOptions = '225ms ease-out';
  /**
   * Transition options of the hide animation
   */
  @Input() public hideTransitionOptions = '195ms ease-in';
  /**
   * Defines a string that labels the filter input
   */
  @Input() public ariaFilterLabel = '';
  /**
   * Show images with the label text at the select items
   */
  @Input() public withImages: boolean;
  /**
   * When present, it specifies that the component should be disabled
   */
  @Input() public disabled = false;
  /**
   * Define a custom property to be used as the label
   */
  @Input() public customLabelProperty: string;
  /**
   * Open the dropdown on focus
   */
  @Input() public openOnFocus: boolean = false;
  /**
   * Specifies if the tooltip is present
   */
  @Input() public hasTooltip: boolean;
  /**
   * Name of the value field of an option.
   */
  @Input() public optionValue: string = 'value';
  /**
   * Applies focus to the filter element when the overlay is shown.
   */
  @Input() public autofocusFilter = true;
  /**
   * Float label
   */
  @Input() public floatLabel = false;

  /**
   * Display Icon
   */
  @Input() public withIcons = false;

  /**
   * Callback to invoke when value of dropdown changes
   *
   * @deprecated use changeSelection instead
   */
  @Output() public change: EventEmitter<any> = new EventEmitter();

  /**
   * Callback to invoke when value of dropdown changes
   */
  @Output() public changeSelection: EventEmitter<any> = new EventEmitter();
  /**
   * Callback to invoke when dropdown gets focus
   *
   * @deprecated use dropdownFocused instead
   */
  @Output() public focus: EventEmitter<any> = new EventEmitter();
  /**
   * Callback to invoke when dropdown gets focus
   */

  @Output() public dropdownFocused: EventEmitter<any> = new EventEmitter();
  /**
   * Callback to invoke when dropdown loses focus.
   */
  @Output() public blur: EventEmitter<any> = new EventEmitter();
  /**
   * Callback to invoke when component is clicked
   *
   * @deprecated use dropdownFocused instead
   */
  @Output() public click: EventEmitter<any> = new EventEmitter();
  /**
   * Callback to invoke when component is clicked
   */
  @Output() public dropdownClicked: EventEmitter<any> = new EventEmitter();
  /**
   * Callback to invoke when dropdown overlay gets visible.
   */
  @Output() public show: EventEmitter<any> = new EventEmitter();
  /**
   * Callback to invoke when dropdown overlay gets hidden
   */
  @Output() public hide: EventEmitter<any> = new EventEmitter();

  /**
   * The templates given from the CapturumTemplateDirective
   */
  @ContentChildren(CapturumTemplateDirective)
  public templates: QueryList<any>;

  public control: FormControl;
  public itemTemplate: TemplateRef<string>;
  public selectedItcapTemplate: TemplateRef<string>;
  public clicked: boolean = false;

  constructor(private injector: Injector, private validatorService: ValidatorService) {
    super();
  }

  /**
   * Listen for changes to the sort variables, needed to make sure the sorting is done correctly..
   */
  public ngOnChanges({ sortBy }: SimpleChanges): void {
    if (this.options && this.sortAlphabetically !== false) {
      this.options = this.sort(this.options);
    }
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      const ngControl: NgControl = this.injector.get(NgControl, null);
      this.control = this.validatorService.extractFormControl(ngControl);
    });
  }

  /**
   * switch templates based on the content children
   */
  public ngAfterContentInit(): void {
    this.templates.forEach((template) => {
      switch (template.getType()) {
        case 'item':
          this.itemTemplate = template.template;
          break;
        case 'selectedItem':
          this.selectedItcapTemplate = template.template;
          break;
      }
    });
  }

  public sort(value: any[]): any[] {
    return value.sort((a, b) => {
      // If sortAlphabetically is selected, do a LowerCase() and otherwise leave it for numeric
      const valueA =
        typeof a[this.sortBy] === 'string' && this.sortAlphabetically ? a[this.sortBy].toLowerCase() : a[this.sortBy];
      const valueB =
        typeof b[this.sortBy] === 'string' && this.sortAlphabetically ? b[this.sortBy].toLowerCase() : b[this.sortBy];

      return valueA > valueB ? 1 : -1;
    });
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public onChangeEvent($event: any): void {
    this.touch();
    this.change.emit($event);
    this.changeSelection.emit($event);
  }

  public onBlur($event: any): void {
    this.clicked = false;

    this.touch();
    this.blur.emit();
  }

  public onFocus(event: any): void {
    if (this.openOnFocus && !this.clicked) {
      this.dropdown.overlayVisible = true;
    }

    this.dropdownFocused.emit(event);
    this.focus.emit(event);
  }

  public onClick($event: any): void {
    this.clicked = true;

    this.dropdownClicked.emit($event);
    this.click.emit($event);
  }
}
