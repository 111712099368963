export * from './layout.module';
export * from './details/details.component';
export * from './details/details.module';
export * from './headline/headline.module';
export * from './headline/headline.component';
export * from './header/header.module';
export * from './header/header.component';
export * from './header-tabs/header-tabs.component';
export * from './header-tabs/interfaces/header-tabs-config.interface';
export * from './header-tabs/services/header-tabs-config.service';
export * from './header-tabs/components/user-menu/user-menu.component';
