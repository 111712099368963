<cap-dynamic-filter-layout
  class="cap-dynamic-input-filter"
  [iconClass]="filterConfig.icon"
  [label]="showLabel ? filterConfig.label : null"
  [showReset]="formControl.value && (filterConfig.resetFilter ?? true)"
  (resetFilter)="reset()">
  <cap-range-filter
    [attr.data-test]="'filter-' + filterConfig.field"
    [mask]="filterConfig?.mask"
    [toLabel]="filterConfig.toLabel || 'To'"
    [formControl]="formControl"
    (change)="handleRangeChange($event)"
    [updateOnClose]="false"
    [isNumbers]="false"
    [placeholder]="filterConfig.placeholder"
    [isRangeBetweenDates]="false"
    [confirmButtonText]="filterConfig.confirmButtonText || 'OK'"
    [emptyMessage]="filterConfig.emptyMessage || 'Empty is no limit'"
    [fromLabel]="filterConfig.fromLabel || 'From'">
  </cap-range-filter>
</cap-dynamic-filter-layout>
