import { Injectable } from '@angular/core';
import { ApiHttpService, ApiIndexResult, ApiService } from '@capturum/api';
import { combineLatest, from, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { FormRendererConfigService } from '../form-renderer.config';
import { FormBuilderModel } from '../models/form-builder.model';
import { FormBuilderConfig } from '../models/form-builder-config.model';
import { BuilderApiService, CapturumBuildersContextService, responseData } from '@capturum/builders/core';
import { Store } from '@ngxs/store';
import { SetFormConfiguration } from '../state/form-builder/form-builder.actions';
import { SessionStorageService } from '@capturum/ui/api';

@Injectable({ providedIn: 'root' })
export class FormRendererApiService extends ApiService<any> {
  protected endpoint = 'builder';

  constructor(
    apiHttpService: ApiHttpService,
    private readonly formBuilderRendererConfigService: FormRendererConfigService,
    private readonly store: Store,
    private builderApiService: BuilderApiService,
    private contextService: CapturumBuildersContextService,
    private sessionStorageService: SessionStorageService,
  ) {
    super(apiHttpService);
  }

  public resolveFormBuilders(): Observable<boolean> {
    return combineLatest([
      this.getFormConfigs(),
      from(FormBuilderModel.query().clear()), // @TODO: Remove this?
    ]).pipe(
      switchMap(([response]) => {
        return from(FormBuilderModel.query().bulkPut(response.data));
      }),
      switchMap((response: any[]) => of(true)),
    );
  }

  public getFormBuilderByKey(key: string, contextKey?: string): Observable<FormBuilderConfig> {
    const currentContext = this.contextService.getContextByKey(contextKey);
    const formConfig = this.sessionStorageService.getItem(`form_config_${key}`);

    if (
      formConfig &&
      formConfig.config &&
      ((!formConfig.context && !currentContext) || this.areObjectsEqual(formConfig?.context, currentContext))
    ) {
      return of(formConfig.config);
    }

    return this.builderApiService.get<{ data: FormBuilderConfig }>(key, `/${this.endpoint}/config/${key}`)
      .pipe(
        responseData,
        tap((configuration) => {
          if (configuration.options?.cache) {
            this.sessionStorageService.setItem(`form_config_${key}`, { config: configuration, context: currentContext });
          }

          this.store.dispatch(new SetFormConfiguration(contextKey || key, configuration, this.contextService.getContextByKey(contextKey || key)));
        }),
      );
  }

  public getFormConfigs(): Observable<ApiIndexResult<FormBuilderConfig>> {
    return this.apiHttp.get<ApiIndexResult<any>>(this.formBuilderRendererConfigService.formBuilderApi);
  }

  public submitFormValue<T = any>(endpoint: string, formValue: T): Observable<T> {
    return this.apiHttp.post(endpoint, formValue);
  }

  public getSource<T = any>(configuration: FormBuilderConfig, id: string, key: string): Observable<T> {
    const endpoint = configuration.source.endpoint.replace('{{id}}', id);

    return this.builderApiService.get<any>(key, `${endpoint}`).pipe(map((response) => {
      if (response.hasOwnProperty('data')) {
        return response.data;
      }

      return response;
    }));
  }

  private areObjectsEqual(object1: object, object2: object): boolean {
    return (
      object1 &&
      object2 &&
      Object.keys(object1).length === Object.keys(object2).length &&
      Object.keys(object1).every((key) => object1[key] === object2[key])
    );
  }
}
