import {
  AfterViewInit,
  ChangeDetectorRef,
  Component, ContentChildren,
  EventEmitter,
  Input,
  OnDestroy,
  Output, QueryList, TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Sidebar } from 'primeng/sidebar';
import { Subscription } from 'rxjs';
import { CapturumTemplateDirective } from '@capturum/ui/api';

@Component({
  selector: 'cap-sidebar',
  templateUrl: './sidebar.component.html',
  encapsulation: ViewEncapsulation.None
})
export class CapturumSidebarComponent implements AfterViewInit, OnDestroy {
  @Input()
  public set visible(value: boolean) {
    if (value !== this._visible) {
      this._visible = value

      if (!value && this.sidebarComponent) {
        // trigger primeng sidebar close event
        this.sidebarComponent.close(new Event('click'));
      }
    }
  };

  public get visible(): boolean {
    return this._visible;
  }

  @Input()
  public title: string;
  @Input()
  public styleClass: string;
  @Input()
  public position: 'right' | 'left' = 'right';
  @Input()
  public style: string;

  @Output()
  public visibleChange = new EventEmitter<boolean>();

  @Output()
  public sidebarClose = new EventEmitter<boolean>();

  @ViewChild('sidebar')
  public sidebarComponent: Sidebar;

  @ContentChildren(CapturumTemplateDirective)
  public templates: QueryList<CapturumTemplateDirective>;

  public subscription: Subscription;
  public footerTemplate: TemplateRef<any>;
  private _visible: boolean;

  constructor(private cdr: ChangeDetectorRef) {
  }

  public ngAfterViewInit(): void {
    this.subscription = this.sidebarComponent.visibleChange?.subscribe((visible) => {
      this.visibleChange.emit(visible);
    });

    const footerTemplate = this.templates.find((template) => template.getType() === 'footer');
    if (footerTemplate) {
      this.footerTemplate = footerTemplate.template;
    }
    this.cdr.detectChanges();
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public closeSidebar(event?: PointerEvent): void {
    if (event) {
      this.sidebarComponent?.close(event);
      this.sidebarClose.emit(true);
    }

    this.visible = false;
  }
}
