<cap-editor
  [hidden]="to.readonly"
  [config]="config"
  [attr.data-test]="'builder-' + field.key"
  [formControl]="formControl">
</cap-editor>

<cpb-readonly-container [hidden]="!to.readonly" [attr.data-test]="'builder-readonly-' + field.key">
  <p [innerHtml]="formControl.value | default: to?.defaultEmptyValue"></p>
</cpb-readonly-container>
