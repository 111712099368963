import { Component, ViewEncapsulation } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { LabelPosition } from '@capturum/builders/core';

@Component({
  selector: 'cpb-dropdown-input',
  templateUrl: './dropdown-input.component.html',
  styleUrls: ['./dropdown-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CapturumBuilderDropdownInputComponent extends FieldType {
  public labelPosition: typeof LabelPosition = LabelPosition;
}
