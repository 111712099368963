import { RoleType } from './role-type.interface';
import Role from './role.interface';

export interface UserModel {
  id: string;
  name: string;
  email: string;
  function: string;
  phone_number: string;
  mobile_number: string;
  modules: string[];
  is_admin: boolean;
  last_login_at: string;
  two_factor_auth: string;
  status: boolean | string;
  read_only: boolean;
  roles?: Role[];
  rolesForCurrentSchoolYear?: Role[];
  currentRoleType?: RoleType;
}
