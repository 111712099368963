import { Pipe, PipeTransform } from '@angular/core';
import { ItemExpression } from '../models';

@Pipe({
  name: 'resolveStyleClassExpressionPipe'
})
export class ResolveStyleClassExpressionPipe implements PipeTransform {
  public transform(styleClassExpression: ItemExpression, item: unknown): string | null {
    if (styleClassExpression) {
      return styleClassExpression(item);
    }

    return null;
  }
}
