import { AbstractControl } from '@angular/forms';
import { ListOptions } from '@capturum/api';
import { FilterMatchMode } from '@capturum/ui/api';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UserService } from './user.service';

// @dynamic
export class UserValidator {
  /*
  * Check if user's email is already taken
  * */
  public static isEmailTaken(userService: UserService, id: string): (ctrl: AbstractControl) => Promise<boolean> {
    return (ctrl: AbstractControl) => {
      const options: ListOptions = {
        filters: [
          { field: 'email', value: ctrl.value, operator: 'equals' }
        ]
      };

      if (id) {
        options.filters.push({ field: 'id', value: id, operator: FilterMatchMode.NOT_EQUALS });
      }

      return userService.index(options)
        .pipe(
          map(res => res.data),
          map(users => users.length === 0 || (users.length === 1 && users.some((user) => user.id === id))),
          catchError(err => of(true))
        ).toPromise();
    };
  }
}
