import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'cpb-hidden-input',
  template: '',
})
export class CapturumBuilderHiddenTypeComponent extends FieldType {

}
