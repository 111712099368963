import { Injectable } from '@angular/core';
import { ApiService, ListOptions } from '@capturum/api';
import { BaseDataKeyIndexedDbModel } from '../models/base-data-key.indexedDb-model';
import { Observable, of } from 'rxjs';
import { take, map, mergeMap } from 'rxjs/operators';
import { BaseTranslation } from '../models/base-translation.model';
import { MapItem } from '@capturum/ui/api';
import { BaseDataValueApi } from '../models/base-data-value.api-model';
import User from '@capturum/auth/lib/user.interface';

@Injectable()
export class BaseDataKeyService extends ApiService<BaseDataKeyIndexedDbModel> {
  public baseDataKeys: BaseDataKeyIndexedDbModel[] = [];
  protected endpoint = 'base-data-key';
  protected includes = ['properties', 'values.propertyValues', 'values', 'values.translations'];
  protected user: User;

  public getBaseDataKeyValues(key: string): Observable<MapItem[]> {
    return this.getBaseDataKeyByKey(key).pipe(mergeMap(base => this.getBaseDataKeyValuesByLocale(base.id, this.user.locale_id)));
  }

  public getBaseDataKeyByKey(key: string): Observable<BaseDataKeyIndexedDbModel> {
    if (!!this.baseDataKeys.length) {
      return of(this.baseDataKeys.find((item) => item.key === key));
    } else {
      return this.index().pipe(
        map((response) => response.data.find((item) => item.key === key)),
        take(1),
      );
    }
  }

  public getBaseDataKeyValuesByLocale(
    id: string | number,
    localeId?: string | number,
    options?: ListOptions,
    parents: boolean = false,
    parentValue?: string,
  ): Observable<MapItem[]> {
    let url = id;

    if (options) {
      url += this.getOptionsQuery(options);
    } else {
      const defaultOptions: ListOptions = { include: this.includes };

      url += this.getOptionsQuery(defaultOptions);
    }

    return this.get<MapItem[]>(url).pipe(map((response: any) => {
      return response.values && localeId ? this.getMapItems(localeId, response.values, parents, parentValue) : [];
    }));
  }

  public extractBaseDataKeys(user?: User): void {
    const options: ListOptions = { perPage: 99999 };

    this.index(options).subscribe((response) => {
      this.baseDataKeys = response.data;
    });

    if (user) {
      this.user = user;
    }
  }

  public getTranslation(localeId: string | number, translations: BaseTranslation[]): BaseTranslation {
    return translations?.find((item) => item.locale_id === localeId);
  }

  public getBaseMapItem(baseItem: BaseDataValueApi, localeId: string | number): MapItem {
    const translate = this.getTranslation(localeId, baseItem?.translations)?.translation;

    return {
      label: this.getTranslation(localeId, baseItem?.translations) ? translate : baseItem?.value,
      value: baseItem.id,
      key: baseItem.value,
    };
  }

  public getMapItems(localeId: string | number, values: BaseDataValueApi[], parents: boolean, parentValue?: string): MapItem[] {
    let baseList = values;

    if (parents) {
      baseList = values.filter((item) => item.parent_id === null);
    } else if (parentValue) {
      baseList = values.filter(item => item.parent_id === parentValue);
    }

    return baseList.map((item) => this.getBaseMapItem(item, localeId));
  }
}
