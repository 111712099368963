import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CapturumListRendererModule } from '@capturum/builders/list-renderer';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'cap-flow-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CapturumListRendererModule],
})
export class CapturumFlowHistoryComponent implements OnInit {
  public builderKey: string;
  public context: Record<string, string>;

  constructor(private config: DynamicDialogConfig) {
    const contextProperty = config?.data?.configurationKey === 'list_action_statistics' ? 'action' : 'flow_ref';

    this.context = {
      [contextProperty]: config?.data?.item?.[contextProperty],
    };
  }

  public ngOnInit(): void {
    this.builderKey =
      this.config?.data?.configurationKey === 'list_action_statistics' ? 'list_action_history' : 'list_flow_history';
  }
}
