import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'em-formly-dropdown',
  templateUrl: './dropdown.component.html'
})
export class DropdownTypeComponent extends FieldType {
  public defaultOptions = {
    props: {
      sortAlphabetically: true,
      sortBy: 'label',
      filterBy: 'label',
      filter: true,
      virtualScroll: true,
      showClear: false,
      virtualScrollItemSize: 30,
      emptyFilterMessage: 'No results found',
      scrollHeight: '200px',
      name: '',
      inputId: '',
      selectId: '',
      dataKey: '',
      style: null,
      panelStyle: null,
      styleClass: '',
      panelStyleClass: '',
      editable: false,
      autofocus: false,
      resetFilterOnHide: false,
      dropdownIcon: 'pi pi-chevron-down',
      optionLabel: 'label',
      autoDisplayFirst: true,
      group: false,
      ariaFilterLabel: '',
      withImages: false,
      openOnFocus: false,
      hasTooltip: false,
      optionValue: 'value',
      autofocusFilter: true,
    }
  };
}
