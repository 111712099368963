import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CapturumInputMaskComponent } from './input-mask.component';
import { InputMaskModule } from 'primeng/inputmask';
import { CapturumSharedModule } from '@capturum/ui/api';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    CapturumInputMaskComponent
  ],
  exports: [
    CapturumInputMaskComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    InputMaskModule,
    CapturumSharedModule,
    OverlayPanelModule
  ]
})
export class CapturumInputMaskModule {
}
