<cap-textarea [formlyAttributes]="field"
              [formControl]="formControl"
              [cols]="to.cols || 30"
              [disabled]="to.disabled || false"
              [placeholder]="(to.translatePlaceholder || to.placeholder) | observablePipe | async"
              [readonly]="to.readOnly || ''"
              [rows]="to.rows || 10"
              [floatLabel]="(to.label_position | observablePipe | async ) === 'floating'"
              [label]="(to.label_position | observablePipe | async ) === 'floating' ? to.label : ''">
</cap-textarea>
