import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ApiHttpService, ApiService } from '@capturum/api';
import { AuthService } from '@capturum/auth';
import { Role } from '../models/role.model';
import { ModuleService } from '../../module/services/module.service';
import { PermissionService } from '../../permission/services/permission.service';
import { SettingService } from '../../setting/services/setting.service';

@Injectable()
export class RoleService extends ApiService<Role> {
  protected endpoint = 'role';

  constructor(public httpService: ApiHttpService,
              private authService: AuthService,
              private settingService: SettingService,
              private moduleService: ModuleService,
              private permissionService: PermissionService) {
    super(httpService);
  }

  /**
   * Set the current role of the user
   *
   * @param role - The role to switch to
   */
  public setUserRole(role: Role): Observable<any> {
    return this.apiHttp.post('/user/role', { role_id: role.id })
    .pipe(tap(() => {
        const user = this.authService.getUser();
        user.current_role = role;
        localStorage.setItem('user', JSON.stringify(user));

        // Refresh the settings/modules/translations
        this.settingService.loadAll();
        this.permissionService.loadPermissions().subscribe();
        this.moduleService.loadModules();
      })
    );
  }
}
