import { Component, Injector, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { take, map, Observable, shareReplay } from 'rxjs';
import { UntypedFormBuilder } from '@angular/forms';
import { AuthService } from '@capturum/auth';
import { BaseDetailComponent } from '@capturum/shared';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { LocaleApiModel, LocaleService } from '../../../locale';
import { TranslationKeyApiModel } from '../../models/translation-key.api-model';
import { TranslationApiModel } from '../../models/translation.api-model';
import { TranslationKeyService } from '../../services/translation-key.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'emc-edit-translation-dialog',
  templateUrl: './edit-translation-dialog.component.html',
  styleUrls: ['./edit-translation-dialog.component.scss'],
})
export class EditTranslationDialogComponent extends BaseDetailComponent<TranslationKeyApiModel> implements OnInit {
  public loading: boolean;
  public locales$: Observable<LocaleApiModel[]>;

  constructor(
    public injector: Injector,
    public translateService: TranslateService,
    public localeService: LocaleService,
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private config: DynamicDialogConfig,
    public apiService: TranslationKeyService,
    public route: ActivatedRoute,
    private dialogRef: DynamicDialogRef
  ) {
    super(injector, translateService);

    this.locales$ = this.localeService.index().pipe(
      map((response) => {
        return response.data;
      }),
      shareReplay(1)
    );

    this.saveMessage = this.translateService.instant('translation.detail-saved.message');
    this.modelTranslation = this.translateService.instant('translation.detail.title');
    this.model = {} as TranslationKeyApiModel;
    this.includes = ['translations'];
    this.loading = true;

    this.fields = [
      {
        key: 'key',
        type: 'input',
        props: {
          translateLabel: this.translateService.stream('translation.overview.label'),
          disabled: true,
        },
      },
    ];

    this.getResourceCallback = (resourceResponse): TranslationKeyApiModel => {
      this.model = resourceResponse;

      // Add inputs for each locale
      this.locales$.pipe(take(1)).subscribe((locales) => {
        locales.forEach((locale: LocaleApiModel) => {
          let index = this.model.translations.findIndex((translation) => {
            return translation.locale_id === locale.id;
          });

          // Add new translation if not exists yet
          if (index === -1) {
            const newTranslation: TranslationApiModel = {
              id: null,
              locale_id: locale.id,
              translation_key_id: this.model.id,
              translation: '',
            };

            this.model.translations.push(newTranslation);
          }
        });

        this.loading = false;
      });

      return this.model;
    };
  }

  public ngOnInit(): void {
    this.formGroup = new FormGroup({});

    this.isEdit = true;
    this.model[this.idField] = this.config?.data?.id;
    this.getResource(this.config?.data?.id);

    this.formGroup = this.formBuilder.group({
      translation: [],
    });
  }

  public onCancel(): void {
    this.submitting = false;
    const currentLangTranslation = this.model?.translations?.find((translation) => {
      return translation?.locale_id === this.authService.getUser()?.locale_id;
    })?.translation;

    this.dialogRef.close(currentLangTranslation);
  }
}
