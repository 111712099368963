<form [formGroup]="formGroup" *ngIf="formGroup">
  <div class="multi-factor-form">
    <section class="multi-factor-form--section">
      <h5 class="multi-factor-form--section-title">
        {{ paragraphNumbers?.code }}. {{ 'auth.2fa.code.title' | translate }}
      </h5>

      <p class="multi-factor-form--section-description">
        {{ 'auth.2fa.' + controlMethod.value + '.code.description' | translate }}
      </p>

      <cap-input-code formControlName="code"
                      (valuesFilled)="appInputCodeFilled()">
      </cap-input-code>
    </section>
  </div>
</form>
