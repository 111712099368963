import { Injectable } from '@angular/core';
import { ConfigItem } from '../models/config-item.model';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class CompleteConfigService {
  public defaultConfigItems: ConfigItem[] = [
    {
      titleTranslationKey: 'config.manage.users',
      iconClass: 'fas fa-user',
      routerLink: '/admin/users',
      permission: 'user.manage.tenant'
    }, {
      titleTranslationKey: 'config.manage.companies',
      iconClass: 'fas fa-building',
      routerLink: '/admin/tenants',
      permission: 'tenant.manage'
    }, {
      titleTranslationKey: 'config.manage.translations',
      iconClass: 'fas fa-language',
      routerLink: '/admin/translations',
      permission: 'translation.manage.tenant'
    }, {
      titleTranslationKey: 'config.manage.roles',
      iconClass: 'fas fa-users',
      routerLink: '/admin/roles',
      permission: 'role.manage.tenant'
    }
  ];

  public defaultAllowedPermissions: string[] = [
    'user.manage.tenant',
    'tenant.manage',
    'module.manage.tenant',
    'translation.manage.tenant',
    'role.manage.tenant'
  ];

  private _configOverviewItems: BehaviorSubject<ConfigItem[]> = new BehaviorSubject(this.defaultConfigItems);
  private _allowedPermissions: BehaviorSubject<string[]> = new BehaviorSubject(this.defaultAllowedPermissions);

  public getConfigOverviewItems(): Observable<ConfigItem[]> {
    return this._configOverviewItems.asObservable();
  }

  public setAdditionalConfigOverviewItems(configItems?: ConfigItem[]): void {
    this._configOverviewItems.next([...this.defaultConfigItems, ...configItems]);
  }

  public getAllowedPermissions(): Observable<string[]> {
    return this._allowedPermissions.asObservable();
  }

  public setAdditionalAllowedPermissions(permissions: string[]): void {
    this._allowedPermissions.next([...this.defaultAllowedPermissions, ...permissions]);
  }
}
