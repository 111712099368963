import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { LabelPosition } from '@capturum/builders/core';

@Component({
  selector: 'cpb-number-input',
  templateUrl: './number-input.component.html',
})
export class CapturumBuilderNumberInputComponent extends FieldType {
  public labelPosition: typeof LabelPosition = LabelPosition;
}
