import { takeUntil } from 'rxjs/operators';
import { DestroyBase } from '@capturum/shared';
import { Page } from 'powerbi-client';
import { PowerBIService } from '../../services/power-bi.service';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'cap-power-bi-paginator',
  templateUrl: './power-bi-paginator.component.html',
  styleUrls: ['./power-bi-paginator.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CapturumPowerBiPaginatorComponent extends DestroyBase {
  @Input()
  styleClass: string;
  @Input()
  reportKey: string;
  @Input()
  showAsList: boolean = false;

  public pages$: Observable<Page[]>;
  public pagination: {
    total: number;
    per_page: number;
    current_page: number;
  };
  private _pages: Page[];

  constructor(
    private powerBIService: PowerBIService,
  ) {
    super();

    this.pages$ = this.powerBIService.pages();

    this.pages$.pipe(takeUntil(this.destroy$))
      .subscribe(pages => {
        const current_page = pages.findIndex(page => page.isActive === true) + 1;
        this.pagination = {
          total: pages.length,
          per_page: 1,
          current_page,
        };
        this._pages = pages;
      });
  }

  public setPage(page: Page | number): void {
    if (typeof page === 'number') {
      const currentPage = this._pages[page];

      if (!currentPage.isActive) {
        this.powerBIService.setPage$.next({
          pageName: currentPage.name,
          reportKey: this.reportKey,
        });

        this.pagination.current_page = page + 1;
      }
    } else {
      if (!page.isActive) {
        this.powerBIService.setPage$.next({
          pageName: page.name,
          reportKey: this.reportKey,
        });
      }
    }
  }

  public trackByFn(index: number): number {
    return index;
  }
}
