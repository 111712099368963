import { HttpHeaders, HttpParams } from '@angular/common/http';

export interface ApiSingleResult<T> {
  data?: T;
}

export interface ApiListResult {
  data: { key: string; label: string }[];
}

export interface ApiIndexResult<T> {
  data: T[];
  meta?: Meta;
}

export interface Meta {
  pagination: {
    total: number;
    count: number;
    per_page: number;
    current_page: number;
    total_pages: number;
  };
}

// TODO Create specific options for get, index, list, create, update and delete
export interface ListOptions {
  include?: string[];
  exclude?: string[];
  sort?: { field: string; direction: 'asc' | 'desc' }[];
  perPage?: number;
  page?: number;
  filters?: { field: string; value: any; operator?: string }[];
  search?: string[] | string;
  has?: { relation: string; count?: number; operator?: '=' | '>' | '<' | '>=' | '<=' }[];
  parameters?: { field: string; value: any }[];
  count?: string[];
}

export interface RequestOptions {
  headers?: HttpHeaders,
  params?: HttpParams,
  responseType?: XMLHttpRequestResponseType;
}
