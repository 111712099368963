import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ListRendererWidgetBase } from '@capturum/builders/list-renderer';

@Component({
  selector: 'cap-number-percentage-widget',
  template: `<span class="number-percentage-widget">{{ item[field] | percent }}</span>`,
  standalone: true,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CapturumNumberPercentageWidgetComponent extends ListRendererWidgetBase {}
