import { Injectable } from '@angular/core';
import { TranslateDefaultParser } from '@ngx-translate/core';
import { EDIT_TRANSLATION_MODE_KEY } from '../constants/edit-translation-mode.constant';

@Injectable({
  providedIn: 'root',
})
export class CapturumTranslateParser extends TranslateDefaultParser {
  public getValue(target: any, key: string): string {
    if (this.isEditTranslationModeOn()) {
      const translation = super.getValue(target, key);

      return translation ? `!@![${key}]-[${translation}]!@!` : key;
    }

    return super.getValue(target, key);
  }

  public isEditTranslationModeOn(): boolean {
    const editTranslation = localStorage.getItem(EDIT_TRANSLATION_MODE_KEY);

    return !!editTranslation && JSON.parse(editTranslation) === true;
  }
}
