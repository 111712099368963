<div class="cap-list-renderer-header-actions">
  <div class="header-actions-container">
    <div class="action-panel">
      <ul class="header-action__items">
        <ng-container *ngFor="let action of actions">
          <li *ngIf="(action?.hidden | observablePipe | async) !== true"
              [attr.data-test]="action?.key || action?.label">
            <cpb-list-renderer-header-action [action]="action"></cpb-list-renderer-header-action>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
</div>
