import { Injectable } from '@angular/core';
import { ApiService, ApiHttpService } from '@capturum/api';
import { CapturumBuildersContextService } from './context.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BuilderApiService extends ApiService<any> {
  constructor(
    protected apiHttpService: ApiHttpService,
    private contextService: CapturumBuildersContextService,
  ) {
    super(apiHttpService);
  }

  public post(key: string, endpoint: string, data: any): Observable<any> {
    const params = this.contextService.getHttpParamsContext(key);

    return this.apiHttp.post(endpoint, this.recursiveParse(data), { params });
  }

  public patch(key: string, endpoint: string, data: any): Observable<any> {
    const params = this.contextService.getHttpParamsContext(key);

    return this.apiHttp.patch(endpoint, this.recursiveParse(data), { params });
  }

  public get<T = any>(key: string, endpoint: string | any): Observable<T> {
    const params = this.contextService.getHttpParamsContext(key);

    return this.apiHttp.get(endpoint, { params });
  }
}
