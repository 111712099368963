import { ReportsLayoutComponent } from './pages/components/reports-layout/reports-layout.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CapturumPowerBIReportsListComponent } from './pages/components/reports-list/reports-list.component';
import { CapturumPowerBIReportsDetailComponent } from './pages/components/reports-detail/reports-detail.component';

export const reportsRoutes: Routes = [
  {
    path: 'reports',
    component: ReportsLayoutComponent,
    children: [
      {
        path: '',
        component: CapturumPowerBIReportsListComponent,
      },
      {
        path: 'report/:id',
        component: CapturumPowerBIReportsDetailComponent,
      },
    ],
  },
];

export const routerModule = RouterModule.forChild(reportsRoutes);

@NgModule({
  imports: [
    routerModule,
  ],
  exports: [RouterModule],
})
export class CapturumPowerBIRoutingModule {
}
