import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CapturumColorPickerComponent } from './color-picker.component';
import { CapturumInputModule } from '@capturum/ui/input';
import { CapturumButtonModule } from '@capturum/ui/button';
import { ColorPickerModule } from 'primeng/colorpicker';
import { CapturumSharedModule } from '@capturum/ui/api';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    CapturumColorPickerComponent
  ],
  exports: [
    CapturumColorPickerComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ColorPickerModule,
    CapturumInputModule,
    CapturumSharedModule,
    CapturumButtonModule
  ]
})
export class CapturumColorPickerModule {
}
