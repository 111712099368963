import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';
import { Subject, takeUntil } from 'rxjs';
import { passwordValidator } from '@capturum/formly';
import { controlsEqual } from '@capturum/builders/core';
import { ValidatorService } from '@capturum/ui/api';

@Component({
  selector: 'cpb-password-type',
  templateUrl: './password-type.component.html',
  styleUrls: ['./password-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CapturumBuilderPasswordTypeComponent extends FieldType implements OnInit, OnDestroy {
  public passwordFormGroup: FormGroup;
  private destroy$ = new Subject<boolean>();

  constructor(
    private formBuilder: FormBuilder,
    private readonly validatorService: ValidatorService) {
    super();
    this.addCustomTranslation();
  }

  public ngOnInit(): void {
    this.passwordFormGroup = this.formBuilder.group(
      {
        password: [null, passwordValidator],
        confirm_password: [null, passwordValidator],
      },
      {
        validators: controlsEqual('confirm_password', 'password', 'fieldMatch'),
      },
    );

    this.passwordFormGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((changes) => {
      this.formControl.setValue(changes);
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public generatePassword(): void {
    const letters = 4;
    const numbers = 4;
    const specialCharacters = 4;
    const either = 4;
    const lowercaseLetters = 4;
    const chars = [
      'ABCDEFGHIJKLMNOPQRSTUVWXYZ', // letters
      'abcdefghijklmnopqrstuvwxyz', // lowercase letters
      '0123456789', // numbers
      '!@#$%^&*()', // special characters
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789', // either
    ];

    const password = [letters, lowercaseLetters, numbers, specialCharacters, either].map(function (length, i) {
      return Array(length).fill(chars[i]).map(function (x) {
        return x[Math.floor(Math.random() * x.length)];
      }).join('');
    }).concat().join('').split('').sort(function () {
      return 0.5 - Math.random();
    }).join('');

    this.passwordFormGroup.setValue({
      password,
      confirm_password: password,
    });
  }

  public addCustomTranslation(): void {
    this.validatorService.setValidationMessages({
      fieldMatch: 'auth.reset-password.mismatch.message',
    });
  }
}
