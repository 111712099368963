import { NgModule } from '@angular/core';
import { CapturumSidebarComponent } from './sidebar.component';
import { SidebarModule } from 'primeng/sidebar';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [CapturumSidebarComponent],
  imports: [SidebarModule, CommonModule],
  exports: [CapturumSidebarComponent],
})
export class CapturumSidebarModule {

}
