import { Injectable } from '@angular/core';
import { ApiHttpService, ApiService } from '@capturum/api';
import { ApiListResult } from '@capturum/auth';
import { FormBuilderConfig, responseData, toMapItems } from '@capturum/builders/core';
import { MapItem } from '@capturum/ui/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WidgetTypeApiService extends ApiService<any> {
  protected endpoint = 'builder/ui/render/widget-type';

  constructor(apiHttp: ApiHttpService) {
    super(apiHttp);
  }

  public listWidgetTypes(): Observable<MapItem[]> {
    return this.apiHttp.get<ApiListResult>(`/${this.endpoint}`).pipe(toMapItems);
  }

  public getWidgetTypeFormConfiguration(type: string): Observable<FormBuilderConfig> {
    return this.apiHttp.get(`/${this.endpoint}/${type}`).pipe(responseData);
  }
}
