import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TableColumn, TableAction, TableActionEvent, TableColumnTypes, FilterMatchMode, Directions } from '@capturum/ui/api';

@Component({
  // tslint:disable-next-line: component-selector
  selector: '[capTableRow]',
  templateUrl: './table-row.component.html',
  styleUrls: ['./table-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableRowComponent {
  /**
   * The data of the table row
   */
  @Input() public rowData: any;
  /**
   * The definition of the columns to be used by the table
   */
  @Input() public columns: TableColumn[];
  /**
   * Define actions in settings dropwdown
   */
  @Input() public rowActions: TableAction[];
  /**
   * Define row actions icon
   */
  @Input() public rowActionsIcon: string = 'fas fa-ellipsis-v';
  /**
   * Value of selected rows
   */
  @Input() public selectedValue: any[] = [];
  /**
   * Indicates if table records are selectable
   */
  @Input() public selectable = false;
  /**
   * Define wheter the table rows are clickable
   */
  @Input() public clickable?: boolean;
  /**
   * Define whether the rows are deletable. This will display a trash icon at the table rows
   */
  @Input() public deletable?: boolean;
  /**
   * Define whether the rows are editable. This will display a pencil icon at the table rows
   */
  @Input() public editable = true;
  /**
   * The property that defines whether the table row is expandable or not, a dataKey is required if true
   */
  @Input() public expandable = false;
  /**
   * The property that determines is the row is currently expanded or not
   */
  @Input() public expanded = false;
  /**
   * If a table element is deletable and editable this is true
   */
  @Input() public actions?: boolean;
  /**
   * Leave this empty when clickable is false or the route should remain the current route
   */
  @Input() public route?: string;
  /**
   * The property that decides whether a row is editable or not
   */
  @Input() public editableProperty: string;
  /**
   * Define whether the columns can be manageable(show or hide a column)
   */
  @Input() public manageableColumns: boolean;
  /**
   * The property that decides where the icons are located
   */
  @Input() public iconPosition: Directions = Directions.left;
  /**
  * Width for each column which are not frozen
  */
  @Input() public unFrozenColumnWidth: string;
  /**
   * Callback to invoke when a action is clicked
   */
  @Output() public onActionClick = new EventEmitter<TableActionEvent>();
  /**
   * Callback to invoke when delete button of a row is clicked
   */
  @Output() public onDeleteRow = new EventEmitter<number>();
  /**
   * Callback to invoke when edit button of a row is clicked
   */
  @Output() public onEditRow = new EventEmitter<number>();
  /**
   * Callback to invoke when icon of a row is clicked
   */
  @Output() public onIconClick = new EventEmitter<{ id: number; field: string }>();
  /**
   * Callback to invoke when state of row checkbox changes. This passes all selected rows
   */
  @Output() public onCheckboxToggle = new EventEmitter<any[]>();
  /**
   * Callback to invoke when state of row checkbox changes. This passes the selected row
   */
  @Output() public onRowToggle = new EventEmitter<any>();
  /**
   * Callback to invoke when the row is clicked. This passes the selected row
   */
  @Output() public onRowClick = new EventEmitter<any>();

  public directions: typeof Directions = Directions;
  public tableColumnTypes = TableColumnTypes;
  public filterMatchMode = FilterMatchMode;

  constructor(private router: Router) {
  }

  /**
   * Call to action
   */
  public callToAction(event: any, id: number, type: 'edit' | 'delete'): void {
    event.stopPropagation();

    if (type === 'edit') {
      this.onEditRow.emit(id);
    } else {
      this.onDeleteRow.emit(id);
    }
  }

  /**
   * When a row is checked in the table
   *
   * @return void
   */
  public onCheckboxClick(rowData: any): void {
    // This event passes all selected rows
    this.onCheckboxToggle.emit(this.selectedValue);
  }

  /**
   * When an element in the table is clicked the user gets routed to another page
   */
  public onElementClick(event: any, id: number): void {
    if (!this.clickable) {
      return;
    }

    // Prevent row click for selection td
    if (this.selectable) {
      const $td = event.target.closest('td');

      if ($td.classList.contains('selectable-wrapper')) {
        return;
      }
    }

    if (this.route) {
      const newRoute = [this.route + '/' + id];

      this.router.navigate(newRoute);
    } else {
      // Trigger onRowClick event
      this.onRowClick.emit(id);
    }
  }

  /**
   * Get optional style class for td
   *
   * @param field
   * @param tdStyleClass
   */
  public getTdStyleClass(field: string, tdStyleClass: any): string {
    if (tdStyleClass) {
      return tdStyleClass[field] || '';
    }

    return '';
  }

  public identifyIconType(type: TableColumnTypes): TableColumnTypes {
    return [TableColumnTypes.ICON, TableColumnTypes.ICON_TEXT].includes(type) ? type : '' as null;
  }

  /**
   * Trigger icon-click-event
   *
   * @param event
   * @param id
   * @param field
   */
  public onIconAction(event: any, id: number, field: string): void {
    event.stopPropagation();

    this.onIconClick.emit({ id, field });
  }
}
