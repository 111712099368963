import { Observable } from 'rxjs';
import { ApiListResult } from './api-list-result.interface';
import { MapItem } from './map-item.interface';

export function apiListResultToMapItem(result: ApiListResult, valueProperty: string = 'key', labelProperty: string = 'label'): MapItem[] {
  return result.data.map((item) => {
    return {
      label: item[labelProperty as keyof MapItem],
      value: item[valueProperty as keyof MapItem],
    };
  });
}

export function toMapItems(source: Observable<ApiListResult>, valueProperty: string = 'key', labelProperty: string = 'label'): Observable<MapItem[]> {
  return new Observable(subscriber => {
    source.subscribe({
      next(value: ApiListResult): void {
        if (value !== undefined && value !== null) {
          subscriber.next(apiListResultToMapItem(value, valueProperty, labelProperty));
        }
      },
      error(error: any): void {
        subscriber.error(error);
      },
      complete(): void {
        subscriber.complete();
      },
    });
  });
}

export function responseData(source: Observable<{ data: any }>): Observable<any> {
  return new Observable(subscriber => {
    source.subscribe({
      next(value: ApiListResult): void {
        if (value) {
          subscriber.next(value.data);
        }

        subscriber.complete();
      },
    });
  });
}
