import { ComponentType } from '@angular/cdk/overlay';
import { ListRendererWidget } from './list-renderer-widget';
import { Type } from '@angular/core';
import { HiddenExpression } from '@capturum/builders/core';
import { StyleClassExpression } from '@capturum/ui/api';
import { DynamicComponentMap } from '@capturum/ui/dynamic-filters';

export interface ListRendererModuleConfig {
  widgets?: Record<string, ComponentType<ListRendererWidget>>;

  [listKey: `list_${string}`]: {
    actions?: {
      [actionKey: string]: HiddenExpression & {
        component?: Type<any>,
      }
    },
  } & StyleClassExpression,
  filters?: DynamicComponentMap[],
}
