import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InputTextModule } from 'primeng/inputtext';
import { KeyFilterModule } from 'primeng/keyfilter';
import { CapturumSharedModule } from '@capturum/ui/api';
import { FormsModule } from '@angular/forms';
import { CapturumPasswordComponent } from './password.component';
import { CapturumInputModule } from '@capturum/ui/input';

@NgModule({
  declarations: [CapturumPasswordComponent],
  exports: [CapturumPasswordComponent],
  imports: [
    CommonModule,
    FormsModule,
    InputTextModule,
    KeyFilterModule,
    CapturumSharedModule,
    CapturumInputModule
  ],
})
export class CapturumPasswordModule {}
