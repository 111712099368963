export enum InputType {
  checkbox = 'checkbox',
  checkboxValue = 'checkboxvalue',
  checkboxList = 'checkboxlist',
  text = 'text',
  textarea = 'textarea',
  custom = 'custom',
  label = 'label',
  email = 'email',
  dropdown = 'dropdown',
  image = 'image',
  number = 'number',
  date = 'date',
  group = 'group',
  radio = 'radio',
  file = 'file',
  multiSelect = 'multiselect',
  toggle = 'toggle',
  repeat = 'repeat',
  autocomplete = 'autocomplete',
  translated = 'translated',
  numberFormat = 'numberFormat',
  entityText = 'entitytext',
  editor = 'editor',
  hidden = 'hidden',
  url = 'url',
  range = 'range',
  password = 'password',
  icon = 'icon',
}
