import { Component } from '@angular/core';
import { ListRendererWidgetBase } from '@capturum/builders/list-renderer';

@Component({
  selector: 'app-ice-cream-widget',
  templateUrl: './ice-cream-widget.component.html',
  styleUrls: ['./ice-cream-widget.component.scss']
})
export class IceCreamWidgetComponent extends ListRendererWidgetBase {

}
