<div class="entity-text-wrapper">
  <div class="p-inputgroup">
    <div class="p-inputwrapper">
      <cap-input [formControl]="formControl"
                 [floatLabel]="(to.label_position | observablePipe | async ) === labelPosition.Floating"
                 [label]="(to.label_position | observablePipe | async ) === labelPosition.Floating ? to.label : ''"
                 [readonly]="to.readonly"
                 [hidden]="to.readonly"
                 [hasTooltip]="to.tooltip"
                 [attr.data-test]="'builder-' + field.key"
                 [selectOnFocus]="to?.select_on_focus"
                 [placeholder]="to.readonly ? '' : to.placeholder">
      </cap-input>
    </div>

    <cap-button styleClass="primary" icon="fas fa-search" (onClick)="openEntityBuilderInfoDialog()"></cap-button>
  </div>

  <cpb-readonly-container [hidden]="!to.readonly" [attr.data-test]="'builder-readonly-' + field.key">
    {{ formControl.value }}
  </cpb-readonly-container>
</div>

