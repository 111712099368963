import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FlowListContextkey, FlowListKey } from '../../enums/flow-list-key.enum';
import { ColumnClickEvent } from '../../interfaces/flow.interface';
import { CapturumInfoTableModule } from '@capturum/ui/info-table';
import { CapturumFlowErrorDetailsComponent } from '../flow-error-details/flow-error-details.component';

@Component({
  selector: 'cap-flow-statistic-details',
  templateUrl: './flow-statistic-details.component.html',
  styleUrls: ['./flow-statistic-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TranslateModule, CapturumInfoTableModule],
})
export class CapturumFlowStatisticDetailsComponent extends CapturumFlowErrorDetailsComponent implements OnInit {

  public columnClick(data: ColumnClickEvent): void {
    const column = data.row[data.column.split('.')[0]];

    if (column?.action) {
      this.dialogRef.close();

      this.dialogService.open(CapturumFlowStatisticDetailsComponent, {
        data: {
          configurationKey: FlowListContextkey.actionStatistics,
          item: column,
        },
        header: this.translateService.instant(`connector.action-statistics.context.label`),
        width: '70%',
        styleClass: FlowListContextkey.actionStatistics,
      });
    }
  }

  protected setContext(): void {
    switch (this.configData?.configurationKey) {
      case FlowListKey.actions:
      case FlowListContextkey.actionStatistics:
        this.contextKey = FlowListContextkey.actionStatistics;
        this.context = {
          action: this.configData?.item?.action,
          id: this.configData?.item?.id,
        };

        break;
      case FlowListKey.flows:
        this.contextKey = FlowListContextkey.flowStatistics;
        this.context = {
          flow_ref: this.configData?.item?.flow_ref,
          id: this.configData?.item?.id,
        };

        break;
      default:
    }

    this.contextService.setContext(this.contextKey, this.context);
  }
}
