import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseDataResolver } from '../../domain/base-data/resolvers/base-data.resolver';
import { ModuleResolver } from '../../domain/module/resolvers/module.resolver';
import { PermissionResolver } from '../../domain/permission/resolvers/permission.resolver';
import { SettingResolver } from '../../domain/setting/resolvers/setting.resolver';
import { catchError, finalize, map } from 'rxjs/operators';
import { TranslationResolver } from '../../domain/translation/resolvers/translation.resolver';
import { LoadingService } from '@capturum/ui/loader';
import { TranslateService } from '@ngx-translate/core';
import { CompleteConfig } from '../../complete-config';

@Injectable()
export class CompleteResolver  {
  constructor(private baseDataResolver: BaseDataResolver,
              private moduleResolver: ModuleResolver,
              private settingResolver: SettingResolver,
              private translationResolver: TranslationResolver,
              private loadingService: LoadingService,
              private translateService: TranslateService,
              private coreConfig: CompleteConfig,
              private permissionResolver: PermissionResolver) {
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.loadingService.toggleLoading(true,
      this.coreConfig.useTranslatedLoaderText ? this.translateService.instant(this.coreConfig.loaderText) : this.coreConfig.loaderText,
      this.coreConfig.loaderImage,
      'complete-resolver-loader'
    );

    return forkJoin([
      this.baseDataResolver.resolve(route, state),
      this.moduleResolver.resolve(route, state),
      this.settingResolver.resolve(route, state),
      this.translationResolver.resolve(route, state),
      this.permissionResolver.resolve(route, state)
    ]).pipe(
      finalize(() => this.loadingService.hideLoader()),
      catchError(() => {
        this.loadingService.hideLoader();

        return of(false);
      }),
      map(() => {
        this.loadingService.hideLoader();

        return true;
      })
    );
  }
}
