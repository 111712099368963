import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { takeUntil } from 'rxjs/operators';
import { MapItem } from '@capturum/ui/api';

@Component({
  selector: 'cpb-icon-input',
  templateUrl: './icon-input.component.html',
  styleUrls: ['./icon-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CapturumBuilderIconInputComponent extends FieldType implements OnInit, OnDestroy {
  public icons: MapItem[] = [];
  private destroy$ = new Subject<boolean>();
  private readonly mappedIcons = './assets/reduced-font-awesome-icons.json';

  constructor(private http: HttpClient) {
    super();
  }

  public ngOnInit(): void {
    this.http.get<MapItem[]>(this.mappedIcons)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: MapItem[]) => {
        this.icons = data;
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
