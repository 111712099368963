import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormField } from './formField.model';

@Component({
  selector: 'em-form',
  templateUrl: './form.component.html',
  styles: []
})
export class FormComponent {
  public form: FormGroup;

  private _fields: FormField[];

  get fields(): FormField[] {
    return this._fields;
  }

  @Input()
  set fields(fields: FormField[]) {
    const setFields: FormField[] = [];

    fields.forEach((field) => {
      setFields.push(new FormField(field));
    });

    this._fields = setFields;
  }

  @Input() public model: any;

  @Output() public onSubmit = new EventEmitter<any>();

  constructor() {
    this.form = new FormGroup({});
    this.model = { email: 'email@gmail.com' };
  }

  public submit(model): void {
    this.onSubmit.emit(model);
  }
}
