import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  EventEmitter,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  DynamicFilterComponent,
  FilterConfigItem,
  MultiSelectFilterConfigItem,
} from '../../interfaces/dynamic-filter.interface';

@Component({
  selector: 'cap-checkbox-filter',
  templateUrl: './checkbox-filter.component.html',
  styleUrls: ['./checkbox-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxFilterComponent implements DynamicFilterComponent<boolean> {
  @Input()
  public filterConfig: FilterConfigItem;
  public updateValue: EventEmitter<boolean> = new EventEmitter<boolean>();
  public formControl = new FormControl();
  public value: boolean;

  constructor(private cdr: ChangeDetectorRef) {}

  public setValue(value: boolean): void {
    this.formControl.setValue(value, { emitEvent: false });
    this.cdr.detectChanges();
  }

  public reset(): void {
    this.formControl.setValue(null);
    this.updateValue.emit(null);
  }

  public updateView(): void {
    this.cdr.detectChanges();
  }
}
