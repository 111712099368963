import { Injectable } from '@angular/core';
import { ActionProvider } from '@capturum/builders/core';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class PepActionService implements ActionProvider {
  constructor(private router: Router) {
  }

  public execute(options: any, item: any): void {
    alert('PEPPY IS AWESOME:' + this.router.url);
  }
}
