<form [formGroup]="formGroup" *ngIf="formGroup">
  <div class="multi-factor-form">
    <section class="multi-factor-form--section" *ngIf="canSelectMethod">
      <h5 class="multi-factor-form--section-title">
        <span>{{ paragraphNumbers?.method }}.</span>&nbsp;{{ 'auth.2fa.types.select' | translate }}
      </h5>

      <div class="multi-factor-form--section-body">
        <cap-dropdown styleClass="w-100"
                      formControlName="method"
                      [options]="types$ | async"
                      [placeholder]="'auth.2fa.types.select' | translate">
          <ng-template capTemplate="item" let-item>
            {{ item.label | translate }}
          </ng-template>

          <ng-template capTemplate="selectedItem" let-item>
            {{ item.label | translate }}
          </ng-template>
        </cap-dropdown>
      </div>
    </section>

    <section class="multi-factor-form--section" *ngIf="controlMethod.value">
      <ng-container [ngSwitch]="controlMethod.value">
        <emc-auth-email *ngSwitchCase="TwoFactorMethod.email"
                        [paragraphNumbers]="paragraphNumbers"
                        [method]="controlMethod.value"
                        (emailCodeEvent)="showAndFocusInputCode()">
        </emc-auth-email>

        <emc-auth-sms *ngSwitchCase="TwoFactorMethod.sms"
                      [paragraphNumbers]="paragraphNumbers"
                      [method]="controlMethod.value"
                      (smsCodeEvent)="showAndFocusInputCode()">
        </emc-auth-sms>

        <emc-auth-authenticator *ngSwitchDefault
                                [paragraphNumbers]="paragraphNumbers"
                                [method]="controlMethod.value">
        </emc-auth-authenticator>
      </ng-container>
    </section>

    <section class="multi-factor-form--section"
             [style.visibility]="!!controlMethod.value ? 'visible' : 'hidden'">
      <h5 class="multi-factor-form--section-title">
        <span>{{ paragraphNumbers?.code }}.</span>&nbsp;{{ 'auth.2fa.code.title' | translate }}
      </h5>

      <p class="multi-factor-form--section-description">
        {{ 'auth.2fa.' + controlMethod.value + '.code.description' | translate }}
      </p>

      <cap-input-code formControlName="code"
                      (valuesFilled)="appInputCodeFilled()">
      </cap-input-code>
    </section>
  </div>
</form>
