import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayResetFilter'
})
export class DisplayResetFilterPipe implements PipeTransform {
  public transform(fieldValue: string | string[]): boolean {
    if (fieldValue) {
      if (Array.isArray(fieldValue)) {
        return !!fieldValue?.length;
      }

      return !!String(fieldValue);
    }

    return false;
  }
}
