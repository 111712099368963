<ng-container *ngIf="actions && iconPosition === directions.left">
    <td class="actions-wrapper">
        <div class="actions" [style.visibility]="!rowData.hideActions ? 'visible' : 'hidden'">
            <i (click)="callToAction($event, rowData.id, 'edit')"
               *ngIf="editable && ((editableProperty && rowData[editableProperty]) || !editableProperty) && !rowData.hideEdit"
               class="fas fa-pencil-alt"></i>
            <i (click)="callToAction($event, rowData.id, 'delete')"
               *ngIf="deletable && !rowData.hideDelete" class="fas fa-trash-alt"></i>
        </div>
    </td>
</ng-container>

<ng-container *ngIf="selectable">
    <td class="selectable-wrapper">
        <p-tableCheckbox (click)="onCheckboxClick(rowData)"
                         [disabled]="rowData.selectable === false"
                         [value]="rowData"></p-tableCheckbox>
    </td>
</ng-container>

<ng-container *ngFor="let col of columns">
    <td *ngIf="col.visible"
        [ngClass]="col.styleClass"
        class="list-page-table-column {{(columns | isFrozen:col) ? 'frozen-cell' : ''}}"
        [ngStyle]="{'width': !(columns | isFrozen) && unFrozenColumnWidth}"
        [attr.data-test]="'table_cell_' + col?.field"
        (click)="onElementClick($event, rowData.id)">
        <div class="divider">
            <span class="p-column-title">{{ col.header | observablePipe | async }}</span>

            <ng-container [ngSwitch]="col.type">
                <ng-container *ngSwitchCase="tableColumnTypes.ARRAY">
                    <span
                        [className]="'td-value ' + getTdStyleClass(col.field, rowData.td_style_class)"
                        [pTooltip]="(rowData | nestedColumnValue : col.field : col.type)"
                        [escape]="false"
                        [tooltipPosition]="col.tooltipPosition">
                        {{ col.isObservable ? (rowData | nestedColumnValue : col.field : col.type | async) : (rowData | nestedColumnValue : col.field : col.type) }}
                    </span>
                </ng-container>

                <ng-container *ngSwitchCase="identifyIconType(col.type)">
                    <span (click)="onIconAction($event, rowData.id, col.field)"
                          [pTooltip]="col.tooltip | observablePipe | async | interpolation : rowData"
                          [tooltipPosition]="col.tooltipPosition"
                          [escape]="false"
                          [className]="'td-icon ' + getTdStyleClass(col.field, rowData.td_style_class)"
                          [innerHTML]="col.type === 'icon-text' ? (rowData | nestedColumnValue : col.field : col.type : [] : col.icon | async) : (rowData | nestedColumnValue : col.field : col.type : [] : col.icon)">
                    </span>
                </ng-container>

                <ng-container *ngSwitchDefault>
                    <span [className]="'td-value ' + getTdStyleClass(col.field, rowData.td_style_class)"
                          [pTooltip]="col.tooltip | observablePipe | async | interpolation : rowData"
                          [tooltipPosition]="col.tooltipPosition"
                          [escape]="false">
                       {{ col.isObservable ? (rowData | nestedColumnValue : col.field : col.type | async) : (rowData | nestedColumnValue : col.field : col.type) }}
                    </span>
                </ng-container>
            </ng-container>
        </div>
    </td>
</ng-container>

<ng-container *ngIf="!(columns | isFrozen)">
    <ng-container *ngIf="rowActions">
        <td class="actions-wrapper">
            <cap-row-action [actions]="rowActions"
                            [item]="rowData"
                            [icon]="rowActionsIcon"
                            (clickAction)="onActionClick?.emit($event)">
            </cap-row-action>
        </td>
    </ng-container>

    <ng-container *ngIf="actions && iconPosition === directions.right">
        <td class="actions-wrapper">
            <div class="actions actions-right"
                 [style.visibility]="!rowData.hideActions ? 'visible' : 'hidden'">
                <i *ngIf="editable && ((editableProperty && rowData[editableProperty]) || !editableProperty) && !rowData.hideEdit"
                   (click)="callToAction($event, rowData.id, 'edit')"
                   class="fas fa-pencil-alt">
                </i>

                <i *ngIf="deletable && !rowData.hideDelete"
                   (click)="callToAction($event, rowData.id, 'delete')"
                   class="fas fa-trash-alt">
                </i>
            </div>
        </td>
    </ng-container>

    <td *ngIf="expandable" class="expand">
        <i [pRowToggler]="rowData" [ngClass]="{'fa-caret-down': expanded, 'fa-caret-up': !expanded}" class="fas"></i>
    </td>

    <td *ngIf="manageableColumns"
        class="empty-manageable-column">
    </td>
</ng-container>
