<p-table
  cdkDropList
  [styleClass]="'cap-info-table ' + styleClass"
  [ngClass]="{ 'cap-info-table--hidden': loading, 'cap-info-table--auto-layout': autoLayout }"
  [class.cap-info-table--resizable-columns]="resizableColumns"
  [value]="data"
  [lazy]="lazyLoading"
  [lazyLoadOnInit]="lazyLoadOnInit"
  [stateKey]="stateKey"
  [columns]="columns"
  [rows]="paginator?.rows || rows"
  [totalRecords]="paginator?.total"
  [sortMode]="sortMode"
  [paginator]="pagination"
  [dataKey]="dataKey"
  [loading]="loading"
  [(selection)]="selectedRows"
  [first]="paginator?.first"
  [rowTrackBy]="rowTrackBy"
  [scrollable]="scrollable || virtualScroll"
  [virtualScroll]="virtualScroll"
  [scrollHeight]="scrollHeight"
  [virtualScrollItemSize]="virtualScrollItemSize"
  [stateStorage]="stateKey ? 'local' : undefined"
  [autoLayout]="autoLayout"
  [selectionPageOnly]="selectionPageOnly"
  [editMode]="isEditableRows ? 'row' : 'cell'"
  [resizableColumns]="resizableColumns"
  [columnResizeMode]="columnResizeMode"
  (cdkDropListDropped)="onCdkRowReorder($event)"
  (onRowSelect)="toggleRowSelect($event, true)"
  (onRowUnselect)="toggleRowSelect($event, false)"
  (onStateRestore)="onStateRestore.emit($event)"
  (onLazyLoad)="loadTableData($event)"
  (selectionChange)="onSelectionChange()"
  (onSort)="onSortColumn($event)">
  <ng-template pTemplate="header">
    <cap-info-table-header
      *ngIf="!resizableColumns"
      style="display: contents"
      [sortable]="sortable"
      [hasFrozenColumns]="hasFrozenColumns"
      [selectable]="selectable"
      [templates]="templates"
      [columns]="columns"
      [reorderableRows]="reorderableRows"
      [editableRows]="isEditableRows"
      (sortColumn)="sortClick?.emit($event)">
    </cap-info-table-header>

    <ng-container *ngIf="resizableColumns" [ngTemplateOutlet]="resizableColumnsHeader"></ng-container>

    <!-- reason for this approach is that primeng Table update style to direct header row  -->
    <!-- .p-datatable-thead > tr, so if we wrap it with component selector it wont work -->
    <ng-template #resizableColumnsHeader>
      <tr class="cap-info-table--resizable-columns-header-row">
        <th *ngIf="reorderableRows" class="reorderable-rows-header"></th>
        <th *ngIf="selectable" class="selectable-table-checkbox-column" capFrozenColumn [frozen]="hasFrozenColumns">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>

        <ng-container *ngFor="let column of columns; let i = index">
          <th
            *ngIf="!column?.hidden"
            class="cap-info-table__header {{ column?.titleClass ? 'th_' + column?.titleClass : '' }}"
            capFrozenColumn
            pResizableColumn
            [pResizableColumnDisabled]="!resizableColumns"
            [frozenColumnWidth]="column?.frozenColumnWidth"
            [frozen]="column?.frozen">
            <div
              *ngIf="
                sortable &&
                  !column.disableSort &&
                  (column?.sortable === undefined || column?.sortable?.enabled) &&
                  column?.title
                  | observablePipe
                  | async;
                else nonsortable
              "
              class="cap-info-table__column-header {{ column?.titleClass }}"
              [capCellTooltip]="column?.title | observablePipe | async"
              [attr.data-test]="'cap-info-table__column-header_' + (column | sortField)"
              [ngClass]="{ 'cap-info-table__head--sortable': column?.sortable }"
              [pSortableColumn]="column | sortField">
              {{ column?.title | observablePipe | async }}
              <cap-sort-icon [field]="column | sortField"></cap-sort-icon>
            </div>

            <ng-template #nonsortable>
              <div
                [capCellTooltip]="column?.title | observablePipe | async"
                class="cap-info-table__column-header {{ column?.titleClass }}"
                [attr.data-test]="'cap-info-table__column-header_' + (column | sortField)">
                {{ column?.title | observablePipe | async }}
              </div>
            </ng-template>

            <ng-container
              *ngIf="templates | columnTemplate : 'th_' + column.field as customHeaderTemplate"
              [ngTemplateOutletContext]="{ column: column, index: i }"
              [ngTemplateOutlet]="customHeaderTemplate">
            </ng-container>
          </th>
        </ng-container>
      </tr>
    </ng-template>

    <ng-container *ngIf="showVisibilityToggler">
      <i
        (click)="visibilityPanel.toggle($event)"
        class="cap-info-table__visibility-toggler {{ visibilityTogglerIcon }}">
      </i>

      <p-overlayPanel
        #visibilityPanel
        styleClass="cap-info-table__visibility-panel p-0"
        appendTo="body"
        [style]="{ width: '250px' }">
        <div *ngFor="let column of columnOptions; let i = index" class="column d-flex align-items-center">
          <p-checkbox
            styleClass="visiblity-checkbox"
            [(ngModel)]="column.visible"
            [binary]="true"
            (onChange)="setVisibility()">
          </p-checkbox>

          <span class="ml-3">{{ column?.title | observablePipe | async }}</span>
        </div>
      </p-overlayPanel>
    </ng-container>
  </ng-template>

  <ng-template pTemplate="body" let-item let-rowIndex="rowIndex" let-editing="editing">
    <cap-info-table-row
      style="display: contents"
      [item]="item"
      [rowIndex]="rowIndex"
      [dataKey]="dataKey"
      [columns]="columns"
      [loading]="loading"
      [selectedValues]="selectedRows"
      [hasFrozenColumns]="hasFrozenColumns"
      [clickable]="clickable"
      [selectable]="selectable"
      [templates]="templates"
      [reorderableRows]="reorderableRows"
      [editableRows]="isEditableRows"
      [editing]="editing"
      [reorderableRowIcon]="reorderableRowIcon"
      [editTable]="editTable"
      [isEdit]="isEdit"
      [cardsView]="cardsView"
      [styleClassExpression]="styleClassExpression"
      (onColumnClick)="onColumnClick.emit($event)"
      (onRowClick)="onRowClick?.emit($event)"
      (onRowCtrlClick)="onRowCtrlClick?.emit($event)"
      (onRowEditClick)="onRowEditClick.emit($event)"
      (onRowEditSaveClick)="onRowEditSaveClick.emit($event)"
      (onRowEditCancelClick)="onRowEditCancelClick.emit($event)">
    </cap-info-table-row>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <tr *ngIf="columns && !loading" class="empty-message-row text-center">
      <td [attr.colspan]="selectable ? visibleColumnsLength + 1 : visibleColumnsLength">
        {{ texts.noResults | observablePipe | async }}
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="paginatorleft">
    <div *ngIf="paginator" class="d-flex align-items-baseline paginator-left">
      <span class="paginator-left__page-text text-capitalize">
        {{ texts.footerPaginationText | observablePipe | async | splitPipe : '{0}' : 0 }}
      </span>

      <!-- @TODO: Investigate why without placeholder="Select" the dropdown value is not predefined -->
      <cap-dropdown
        id="num-rows"
        sortBy="value"
        [placeholder]="'placeholder.select' | translate"
        styleClass="num-rows-dropdown text-left"
        inputId="num-rows"
        name="num_rows"
        [(ngModel)]="paginator.rows"
        [options]="perPageOptions"
        [sortAlphabetically]="false"
        (change)="onPerPageChange($event.value)">
      </cap-dropdown>

      <span class="paginator-left__total-text">
        {{ texts.footerPaginationText | observablePipe | async | splitPipe : '{0}' : 1 | splitPipe : '{1}' : 0 }}
        {{ paginator.total }}
        {{ texts.footerPaginationText | observablePipe | async | splitPipe : '{1}' : 1 }}
      </span>
    </div>
  </ng-template>

  <ng-template pTemplate="footer" let-columns *ngIf="footerTemplate">
    <ng-container *ngTemplateOutlet="footerTemplate; context: { $implicit: columns }"></ng-container>
  </ng-template>
</p-table>

<ng-container *ngIf="loading">
  <div #skeletonRef class="cap-info-table__skeleton">
    <ng-content select="[skeleton]"></ng-content>
  </div>

  <cap-info-table-skeleton *ngIf="!skeletonRef?.children?.length"></cap-info-table-skeleton>
</ng-container>
