import { Directive, forwardRef, Input } from '@angular/core';
import { NG_VALIDATORS, Validator } from '@angular/forms';
import { KeyFilter } from 'primeng/keyfilter';

export const KEYFILTER_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => CapturumKeyfilterDirective),
  multi: true,
};

/** This object is no longer exported from primeng since version 9.1.3 */
export const DEFAULT_MASKS = {
  pint: /[\d]/,
  int: /[\d\-]/,
  pnum: /[\d\.]/,
  money: /[\d\.\s,]/,
  num: /[\d\-\.]/,
  hex: /[0-9a-f]/i,
  email: /[a-z0-9_\.\-@]/i,
  alpha: /[a-z_]/i,
  alphanum: /[a-z0-9_]/i,
};

@Directive({
  selector: '[capKeyFilter]',
  providers: [KEYFILTER_VALIDATOR],
})
export class CapturumKeyfilterDirective extends KeyFilter implements Validator {
  @Input('emKeyFilter') set pattern(_pattern: any) {
    this._pattern = _pattern;
    this.regex = DEFAULT_MASKS[this._pattern as string] || (this._pattern as RegExp);
  }
}
