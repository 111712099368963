<p-tabView styleClass="flow-tabs" (onChange)="changeTabIndex()" [(activeIndex)]="activeIndex">
  <!--  Actions  -->
  <p-tabPanel [header]="FlowTabs.actions">
    <ng-template pTemplate="header">
      <span class="tab-title">{{ 'connector.action-statistics.tabs.actions' | translate }}</span>
    </ng-template>

    <cap-flow-list *ngIf="selectedTab === FlowTabs.actions" [builderKey]="flowListKey.actions"></cap-flow-list>
  </p-tabPanel>

  <!--  Flows -->
  <p-tabPanel [header]="FlowTabs.flows">
    <ng-template pTemplate="header">
      <span class="tab-title">{{ 'connector.action-statistics.tabs.flows' | translate }}</span>
    </ng-template>

    <cap-flow-list *ngIf="selectedTab === FlowTabs.flows" [builderKey]="flowListKey.flows"></cap-flow-list>
  </p-tabPanel>
</p-tabView>
