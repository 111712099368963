import { Pipe, PipeTransform } from '@angular/core';
import { HiddenExpression } from '@capturum/builders/core';

@Pipe({
  name: 'filterByHiddenExpression'
})
export class FilterByHiddenExpressionPipe implements PipeTransform {
  public transform(values: HiddenExpression[], item: unknown): HiddenExpression[] {
    return values.filter((value) => {
      return !value.hiddenExpression || !value.hiddenExpression(item);
    });
  }
}
