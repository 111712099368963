import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ListRendererWidgetBase } from '@capturum/builders/list-renderer';

@Component({
  selector: 'cap-number-without-decimals-widget',
  template: `<span class="number-without-decimals-widget">{{ item[field] | number: '1.0-0' }}</span>`,
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
})
export class CapturumNumberWithoutDecimalsWidgetComponent extends ListRendererWidgetBase {}
