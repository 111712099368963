import { Component, OnInit } from '@angular/core';
import { ModuleIndexedDbModel } from '../../../module/models/module.indexedDb-model';
import { BaseDataKeyService } from '../../../base-data/services/base-data-key.service';
import { BaseDataKeyIndexedDbModel } from '../../../base-data/models/base-data-key.indexedDb-model';
import { CompleteConfigService } from '../../services/complete-config.service';
import { ConfigItem } from '../../models/config-item.model';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'emc-config-overview',
  templateUrl: 'config-overview.component.html',
  styleUrls: ['./config-overview.component.scss']
})
export class ConfigOverviewComponent implements OnInit {
  public baseDataKeys: BaseDataKeyIndexedDbModel[];
  public allowedPermissions$: Observable<string[]>;
  public configItems$: Observable<ConfigItem[]>;

  public constructor(
    private router: Router,
    private baseDataKeyService: BaseDataKeyService,
    private completeConfigService: CompleteConfigService
  ) {
  }

  public ngOnInit(): void {
    this.getBaseDataKeys();
    this.configItems$ = this.completeConfigService.getConfigOverviewItems();
    this.allowedPermissions$ = this.completeConfigService.getAllowedPermissions();
  }

  public onConfigItemClick(configItem: ConfigItem): void {
    if (configItem.routerLink) {
      this.router.navigate([configItem.routerLink]);
    } else if (configItem.callback) {
      configItem.callback();
    }
  }

  private getBaseDataKeys(): void {
    const options = {
      perPage: 99999
    };

    this.baseDataKeyService.index(options).subscribe(async (response) => {
      this.baseDataKeys = response.data.map((baseDataKey) => {
        const module = ModuleIndexedDbModel.modules.find((moduleModel) => moduleModel.id === baseDataKey.module_id);

        if (module) {
          baseDataKey.key = `${module.name}.base-data.${baseDataKey.key.replace(/_/g, '-')}`;
        }

        return baseDataKey;
      });
    });
  }
}
