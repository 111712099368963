import { CapturumDropdownModule } from '@capturum/ui/dropdown';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// Libraries, 3d party components
import { SharedModule as EmendisSharedModule } from '@capturum/shared';
import { ToastModule } from 'primeng/toast';
import { FormlyModule } from '@ngx-formly/core';
import { TranslateModule, TranslatePipe, TranslateService } from '@ngx-translate/core';
import { NgxPermissionsModule } from 'ngx-permissions';
// Custom components
import { FormModule } from '@capturum/formly';
import { TenantService } from '../domain/tenant/services/tenant.service';
import { RoleSelectorComponent } from './components/role-selector/role-selector.component';
import { DropdownModule } from 'primeng/dropdown';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CapturumLoaderModule } from '@capturum/ui/loader';
import { CapturumLayoutModule } from '@capturum/ui/layout';
import { CapturumSharedModule } from '@capturum/ui/api';
import { CapturumTableModule } from '@capturum/ui/table';

@NgModule({
  declarations: [
    RoleSelectorComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormModule,
    FormlyModule,
    ToastModule,
    TranslateModule,
    NgxPermissionsModule,
    EmendisSharedModule,
    CapturumTableModule,
    CapturumLayoutModule,
    CapturumTableModule,
    CapturumLoaderModule,
    DropdownModule,
    ConfirmDialogModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    TranslatePipe,
    FormModule,
    FormlyModule,
    TranslateModule,
    EmendisSharedModule,
    NgxPermissionsModule,
    RoleSelectorComponent,
    CapturumSharedModule,
    CapturumLayoutModule,
    CapturumTableModule,
    CapturumLoaderModule,
    CapturumDropdownModule,
    DropdownModule,
    ConfirmDialogModule
  ],
  providers: [
    TranslateService,
    TenantService
  ]
})
export class SharedModule {
}
