<div
  *ngIf="filterConfig.filters?.length"
  class="cap-filters {{ containerStyleClass }}"
  [class.sidebar-filtering]="sidebarFiltering">
  <div class="filter-list">
    <ng-container *ngFor="let filter of showFilters; let index = index">
      <div
        *ngIf="index < maxFiltersShown || !sidebarFiltering"
        class="filter {{ filterStyleClass }} {{ filter?.styleClass }}"
        [ngClass]="{ search: filter?.field === 'global_search' }">
        <cap-dynamic-filter
          [attr.data-test]="'filter-' + filter.field"
          (updateValue)="handleUpdateValue($event)"
          [activeFilter]="activeFilters | filterField : filter.field"
          [filterConfig]="filter">
        </cap-dynamic-filter>
      </div>
    </ng-container>
  </div>

  <div class="filter-button">
    <cap-button
      [class.hide-filter-button]="maxFiltersShown >= filterConfig.filters.length"
      styleClass="primary"
      class="mr-2 sidebar-filter-button"
      icon="fas fa-filter"
      (click)="openFilterSidebar()">
    </cap-button>

    <cap-button
      *ngIf="showRefreshButton"
      class="refresh-filter-button mr-2"
      styleClass="tertiary"
      [icon]="filterConfig.icons?.refresh || ''"
      [label]="filterConfig.texts?.refresh || ('refresh.button' | translate)"
      (click)="refreshFilters()"></cap-button>

    <cap-button
      *ngIf="showResetButton"
      class="reset-filter-button"
      styleClass="tertiary"
      [icon]="filterConfig.icons?.reset || ''"
      [label]="filterConfig.texts?.reset || ('reset.button' | translate)"
      (click)="resetFilters()"></cap-button>
  </div>
</div>

<div class="dynamic-filter-chips" *ngIf="sidebarFiltering">
  <p-chips
    [(ngModel)]="filteredTokens"
    [showClear]="false"
    styleClass="table-filters-tokens"
    [inputStyle]="{ display: 'none' }"
    (onRemove)="removeFilter($event.value)">
    <ng-template let-item pTemplate="item">
      {{ item.label }}
    </ng-template>
  </p-chips>
</div>

<cap-sidebar [(visible)]="showSidebar"
             styleClass="cap-dynamic-filters-sidebar"
             [title]="filterConfig.texts?.filters || 'Filters'">
  <cap-dynamic-filters-sidebar
    [activeFilters]="activeFilters"
    [filterConfig]="filterConfig"
    (filterChange)="applySidebarFilters($event)"
    (cancelClick)="showSidebar = false"
    [storageKey]="storageKey">
  </cap-dynamic-filters-sidebar>
</cap-sidebar>
