export enum FilterMatchMode {
  LESS_OR_EQUAL = 'lessorequal',
  GREATER_OR_EQUAL = 'greaterorequal',
  EQUALS = 'equals',
  NOT_EQUALS = 'notequals',
  GREATER = 'greater',
  LESS = 'less',
  BETWEEN = 'between',
  NOT_BETWEEN = 'notbetween',
  IN = 'in',
  NOT_IN = 'notin',
  LIKE = 'like',
  NOT_LIKE = 'notlike',
  DATEBETWEEN = 'datebetween',
  DATEEQUALS = 'dateequals',
}
