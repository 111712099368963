import { Component } from '@angular/core';
import { defaultModulesConfig } from '@capturum/ui/editor';
import { FieldType } from '@ngx-formly/core';
import { QuillConfig } from 'ngx-quill';

@Component({
  selector: 'cpb-editor-input',
  templateUrl: './editor-input.component.html',
  styleUrls: ['./editor-input.component.scss'],
})
export class CapturumBuilderEditorInputComponent extends FieldType {
  public get config(): QuillConfig {
    return {
      placeholder: this.props?.placeholder || '',
      modules: this.props?.modules || {...defaultModulesConfig},
    }
  }
}
