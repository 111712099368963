<form class="inputs-code w-100" [formGroup]="formGroup">
  <ng-container *ngFor="let control of codeInputArray?.controls; let index = index">
    <ng-container formArrayName="code">
      <cap-input [formControl]="control"
                 maxLength="1"
                 pKeyFilter="pint"
                 styleClass="input-code"
                 (paste)="onPaste($event, index)">
      </cap-input>
    </ng-container>
  </ng-container>
</form>
