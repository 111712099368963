import { DestroyBase } from '@capturum/shared';
import {
  EntitiesGroupInfo,
  EntityInfoRequest,
  EntityInformation,
  GroupOptionItem,
} from './../../interfaces/entity-builder-config.interface';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Component, OnInit, ViewChild, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { switchMap, takeUntil, catchError, take } from 'rxjs/operators';
import { BuilderUiEntityService } from '../../services/form-builder-entity.service';
import { AutoComplete } from 'primeng/autocomplete';
import { interval, of } from 'rxjs';
import { EntityDialogAction } from '../../enums/entity-dialog-action.enum';
import { FormRendererService } from '../../services/form-renderer.service';
import { EntityInfoSourceMethod, EntityInfoType } from '../../enums/entity-info-type.enum';

@Component({
  selector: 'cpb-entity-text-input-dialog',
  templateUrl: './entity-text-input-dialog.component.html',
  styleUrls: ['./entity-text-input-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EntityTextInputDialogComponent extends DestroyBase implements OnInit, AfterViewInit {
  @ViewChild('autoComplete', { static: true })
  public autoComplete: AutoComplete;

  public suggestions: EntitiesGroupInfo[] = [];
  public selectedEntities: GroupOptionItem[];
  public key: string;
  public type: string;
  public entities: Map<string, Record<string, EntityInformation[]>> = new Map();
  public disabled: boolean = true;
  public sourceMethod: string;

  constructor(
    private config: DynamicDialogConfig,
    private entityService: BuilderUiEntityService,
    private formRendererService: FormRendererService,
    private dialogRef: DynamicDialogRef
  ) {
    super();

    this.key = this.config?.data?.key;
    this.type = this.config?.data?.type;
    this.sourceMethod = !(this.type === EntityInfoType.list || this.type === EntityInfoType.form)
      ? EntityInfoSourceMethod.getSourceValueByKey
      : EntityInfoSourceMethod.getFormValueByKey;
  }

  public ngOnInit(): void {
    if (this.key) {
      this.formRendererService[this.sourceMethod](this.key)
        .pipe(
          takeUntil(this.destroy$),
          switchMap(({ source }) => {
            const entity: EntityInfoRequest = {
              type: !(this.type === EntityInfoType.list || this.type === EntityInfoType.form)
                ? this.type
                : EntityInfoType.method,
              service: source.get.class,
            };

            return this.entityService.searchEntityInformation(entity);
          }),
          catchError((error) => {
            this.closeDialog();

            return of(error);
          })
        )
        .subscribe((entities) => {
          Object.keys(entities).forEach((entity) => {
            if (entity !== EntityInfoType.name) {
              this.entities.set(entity, entities[entity]);
            }
          });

          this.disabled = false;
        });
    }
  }

  public ngAfterViewInit(): void {
    this.autoComplete.multiContainerEL.nativeElement.readOnly = true;
  }

  public onFocus(event: FocusEvent): void {
    const eventTarget: HTMLInputElement = event.target as HTMLInputElement;

    if (eventTarget.value.trim() === '' && !this.selectedEntities) {
      this.getEntitiesStartPoint();
    }

    this.autoComplete.show();
  }

  public selectItem(item: GroupOptionItem): void {
    this.autoComplete.multiContainerEL.nativeElement.disabled = item.type !== EntityInfoType.relations;
    const relations = this.getFromEntitiesList(item.key);

    if (relations) {
      this.suggestions = this.mapEntityInformationResult(relations);
    }

    this.blurOnSelectUnselectItem();
  }

  public unselectItem(item: GroupOptionItem): void {
    this.autoComplete.multiContainerEL.nativeElement.disabled = false;

    if (this.selectedEntities?.length) {
      const [entity] = this.selectedEntities.slice(-1);
      this.suggestions = this.mapEntityInformationResult(this.getFromEntitiesList(entity.key));
    } else {
      this.getEntitiesStartPoint();
    }

    this.blurOnSelectUnselectItem();
  }

  public closeDialog(): void {
    this.dialogRef.close({
      action: EntityDialogAction.cancel,
    });

    this.dialogRef.destroy();
  }

  public submit(): void {
    this.dialogRef.close({
      action: EntityDialogAction.submit,
      data: this.selectedEntities.map((entity) => entity.value).join('.'),
    });
  }

  private getEntitiesStartPoint(): void {
    const [service_methods] = this.entities.values();

    this.suggestions = this.mapEntityInformationResult(
      this.entities.has(EntityInfoType.start) ? this.entities.get(EntityInfoType.start) : service_methods
    );
  }

  private mapEntityInformationResult(result: string[] | Record<string, EntityInformation[]>): EntitiesGroupInfo[] {
    const grouppedInfo = [];

    if (Array.isArray(result)) {
      grouppedInfo.push({
        label: EntityInfoType.methods,
        items: result.map((item) => ({
          key: item,
          value: item,
          type: EntityInfoType.methods,
        })),
      });

      return grouppedInfo;
    }

    for (const info in result) {
      if (info !== EntityInfoType.name) {
        if (!Array.isArray(result[info]) && typeof Object.values(result[info]) === 'object') {
          const relationItems = [];

          for (const [relationKey, relationValue] of Object.entries(result[info])) {
            relationItems.push({
              key: relationKey,
              value: relationValue,
              type: info,
            });
          }

          grouppedInfo.push({
            label: info,
            items: relationItems,
          });
        } else if (Array.isArray(result[info])) {
          grouppedInfo.push({
            label: info,
            items: result[info].map((item) => ({
              key: item,
              value: item,
              type: info,
            })),
          });
        }
      }
    }

    return grouppedInfo;
  }

  private getFromEntitiesList(entity: string): Record<string, EntityInformation[]> {
    for (const [key, value] of this.entities.entries()) {
      if (key === entity) {
        return this.entities.get(key);
      }
    }
  }

  private blurOnSelectUnselectItem(): void {
    interval(0)
      .pipe(take(1))
      .subscribe(() => this.autoComplete.multiContainerEL.nativeElement.blur());
  }
}
