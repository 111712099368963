import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[capTemplate]',
})
export class CapturumTemplateDirective {
  @Input('capTemplate')
  public name: string;

  constructor(public template: TemplateRef<any>) {
  }

  public getType(): string {
    return this.name;
  }
}
