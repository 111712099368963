<div class="wrapper wrapper-content">
  <ng-template [ngxPermissionsOnly]="['setting.manage', 'setting.manage.tenant']">
    <div class="row">
      <div class="col-12">
        <div class="ibox">
          <div class="ibox-title">
            <h5>
              {{ 'config.overview.title' | translate }}
            </h5>
          </div>

          <div class="ibox-content">
            <div class="row">

              <div class="col-12 col-sm-6 col-lg-3 col-xl-2 mb-4 mb-xl-0">
                <div class="config-card" routerLink="/admin/settings">
                  <i class="fas fa-sliders-h"></i>
                  <div class="title">
                    {{ 'config.setting-overview.label' | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template [ngxPermissionsOnly]="allowedPermissions$ | async">
    <div class="row mt-3">
      <div class="col-lg-12">
        <div class="ibox">
          <div class="ibox-title">
            <h5>{{ 'config.manage.title' | translate }}</h5>
          </div>

          <div class="ibox-content">
            <div class="row">
              <ng-container *ngFor="let configItem of configItems$ | async">
                <ng-template [ngxPermissionsOnly]="configItem.permission">
                  <div class="col-12 col-sm-6 col-lg-3 col-xl-2 mb-4" [class.disabled]="configItem.disabled">
                    <div class="config-card" (click)="onConfigItemClick(configItem)">
                      <i [class]="configItem.iconClass"></i>
                      <div class="title">
                        {{ configItem.titleTranslationKey | translate }}
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template ngxPermissionsOnly="base-data-value.manage.tenant">
    <div class="row mt-3">
      <div class="col-12">
        <div class="ibox">
          <div class="ibox-title">
            <h5>
              {{ 'config.base_data.title' | translate }}
            </h5>
          </div>

          <div class="ibox-content">
            <div class="row">
              <ng-container *ngFor="let baseDataKey of baseDataKeys">
                <div class="col-12 col-sm-6 col-lg-3 col-xl-2 mb-4">
                  <div class="config-card" routerLink="/admin/base-data/{{ baseDataKey.id }}/values">
                    <i class="fas fa-database"></i>

                    <div class="title">
                      {{ baseDataKey.key | translate }}
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
