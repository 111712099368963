import { Injectable } from '@angular/core';
import { ListRendererFilterConfigField } from '@capturum/builders/core';
import { ListRendererFilter } from '../models/list-renderer-filter.model';

export type FilterData = Record<string, any>;

@Injectable()
export class ListRendererFiltersService {
  private filterStateKey = `list-renderer-filter`;

  public prepareFilters(configFilters: ListRendererFilterConfigField[]): ListRendererFilter[] {
    return configFilters
      .map((configFilter) => {
        return {
          field: configFilter.name,
          type: configFilter.type,
          options: configFilter.options,
          placeholder: configFilter.placeholder,
          label: configFilter.label,
          icon: configFilter.icon,
          matchMode: configFilter.operator,
          autoDisplayFirst: configFilter.autoDisplayFirst,
          defaultValue: configFilter.default,
        };
      })
      .map((item) => this.extractFilledProperty(item));
  }

  public getActiveFilters(listKey: string): FilterData {
    return JSON.parse(localStorage.getItem(this.getFilterStateKey(listKey)));
  }

  public setFilterState(key: string, filters: FilterData): void {
    localStorage.setItem(this.getFilterStateKey(key), JSON.stringify(filters));
  }

  public clearFilterState(listKey: string): void {
    localStorage.removeItem(this.getFilterStateKey(listKey));
  }

  public getFilterStateKey(listKey: string): string {
    return `${this.filterStateKey}-${listKey}`;
  }

  private extractFilledProperty(item: ListRendererFilter): ListRendererFilter {
    return Object.keys(item).reduce((acc, key) => {
      if (item[key]) {
        acc[key] = item[key];
      }

      return acc;
    }, {} as ListRendererFilter);
  }
}
