import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiHttpService, ApiService } from '@capturum/api';
import { ModuleApiModel } from '../models/module.api-model';
import { ModuleIndexedDbModel } from '../models/module.indexedDb-model';

@Injectable()
export class ModuleService extends ApiService<ModuleApiModel> {
  public endpoint = 'role/module';

  constructor(private api: ApiHttpService) {
    super(api);
  }

  /**
   * Get the modules of the user and store them in the indexedDB
   */
  public loadModules(): Observable<boolean> {
    return new Observable(observer => {
      this.apiHttp
        .get(`/${this.endpoint}`)
        .pipe(map((response: { data: string[] }) => response.data))
        .subscribe(
          async modules => {
            await ModuleIndexedDbModel.query().clear();
            await ModuleIndexedDbModel.query().bulkAdd(modules);
            await ModuleIndexedDbModel.loadModules();
            observer.next(true);
            observer.complete();
          },
          async () => {
            await ModuleIndexedDbModel.loadModules();
            observer.next(true);
            observer.complete();
          }
        );
    });
  }

  /**
   * Retrieve all modules
   */
  public getModuleList(): Observable<ModuleApiModel[]> {
    return this.apiHttp.get('/module/list').pipe(map((response: { data: ModuleApiModel[] }) => response.data));
  }
}
