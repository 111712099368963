@if (config) {
  <nav class="header">
    <div class="header-inner" [hidden]="!config.showHeader">
      <div class="header-inner-container">
        <div class="back-button" [hidden]="!config.backRoute" [routerLink]="[config.backRoute]" [relativeTo]="route">
          <div class="inner-back-button">
            <i [ngClass]="config.backButtonIconClass || backButtonIconClass"> </i>
            <span class="back-to-overview">{{ config.backButtonLabel }}</span>
          </div>
        </div>

        <div class="title-container" [hidden]="!config.title">
          <span class="title">{{ config.title }}</span>
        </div>

        <div class="menu-container" [hidden]="!config.userMenu">
          <ng-container [ngTemplateOutlet]="userMenuTemplate || defaultUserMenu"></ng-container>

          <ng-template #defaultUserMenu>
            <cap-header-tabs-user-menu [config]="config.userMenu"></cap-header-tabs-user-menu>
          </ng-template>
        </div>
      </div>
    </div>

    <div class="header-menu" [hidden]="!config.headerMenuItems">
      <div class="header-menu-left">
        <p-tabMenu
          [model]="config.headerMenuItems"
          [activeItem]="config.headerMenuItems && config.headerMenuItems[selectedIndex]"
          (activeItemChange)="onActiveItemChange($event)"
        ></p-tabMenu>
      </div>

      <div class="header-menu-right">
        <ng-container
          [ngTemplateOutlet]="headerButtonTemplate || configHeaderButton"></ng-container>

        <ng-template #configHeaderButton>
          @if (currentButtonConfig?.visible) {
            <cap-button
              [styleClass]="currentButtonConfig.styleClass || 'primary'"
              (onClick)="execute(currentButtonConfig)"
              [label]="currentButtonConfig.label"
              [icon]="currentButtonConfig.icon"
              [iconPos]="direction.left">
            </cap-button>
          }

          @if (menuButtonItems) {
            <cap-three-dot-menu-button dataTestId="menu-header-button" [items]="menuButtonItems"></cap-three-dot-menu-button>
          }
        </ng-template>
      </div>
    </div>
  </nav>
}
