<div class="popup-content" *ngIf="flow$ | async as flow">
  <div class="flow-statistics-content" [innerHTML]="flow.context"></div>

  <div class="flow-statistics-table" *ngIf="flowTable$ | async as flowTable">
    <cap-info-table
      [data]="flowTable.tableData"
      [columns]="flowTable.columns"
      [pagination]="false"
      (onColumnClick)="columnClick($event)">
    </cap-info-table>
  </div>
</div>
