import { CapturumPageBuilderModule } from '@capturum/builders/page-builder';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApiModule } from '@capturum/api';
import { AuthModule } from '@capturum/auth';
import { CapturumBuilderCoreModule, NavigateAction } from '@capturum/builders/core';
import { CapturumFormRendererModule, FormBuilderModel } from '@capturum/builders/form-renderer';
import { CapturumListRendererModule } from '@capturum/builders/list-renderer';
import { CompleteModule, ErrorMessageInterceptor, TranslationLoader } from '@capturum/complete';
import { CapturumPowerBIModule } from '@capturum/powerbi';
import { CapturumDropdownModule } from '@capturum/ui/dropdown';
import { CapturumToastModule } from '@capturum/ui/toast';
import { CodeEditorModule } from '@ngstack/code-editor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule } from '@ngxs/store';
import * as Sentry from '@sentry/angular-ivy';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import {
  TrafficLightFormFieldComponent,
} from './features/maker/components/traffic-light-form-field/traffic-light-form-field.component';
import {
  TrafficLightWidgetComponent,
} from './features/maker/components/traffic-light-widget/traffic-light-widget.component';
import { IceCreamWidgetComponent } from './ice-cream-widget/ice-cream-widget.component';
import { PepActionService } from './shared/pep-action.service';
import { CapturumFlowModule } from '@capturum/flow';

Sentry.init({
  dsn: environment.sentryUrl,
  environment: environment.name,
});

@NgModule({
  declarations: [
    AppComponent,
    IceCreamWidgetComponent,
  ],
  imports: [
    BrowserModule,
    CodeEditorModule.forRoot(),
    ApiModule.forRoot({
      baseUrl: environment.baseUrl,
      production: environment.production,
      onAuthError: () => {
      },
    }),
    AuthModule.forRoot({
      baseUrl: environment.baseUrl,
      authRefreshToken: {
        useRefreshToken: false,
        offsetSeconds: 60,
        intervalSeconds: 3,
      },
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslationLoader,
      },
    }),
    CompleteModule.forRoot({
      databaseName: 'pep',
      indexedDbModels: [FormBuilderModel],
    }, NgxPermissionsService, NgxPermissionsModule),
    BrowserAnimationsModule,
    CapturumFormRendererModule.forRoot({
      translationKeyPrefix: 'test-app',
      layoutTemplates: {
        pepTemplate: {
          amountOfContentGroups: 1,
          locations: {
            content: {
              containerClass: 'p-grid',
              groupClass: 'p-col-2',
            },
          },
        },
      },
      types: [
        {
          name: 'traffic-light',
          component: TrafficLightFormFieldComponent,
        },
      ],
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
    NgxsModule.forRoot(),
    CoreModule,
    CapturumListRendererModule.forRoot({
      widgets: {
        'ice-cream': IceCreamWidgetComponent,
        'traffic-light': TrafficLightWidgetComponent,
      },
    }),
    CapturumToastModule,
    ConfirmDialogModule,
    NgxPermissionsModule.forRoot(),
    CapturumDropdownModule,
    CapturumBuilderCoreModule.forRoot({
      actions: {
        'my-custom-submit-action': PepActionService,
        'my-custom-cancel-action': PepActionService,
        navigate: NavigateAction,
      },
      baseUrl: '/maker/builder',
      configListUrl: '/config',
    }),
    CapturumPowerBIModule,
    CapturumPageBuilderModule,
    AppRoutingModule,
    CapturumFlowModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorMessageInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
