import { CommonModule } from '@angular/common';
import { Inject, InjectionToken, ModuleWithProviders, NgModule, Optional } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CapturumAccordionModule } from '@capturum/ui/accordion';
import { CapturumSharedModule } from '@capturum/ui/api';
import { CapturumButtonModule } from '@capturum/ui/button';
import { CapturumCalendarModule } from '@capturum/ui/calendar';
import { CapturumCheckboxModule } from '@capturum/ui/checkbox';
import { CapturumDropdownModule } from '@capturum/ui/dropdown';
import { CapturumEditorModule } from '@capturum/ui/editor';
import { CapturumFilePreviewListModule } from '@capturum/ui/file-preview-list';
import { CapturumFileUploadModule } from '@capturum/ui/file-upload';
import { CapturumInputModule } from '@capturum/ui/input';
import { CapturumInputNumberModule } from '@capturum/ui/input-number';
import { CapturumInputSwitchModule } from '@capturum/ui/input-switch';
import { CapturumMultiSelectModule } from '@capturum/ui/multi-select';
import { CapturumTextareaModule } from '@capturum/ui/textarea';
import { FormlyConfig, FormlyModule } from '@ngx-formly/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgxsModule } from '@ngxs/store';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CheckboxModule } from 'primeng/checkbox';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TooltipModule } from 'primeng/tooltip';
import { CapturumPasswordModule } from '@capturum/ui/password';

import { CancelButtonComponent } from './components/cancel-button/cancel-button.component';
import { CapturumBuilderCheckboxInputComponent } from './components/checkbox-input/checkbox-input.component';
import {
  CapturumBuilderCheckboxListInputComponent
} from './components/checkbox-list-input/checkbox-list-input.component';
import {
  CapturumBuilderCheckboxValueInputComponent
} from './components/checkbox-value-input/checkbox-value-input.component';
import { CapturumBuilderDateInputComponent } from './components/date-input/date-input.component';
import { CapturumBuilderDropdownInputComponent } from './components/dropdown-input/dropdown-input.component';
import { CapturumBuilderEditorInputComponent } from './components/editor-input/editor-input.component';
import { CapturumBuilderEmailInputComponent } from './components/email-input/email-input.component';
import {
  EntityTextInputDialogComponent
} from './components/entity-text-input-dialog/entity-text-input-dialog.component';
import { CapturumBuilderEntityTextInputComponent } from './components/entity-text-input/entity-text-input.component';
import { CapturumBuilderFileInputComponent } from './components/file-input/file-input.component';
import { FilePreviewComponent } from './components/file-preview/file-preview.component';
import { FormRendererHeaderComponent } from './components/form-renderer-header/form-renderer-header.component';
import { FormTooltipComponent } from './components/form-tooltip/form-tooltip.component';
import { CapturumBuilderGroupInputComponent } from './components/group-input/group-input.component';
import { CapturumBuilderHiddenTypeComponent } from './components/hidden-input/hidden-input.component';
import { CapturumBuilderImageInputComponent } from './components/image-input/image-input.component';
import { CapturumBuilderLabelInputComponent } from './components/label-input/label-input.component';
import { CapturumMultiSelectInputComponent } from './components/multi-select-input/multi-select-input.component';
import {
  CapturumBuilderNumberFormatInputComponent
} from './components/number-format-input/number-format-input.component';
import { CapturumBuilderNumberInputComponent } from './components/number-input/number-input.component';
import { CapturumBuilderPasswordTypeComponent } from './components/password-type/password-type.component';
import { CapturumBuilderRadioInputComponent } from './components/radio-input/radio-input.component';
import { CapturumReadonlyContainerComponent } from './components/readonly-container/readonly-container.component';
import { CapturumRepeatInputTypeComponent } from './components/repeat/repeat-input-type.component';
import { SubmitButtonComponent } from './components/submit-button/submit-button.component';
import { CapturumBuilderTextInputComponent } from './components/text-input/text-input.component';
import { CapturumBuilderTextareaInputComponent } from './components/textarea-input/textarea-input.component';
import { CapturumBuilderToggleInputComponent } from './components/toggle-input/toggle-input.component';
import { CapturumTooltipComponent } from './components/tooltip/tooltip.component';
import { CapturumBuilderTranslatedInputComponent } from './components/translated-input/translated-input.component';
import { CapturumBuilderUrlInputComponent } from './components/url-input/url-input.component';
import {
  CapturumBuilderValidationMessageComponent
} from './components/validation-message/validation-message.component';
import { defaultLayoutTemplates } from './configs/default-layout-templates.configs';
import { CapturumFormlyConfig } from './configs/formly.config';
import { inputTypeBuilders } from './configs/input-type-builders.config';
import { inputTypeComponents } from './configs/input-type-components.config';
import { inputTypeWrappers } from './configs/input-type-wrappers.config';
import { CapturumFormRendererComponent } from './containers/form-renderer/form-renderer.component';
import { FormRendererConfig, FormRendererConfigService } from './form-renderer.config';
import { CreateObjectUrlPipe } from './pipes/createObjectUrl.pipe';
import { DisplayValueByCurrentLocalePipe } from './pipes/display-value-by-current-locale.pipe';
import { LabelsByValuesPipe } from './pipes/labels-by-values.pipe';
import { OptionsPipe } from './pipes/options.pipe';
import { FormRendererService } from './services/form-renderer.service';
import { FormBuilderState } from './state/form-builder/form-builder.state';
import { FormRendererState } from './state/form-renderer/form-renderer.state';
import { FormFieldsManipulatorUtil } from './utils/form-fields-manipulator.util';
import { CapturumBuilderCheckboxWrapperComponent } from './wrappers/checkbox-wrapper/checkbox-wrapper.component';
import {
  CapturumBuilderCollapsableWrapperComponent
} from './wrappers/collapsable-wrapper/collapsable-wrapper.component';
import {
  CapturumBuilderDefaultInputWrapperComponent
} from './wrappers/default-input-wrapper/default-input-wrapper.component';
import { CapturumBuilderIconInputComponent } from './components/icon-input/icon-input.component';
import { CapturumSkeletonModule } from '@capturum/ui/skeleton';

export const ngxsModule = NgxsModule.forFeature([FormBuilderState, FormRendererState]);

export const formlyModule = FormlyModule.forChild({
  types: inputTypeComponents,
  wrappers: inputTypeWrappers,
});
export const translateModule = TranslateModule;
export const ngxPermissionModule = NgxPermissionsModule.forChild();

export const FORM_RENDERER_CONFIG = new InjectionToken<FormRendererConfig[]>('FORM_RENDERER_CONFIG');

@NgModule({
  declarations: [
    CapturumFormRendererComponent,
    OptionsPipe,
    CapturumBuilderCheckboxInputComponent,
    CapturumBuilderCheckboxListInputComponent,
    CapturumBuilderTextInputComponent,
    CapturumBuilderTextareaInputComponent,
    CapturumBuilderLabelInputComponent,
    CapturumBuilderEmailInputComponent,
    CapturumBuilderDropdownInputComponent,
    CapturumBuilderImageInputComponent,
    CapturumBuilderNumberInputComponent,
    CapturumBuilderDateInputComponent,
    CapturumBuilderGroupInputComponent,
    CapturumBuilderRadioInputComponent,
    CapturumBuilderFileInputComponent,
    CapturumMultiSelectInputComponent,
    CapturumBuilderToggleInputComponent,
    FormTooltipComponent,
    CapturumTooltipComponent,
    CapturumBuilderDefaultInputWrapperComponent,
    LabelsByValuesPipe,
    CapturumReadonlyContainerComponent,
    CapturumRepeatInputTypeComponent,
    CapturumBuilderCollapsableWrapperComponent,
    SubmitButtonComponent,
    CancelButtonComponent,
    FormRendererHeaderComponent,
    FilePreviewComponent,
    CreateObjectUrlPipe,
    CapturumBuilderCheckboxWrapperComponent,
    CapturumBuilderTranslatedInputComponent,
    CapturumBuilderValidationMessageComponent,
    DisplayValueByCurrentLocalePipe,
    CapturumBuilderNumberFormatInputComponent,
    CapturumBuilderEntityTextInputComponent,
    EntityTextInputDialogComponent,
    CapturumBuilderEditorInputComponent,
    CapturumBuilderCheckboxValueInputComponent,
    CapturumBuilderHiddenTypeComponent,
    CapturumBuilderUrlInputComponent,
    CapturumBuilderPasswordTypeComponent,
    CapturumBuilderIconInputComponent,
  ],
  imports: [
    CommonModule,
    TooltipModule,
    FormsModule,
    ReactiveFormsModule,
    CapturumCheckboxModule,
    CapturumDropdownModule,
    CapturumFileUploadModule,
    CapturumSharedModule,
    CapturumTextareaModule,
    CapturumInputSwitchModule,
    CapturumMultiSelectModule,
    CapturumInputModule,
    CapturumButtonModule,
    CheckboxModule,
    translateModule,
    RadioButtonModule,
    CapturumCalendarModule,
    RouterModule,
    CapturumAccordionModule,
    ngxsModule,
    formlyModule,
    FormlyModule,
    ngxPermissionModule,
    CapturumFilePreviewListModule,
    SplitButtonModule,
    CapturumInputNumberModule,
    AutoCompleteModule,
    CapturumEditorModule,
    CapturumPasswordModule,
    CapturumSkeletonModule
  ],
  exports: [
    CapturumFormRendererComponent,
    CapturumReadonlyContainerComponent,
    OptionsPipe,
    FormlyModule,
    CapturumBuilderValidationMessageComponent,
    LabelsByValuesPipe,
  ],
  providers: [
    OptionsPipe,
    FormRendererService,
    {
      provide: FormlyConfig,
      useClass: CapturumFormlyConfig,
    },
    DynamicDialogRef,
  ],
})
export class CapturumFormRendererModule {
  constructor(
    formlyConfig: FormlyConfig,
    private formRendererConfigService: FormRendererConfigService,
    @Optional()
    @Inject(FORM_RENDERER_CONFIG)
    private readonly rendererConfig: FormRendererConfigService[] = []
  ) {
    if (!rendererConfig) {
      return;
    }

    rendererConfig.forEach((config) => {
      formlyConfig.addConfig(config);
      formRendererConfigService.addConfig(config);
    });
  }

  public static forRoot(configs: FormRendererConfig): ModuleWithProviders<CapturumFormRendererModule> {
    let types = [];

    if (configs?.types) {
      types = configs.types.map((type) => {
        type.name = FormFieldsManipulatorUtil.getFormlyTypeName(type.name);

        return type;
      });
    }

    return {
      ngModule: CapturumFormRendererModule,
      providers: [
        {
          provide: FORM_RENDERER_CONFIG,
          useValue: {
            formBuilderApi: configs.formBuilderApi || '/form-builder',
            dynamicForms: configs.dynamicForms || [],
            types: types || [],
            builders: {
              ...inputTypeBuilders,
              ...(configs.builders || {}),
            },
            wrappers: [...inputTypeWrappers, ...(configs.wrappers || [])],
            translationKeyPrefix: configs.translationKeyPrefix || '',
            layoutTemplates: {
              ...defaultLayoutTemplates,
              ...(configs.layoutTemplates || {}),
            },
            defaultEmptyValue: configs.defaultEmptyValue || '',
          },
          multi: true,
        },
      ],
    };
  }

  public static forChild(configs: FormRendererConfig): ModuleWithProviders<CapturumFormRendererModule> {
    return {
      ngModule: CapturumFormRendererModule,
      providers: [
        {
          provide: FORM_RENDERER_CONFIG,
          useValue: {
            builders: {
              ...inputTypeBuilders,
              ...(configs.builders || {}),
            },
          },
          multi: true,
        },
      ],
    };
  }
}

export function formlyFactory(configs: FormRendererConfig): any {
  return FormlyModule.forRoot(configs);
}
