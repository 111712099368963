import { Injectable } from '@angular/core';
import { ApiHttpService } from './api.http-service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiIndexResult, ApiListResult, ApiSingleResult, ListOptions } from '../interfaces/api.interface';
import { RecursiveIteration } from '../utils/recursive-iteration';

@Injectable()
export class ApiService<D> extends RecursiveIteration {
  public static generateQuery(options?: ListOptions): string {
    if (!options) {
      return '';
    }

    const parts = [];

    if (options.include) {
      parts.push('_meta[include]=' + options.include.join(','));
    }

    if (options.exclude) {
      parts.push('_meta[exclude]=' + options.exclude.join(','));
    }

    if (options.page) {
      parts.push('_meta[page]=' + options.page);
    }

    if (options.perPage) {
      parts.push('_meta[perPage]=' + options.perPage);
    }

    if (options.search) {
      if (Array.isArray(options.search) && options.search[0]) {
        parts.push(`_meta[search]=${encodeURIComponent(options.search[0])}`);
      } else {
        parts.push(`_meta[search]=${encodeURIComponent(options.search as string)}`);
      }
    }

    if (options.parameters) {
      options.parameters.forEach(parameter => {
        if (typeof parameter.value === 'object') {
          parts.push(`${parameter.field}=${encodeURIComponent(JSON.stringify(parameter.value))}`);
        } else {
          parts.push(`${parameter.field}=${encodeURIComponent(parameter.value)}`);
        }
      });
    }

    if (options.filters) {
      options.filters.forEach(
        (
          filter: { field: string; value: any; operator: string },
          index: number
        ) => {
          const fieldFilters = options.filters.filter(f => f.field === filter.field);
          let fieldIndex = '';

          if (fieldFilters.length > 1) {
            const i = fieldFilters.findIndex(fieldFilter => fieldFilter === filter);
            fieldIndex = `[${i}]`;
          }

          if (filter.operator !== undefined && filter.operator !== null) {
            parts.push(
              `_meta[filter][${filter.field}]${fieldIndex}[operator]=${filter.operator}`
            );
          }

          if (Array.isArray(filter.value)) {
            filter.value.forEach((value, i) => {
              parts.push(`_meta[filter][${filter.field}]${fieldIndex}[value][${i}]=${encodeURIComponent(value)}`);
            });
          } else {
            parts.push(`_meta[filter][${filter.field}]${fieldIndex}[value]=${encodeURIComponent(filter.value)}`);
          }
        }
      );
    }

    if (options.sort) {
      options.sort.forEach(
        (
          value: { field: string; direction: 'asc' | 'desc' },
          index: number
        ) => {
          parts.push(
            `_meta[sort][${index}][${value.field}]=${value.direction}`
          );
        }
      );
    }

    if (options.has) {
      options.has.forEach(
        (
          has: { relation: string; count?: number; operator?: '=' | '>' | '<' | '>=' | '<=' },
          index: number
        ) => {
          if (has.operator !== undefined && has.operator !== null) {
            parts.push(
              `_meta[has][${has.relation}][operator]=${has.operator}`
            );
          }

          if (has.count !== undefined && has.count !== null) {
            parts.push(
              `_meta[has][${has.relation}][count]=${has.count}`
            );
          }

          if ((has.operator === undefined || has.operator === null) &&
            (has.count === undefined || has.count === null)) {
            parts.push(
              `_meta[has][${has.relation}]`
            );
          }
        }
      );
    }

    if (options.count) {
      parts.push('_meta[count]=' + options.count.join(','));
    }

    return '?' + parts.join('&');
  }

  protected endpoint: string;

  constructor(
    public apiHttp: ApiHttpService
  ) {
    super();

    if (this.apiHttp.getConfig().dateInterceptor) {
      this.dateProperties = this.apiHttp.getConfig().dateInterceptor.dateProperties;
      this.dateFormat = this.apiHttp.getConfig().dateInterceptor.sendFormat;
    }
  }

  public get<T = D>(id: number | string, options?: ListOptions): Observable<T> {
    return this.apiHttp
      .get<ApiSingleResult<T>>(
        `/${this.endpoint}/${id}` + this.getOptionsQuery(options)
      )
      .pipe(map((result: ApiSingleResult<T>) => result.hasOwnProperty('data') ? result.data : result as T));
  }

  public index<T = D>(options?: ListOptions): Observable<ApiIndexResult<T>> {
    return this.apiHttp.get<ApiIndexResult<T>>(
      `/${this.endpoint}` + this.getOptionsQuery(options)
    );
  }

  public list(options?: ListOptions): Observable<ApiListResult> {
    return this.apiHttp.get<ApiListResult>(
      `/${this.endpoint}/list` + this.getOptionsQuery(options)
    );
  }

  public create<T = D>(data: any, options?: ListOptions): Observable<T> {
    return this.apiHttp
      .post<ApiSingleResult<T>>(
        `/${this.endpoint}` + this.getOptionsQuery(options),
        this.recursiveParse(data)
      )
      .pipe(map((result: ApiSingleResult<T>) => result.hasOwnProperty('data') ? result.data : result as T));
  }

  public update<T = D>(data: any, options?: ListOptions): Observable<T> {
    return this.apiHttp
      .put<ApiSingleResult<T>>(
        `/${this.endpoint}/${data.id}` + this.getOptionsQuery(options),
        this.recursiveParse(data)
      )
      .pipe(map((result: ApiSingleResult<T>) => result.hasOwnProperty('data') ? result.data : result as T));
  }

  public updatePatch<T = D>(data: any, options?: ListOptions): Observable<T> {
    return this.apiHttp
      .patch<ApiSingleResult<T>>(
        `/${this.endpoint}/${data.id}` + this.getOptionsQuery(options),
        this.recursiveParse(data)
      )
      .pipe(map((result: ApiSingleResult<T>) => result.hasOwnProperty('data') ? result.data : result as T));
  }

  public delete(id: number | string): Observable<any> {
    return this.apiHttp.delete(`/${this.endpoint}/${id}`);
  }

  public uploadFiles(url: string, files: File[]): Observable<any> {
    return this.apiHttp.uploadFiles(`/${this.endpoint}/${url}`, files);
  }

  public toFormData<T>(formValue: T): FormData {
    const formData = new FormData();
    const keys = Object.keys(formValue);

    for (const key of keys) {
      const value = formValue[key];

      if (Array.isArray(value)) {
        value.forEach(item => formData.append(key, item));
      } else if (typeof value === 'object' && !(value instanceof File)) {
        formData.append(key, JSON.stringify(value));
      } else {
        formData.append(key, value);
      }
    }

    return formData;
  }

  public getOptionsQuery(options?: ListOptions): string {
    return ApiService.generateQuery(options);
  }
}
