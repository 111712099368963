import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@capturum/auth';
import { ToastService } from '@capturum/ui/api';

@Injectable({
  providedIn: 'root',
})
export class ErrorMessageInterceptor implements HttpInterceptor {
  constructor(
    public router: Router,
    public toastService: ToastService,
    public translateService: TranslateService,
    public authService: AuthService,
  ) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        this.handleError(err, request);
        this.catchErrorLogic(err, request);

        return throwError(err);
      }),
    );
  }

  public defaultParsingErrors(err: HttpErrorResponse): void {
    if (this.exceptionUrls(err)) {
      if (err.error && err.error.hasOwnProperty('errors')) {
        const errorKeys = Object.keys(err.error.errors);

        if (errorKeys && errorKeys.length) {
          const firstError = err.error.errors[errorKeys[0]][0];

          this.toastService.error(
            this.translateService.instant('toast.error.title'),
            firstError,
          );
        }
      } else if (err.error && err.error.message) {
        this.toastService.error(
          this.translateService.instant('toast.error.title'),
          err.error.message,
        );
      }
    }
  }

  public exceptionUrls(err: HttpErrorResponse): boolean {
    let response: boolean = false;
    response = (err.status === 422 || err.status === 500) && (err.url.endsWith('/auth/login') || err.url.endsWith('/auth/recovery'));

    return !response;
  }

  public notAuthorizeHandle(err: HttpErrorResponse): void {
    this.authService.logout().subscribe(result => {
      if (result) {
        // Navigate to login
        this.router.navigate([`auth/login`]).then(() => {
          // Hotfix: reload page because of user specific indexDB issue
          window.location.reload();
        });
      }
    });
  }

  public handleError(err: HttpErrorResponse, request?: HttpRequest<any>): void {
    if (err instanceof HttpErrorResponse && err.error instanceof Blob && err.headers.get('Content-Type') === 'application/json') {
      const reader = new FileReader();

      reader.addEventListener('loadend', (e) => {
        const errorFromBlob = { error: JSON.parse(e.srcElement['result']) } as any; /* tslint:disable-line deprecation */

        this.catchErrorLogic(errorFromBlob, request);
      });
      reader.readAsText(err.error);
    }
  }

  public catchErrorLogic(err: HttpErrorResponse, request?: HttpRequest<any>): void {
    switch (err.status) {
      case 401:
        this.notAuthorizeHandle(err);
        break;
      default:
        this.defaultParsingErrors(err);
    }
  }
}
