import { Injectable } from '@angular/core';
import { BaseDataIndexedDbModel, BaseDataKeyIndexedDbModel, BaseDataValueApiModel, BaseDataValueIndexedDbModel } from '@capturum/complete';
import { from, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { BaseDataValueMapItem } from '../models/base-data-value-map-item.model';

@Injectable({
  providedIn: 'root',
})
export class FormBuilderBaseDataService {
  constructor(private translateService: TranslateService) {
  }

  public list(key: string): Observable<BaseDataValueMapItem[]> {
    return from(BaseDataIndexedDbModel.query()
      .where({ key })
      .first())
      .pipe(this.prepareBaseDataValues.bind(this));
  }

  public getBaseDataValuesByKeyAndAttribute(
    key: string,
    attributeKey?: string,
    attributeValue: any = null,
  ): Observable<BaseDataValueMapItem[]> {
    return from(BaseDataValueIndexedDbModel.getByBaseDataKey(`${key}`))
      .pipe(
        map(baseDataValues => {
          const items: BaseDataValueMapItem[] = [];

          baseDataValues.forEach((baseDataValue: any) => {
            if (this.hasAttributeByKeyAndValue(baseDataValue, attributeKey as string, attributeValue) ||
              (!attributeKey && !attributeValue)) {
              items.push({
                ...baseDataValue,
                ...this.convertBaseDataValueToMapItem({
                  id: baseDataValue.id,
                  value: baseDataValue.value,
                } as BaseDataValueApiModel),
              } as BaseDataValueMapItem);
            }
          });

          return items;
        }),
      );
  }

  public getBaseDataValueTranslation(baseDataValue: BaseDataValueApiModel): string {
    return baseDataValue?.id ? this.translateService.instant(`base-data.${baseDataValue.id}`) : null;
  }

  public convertBaseDataValueToMapItem(baseDataValue: BaseDataValueApiModel): BaseDataValueMapItem {
    return {
      label: this.getBaseDataValueTranslation(baseDataValue),
      value: baseDataValue.id,
      value_key: baseDataValue.value,
    };
  }

  public getById(id: string): Observable<BaseDataValueMapItem> {
    const result = BaseDataValueIndexedDbModel.query().where({ id }).first();

    return from(result).pipe(map(data => ({ ...data, label: this.getBaseDataValueTranslation(data) })));
  }

  private hasAttributeByKeyAndValue(baseDataValue: any, attributeKey: string, attributeValue: string): boolean {
    if (attributeKey && attributeValue && (!baseDataValue.attributes && baseDataValue.attributes.length)) {
      return !!baseDataValue.attributes.find((attribute: any) => {
        if (attribute.attribute.data_type.type === 'boolean') {
          attribute.value = attribute.value === 'true';
        }

        return attribute.attribute.key === attributeKey && attribute.value === attributeValue;
      });
    }

    return false;
  }

  private prepareBaseDataValues(source: Observable<any>): Observable<BaseDataValueMapItem[]> {
    const self = this;

    return new Observable(subscriber => {
      source.subscribe({
        next(baseDataKey: BaseDataKeyIndexedDbModel[]): void {
          if (baseDataKey?.values) {
            /* @ts-ignore */
            subscriber.next(self.unflattenBaseDataValueChildren(baseDataKey.values.map((baseDataValue: BaseDataValueApiModel) => {
              return {
                ...self.convertBaseDataValueToMapItem(baseDataValue),
                parent_id: baseDataValue.parent_id,
                attributes: baseDataValue.attributes,
              };
            })));
          } else {
            subscriber.next([]);
          }

          subscriber.complete();
        },
      });
    });
  }

  private unflattenBaseDataValueChildren(list: BaseDataValueApiModel[]): BaseDataValueApiModel[] {
    const children = [];

    for (const item of list) {
      if (item.parent_id) {
        continue;
      }

      const groupItem: (BaseDataValueApiModel & { children: BaseDataValueApiModel[] }) = { ...item, children: [] };

      for (const subItem of list) {
        if (subItem.parent_id !== groupItem.value) {
          continue;
        }

        if (subItem) {
          groupItem.children.push({ ...subItem });
        }
      }

      children.push(groupItem);
    }

    return children;
  }
}
