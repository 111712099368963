import { Injectable } from '@angular/core';
import { combineLatest, Observable, throwError } from 'rxjs';
import { FormRendererState } from '../state/form-renderer/form-renderer.state';
import { FormBuilderConfig } from '../models/form-builder-config.model';
import { Store } from '@ngxs/store';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { BuilderApiService, CapturumBuilderActionService } from '@capturum/builders/core';
import { SetFormValidationErrors, SetSubmitted } from '../state/form-renderer/form-renderer.actions';
import { FormSaverResponse } from '../interfaces/form-saver-response.interface';

@Injectable({ providedIn: 'root' })
export class FormSaverService {
  constructor(
    private readonly store: Store,
    private actionService: CapturumBuilderActionService,
    private builderApiService: BuilderApiService,
  ) {
  }

  public submit<T = any>(key: string, additionalValue: Object = {}): Observable<FormSaverResponse> {
    return combineLatest([
      this.getFormValueByKey(key),
      this.getFormConfigurationByKey(key),
    ]).pipe(
      tap(() => this.markFormAsSubmitted(key)),
      switchMap(([value, configuration]) => {
        return this.submitToBackend(configuration.submit.endpoint, { ...value, ...additionalValue }, key);
      }),
      switchMap((response) => this.getFormConfigurationByKey(key).pipe(
        map((config) => {
          return { config, response };
        }))),
      tap(({ config, response }) => {
        const body = response.data || response;

        this.actionService.executeAction(config.callbacks.submit, body, key);
      }),
    );
  }

  public cancel(key: string): void {
    const config = this.store.selectSnapshot(FormRendererState.configurationByKey(key));
    const value = this.store.selectSnapshot(FormRendererState.sourceValueByKey(key));

    this.actionService.executeAction(config.callbacks.cancel, value, key);
  }

  public submitToBackend(endpoint: string, value: any, key: string, contextKey?: string): Observable<any> {
    return this.builderApiService.post(key, endpoint, value).pipe(
      catchError(({ error }) => {
        if (error) {
          this.setValidationErrors(error, key);
        }

        return throwError(error);
      }),
    );
  }

  public markFormAsSubmitted(key: string): void {
    this.store.dispatch(new SetSubmitted(key));
  }

  public getFormValueByKey<T = any>(key: string): Observable<T> {
    return this.store.select(FormRendererState.formValueByKey(key)).pipe(take(1));
  }

  public getFormConfigurationByKey(key: string): Observable<FormBuilderConfig> {
    return this.store.select(FormRendererState.configurationByKey(key)).pipe(take(1));
  }

  public setValidationErrors(error: { errors: Record<string, string[]> }, key: string): void {
    const keys = error.errors && Object.keys(error.errors);

    if (keys && keys.length) {
      this.store.dispatch(new SetFormValidationErrors(key, error.errors));
    }
  }

  public getValidationErrors(key: string): Observable<Record<string, string[]>> {
    return this.store.select(FormRendererState.validationErrorsByKey(key));
  }
}
