import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, ViewEncapsulation } from '@angular/core';
import { CalendarFilterConfigItem, DynamicFilterComponent } from '../../interfaces/dynamic-filter.interface';
import { FormControl } from '@angular/forms';
import { DateFormat, FilterMatchMode } from '@capturum/ui/api';
import { DatePipe } from '@angular/common';

type CalendarValue = string[] | string;

@Component({
  selector: 'cap-calendar-filter',
  templateUrl: './calendar-filter.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarFilterComponent implements DynamicFilterComponent<CalendarValue> {
  public updateValue: EventEmitter<CalendarValue> = new EventEmitter<CalendarValue>();
  @Input()
  public filterConfig: CalendarFilterConfigItem;
  public formControl = new FormControl();

  constructor(private cdr: ChangeDetectorRef, private datePipe: DatePipe) {}

  public setValue(value: CalendarValue): void {
    this.formControl.setValue(value);
    this.cdr.detectChanges();
  }

  public reset(): void {
    this.formControl.setValue(null);
    this.updateValue.emit(this.formControl.value);
  }

  public handleDateFilterChange(filterMatchMode: FilterMatchMode | string): void {
    if (filterMatchMode === FilterMatchMode.BETWEEN || filterMatchMode === FilterMatchMode.DATEBETWEEN) {
      const dateValues: string[] = this.formControl.value.map((date) =>
        this.datePipe.transform(date, DateFormat.generalReverse)
      );

      if (dateValues.length === 2 && dateValues.every(Boolean)) {
        this.updateValue.emit(dateValues);
      }
    } else {
      this.updateValue.emit(this.datePipe.transform(this.formControl.value, DateFormat.generalReverse));
    }

    this.cdr.detectChanges();
  }

  public updateView(): void {
    this.cdr.detectChanges();
  }
}
