import { PublicSettingResolver } from './shared/resolvers/public-setting.resolver';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPermissionsGuard, NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { CompleteConfig, CompleteConfigInterface } from './complete-config';
import { PermissionModule } from './domain/permission/permission.module';
import { SettingModule } from './domain/setting/setting.module';
import { SettingPipe } from './domain/setting/pipes/setting.pipe';
import { IfSettingDirective } from './domain/setting/directives/if-setting.directive';
import { SettingColorDirective } from './domain/setting/directives/setting-color.directive';
import { IndexedDbModule } from './shared/indexedDb/indexedDb.module';
import { SharedModule } from './shared/shared.module';
import { CompleteRoutingModule } from './complete-routing.module';
import { ConfigOverviewComponent } from './domain/setting/pages/config-overview/config-overview.component';
import { TenantModule } from './domain/tenant/tenant.module';
import { BaseDataModule } from './domain/base-data/base-data.module';
import { RoleModule } from './domain/role/role.module';
import { TranslationModule } from './domain/translation/translation.module';
import { UserModule } from './domain/user/user.module';
import { IndexedDbService } from './shared/indexedDb/indexedDb.service';
import { IndexedDbGuard } from './shared/indexedDb/indexedDb.guard';
import { TenantService } from './domain/tenant/services/tenant.service';
import { SettingService } from './domain/setting/services/setting.service';
import { CompleteConfigService } from './domain/setting/services/complete-config.service';
import { BaseDataKeyService } from './domain/base-data/services/base-data-key.service';
import { BaseDataValueService } from './domain/base-data/services/base-data-value.service';
import { BaseDataService } from './domain/base-data/services/base-data.service';
import { TranslationService } from './domain/translation/services/translation.service';
import { LocaleService } from './domain/locale/services/locale.service';
import { ModuleService } from './domain/module/services/module.service';
import { PermissionService } from './domain/permission/services/permission.service';
import { RoleService } from './domain/role/services/role.service';
import { UserService } from './domain/user/user.service';
import { UtilService } from './shared/services/util.service';
import { SyncService } from './shared/services/sync.service';
import { CompleteResolver } from './shared/resolvers/complete.resolver';
import { TranslationResolver } from './domain/translation/resolvers/translation.resolver';
import { SettingResolver } from './domain/setting/resolvers/setting.resolver';
import { ModuleResolver } from './domain/module/resolvers/module.resolver';
import { PermissionResolver } from './domain/permission/resolvers/permission.resolver';
import { BaseDataResolver } from './domain/base-data/resolvers/base-data.resolver';
import { PublicTranslationResolver } from './shared/resolvers/public-translation.resolver';
import { ConnectivityService } from './shared/services/connectivity.service';
import { DataService } from './shared/services/data.service';
import { TranslationKeyService } from './domain/translation';
import { MultiFactorFormComponent } from './domain/user/components/multi-factor-form/multi-factor-form.component';
import { MultiFactorDialogComponent } from './domain/user/components/multi-factor-dialog/multi-factor-dialog.component';
import { MultiFactorDisableFormComponent, MultiFactorEnableFormComponent } from './domain/user';

@NgModule({
  declarations: [
    SettingPipe,
    SettingColorDirective,
    IfSettingDirective,
    ConfigOverviewComponent,
  ],
  imports: [
    SharedModule,
    CommonModule,
    IndexedDbModule,
    PermissionModule,
    RouterModule,
    NgxPermissionsModule.forChild(),
    TranslateModule,
    CompleteRoutingModule,
    BaseDataModule,
    RoleModule,
    SettingModule,
    TenantModule,
    TranslationModule,
    UserModule,
  ],
  exports: [
    SettingPipe,
    IfSettingDirective,
    SettingColorDirective,
    ConfigOverviewComponent,
    MultiFactorFormComponent,
    MultiFactorDialogComponent,
    MultiFactorEnableFormComponent,
    MultiFactorDisableFormComponent,
  ],
  providers: [
    NgxPermissionsGuard,
  ],
})
export class CompleteModule {
  public static forRoot(
    config: Partial<CompleteConfigInterface>,
    ngxPermissionService: Type<NgxPermissionsService>,
    module: Type<NgxPermissionsModule>): ModuleWithProviders<CompleteModule> {
    return {
      ngModule: CompleteModule,
      providers: [
        IndexedDbService,
        IndexedDbGuard,
        TenantService,
        SettingService,
        BaseDataKeyService,
        BaseDataValueService,
        TranslationService,
        CompleteConfigService,
        BaseDataService,
        LocaleService,
        ModuleService,
        PermissionService,
        RoleService,
        UserService,
        UtilService,
        SyncService,
        CompleteResolver,
        TranslationResolver,
        BaseDataResolver,
        SettingResolver,
        ModuleResolver,
        PermissionResolver,
        PublicTranslationResolver,
        PublicSettingResolver,
        ConnectivityService,
        DataService,
        {
          provide: CompleteConfig,
          useValue: { ...new CompleteConfig(), ...config },
        },
        {
          provide: NgxPermissionsService,
          useClass: ngxPermissionService || NgxPermissionsService,
        },
        {
          provide: NgxPermissionsModule,
          useValue: module,
        },
        {
          provide: BaseDataService,
          useClass: config.baseDataService || BaseDataService,
        },
        {
          provide: BaseDataValueService,
          useClass: config.baseDataValueService || BaseDataValueService,
        },
        {
          provide: BaseDataKeyService,
          useClass: config.baseDataKeyService || BaseDataKeyService,
        },
        {
          provide: TranslationKeyService,
          useClass: config.translateKeyService || TranslationKeyService,
        },
        {
          provide: PermissionService,
          useClass: config.permissionService || PermissionService,
        },
        {
          provide: ModuleService,
          useClass: config.moduleService || ModuleService,
        },
        {
          provide: SettingService,
          useClass: config.settingService || SettingService,
        },
      ],
    };
  }
}
