<cpb-builder-detail-template title="Create page"
                             [key]="pageConfigKey"
                             [configId]="configId"
                             [contentRightTemplate]="rightTemplate"
                             [type]="'page'"
                             (onSubmit)="submitConfig()"
                             (configChange)="handleConfigChange($event)">
  <div class="page-preview">
    <div cdkDropList cpbDragDropManager class="widget-content-list"
          [id]="rootDropListId"
          (cdkDropListDropped)="widgetDropped($event)"
          [cdkDropListData]="config?.content">
      <ng-container *ngIf="config?.content as widgets">
        <cpb-page-builder-widget *ngFor="let widget of widgets; let index= index"
                                  [widget]="widget"
                                  [index]="index"
                                  (widgetDrop)="widgetDropped($event)"
                                  (editWidget)="handleEditWidget($event)"
                                  (removeWidget)="handleRemoveWidget($event)">
        </cpb-page-builder-widget>
      </ng-container>

      <div class="empty-row-widget-message" *ngIf="config?.content.length === 0">
        <span>
          {{ 'builders.ui.form.drag-drop-message' | translate }}
        </span>
      </div>
    </div>

    <ng-template #rightTemplate>
      <cpb-builder-card [title]="'builders.ui.page.add-widgets' | translate">
        <div class="widget-options-list" cdkDropList cpbDragDropManager
            *ngIf="widgetTypeList$ | async as widgetTypeList"
            [id]="widgetOptionsListId"
            [cdkDropListData]="widgetTypeList"
            cdkDropListSortingDisabled>
          <div class="widget-options-list-item" cdkDrag
               *ngFor="let widget of widgetTypeList"
               [cdkDragData]="widget.value">
            <div class="label">{{ widget.value }}</div>
            <i class="fas fa-arrows" cdkDragHandle></i>
          </div>
        </div>
      </cpb-builder-card>

    </ng-template>
  </div>

  <ng-container *ngIf="widgetConfiguration$ | async as widgetConfiguration">
    <cap-sidebar [(visible)]="showSidebar" [title]="widgetConfiguration.title" [style]="{'width': '470px'}">
      <cpb-form-renderer #rendererComponent
                        [showHeader]="false"
                        [formConfiguration]="widgetConfiguration"
                        [modelId]="activeWidget?.id"></cpb-form-renderer>

      <ng-template capTemplate="footer">
        <div class="save-actions">
          <cap-button [label]="'button.submit' | translate"
                      (onClick)="submitWidgetConfiguration({ value: rendererComponent.form.value, formConfig: widgetConfiguration })"
                      styleClass="success">
          </cap-button>

          <cap-button [label]="'button.cancel' | translate"
                      styleClass="secondary"
                      (onClick)="cancelWidgetConfiguration()">
          </cap-button>
        </div>
      </ng-template>
    </cap-sidebar>
  </ng-container>
</cpb-builder-detail-template>
