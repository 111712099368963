import { FieldArrayType } from '@ngx-formly/core';
import { Component, OnInit } from '@angular/core';
import { FormRendererService } from '../../services/form-renderer.service';
import { defaultLayoutTemplates } from '../../configs/default-layout-templates.configs';
import { FormRendererConfigService } from '../../form-renderer.config';

@Component({
  selector: 'cpb-repeat-input-type',
  template: `
    <ng-container *ngIf="repeatReady">
      <div *ngFor="let fieldGroupField of field.fieldGroup; let index = index; let first = first"
           class="repeat-field-row">

        <formly-field class="repeat-field-form" [field]="fieldGroupField"></formly-field>

        <div class="repeat-field-delete">
          <i class="fas fa-trash-alt repeat-field-delete-icon" *ngIf="!first && !to.hideDeleteButton"
             (click)="remove(index)"></i>
        </div>
      </div>

      <div class="repeat-add-button" *ngIf="!to.hideAddButton">
        <cap-button styleClass="primary" (click)="add()" [label]="to.addButtonText" [icon]="to.addButtonIcon"></cap-button>
      </div>
    </ng-container>
  `,
  styleUrls: ['./repeat-input-type.component.scss'],
})
export class CapturumRepeatInputTypeComponent extends FieldArrayType implements OnInit {
  public repeatReady: boolean;

  constructor(
    private readonly formRendererService: FormRendererService,
    private readonly formRendererConfig: FormRendererConfigService,
  ) {
    super();
  }

  public ngOnInit(): void {
    const configurations = this.field.props.configuration;
    const templates = { ...defaultLayoutTemplates, ...this.formRendererConfig.layoutTemplates };
    const template = templates[configurations.options?.form?.template];
    const fields = configurations.options.form.groups.reduce((acc, group) => {
      const inputs = group.inputs.map((inputConfiguration) => {
        const inputField = this.formRendererService.getInputByConfigs(inputConfiguration, '', [{}]);

        if (template) {
          inputField.className = template.locations.content.groupClass;
        }

        return inputField;
      });

      return [...acc, ...inputs];
    }, []);

    this.field.fieldArray = { fieldGroup: fields, fieldGroupClassName: template?.locations.content?.containerClass || null };

    if (!this.field.defaultValue || !this.field?.defaultValue?.length) {
      this.add();
    }

    this.repeatReady = true;
  }
}
