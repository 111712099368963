import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToastPosition } from '@capturum/ui/api';

@Component({
  selector: 'cap-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class CapturumToastComponent {
  /**
   * Key to match the key of a message to display.
   */
  @Input()
  public key: string;
  /**
   * Position of the component, valid values are "top-right", "top-left", "bottom-left", "bottom-right", "top-center, "bottom-center" and "center".
   */
  @Input()
  public position: ToastPosition = ToastPosition.TOP_RIGHT;
  /**
   * Style class of the component.
   */
  @Input()
  public styleClass: string = '';
  /**
   * Callback to invoke when a message is closed.
   */
  @Output()
  public onClose = new EventEmitter<void>();
}
