import { Injectable } from '@angular/core';
import { InputTypeBuilder } from '../input/input-type.builder';
import { FormInputConfig } from '../../interfaces/form-input-config';
import { InputType } from '@capturum/builders/core';
import { InputConfiguration } from '@capturum/builders/core';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Injectable({ providedIn: 'root' })
export class CheckboxValueInputTypeBuilder extends InputTypeBuilder implements FormInputConfig<InputConfiguration> {
  protected inputType: string = InputType.checkbox;

  protected appendOptions(input: FormlyFieldConfig, configuration: InputConfiguration): void {
    input.wrappers = ['checkbox-wrapper'];
    input.props.trueValue = configuration.options?.trueValue || true;
    input.props.falseValue = configuration.options?.falseValue || false;
  }

  protected setDefaultValue(input: FormlyFieldConfig, value: any): void {
    if (value) {
      input.defaultValue = value[input.key as string] ?? null;
    } else {
      input.defaultValue = input.defaultValue ?? false;
    }
  }
}
