import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Validators } from '@angular/forms';
import { LabelPosition } from '@capturum/builders/core';

@Component({
  selector: 'cpb-email-input',
  templateUrl: './email-input.component.html',
})
export class CapturumBuilderEmailInputComponent extends FieldType implements OnInit {
  public labelPosition: typeof LabelPosition = LabelPosition;

  public ngOnInit(): void {
    this.formControl.setValidators(Validators.email);
  }
}
