import { IndexedDbModel } from '@capturum/complete';

export class FormBuilderModel extends IndexedDbModel {
  public entity = 'form-builder';
  public table = 'form_builder';
  public schema = 'key';
  public sync = true;

  public key!: string;

  constructor(attributes: any) {
    super(attributes);
  }
}
