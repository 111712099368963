<div class="form-element">
  <label
    *ngIf="(label || hasTooltip) && !floatLabel"
    [style]="{ 'justify-content': label ? 'space-between' : 'flex-end' }">
    <span *ngIf="label">{{ label }}</span>

    <ng-content select=".tooltip-content"></ng-content>
  </label>

  <ng-container *ngIf="!floatLabel; else floatingInput">
    <ng-container *ngTemplateOutlet="defaultTemplate"></ng-container>
  </ng-container>

  <ng-template #floatingInput>
    <span class="p-float-label dropdown-float-label">
      <ng-container *ngTemplateOutlet="defaultTemplate"></ng-container>
      <label class="cpb-float-label" htmlFor="float-label">{{ label }}</label>
    </span>
  </ng-template>

  <ng-template #defaultTemplate>
    <p-dropdown
      (onChange)="onChangeEvent($event)"
      (onBlur)="onBlur($event)"
      (onFocus)="onFocus($event)"
      (onClick)="onClick($event)"
      (onHide)="hide.emit($event)"
      (onShow)="show.emit($event)"
      (focus)="onFocus($event)"
      [(ngModel)]="value"
      [appendTo]="appendTo"
      [class.readonly]="readonly"
      [filterBy]="filterBy"
      [filterPlaceholder]="filterPlaceholder"
      [autoDisplayFirst]="autoDisplayFirst"
      [filter]="filter"
      [emptyMessage]="emptyMessage || ('table.no_results' | translate)"
      [emptyFilterMessage]="emptyFilterMessage || ('table.no_results' | translate)"
      [optionLabel]="optionLabel"
      [options]="options"
      [panelStyleClass]="'cap-dropdown-panel ' + panelStyleClass"
      [panelStyle]="panelStyle"
      [editable]="editable"
      [placeholder]="placeholder"
      [scrollHeight]="scrollHeight"
      [resetFilterOnHide]="resetFilterOnHide"
      [showClear]="showClear"
      [group]="group"
      [styleClass]="'cap-dropdown ' + styleClass"
      [virtualScroll]="virtualScroll"
      [virtualScrollItemSize]="virtualScrollItemSize"
      [disabled]="disabled"
      [dropdownIcon]="dropdownIcon"
      [readonly]="readonly"
      [optionValue]="optionValue"
      [tabindex]="tabindex"
      [autofocus]="autofocus"
      [autofocusFilter]="autofocusFilter"
      [style]="style">
      <ng-container *ngIf="itemTemplate || withImages || withIcons">
        <ng-template pTemplate="item" let-item>
          <ng-container
            [ngTemplateOutlet]="itemTemplate || (withImages && itemWithImages) || (withIcons && itemWithIcon)"
            [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
        </ng-template>
      </ng-container>

      <ng-container *ngIf="withIcons">
        <ng-template pTemplate="selectedItem" let-item>
          <ng-container
            [ngTemplateOutlet]="selectedItemWithIcon"
            [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
        </ng-template>
      </ng-container>

      <ng-container *ngIf="selectedItcapTemplate || withImages">
        <ng-template pTemplate="selectedItem" let-item>
          <ng-container
            [ngTemplateOutlet]="selectedItcapTemplate ? selectedItcapTemplate : selectedItemWithImages"
            [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
        </ng-template>
      </ng-container>
    </p-dropdown>
  </ng-template>

  <ng-template #selectedItemWithImages let-item>
    <div class="selected-item-container">
      <img [src]="item?.url" class="selected-img" />
      <span class="selected-label">{{ customLabelProperty ? item[customLabelProperty] : item?.label }}</span>
    </div>
  </ng-template>

  <ng-template #selectedItemWithIcon let-item>
    <div class="selected-item-container">
      <i [ngClass]="[item?.icon, 'selected-icon']"></i>
      <span class="selected-label-icon">{{ customLabelProperty ? item[customLabelProperty] : item?.label }}</span>
    </div>
  </ng-template>

  <ng-template #itemWithImages let-item>
    <div class="custom-item">
      <img [src]="item?.url" class="custom-img" />
      <div class="custom-label">{{ item?.label }}</div>
    </div>
  </ng-template>

  <ng-template #itemWithIcon let-item>
    <div class="cap-dropdown-item-with-icon">
      <i [ngClass]="[item?.icon || '', 'item-icon']"></i>
      <span class="cap-dropdown-item-with-icon-label">{{
        customLabelProperty ? item[customLabelProperty] : item?.label
      }}</span>
    </div>
  </ng-template>

  <cap-validator *ngIf="control" [control]="control" [customName]="label"></cap-validator>
</div>
