import { AfterContentChecked, Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { FormBuilderGroup } from '../../models/form-builder-group.model';

@Component({
  selector: 'cpb-group-input',
  templateUrl: './group-input.component.html',
})
export class CapturumBuilderGroupInputComponent extends FieldType implements AfterContentChecked {
  public field!: FormBuilderGroup;
  public hidden!: boolean;

  public ngAfterContentChecked(): void {
    if (this.field.fieldGroup) {
      this.hidden = this.field.fieldGroup.every(field => field.hide);
    }
  }
}
