<div class="translation-toggler">
  <div class="d-flex flex-column w-100">
    <cap-input-switch
      class="d-flex w-100"
      [(ngModel)]="editMode"
      [disabled]="editMode"
      [label]="'translation.edit.label' | translate"
      (change)="toggleEditMode($event.checked)">
    </cap-input-switch>
  </div>
</div>

<cap-loader *ngIf="loading$ | async"></cap-loader>
