<ng-container *ngIf="to.options$.asObservable() | async as options">
  <div [hidden]="to.readonly">
    <cap-checkbox *ngFor="let option of options"
                  [label]="option.value"
                  (change)="setChecked(option, $event.checked)">
    </cap-checkbox>
  </div>

  <cpb-readonly-container [hidden]="!to.readonly">
    {{ (readonlyString | async) | labelsByValue : options }}
  </cpb-readonly-container>
</ng-container>
