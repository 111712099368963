import { Type } from '@angular/core';
import { BuilderAction } from './builder-action.interface';
import { Observable } from 'rxjs';

export interface ActionProvider {
  execute(options: ActionOptions, item: any, context?: Record<string, any>): Observable<any> | any | void;
}

export interface ActionConfig {
  [key: string]: Type<ActionProvider>;
}

export interface ActionOptions {
  configurationKey: string;
  action: BuilderAction;
  contextKey?: string;

  [key: string]: any;
}
