import { Injectable } from '@angular/core';
import { FileInputTypeBuilder } from '../file/file-input-type.builder';
import { ImageInputConfiguration } from '../../models/image-input-configuration.model';
import { FormInputConfig } from '../../interfaces/form-input-config';
import { InputType } from '@capturum/builders/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FileInputConfiguration } from '../../models/file-input-configuration.model';

@Injectable({ providedIn: 'root' })
export class ImageInputTypeBuilder extends FileInputTypeBuilder implements FormInputConfig<ImageInputConfiguration> {
  protected inputType: string = InputType.image;

  protected appendOptions(input: FormlyFieldConfig, configuration: FileInputConfiguration): void {
    super.appendOptions(input, configuration);

    if (!input.props.availableExtensions || input.props.availableExtensions === '.') {
      input.props.availableExtensions = 'image/*';
    }
  }
}
