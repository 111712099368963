<div class="d-flex">
  <cap-checkbox [hidden]="to.readonly" [formControl]="formControl" [attr.data-test]="'builder-' + field.key">
  </cap-checkbox>

  <cpb-readonly-container [hidden]="!to.readonly" [attr.data-test]="'builder-readonly-' + field.key">
    {{ !!formControl.value ? ((to.activeTranslationKey || 'builders.checkbox.active') | translate) : ((to.inactiveTranslationKey || 'builders.checkbox.inactive') | translate) }}
  </cpb-readonly-container>
</div>

<span *ngIf="to?.description" class="small-description">{{ to?.description }}</span>
