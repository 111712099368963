<cap-input-number [formControl]="formControl"
                  [locale]="props?.locale_format"
                  [prefix]="props?.prefix"
                  [attr.data-test]="'builder-' + field.key"
                  [suffix]="props?.suffix"
                  [useGrouping]="props?.group_thousands"
                  [minFractionDigits]="props?.decimals || 0"
                  [maxFractionDigits]="props?.decimals || 0"
                  [disabled]="props?.readonly"
                  [selectOnFocus]="props?.select_on_focus"
                  [min]="props?.min"
                  [max]="props?.max"
                  [styleClass]="props?.readonly ? 'cap-number-format-readonly' : ''"
                  [autocomplete]="props?.autocomplete"
                  [readonly]="props.readonly">
</cap-input-number>
