import { Component, ElementRef, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'cap-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class CapturumFileUploadComponent {
  @Input() public id: string;
  @Input() public accept: string;
  @Input() public maxSize: number = 0; // 0 (default) to disable limit, in bytes
  @Input() public label: string = 'Upload';
  @Input() public styleClass: string | string[];
  @Input() public multiple: boolean = false;
  @Input() public uploaderContent: TemplateRef<string>;
  @Output() public onFileChange = new EventEmitter<File[]>();

  public dragover: boolean;
  @ViewChild('inputRef', { static: false }) public inputRef: ElementRef;

  /**
   * Set the dragover property to true when dragging a file over the component
   *
   * @param event
   */
  public onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();

    this.dragover = true;
  }

  /**
   * Set the dragover property to false when leaving the component while dragging a file
   *
   * @param event
   */
  public onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();

    this.dragover = false;
  }

  /**
   * Emit drop event when a file is dropped onto the component
   *
   * @param event
   */
  public onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.dragover = false;

    const { dataTransfer: { files } }: { dataTransfer: { files: FileList } } = event;

    this.handleFilesChange(files);
  }

  /**
   * Emit change event when file is selected
   *
   * @param event
   */
  public onChange(event: any): void {
    const { target: { files } }: { target: { files: FileList } } = event;

    this.handleFilesChange(files);
  }

  private handleFilesChange(files: FileList): void {
    if (files?.length > 0 && this.areFileSizesValid(files)) {
      this.onFileChange.emit(Array.from(files));

      this.inputRef.nativeElement.value = '';
    }
  }

  private areFileSizesValid(files: FileList): boolean {
    if (this.maxSize === 0) {
      return true;
    }

    return Array.from(files)
      .every(file => file.size <= this.maxSize);
  }
}
