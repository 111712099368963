import { ChangeDetectorRef, Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'em-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageTypeComponent extends FieldType {
  public loading: boolean;
  public previewImage: string | ArrayBuffer;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  public readURL(event: any): void {
    if (event.target.files && event.target.files[0]) {
      this.loading = true;
      const reader = new FileReader();

      reader.onload = (onLoadEvent: any) => {
        this.previewImage = reader.result;
        this.formControl.setValue(reader.result);
        this.changeDetectorRef.detectChanges();
      };

      reader.onloadend = () => {
        this.loading = false;
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  }

  public deleteImage(): void {
    this.previewImage = null;
    this.formControl.setValue(null);
    this.changeDetectorRef.detectChanges();
  }
}
