import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewEncapsulation, inject } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CapturumSharedModule } from '@capturum/ui/api';
import { CommonModule, DatePipe } from '@angular/common';
import { CapturumDropdownModule } from '@capturum/ui/dropdown';
import { parse, apply, toString } from 'duration-fns';
import { DynamicFilterComponent, FilterConfigItem } from '../../interfaces/dynamic-filter.interface';
import { CapturumDynamicFilterLayoutComponent } from '../dynamic-filter-layout/dynamic-filter-layout.component';
import { intervalToDuration, isBefore, isValid } from 'date-fns';

@Component({
  selector: 'cap-date-time-dynamic-duration-filter',
  templateUrl: './date-time-duration-filter.component.html',
  styleUrls: ['./date-time-duration-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    CapturumDropdownModule,
    FormsModule,
    ReactiveFormsModule,
    CapturumSharedModule,
    CapturumDynamicFilterLayoutComponent,
  ],
  providers: [DatePipe],
})
export class CapturumDateTimeDurationFilterComponent implements DynamicFilterComponent<string | string[]> {
  @Input()
  public formControl = new FormControl();
  @Input()
  public showReset = true;
  @Input()
  public showLabel: boolean;
  @Input()
  public filterConfig: FilterConfigItem;

  @Output()
  public updateValue = new EventEmitter<string | string[]>();

  private cdr = inject(ChangeDetectorRef);
  private datePipe = inject(DatePipe);
  private duration: string;

  public setValue(value: string | string[]): void {
    let storedValue = null;

    // convert date array to duration string
    if (Array.isArray(value) && value.length > 1) {
      const startDate = new Date(value[0]).toISOString();
      const endDate = new Date(value[1]).toISOString();

      if (isValid(new Date(startDate)) && isValid(new Date(endDate))) {
        const intervalDuration = intervalToDuration({
          start: new Date(startDate),
          end: new Date(endDate),
        });

        storedValue = isBefore(new Date(startDate), new Date(endDate))
          ? `-${toString(intervalDuration)}`
          : toString(intervalDuration);
      }
    }

    this.formControl.setValue(storedValue);
    this.cdr.detectChanges();
  }

  public reset(): void {
    this.formControl.reset();
    this.updateValue.emit(null);
  }

  public changeSelection(duration: string): void {
    this.duration = duration;

    const dateNow = new Date().toISOString();
    const dateTo = this.datePipe.transform(dateNow, 'yyyy-MM-dd HH:mm:ss', '+0000');
    const dateFrom = this.datePipe.transform(apply(dateNow, parse(this.duration)), 'yyyy-MM-dd HH:mm:ss', '+0000');

    this.updateValue.emit([dateFrom, dateTo]);
  }

  public updateView(): void {
    this.cdr.detectChanges();
  }
}
