<cap-textarea [formControl]="formControl"
              [floatLabel]="(to.label_position | observablePipe | async ) === labelPosition.Floating"
              [label]="(to.label_position | observablePipe | async ) === labelPosition.Floating ? to.label : ''"
              [hasTooltip]="to.tooltip"
              [hidden]="to.readonly"
              [id]="key"
              [maxlength]="to?.maxLength"
              [attr.data-test]="'builder-' + field.key"
              [readonly]="formState.readonly"
              [selectOnFocus]="to?.select_on_focus"
              [placeholder]="formState.readonly ? '' : to.placeholder">
</cap-textarea>

<cpb-readonly-container [hidden]="!to.readonly" [attr.data-test]="'builder-readonly-' + field.key">
  {{ formControl.value | default: to?.defaultEmptyValue }}
</cpb-readonly-container>
