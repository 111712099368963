import { Component } from '@angular/core';
import { ListRendererWidgetBase } from '@capturum/builders/list-renderer';

@Component({
  selector: 'app-traffic-light-widget',
  template:
    `
    <div class="traffic-light">
      <i class="fas fa-circle red active" [class.active]="item[field] === 'red'"></i>
      <i class="fas fa-circle orange" [class.active]="item[field] === 'orange'"></i>
      <i class="fas fa-circle green" [class.active]="item[field] === 'green'"></i>
    </div>
  `,
  styleUrls: ['./traffic-light-widget.component.scss']
})
export class TrafficLightWidgetComponent extends ListRendererWidgetBase {

}
