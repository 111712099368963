import { Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { SplitButton } from 'primeng/splitbutton';

import { SplitButtonItem } from './interfaces/split-button-item.interface';

@Component({
  selector: 'cap-split-button',
  templateUrl: './split-button.component.html',
  styleUrls: ['./split-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CapturumSplitButtonComponent {
  @ViewChild('splitButton')
  public splitButton: SplitButton;
  /** Text of the button */
  @Input()
  public label: string;
  /** Name of the icon */
  @Input()
  public icon: string;
  /** Position of the icon, valid values are "left" and "right" */
  @Input()
  public iconPos: 'left' | 'right' = 'left';
  /** Inline style of the component */
  @Input()
  public style: string;
  /** Style class of the component */
  @Input()
  public styleClass: string = 'default';
  /** items to be shown in dropdown */
  @Input()
  public items: SplitButtonItem[];
  /** Inline style of the overlay menu */
  @Input()
  public menuStyle: string;
  /** Style class of the overlay menu */
  @Input()
  public menuStyleClass: string = '';
  /** Target element to attach the overlay */
  @Input()
  public appendTo: any;
  /** When present, it specifies that the component should be disabled */
  @Input()
  public disabled: boolean;
  /** Index of the element in tabbing order */
  @Input()
  public tabIndex: number;
  @Output()
  public onClick: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  public onDropdownClick: EventEmitter<void> = new EventEmitter<void>();

  public dropdownButtonClick(event: MouseEvent): void {
    this.splitButton.onDropdownButtonClick(event);
  }
}
