import { ConfirmationBackendAction } from '../actions/confirmation-backend.action';
import { Type } from '@angular/core';
import { ActionProvider } from '../interfaces/action-provider.interface';
import { OpenSidebarAction } from '../actions/open-sidebar.action';
import { NavigateAction } from '../actions/navigate.action';
import { ConfigurationNavigateAction } from '../actions/configuration-navigate.action';
import { OpenDialogAction } from '../actions/open-dialog.action';
import { BackendAction } from '../actions/backend.action';

export const defaultCapturumBuilderActions: Record<string, Type<ActionProvider>> = {
  navigate: NavigateAction,
  configurationNavigate: ConfigurationNavigateAction,
  open_sidebar: OpenSidebarAction,
  open_popup: OpenDialogAction,
  backend: BackendAction,
  backend_confirmation: ConfirmationBackendAction,
};
