<div class="form-element">
  <label
    *ngIf="(label || hasTooltip) && !floatLabel"
    [style]="{ 'justify-content': label ? 'space-between' : 'flex-end' }">
    <span *ngIf="label">{{ label }}</span>

    <ng-content select=".tooltip-content"></ng-content>
  </label>

  <div class="p-inputgroup">
    <span *ngIf="iconLeft || contentLeftTemplate" class="p-inputgroup-addon">
      <ng-container [ngTemplateOutlet]="contentLeftTemplate || defaultTemplateLeft"> </ng-container>

      <ng-template #defaultTemplateLeft>
        <i [class]="iconLeft"></i>
      </ng-template>
    </span>

    <!-- TODO: migrate to cap prefix and remove em-input -->
    <ng-container *ngIf="!floatLabel; else floatingInput">
      <ng-container *ngTemplateOutlet="defaultTemplate"></ng-container>
    </ng-container>

    <ng-template #floatingInput>
      <span class="p-float-label input-float-label">
        <ng-container *ngTemplateOutlet="defaultTemplate"></ng-container>
        <label class="cpb-float-label" for="float-label-input">{{ label }}</label>
      </span>
    </ng-template>

    <ng-template #defaultTemplate>
      <input
        #input
        [class]="'p-inputtext em-input cap-input ' + styleClass"
        [class.readonly]="readonly"
        [class.populated]="value"
        [(ngModel)]="value"
        [disabled]="disabled"
        [pKeyFilter]="filter"
        [placeholder]="placeholder"
        [readonly]="readonly"
        [min]="min"
        [max]="max"
        [maxlength]="maxLength"
        [minlength]="minLength"
        [autocomplete]="autocomplete"
        [class.content-left]="iconLeft || contentLeftTemplate"
        [class.content-right]="iconRight || contentRightTemplate"
        [pattern]="pattern"
        [capAutofocus]="autofocus"
        [tabIndex]="tabindex"
        [type]="type"
        (keyup)="onKeyUp.emit($event)"
        (input)="onInputEvent($event)"
        (blur)="onBlur($event)"
        (focus)="onFocus($event)" />
    </ng-template>

    <span *ngIf="iconRight || contentRightTemplate" class="p-inputgroup-addon">
      <ng-container [ngTemplateOutlet]="contentRightTemplate || defaultTemplateRight"> </ng-container>

      <ng-template #defaultTemplateRight>
        <i [class]="iconRight"></i>
      </ng-template>
    </span>
  </div>

  <cap-validator *ngIf="control" [control]="control" [customName]="label"></cap-validator>
</div>
