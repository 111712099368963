<div class="wrapper wrapper-content">
  <div class="row mb-3">
    <div class="col">
      <div class="ibox">
        <div class="ibox-title">
          <h5>
            {{ 'user.profile.settings' | translate }}
          </h5>
        </div>

        <div class="ibox-content">
          <div class="row">
            <cap-button label="{{ 'user.profile.reset_database' | translate }}"
                       icon="fas fa-database"
                       class="mr-3"
                       (click)="onDatabaseReset()">
            </cap-button>

            <cap-button label="{{ 'user.profile.synchronise' | translate }}"
                       icon="fas fa-sync"
                       class="mr-3"
                       (click)="onSyncNow()">
            </cap-button>

            <cap-button label="{{ 'user.profile.forgot_password' | translate }}"
                       icon="fas fa-unlock-alt"
                       class="mr-3"
                       (click)="onResetPassword()">
            </cap-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-3" *ngIf="currentUser.roles.length > 1">
    <div class="col">
      <h5 *ngIf="currentUser.current_role">
        {{ 'user.profile.current_role' | translate }}: {{ currentUser.current_role.name }}
      </h5>
    </div>

    <div class="col">
      <emc-role-selector></emc-role-selector>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col">
      <div class="ibox">
        <div class="ibox-title">
          <h5>
            {{ 'user.profile.title' | translate }}
          </h5>
        </div>

        <div class="ibox-content">
          <form [formGroup]="formGroup" (ngSubmit)="saveUser()">
            <formly-form [form]="formGroup" [fields]="fields" [model]="model">
              <cap-button type="submit" [disabled]="submitting"
                 [icon]="submitting ? 'fas fa-spinner fa-pulse' : 'fa fa-check'" label="{{ 'button.submit' | translate }}"></cap-button>
            </formly-form>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<p-confirmDialog appendTo="body"
                 [rejectLabel]="'general.no' | translate"
                 [acceptLabel]="'general.yes' | translate">
</p-confirmDialog>
