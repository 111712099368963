import { FormBuilderConfig } from '../../models/form-builder-config.model';

export class SetValue {
  public static readonly type = '[FormBuilder] Set value';

  constructor(
    public key: string,
    public value: any,
  ) {
  }
}

export class SetFormValue {
  public static readonly type = '[FormRenderer] Set form value';

  constructor(
    public key: string,
    public value: any,
  ) {
  }
}

export class SetFormConfiguration {
  public static readonly type = '[FormRenderer] Set form configuration';

  constructor(
    public key: string,
    public configuration: FormBuilderConfig,
    public context?: Record<string, any>,
  ) {
  }
}

export class SetFormStatus {
  public static readonly type = '[FormRenderer] Set form status';

  constructor(
    public key: string,
    public valid: boolean,
  ) {
  }
}

export class ResetFormValue {
  public static readonly type = '[FormRenderer] Reset form value';

  constructor(
    public key: string,
  ) {
  }
}
