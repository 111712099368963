import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditorComponent } from './editor.component';
import { FormsModule } from '@angular/forms';
import { QUILL_CONFIG_TOKEN, QuillConfig, QuillModule } from 'ngx-quill'
import { CapturumSharedModule } from '@capturum/ui/api';
import QuillImageDropAndPaste from 'quill-image-drop-and-paste';

@NgModule({
  declarations: [
    EditorComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    QuillModule.forRoot({
      customModules: [
        { path: 'modules/imageDropAndPaste', implementation: QuillImageDropAndPaste }
      ],
    }),
    CapturumSharedModule,
  ],
  exports: [
    EditorComponent
  ]
})
export class CapturumEditorModule {
  static forRoot(config?: QuillConfig): ModuleWithProviders<CapturumEditorModule> {
    return {
      ngModule: CapturumEditorModule,
      providers: [
        {
          provide: QUILL_CONFIG_TOKEN,
          useValue: {
            customModules: [
              ...(Array.isArray(config?.customModules) ? config.customModules : []),
              { path: 'modules/imageDropAndPaste', implementation: QuillImageDropAndPaste }
            ],
          },
        },
      ],
    }
  }
}
