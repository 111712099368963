import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CapturumToastComponent } from './toast.component';
import { CapturumSharedModule } from '@capturum/ui/api';
import { ToastModule } from 'primeng/toast';

@NgModule({
  declarations: [
    CapturumToastComponent
  ],
  exports: [
    CapturumToastComponent
  ],
  imports: [
    CommonModule,
    ToastModule,
    CapturumSharedModule
  ]
})
export class CapturumToastModule {
}
