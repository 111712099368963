import { IndexedDbModel } from '../../../shared/indexedDb/indexedDb.model';
import { SelectItem } from 'primeng/api';

export class BaseDataValueIndexedDbModel extends IndexedDbModel {
  /**
   * Get the base-data-values by base-data-key
   *
   * @param key
   *
   * @return list of base-data-values
   */
  public static getByBaseDataKey(key: string): Promise<BaseDataValueIndexedDbModel[]> {
    return BaseDataValueIndexedDbModel.query().where({ base_data_key: key }).toArray();
  }

  /**
   * Return base data into Select items by key
   *
   * @param key
   */
  public static async getBaseDataList(key: string): Promise<SelectItem[]> {
    const values = await BaseDataValueIndexedDbModel.getByBaseDataKey(key);

    return values.map((value) => {
      return { label: value.id, value: value.id };
    });
  }

  // Configuration
  public entity = 'base-data-value';
  public table = 'base_data_value';
  public schema = '++id, base_data_key, parent_id, value';
  public sync = true;

  // Fields
  public base_data_key: string;
  public id: string;
  public parent_id: string;
  public value: string;

  // Constructor
  constructor(attributes: Partial<BaseDataValueIndexedDbModel>) {
    super(attributes);
  }
}
