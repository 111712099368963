import { Inject, Injectable, Type } from '@angular/core';
import { ActionProvider } from '../interfaces/action-provider.interface';
import { CapturumDialogService } from '@capturum/ui/api';
import { DynamicDialogComponent } from 'primeng/dynamicdialog';
import { ContainerComponent } from '../interfaces/container-component.interface';
import { BuilderAction } from '../interfaces/builder-action.interface';
import { BuilderCoreConfigService } from '../services/core-config.service';

@Injectable({ providedIn: 'root' })
export class OpenContainerAction implements ActionProvider {
  constructor(
    @Inject(CapturumDialogService)
    private dialogService: CapturumDialogService,
    private builderCoreConfig: BuilderCoreConfigService,
  ) {
  }

  public execute(options: { containerType: Type<ContainerComponent>, configurationKey: string, title: string, width?: string, action: BuilderAction }, item: any): void {
    const componentKey = `${options.configurationKey}_${options.action.key}`;
    const component = (this.builderCoreConfig?.components && this.builderCoreConfig.components[componentKey]) ?? options.action.options.component;

    if (component) {
      this.dialogService.open(
        component,
        {
          header: options.title,
          data: {
            item,
            ...options,
          },
          width: options.width || '70%',
          styleClass: componentKey,
        },
        options.containerType || DynamicDialogComponent,
      );
    } else {
      throw Error(`Component with key "${componentKey}" not found. Make sure to provide the component in the forRoot config of the CapturumBuilderCoreModule`);
    }
  }
}
