import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChildren,
  EventEmitter,
  Injector,
  Input,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, NgControl, NgModel } from '@angular/forms';
import { CapturumTemplateDirective, ValidatorService, ValueAccessorBase } from '@capturum/ui/api';

@Component({
  selector: 'cap-autocomplete',
  templateUrl: './autocomplete.component.html',
  providers: [ValueAccessorBase.getProviderConfig(CapturumAutocompleteComponent)],
})
export class CapturumAutocompleteComponent extends ValueAccessorBase<string> implements AfterViewInit, AfterContentInit {
  @ViewChild(NgModel, { static: true }) public model: NgModel;
  /**
   * The label to be displayed inside the input
   */
  @Input() public label: string = null;
  /**
   * An array of suggestions to display
   */
  @Input() public suggestionsFilter: any[] = ['suggestion'];
  /**
   * Field of a suggested object to resolve and display
   */
  @Input() public field: any = null;
  /**
   * Maximum height of the suggestions panel
   */
  @Input() public scrollHeight: string = '200px';
  /**
   * Displays a button next to the input field when enabled
   */
  @Input() public dropdown: boolean = false;
  /**
   * Specifies if multiple values can be selected
   */
  @Input() public multiple: boolean = false;
  /**
   * Icon class of the dropdown icon
   */
  @Input() public dropdownIcon: string = 'fas fa-chevron-down';
  /**
   * Minimum number of characters to initiate a search
   */
  @Input() public minLength: number = 1;
  /**
   * Delay between keystrokes to wait before sending a query
   */
  @Input() public delay: number = 300;
  /**
   * Inline style of the component
   */
  @Input() public style: string = null;
  /**
   * Style class of the component
   */
  @Input() public styleClass: string = 'autocomplete';
  /**
   * Inline style of the input field
   */
  @Input() public inputStyle: string = null;
  /**
   * Inline style of the input field
   */
  @Input() public inputStyleClass: string = 'prime-input-style';
  /**
   * Identifier of the focus input to match a label defined for the component
   */
  @Input() public inputId: string = null;
  /**
   * Hint text for the input field
   */
  @Input() public placeholder: string = null;
  /**
   * When present, it specifies that the input cannot be typed
   */
  @Input() public readonly: boolean = false;
  /**
   * When present, it specifies that the component should be disabled
   */
  @Input() public disabled: boolean = false;
  /**
   * Maximum number of character allows in the input field
   */
  @Input() public maxlength: number = null;
  /**
   * Target element to attach the overlay, valid values are "body" or a local ng-template variable of another element.
   */
  @Input() public appendTo: any = null;
  /**
   * Size of the input field
   */
  @Input() public size: number = null;
  /**
   * Index of the element in tabbing order
   */
  @Input() public tabindex: number = null;
  /**
   * A property to uniquely identify a value in options
   */
  @Input() public dataKey: string = null;
  /**
   * When enabled, highlights the first item in the list by default
   */
  @Input() public autoHighlight: boolean = false;
  /**
   * Type of the input, defaults to "text"
   */
  @Input() public type: string = 'text';
  /**
   * Message to display when there are no results for a query
   */
  @Input() public emptyMessage: string = null;
  /**
   * Defines how the suggestions should be manipulated. More information is available at "Change Detection" section above
   */
  @Input() public immutable: boolean = true;
  /**
   * When present, it specifies that an input field must be filled out before submitting the form
   */
  @Input() public required: boolean = false;
  /**
   * When present, it specifies that the component should automatically get focus on load
   */
  @Input() public autofocus: boolean = false;
  /**
   * When present, autocomplete clears the manual input if it does not match of the suggestions to force only accepting values from the suggestions
   */
  @Input() public forceSelection: boolean = false;
  /**
   * Specifies the behavior dropdown button. Default "blank" mode sends an empty string and "current" mode sends the input value
   */
  @Input() public dropdownMode: string = 'blank';
  /**
   * Base zIndex value to use in layering
   */
  @Input() public baseZIndex: number = 0;
  /**
   * Whether to automatically manage layering
   */
  @Input() public autoZIndex: boolean = true;
  /**
   * Transition options of the show animation
   */
  @Input() public showTransitionOptions: string = '225ms ease-out';
  /**
   * Transition options of the hide animation
   */
  @Input() public hideTransitionOptions: string = '195ms ease-in';
  /**
   * Define a string that labels the input field
   */
  @Input() public ariaLabel: string = null;
  /**
   * Specifies one or more IDs in the DOM that labels the input field
   */
  @Input() public ariaLabelledBy: string = null;
  /**
   * Specifies if the tooltip is present
   */
  @Input() public hasTooltip: boolean;
  /**
   * Float label
   */
  @Input() public floatLabel = false;
  /**
   * Callback to invoke to search for suggestions
   */
  @Output() public completeMethod: EventEmitter<any> = new EventEmitter();
  /**
   * Callback to invoke when autocomplete gets focus
   */
  @Output() public focus: EventEmitter<any> = new EventEmitter();
  /**
   * Callback to invoke when autocomplete loses focus
   */
  @Output() public blur: EventEmitter<any> = new EventEmitter();
  /**
   * Callback to invoke when a user releases a key
   */
  @Output() public onKeyUp: EventEmitter<any> = new EventEmitter();
  /**
   * Callback to invoke when a suggestion is selected
   */
  @Output() public onSelect: EventEmitter<any> = new EventEmitter();
  /**
   * Callback to invoke when a selected value is removed
   */
  @Output() public onUnselect: EventEmitter<any> = new EventEmitter();
  /**
   * Callback to invoke when dropdown button is clicked
   */
  @Output() public onDropdownClick: EventEmitter<any> = new EventEmitter();
  /**
   * Callback to invoke when input field is cleared
   */
  @Output() public onClear: EventEmitter<any> = new EventEmitter();

  @ContentChildren(CapturumTemplateDirective)
  public templates: QueryList<any>;
  /**
   * The form control class to control the input
   */
  public control: FormControl;
  public itemTemplate: TemplateRef<string>;

  constructor(private injector: Injector,
              private validatorService: ValidatorService) {
    super();
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      const ngControl: NgControl = this.injector.get(NgControl, null);
      this.control = this.validatorService.extractFormControl(ngControl);
    });
  }

  public ngAfterContentInit(): void {
    this.templates.forEach(template => {
      switch (template.getType()) {
        case 'item':
          this.itemTemplate = template.template;
          break;
      }
    });
  }
}
