import { Component, Injector, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { AuthService } from '@capturum/auth';
import { BaseDetailComponent } from '@capturum/shared';
import { emailValidator, noScript, noSpecialChars } from '@capturum/formly';
import { User } from '../../user.api-model';
import { TenantService } from '../../../tenant/services/tenant.service';
import { IndexedDbService } from '../../../../shared/indexedDb/indexedDb.service';
import { SynchronisationService } from '../../../../shared/services/synchronisation.service';
import { UserService } from '../../user.service';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'emc-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent extends BaseDetailComponent<User> implements OnInit  {
  @Input()
  public fields: FormlyFieldConfig[];

  public currentUser: User;

  constructor(
    public injector: Injector,
    public route: ActivatedRoute,
    public translateService: TranslateService,
    public apiService: UserService,
    private router: Router,
    private authService: AuthService,
    private synchronisationService: SynchronisationService,
    private tenantService: TenantService,
    private confirmationService: ConfirmationService,
    private indexedDbService: IndexedDbService
  ) {
    super(injector, translateService);

    this.cancelRedirectRoute = '/admin/users/profile';
    this.modelTranslation = this.translateService.instant('user.profile.title');

    this.model = {} as User;
    this.isEdit = true;
  }

  public ngOnInit(): void {
    this.createForm();

    super.ngOnInit();

    this.currentUser = this.authService.getUser();
    this.model = this.currentUser;
  }

  /**
   * Display confirmation message when database reset button is clicked
   */
  public onDatabaseReset(): void {
    this.confirmationService.confirm({
      message: this.translateService.instant('user.profile.reset_database.message'),
      header: this.translateService.instant('user.profile.reset_database'),
      icon: 'fa fa-question-circle',
      accept: async () => {
        await this.indexedDbService.resetStore();
        window.location.reload();
      }
    });
  }

  /**
   * Display confirmation message when sync button is clicked
   */
  public onSyncNow(): void {
    this.confirmationService.confirm({
      message: this.translateService.instant('user.profile.synchronise.message'),
      header: this.translateService.instant('user.profile.synchronise'),
      icon: 'fa fa-question-circle',
      accept: async () => {
        this.toastService.success(
          this.translateService.instant('synchronisation.title'),
          this.translateService.instant('synchronisation.data-synced')
        ),
        this.synchronisationService.synchronise();
      }
    });
  }

  /**
   * Send password reset mail to current user
   */
  public onResetPassword(): void {
    this.authService.recoverPassword(this.currentUser.email).subscribe(result => {
      if (result) {
        this.toastService.success(
          this.translateService.instant('toast.success.title'),
          this.translateService.instant('toast.email-reset.message')
        );
      }
    }, (error) => {
      this.toastService.handleError(
        error,
        this.translateService.instant('toast.error.title'),
        this.translateService.instant('toast.error.message')
      );
    });
  }

  public saveUser(): void {
    if (this.formGroup.valid) {
      const options = {
        include: [
          'currentRole',
          'locale',
          'roles'
        ]
      };

      this.apiService.update(this.model, options).subscribe((user) => {
        this.toastService.success(this.modelTranslation, this.saveMessage);

        localStorage.setItem('user', JSON.stringify(user));
      }, (error) => {
        this.toastService.error(this.modelTranslation, this.errorMessage);
      });
    }
  }

  /**
   * Create the profile form
   */
  private async createForm(): Promise<void> {
    this.fields = this.fields?.length ? this.fields : [
      {
        type: 'input',
        key: 'name',
        props: {
          translateLabel: this.translateService.stream('user.profile.name'),
          required: true,
          minLength: 2,
          maxLength: 50,
        },
        validators: {
          validation: [noSpecialChars, noScript],
        },
      },
      {
        type: 'input',
        key: 'email',
        props: {
          translateLabel: this.translateService.stream('user.profile.email'),
          type: 'email',
          required: true
        },
        validators: {
          validation: [emailValidator]
        }
      }
    ];
  }
}
