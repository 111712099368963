<p-toast [styleClass]="'cap-toast ' + styleClass" [position]="position" [key]="key" (onClose)="onClose.emit()">
    <ng-template let-message pTemplate="message">
        <div class="d-flex">
            <i class="toast-icon d-flex justify-content-center flex-column fas"
               [ngClass]="{
               'fa-check-circle': message.severity === 'success',
                'fa-exclamation-circle': message.severity === 'warn',
                'fa-times-circle': message.severity === 'error',
                'fa-info-circle': message.severity === 'info'
                }"></i>

            <div class="toast-content">
                <h3 class="toast-summary">{{ message.summary }}</h3>

                <span class="toast-detail" [innerHTML]="message.detail"></span>
            </div>
        </div>
    </ng-template>
</p-toast>
