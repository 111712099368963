<cap-dynamic-filter-layout
  class="cap-dynamic-input-filter"
  [iconClass]="filterConfig.icon"
  [label]="filterConfig.showLabel ? filterConfig.label : ''"
  [showReset]="formControl.value && (filterConfig.resetFilter ?? true)"
  (resetFilter)="reset()">
  <cap-input
    [formControl]="formControl"
    [attr.data-test]="'filter-' + filterConfig.field"
    [disabled]="filterConfig.disabled | observablePipe | async"
    [placeholder]="filterConfig.placeholder"></cap-input>
</cap-dynamic-filter-layout>
