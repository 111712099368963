import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ApiConfig } from '../api.config';
import { RecursiveIteration } from '../utils/recursive-iteration';

@Injectable()
export class DateTimezoneInterceptor extends RecursiveIteration implements HttpInterceptor {
  private readonly blackListedURLs = [];
  private readonly blackListedStatusCodes = [401, 204];

  constructor(protected config: ApiConfig) {
    super();

    if (this.config.dateInterceptor) {
      this.dateProperties = this.config.dateInterceptor.dateProperties;
      this.blackListedURLs = this.config.dateInterceptor.blackListedURLs;
      this.blackListedStatusCodes = this.config.dateInterceptor.blackListedStatusCodes;
      this.dateFormat = this.config.dateInterceptor.displayFormat;
    }
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      filter(event => event instanceof HttpResponse),
      map((event: HttpResponse<any>) => {
        const blackListUrl = this.blackListedURLs.some(item => event.url.includes(item));
        const blackListCode = this.blackListedStatusCodes.includes(+event.status);

        if (blackListUrl || blackListCode) {
          return event;
        } else {
          return event.clone({ body: this.recursiveParse(event.body) });
        }
      })
    );
  }
}
