import { FormControl, ValidationErrors } from '@angular/forms';

/**
 * Formly validator for scripts
 *
 * @param control
 */
export function noScript(control: FormControl): ValidationErrors {
  const regex = /<script.*?>([\s\S]*?)<\/script>/gi;
  const valid = !regex.test(control.value);

  return valid ? null : { script: true };
}
