import { DatePipe } from '@angular/common';
import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChildren,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, NgControl, NgModel } from '@angular/forms';
import { CapturumTemplateDirective, MapItem, ValidatorService, ValueAccessorBase } from '@capturum/ui/api';
import { format, isValid } from 'date-fns';
import { Calendar, LocaleSettings } from 'primeng/calendar';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { CalendarDataType, CalendarSelectionMode, CalendarViewMode, TimeUnits } from './enums/time.enum';
import { CalendarConfig } from './models/calendar-config.model';
import { CapturumCalendarService } from './services/calendar.service';
import { parseDateTime, parseStringTime } from './utils/calendar.utils';

@Component({
  selector: 'cap-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [ValueAccessorBase.getProviderConfig(CapturumCalendarComponent)],
})
export class CapturumCalendarComponent
  extends ValueAccessorBase<Date>
  implements AfterViewInit, OnDestroy, AfterContentInit
{
  @ViewChild(NgModel, { static: true }) public model: NgModel;
  @ViewChild(Calendar, { static: false }) public calendarRef: Calendar;

  public calendarValue: string | Date | (string | Date)[];
  /**
   * The label displayed with the calendar
   */
  @Input() public label: string;
  /**
   *  Identifier of the focus input to match a label defined for the component.
   */
  @Input() public inputId: string;
  /**
   * Name of the input element.
   */
  @Input() public name: string;
  /**
   * When specified, disables the component.
   */
  @Input() public disabled: any;
  /**
   * Whether to hide the overlay on date selection when range is selected.
   */
  @Input() public hideOnRangeSelect = false;
  /**
   * An object having regional configuration properties for the calendar.
   */
  @Input() public locale: LocaleSettings;
  /**
   * Whether to add an apply button to the footer or not.
   */
  @Input() public showApplyButton: boolean = false;
  /**
   * The default ranges for calendar.
   */
  @Input() public defaultRanges: MapItem[];
  /**
   * Specifies if the tooltip is present
   */
  @Input() public hasTooltip: boolean;
  /**
   * The transformation function to be executed when retrieving the value
   */
  public getValueTransform: (value: Date) => any;
  /**
   * The transformation function to be executed when setting the value
   */
  @Input() public setValueTransform: (value: string | Date | (string | Date)[]) => string | Date | (string | Date)[];
  /**
   * Float label
   */
  @Input() public floatLabel = false;
  /**
   * Callback to invoke on focus of input field.
   */
  @Output() public focus: EventEmitter<any> = new EventEmitter();
  /**
   * Callback to invoke on blur of input field.
   */
  @Output() public blur: EventEmitter<any> = new EventEmitter();
  /**
   * Callback to invoke when datepicker panel is closed.
   */
  @Output() public close: EventEmitter<any> = new EventEmitter();
  /**
   * Callback to invoke when a date is selected.
   * Note that this event is not called when the value is entered from the input manually.
   */
  @Output() public select: EventEmitter<any> = new EventEmitter();
  /**
   * Callback to invoke when input field is being typed.
   */
  @Output() public input: EventEmitter<any> = new EventEmitter();
  /**
   * Callback to invoke when today button is clicked.
   */
  @Output() public todayClick: EventEmitter<any> = new EventEmitter();
  /**
   * Callback to invoke when clear button is clicked.
   */
  @Output() public clearClick: EventEmitter<any> = new EventEmitter();
  /**
   * Callback to invoke when click outside of datepicker panel.
   */
  @Output() public clickOutside: EventEmitter<any> = new EventEmitter();
  /**
   * Callback to invoke when a month is changed using the navigators.
   */
  @Output() public monthChange: EventEmitter<any> = new EventEmitter();
  /**
   * Callback to invoke when a year is changed using the navigators.
   */
  @Output() public yearChange: EventEmitter<any> = new EventEmitter();
  /**
   * Define whether the user can enter a value in input manually
   */
  @Input() public readOnly: boolean;
  /**
   * Callback to invoke when a default range is changed.
   */
  @Output() public defaultRangeSelect: EventEmitter<MapItem> = new EventEmitter();
  @ContentChildren(CapturumTemplateDirective) public templates: QueryList<CapturumTemplateDirective>;
  public control: FormControl;
  public config: CalendarConfig;
  public footerTemplate: TemplateRef<any>;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private injector: Injector,
    private validatorService: ValidatorService,
    private calendarService: CapturumCalendarService,
    private datePipe: DatePipe
  ) {
    super();

    this.calendarService
      .getConfig()
      .pipe(filter(Boolean), takeUntil(this.destroy$))
      .subscribe((config: CalendarConfig) => {
        const defaultConfig = { ...config };
        this.config = defaultConfig;
      });
  }

  /**
   * Set the date to highlight on first opening if the field is blank.
   */
  @Input()
  public set defaultDate(value: Observable<Date> | Date) {
    this.config.defaultDate = value;
  }

  /**
   * The label displayed with the timepicker
   */
  @Input()
  public set timeLabel(value: Observable<string> | string) {
    this.config.timeLabel = value;
  }

  /**
   * Inline style of the component
   */
  @Input()
  public set style(value: Observable<any> | any) {
    this.config.style = value ?? null;
  }

  /**
   * Style class of the component.
   */
  @Input()
  public set styleClass(value: Observable<string> | string) {
    this.config.styleClass = `cap-calendar ${value ?? ''}`;
  }

  /**
   *  Inline style of the input field.
   */
  @Input()
  public set inputStyle(value: Observable<any> | any) {
    this.config.inputStyle = value ?? null;
  }

  /**
   * Style class of the input field.
   */
  @Input()
  public set inputStyleClass(value: Observable<string> | string) {
    this.config.inputStyleClass = value ?? null;
  }

  /**
   * Placeholder text for the input.
   */
  @Input()
  public set placeholder(value: Observable<string> | string) {
    this.config.placeholder = value ?? null;
  }

  /**
   * Format of the date which can also be defined at locale settings.
   */
  @Input()
  public set dateFormat(value: Observable<string> | string) {
    this.config.dateFormat = value ?? 'dd-mm-yy';
  }

  @Input()
  public set setTimeZone(value: boolean) {
    this.config.setTimeZone = value;
  }

  @Input()
  public set sendFormat(value: string) {
    this.config.sendFormat = value ?? 'yyyy-MM-dd';
  }

  @Input()
  public set sendDateTimeFormat(value: string) {
    this.config.sendDateTimeFormat = value ?? `yyyy-MM-dd'T'HH:mm:ssxx`;
  }

  /**
   * The day to use as the first day of the week
   */
  @Input()
  public set firstDayOfWeek(value: Observable<number> | number) {
    this.config.firstDayOfWeek = value ?? 1;
  }

  /**
   * When enabled, displays the calendar as inline. Default is false for popup mode.
   */
  @Input()
  public set inline(value: Observable<boolean> | boolean) {
    this.config.inline = value ?? false;
  }

  /**
   * Whether to display dates in other months (non-selectable) at the start or end of the current month.
   * To make these days selectable use the selectOtherMonths option.
   */
  @Input()
  public set showOtherMonths(value: Observable<boolean> | boolean) {
    this.config.showOtherMonths = value ?? true;
  }

  /**
   * Whether days in other months shown before or after the current month are selectable. This only applies if the showOtherMonths option is set to true.
   */
  @Input()
  public set selectOtherMonths(value: Observable<boolean> | boolean) {
    this.config.selectOtherMonths = value ?? false;
  }

  /**
   * When enabled, displays a button with icon next to input.
   */
  @Input()
  public set showIcon(value: Observable<boolean> | boolean) {
    this.config.showIcon = value ?? true;
  }

  /**
   * Icon of the calendar button.
   */
  @Input()
  public set icon(value: Observable<string> | string) {
    this.config.icon = value ?? 'pi pi-calendar';
  }

  /**
   * Target element to attach the overlay, valid values are "body" or a local ng-template variable of another element.
   */
  @Input()
  public set appendTo(value: Observable<any> | any) {
    this.config.appendTo = value ?? null;
  }

  /**
   * When specified, prevents entering the date manually with keyboard.
   */
  @Input()
  public set readonlyInput(value: Observable<boolean> | boolean) {
    this.config.readonlyInput = value ?? null;
  }

  /**
   * The cutoff year for determining the century for a date.
   */
  @Input()
  public set shortYearCutoff(value: Observable<any> | any) {
    this.config.shortYearCutoff = value ?? '+10';
  }

  /**
   * Whether the month should be rendered as a dropdown instead of text.
   */
  @Input()
  public set monthNavigator(value: Observable<boolean> | boolean) {
    this.config.monthNavigator = value ?? false;
  }

  /**
   * Whether the year should be rendered as a dropdown instead of text.
   */
  @Input()
  public set yearNavigator(value: Observable<boolean> | boolean) {
    this.config.yearNavigator = value ?? false;
  }

  /**
   * Specifies 12 or 24 hour format.
   */
  @Input()
  public set hourFormat(value: Observable<string> | string) {
    this.config.hourFormat = value ?? '24';
  }

  /**
   * Whether to display timepicker only.
   */
  @Input()
  public set timeOnly(value: Observable<boolean> | boolean) {
    this.config.timeOnly = value ?? false;
  }

  /**
   * Hours to change per step.
   */
  @Input()
  public set stepHour(value: Observable<number> | number) {
    this.config.stepHour = value ?? 1;
  }

  /**
   * Minutes to change per step.
   */
  @Input()
  public set stepMinute(value: Observable<number> | number) {
    this.config.stepMinute = value ?? 1;
  }

  /**
   * Seconds to change per step.
   */
  @Input()
  public set stepSecond(value: Observable<number> | number) {
    this.config.stepSecond = value ?? 1;
  }

  /**
   * Whether to show the seconds in time picker.
   */
  @Input()
  public set showSeconds(value: Observable<boolean> | boolean) {
    this.config.showSeconds = value ?? false;
  }

  /**
   * When present, it specifies that an input field must be filled out before submitting the form.
   */
  @Input()
  public set required(value: Observable<boolean> | boolean) {
    this.config.required = value ?? false;
  }

  /**
   * When disabled, datepicker will not be visible with input focus.
   */
  @Input()
  public set showOnFocus(value: Observable<boolean> | boolean) {
    this.config.showOnFocus = value ?? true;
  }

  /**
   * When enabled, calendar will show week numbers.
   */
  @Input()
  public set showWeek(value: Observable<boolean> | boolean) {
    this.config.showWeek = value ?? false;
  }

  /**
   * Type of the value to write back to ngModel, default is date and alternative is string.
   */
  @Input()
  public set dataType(value: Observable<string> | string) {
    this.config.dataType = value ?? CalendarDataType.date;
  }

  /**
   * Defines the quantity of the selection, valid values are "single", "multiple" and "range".
   */
  @Input()
  public set selectionMode(value: Observable<string> | string) {
    this.config.selectionMode = value ?? CalendarSelectionMode.single;
  }

  /**
   * Maximum number of selectable dates in multiple mode.
   */
  @Input()
  public set maxDateCount(value: Observable<number> | number) {
    this.config.maxDateCount = value ?? null;
  }

  /**
   * Whether to display today and clear buttons at the footer
   */
  @Input()
  public set showButtonBar(value: Observable<boolean> | boolean) {
    this.config.showButtonBar = value ?? false;
  }

  /**
   * Style class of the today button.
   */
  @Input()
  public set todayButtonStyleClass(value: Observable<string> | string) {
    this.config.todayButtonStyleClass = value ?? 'ui-button-secondary';
  }

  /**
   * Style class of the clear button.
   */
  @Input()
  public set clearButtonStyleClass(value: Observable<string> | string) {
    this.config.clearButtonStyleClass = value ?? 'ui-button-secondary';
  }

  /**
   * Whether to automatically manage layering.
   */
  @Input()
  public set autoZIndex(value: Observable<boolean> | boolean) {
    this.config.autoZIndex = value ?? true;
  }

  /**
   * Base zIndex value to use in layering.
   */
  @Input()
  public set baseZIndex(value: Observable<number> | number) {
    this.config.baseZIndex = value ?? 0;
  }

  /**
   * Style class of the datetimepicker container element.
   */
  @Input()
  public set panelStyleClass(value: Observable<string> | string) {
    this.config.panelStyleClass = value ?? null;
  }

  /**
   * Inline style of the datetimepicker container element.
   */
  @Input()
  public set panelStyle(value: Observable<any> | any) {
    this.config.panelStyle = value ?? null;
  }

  /**
   * Keep invalid value when input blur.
   */
  @Input()
  public set keepInvalid(value: Observable<boolean> | boolean) {
    this.config.keepInvalid = value ?? false;
  }

  /**
   * Whether to hide the overlay on date selection when showTime is enabled.
   */
  @Input()
  public set hideOnDateTimeSelect(value: Observable<boolean> | boolean) {
    this.config.hideOnDateTimeSelect = value ?? true;
  }

  /**
   * Number of months to display.
   */
  @Input()
  public set numberOfMonths(value: Observable<number> | number) {
    this.config.numberOfMonths = value ?? 1;
  }

  /**
   * Type of view to display, valid values are "date" for datepicker and "month" for month picker
   */
  @Input()
  public set view(value: Observable<string> | string) {
    this.config.view = value ?? CalendarViewMode.date;
  }

  /**
   * When enabled, calendar overlay is displayed as optimized for touch devices.
   */
  @Input()
  public set touchUI(value: Observable<boolean> | boolean) {
    this.config.touchUI = value ?? false;
  }

  /**
   * Separator of time selector.
   */
  @Input()
  public set timeSeparator(value: Observable<string> | string) {
    this.config.timeSeparator = value ?? ':';
  }

  /**
   * Transition options of the show animation.
   */
  @Input()
  public set showTransitionOptions(value: Observable<string> | string) {
    this.config.showTransitionOptions = value ?? '225ms ease-out';
  }

  /**
   * Transition options of the hide animation.
   */
  @Input()
  public set hideTransitionOptions(value: Observable<string> | string) {
    this.config.hideTransitionOptions = value ?? '195ms ease-in';
  }

  /**
   * The range of years displayed in the year drop-down in (nnnn:nnnn) format such as (2000:2020).
   */
  @Input()
  public set yearRange(value: Observable<string> | string) {
    this.config.yearRange = value ?? null;
  }

  /**
   * Index of the element in tabbing order.
   */
  @Input()
  public set tabindex(value: Observable<number> | number) {
    this.config.tabindex = value ?? null;
  }

  /**
   * Whether to display timepicker.
   */
  @Input()
  public set showTime(value: Observable<boolean> | boolean) {
    this.config.showTime = value ?? false;
  }

  /**
   * The minimum selectable date.
   */
  @Input()
  public set minDate(value: Observable<Date> | Date) {
    this.config.minDate = value ?? null;
  }

  /**
   * The maximum selectable date.
   */
  @Input()
  public set maxDate(value: Observable<Date> | Date) {
    this.config.maxDate = value ?? null;
  }

  /**
   * Array with dates that should be disabled (not selectable).
   */
  @Input()
  public set disabledDates(value: Observable<Date[]> | Date[]) {
    this.config.disabledDates = value ?? null;
  }

  /**
   * Array with weekday numbers that should be disabled (not selectable).
   */
  @Input()
  public set disabledDays(value: Observable<number[]> | number[]) {
    this.config.disabledDays = value ?? null;
  }

  private get isSingleSelection(): boolean {
    return this.config?.selectionMode === CalendarSelectionMode.single;
  }

  private get isRangeSelection(): boolean {
    return this.config?.selectionMode === CalendarSelectionMode.range;
  }

  private get isMultipleSelection(): boolean {
    return this.config?.selectionMode === CalendarSelectionMode.multiple;
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public ngAfterViewInit(): void {
    if (this.calendarRef) {
      setTimeout(() => {
        const ngControl: NgControl = this.injector.get(NgControl, null);
        this.control = this.validatorService.extractFormControl(ngControl);

        if (this.readOnly) {
          this.calendarRef.inputfieldViewChild.nativeElement.setAttribute('readonly', 'true');
        }
      });

      // Overwrite the onMonthSelect function in order to simulate "disabled" month in month picker
      // This functionality is only available in Primeng 14
      // @TODO: Remove this code once the upgrade to NG 14 has been completed
      const originalFn = this.calendarRef?.onMonthSelect.bind(this.calendarRef);
      const originalFocusFn = this.calendarRef?.updateFocus.bind(this.calendarRef);

      this.calendarRef.onMonthSelect = (event, index) => {
        if (this.config.minDate && !this.calendarRef.isSelectable(1, index, this.calendarRef.currentYear, false)) {
          event.preventDefault();

          return;
        } else {
          originalFn(event, index);
        }
      };

      this.calendarRef.updateFocus = () => {
        originalFocusFn();

        this.updateDisabledElements(this.calendarRef.currentView);
      };

      const originalSetCurrentViewFn = this.calendarRef.setCurrentView.bind(this.calendarRef);
      this.calendarRef.setCurrentView = (view: string) => {
        originalSetCurrentViewFn(view);

        this.updateDisabledElements(view);
      };
    }
  }

  public ngAfterContentInit(): void {
    this.templates.forEach((template) => {
      switch (template.getType()) {
        case 'footer':
          this.footerTemplate = template.template;
          break;
      }
    });
  }

  public transformValueChanged(value: string | string[] | Date): void {
    if (this.isSingleSelection) {
      if (value) {
        this.value = this.formatValue(value as string) as Date;
      }
    } else if (this.isRangeSelection) {
      if (value && (value as string[]).length) {
        const formattedValue = (value as string[]).map((date) => {
          return !!date ? (this.formatValue(date) as string) : date;
        });
        this.value = formattedValue as unknown as Date;
      }
    } else {
      this.value = value as Date;
    }

    this.calendarValue = value;
  }

  public writeValue(value: Date): void {
    this.innerValue = value;
    if (value) {
      this.calendarValue = this.transformWriteValue(value);
    } else {
      this.calendarValue = value;
    }
  }

  public setValue(data: { item: TimeUnits; value: number }): void {
    if (!this.calendarValue) {
      this.calendarValue = new Date();
    }

    if (this.calendarValue && typeof this.calendarValue === 'string') {
      this.calendarValue = parseDateTime(this.calendarValue) as unknown as Date;
    }

    switch (data.item) {
      case TimeUnits.hour:
        (this.calendarValue as Date).setHours(data.value);
        break;

      case TimeUnits.minute:
        (this.calendarValue as Date).setMinutes(data.value);
        break;
    }

    this.calendarValue = new Date(this.calendarValue as Date);
    if (this.config?.timeOnly) {
      this.calendarValue = this.datePipe.transform(this.calendarValue, 'HH:mm') as unknown as Date;
    }

    this.transformValueChanged(this.calendarValue);
  }

  public onBlur(event: any): void {
    if (!event.target.value) {
      this.value = null;
    }

    this.blur.emit(event);
  }

  public onSelect(event: any): void {
    this.select.emit(event);

    if (this.hideOnRangeSelect) {
      if (this.config?.selectionMode !== CalendarSelectionMode.range) {
        return console.warn('Please set selectionMode=range');
      }

      if (
        Array.isArray(this.calendarValue) &&
        this.calendarValue.every((item) => item instanceof Date || typeof item === this.dataType)
      ) {
        this.calendarRef.hideOverlay();
      }
    }
  }

  public yearOnChange(event: { month: number; year: number }): void {
    this.yearChange.emit(event);

    if (this.config.view === 'month') {
      this.calendarRef.updateFocus();
    }
  }

  public setDefaultRange(defaultRange: MapItem): void {
    if (this.config?.selectionMode !== CalendarSelectionMode.range) {
      return console.warn('Please set selectionMode=range');
    }

    if (!Array.isArray(defaultRange.value) || defaultRange.value.some((item) => !(item instanceof Date))) {
      return console.warn(`${defaultRange.key ? defaultRange.key : 'Range'} value should be an array of Date`);
    }

    this.calendarValue = defaultRange.value as any;
    this.value = (defaultRange.value as string[]).map((date) => {
      return !!date ? (this.formatValue(date) as string) : date;
    }) as unknown as Date;

    this.defaultRangeSelect.emit(defaultRange);

    if (this.hideOnRangeSelect) {
      this.calendarRef.hideOverlay();
    }
  }

  public transformWriteValue(value: string | Date | (string | Date)[]): string | Date | (string | Date)[] {
    if (value) {
      if (this.config?.timeOnly) {
        return this.config?.dataType === CalendarDataType.date ? parseDateTime(value) : parseStringTime(`${value}`);
      } else if (this.config?.selectionMode === CalendarSelectionMode.range && Array.isArray(value)) {
        const rangeValue = (value as string[]).map((date) => {
          return isValid(date as unknown as Date) ? date : !date ? null : new Date(date);
        });

        return rangeValue;
      }

      return isValid(value as unknown as Date) ? value : new Date(value as string);
    }

    return null;
  }

  public updateDisabledElements(view: string): void {
    const elements = document.querySelectorAll(
      view === 'month' ? '.p-monthpicker .p-monthpicker-month' : '.p-yearpicker .p-yearpicker-year'
    );

    elements?.forEach((element, index) => {
      let yearValid = true;
      const calendarYear = +element.innerHTML.trim();

      if (view === 'year') {
        if (this.config.minDate && this.config.minDate instanceof Date) {
          if (this.config.minDate.getFullYear() > calendarYear) {
            yearValid = false;
          }
        }

        if (this.config.maxDate && this.config.maxDate instanceof Date) {
          if (this.config.maxDate.getFullYear() < calendarYear) {
            yearValid = false;
          }
        }
      }

      if (
        (view === 'year' && !yearValid) ||
        (view === 'month' &&
          !this.calendarRef.isSelectable(
            (this.config.minDate as Date)?.getDay() || 1,
            index,
            this.calendarRef.currentYear,
            false
          ))
      ) {
        element.classList.add('p-disabled');
      } else {
        element.classList.remove('p-disabled');
      }
    });
  }

  private formatValue(value: string | Date): string | Date {
    if (!this.config?.timeOnly) {
      if (!this.config?.setTimeZone && !this.config?.showTime) {
        return format(value as Date, this.config?.sendFormat);
      } else if (this.config?.showTime || this.config?.setTimeZone) {
        return format(value as Date, this.config?.sendDateTimeFormat);
      }
    } else if (this.config?.timeOnly) {
      return isValid(value as Date) ? this.formatTime(value as Date) : value;
    }

    return value;
  }

  private formatTime(date: Date): string {
    if (!date) {
      return '';
    }

    let output = '';
    const hours = date.getHours();
    const minutes = date.getMinutes();

    output += hours < 10 ? '0' + hours : hours;
    output += ':';
    output += minutes < 10 ? '0' + minutes : minutes;

    return output;
  }
}
