export class SettingApiModel {
  public id: string;
  public module_id?: string;
  public name: string;
  public type: string;
  public description?: string;
  public default_value?: string;
  public editable_by_tenant: boolean;
  public value?: string | boolean | Date;
}
