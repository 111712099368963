import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  /**
   * Dom sanitizer instance
   */
  protected sanitizer: DomSanitizer;

  constructor() { }

  /**
   * Store the current router url in local storage
   *
   * @param router
   *
   * @return void
   */
  public storeCurrentRoute(router: Router): void {
    localStorage.setItem('lastRoute', router.url);
  }

  /**
   * Store the previous router url in local storage
   *
   * @param router
   *
   * @return void
   */
  public storePreviousRoute(router: Router): void {
    if (router.url !== localStorage.getItem('lastRoute')) {
      localStorage.setItem('previousRoute', localStorage.getItem('lastRoute'));
    }
  }

  /**
   * Map the enumeration values to a dropdown list array
   *
   * @param enumeration
   *
   * @return SelectItem[]
   */
  public mapEnumToSelectItems(enumeration: Object): SelectItem[] {
    const values: SelectItem[] = [];

    for (const key in enumeration) {
      values.push({label: enumeration[key], value: key});
    }

    return values;
  }

  /**
   * Transform given object to an array of SelectItems
   *
   * @param object
   * @param placeholder
   * @param sort
   *
   * @return any
   */
  public mapObjectToSelectItems(object: {[key: string]: any}, placeholder: string = null, sort: boolean = false): { value: any, label: string }[] {
    let dropdownOptions = [];

    if (placeholder) {
      dropdownOptions.push({label: placeholder, value: ''});
    }

    for (let key in object) {
      dropdownOptions.push({label: object[key], value: key});
    }

    if (sort) {
      dropdownOptions = dropdownOptions.sort((a, b) => {
        return a.label < b.label ? -1 : 1;
      });
    }

    return dropdownOptions;
  }

  /**
   * Get a preview url for uploaded images
   *
   * @param file
   *
   * @return Observable<SafeUrl>
   */
  public readUrl(file: File): Observable<SafeUrl> {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    return new Observable((observer) => {
      reader.onerror = (error) => {
        observer.error(error);
      };

      reader.onload = (onLoadEvent: any) => {
        observer.next(this.sanitizer.bypassSecurityTrustUrl(onLoadEvent.target.result));
      };

      reader.onloadend = () => {
        observer.complete();
      };
    });
  }
}
