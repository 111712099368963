import { ChangeDetectorRef, Component, EventEmitter, Input, ViewEncapsulation } from '@angular/core';
import { DropdownFilterConfigItem, DynamicFilterComponent } from '../../interfaces/dynamic-filter.interface';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'cap-dropdown-filter',
  templateUrl: './dropdown-filter.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class CapturumDropdownFilterComponent implements DynamicFilterComponent<string> {
  @Input()
  public filterConfig: DropdownFilterConfigItem;
  public updateValue: EventEmitter<string> = new EventEmitter<string>();
  public formControl = new FormControl();

  constructor(private cdr: ChangeDetectorRef) {
  }

  public setValue(value: string): void {
    this.formControl.setValue(value);
    this.cdr.detectChanges();
  }

  public reset(): void {
    this.formControl.reset();
    this.updateValue.emit(null);
  }

  public updateView(): void {
    this.cdr.detectChanges;
  }
}
