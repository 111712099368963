import { Injectable } from '@angular/core';
import { InputTypeBuilder } from '../input/input-type.builder';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { InputType } from '@capturum/builders/core';
import { RepeatTypeConfiguration } from '../../models/repeat-type-configuration.model';

@Injectable({ providedIn: 'root' })
export class RepeatInputTypeBuilder extends InputTypeBuilder {
  protected inputType: string = InputType.repeat;

  protected appendOptions(input: FormlyFieldConfig, configuration: RepeatTypeConfiguration): void {
    input.props = {
      ...input.props,
      configuration,
      addButtonText: configuration.options.actions.add_button.label,
      addButtonIcon: configuration.options.actions.add_button.icon,
      deleteButtonIcon: configuration.options.actions.delete_button.icon,
      deleteButtonText: configuration.options.actions.delete_button.label,
    };
  }
}
