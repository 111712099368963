import { Component, Input } from '@angular/core';
import { ListRendererWidgetBase } from '../../base/list-renderer-widget-base';
import { LinkHrefType } from './link-href-type.enum';

@Component({
  selector: 'cpb-list-renderer-widget-link',
  template: `
    <a *ngIf="item[field] as link"
       [target]="target"
       [href]="type ? LinkHrefType[type] + link : link">{{link}}
    </a>
  `
})
export class ListRendererWidgetLinkComponent extends ListRendererWidgetBase {
  @Input() public type: keyof typeof LinkHrefType;
  @Input() public target: string = '_blank';
  public LinkHrefType: typeof LinkHrefType = LinkHrefType;
}
