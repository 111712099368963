<div class="cta-container" *ngIf="menuItems.length">
  <div class="cta-menu-items" [@listAnimation]="displayedMenuItems.length">
    <cap-button *ngFor="let menuItem of displayedMenuItems"
                class="cta-menu-item"
                styleClass="primary"
                [label]="menuItem.label"
                [icon]="menuItem.icon"
                (onClick)="executeCallback(menuItem)">
    </cap-button>
  </div>

  <cap-button styleClass="primary" class="cta-button" [icon]="ctaButtonIcon" (onClick)="handleCtaButtonClick()"></cap-button>
</div>

<div *ngIf="showMenuItems" (click)="handleCtaButtonClick()" class="cta-blur-overlay"></div>
