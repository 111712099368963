import {
  AfterViewInit,
  Component,
  EventEmitter,
  Injector,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, NgControl, NgModel } from '@angular/forms';
import { ValidatorService, ValueAccessorBase } from '@capturum/ui/api';

/**
 * A password component
 *
 * Example:
 * ```html
 *     <cap-password placeholder='Placeholder' label='Label' [toggleMask]='true'></cap-password>
 * ```
 */
@Component({
  selector: 'cap-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
  providers: [ValueAccessorBase.getProviderConfig(CapturumPasswordComponent)],
})
export class CapturumPasswordComponent extends ValueAccessorBase<string>
  implements AfterViewInit {
  @ViewChild(NgModel, { static: true }) public model: NgModel;

  /**
   * The placeholder for the input to display when there is no input filled in
   */
  @Input() public placeholder = '';
  /**
   * The label to be displayed inside the input
   */
  @Input() public label: string;
  /**
   * The value that determines if the input is disabled
   */
  @Input() public disabled: boolean;
  /**
   * The variable that configures the input to be read only or not
   */
  @Input() public readonly: boolean;
  /**
   * The styleClass of the Input component
   */
  @Input() public styleClass: string = '';
  /**
   * The toggleMask of the Input component
   */
  @Input() public toggleMask: boolean;
  /**
   * The hideIcon have default value = 'fal fa-eye-slash'
   */
  @Input() public hideIcon: string;
  /**
   * The showIcon have default value = 'fal fa-eye'
   */
  @Input() public showIcon: string;
  /**
   * The autoCloseTimeout will hide password automatically for ex: 2500
   */
  @Input() public autoCloseTimeout: number;
  /**
   * The variable to be set when you want to enable autocomplete
   */
  @Input() public autocomplete = 'off';

  /**
   * The action you want to emit when there is a change in the input
   */
  @Output() public onInput: EventEmitter<any> = new EventEmitter();
  /**
   * The action you want to emit when the input loses focus
   */
  @Output() public blur: EventEmitter<void> = new EventEmitter();
  /**
   * The action you want to emit when input is in focus
   */
  @Output() public focus: EventEmitter<void> = new EventEmitter();
  /**
   * The action you want to emit when input a keyboard key up event happens
   */
  @Output() public onKeyUp: EventEmitter<KeyboardEvent> = new EventEmitter();

  public isPasswordTypeText: boolean = false;
  public control: FormControl;

  constructor(
    private validatorService: ValidatorService,
    private injector: Injector
  ) {
    super();
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      const ngControl: NgControl = this.injector.get(NgControl, null);
      this.control = this.validatorService.extractFormControl(ngControl);
    });
  }

  /**
   * Password field type text or password
   */
  public displayToggle(): void {
    this.isPasswordTypeText = !this.isPasswordTypeText;
    if (this.autoCloseTimeout) {
      setTimeout(() => {
        this.isPasswordTypeText = false;
      }, this.autoCloseTimeout);
    }
  }

  public onInputEvent($event: any): void {
    this.touch();
    this.onInput.emit($event);
  }

  public onBlur($event: any): void {
    this.touch();
    this.blur.emit();
  }

  public onFocus($event: any): void {
    this.focus.emit();
  }
}
