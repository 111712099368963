import { Injectable } from '@angular/core';
import { CSSVarNames } from '../enums/css-vars.enum';
import { Colors } from '../enums/colors.enum';
import { Theme } from '../models/theme.model';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  public static getPageWidth(): number {
    return Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    );
  }

  private theme: Theme;

  public initializeTheme(): void {
    this.theme = this.getTheme();
    this.applyChanges();
  }

  public setProps(props: { [key: string]: string }): void {
    this.theme = { ...this.theme, ...props };
    this.applyChanges();
  }

  public setRandomColors(): void {
    Object.keys(this.theme).forEach(key => this.theme[key] = this.getRandomColor());
    this.applyChanges();
  }

  private applyChanges(): void {
    Object.keys(this.theme).forEach(key =>
      document.documentElement.style.setProperty(`${key}`, this.theme[key])
    );
  }

  /* Can be replaced with an API call */
  private getTheme(): Theme {
    return {
      [CSSVarNames.Primary]: Colors.Primary,
      [CSSVarNames.Secondary]: Colors.Secondary,
      [CSSVarNames.Third]: Colors.Third,
      [CSSVarNames.Text]: Colors.Text,
      [CSSVarNames.Text2]: Colors.Text2,
      [CSSVarNames.Text3]: Colors.Text3,
      [CSSVarNames.Text4]: Colors.Text4,
      [CSSVarNames.Success]: Colors.Success,
      [CSSVarNames.Warning]: Colors.Warning,
      [CSSVarNames.Confirm]: Colors.Confirm,
      [CSSVarNames.PrimaryShadow]: Colors.PrimaryShadow,
      [CSSVarNames.SecondaryShadow]: Colors.SecondaryShadow,
      [CSSVarNames.ThirdShadow]: Colors.ThirdShadow,
      [CSSVarNames.SuccessShadow]: Colors.SuccessShadow,
      [CSSVarNames.WarningShadow]: Colors.WarningShadow,
      [CSSVarNames.ErrorShadow]: Colors.ErrorShadow,
      [CSSVarNames.ConfirmShadow]: Colors.ConfirmShadow,
      [CSSVarNames.Error]: Colors.Error,
      [CSSVarNames.Info]: Colors.Info,
      [CSSVarNames.Font]: 'times, serif',
      [CSSVarNames.ToastText]: Colors.ToastText,
      [CSSVarNames.InputHeight]: '30px',
      [CSSVarNames.DynamicFilterInputHeight]: '30px',
    };
  }

  private getRandomColor(): string {
    const letters = '0123456789ABCDEF';
    let color = '#';

    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }

    return color;
  }
}

