<form [formGroup]="form" *ngIf="form">
  <div class="multi-factor-form">
    <section class="multi-factor-form--section">
      <h5 class="multi-factor-form--section-title">
        1. {{ 'auth.2fa.types.select' | translate }}
      </h5>

      <div class="multi-factor-form--section-body">
        <cap-dropdown styleClass="w-100"
                      [options]="types$ | async"
                      formControlName="method"
                      [placeholder]="'auth.2fa.types.select' | translate"
                      (change)="changeMethodAuth($event.value)">
          <ng-template capTemplate="item" let-item>
            {{ item.label | translate }}
          </ng-template>

          <ng-template capTemplate="selectedItem" let-item>
            {{ item.label | translate }}
          </ng-template>
        </cap-dropdown>
      </div>
    </section>

    <section class="multi-factor-form--section">
      <ng-container [ngSwitch]="controlMethod.value">
        <emc-auth-authenticator *ngSwitchCase="TwoFactorMethod.google || TwoFactorMethod.microsoft" [method]="controlMethod.value">
        </emc-auth-authenticator>

        <emc-auth-email *ngSwitchCase="TwoFactorMethod.email" (emailCodeEvent)="showAndFocusInputCode()">
        </emc-auth-email>

        <emc-auth-sms *ngSwitchCase="TwoFactorMethod.sms" (smsCodeEvent)="showAndFocusInputCode()">
        </emc-auth-sms>
      </ng-container>
    </section>

    <section class="multi-factor-form--section" [style.visibility]="showCode ? 'visible' : 'hidden'">
      <h5 class="multi-factor-form--section-title">
        {{ controlMethod.value === TwoFactorMethod.google ? '4' : '3' }}
        . {{ 'auth.2fa.code.title' | translate }}
      </h5>

      <p class="multi-factor-form--section-description">
        {{ 'auth.2fa.' + controlMethod.value + '.code.description' | translate }}
      </p>

      <div class="input-codes">
        <cap-input-code formControlName="code" (valuesFilled)="appInputCodeFilled()"></cap-input-code>
      </div>
    </section>
  </div>
</form>
