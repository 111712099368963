import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CapturumInputNumberComponent } from './input-number.component';
import { InputNumberModule } from 'primeng/inputnumber';
import { CapturumSharedModule } from '@capturum/ui/api';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    CapturumInputNumberComponent,
  ],
  exports: [
    CapturumInputNumberComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    InputNumberModule,
    CapturumSharedModule,
  ]
})
export class CapturumInputNumberModule {
}
