import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'cpb-toggle-input',
  template: `
    <div [style.display]="to.readonly ? 'none' : 'block'">
      <cap-input-switch [name]="field.key"
                        [attr.data-test]="'builder-' + field.key"
                        [disabled]="to.readonly"
                        [formControl]="formControl"
                        [formlyAttributes]="field">
      </cap-input-switch>
    </div>

    <span *ngIf="to.readonly" [attr.data-test]="'builder-readonly-' + field.key">
      {{ formControl.value | json }}
    </span>
  `,
})
export class CapturumBuilderToggleInputComponent extends FieldType {
}
