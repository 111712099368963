import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'cpb-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CapturumTooltipComponent {
  @Input() public position: 'bottom-right' | 'bottom-left' | 'top-right' | 'top-left' = 'bottom-right';
  @Input() public isShort: boolean = false;
}
