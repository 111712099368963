import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { KeyCode, numberKeyCodes, ValueAccessorBase } from '@capturum/ui/api';
import { CapturumInputComponent } from '@capturum/ui/input';
import { fromEvent, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'cap-input-code',
  templateUrl: './input-code.component.html',
  styleUrls: ['./input-code.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ValueAccessorBase.getProviderConfig(CapturumInputCodeComponent)],
})
export class CapturumInputCodeComponent extends ValueAccessorBase<string> implements OnInit, OnDestroy, AfterViewInit {
  public FormControl: typeof FormControl = FormControl;
  public formGroup: FormGroup;
  public codeString: string;
  public subscriptions: Subscription = new Subscription();

  @Input()
  public maxInputLength: number = 6;

  @Output()
  public valuesFilled = new EventEmitter();

  @Output()
  public onInitialized = new EventEmitter<boolean>();

  @ViewChildren(CapturumInputComponent, { read: ElementRef })
  private _domInputs: QueryList<ElementRef>;

  public get domInputs(): ElementRef[] {
    return this._domInputs.toArray();
  }

  public get codeInputArray(): FormArray {
    return this.formGroup.get('code') as FormArray;
  }

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.createForm();
    this.handleEmitWhenCodeIsEntered();
    this.handleKeyboardInput();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public ngAfterViewInit(): void {
    this.focusInput(0);
  }

  public focus(): void {
    this.focusInput(0);
  }

  public reset(): void {
    this.formGroup.reset();

    this.codeInputArray.controls.forEach((control, index) => {
      if (index !== 0) {
        control.disable();
      }
    });

    this.focusInput(0);
  }

  public writeValue(value: string): void {
    if (value) {
      super.writeValue(value);
    } else {
      this.codeInputArray.reset();
    }
  }

  public onPaste(event: ClipboardEvent, inputIndex: number): void {
    event.preventDefault();
    event.stopPropagation();

    const data = event?.clipboardData?.getData('text')?.trim() ?? null;

    if (this.isEmptyValue(data)) {
      return;
    }

    const values = data.split('').slice(0, this.maxInputLength);
    let inputValueIndex = 0;

    for (let index = inputIndex; index < this.domInputs.length; index++) {
      if (inputValueIndex === values.length) {
        break;
      }

      const currentValue = values[inputValueIndex].toString();

      this.codeInputArray.at(index).enable();
      this.codeInputArray.at(index).patchValue((this.isEmptyValue(currentValue)) ? '' : currentValue);

      inputValueIndex++;
    }

    this.focusInput(values.length - 1);
  }

  private createForm(): void {
    this.formGroup = this.formBuilder.group({
      code: this.formBuilder.array([]),
    });

    for (let i = 0; i < this.maxInputLength; i++) {
      const isNotFirstItem = (i > 0);
      const control = new FormControl(
        { value: null, disabled: isNotFirstItem },
        [Validators.required],
      );

      this.codeInputArray.push(control);
    }
  }

  private handleEmitWhenCodeIsEntered(): void {
    this.subscriptions.add(
      this.codeInputArray.valueChanges.subscribe(() => {
        this.value = this.codeInputArray.value.join('');

        if (this.codeInputArray.valid) {
          this.valuesFilled.emit();
        }
      }),
    );
  }

  private handleKeyboardInput(): void {
    this.subscriptions.add(
      fromEvent<KeyboardEvent>(document, 'keyup').pipe(
        map((keyEvent) => keyEvent.code as KeyCode),
      ).subscribe((keyCode) => {
        const values = this.formGroup.getRawValue();
        const firstAvailableIndex = values.code.findIndex((value) => !value);
        const focusedIndex = this.getIndexWithinRange(firstAvailableIndex);

        this.handleNumericKeyPressed(keyCode, focusedIndex);
        this.handleBackspaceKeyPressed(keyCode, focusedIndex, values[focusedIndex]);
      }),
    );
  }

  private getIndexWithinRange(index: number): number {
    if (index === -1) {
      return this.maxInputLength - 1;
    } else if (index === 0) {
      return 0;
    } else {
      return index - 1;
    }
  }

  private handleNumericKeyPressed(keyCode: KeyCode, focusedIndex: number): void {
    if (numberKeyCodes.includes(keyCode)) {
      if (focusedIndex + 1 < this.maxInputLength) {
        this.enableInput(focusedIndex + 1);
        this.focusInput(focusedIndex + 1);
      }
    }
  }

  private handleBackspaceKeyPressed(keyCode: KeyCode, focusedIndex: number, focusedValue: string): void {
    if (keyCode === KeyCode.Backspace) {
      if (this.isEmptyValue(focusedValue)) {
        this.codeInputArray.at(focusedIndex).reset();
        this.disableInputStartingFrom(focusedIndex + 1);
        this.focusInput(focusedIndex);
      }
    }
  }

  private focusInput(index: number): void {
    setTimeout(() => {
      this.domInputs[index]?.nativeElement?.querySelector('input')?.focus();
      this.cdr.detectChanges();
    });
  }

  private enableInput(index: number): void {
    this.codeInputArray.at(index).enable();
    this.cdr.detectChanges();
  };

  private disableInputStartingFrom(startingIndex: number): void {
    this.codeInputArray.controls
      .slice(startingIndex, this.codeInputArray.controls.length)
      .forEach((control) => {
        control.disable();
        control.reset();
      });

    this.cdr.detectChanges();
  };


  private isEmptyValue(value: string): boolean {
    return !(value) || !(value.toString().length);
  }
}

