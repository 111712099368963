import { BaseTranslation } from './base-translation.model';
import { TranslationApiModel } from '../../translation';

export class BaseDataValueApiModel {
  public id: string;
  public value: string;
  public parent_id?: string;
  public translations: TranslationApiModel[];
  public base_data_key_id: string;
  public attributes?: BaseDataValueAttribute[];
  public propertyValues?: BaseDataValueAttribute[];
  public properties?: BaseDataValueAttribute[];
  public editable_by_tenant?: boolean;
}

export interface BaseDataValueApi {
  id: string;
  value: string;
  parent_id?: string;
  base_data_key_id: string;
  tenant_id: string | number;
  translations?: BaseTranslation[];
  attributes?: BaseDataValueAttribute[];
  propertyValues?: BaseDataValueAttribute[];
  created_at: string;
  updated_at: string;
}

export interface BaseDataValueAttributeBoolean {
  value: boolean;
}
export interface BaseDataValueAttributeString {
  value: string;
}

export type BaseDataAttributeValue = BaseDataValueAttributeBoolean | BaseDataValueAttributeString;
export type BaseDataValueAttribute  = BaseDataAttributeValue & {[key: string]: string};

