<form [formGroup]="radioForm" class="radio-input-container" [attr.data-test]="'builder-' + field.key">
  <ng-container *ngIf="to.options$ | async as options">
    <div *ngFor="let option of options" class="p-field-radiobutton">
      <p-radioButton [value]="option[to.value_key]"
                     formControlName="radio"
                     [inputId]="option[to.value_key]"
                     name="radio">
      </p-radioButton>

      <label [for]="option[to.value_key]">{{ option[to.label_key] }}</label>
    </div>
  </ng-container>
</form>
