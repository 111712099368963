<div class="cap-not-found">
  <img [src]="image" alt="not-found" />

  <div class="cap-not-found-header">
    {{ titleTranslationKey | translate }}
  </div>

  <div class="cap-not-found-text">
    {{ textTranslationKey | translate }}
  </div>
</div>
