import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { RouterModule } from '@angular/router';
import { TranslationFormComponent } from './components/translation-form/translation-form.component';
import { EditTranslationsTogglerComponent } from './components/edit-translations-toggler/edit-translations-toggler.component';
import { CapturumInputSwitchModule } from '@capturum/ui/input-switch';
import { EditTranslationDialogComponent } from './components/edit-translation-dialog/edit-translation-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CapturumButtonModule } from '@capturum/ui/button';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [TranslationFormComponent, EditTranslationsTogglerComponent, EditTranslationDialogComponent],
  imports: [
    CommonModule,
    CapturumButtonModule,
    CapturumInputSwitchModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  exports: [TranslationFormComponent, EditTranslationsTogglerComponent, EditTranslationDialogComponent],
})
export class TranslationModule {}
