import { Injectable } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { FormlyFieldConfig, FormlyFieldProps } from '@ngx-formly/core';
import { map, shareReplay, startWith, take, switchMap } from 'rxjs/operators';
import { InputTypeBuilder } from '../input/input-type.builder';
import { FormInputConfig } from '../../interfaces/form-input-config';
import { CheckboxListInputConfiguration } from '../../models/checkbox-list-input-configuration.model';
import { InputType } from '@capturum/builders/core';
import { FormInputTypeFacade } from '../../facades/form-input-type.facade';

@Injectable({ providedIn: 'root' })
export class CheckboxListInputTypeBuilder extends InputTypeBuilder implements FormInputConfig<CheckboxListInputConfiguration> {
  protected inputType: string = InputType.checkboxList;
  private subscription: Subscription = new Subscription();

  constructor(protected readonly formInputTypeFacade: FormInputTypeFacade) {
    super(formInputTypeFacade);
  }

  public getValueChangesSubscription(field: FormlyFieldConfig, configuration: CheckboxListInputConfiguration): Subscription {
    return field.formControl.valueChanges.pipe(
      startWith(field.formControl.value),
      switchMap<any, Observable<{ fieldValue: any, fieldOptions: any }>>((fieldValue) => {
        return field?.props?.options$ ? field.props.options$.asObservable().pipe(
          map((fieldOptions) => ({ fieldValue, fieldOptions })),
        ) : of({
          fieldValue: null,
          fieldOptions: [],
        });
      }),
    ).subscribe(({ fieldValue, fieldOptions }: { fieldValue: any, fieldOptions: any }) => {
      if (field?.props) {
        this.setStateValue(fieldOptions, field, fieldValue, configuration);
      }
    });
  }

  protected appendOptions(input: FormlyFieldConfig, configuration: CheckboxListInputConfiguration): void {
    if (configuration.options) {
      const options$ = this.formInputTypeFacade.getTransformedOptions(configuration.options, input.props.formKey).pipe(shareReplay(1));

      input.hooks = {
        onInit: (field: FormlyFieldConfig | undefined) => {
          this.setprops(options$, input);

          if (configuration.sets && field?.formControl) {
            this.subscription.add(
              this.getValueChangesSubscription(field, configuration),
            );
          }

          if (configuration.dependencies && configuration.dependencies.length && configuration?.dependency_operator && field) {
            this.formInputTypeFacade.resolveDependencies(field, configuration);
          }
        },
        onDestroy: () => this.subscription.unsubscribe(),
      };
    }
  }

  private setprops(options$: Observable<any>, input: FormlyFieldConfig): void {
    options$.pipe(take(1)).subscribe((options) => {
      input.props.options$.next(options);
    });
  }

  private setStateValue(fieldOptions: any[], field: FormlyFieldConfig, fieldValue: any, configuration: CheckboxListInputConfiguration): void {
    const stateOptions = fieldOptions.filter((option: FormlyFieldProps) => {
      return fieldValue && fieldValue.includes(option[field?.props?.value_key]);
    });

    this.formInputTypeFacade.setValueByKey(configuration.sets as string, stateOptions);
  }
}
