export enum EntityInfoType {
  name = 'name',
  columns = 'columns',
  relations = 'relations',
  method = 'method',
  methods = 'methods',
  start = 'start',
  list = 'list',
  form = 'form',
}

export enum EntityInfoSourceMethod {
  getSourceValueByKey = 'getSourceValueByKey',
  getFormValueByKey = 'getFormValueByKey',
}
