import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CapturumChipsComponent } from './chips.component';
import { ChipsModule } from 'primeng/chips';
import { CapturumSharedModule } from '@capturum/ui/api';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    CapturumChipsComponent
  ],
  exports: [
    CapturumChipsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ChipsModule,
    CapturumSharedModule
  ]
})
export class CapturumChipsModule {
}
