import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CapturumAccordionComponent } from './accordion.component';
import { AccordionModule } from 'primeng/accordion';
import { TranslateModule } from '@ngx-translate/core';
import { CapturumAccordionCardComponent } from './accordion-card/accordion-card.component';

@NgModule({
  declarations: [
    CapturumAccordionComponent,
    CapturumAccordionCardComponent
  ],
  imports: [
    CommonModule,
    AccordionModule,
    TranslateModule
  ],
  exports: [
    CapturumAccordionComponent,
    CapturumAccordionCardComponent
  ]
})
export class CapturumAccordionModule {
}
