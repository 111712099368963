<!--
    Additional properties needed:
        - if throwing in { label: '..', value: '...' } format or an object, please specify the field property which to filter on.
        - if you want to customize the 'no results' message, please specify as well.

    These can be specified using the 'additionalProperties' property:

        ..
        additionalProperties: {
            filterField: 'label',
            emptyMessage: 'Geen resultaten.'
        },
        ..
-->

<ng-container *ngIf="(to.options | observablePipe | async) as options">
  <cap-autocomplete (onSelect)="to.change ? to.change(field, $event) : null"
                    [suggestionsFilter]="filteredResults"
                    (completeMethod)="filterResults($event, options)"
                    [formControl]="formControl"
                    [formlyAttributes]="field"
                    [field]="(to.additionalProperties && to.additionalProperties['filterField']) ? to.additionalProperties['filterField'] : 'label'"
                    [emptyMessage]="(to.additionalProperties['emptyMessage'] || 'No results') | observablePipe | async"
                    [placeholder]="(to.translatePlaceholder || to.placeholder) | observablePipe | async"
                    [dropdown]="true"
                    [floatLabel]="(to.label_position | observablePipe | async ) === 'floating'"
                    [label]="(to.label_position | observablePipe | async ) === 'floating' ? to.label : ''">
  </cap-autocomplete>
</ng-container>
