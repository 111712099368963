import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilePreviewListComponent } from './file-preview-list.component';
import { ProgressBarModule } from 'primeng/progressbar';
import { OverlayPanelModule } from 'primeng/overlaypanel';

@NgModule({
  declarations: [
    FilePreviewListComponent,
  ],
  imports: [
    CommonModule,
    ProgressBarModule,
    OverlayPanelModule,
  ],
  exports: [
    FilePreviewListComponent,
  ],
})
export class CapturumFilePreviewListModule {
}
