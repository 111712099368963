<cap-color-picker
        [style]="to.style || null"
        [styleClass]="to.styleClass || null"
        [disabled]="to.disabled || false"
        [format]="to.format || 'hex'"
        [displayInput]="false"
        [formControl]="formControl"
        [formlyAttributes]="field"
        [appendTo]="to.appendTo || null"
        [showTransitionOptions]="to.showTransitionOptions || '225ms ease-out'"
        [hideTransitionOptions]="to.hideTransitionOptions || '195ms ease-in'"
        (onChange)="to.onChange ? to.onChange($event) : null"
        [inline]="!to.readOnly || (to.inline && !to.readOnly)">
</cap-color-picker>

<div class="color-details">
	<label for="selected-color" class="d-block">
		{{ (to.selectedColorLabel | observablePipe | async) || 'Geselecteerde kleur' }}
	</label>

	<input pInputText [disabled]="to.disabled || false" [readOnly]="to.readOnly || false" class="color-picker-input"
		type="text" id="selected-color" [value]="formControl.value" (blur)="onBlur($event)" (input)="onInput($event)" />
</div>