import { Injectable, inject } from '@angular/core';
import { CapturumBuildersContextService, responseData } from '@capturum/builders/core';
import { ApiService } from '@capturum/api';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FlowApiService extends ApiService<any> {
  protected endpoint = '/builder/source';

  private contextService = inject(CapturumBuildersContextService);

  public get<T = any>(key: string): Observable<T> {
    const params = this.contextService.getHttpParamsContext(key);

    return this.apiHttp.get(`${this.endpoint}/${key}`, { params }).pipe(responseData);
  }
}
