import { Observable } from 'rxjs';

export interface BatchStatus {
  created_at: string;
  finished_at: string;
  failed_jobs: number;
  has_failures?: boolean;
  pending_jobs?: number;
  action?: { [key: string]: string };
  progress: number;
}

export interface FailedBatchJob {
  id: string;
  connection: string;
  queue: string;
  failed_at: string;
}

export interface FinishedBatchStatus {
  finished: boolean;
  action?: { [key: string]: string };
  message?: any;
  progress: number;
}

export interface BatchStatusActionProvider {
  execute(action: BatchStatusAction): Observable<any> | any | void;
}

export interface BatchStatusAction {
  action: any;
  type: string;
}
