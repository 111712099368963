import { FormArray, FormControl, FormGroup } from '@angular/forms';

// @dynamic
export class FormHelper {
  public static checkValidity(formGroup: FormGroup): boolean {
    if (formGroup.invalid) {
      FormHelper.markAsTouched(formGroup);
    }

    return formGroup.valid;
  }

  public static markAsTouched(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);

      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.markAsTouched(control);
      } else if (control instanceof FormArray) {
        control.controls.forEach((arrayControl: FormGroup) => {
          this.markAsTouched(arrayControl);
        });
      }
    });
  }

  public static validFormControls(form: FormGroup, validatedControls: string[]): boolean {
    return validatedControls.every(controlName => form.controls[controlName].valid);
  }

  public static resetFormArray(formArray: FormArray): FormArray {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }

    return formArray;
  }

  public static isEqualObjects(value: any, comparableValue: any): boolean {
    return JSON.stringify(value) === JSON.stringify(comparableValue);
  }

  public static getChangedPropertyKey(data: Object, savedData: Object): string[] {
    const changedProperty: string[] = [];

    Object.keys(data).map(key => {
      if (data[key] !== savedData[key]) {
        changedProperty.push(key);
      }
    });

    return changedProperty || null;
  }
}
