import { Pipe, PipeTransform } from '@angular/core';

/**
 * Get the tr styleClass of a column
 */
@Pipe({
  name: 'trStyleClass'
})
export class TrStyleClassPipe implements PipeTransform {
  public transform(styleClass: any, args?: any): string {
    const trClasses = ['row-divider'];

    if (styleClass) {
      trClasses.push(styleClass);
    }

    return trClasses.join(' ');
  }
}
