import { FormBuilderConfig } from '../../models/form-builder-config.model';
import { FormValidationMessageType } from '../../types/form-validation-message.type';

export class SetFormValue {
  public static readonly type = '[FormRenderer] Set form value';

  constructor(
    public key: string,
    public value: any,
  ) {
  }
}

export class SetFormConfiguration {
  public static readonly type = '[FormRenderer] Set form configuration';

  constructor(
    public key: string,
    public configuration: FormBuilderConfig,
    public context?: Record<string, any>,
  ) {
  }
}

export class SetFormStatus {
  public static readonly type = '[FormRenderer] Set form status';

  constructor(
    public key: string,
    public valid: boolean,
  ) {
  }
}

export class ResetFormValue {
  public static readonly type = '[FormRenderer] Reset form value';

  constructor(
    public key: string,
  ) {
  }
}

export class SetSubmitted {
  public static readonly type = '[FormRenderer] Set Submitted';

  constructor(
    public key: string,
  ) {
  }
}

export class SetSourceValue {
  public static readonly type = '[FormRenderer] Set Source value';

  constructor(
    public key: string,
    public value: any,
  ) {
  }
}

export class GetSourceValue {
  public static readonly type = '[FormRenderer] Set Source value';

  constructor(
    public key: string,
  ) {
  }
}

export class SetFormValidationErrors {
  public static readonly type = '[FormRenderer] Set Validation Errors';

  constructor(
    public key: string,
    public validationErrors: FormValidationMessageType,
  ) {
  }
}

export class ResetFormFieldValidationErrors {
  public static readonly type = '[FormRenderer] Reset Validation Errors';

  constructor(
    public key: string,
    public field: string,
  ) {
  }
}
