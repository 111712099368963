<div class="body-part">
  <h5 class="multi-factor-form--section-title">
    <span>{{ paragraphNumbers?.description }}.</span>&nbsp;{{ 'auth.2fa.' + method + '.title' | translate }}
  </h5>

  <p class="multi-factor-form--section-description">
    {{ 'auth.2fa.' + method + '.description' | translate }}
  </p>
</div>

<form [formGroup]="emailForm">
  <cap-input type="text"
             styleClass="w-100 mt-3"
             formControlName="email">
  </cap-input>

  <cap-button type="submit"
              class="w-100"
              styleClass="primary w-100"
              [disabled]="emailForm.invalid"
              [label]="(emailSent ? 'auth.2fa.' + method + '.button.resend' : 'auth.2fa.' + method + '.button.send') | translate"
              (click)="sendEmail()">
  </cap-button>

  <h6 class="done-text mt-3 mb-4" *ngIf="emailSent">
    <i class="fas fa-check-circle"></i> {{ 'auth.2fa.' + method + '.send' | translate }}
  </h6>
</form>
