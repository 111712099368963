import { AfterViewInit, Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'em-formly-color-picker',
  templateUrl: './color-picker.component.html'
})
export class ColorPickerTypeComponent extends FieldType implements AfterViewInit {
  public hexColorRegexp = new RegExp('^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$');

  public ngAfterViewInit(): void {
    if (this.formControl.value) {
      this.onInput({ target: { value: this.formControl.value } });
    }
  }

  public onInput(event: any): void {
    if (this.hexColorRegexp.test(event.target.value)) {
      this.formControl.patchValue(event.target.value);
    }
  }

  public onBlur(event: any): void {
    if (!this.hexColorRegexp.test(event.target.value)) {
      event.target.value = this.formControl.value;
    }
  }

}
