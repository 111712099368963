import { CdkDropList } from '@angular/cdk/drag-drop';
import { Directive, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { DragDropManagerService } from '../services/drag-drop-manager.service';

@Directive({
  selector: '[cpbDragDropManager]'
})
export class DragDropManagerDirective implements OnInit, OnDestroy {
  private manager!: Subscription;
  private rootDropListId = 'widget-content-list';
  private optionsDropListId = 'widget-options-list';

  constructor(
    private dropList: CdkDropList,
    private managerService: DragDropManagerService,
  ) { }

  public ngOnInit(): void {
    this.managerService.register(this.dropList.id);
    this.manager = this.managerService.onListChange().subscribe((dropList) => {
      const optionListIndex = dropList.findIndex(list => list === this.optionsDropListId);

      if (optionListIndex > -1) {
        dropList.unshift(...dropList.splice(optionListIndex, 1));
      }

      const rootListIndex = dropList.findIndex(list => list === this.rootDropListId);

      if (rootListIndex > -1) {
        dropList.unshift(...dropList.splice(rootListIndex, 1));
      }

      this.dropList.connectedTo = dropList.reverse().filter(list => list !== this.dropList.id);
    });
  }

  public ngOnDestroy(): void {
    this.manager.unsubscribe();
    this.managerService.unregister(this.dropList.id);
  }
}

// Create a root directive that uses scoped drag&drop manager service.
// This allows us to have multiple independant nested drag&drop lists
@Directive({
  selector: '[cpbDragDropManagerRoot]',
  providers: [DragDropManagerService],
})
export class DragDropManagerRootDirective extends DragDropManagerDirective {
}
