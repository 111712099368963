import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'capDate',
})
export class CapturumDatePipe implements PipeTransform {
  private datePipe = new DatePipe('en-US');

  public transform(value: string | Date, dateFormatting: string = 'mediumDate', defaultValue: string = '-'): string {
    const adjustedValue = this.checkIsSafariBrowser(value);

    if (!adjustedValue || !new Date(adjustedValue as string).getTime()) {
      return defaultValue;
    }

    return this.datePipe.transform(
      new Date(adjustedValue as string),
      dateFormatting,
    );
  }

  private checkIsSafariBrowser(date: unknown): unknown {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    if (isSafari && date && !(date instanceof Date)) {
      date = (date as string).replace(/ /g, 'T');
    }

    return date;
  }
}
