import { Injectable } from '@angular/core';
import { InputTypeBuilder } from '../input/input-type.builder';
import { FormInputConfig } from '../../interfaces/form-input-config';
import { LabelInputConfiguration } from '../../models/label-input-configuration.model';
import { InputConfiguration, InputType } from '@capturum/builders/core';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Injectable({ providedIn: 'root' })
export class LabelInputTypeBuilder extends InputTypeBuilder implements FormInputConfig<LabelInputConfiguration> {
  protected inputType: string = InputType.label;

  protected appendOptions(input: FormlyFieldConfig, configuration: InputConfiguration): void {
    input.wrappers = ['content'];
    input.props = { ...input.props, css_class: configuration?.options?.css_class };
  }
}
