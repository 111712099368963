import { Pipe, PipeTransform } from '@angular/core';
import { TableColumn } from '@capturum/ui/api';

/**
 * @jsdoc Pipe
 *
 * @example
 * columns | isFrozen => Check if is frozen table
 * columns | isFrozen:col => Check if is frozen column in unfrozen table
 */

@Pipe({
  name: 'isFrozen'
})
export class IsFrozenPipe implements PipeTransform {
  public transform(columns: TableColumn[], column?: TableColumn): boolean {
    if (columns?.length) {
      if (column) {
        return column.frozen && !columns[columns.length - 1].frozen;
      }

      return columns[columns.length - 1].frozen;
    } else {
      return false;
    }
  }
}
