import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'cpb-hidden-wrapper',
  template: '',
})
export class CapturumBuilderHiddenWrapperComponent extends FieldWrapper {

}
