import { IndexedDbModel } from '../../../shared/indexedDb/indexedDb.model';

// @dynamic
export class ModuleIndexedDbModel extends IndexedDbModel {
  public static modules: ModuleIndexedDbModel[] = [];

  /**
   * Load current modules in static cache
   */
  public static async loadModules(): Promise<void> {
    ModuleIndexedDbModel.modules = await ModuleIndexedDbModel.query().toArray();
  }

  /**
   * Get module by given name
   */
  public static getByName(name: string): ModuleIndexedDbModel | undefined {
    if (!ModuleIndexedDbModel.modules) {
      return undefined;
    }

    return ModuleIndexedDbModel.modules.find(module => module.name === name);
  }

  /**
   * Get the active modules
   *
   * @return list of modules
   */
  public static async getModules(): Promise<ModuleIndexedDbModel[]> {
    return await ModuleIndexedDbModel.query().toArray();
  }

  // Configuration
  public entity = 'module';
  public table = 'modules';
  public schema = '++id, name';
  public sync = false;

  // Fields
  public name: string;
  public description: string;

  // Constructor
  constructor(attributes: Partial<ModuleIndexedDbModel>) {
    super(attributes);
  }
}
