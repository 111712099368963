import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { InputType } from '@capturum/builders/core';
import { TextInputTypeBuilder } from '../text/text-input-type.builder';
import { TextInputConfiguration } from '../../models/text-input-configuration.model';

@Injectable({ providedIn: 'root' })
export class DateInputTypeBuilder extends TextInputTypeBuilder {
  protected inputType: string = InputType.date;

  protected appendOptions(input: FormlyFieldConfig, configuration: TextInputConfiguration): void {
    if (input.props) {
      input.props.start_date = configuration.options?.start_date || null;
      input.props.min_date = configuration.options?.min_date ? new Date(configuration.options?.min_date) : null;
      input.props.max_date = configuration.options?.max_date ? new Date(configuration.options?.max_date) : null;
      input.props.date = configuration.options?.date ?? null;
      input.props.time = configuration.options?.time ?? null;
      input.props.timezone = configuration.options?.timezone ?? null;
      input.props.selectionMode = configuration.options?.selectionMode ?? null;
      input.props.appendTo = configuration.options?.appendTo ?? null;
    }
  }

  protected setDefaultValue(input: FormlyFieldConfig, value: any): void {
    const defaultDate = Array.isArray(input.defaultValue)
      ? [new Date(input.defaultValue[0]), new Date(input.defaultValue[1])]
      : input.defaultValue ? new Date(input.defaultValue) : null;
    const isValidDate = Array.isArray(defaultDate)
      ? defaultDate.every((date) => date && !isNaN(date.getTime()))
      : defaultDate && !isNaN(defaultDate.getTime());

    input.defaultValue = isValidDate ? defaultDate : null;
  }
}
