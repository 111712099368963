import { Injectable, Injector, OnDestroy } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilderGroup, FormBuilderGroupConfiguration } from '../models/form-builder-group.model';
import { FormRendererApiService } from './form-renderer-api.service';
import { FormInputHelperService } from './form-input-helper.service';
import { FormFieldsConfig } from '../interfaces/form-fields-config';
import { CapturumBuildersContextService, InputConfiguration, InputType } from '@capturum/builders/core';
import { FormRendererConfigService } from '../form-renderer.config';
import { inputTypeWrappers } from '../configs/input-type-wrappers.config';
import { Store } from '@ngxs/store';
import {
  ResetFormValue,
  SetFormConfiguration,
  SetFormStatus,
  SetFormValue,
} from '../state/form-builder/form-builder.actions';
import { FormBuilderConfig } from '../models/form-builder-config.model';
import { FormRendererState } from '../state/form-renderer/form-renderer.state';
import { SetFormValidationErrors, SetSourceValue, SetSubmitted } from '../state/form-renderer/form-renderer.actions';
import { LayoutTemplateConfig } from '../interfaces/template-config.interface';
import { defaultLayoutTemplates } from '../configs/default-layout-templates.configs';
import { CustomInputTypeBuilder } from '../builders/custom/custom-input-type.builder';
import { FormFieldsManipulatorUtil } from '../utils/form-fields-manipulator.util';

@Injectable({ providedIn: 'root' })
export class FormRendererService implements OnDestroy {
  private formKey: string;

  constructor(
    private readonly formRendererApiService: FormRendererApiService,
    private readonly injector: Injector,
    private readonly translateService: TranslateService,
    private readonly formInputHelperService: FormInputHelperService,
    private readonly formRendererConfig: FormRendererConfigService,
    private readonly store: Store,
    private readonly contextService: CapturumBuildersContextService
  ) {}

  public ngOnDestroy(): void {
    this.formInputHelperService.ngOnDestroy();
  }

  public getFields(
    formKey: string,
    defaultValue: any,
    contextKey?: string,
    cacheOptions?: { cache: boolean; cacheWithoutCheckingContext: boolean }
  ): Observable<FormFieldsConfig> {
    this.formKey = formKey;
    const dynamicForm =
      this.formRendererConfig.dynamicForms &&
      this.formRendererConfig.dynamicForms.find((form: any) => form.key === formKey);
    const formBuilderRequest = dynamicForm
      ? dynamicForm.callback(this.injector)
      : this.retrieveBuilderConfig(formKey, contextKey, cacheOptions);

    return formBuilderRequest.pipe(
      map((formConfigs) => this.getFieldConfig(formConfigs, contextKey || formKey, defaultValue))
    );
  }

  public getFieldConfig(formConfigs: FormBuilderConfig, formKey: string, defaultValue: any): FormFieldsConfig {
    const formConfigGroups = formConfigs.groups;
    const formlyConfig: FormlyFieldConfig[] = [];
    const layoutTemplates = this.getLayoutTemplates();
    const formLayoutTemplate = layoutTemplates.hasOwnProperty(formConfigs?.template)
      ? layoutTemplates[formConfigs?.template]
      : layoutTemplates.default;
    const locationGroups: Record<string, FormBuilderGroupConfiguration[]> = formConfigGroups.reduce((acc, group) => {
      acc[group.location] = [...(acc[group.location] || []), group];

      return acc;
    }, {});

    for (const location in locationGroups) {
      if (locationGroups.hasOwnProperty(location)) {
        const locationTemplate = formLayoutTemplate.locations[location];
        const locationGroup = this.getContainerFormGroupConfig(locationTemplate?.containerClass || null);

        locationGroup.fieldGroup = locationGroups[location].map((locationField) => {
          return this.getWrapperFormGroup(locationField, locationTemplate, formKey, defaultValue);
        });

        formlyConfig.push(locationGroup);
      }
    }

    return {
      inputs: formlyConfig,
      permissions: formConfigs.permissions,
      title: formConfigs.title,
      callbacks: formConfigs.callbacks,
    };
  }

  public updateFormValueState(key: string, value: any): void {
    this.store.dispatch(new SetFormValue(key, value));
  }

  public updateFormStatus(key: string, value: boolean): void {
    this.store.dispatch(new SetFormStatus(key, value));
  }

  public resetFormValue(key: string): void {
    this.store.dispatch(new ResetFormValue(key));
  }

  public setSourceValue(key: string, value: any): void {
    this.store.dispatch(new SetSourceValue(key, value));
  }

  public submitToBackend(key: string, value: any): Observable<any> {
    return this.formRendererApiService.submitFormValue(key, value);
  }

  public getFormValueByKey<T = any>(key: string): Observable<T> {
    return this.store.select(FormRendererState.formValueByKey(key)).pipe(take(1));
  }

  public getFormValueSnapshotByKey<T = unknown>(key: string): T {
    return this.store.selectSnapshot(FormRendererState.formValueByKey(key));
  }

  public getFormValueStreamByKey<T = any>(key: string): Observable<T> {
    return this.store.select(FormRendererState.formValueByKey(key));
  }

  public getSourceValueByKey<T = any>(key: string): Observable<T> {
    return this.store.select(FormRendererState.sourceValueByKey(key));
  }

  public getFormConfigurationByKey(key: string): Observable<FormBuilderConfig> {
    return this.store.select(FormRendererState.configurationByKey(key));
  }

  public setConfig(config: FormBuilderConfig, key: string): void {
    this.store.dispatch(new SetFormConfiguration(key, config));
  }

  public setIsSubmitted(key: string): void {
    this.store.dispatch(new SetSubmitted(key));
  }

  public getInputByConfigs(configs: InputConfiguration, formKey: string, defaultValue: any): FormlyFieldConfig | null {
    const builderClass = this.formRendererConfig.builders[configs.type];
    let fieldConfig = null;

    if (builderClass) {
      fieldConfig = this.injector
        .get(builderClass)
        .getInput(configs, formKey, defaultValue, this.formRendererConfig.defaultEmptyValue);
    } else if (configs.type !== 'group') {
      fieldConfig = this.injector
        .get(CustomInputTypeBuilder)
        .getInput(configs, formKey, defaultValue, this.formRendererConfig.defaultEmptyValue);
    }

    return fieldConfig;
  }

  public resetValidationErrors(key: string): void {
    this.store.dispatch(new SetFormValidationErrors(key, null));
  }

  public retrieveBuilderConfig(
    formKey: string,
    contextKey?: string,
    cacheOptions?: { cache: boolean; cacheWithoutCheckingContext?: boolean }
  ): Observable<FormBuilderConfig> {
    const context = this.contextService.getContextByKey(contextKey || formKey);

    if (cacheOptions?.cache) {
      const config = this.store.selectSnapshot(
        FormRendererState.formByKeyAndContext(contextKey || formKey, context, cacheOptions?.cacheWithoutCheckingContext)
      );

      if (config) {
        return of(config.configuration);
      }
    }

    return this.formRendererApiService.getFormBuilderByKey(formKey, contextKey);
  }

  private getContainerFormGroupConfig(containerClass: string): FormBuilderGroup {
    return {
      fieldGroup: [],
      fieldGroupClassName: containerClass ?? null,
    };
  }

  private getWrapperFormGroup(
    group: FormBuilderGroupConfiguration,
    template: any,
    key: string,
    defaultValue: any
  ): FormBuilderGroup {
    const hasWrapper = inputTypeWrappers.some((item) => item.name === group.location);
    const wrapper = hasWrapper ? group.location : null;

    const inputs = group.inputs.map((input) => {
      return this.getInputByConfigs(input, key, defaultValue);
    });

    return {
      type: FormFieldsManipulatorUtil.getFormlyTypeName(InputType.group),
      fieldGroup: inputs,
      className: template?.groupClass ?? null,
      wrappers: group.collapsable ? ['collapsable-wrapper'] : wrapper ? [wrapper] : null,
      label: group.label
        ? this.translateService.instant(`${this.formRendererConfig.translationKeyPrefix}.${group.label}`)
        : null,
      description: group.description
        ? this.translateService.instant(`${this.formRendererConfig.translationKeyPrefix}.${group.description}`)
        : null,
      props: {
        collapsed: group.collapsed,
        title: group.name,
        tooltip: group.tooltip ? group.tooltip : null,
      },
    };
  }

  private getLayoutTemplates(): LayoutTemplateConfig {
    return {
      ...defaultLayoutTemplates,
      ...this.formRendererConfig.layoutTemplates,
    };
  }

  private areObjectsEqual(object1: object, object2: object): boolean {
    return (
      object1 &&
      object2 &&
      Object.keys(object1).length === Object.keys(object2).length &&
      Object.keys(object1).every((key) => object1[key] === object2[key])
    );
  }
}

