import { Pipe, PipeTransform } from '@angular/core';

/**
 * @jsdoc Pipe
 *
 * @description
 * This pipe is used to extract property value by key
 *
 * @example
 *  customer | propertyValue: 'first_name'
 */
@Pipe({
  name: 'propertyValue',
})
export class CapturumPropertyValuePipe implements PipeTransform {
  public transform(item: any, property: string): any {
    if (property.includes('.')) {
      return property.split('.')
        .reduce((object, key) => object ? object[key] : '-', item);
    }

    return item[property];
  }
}
