import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { FormRendererState } from '../../state/form-renderer/form-renderer.state';
import { LabelPosition } from '@capturum/builders/core';

@Component({
  selector: 'cpb-checkbox-wrapper',
  templateUrl: './checkbox-wrapper.component.html',
  styleUrls: ['./checkbox-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CapturumBuilderCheckboxWrapperComponent extends FieldWrapper implements OnInit {
  public submitted: Observable<boolean>;
  public labelPosition: typeof LabelPosition = LabelPosition;

  constructor(private readonly store: Store) {
    super();
  }

  public ngOnInit(): void {
    this.submitted = this.store.select(FormRendererState.isSubmitted(this.to.formKey));
  }
}
