import { ListRendererConfig } from '../models/list-renderer-config.model';

export class SetListConfiguration {
  public static readonly type = '[ListRenderer] Set list configuration';

  constructor(
    public key: string,
    public configuration: ListRendererConfig,
  ) {
  }
}

export class GetListConfigurationByKey {

}
