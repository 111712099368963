import { LocationStrategy } from '@angular/common';
import {
  Attribute,
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterLink } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

@Directive({
  selector: '[capRouterLink], a[routerLink], area[routerLink]',
  providers: [
    {
      provide: RouterLink,
      useExisting: CapturumRouterLinkDirective,
    },
  ],
})
export class CapturumRouterLinkDirective extends RouterLink implements OnDestroy, OnChanges {
  @HostBinding('attr.target')
  @Input()
  public target?: string;

  @HostBinding('attr.href')
  public href: string = null;

  private isAnAnchorElement: boolean;
  private destroy$ = new Subject<void>();
  private onChanges = new Subject<RouterLink>();

  constructor(
    private router2: Router,
    private route2: ActivatedRoute,
    @Attribute('tabindex') private readonly tabIndex: string | null | undefined,
    private readonly renderer2: Renderer2,
    private readonly elementRef: ElementRef,
    private locationStrategyRef: LocationStrategy
  ) {
    super(router2, route2, tabIndex, renderer2, elementRef, locationStrategyRef);

    const tagName = elementRef.nativeElement.tagName?.toLowerCase();
    this.isAnAnchorElement = tagName === 'a' || tagName === 'area';

    if (this.isAnAnchorElement) {
      router2.events.pipe(takeUntil(this.destroy$)).subscribe((url) => {
        if (url instanceof NavigationEnd) {
          this.updateTargetUrlAndHref();
        }
      });
    }
  }

  @Input('capRouterLink')
  public set routerLink(commands: any[] | string | null | undefined) {
    super.routerLink = commands;
  }

  public ngOnChanges(): void {
    this.updateTargetUrlAndHref();
    this.onChanges.next(this);
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  /**
   * override the default Routerlink onClick method to prevent the default behavior
   */
  public onClick(): boolean {
    return false;
  }

  @HostListener('click', ['$event', '$event.button', '$event.ctrlKey', '$event.metaKey'])
  public handleClick(event: Event, button: number, ctrlKey: boolean, metaKey: boolean): boolean {
    if (this.urlTree === null) {
      return true;
    }

    if (this.isAnAnchorElement) {
      // prevent anchor links from opening in new tab with target attribute
      if (typeof this.target === 'string' && this.target !== '_self') {
        return true;
      }
    }

    // Event.button 1 = Auxiliary button, usually the wheel button or the middle button (if present)
    if (metaKey || ctrlKey || (button !== 0 && button === 1)) {
      event.preventDefault();
      event.stopImmediatePropagation();

      this.openNewwindow();

      return false;
    } else {
      const extras = {
        skipLocationChange: this.skipLocationChange,
        replaceUrl: this.replaceUrl,
        state: this.state,
      };

      this.router2.navigateByUrl(this.urlTree, extras);
    }

    return false;
  }

  private openNewwindow(): void {
    const origin = window.location.origin;
    const tabUrl = `${origin}${this.urlTree}`;

    window.open(tabUrl, '_blank');
  }

  private updateTargetUrlAndHref(): void {
    this.href =
      this.urlTree !== null
        ? this.locationStrategyRef.prepareExternalUrl(this.router2.serializeUrl(this.urlTree))
        : null;
  }
}
