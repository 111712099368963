import { FormControl, ValidationErrors } from '@angular/forms';

/**
 * Formly validator for e-mail address
 *
 * @param control
 */
export function emailValidator(control: FormControl): ValidationErrors {

  /**
   * Standard e-mail address validation regular expression
   */
  const emailRegExp: RegExp = new RegExp('[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?');

  return !control.value || emailRegExp.test(control.value) ? null : { 'email': true };
}
