<div class="form-group" [class.has-error]="showError">
  <label *ngIf="to.translateLabel && to.hideLabel !== true" [attr.for]="id" class="d-block">
    {{ to.translateLabel | observablePipe | async }}
    <span *ngIf="to.required && to.hideRequiredMarker !== true">*</span>
  </label>

  <ng-template #fieldComponent></ng-template>

  <div *ngIf="showError" class="invalid-feedback" [style.display]="'block'">
    <cap-validator [control]="field.formControl"></cap-validator>
  </div>

  <small *ngIf="to.description" class="form-text text-muted">{{ to.description | observablePipe | async }}</small>
</div>

