import { ListRendererConfig } from './../models/list-renderer-config.model';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, Subject, take } from 'rxjs';
import { ListRendererState } from '../state/list-renderer.state';

@Injectable({
  providedIn: 'root',
})
export class ListRendererService {
  private _refreshTable = new Subject<void>();

  constructor(private store: Store) { }

  public getRefreshTable(): Observable<void> {
    return this._refreshTable.asObservable();
  }

  public refreshTable(): void {
    this._refreshTable.next();
  }

  public getListConfigByKey(key: string): Observable<ListRendererConfig> {
    return this.store.select(ListRendererState.configurationByKey(key)).pipe(take(1));
  }
}
