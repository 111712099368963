import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '../services/auth.service';
import { AuthConfig } from '../auth.config';

@Injectable()
export class UnauthenticatedGuard  {
  constructor(
    private authService: AuthService,
    private router: Router,
    private config: AuthConfig
  ) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.authService.isAuthenticated()) {
      return true;
    }

    this.router.navigate([this.config.unauthenticatedRejectionRoute]);

    return false;
  }

  public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }
}
