<div [classList]="'cap-table-filters-wrapper ' + wrapperFiltersStyleClass">
  <div [classList]="'cap-table-filters ' + tableFiltersStyleClass">
    <div [classList]="'cap-table-filter ' + filterWrapperStyleClass" *ngIf="searchable">
      <cap-input [styleClass]="filterStyleClass"
                 [class.has-icon]="true"
                 [placeholder]="searchPlaceholder"
                 #input
                 [formControl]="searchControl">
      </cap-input>

      <div class="filter-icon">
        <i class="fas fa-fw fa-search"></i>
      </div>

      <div *ngIf="resettableFilters && searchControl.value" class="reset-filter">
        <i class="fas fa-fw fa-trash-alt" (click)="searchControl.reset('')"></i>
      </div>
    </div>

    <ng-container *ngFor="let tableColumn of tableColumns">
      <ng-container [ngSwitch]="tableColumn.filterType">
        <div *ngSwitchCase="filterType.RANGE"
             [classList]="'cap-table-filter ' + filterWrapperStyleClass + (activeFilters[tableColumn.filterField]?.value ? ' active' : '')">
          <cap-range-filter (change)="onRangeChange(tableColumn.filterField, $event)"
                            [isRangeBetweenDates]="tableColumn.isRangeBetweenDates"
                            [class.has-icon]="!!tableColumn.filterIconClass"
                            [placeholder]="tableColumn.filterPlaceholder | observablePipe | async"
                            [fromLabel]="tableColumn.rangeFilterFromLabel"
                            [toLabel]="tableColumn.rangeFilterToLabel"
                            [isCurrency]="tableColumn.currencyFilter"
                            [updateOnClose]="tableColumn.updateRangeFilterOnClose"
                            [ngModel]="activeFilters[tableColumn.filterField]"
                            [showConfirmButton]="tableColumn.showConfirmRangeButton"
                            [confirmButtonText]="tableColumn.confirmRangeButtonText"
                            [emptyMessage]="tableColumn.rangeFilterEmptyLabel">
          </cap-range-filter>

          <ng-container *ngTemplateOutlet="filterIcon; context:{tableColumn: tableColumn}"></ng-container>
          <ng-container *ngTemplateOutlet="resetFilter; context:{tableColumn: tableColumn}"></ng-container>
        </div>

        <div *ngSwitchCase="filterType.MULTISELECT"
             [classList]="'cap-table-filter ' + filterWrapperStyleClass + (activeFilters[tableColumn.filterField]?.value ? ' active' : '')">
          <cap-multi-select [styleClass]="filterStyleClass"
                            [defaultLabel]="tableColumn.filterPlaceholder | observablePipe | async"
                            (change)="onMultiSelectFilterChange(tableColumn, $event)"
                            (panelHide)="setMultiSelectFilter(tableColumn, multiSelectFilter.value)"
                            [maxSelectedLabels]="1"
                            [showToggleAll]="false"
                            [class.has-icon]="!!tableColumn.filterIconClass"
                            [virtualScroll]="tableColumn.filterVirtualScroll"
                            [filter]="tableColumn.filterSearchable"
                            [appendTo]="tableColumn?.appendTo"
                            [selectedItemsLabel]="itemsSelectedMessage | observablePipe | async"
                            [showHeader]="true"
                            [options]="tableColumn.filterOptions | observablePipe | async"
                            [ngModel]="activeFilters[tableColumn.filterField]?.value"
                            #multiSelectFilter>
            <ng-template *ngIf="tableColumn.filterShowApplyButton" capTemplate="footer">
              <cap-button (onClick)="multiSelectFilter.hide(); setMultiSelectFilter(tableColumn, multiSelectFilter.value)"
                          [label]="applyFilterMessage | observablePipe | async"
                          styleClass="primary mx-2 my-1 apply-filter-button">
              </cap-button>
            </ng-template>
          </cap-multi-select>

          <ng-container *ngTemplateOutlet="filterIcon; context:{tableColumn: tableColumn}"></ng-container>
          <ng-container *ngTemplateOutlet="resetFilter; context:{tableColumn: tableColumn}"></ng-container>
        </div>

        <div *ngSwitchCase="filterType.SELECT"
             [classList]="'cap-table-filter ' + filterWrapperStyleClass + (activeFilters[tableColumn.filterField]?.value ? ' active' : '')">
          <cap-dropdown [styleClass]="filterStyleClass"
                        [placeholder]="tableColumn.filterPlaceholder | observablePipe | async"
                        (change)="setFilter(tableColumn.filterField, filterMatchMode.IN, $event.value, tableColumn.filterTimeout)"
                        [filter]="tableColumn.filterSearchable"
                        [class.has-icon]="!!tableColumn.filterIconClass"
                        [appendTo]="tableColumn?.appendTo"
                        [options]="tableColumn.filterOptions | observablePipe | async"
                        [ngModel]="activeFilters[tableColumn.filterField]?.value">
          </cap-dropdown>

          <ng-container *ngTemplateOutlet="filterIcon; context:{tableColumn: tableColumn}"></ng-container>
          <ng-container *ngTemplateOutlet="resetFilter; context:{tableColumn: tableColumn}"></ng-container>
        </div>

        <div *ngSwitchCase="filterType.DATEPICKER"
             [classList]="'cap-table-filter ' + filterWrapperStyleClass + (activeFilters[tableColumn.filterField]?.value ? ' active' : '')">
          <cap-calendar (clearClick)="setFilter(tableColumn.filterField, tableColumn.filterMatchMode || filterMatchMode.BETWEEN, null)"
                        (close)="setFilter(tableColumn.filterField, tableColumn.filterMatchMode || filterMatchMode.BETWEEN, date.value, tableColumn.filterTimeout)"
                        [ngClass]="{'double-date': date?.value?.length > 1}"
                        [ngModel]="tableColumn.filterDateRange ? getDateFilterValue(tableColumn.filterField) : activeFilters[tableColumn.filterField]?.value"
                        [class.has-icon]="!!tableColumn.filterIconClass"
                        [selectionMode]="tableColumn.filterDateRange ? 'range' : 'single'"
                        [placeholder]="tableColumn.filterPlaceholder | observablePipe | async"
                        [showButtonBar]="tableColumn.filterShowButtonBar"
                        [styleClass]="filterStyleClass"
                        [locale]="calendarLocale"
                        [readOnly]="tableColumn.readonly"
                        [readonlyInput]="tableColumn.readonly"
                        [dateFormat]="calendarLocale.dateFormat || 'dd-mm-yy'"
                        [appendTo]="tableColumn.appendTo || 'body'"
                        [showApplyButton]="tableColumn.filterShowApplyButton"
                        [defaultRanges]="calendarDefaultRanges"
                        [minDate]="tableColumn.filterMinDate"
                        [maxDate]="tableColumn.filterMaxDate"
                        inputStyleClass="table-filter"
                        #date>
          </cap-calendar>

          <ng-container *ngTemplateOutlet="filterIcon; context:{tableColumn: tableColumn}"></ng-container>
          <ng-container *ngTemplateOutlet="resetFilter; context:{tableColumn: tableColumn}"></ng-container>
        </div>

        <div *ngSwitchCase="filterType.INPUT"
             [classList]="'cap-table-filter ' + filterWrapperStyleClass + (activeFilters[tableColumn.filterField]?.value ? ' active' : '')">
          <cap-input [styleClass]="filterStyleClass"
                     [class.has-icon]="!!tableColumn.filterIconClass"
                     [placeholder]="tableColumn.filterPlaceholder | observablePipe | async"
                     #input
                     [attr.data-test]="'filter_' + tableColumn.filterField"
                     (onInput)="setFilter(tableColumn.filterField, filterMatchMode.LIKE, input.value, tableColumn.filterTimeout)"
                     [ngModel]="activeFilters[tableColumn.filterField]?.value">
          </cap-input>

          <ng-container *ngTemplateOutlet="filterIcon; context:{tableColumn: tableColumn}"></ng-container>
          <ng-container *ngTemplateOutlet="resetFilter; context:{tableColumn: tableColumn}"></ng-container>
        </div>

        <div *ngSwitchCase="filterType.CHECKBOX"
             [classList]="'cap-table-filter ' + filterWrapperStyleClass + (activeFilters[tableColumn.filterField]?.value ? ' active' : '')">
          <div class="checkbox-filter">
            <div class="checkbox-filter-label">
              {{ tableColumn.filterPlaceholder | observablePipe | async }}
            </div>

            <cap-checkbox
              (change)="setCheckboxFilter(tableColumn.filterField, $event.checked, tableColumn.checkedCheckboxValue)"
              [binary]="true" [ngModel]="activeFilters[tableColumn.filterField]?.value.length === 2"
              class="float-right">
            </cap-checkbox>

            <ng-container *ngTemplateOutlet="filterIcon; context:{tableColumn: tableColumn}"></ng-container>
          </div>
        </div>

        <div *ngSwitchCase="filterType.TEMPLATE"
             [classList]="'cap-table-filter ' + filterWrapperStyleClass + (activeFilters[tableColumn.filterField]?.value ? ' active' : '')">
          <ng-container *ngIf="templates | columnTemplate: tableColumn?.field as customTemplate"
                        [ngTemplateOutletContext]="{activeFilters: activeFilters, tableColumn: tableColumn}"
                        [ngTemplateOutlet]="customTemplate">
          </ng-container>

          <ng-container *ngTemplateOutlet="resetFilter; context:{tableColumn: tableColumn}"></ng-container>
        </div>
      </ng-container>
    </ng-container>

    <ng-template #filterIcon let-tableColumn="tableColumn">
      <div class="filter-icon" *ngIf="tableColumn.filterIconClass">
        <i [ngClass]="tableColumn.filterIconClass"></i>
      </div>
    </ng-template>

    <ng-template #resetFilter let-tableColumn="tableColumn">
      <ng-container *ngIf="resettableFilters">
        <div class="reset-filter" *ngIf="activeFilters[tableColumn.filterField]?.value">
          <i class="fas fa-fw fa-trash-alt" (click)="resetTableFilter(tableColumn.filterField)"></i>
        </div>
      </ng-container>
    </ng-template>

    <ng-container [ngTemplateOutlet]="afterFiltersTemplate"></ng-container>
  </div>

  <div class="right-filters">
    <ng-container [ngTemplateOutlet]="rightFiltersTemplate"></ng-container>
  </div>
</div>
