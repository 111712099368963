import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'labelsByValue',
})
export class LabelsByValuesPipe implements PipeTransform {
  public transform(values: string[], options: any[], labelKey: string = 'label', valueKey: string = 'key'): string {
    if (values) {
      return options.filter((option) => values.includes(option[valueKey])).map((option) => {
        return option[labelKey];
      }).join(', ');
    }

    return null;
  }
}
