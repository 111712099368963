import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { LabelPosition } from '@capturum/builders/core';

@Component({
  selector: 'cpb-url-input',
  templateUrl: './url-input.component.html',
  styleUrls: ['./url-input.component.scss'],
})
export class CapturumBuilderUrlInputComponent extends FieldType {
  public labelPosition: typeof LabelPosition = LabelPosition;
}
