export enum VaporUploadVisibility {
  private = 'private',
  publicRead = 'public-read',
  publicReadWrite = 'public-read-write',
  awsExecRead = 'awsExecRead',
  authenticatedRead = 'authenticated-read',
  bucketOwnerRead = 'bucket-owner-read',
  bucketOwnerFullControl = 'bucket-owner-full-control',
  logDeliveryWrite = 'log-delivery-write',
}
