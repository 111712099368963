import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CapturumButtonComponent } from './button.component';
import { ButtonModule } from 'primeng/button';
import { CapturumOfflineIndicatorComponent } from './offline-indicator/offline-indicator.component';

@NgModule({
  declarations: [
    CapturumButtonComponent,
    CapturumOfflineIndicatorComponent
  ],
  exports: [
    CapturumButtonComponent,
    CapturumOfflineIndicatorComponent
  ],
  imports: [
    CommonModule,
    ButtonModule,
  ]
})
export class CapturumButtonModule {
}
