import { AccordionActionVisibility } from './accordion-action-visibility.enum';

export interface AccordionConfig {
  texts?: AccordionTexts;
  actions?: AccordionActionItem[];
  openIcon?: string;
  closeIcon?: string;
}

export interface AccordionTexts {
  showText: string;
  hideText: string;
}

export interface AccordionActionItem {
  icon: string;
  key?: string;
  visible?: AccordionActionVisibility;
  action?: () => void;
}
