import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CapturumInputSwitchComponent } from './input-switch.component';
import { CapturumSharedModule } from '@capturum/ui/api';
import { InputSwitchModule } from 'primeng/inputswitch';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    CapturumInputSwitchComponent
  ],
  exports: [
    CapturumInputSwitchComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    InputSwitchModule,
    CapturumSharedModule
  ]
})
export class CapturumInputSwitchModule {
}
