import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'default'
})
export class DefaultPipe implements PipeTransform {
  public transform(value: any, defaultValue: string = '-'): any {
    const isEmptyValue = typeof value !== 'number' && !(!!value);
    const isEmptyArray = Array.isArray(value) && value.length === 0;
    const isEmptyObject = typeof value === 'number' ? false : this.isEmptyObject(value);

    return (isEmptyValue || isEmptyArray || isEmptyObject) ? defaultValue : value;
  }

  private isEmptyObject(value: any): boolean {
    return value
      && Object.keys(value).length === 0
      && Object.getPrototypeOf(value) === Object.prototype;
  }
}
