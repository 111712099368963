import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { FormValidationMessageType } from '../../types/form-validation-message.type';
import { Store } from '@ngxs/store';
import { FormRendererState } from '../../state/form-renderer/form-renderer.state';
import { ResetFormFieldValidationErrors } from '../../state/form-renderer/form-renderer.actions';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'cbp-validation-message',
  templateUrl: './validation-message.component.html',
  styleUrls: ['./validation-message.component.scss'],
})
export class CapturumBuilderValidationMessageComponent implements OnInit, OnDestroy {
  @Input()
  public field: FormlyFieldConfig;

  public validationErrors: Observable<FormValidationMessageType>;
  private subscription: Subscription;

  constructor(private readonly store: Store) {
  }

  public ngOnInit(): void {
    if (this.field) {
      this.validationErrors = this.store.select(FormRendererState.validationErrorsByKeyAndControlName(
        this.field.props.formKey,
        this.field.key as string));

      if (this.field.form.get(this.field.key as string)) {
        this.subscription = this.field.form.get(this.field.key as string).valueChanges.subscribe(() => {
          this.store.dispatch(new ResetFormFieldValidationErrors(this.field.props.formKey, this.field.key as string));
        });
      }
    }
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
