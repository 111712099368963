import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LabelPosition } from '@capturum/builders/core';

@Component({
  selector: 'cpb-multi-select-input',
  templateUrl: './multi-select-input.component.html',
  styleUrls: ['./multi-select-input.component.scss'],
})
export class CapturumMultiSelectInputComponent extends FieldType implements OnInit, OnDestroy {
  public labelPosition: typeof LabelPosition = LabelPosition;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  public ngOnInit(): void {
    this.formControl.valueChanges
      .pipe(
        filter((value) => {
          return Array.isArray(value) && value.some((item) => typeof item === 'object');
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((valueObject) => {
        const values = valueObject.map((value) => {
          return value[this.props.sourceValueKey || 'id'] ?? value;
        });

        this.formControl.setValue(values, { emitEvent: false });
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
