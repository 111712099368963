import { Observable } from 'rxjs';
import { InputConfiguration } from '@capturum/builders/core';
import { BuilderAction } from '@capturum/builders/core';
import { MapItem } from '@capturum/ui/api';

export type FormBuilderCallback = Record<string, BuilderAction>;

export interface FormBuilderConfig {
  id?: string;
  key?: string;
  title?: string;
  type?: string;
  template?: string;
  permissions: string[];
  identifier_field?: string;
  source?: FormBuilderSource['action'];
  submit?: FormBuilderSource['action'];
  callbacks?: FormBuilderCallback;
  groups: InputGroup[];
  label?: string;
  actions?: {
    [key: string]: {
      key: string;
      options?: any;
    }
  };
  label_position?: string | Observable<string>;
  comment?: string;
  options?: Record<string, unknown>;
}

export interface FormBuilderSource {
  action?: {
    endpoint: string;
    options: any;
  };
  source?: {
    image_key?: string,
  };
  items?: MapItem[];
}

export interface InputGroup {
  inputs: InputConfiguration[];
  location: string;
  id?: string;
  group_index?: number;
}
