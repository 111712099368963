export interface LayoutTemplateConfig {
  [key: string]: LayoutTemplate;
}

export interface LayoutTemplate {
  amountOfContentGroups: number;
  locations: {
    content?: LocationTemplate;
    footer?: LocationTemplate;
    header?: LocationTemplate;
    [key: string]: any;
  };
}

export interface LocationTemplate {
  containerClass: string;
  groupClass: string;
}
