import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
} from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { CompleteBaseForm } from '../../../../shared/components/complete-base-form';
import { delay } from 'rxjs/operators';
import { passwordValidator } from '@capturum/formly';

@Component({
  selector: 'emc-password-form',
  templateUrl: './password-form.component.html',
  styleUrls: ['./password-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasswordFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PasswordFormComponent),
      multi: true,
    },
  ],
})
export class PasswordFormComponent extends CompleteBaseForm implements AfterViewInit {
  @Input()
  public isEdit: boolean;
  @Input()
  public passwordValidator: (control: FormControl) => ValidationErrors = passwordValidator;

  public fields: FormlyFieldConfig[];

  constructor(private translateService: TranslateService,
              private changeDetectorRef: ChangeDetectorRef,
  ) {
    super(changeDetectorRef);
  }

  public ngAfterViewInit(): void {
    this.listenToFormChanges();
    this.createForm();
    this.updateFormValue();
    this.changeDetectorRef.detectChanges();
  }

  public listenToFormChanges(): void {
    this.formSubscription = this.onFormChange().pipe(delay(0)).subscribe((value) => {
      this.formChanged.emit(this.form.value['passwordFields']);
    });
  }

  public createForm(): void {
    this.fields = [
      {
        key: 'passwordFields',
        validators: {
          fieldMatch: {
            expression: (control) => {
              const value = control.value;

              return value.password_confirmation === value.password
                || (!value.password_confirmation || !value.password);
            },
            message: this.translateService.instant('user.detail.password-mismatch.message'),
            errorPath: 'password_confirmation',
          },
        },
        fieldGroup: [
          {
            key: 'password',
            type: 'input',
            props: {
              type: 'password',
              translateLabel: this.translateService.stream('user.detail.password'),
              placeholder: this.translateService.instant('user.detail.password'),
              required: !this.isEdit,
              autocomplete: 'new-password',
            },
            validators: {
              validation: [this.passwordValidator],
            },
            validation: {
              messages: {
                password: (error, field: FormlyFieldConfig) => this.translateService.instant('validation-errors.password'),
              },
            },
          },
          {
            key: 'password_confirmation',
            type: 'input',
            props: {
              type: 'password',
              translateLabel: this.translateService.stream('user.detail.password-confirmation'),
              placeholder: this.translateService.instant('user.detail.password-confirmation'),
              required: !this.isEdit,
              autocomplete: 'new-password',
            },
          },
        ],
      },
    ];
  }
}
