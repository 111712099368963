import { Directive, HostListener, Output, EventEmitter, Input } from '@angular/core';
import { ScrollEvent } from '../models/scroll-event.model';
@Directive({
  selector: '[capDetectScroll]'
})
export class CapturumScrollDirective {
  @Input() public bottomOffset: number = 100;
  @Input() public topOffset: number = 100;
  @Output() public onScroll = new EventEmitter<ScrollEvent>();

  @HostListener('scroll', ['$event'])
  public scrolled($event: Event): void {
    this.elementScrollEvent($event);
  }

  protected elementScrollEvent($event: Event): void {
    const target = $event.target as HTMLElement;
    const scrollPosition = target.scrollHeight - target.scrollTop;
    const offsetHeight = target.offsetHeight;
    const isReachingTop = target.scrollTop < this.topOffset;
    const isReachingBottom = (scrollPosition - offsetHeight) < this.bottomOffset;
    const emitValue: ScrollEvent = { isReachingBottom, isReachingTop, originalEvent: $event, isWindowEvent: false };

    this.onScroll.emit(emitValue);
  }
}
