import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnChanges,
} from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CompleteBaseForm } from '../../../../shared/components/complete-base-form';
import { TranslateService } from '@ngx-translate/core';
import { UtilService } from '../../../../shared/services/util.service';
import { emailValidator, noScript, noSpecialChars } from '@capturum/formly';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { MapItem } from '@capturum/ui/api';
import { UserValidator } from '../../user.validator';
import { UserService } from '../../user.service';

@Component({
  selector: 'emc-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => UserFormComponent),
      multi: true,
    },
  ],
})
export class UserFormComponent extends CompleteBaseForm implements OnChanges, AfterViewInit {
  @Input()
  public isEdit: boolean;
  @Input()
  public roleList: MapItem[] = [];
  @Input()
  public localesList: MapItem[] = [];
  @Input()
  public userId: string;

  @Input()
  public fields: FormlyFieldConfig[];

  constructor(private translateService: TranslateService,
              private utilService: UtilService,
              private apiService: UserService,
              private changeDetectorRef: ChangeDetectorRef,
  ) {
    super(changeDetectorRef);
  }

  public ngAfterViewInit(): void {
    this.listenToFormChanges();
    this.changeDetectorRef.detectChanges();
  }

  public ngOnChanges(): void {
    if (this.localesList?.length && this.roleList?.length) {
      if (!this.fields) {
        this.createForm();
      }

      this.updateFormValue();
    }
  }

  public createForm(): void {
    this.fields = this.fields?.length ? this.fields : [
      {
        type: 'input',
        key: 'name',
        props: {
          translateLabel: this.translateService.stream('user.profile.name'),
          required: true,
          minLength: 2,
          maxLength: 50,
        },
        validators: {
          validation: [noSpecialChars, noScript],
        },
      },
      {
        type: 'input',
        key: 'email',
        props: {
          translateLabel: this.translateService.stream('user.profile.email'),
          type: 'email',
          required: true,
          autocomplete: 'new-email',
        },
        validators: {
          validation: [emailValidator],
        },
        asyncValidators: {
          emailBusy: {
            expression: UserValidator.isEmailTaken(this.apiService, this.userId as string),
            message: this.translateService.instant('validation-errors.email-in-use'),
          },
        },
      },
      {
        key: 'role_ids',
        type: 'multiSelect',
        props: {
          options: this.roleList,
          styleClass: 'w-100',
          required: true,
          filter: true,
          translatePlaceholder: this.translateService.instant('placeholder.select'),
          translateLabel: this.translateService.stream('user.profile.select_role'),
        },
      },
      {
        key: 'locale_id',
        type: 'dropdown',
        props: {
          translateLabel: this.translateService.stream('user.profile.select_language'),
          translatePlaceholder: this.translateService.instant('placeholder.select'),
          options: this.localesList,
          required: true,
        },
      },
    ];
  }
}
