import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';

export interface TableSorting {
  field: string;
  header: string | Observable<string | number>;
  sortDirection: 'desc' | 'asc' | null;
  sortOrder?: number;
}

@Component({
  selector: 'cap-table-sort',
  templateUrl: './table-sort.component.html',
  styleUrls: ['./table-sort.component.scss']
})
export class TableSortComponent {
  @Input()
  public columns: TableSorting[];
  @Input()
  public ascendingLabel: string | Observable<string> = 'Ascending';
  @Input()
  public descendingLabel: string | Observable<string> = 'Descending';
  @Input()
  public sortButtonLabel: string | Observable<string> = 'Sort data';
  @Input()
  public resetSortingLabel: string | Observable<string> = 'Reset sorting';

  @Output()
  public change = new EventEmitter<TableSorting[]>();

  public showSortContainer: boolean;
  public hiddenIndex: number;
  public show = false;

  public drop(event: CdkDragDrop<any[]>): void {
    this.hiddenIndex = event.previousIndex;

    // Temporary solution for primeng radio buttons not remaining their state when dragged and droppped
    setTimeout(() => {
      moveItemInArray(this.columns, event.previousIndex, event.currentIndex);
      this.change.emit(this.columns);
      this.hiddenIndex = null;
    }, 0);
  }

  public onRadioChange(): void {
    this.sortColumns();

    this.change.emit(this.columns);
  }

  /**
   * Reset the sorting for the selected column
   *
   * @param index - the index of the column
   */
  public removeSorting(index: number): void {
    this.columns[index].sortDirection = null;
    this.sortColumns();

    this.change.emit(this.columns);
  }

  public trackByFn(index: number, item: any): string {
    return item.field;
  }

  public closeContainer(clickedElement: HTMLElement): void {
    if (!clickedElement.classList.contains('fa-trash')) {
      this.showSortContainer = false;
    }
  }

  private sortColumns(): void {
    this.columns.sort((a, b) => {
      if (b.sortDirection) {
        return 0;
      } else if (a.sortDirection) {
        return a.sortDirection ? -1 : 1;
      }

      return 0;
    });
  }
}
