import { FormControl, ValidationErrors } from '@angular/forms';

/**
 * Formly validator for password
 *
 * @param control
 */
export function passwordValidator(control: FormControl): ValidationErrors {

  /**
   * Standard password validation regular expression
   * Length must be at least 8 characters
   */
  return !control.value || control.value.length >= 8 ? null : { 'password': true };
}
