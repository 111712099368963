<div class="list-renderer-settings-sidebar">
  <p-tabView>
    <p-tabPanel *ngIf="filtersData" [header]="'table.filters' | translate">
      <span class="placeholder-text">
        {{ 'table-settings.filters.description' | translate }}
      </span>
  
      <div *ngIf="globalSearch" class="settings-list-items__item d-flex align-items-center mb-2">
        <span class="flex-1">{{ 'search.placeholder' | translate }}</span>
      </div>
  
      <ng-container *ngTemplateOutlet="settingsList; context: { data: filtersData }"></ng-container>
    </p-tabPanel>
  
    <p-tabPanel *ngIf="columnsData" [header]="'table.columns' | translate">
      <span class="placeholder-text">
        {{ 'table-settings.columns.description' | translate }}
      </span>
  
      <ng-container *ngTemplateOutlet="settingsList; context: { data: columnsData }"></ng-container>
    </p-tabPanel>
  </p-tabView>
  
  <ng-template #settingsList let-data="data">
    <div class="settings-list-items" cdkDropList (cdkDropListDropped)="onListReorder($event, data)">
      <div *ngFor="let item of data" 
           class="settings-list-items__item d-flex align-items-center mb-2"
           cdkDragBoundary=".cap-sidebar-content" 
           cdkDrag
           [cdkDragDisabled]="!item.reorderable">
        <span class="flex-1">{{ item.label }}</span>
  
        <div class="hide-handle" (click)="item.hidden = !item.hidden">
          <i [class]="item.hidden ? 'fas fa-eye' : 'fas fa-eye-slash'"></i>
        </div>
      
        <div *ngIf="item.reorderable" class="drag-handle" cdkDragHandle>
          <i class="fas fa-arrows-v"></i>
        </div>
      </div>
    </div>
  </ng-template>
  
  <div class="sidebar-settings-buttons">
    <cap-button styleClass="secondary" [label]="'button.cancel' | translate" (onClick)="cancel()"></cap-button>
  
    <cap-button styleClass="success" icon="fas fa-check" [label]="'apply.button' | translate" (onClick)="applySettingsChanges()"></cap-button>
  </div>
</div>
