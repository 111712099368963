import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  public menus: any;

  public ngOnInit(): void {
    this.populateSidebar();
  }

  private populateSidebar(): void {
    this.menus = [
      {
        title: 'Form builder',
        items: [
          {
            label: 'Repeatable',
            value: '/maker/builder/repeat',
            icon: 'fas fa-th-large',
            key: 'main-menu',
          },
          {
            label: 'Renderer',
            value: '/maker/builder/form-renderer-preview',
            icon: 'fas fa-th-large',
            key: 'main-menu',
          },
        ],
      },
      {
        title: 'List Renderer',
        items: [
          {
            label: 'List Renderer',
            value: '/maker/builder/list-renderer-preview',
            icon: 'fas fa-list-ol',
            key: 'second-menu',
          },
        ],
      },
      {
        title: 'Page Renderer',
        items: [
          {
            label: 'Page Renderer',
            value: '/maker/builder/page-renderer-preview',
            icon: 'fas fa-shapes',
            key: 'second-menu',
          },
        ],
      },
      {
        title: 'Pages',
        items: [
          {
            label: 'Release list',
            value: '/maker/builder/release',
            icon: 'fas fa-list-ol',
            key: 'second-menu',
          },
          {
            label: 'Config list',
            value: '/maker/builder/configuration',
            icon: 'fas fa-list-ol',
            key: 'second-menu',
          },
          {
            label: 'Project config list',
            value: '/maker/builder/project-config',
            icon: 'fas fa-list-ol',
            key: 'second-menu',
          },
        ],
      },
      {
        title: 'Practice assignment',
        items: [
          {
            label: 'Rental car list',
            value: '/rental-cars',
            icon: 'fas fa-car',
            key: 'third-menu',
          },
        ],
      },
      {
        title: 'Power BI',
        items: [
          {
            label: 'Reports',
            value: '/reports',
            icon: 'fas fa-analytics',
            key: 'fourth-menu',
          },
          {
            label: 'Manage reports',
            value: '/power-bi/reports',
            icon: 'fas fa-analytics',
            key: 'fourth-menu',
          },
          {
            label: 'Manage clients',
            value: '/power-bi/clients',
            icon: 'fas fa-user-chart',
            key: 'fourth-menu',
          },
        ],
      },
      {
        title: 'Flow',
        items: [
          {
            label: 'Action statistics',
            value: '/flow/actions',
            icon: 'fas fa-analytics',
            key: 'fourth-menu',
          },
        ],
      },
    ];
  }
}
