import { FormlyFieldConfig, FormlyFieldProps } from '@ngx-formly/core';
import { InputType } from '@capturum/builders/core';

export class FormFieldsManipulatorUtil {
  public static getFormInputByKey(fieldGroup: FormlyFieldConfig[], key: string): FormlyFieldConfig {
    let selectedFieldGroup = null;

    for (const fieldConfig of fieldGroup) {
      if (fieldConfig.key && fieldConfig.key === key) {
        selectedFieldGroup = fieldConfig;
        break;
      } else if (fieldConfig?.fieldGroup?.length && !selectedFieldGroup) {
        selectedFieldGroup = FormFieldsManipulatorUtil.getFormInputByKey(fieldConfig?.fieldGroup, key);
      }
    }

    return selectedFieldGroup;
  }

  public static patchprops(formConfig: FormlyFieldConfig[], key: string, props: FormlyFieldProps): FormlyFieldConfig[] {
    const input = FormFieldsManipulatorUtil.getFormInputByKey(formConfig, key);

    input.props = { ...input.props, ...props };

    return formConfig;
  }

  public static patchFormField(formConfig: FormlyFieldConfig[], key: string, fieldConfig: FormlyFieldConfig): FormlyFieldConfig[] {
    const input = FormFieldsManipulatorUtil.getFormInputByKey(formConfig, key);

    for (const fieldConfigKey in fieldConfig) {
      if (input.hasOwnProperty(fieldConfigKey) && fieldConfig.hasOwnProperty(fieldConfigKey)) {
        if (fieldConfigKey === 'props') {
          input[fieldConfigKey] = { ...input[fieldConfigKey], ...fieldConfig[fieldConfigKey] };
        } else {
          input[fieldConfigKey] = fieldConfig[fieldConfigKey];
        }
      }
    }

    return formConfig;
  }

  public static getFormlyTypeName(inputType: InputType | string): string {
    return `cpb-${inputType}`;
  }
}
