import { Params } from '@angular/router';
import { ActiveFilters } from '../interfaces/dynamic-filter.interface';
import { FilterMatchMode } from '@capturum/ui/api';

export class DynamicFiltersUtils {
  public static generateStaticQueryUrlFromActiveFilters(filters: ActiveFilters[]): Params {
    let queryParams: Params = {};

    filters.forEach((filter) => {
      let filterValue = filter.value;

      if (filter.value && Array.isArray(filter.value)) {
        filterValue = [...filter.value].join(',');
      }
      
      queryParams = { ...queryParams, [`[staticFilter][${filter.field}][${filter.matchMode || ''}]`]: filterValue };
    });
    
    return queryParams;
  }

  public static generateQueryUrlFromActiveFilters(filters: ActiveFilters[]): Params {
    let queryParams: Params = {};

    filters.forEach((filter) => {
      let filterValue = filter.value;

      if (filter.value && Array.isArray(filter.value)) {
        filterValue = [...filter.value].join(',');
      }
      
      queryParams = { ...queryParams, [`[filter][${filter.field}][${filter.matchMode || ''}]`]: filterValue };
    });

    return queryParams;
  }

  public static retrieveQueryParamFilters(queryParams: Params, staticFilters = false, tableKey?: string): ActiveFilters[] {
    const activeFilters = Object.keys(queryParams).reduce((agg, key) => {
      let splitByOpeningBracket = key.split('[');
      splitByOpeningBracket.shift();

      if (splitByOpeningBracket?.length && splitByOpeningBracket[0].includes(staticFilters ? 'staticFilter' : 'filter')) {
        splitByOpeningBracket = splitByOpeningBracket.map((item) => {
          return item.replace(']', '');
        });
     
        const field = splitByOpeningBracket[1];
        const matchMode = splitByOpeningBracket[2];
        const value =
          matchMode === FilterMatchMode.EQUALS || 
          matchMode === FilterMatchMode.LIKE || 
          matchMode === FilterMatchMode.NOT_LIKE || 
          matchMode === FilterMatchMode.DATEEQUALS ? 
            queryParams[key] :
            queryParams[key].split(',');

        if (splitByOpeningBracket[3] && tableKey && `${splitByOpeningBracket[3]}` !== `${tableKey}`) {
          return;
        }
        
        agg.push({
          field, value, matchMode,
        });
      }

      return agg;
    }, []) || [];

    return activeFilters
  }
}
