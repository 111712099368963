import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FlowTabs } from '../../enums/flow-tabs.enum';
import { TabView, TabViewModule } from 'primeng/tabview';
import { TranslateModule } from '@ngx-translate/core';
import { CapturumFlowListComponent } from '../flow-list/flow-list.component';
import { FlowListKey } from '../../enums/flow-list-key.enum';
import { CapturumBuilderActionService } from '@capturum/builders/core';
import { FlowService } from '../../services/flow.service';

@Component({
  selector: 'cap-flow-overview',
  templateUrl: './flow-overview.component.html',
  styleUrls: ['./flow-overview.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule, TabViewModule, TranslateModule, CapturumFlowListComponent],
})
export class CapturumFlowOverviewComponent implements OnInit {
  @ViewChild(TabView)
  public tabView: TabView;

  @ViewChild(CapturumFlowListComponent)
  public flowListComponent: CapturumFlowListComponent;

  public selectedTab: string = FlowTabs.actions;
  public FlowTabs = FlowTabs;
  public flowListKey = FlowListKey;
  public activeIndex = 0;

  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private actionService = inject(CapturumBuilderActionService);
  private flowService = inject(FlowService);

  public ngOnInit(): void {
    this.actionService.getActionExecutionByActionKey('log_context').subscribe((action) => {
      if (action.action.type === 'flow_ref') {
        this.selectedTab = FlowTabs.actions;
        this.activeIndex = 0;
        this.flowService.setActionLogsFilter(action.item)
      } else if (action.action.type === 'action') {
        this.selectedTab = FlowTabs.flows;
        this.activeIndex = 1;
      }
    });
  }

  public changeTabIndex(): void {
    this.selectedTab = this.tabView.findSelectedTab().header;

    this.router.navigate([], { relativeTo: this.route, queryParams: { tab: this.selectedTab } });
  }
}
