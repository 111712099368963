import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CalendarConfig } from '../models/calendar-config.model';

@Injectable({
  providedIn: 'root'
})
export class CapturumCalendarService {
  private defaultConfig: CalendarConfig = {
    firstDayOfWeek: 1, // Monday
    dateFormat: 'dd-mm-yy',
    defaultDate: null,
    showTime: false,
    showIcon: true,
    timeLabel: 'Time',
    styleClass: '',
    style: null,
    inputStyle: null,
    inputStyleClass: null,
    placeholder: null,
    icon: 'pi pi-calendar',
    appendTo: null,
    readonlyInput: null,
    shortYearCutoff: '+10',
    monthNavigator: false,
    yearNavigator: false,
    hourFormat: '24',
    timeOnly: false,
    stepHour: 1,
    stepMinute: 1,
    stepSecond: 1,
    showSeconds: false,
    required: false,
    showOnFocus: true,
    showWeek: false,
    dataType: 'date',
    selectionMode: 'single',
    maxDateCount: null,
    showButtonBar: false,
    todayButtonStyleClass: 'ui-button-secondary',
    clearButtonStyleClass: 'ui-button-secondary',
    autoZIndex: true,
    baseZIndex: 0,
    panelStyleClass: null,
    panelStyle: null,
    keepInvalid: false,
    hideOnDateTimeSelect: true,
    hideOnRangeSelect: false,
    numberOfMonths: 1,
    view: 'date',
    touchUI: false,
    timeSeparator: ':',
    showTransitionOptions: '225ms ease-out',
    hideTransitionOptions: '195ms ease-in',
    disabledDates: null,
    disabledDays: null,
    inline: false,
    maxDate: null,
    minDate: null,
    showOtherMonths: true,
    selectOtherMonths: false,
    tabindex: null,
    yearRange: '',
    showApplyButton: false,
    setTimeZone: false,
    sendFormat: 'yyyy-MM-dd',
    sendDateTimeFormat: `yyyy-MM-dd'T'HH:mm:ssxx`,
  };
  private config$: BehaviorSubject<CalendarConfig> = new BehaviorSubject<CalendarConfig>(this.defaultConfig);

  /**
   * Set the configuration
   *
   * @param config: Partial<CalendarConfig>
   * @return void
   */
  public setConfig(config: Partial<CalendarConfig>): void {
    this.config$.next({ ...this.defaultConfig, ...config });
  }

  /**
   * Get the configuration as observable
   *
   * @return Observable<CalendarConfig>
   */
  public getConfig(): Observable<CalendarConfig> {
    return this.config$.asObservable();
  }
}
