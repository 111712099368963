import { Component, ViewEncapsulation } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'cpb-number-format-input',
  templateUrl: './number-format-input.component.html',
  styleUrls: ['./number-format-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CapturumBuilderNumberFormatInputComponent extends FieldType {
}
