import * as UserInterface from '@capturum/auth/lib/user.interface';
import { Role } from '../role/models/role.model';

export class User implements UserInterface.default {
  public id: number | string;
  public name: string;
  public locale_id?: number | string;
  public role_ids?: string[];
  public roles: Role[];
  public password?: string;
  public password_confirmation?: string;
  public email: string;
  public status?: string;
  public passwordFields?: { password: string, password_confirmation: string };
  public current_role?: Role;
}
