import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { PermissionService } from '../services/permission.service';

@Injectable()
export class PermissionResolver  {
  constructor(private permissionService: PermissionService) {}

  /**
   * Make sure the settings are loaded before accessing the route
   */
  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.permissionService.loadPermissions();
  }
}
