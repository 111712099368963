<cap-calendar [formlyAttributes]="field"
              [formControl]="formControl"
              [floatLabel]="(to.label_position | observablePipe | async ) === 'floating'"
              [label]="(to.label_position | observablePipe | async ) === 'floating' ? to.label : ''"
              [defaultDate]="to.defaultDate || null"
              [selectionMode]="to.selectionMode || 'single'"
              [style]="to.style || null"
              [inputStyle]="to.inputStyle || null"
              [inputId]="field.key"
              [name]="field.key"
              [placeholder]="(to.translatePlaceholder || to.placeholder) | observablePipe | async"
              [disabled]="to.disabled"
              [dateFormat]="to.dateFormat || 'dd-mm-yy'"
              [inline]="to.inline || false"
              [showOtherMonths]="to.showOtherMonths || true"
              [selectOtherMonths]="to.selectOtherMonths"
              [showIcon]="to.showIcon || true"
              [showOnFocus]="to.showOnFocus || true"
              [icon]="to.icon || 'pi pi-calendar'"
              [appendTo]="to.appendTo || null"
              [readonlyInput]="to.readOnlyInput || null"
              [shortYearCutoff]="to.shortYearCutoff || '+10'"
              [minDate]="to.minDate || null"
              [maxDate]="to.maxDate || null"
              [disabledDates]="to.disabledDates || null"
              [disabledDays]="to.disabledDays || null"
              [monthNavigator]="to.monthNavigator || false"
              [yearNavigator]="to.yearNavigator || false"
              [yearRange]="to.yearRange || false"
              [showTime]="to.showTime || false"
              [hourFormat]="to.hourFormat || '24'"
              [timeOnly]="to.timeOnly || false"
              [dataType]="to.dataType || 'date'"
              [required]="to.required || false"
              [tabIndex]="to.tabindex || null"
              [showSeconds]="to.showSeconds"
              [stepHour]="to.stepHour || 1"
              [stepMinute]="to.stepMinute || 1"
              [stepSecond]="to.stepSecond || 1"
              [maxDateCount]="to.maxDateCount || null"
              [showButtonBar]="to.showButtonBar || false"
              [todayButtonStyleClass]="to.todayButtonStyleClass || 'ui-secondary-button'"
              [clearButtonStyleClass]="to.clearButtonStyleClass || 'ui-secondary-button'"
              [panelStyleClass]="to.panelStyleClass || null"
              [panelStyle]="to.panelStyle || null"
              [keepInvalid]="to.keepInvalid || false"
              [hideOnDateTimeSelect]="to.hideOnDateTimeSelect || false"
              [numberOfMonths]="to.numberOfMonths || 1"
              [view]="to.view || 'date'"
              [touchUI]="to.touchUI || false"
              [showTransitionOptions]="to.showTransitionOptions || '225ms ease-out'"
              [hideTransitionOptions]="to.hideTransitionOptions || '195ms ease-in'"
              [inputStyleClass]="to.inputStyleClass || null"
              (onSelect)="to.onSelect ? to.onSelect($event) : null"
              (onBlur)="to.onBlur ? to.onBlur($event) : null"
              (onFocus)="to.onFocus ? to.onFocus($event) : null"
              (onClose)="to.onClose ? to.onClose($event) : null"
              (onInput)="to.onInput ? to.onInput($event) : null"
              (onTodayClick)="to.onTodayClick ? to.onTodayClick($event) : null"
              (onClearClick)="to.onClearClick ? to.onClearClick($event) : null"
              (onMonthChange)="to.onMonthChange ? to.onMonthChange($event) : null"
              (onYearChange)="to.onYearChange ? to.onYearChange($event) : null">
</cap-calendar>
