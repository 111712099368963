import { Pipe, PipeTransform } from '@angular/core';
import { SettingIndexedDbModel } from '../models/setting.indexedDb-model';

/*
 * Retrieves tenant or module setting
 * Usage:
 *   settingName | setting:'module name or "tenant"'
 * Example:
 *   {{ name | setting:'tenant' }}
 *   formats to: "Tenant B.V."
 */
@Pipe({ name: 'setting', pure: false })
export class SettingPipe implements PipeTransform {
  public transform(settingName: string,
                   /**
                    * @deprecated just ignore this parameter
                    */
                   moduleName?: string): string {
    return SettingIndexedDbModel.getValue(settingName) as string;
  }
}
