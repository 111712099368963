import * as RoleInterface from '@capturum/auth/lib/role.interface';
import { Permission } from '../../permission/models/permission.api-model';

export class Role implements RoleInterface.default {
  public id: number | string;
  public tenant_id?: string;
  public name: string;
  public key: string;
  public permissions?: Permission[];
}
