export function deepEqual(object1: object, object2: object): boolean {
  const objectKeys = Object.keys,
    typeObject1 = typeof object1,
    typeObject2 = typeof object2;

  return object1 && object2 && typeObject1 === 'object' && typeObject1 === typeObject2 ? (
    objectKeys(object1).length === objectKeys(object2).length &&
    objectKeys(object1).every(key => deepEqual(object1[key], object2[key]))
  ) : (object1 === object2);
}
