import { Component, Input } from '@angular/core';
import { ListRendererWidgetBase } from '../../base/list-renderer-widget-base';

@Component({
  selector: 'cpb-list-renderer-widget-boolean',
  template: `
    <p>
      {{ (!!item[field] ? 'general.yes' : 'general.no') | translate }}
    </p>
  `,
})
export class ListRendererWidgetBooleanComponent extends ListRendererWidgetBase {
  @Input() public default: string = '-';
  public value: string;
}
