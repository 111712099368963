<div [class.password-input]="toggleMask">
  <div *ngIf="toggleMask" class="display-password-toggle" (click)="displayToggle()">
    <i [class]="isPasswordTypeText ? hideIcon || 'fal fa-eye-slash' : showIcon || 'fal fa-eye'"></i>
  </div>

  <cap-input [autocomplete]="autocomplete"
             [class]="'cap-password ' + styleClass"
             [type]="isPasswordTypeText ? 'text' : 'password'"
             [placeholder]="placeholder"
             [readonly]="readonly"
             [disabled]="disabled"
             [styleClass]="styleClass"
             [label]="label"
             [(ngModel)]="value"
             (keyup)="onKeyUp.emit($event)"
             (input)="onInputEvent($event)"
             (blur)="onBlur($event)"
             (focus)="onFocus($event)">
  </cap-input>

  <cap-validator *ngIf="control" [control]="control" [customName]="label"></cap-validator>
</div>
