import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChildren,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { FormControl, NgControl, NgModel } from '@angular/forms';
import { CapturumTemplateDirective, ValidatorService, ValueAccessorBase } from '@capturum/ui/api';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { MultiSelect } from 'primeng/multiselect';

@Component({
  selector: 'cap-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
  providers: [ValueAccessorBase.getProviderConfig(CapturumMultiSelectComponent)],
})
export class CapturumMultiSelectComponent extends ValueAccessorBase<any[]> implements OnDestroy, AfterViewInit, AfterContentInit, OnInit, OnChanges {
  @ViewChild(NgModel, { static: true })
  public model: NgModel;

  @ViewChild('pMultiSelect')
  public pMultiSelect: MultiSelect;

  /**
   * Sort the options alphabetically
   */
  @Input() public sortAlphabetically: boolean = true;
  /**
   * Define the property the options should be sorted by. Works in combination with sortAlphabetically
   */
  @Input() public sortBy: string = 'label';
  /**
   * Height of the viewport in pixels, a scrollbar is defined if height of list exceeds this value
   */
  @Input() public scrollHeight = '200px';
  /**
   * Inline style of the element
   */
  @Input() public style: any;
  /**
   * Style class of the element
   */
  @Input() public styleClass: string = '';
  /**
   * Inline style of the overlay panel
   */
  @Input() public panelStyle: any;
  /**
   * Style class of the overlay panel
   */
  @Input() public panelStyleClass: string = '';
  /**
   * Identifier of the focus input to match a label defined for the component
   */
  @Input() public inputId: string;
  /**
   * When present, it specifies that the element should be disabled
   */
  @Input() public disabled: boolean;
  /**
   * When present, it specifies that the component cannot be edited
   */
  @Input() public readonly: boolean;

  /**
   * When present, it specifies that an input field must be filled out before submitting the form
   */
  @Input() public required: boolean;
  /**
   * When specified, displays an input field to filter the items on keyup
   */
  @Input() public filter = true;
  /**
   * Defines placeholder of the filter input
   */
  @Input() public filterPlaceHolder: string;
  /**
   * Specifies the visibility of the options panel
   */
  @Input() public overlayVisible: boolean;
  /**
   * Index of the element in tabbing order
   */
  @Input() public tabindex: number;
  /**
   * Target element to attach the overlay, valid values are "body" or a local ng-template variable of another element
   */
  @Input() public appendTo: any;
  /**
   * A property to uniquely identify a value in options
   */
  @Input() public dataKey: string;
  /**
   * Name of the input element
   */
  @Input() public name: string;
  /**
   * Whether to show labels of selected item labels or use default label
   */
  @Input() public displaySelectedLabel = true;
  /**
   * Decides how many selected item labels to show at most
   */
  @Input() public maxSelectedLabels = 3;
  /**
   * Number of maximum options that can be selected
   */
  @Input() public selectionLimit: number;
  /**
   * Label to display after exceeding max selected labels
   */
  @Input() public selectedItemsLabel = '{0} items selected';
  /**
   * Whether to show the checkbox at header to toggle all items at once
   */
  @Input() public showToggleAll = true;
  /**
   * Clears the filter value when hiding the dropdown
   */
  @Input() public resetFilterOnHide = false;
  /**
   * Icon class of the dropdown icon
   */
  @Input() public dropdownIcon = 'pi pi-chevron-down';
  /**
   * Name of the label field of an option when an arbitrary objects instead of SelectItems are used as options
   */
  @Input() public optionLabel: string;
  /**
   *  Name of the value field of an option
   */
  @Input() public optionValue: string;
  /**
   * Whether to show the header
   */
  @Input() public showHeader = true;
  /**
   * Whether to automatically manage layering
   */
  @Input() public autoZIndex = true;
  /**
   * Base zIndex value to use in layering
   */
  @Input() public baseZIndex = 0;
  /**
   * When filtering is enabled, filterBy decides which field or fields (comma separated) to search against
   */
  @Input() public filterBy = 'label';
  /**
   Whether the data should be loaded on demand during scroll
   */
  @Input() public virtualScroll = false;
  /**
   * Height of an item in the list for VirtualScrolling
   */
  @Input() public virtualScrollItemSize = 34;
  /**
   * Transition options of the show animation
   */
  @Input() public showTransitionOptions = '225ms ease-out';
  /**
   * Transition options of the hide animation
   */
  @Input() public hideTransitionOptions = '195ms ease-in';
  /**
   * Defines a string that labels the filter input
   */
  @Input() public ariaFilterLabel: string;
  /**
   * Label of the multi-select
   */
  @Input() public label: string;
  /**
   * Label to display when there are no selections
   */
  @Input() public defaultLabel: string;
  /**
   * Icon class
   */
  @Input() public icon: string;
  /**
   * Specifies if the tooltip is present
   */
  @Input() public hasTooltip: boolean;
  /**
   * Set to chip to display the selected items in a chips display
   */
  @Input() public display: string = 'comma';
  /**
   * Text to display when there is no data.
   */
  @Input() public emptyMessage: string;
  /**
   * Text to display when filtering does not return any results.
   */
    @Input() public emptyFilterMessage: string;
  /**
   * Whether to display options as grouped when nested options are provided.
   */
  @Input() public group: boolean;
  /**
   * Name of the label field of an option group.
   */
  @Input() public optionGroupLabel: string = 'label';
  /**
   * Name of the options field of an option group.
   */
  @Input() public optionGroupChildren: string = 'items';

  /**
   * Applies focus to the filter element when the overlay is shown.
   */
  @Input() public autofocusFilter = true;
  /**
   * Float label
   */
  @Input() public floatLabel = false;
  /**
   * Callback to invoke when value changes
   */
  @Output() public change: EventEmitter<any> = new EventEmitter();
  /**
   * Callback to invoke when multiselect receives focus
   */
  @Output() public focus: EventEmitter<any> = new EventEmitter();
  /**
   * Callback to invoke when multiselect loses focus
   */
  @Output() public blur: EventEmitter<any> = new EventEmitter();
  /**
   * Callback to invoke when component is clicked
   */
  @Output() public click: EventEmitter<any> = new EventEmitter();
  /**
   * Callback to invoke when component is filtered
   */
  @Output() public filtered: EventEmitter<any> = new EventEmitter();
  /**
   * Callback to invoke when overlay panel becomes visible
   */
  @Output() public panelShow: EventEmitter<any> = new EventEmitter();
  /**
   * Callback to invoke when overlay panel becomes hidden
   */
  @Output() public panelHide: EventEmitter<any> = new EventEmitter();
  @ContentChildren(CapturumTemplateDirective)
  public templates: QueryList<any>;
  public control: FormControl = new FormControl();
  public itemTemplate: TemplateRef<string>;
  public selectedItemsTemplate: TemplateRef<string>;
  public footerTemplate: TemplateRef<string>;
  public interpolatedSelectedLabel: string;
  private subscription: Subscription = new Subscription();

  constructor(private injector: Injector,
              private validatorService: ValidatorService) {
    super();
  }

  private _options: any = [];

  get options(): any[] {
    return this._options;
  }

  /**
   * An array of objects to display as the available options
   *
   * @param options
   */
  @Input()
  set options(options: any[]) {
    if (Array.isArray(options)) {
      this._options = options;
    } else {
      this._options = [];
    }
  }

  public ngOnChanges(): void {
    if (this.options && this.sortAlphabetically) {
      this.options = this.sortOptionsAlphabetically(this.options);
    }
  }

  public ngOnInit(): void {
    this.subscription = this.control.valueChanges.pipe(
      debounceTime(333),
    ).subscribe(() => {
      if (this.icon) {
        this.setSelectedItems();
      }
    });
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      const ngControl: NgControl = this.injector.get(NgControl, null);
      this.control = this.validatorService.extractFormControl(ngControl);
      if (this.icon) {
        this.setSelectedItems();
      }
    });
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Switch templates based on the content children
   */
  public ngAfterContentInit(): void {
    this.templates.forEach(template => {
      switch (template.getType()) {
        case 'item':
          this.itemTemplate = template.template;
          break;
        case 'selectedItems':
          this.selectedItemsTemplate = template.template;
          break;
        case 'footer':
          this.footerTemplate = template.template;
          break;
      }
    });
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public setSelectedItems(): void {
    /* Example of string: {0} items selected */
    const pattern = /{(.*?)}/;

    if (this.value) {
      if (this.value.length >= this.maxSelectedLabels && pattern.test(this.selectedItemsLabel)) {
        this.interpolatedSelectedLabel = this.selectedItemsLabel.replace(this.selectedItemsLabel.match(pattern)[0], this.value.length + '');
      } else {
        const selectedOptions = this.options.filter(item => this.value.includes(item.value));

        this.interpolatedSelectedLabel = selectedOptions.reduce((acc, item, index) => {
          const prefix = !index ? ' ' : ', ';

          return acc += `${prefix}${item.label}`;
        }, '');
      }
    }
  }

  public onChange($event: any[]): void {
    if (this.icon) {
      this.setSelectedItems();
    }

    this.change.emit($event);
  }

  public hide(): void {
    this.pMultiSelect.hide();
  }

  private sortOptionsAlphabetically(options: any[]): any[] {
    return options.sort((a, b) => {
      if (!a[this.sortBy] || !b[this.sortBy]) {
        return 0;
      }

      return a[this.sortBy].toLowerCase() > b[this.sortBy].toLowerCase() ? 1 : -1;
    });
  }
}
