import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { TwoFactorAuthService, TwoFactorMethod } from '@capturum/auth';
import { take } from 'rxjs/operators';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ToastService } from '@capturum/ui/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'emc-multi-factor-dialog',
  templateUrl: './multi-factor-dialog.component.html',
  styleUrls: ['./multi-factor-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MultiFactorDialogComponent implements OnInit {
  public code: string = '';
  public selectedMethod: TwoFactorMethod;
  public canSelectMethod: boolean;
  public isEnabled: boolean;
  public isValidFormGroup: boolean;
  public submitStyleClass;

  @HostListener('window:keyup', ['$event'])
  public keyEvent(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.submitDialog();
    }
  }

  constructor(
    private readonly twoFactorAuthService: TwoFactorAuthService,
    private readonly dynamicDialogRef: DynamicDialogRef,
    private readonly dynamicDialogConfig: DynamicDialogConfig,
    private readonly translateService: TranslateService,
    private readonly toastService: ToastService,
  ) {
  }

  public ngOnInit(): void {
    this.isEnabled = this.dynamicDialogConfig.data.isEnabled ?? false;
    this.selectedMethod = this.dynamicDialogConfig.data.selectedMethod ?? null;
    this.canSelectMethod = this.dynamicDialogConfig.data.showSelectMethod ?? true;
    this.submitStyleClass = this.dynamicDialogConfig.data.submitStyleClass ?? 'primary w-100';

    if (this.isEnabled) {
      this.initDisableTimeout();
    }
  }

  public updateCode({ code, method, isValid }): void {
    this.code = code;
    this.selectedMethod = method;
    this.isValidFormGroup = isValid;
  }

  public closeDialog(): void {
    this.dynamicDialogRef.close();
  }

  public submitDialog(): void {
    if (!this.isValidFormGroup) {
      return;
    }

    if (this.dynamicDialogConfig.data.isEnabled) {
      this.confirmDisable();
    } else {
      this.confirmEnable();
    }
  }

  public initDisableTimeout(): void {
    this.twoFactorAuthService.requestDisable().pipe(
      take(1),
    ).subscribe();
  }

  private confirmEnable(): void {
    this.twoFactorAuthService.confirm2fa(this.selectedMethod, this.code).pipe(
      take(1),
    ).subscribe(({ success }) => {
      this.showToast(success);

      this.dynamicDialogRef.close({
        currentMethod: this.selectedMethod,
        isEnabled: (success) ? !this.isEnabled : this.isEnabled,
        isSuccess: success,
      });
    });
  }

  private confirmDisable(): void {
    this.twoFactorAuthService.confirmDisable(this.selectedMethod, this.code).pipe(
      take(1),
    ).subscribe(({ success }) => {
      this.showToast(success);

      this.dynamicDialogRef.close({
        currentMethod: null,
        isEnabled: (success) ? !this.isEnabled : this.isEnabled,
        isSuccess: success,
      });
    });
  }

  private showToast(success: boolean): void {
    if (!this.dynamicDialogConfig.data.disableToastNotifications) {
      const responseStatusKey = (success)
        ? 'success'
        : 'error';

      const enableStatusKey = (this.isEnabled)
        ? 'disable'
        : 'enable';

      const defaultTitleKey = `toast.${responseStatusKey}.title`;
      const defaultMessageKey = `auth.2fa.toast.${enableStatusKey}.${responseStatusKey}`;

      const toastTitle = (success)
        ? this.dynamicDialogConfig.data.toastSuccesTitle ?? this.translateService.instant(defaultTitleKey)
        : this.dynamicDialogConfig.data.toastErrorTitle ?? this.translateService.instant(defaultTitleKey);

      const toastMessage = (success)
        ? this.dynamicDialogConfig.data.toastSuccesMessage ?? this.translateService.instant(defaultMessageKey)
        : this.dynamicDialogConfig.data.toastErrorMessage ?? this.translateService.instant(defaultMessageKey);

      this.toastService.success(toastTitle, toastMessage);
    }
  }
}
