import { AfterContentInit, Component, ContentChildren, QueryList } from '@angular/core';
import { CapturumTemplateDirective } from '@capturum/ui/api';

@Component({
  selector: 'cap-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class CapturumHeaderComponent implements AfterContentInit {
  public contentTemplate: any;
  public logoTemplate: any;

  @ContentChildren(CapturumTemplateDirective)
  public templates: QueryList<any>;

  public ngAfterContentInit(): void {
    this.templates.forEach(item => {
      switch (item.getType()) {
        case 'logo':
          this.contentTemplate = item.template;
          break;

        case 'content':
          this.logoTemplate = item.template;
          break;
      }
    });
  }
}
