<div class="dialog-container">
  <div class="dialog-container__header">
    <span>
      {{ 'builders.entity.info.header' | translate }}
    </span>

    <i class="fas fa-times close" (click)="closeDialog()"></i>
  </div>

  <div class="dialog-container__content">
    <p-autoComplete #autoComplete
                    styleClass="autocomplete"
                    field="value"
                    placeholder="entity"
                    [(ngModel)]="selectedEntities"
                    [suggestions]="suggestions"
                    [multiple]="true"
                    [group]="true"
                    (onFocus)="onFocus($event)"
                    (onSelect)="selectItem($event)"
                    (onUnselect)="unselectItem($event)">
    </p-autoComplete>
  </div>

  <div class="dialog-container__footer">
    <cap-button styleClass="primary"
                [label]="'button.submit' | translate"
                [disabled]="!selectedEntities?.length"
                (onClick)="submit()">
    </cap-button>
  </div>
</div>
