import { Component, Input } from '@angular/core';
import { ListRendererWidgetBase } from '../../base/list-renderer-widget-base';

@Component({
  selector: 'cpb-list-renderer-widget-image',
  template: `
    <div *ngIf="item" class="list-renderer-widget-image list-renderer-widget-image--{{shape}}">
      <img [src]="item[field]" alt="widget-image" />
    </div>
  `,
  styleUrls: ['./list-renderer-widget-image.component.scss']
})
export class ListRendererWidgetImageComponent extends ListRendererWidgetBase {
  @Input() public shape: 'square' | 'circle' = 'square';
}
