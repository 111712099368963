import { LayoutTemplateConfig } from '../interfaces/template-config.interface';

export const defaultLayoutTemplates: LayoutTemplateConfig = {
  default: {
    amountOfContentGroups: 1,
    locations: {
      content: {
        containerClass: 'cpb-grid',
        groupClass: 'col',
      },
      header: {
        containerClass: 'cpb-grid',
        groupClass: 'cpb-col-12',
      },
      footer: {
        containerClass: 'cpb-grid',
        groupClass: 'cpb-col-12',
      },
    },
  },
  one_column: {
    amountOfContentGroups: 1,
    locations: {
      content: {
        containerClass: 'cpb-grid',
        groupClass: 'cpb-col-12',
      },
    }
  },
  two_column: {
    amountOfContentGroups: 2,
    locations: {
      content: {
        containerClass: 'cpb-grid',
        groupClass: 'cpb-col-12 lg:cpb-col-6',
      },
    },
  },
  three_column: {
    amountOfContentGroups: 3,
    locations: {
      content: {
        containerClass: 'cpb-grid',
        groupClass: 'cpb-col-4',
      },
    },
  },
  two_columns_with_header_and_footer: {
    amountOfContentGroups: 2,
    locations: {
      header: {
        containerClass: 'cpb-grid',
        groupClass: 'cpb-col-12',
      },
      content: {
        containerClass: 'cpb-grid',
        groupClass: 'cpb-col-12 md:cpb-col-6',
      },
      footer: {
        containerClass: 'cpb-grid',
        groupClass: 'cpb-col-12',
      },
    },
  },
  three_columns_with_header_and_footer: {
    amountOfContentGroups: 3,
    locations: {
      header: {
        containerClass: 'cpb-grid',
        groupClass: 'cpb-col-12',
      },
      content: {
        containerClass: 'cpb-grid',
        groupClass: 'cpb-col-12 md:cpb-col-4',
      },
      footer: {
        containerClass: 'cpb-grid',
        groupClass: 'cpb-col-12',
      },
    },
  },
};
