import { Positions } from '../enums/positions.enum';

export interface NotificationBadgeOptions {
  badgeSize?: string;
  width?: string;
  height?: string;
  borderRadius?: string;
  position?: Positions | 'none';
  label?: string;
  icon?: string;
  fontColor?: string;
  fontSize?: string;
  fontWeight?: string;
  backgroundColor?: string;
  showBorder?: boolean;
  showBadge?: boolean;
  borderType?: string;
  borderSize?: string;
  borderColor?: string;
}
