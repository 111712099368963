import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'capIsTableRowSelected',
})
export class IsTableRowSelectedPipe implements PipeTransform {
  public transform(value: any, selectedValues: any[], dataKey?: string): boolean {
    if (typeof value === "object" && dataKey) {
      return !!selectedValues?.find((item) => item[dataKey] === value[dataKey]);
    }
    return selectedValues && selectedValues.includes(value);
  }
}
