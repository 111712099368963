import { Inject, Injectable, InjectionToken, Injector, Optional, Type } from '@angular/core';
import { defaultCapturumBuilderActions } from '../configs/default-actions.config';
import { ActionProvider } from '../interfaces/action-provider.interface';
import { CoreModuleConfig } from '../interfaces/core-module-config.interface';
import { CAPTURUM_BUILDERS_CONFIG_STORAGE_PROVIDERS } from '../providers/config-storage-types.provider';
import { LocalStorageService, SessionStorageService, StorageProvider } from '@capturum/ui/api';
export const BUILDERS_CORE_CONFIG = new InjectionToken<CoreModuleConfig[]>('BUILDERS_CORE_CONFIG');
@Injectable({
  providedIn: 'root',
})
export class BuilderCoreConfigService {
  constructor(
    @Optional()
    @Inject(CAPTURUM_BUILDERS_CONFIG_STORAGE_PROVIDERS)
    private readonly storageProviders: Record<string, string>,
    private injector: Injector
  ) {}

  private _actions: Record<string, Type<ActionProvider>> = {
    ...defaultCapturumBuilderActions,
  };

  public get actions(): Record<string, Type<ActionProvider>> {
    return this._actions;
  }

  private _components: Record<string, Type<any>> = {};

  public get components(): Record<string, Type<any>> {
    return this._components;
  }

  public getStorageClassByKey(key: string): StorageProvider {
    switch (key) {
      case 'session':
        return this.injector.get(SessionStorageService);
      case 'local':
        return this.injector.get(LocalStorageService);
      default:
        return this.injector.get(this.storageProviders?.[key] || SessionStorageService);
    }
  }

  public addConfig(config: CoreModuleConfig): void {
    if (config.components) {
      Object.keys(config.components).forEach((componentKey) => {
        if (!this._components[componentKey]) {
          this._components[componentKey] = config.components[componentKey];
        }
      });
    }

    if (config.actions) {
      this._actions = {
        ...this.actions,
        ...config.actions,
      };
    }
  }
}
