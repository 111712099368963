import { Injectable } from '@angular/core';
import { ListRendererColumnConfig } from '../models/list-renderer-config.model';
import { InfoTableColumn, InfoTableColumnType } from '@capturum/ui/info-table';

@Injectable()
export class ListRendererColumnsService {
  public actionColumn: InfoTableColumn = {
    field: 'actions',
    type: InfoTableColumnType.Actions,
    titleClass: 'cpb-action-header',
    disableSort: true,
    disableRowClick: true,
  };

  public prepareColumns<Model>(configFields: ListRendererColumnConfig[]): InfoTableColumn[] {
    return configFields.map(configField => {
      let type: string = configField.type;

      if (type === 'template') {
        type = configField?.widget?.name;
      }

      const column: InfoTableColumn = {
        field: configField.column,
        disableSort: !(!!configField?.sortable),
        type: (configField.type === InfoTableColumnType.Text || !configField.type) ? InfoTableColumnType.Text : InfoTableColumnType.Template,
        columnType: type,
        titleClass: configField?.options?.css_class ?? null,
        cellClass: configField?.options?.css_class ?? null,
        hidden: configField?.options?.hidden ?? null,
        options: configField?.options,
        frozen: configField?.options?.frozen ?? null,
      };

      if (!!configField?.field) {
        column.sortable = {
          enabled: !!configField?.sortable,
          direction: null,
          field: configField?.field
        };
      }

      if (configField.title) {
        column.title = configField.title;
      }

      if (configField.widget) {
        column.widget = configField.widget;
      }


      if (configField.disableRowClick) {
        column.disableRowClick = configField.disableRowClick;
      }

      return column;
    });
  }
}
