<cap-file-upload styleClass="g-uploader"
                 [id]="field.key"
                 label=""
                 [multiple]="to.multiple"
                 [attr.data-test]="'builder-' + field.key"
                 [accept]="to.availableExtensions"
                 [uploaderContent]="uploadContent"
                 (onFileChange)="handleFileChange($event)">
</cap-file-upload>

<!-- PREVIEW DOWNLOADED FILES SECTION -->
<div class="preview--section" [attr.data-test]="'builder-readonly-' + field.key">
  <cap-file-preview-list [files]="previewFiles" [showDelete]="true" (onDeleteClick)="removeFile($event)"></cap-file-preview-list>
</div>

<!-- DRAG FILE INTO SECTION-->
<ng-template #uploadContent>
  <div class="upload">
    <i class="fas fa-cloud-upload-alt"></i>
    <p [innerHTML]="field?.name"></p>
    <span class="placeholder" *ngIf="field?.props?.label as placeholder">{{ 'file.upload.description' | translate }}</span>
  </div>
</ng-template>
