import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiHttpService } from '@capturum/api';
import { v4 as uuidv4 } from 'uuid';
import { IndexedDbService } from '../indexedDb/indexedDb.service';

const uuid = uuidv4;

/**
 * QueryOptions for sorting and filtering indexedDB based query's
 */
export class QueryOptions {
  public usePaging = true;
  public pageSize = 10;
  public pageIndex = 0;

  // Sorting variables
  public sortColumn = null;
  public sortOrder = 'desc';

  // Filtering variables
  // columns array contains array of object { name: , filter: , filterMode: }
  public columns: any[] = [];
  public globalFilter: any;

  public constructor(options: any = {}) {
    Object.assign(this, options);
  }
}

@Injectable()
export class DataService {
  private indexedDB = this.indexedDbService.store;
  private allowBackend = false;
  private record;
  private totalRecords;
  private rec: Observable<any>;
  private result;
  private urlQuery;

  constructor(
    private api: ApiHttpService,
    private indexedDbService: IndexedDbService
  ) {
  }

  /**
   * Get data from API or indexedDB with additional filters and sorting.
   *
   * @param tableName indexedDB table name
   * @param options QueryOptions for indexedDB usage
   * @param url URl of API call in case of backend connection
   */
  public async get(tableName: string, options: QueryOptions, url: string): Promise<any> {
    this.indexedDB = this.indexedDbService.store;
    if (!this.allowBackend) {
      this.result = this.indexedDB.table(tableName);

      const items = await this.result.toArray();

      // Init each model for custom processing
      for (const model of items) {
        await model.init();
      }

      this.result = items;

      this.processSearching(options);
      this.processSorting(options);
      this.paginate(options);

      return this.result;
    } else {
      // Use API service
    }
  }

  /**
   * Get data indexedDB by id
   *
   * @param tableName indexedDB table name
   * @param id ID number
   */
  public async getById(tableName: string, id: any): Promise<any> {
    this.indexedDB = this.indexedDbService.store;
    const item = await this.indexedDB.table(tableName).get(id);

    if (!item) {
      return undefined;
    }

    item.init();

    return item;
  }

  /**
   * Get data indexedDB by id
   *
   * @param tableName indexedDB table name
   * @param column indexedDB columnName
   * @param value indexedDB columnValue
   */
  public async getBy(tableName: string, column: string, value: any): Promise<any> {
    this.indexedDB = this.indexedDbService.store;
    const filter = {};
    filter[column] = value;
    const items = await this.indexedDB.table(tableName).where(filter).toArray();

    // Init each model for custom processing
    for (const model of items) {
      await model.init();
    }

    return items;
  }

  /**
   * Perform a lookup in de database by given criteria and return first match
   * http://dexie.org/docs/Table/Table.get()
   *
   * @param tableName indexedDB table name
   * @param criteria indexedDB search criteria
   * @param url Url
   */
  public async getWhere(tableName: string, criteria: object, url: string): Promise<any> {
    this.indexedDB = this.indexedDbService.store;
    if (!this.allowBackend) {
      let item = await this.indexedDB.table(tableName)
        .get(criteria)
        .then(data => item = data);

      return item;
    } else {
      // Use API service
    }
  }

  /**
   * Count the number of total records with the applied filters
   *
   * @param tableName indexedDB table name
   * @param options QueryOptions for indexedDB usage
   * @param url URl of API call in case of backend connection
   * @return Promise<number>
   */
  public async count(tableName: string, options: QueryOptions, url: string): Promise<number> {
    this.indexedDB = this.indexedDbService.store;

    if (this.allowBackend) {
      return 0;
    }
    this.result = this.indexedDB.table(tableName);

    const items = await this.result.toArray();

    // Init each model for custom processing
    for (const model of items) {
      await model.init();
    }

    this.result = items;

    this.processSearching(options);
    this.processSorting(options);

    return this.result.length;
  }

  /**
   * Create or update object
   *
   * @param tableName indexedDB table name
   * @param object the object to be written to the indexedDB table
   * @return Promise<any>
   */
  public async createOrUpdate(tableName: string, object: any): Promise<any> {
    this.indexedDB = this.indexedDbService.store;
    if (!object) {
      return;
    }

    if (!this.allowBackend) {
      if (!object.id) {
        object.id = uuid();
      }

      return await this.indexedDB.table(tableName).put(object);
    } else {
      // Use API service
    }
  }

  /**
   * Add object
   *
   * @param tableName indexedDB table name
   * @param object the object to be written to the indexedDB table
   * @return Promise<any>
   */
  public async add(tableName: string, object: any): Promise<any> {
    this.indexedDB = this.indexedDbService.store;
    if (!object) {
      return;
    }

    if (!this.allowBackend) {
      return await this.indexedDB.table(tableName).add(object);
    } else {
      // Use API service
    }
  }

  /**
   * Update object
   *
   * @param tableName indexedDB table name
   * @param id primary key id
   * @param attributes object with attributes which need to be updated
   * @return Promise<any>
   */
  public async update(tableName: string, id: number, attributes: any): Promise<any> {
    this.indexedDB = this.indexedDbService.store;
    if (!this.allowBackend) {
      return await this.indexedDB.table(tableName).update(id, attributes);
    } else {
      // Use API service
    }
  }

  /**
   * Delete object
   *
   * @param tableName indexedDB table name
   * @param id primary key id
   * @return Promise<void>
   */
  public async delete(tableName: string, id: any): Promise<void> {
    this.indexedDB = this.indexedDbService.store;
    if (!this.allowBackend) {
      return await this.indexedDB.table(tableName).delete(id);
    } else {
      // Use API service
    }
  }

  /**
   * Process available sorting
   *
   * @param options QueryOptions
   */
  public processSorting(options: QueryOptions): void {
    this.indexedDB = this.indexedDbService.store;
    if (options.sortColumn) {
      this.result.sort((a, b) => {
        return a[options.sortColumn] > b[options.sortColumn];
      });

      if (options.sortOrder !== 'asc') {
        this.result = this.result.reverse();
      }
    }
  }

  /**
   * Paginate result
   *
   * @param options QueryOptions
   */
  public paginate(options: QueryOptions): void {
    if (options.usePaging) {
      const start = options.pageIndex * options.pageSize;
      const end = start + options.pageSize;
      this.result = this.result.slice(start, end);
    }
  }

  public processSearching(options: QueryOptions): void {
    if (!options || options.columns.length === 0) {
      return;
    }

    // Do not process if no filters are given
    if (!options.globalFilter && options.columns.find(column => column.filter !== undefined) === undefined) {
      return;
    }

    this.result = this.result.filter(record => {
      let include = false;

      options.columns.forEach(column => {

        // Skip if no filters are set
        if (!options.globalFilter && !column.filter) {
          return;
        }

        if (!record[column.name]) {
          return;
        }

        if (options.globalFilter && (
          '' + record[column.name]
        ).includes(options.globalFilter)) {
          include = true;
        }

        if (column.filter && (
          '' + record[column.name]
        ).includes(column.filter)) {
          include = true;
        }
      });

      return include;
    });
  }
}
