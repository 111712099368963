import { IndexedDbModel } from '../../../shared/indexedDb/indexedDb.model';

export class SettingIndexedDbModel extends IndexedDbModel {
  public static settings: SettingIndexedDbModel[] = [];

  /**
   * Load current setting in static cache
   */
  public static async loadSettings(): Promise<void> {
    SettingIndexedDbModel.settings = await SettingIndexedDbModel.query().toArray();
  }

  /**
   * Get the setting by name
   *
   * @param name
   */
  public static getValue(name: string): string | boolean | null | undefined {
    if (!SettingIndexedDbModel.settings) {
      return undefined;
    }

    const currentSetting: SettingIndexedDbModel = SettingIndexedDbModel.settings.find(setting => setting.id === name);

    return currentSetting ? currentSetting.value : null;
  }

  /**
   * Get the boolean value of a setting
   *
   * @param name
   */
  public static getBooleanValueByName(name: string): boolean {
    if (!SettingIndexedDbModel.settings) {
      return false;
    }

    return SettingIndexedDbModel.getValue(name) === 'true'
      || SettingIndexedDbModel.getValue(name) === true;
  }

  // Configuration
  public entity = 'setting';
  public table = 'settings';
  public schema = '++id, tenant_id, name';
  public sync = false;

  // Fields
  public tenant_id: number;
  public name: string;
  public type: string;
  public description: string;
  public value: string | boolean;

  // Constructor
  constructor(attributes: Partial<SettingIndexedDbModel>) {
    super(attributes);
  }
}
