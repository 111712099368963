import { QuillModules } from 'ngx-quill';

export const defaultModulesConfig: QuillModules = {
  toolbar: {
    container: [
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ size: ['small', false, 'large', 'huge'] }],
      [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['link', 'image'],
    ],
    handlers: {
      image: imageUrlHandler,
    },
  },
};

// Handler for image, enter image url instead of upload.
function imageUrlHandler() {
  const tooltip = this.quill.theme.tooltip;
  const originalSave = tooltip.save;
  const originalHide = tooltip.hide;

  tooltip.save = function () {
    const range = this.quill.getSelection(true);
    const value = this.textbox.value;

    if (value) {
      this.quill.insertEmbed(range.index, 'image', value, 'user');
    }
  };

  // Called on hide and save.
  tooltip.hide = function () {
    tooltip.save = originalSave;
    tooltip.hide = originalHide;
    tooltip.hide();
  };

  tooltip.edit('image');
  tooltip.textbox.placeholder = 'Image URL';
}
