<div class="cap-loader" [ngClass]="styleClass">
    <p-progressSpinner *ngIf="!image" styleClass="cap-progress-spinner"></p-progressSpinner>

    <ng-container *ngIf="image">
        <img class="cap-loader-image" [src]="image" alt="loading spinner" />
    </ng-container>

    <span class="cap-loader-text" *ngIf="text">
        {{ text }}
    </span>
</div>
