import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ConfigOverviewComponent } from './domain/setting/pages/config-overview/config-overview.component';

/**
 * All default complete routes with lazy loaded modules
 */
const routes = [
  {
    path: 'config',
    component: ConfigOverviewComponent
  }
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: []
})
export class CompleteRoutingModule {}
