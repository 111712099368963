<div class="preview row" *ngIf="items">
  <div [classList]="styleClass"
       *ngFor="let uploaderItem of items">
    <div class="file" *ngIf="(uploaderItem?.file || uploaderItem) as item" (click)="cardClick.emit(item[nameProperty])">
      <div class="file__preview" [ngClass]="{'file__preview--centered': !showThumbnail}">
        <ng-container *ngIf="showThumbnail">
          <img *ngIf="showThumbnail"
               [src]="imagePathProperty ? item[imagePathProperty] : (item | createObjectUrl)"
               [alt]="item[nameProperty]"/>
        </ng-container>

        <ng-container *ngIf="!showThumbnail">
          <i class="far fa-file"></i>
        </ng-container>
      </div>

      <p class="file__name">{{ item[nameProperty] }}</p>

      <i *ngIf="deletable" (click)="handleRemoveIconClick($event, item[nameProperty])"
         class="fas fa-trash-alt file--remove"></i>
    </div>
  </div>
</div>
