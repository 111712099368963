import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CapturumInfoTableModule } from '@capturum/ui/info-table';
import { CapturumLayoutDetailsModule } from '@capturum/ui/layout';
import { NgxPermissionsModule } from 'ngx-permissions';

import { SharedModule } from '../../shared/shared.module';
import { JobsRoutingModule } from './jobs-routing.module';
import { JobsListComponent } from './pages/jobs-list/jobs-list.component';

@NgModule({
  declarations: [
    JobsListComponent,
  ],
  imports: [
    CommonModule,
    JobsRoutingModule,
    NgxPermissionsModule,
    RouterModule,
    CapturumLayoutDetailsModule,
    CapturumInfoTableModule,
    SharedModule,
  ],
})
export class JobsModule {
}
