import { TemplateRef } from '@angular/core';
import { SprintStates } from '../enums/sprint-states.enum';

export interface AccordionTab {
  header: string;
  headerStatus: SprintStates;
  headerIcon: string;
  contentTemplate?: TemplateRef<any>;
  id: string;
}
