import { Component, Input } from '@angular/core';

@Component({
  selector: 'cap-loader',
  templateUrl: './loader.component.html'
})
export class LoaderComponent {
  @Input()
  public text: string;
  @Input()
  public image: string;
  @Input()
  public styleClass: string;
}
