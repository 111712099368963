<cap-chips [formlyAttributes]="field"
          [field]="to.field || null"
          [max]="to.max || null"
          [disabled]="to.disabled || false"
          [style]="to.style || null"
          [styleClass]="to.styleClass || null"
          [inputStyle]="to.inputStyle || null"
          [placeholder]="(to.translatePlaceholder || to.placeholder) | observablePipe | async"
          [tabindex]="to.tabindex || null"
          [inputId]="key"
          [allowDuplicate]="to.allowDuplicate || true"
          [addOnTab]="to.addOnTab || true"
          [addOnBlur]="to.addOnBlur || false"
          [inputStyleClass]="to.inputStyleClass || null"
          (onAdd)="to.onAdd ? to.onAdd($event) : null"
          (onRemove)="to.onRemove ? to.onRemove($event) : null"
          (onChipClick)="to.onChipClick ? to.onChipClick($event) : null"
          (onBlur)="to.onBlur ? to.onBlur($event) : null"
          [formControl]="formControl">
</cap-chips>
