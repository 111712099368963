import { ActionOptions, ActionProvider } from '../interfaces/action-provider.interface';
import { Injectable } from '@angular/core';
import { ApiHttpService } from '@capturum/api';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BuilderApiService } from '../services/builder-api.service';

@Injectable({ providedIn: 'root' })
export class BackendAction implements ActionProvider {
  constructor(
    protected readonly apiHttp: ApiHttpService,
    protected readonly builderApiHttpService: BuilderApiService,
  ) {
  }

  public execute(options: ActionOptions, item: any): Observable<any> {
    if (options.endpoint) {
      return this.builderApiHttpService.post(
        options.contextKey || options.configurationKey,
        options.endpoint,
        Array.isArray(item) ? item : [item]
      ).pipe(take(1));
    } else {
      throw new Error(`Endpoint not provided for action "${options.action.key}". Please provide an endpoint in the configuration of the action`);
    }
  }
}
