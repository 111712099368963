import { Injectable } from '@angular/core';
import { TextInputTypeBuilder } from '../text/text-input-type.builder';
import { InputConfiguration, InputType } from '@capturum/builders/core';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Injectable({ providedIn: 'root' })
export class HiddenInputTypeBuilder extends TextInputTypeBuilder {
  protected inputType: string = InputType.hidden;

  protected appendOptions(input: FormlyFieldConfig, configuration: InputConfiguration): void {
    super.appendOptions(input, configuration);

    input.wrappers = ['hidden'];
  }
}
