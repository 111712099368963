export function getJwtTokenExpirationDate(token: Record<string, any>): Date | null {
  if (!token || !token.hasOwnProperty('exp')) {
    return null;
  }

  const date = new Date(0);
  date.setUTCSeconds(token['exp']);

  return date;
}

export function isJwtTokenExpired(token: Record<string, any>, offsetSeconds?: number): boolean {
  if (!token) {
    return true;
  }

  const date = getJwtTokenExpirationDate(token);
  offsetSeconds = offsetSeconds || 0;

  if (date === null) {
    return false;
  }

  return !(date.valueOf() > new Date().valueOf() + offsetSeconds * 1000);
}
