import { Pipe, PipeTransform } from '@angular/core';
import { ActiveFilters } from '../interfaces/dynamic-filter.interface';

@Pipe({
  name: 'filterField'
})
export class FilterFieldPipe implements PipeTransform {
  public transform(value: ActiveFilters[], field: string): ActiveFilters {
    return value?.find((filter) => filter.field === field);
  }
}
