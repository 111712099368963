import { Injectable, Injector } from '@angular/core';
import { ApiHttpService, ApiIndexResult, ApiService, ApiSingleResult, ListOptions } from '@capturum/api';
import { ToastService } from '@capturum/ui/api';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, interval, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { BatchStatus, BatchStatusAction } from '../models/batch-status.model';
import { FailedBatchJob, FinishedBatchStatus } from './../models/batch-status.model';
import { BatchStatusActionsService } from './batch-status-actions.service';

@Injectable({
  providedIn: 'root',
})
export class BatchStatusService extends ApiService<BatchStatus> {
  protected _endpoint = 'job-batch';
  protected defaultInterval = 3000;
  private activeBatches = new Map<string, string>();

  constructor(
    public apiHttp: ApiHttpService,
    private notificationService: ToastService,
    private translateService: TranslateService,
    private batchStatusActions: BatchStatusActionsService,
    private injector: Injector,
  ) {
    super(apiHttp);
  }

  public getBatchIdByIdentifier(identifier: string): string {
    return this.activeBatches.get(identifier);
  }

  public setActiveBatch(batchId: string, identifier: string): void {
    this.activeBatches.set(identifier, batchId);
  }

  public removeBatch(batchId: string): void {
    let id = null;

    this.activeBatches.forEach((value, key) => {
      if (value === batchId) {
        id = key;
      }
    });

    this.activeBatches.delete(id);
  }

  public getBatchStatus(id: string, options?: ListOptions): Observable<BatchStatus> {
    this.endpoint = `${this._endpoint}/status`;

    return super.get(id, options).pipe(
      tap(response => {
        if (response.finished_at) {
          this.removeBatch(id);
        }
      }),
    );
  }

  public getBatchSize(options?: ListOptions): Observable<BatchStatus> {
    this.endpoint = `${this._endpoint}/size`;

    return this.apiHttp
      .get<ApiSingleResult<BatchStatus>>(
        `/${this.endpoint}` + this.getOptionsQuery(options)
      )
      .pipe(map((result: ApiSingleResult<BatchStatus>) => result.hasOwnProperty('data') ? result.data : result as BatchStatus));
  }

  public getFailedJobs(options?: ListOptions): Observable<ApiIndexResult<FailedBatchJob>> {
    this.endpoint = `${this._endpoint}/failed`;

    return super.index(options);
  }

  public getFailedJob(id: string, options?: ListOptions): Observable<FailedBatchJob> {
    this.endpoint = `${this._endpoint}/failed`;

    return super.get(id, options);
  }

  public getIsUpdatedBatch(batchId: string,
                           showSuccessfulMessage: boolean = true,
                           successMessageKey: string = 'toast.success.title',
                           executeAction: boolean = true,
                           options?: { interval: number },
  ): Observable<FinishedBatchStatus> {
    const subject = new BehaviorSubject<FinishedBatchStatus>({
      finished: false,
      progress: 0,
    });

    this.getBatchStatus(batchId)
      .pipe(
        switchMap((response: BatchStatus) => {
          if (response.finished_at || response.failed_jobs > 0) {
            return of({ finished: true, withFailures: response.failed_jobs > 0, action: response.action, message: null, progress: 100 });
          } else {
            return this.startInterval(batchId, options?.interval).pipe(
              map<BatchStatus, { finished: boolean; withFailures: boolean; action: { [key: string]: string }, message?: any, progress: number }>(batchStatus => {
                return {
                  finished: !!batchStatus.finished_at || batchStatus.failed_jobs > 0,
                  withFailures: batchStatus.failed_jobs > 0,
                  action: batchStatus.action,
                  message: null,
                  progress: batchStatus.progress,
                };
              }),
            );
          }
        }),
        catchError((error) => {
          return of({ finished: true, withFailures: true, action: null, message: error.error || error.message, progress: 0 });
        }),
        takeUntil(subject.asObservable().pipe(filter(status => status.finished === true))),
      )
      .subscribe(response => {
        if (response.withFailures) {
          this.notificationService.error(
            this.translateService.instant('toast.error.title'),
            this.translateService.instant('toast.error.message'),
          );
        } else if (response.finished && showSuccessfulMessage) {
          this.notificationService.success(
            this.translateService.instant('toast.success.title'),
            this.translateService.instant(successMessageKey),
          );
        }

        if (response.finished && executeAction && response.action?.type) {
          this.executeAction(response.action);
        }

        subject.next({
          finished: response.finished,
          action: response.action,
          message: response.message,
          progress: response.progress,
        });
      });

    return subject.asObservable();
  }

  private startInterval(batchId: string, intervalAmount = this.defaultInterval): Observable<BatchStatus> {
    return interval(intervalAmount ?? this.defaultInterval).pipe(switchMap(() => this.getBatchStatus(batchId)));
  }

  private executeAction(action: BatchStatusAction): void {
    if (action) {
      if (this.batchStatusActions.actions[action.type]) {
        const instance = this.injector.get(this.batchStatusActions.actions[action.type]);

        if (instance) {
          instance.execute(action);
        }
      } else {
        throw Error(`Action "${action.type}" not found. Make sure to provide the action in the BatchStatusActionsService`);
      }
    }
  }
}
