import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { CapturumBuilderActionService, CapturumBuildersContextService } from '@capturum/builders/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FlowListContextkey, FlowListKey } from '../../enums/flow-list-key.enum';
import { Observable, shareReplay } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ActionStatictic, ColumnClickEvent, FlowStatistic, FlowTable } from '../../interfaces/flow.interface';
import { FlowApiService } from '../../services/flow-api.service';
import { CapturumInfoTableModule } from '@capturum/ui/info-table';
import { CapturumDialogService } from '@capturum/ui/api';

@Component({
  selector: 'cap-flow-error-details',
  templateUrl: './flow-error-details.component.html',
  styleUrls: ['./flow-error-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TranslateModule, CapturumInfoTableModule],
})
export class CapturumFlowErrorDetailsComponent implements OnInit {
  public contextHtml: string;
  public configData: any;
  public context: Record<string, string>;
  public contextKey: string;
  public flow$: Observable<ActionStatictic | FlowStatistic>;
  public flowTable$: Observable<FlowTable>;
  public translationKeyPrefix = '';

  protected contextService = inject(CapturumBuildersContextService);
  protected translateService = inject(TranslateService);
  protected dialogService = inject(CapturumDialogService);
  protected dialogRef = inject(DynamicDialogRef);

  private config = inject(DynamicDialogConfig);
  private flowApiService = inject(FlowApiService);
  private actionService = inject(CapturumBuilderActionService);

  constructor() {
    this.configData = this.config?.data;
  }

  public ngOnInit(): void {
    this.setContext();

    this.translationKeyPrefix = this.configData.configurationKey === FlowListKey.actions ? 'action' : 'flow';

    this.flow$ = this.flowApiService.get(this.contextKey).pipe(
      map((flow) => {
        // return first item from array. response only has 1 item in array
        return flow?.shift();
      }),
      shareReplay(1)
    );

    this.flowTable$ = this.flow$.pipe(
      filter((flow) => {
        return flow?.table?.length > 0;
      }),
      map((flow) => {
        return {
          columns: Object.keys(flow.table[0]).map((key) => {
            return {
              field: `${key}.value`,
              title: this.translateService.instant(`connector.action-statistics.${this.translationKeyPrefix}.${key}.label`),
              disableRowClick: Boolean(flow.table[0][key].action),
              sortable: {
                enabled: !Boolean(flow.table[0][key].action),
                direction: 'asc',
              },
            };
          }),
          tableData: flow.table,
        };
      })
    );
  }

  public columnClick(data: ColumnClickEvent): void {
    const column = data.row[data.column.split('.')[0]];

    if (column?.action) {
      this.dialogRef.close();

      switch (column.type) {
        case 'open_popup':
          this.dialogService.open(CapturumFlowErrorDetailsComponent, {
            data: {
              configurationKey: FlowListContextkey.actions,
              item: column,
            },
            header: this.translateService.instant(`connector.action-statistics.context.label`),
            width: '70%',
            styleClass: FlowListContextkey.actions,
          });
          break;
        case 'navigate':
          this.actionService.broadcastActionExecuted(
            { key: 'log_context', type: 'flow_ref', options: null },
            {
              field: 'action',
              value: column.action,
            },
            null
          );

          break;
        default:
      }
    }
  }

  public openFlowRef(flow: FlowStatistic): void {
    this.dialogRef.close();

    this.dialogService.open(CapturumFlowErrorDetailsComponent, {
      data: {
        configurationKey: FlowListContextkey.flows,
        item: {
          flow_ref: flow.flow_ref,
          id: flow.id,
        },
      },
      header: this.translateService.instant(`connector.action-statistics.context.label`),
      width: '70%',
      styleClass: FlowListContextkey.actions,
    });
  }

  public openHistory(flow: ActionStatictic): void {
    this.dialogRef.close();

    this.actionService.broadcastActionExecuted(
      { key: 'log_context', type: 'flow_ref', options: null },
      {
        field: 'action',
        value: flow.action,
      },
      null
    );
  }

  protected setContext(): void {
    switch (this.configData?.configurationKey) {
      case FlowListKey.actions:
      case FlowListContextkey.actions:
        this.contextKey = FlowListContextkey.actions;
        this.context = {
          action: this.configData?.item?.action,
          id: this.configData?.item?.id,
        };

        break;
      case FlowListKey.flows:
      case FlowListContextkey.flows:
        this.contextKey = FlowListContextkey.flows;
        this.context = {
          flow_ref: this.configData?.item?.flow_ref,
          id: this.configData?.item?.id,
        };

        break;
      default:
    }

    this.contextService.setContext(this.contextKey, this.context);
  }
}
