<div class="form-element">
    <p-checkbox (onChange)="change.emit($event)"
                [(ngModel)]="value"
                [binary]="binary"
                [checkboxIcon]="checkboxIcon"
                [label]="label"
                [disabled]="disabled"
                [value]="checkboxValue"
                [styleClass]="styleClass "
                [style]="style"
                [tabIndex]="tabindex"
                [trueValue]="trueValue"
                [falseValue]="falseValue"
                [labelStyleClass]="labelStyleClass">
    </p-checkbox>

    <cap-validator *ngIf="control" [control]="control" [customName]="label"></cap-validator>
</div>
