import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { LabelPosition } from '@capturum/builders/core';

@Component({
  selector: 'cpb-textarea-input',
  templateUrl: './textarea-input.component.html',
})
export class CapturumBuilderTextareaInputComponent extends FieldType {
  public labelPosition: typeof LabelPosition = LabelPosition;
}
