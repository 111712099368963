import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CapturumFlowOverviewComponent } from './pages/flow-overview/flow-overview.component';

export const flowRoutes: Routes = [
  {
    path: 'actions',
    component: CapturumFlowOverviewComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(flowRoutes)],
  exports: [RouterModule],
})
export class CapturumFlowRoutingModule {}
