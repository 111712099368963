import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SettingService } from '../services/setting.service';

@Injectable()
export class SettingResolver  {
  constructor(private settingService: SettingService) {}

  /**
   * Make sure the settings are loaded before accessing the route
   */
  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    return this.settingService.loadAll();
  }
}
