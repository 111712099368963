import { Injectable } from '@angular/core';
import { InputTypeBuilder } from '../input/input-type.builder';
import { TextInputConfiguration } from '../../models/text-input-configuration.model';
import { FormInputConfig } from '../../interfaces/form-input-config';
import { InputType } from '@capturum/builders/core';

@Injectable({ providedIn: 'root' })
export class EntityTextInputTypeBuilder extends InputTypeBuilder implements FormInputConfig<TextInputConfiguration> {
  protected inputType: string = InputType.entityText;
}
