import { DatePipe } from '@angular/common';

// @dynamic
export class DateHelper {
  public static toGeneralFormat(date: Date): string {
    return new DatePipe('en').transform(date, 'yyyy-MM-ddTHH:mm:ssZZZ');
  }

  public static getRange([start, end]: string[]): string[] {
    return [this.getStartOfDay(start), this.getEndOfDay(end ?? start)];
  }

  public static getStartOfDay(startDate: string): string {
    const start = new Date(startDate);

    start.setHours(0, 0, 0, 0);

    return this.toGeneralFormat(start);
  }

  public static getEndOfDay(endDate: string): string {
    const end = new Date(endDate);

    end.setHours(23, 59, 59, 999);

    return this.toGeneralFormat(end);
  }

  public static timezoneManipulation(date: Date): Date {
    const timeOffsetInMS: number = date.getTimezoneOffset() * 60000;

    date.setTime(date.getTime() - timeOffsetInMS);

    return date;
  }

  public static fromStringToDate(date: string | string[]): Date | Date[] {
    if (date && Array.isArray(date)) {
      return date.map(item => {
        if (item) {
          return new Date(item);
        }

        return item as any;
      });

    } else if (typeof date === 'string') {
      return new Date(date as string);
    }

    return null;
  }
}
