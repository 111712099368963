<div class="cap-info-table__skeleton">
    <div class="cap-info-table__skeleton-header">
        <cap-skeleton height="18px"></cap-skeleton>
    </div>

    <div class="cap-info-table__skeleton-items">
        <cap-skeleton height="50px"></cap-skeleton>
        <cap-skeleton height="50px"></cap-skeleton>
        <cap-skeleton height="50px"></cap-skeleton>
        <cap-skeleton height="50px"></cap-skeleton>
        <cap-skeleton height="50px"></cap-skeleton>
    </div>
</div>
