import { Injectable } from '@angular/core';
import { ApiHttpService, ApiService } from '@capturum/api';
import { BaseDataValueApiModel } from '../models/base-data-value.api-model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class BaseDataValueService extends ApiService<BaseDataValueApiModel> {
  protected endpoint = 'base-data-value';

  constructor(public apiHttp: ApiHttpService, private translateService: TranslateService) {
    super(apiHttp);
  }

  /**
   * Return a list of base-data-values filtered by key and optionally translated
   *
   * @param key - the base-data-key
   * @param translate - Whether or not to translate the values
   */
  public getListValuesByKey(key: string, translate: boolean = true): Observable<{ value: string, label: string }[]> {
    return this.list({ filters: [{ field: 'key.key', value: key }] }).pipe(
      map((response) => response.data.map((value) => ({
          value: value.key,
          label: translate ? this.translateService.instant(`base-data.${value.key.toLowerCase()}`) : value.label
        })
      ))
    );
  }
}
