export enum FlowListKey {
  actions = 'list_action_logs',
  flows = 'list_flow_logs',
}

export enum FlowListContextkey {
  actions = 'list_action_context',
  flows = 'list_flow_context',
  actionStatistics = 'list_action_statistics',
  flowStatistics = 'list_flow_statistics',
}
