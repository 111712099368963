import { TableText } from '@capturum/ui/api';
import { SelectItem } from 'primeng/api';

export interface InfoTableConfig {
  texts?: InfoTableTexts;
  cardBreakpoint: number;
  defaultTexts: TableText;
  rows?: number;
  styleClass?: string;
  sortable?: boolean;
  selectable?: boolean;
  showVisibilityToggler?: boolean;
  clickable?: boolean;
  pagination?: boolean;
  autoLayout?: boolean;
  virtualScroll?: boolean;
  lazyLoading?: boolean;
  sortMode?: 'single' | 'multiple';
  scrollHeight?: string;
  visibilityTogglerIcon?: string;
  dataKey?: string;
  virtualScrollItemSize?: number;
  perPageOptions?: SelectItem[];
  editableRows: EditableRowsButtons;
}

export interface InfoTableTexts {
  positive?: string;
  negative?: string;
}

export interface EditableRowsButtons {
  editButton: EditableRowsButton;
  saveButton: EditableRowsButton;
  cancelButton: EditableRowsButton;
}

export interface EditableRowsButton {
  label: string;
  icon: string;
  iconOnly: boolean;
}
