/*
  A media type (also known as a Multipurpose Internet Mail Extensions or MIME type) is a standard that indicates the nature and
  format of a document, file, or assortment of bytes.
  Example: <input type="file" accept='image/*, text/*'>
  https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types
*/
export enum GenericMimeTypes {
  Application = 'application/*',
  Audio = 'audio/*',
  Font = 'font/*',
  Image = 'image/*',
  Model = 'model/*',
  Text = 'text/*',
  Video = 'video/*',
  Multipart = 'multipart/*',
  Message = 'message/*',
}

export enum ImageMimeTypes {
  APNG = 'image/apng',
  Bmp = 'image/bmp',
  Gif = 'image/gif',
  Ico = 'image/x-icon',
  Jpeg = 'image/jpeg',
  Png = 'image/png',
  Svg = 'image/svg',
  Tiff = 'image/tiff',
  Webp = 'image/webp',
}

export enum AudioMimeTypes {
  Wave = 'audio/wave',
  Wav = 'audio/wav',
  XWav = 'audio/x-wav',
  XPnWav = 'audio/x-pn-wav',
  Webm = 'audio/webm',
  Ogg = 'audio/ogg',
}

export enum VideoMimeTypes {
  Webm = 'video/webm',
  Ogg = 'video/ogg'
}

export enum MulitpartMimeTypes {
  Message = 'message',
  Multipart = 'multipart'
}

export enum DocumentMimeTypes {
  Doc = 'application/msword',
  Docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  Docm = 'application/vnd.ms-word.document.macroEnabled.12',
  Pdf = 'application/pdf',
}
