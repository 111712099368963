import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HeaderTabsConfig } from '../interfaces/header-tabs-config.interface';

@Injectable({
  providedIn: 'root'
})
export class HeaderTabsConfigService {
  private headerConfig = new BehaviorSubject<HeaderTabsConfig>({ title: null });

  public getHeaderConfig(): Observable<HeaderTabsConfig> {
    return this.headerConfig.asObservable();
  }

  public setHeaderConfig(config: HeaderTabsConfig): void {
    this.headerConfig.next(config);
  }

  public patchHeaderConfig(config: Partial<HeaderTabsConfig>): void {
    this.headerConfig.next({
      ...this.headerConfig.getValue(),
      ...config
    });
  }

  public resetHeaderConfig(): void {
    this.headerConfig.next({ title: '' });
  }
}
