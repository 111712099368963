import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ActionProvider } from '../interfaces/action-provider.interface';
import { InterpolationPipe } from '@capturum/ui/api';
import { DOCUMENT } from '@angular/common';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NavigateAction implements ActionProvider {
  private route: string;

  constructor(
    private readonly router: Router,
    private readonly interpolationPipe: InterpolationPipe,
    @Inject(DOCUMENT) private document: Document
  ) {}

  public execute(
    options: any,
    item: any,
    context: Record<string, any> = {}
  ): { response: any } | Observable<any> | void {
    if (options?.route) {
      this.route = this.interpolationPipe.transform(options.route, { ...context, ...item });

      if (this.route.endsWith('/')) {
        this.route = this.route.slice(0, -1); // remove last character
      }

      if (options?.control_click && options?.action?.ctrlKey) {
        this.openNewWindow();
      } else {
        this.router.navigateByUrl(this.route);
      }
    }
  }

  private openNewWindow(): void {
    const origin = this.document.location.origin;
    const tabUrl = `${origin}${this.route}`;

    window.open(tabUrl, '_blank');
  }
}
