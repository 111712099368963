import { InputFilterComponent } from './components/input-filter/input-filter.component';
import { CapturumDropdownFilterComponent } from './components/dropdown-filter/dropdown-filter.component';
import { CapturumMultiSelectFilterComponent } from './components/multi-select-filter/multi-select-filter.component';
import { CalendarFilterComponent } from './components/calendar-filter/calendar-filter.component';
import { DynamicFilterType } from './enums/dynamic-filter-type.enum';
import { DynamicRangeFilterComponent } from './components/range-filter/range-filter.component';
import { CheckboxFilterComponent } from './components/checkbox-filter/checkbox-filter.component';
import { CapturumDateTimeDurationFilterComponent } from './components/date-time-duration-filter/date-time-duration-filter.component';

export const dynamicFilterComponents = [
  { name: DynamicFilterType.input, component: InputFilterComponent },
  { name: DynamicFilterType.dropdown, component: CapturumDropdownFilterComponent },
  { name: DynamicFilterType.multiselect, component: CapturumMultiSelectFilterComponent },
  { name: DynamicFilterType.date, component: CalendarFilterComponent },
  { name: DynamicFilterType.range, component: DynamicRangeFilterComponent },
  { name: DynamicFilterType.checkbox, component: CheckboxFilterComponent },
  { name: DynamicFilterType.datetimeduration, component: CapturumDateTimeDurationFilterComponent}
];
