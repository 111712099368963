import { FormlyFieldConfig, FormlyFieldProps } from '@ngx-formly/core';

/**
 * Used for Form component
 */
export class FormField implements FormlyFieldConfig {
  public key: string;
  public id?: string;
  public type?: string;
  public className?: string;
  public props?: any;
  public wrappers?: string[];

  constructor(data: FormField) {
    // Set values
    this.key = data['key'];
    this.id = data['key'];
    this.type = data['type'] || 'input';
    this.className = data['className'] || null;
    this.wrappers = data['wrappers'] || ['cap-form-field'];

    let props = {};

    if (data['props']) {
      props = data['props'];
      props['label'] = data['props']['label'] || data['key'];
      props['placeholder'] = data['props']['placeholder'] || `${props['label']}...`;
      props['required'] = data['props']['required'] || true;
      props['options'] = data['props']['options'] || true;
    }

    this.props = props;
  }
}
