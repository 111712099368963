import { Injectable } from '@angular/core';
import { TextInputTypeBuilder } from '../text/text-input-type.builder';
import { InputConfiguration, InputType } from '@capturum/builders/core';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Injectable({ providedIn: 'root' })
export class EditorInputTypeBuilder extends TextInputTypeBuilder {
  protected inputType: string = InputType.editor;

  protected appendOptions(input: FormlyFieldConfig, configuration: InputConfiguration) {
    input.props = {
      ...input.props,
      ...configuration.options,
    }

    super.appendOptions(input, configuration);
  }
}
