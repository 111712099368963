import { FormControl, ValidationErrors } from '@angular/forms';

/**
 * Formly validator for special characters
 *
 * @param control
 */
export function noSpecialChars(control: FormControl): ValidationErrors {
  const regex = /^[a-zA-Z0-9\s]*$/;
  const valid = regex.test(control.value);

  return valid ? null : { specialChars: true };
}
