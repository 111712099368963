import { FieldType } from '@ngx-formly/core';
import { Component } from '@angular/core';

@Component({
  selector: 'em-multi-select-type',
  template: `
    <cap-multi-select [options]="to.options | formlySelectOptions:field | async"
                      [formlyAttributes]="field"
                      [formControl]="formControl"
                      [defaultLabel]="(to.translatePlaceholder || to.placeholder) | observablePipe | async"
                      [disabled]="to.disabled"
                      [styleClass]="to.styleClass"
                      [inputId]="field.key"
                      [filter]="to.filter || defaultOptions.props.filter"
                      [filterBy]="to.filterBy || defaultOptions.props.filterBy"
                      [filterPlaceHolder]="to.filterPlaceHolder"
                      [appendTo]="to.appendTo"
                      [autofocusFilter]="to.autofocusFilter"
                      (onChange)="to.change ? to.change(field, $event) : null"
                      [scrollHeight]="to.scrollHeight || defaultOptions.props.scrollHeight"
                      [readonly]="to.readOnly"
                      [floatLabel]="(to.label_position | observablePipe | async ) === 'floating'"
                      [label]="(to.label_position | observablePipe | async ) === 'floating' ? to.label : ''">
    </cap-multi-select>`,
})
export class MultiSelectTypeComponent extends FieldType {
  defaultOptions = {
    props: {
      filter: true,
      filterBy: 'label',
      appendTo: null,
      scrollHeight: '200px',
      overlayVisible: false,
      dataKey: 'value',
      defaultLabel: 'Choose',
      onChange: null,
      autofocusFilter: true,
    },
  };
}
