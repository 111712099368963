<cap-dropdown [appendTo]="to?.appendTo || 'body'"
              [attr.data-test]="'builder-' + field.key"
              [filterBy]="'search'"
              [filter]="true"
              [formControl]="formControl"
              [hidden]="to.readonly"
              [optionLabel]="'label'"
              [optionValue]="'key'"
              [options]="icons"
              [panelStyleClass]="to.css_class"
              [placeholder]="to.placeholder || ('placeholder.select' | translate)"
              [readonly]="to.readonly"
              [showClear]="true"
              [sortAlphabetically]="to?.sort_asc"
              [virtualScrollItemSize]="to.virtualScrollItemSize || 30"
              [virtualScroll]="to.virtualScroll">>
  <ng-template capTemplate="item" let-item>
    <ng-container [ngTemplateOutletContext]="{$implicit: item}"
                  [ngTemplateOutlet]="icon">
    </ng-container>
  </ng-template>

  <ng-template capTemplate="selectedItem" let-item>
    <ng-container [ngTemplateOutletContext]="{$implicit: item}"
                  [ngTemplateOutlet]="icon">
    </ng-container>
  </ng-template>

  <ng-template #icon let-item>
    <div class="cpb-icon-input-icons">
      <i [class]="item.key" [ngClass]="'input-icon'"></i>
      <div class="icon-name">{{ item.label }}</div>
    </div>
  </ng-template>
</cap-dropdown>

<cpb-readonly-container [attr.data-test]="'builder-readonly-' + field.key" [hidden]="!to.readonly">
  <div class="cpb-icon-input-icons">
    <i [class]="formControl.value" [ngClass]="'input-icon'"></i>
    {{ [formControl.value] | labelsByValue : icons | default : to?.defaultEmptyValue }}
  </div>
</cpb-readonly-container>
