import { MapItem, TwoFactorAuthService, TwoFactorMethod } from '@capturum/auth';
import { Component, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CapturumInputCodeComponent } from '@capturum/ui/input-code';

@Component({
  selector: 'emc-multi-factor-form',
  templateUrl: './multi-factor-form.component.html',
  styleUrls: ['./multi-factor-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MultiFactorFormComponent implements OnInit { // @Deprecated
  public types$: Observable<MapItem[]>;
  public form: FormGroup;
  public showCode: boolean = false;
  public TwoFactorMethod: typeof TwoFactorMethod = TwoFactorMethod;

  @ViewChild(CapturumInputCodeComponent)
  inputCodeComponent: CapturumInputCodeComponent;

  @Output()
  public autoSubmit = new EventEmitter<{ code: string, method: TwoFactorMethod }>();

  public get controlMethod(): AbstractControl {
    return this.form.get('method');
  }

  public get controlKey(): AbstractControl {
    return this.form.get('code');
  }

  constructor(
    protected twoFactorAuthService: TwoFactorAuthService,
    private formBuilder: FormBuilder,
  ) {
    this.types$ = this.twoFactorAuthService.list2faMethods();
  }

  public ngOnInit(): void {
    this.form = this.generateForm();
  }

  public changeMethodAuth(method: TwoFactorMethod): void {
    this.form.get('code').setValue(null);

    this.showCode = method === TwoFactorMethod.google;
  }

  public appInputCodeFilled(): void {
    this.autoSubmit.emit(this.form.value);
  }

  public showAndFocusInputCode(): void {
    this.showCode = true;
    this.form.get('code').setValue(null);

    Promise.resolve(null).then(() => this.inputCodeComponent.focus());
  }

  private generateForm(): FormGroup {
    return this.formBuilder.group({
      code: [null, [Validators.required, Validators.minLength(6)]],
      method: [null, Validators.required],
    });
  }
}
