import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CapturumSkeletonComponent } from './skeleton.component';

@NgModule({
  declarations: [CapturumSkeletonComponent],
  exports: [CapturumSkeletonComponent],
  imports: [CommonModule]
})
export class CapturumSkeletonModule {}
