import { Injectable } from '@angular/core';
import { OptionsPipe } from '../pipes/options.pipe';
import { FormInputHelperService } from '../services/form-input-helper.service';
import { TranslateService } from '@ngx-translate/core';
import { FormRendererConfigService } from '../form-renderer.config';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { MapItem } from '@capturum/ui/api';
import { FormBuilderSource } from '../models/form-builder-config.model';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { DependencyOperator, InputConfiguration } from '@capturum/builders/core';
import { FormRendererState } from '../state/form-renderer/form-renderer.state';
import { FormManipulatorService } from '../services/form-manipulator.service';

@Injectable({ providedIn: 'root' })
export class FormInputTypeFacade {
  constructor(
    private readonly optionsPipe: OptionsPipe,
    private readonly formInputHelperService: FormInputHelperService,
    private readonly translateService: TranslateService,
    private readonly formRendererConfig: FormRendererConfigService,
    private readonly store: Store,
    private readonly formManipulatorService: FormManipulatorService,
  ) {
  }

  public getTransformedOptions(source: FormBuilderSource, formKey: string): Observable<MapItem[]> {
    return this.optionsPipe.transform(source, formKey).pipe(
      map(options => {
        return this.formInputHelperService.getOptionsWithImageKey([...options], source.source?.image_key);
      }),
      shareReplay(1),
    );
  }

  public setInputConfiguration(name: string, configuration: any): void {
    this.formInputHelperService.setInputConfiguration(name, configuration);
  }

  public handleOptionsSourceSets(field: FormlyFieldConfig, configuration: InputConfiguration): void {
    this.formInputHelperService.handleOptionsSourceSets(field, configuration);
  }

  public resolveDependencies(field: FormlyFieldConfig, configuration: InputConfiguration, options$?: Observable<MapItem[]>): void {
    this.formInputHelperService.resolveDependencies(
      field,
      configuration.dependencies, configuration.dependency_operator as DependencyOperator,
      options$,
    );
  }

  public setDefaultDropdownOption(options$: Observable<MapItem[]>, field: FormlyFieldConfig): void {
    this.formInputHelperService.setDefaultDropdownOption(options$, field);
  }

  public setCustomValidations(configuration: InputConfiguration, field: FormlyFieldConfig): void {
    if (configuration?.validations) {
      this.formInputHelperService.setCustomValidations(configuration.validations, field);
    }
  }

  public getValidations(configuration: InputConfiguration): Record<string, any> {
    if (configuration?.validations) {
      return this.formInputHelperService.getValidations(configuration?.validations as []);
    }

    return {};
  }

  public getLabelPosition(formKey: string): Observable<string> {
    return this.store.select(FormRendererState.configurationByKey(formKey))
      .pipe(switchMap(config => of<string>(config?.label_position as string ?? null)))
  }

  public setValueByKey(sets: string, value: any): void {
    this.formInputHelperService.setValueByKey(sets as string, value);
  }

  public registerTriggerHandler(field: FormlyFieldConfig, formKey: string, configuration: InputConfiguration): void {
    this.formManipulatorService.registerTriggerHandler(field, formKey, configuration);
  }

  public getFormManipulations(formKey: string, fieldKey: string): Observable<Record<string, any>> {
    return this.formManipulatorService.getFieldManipulations(formKey, fieldKey);
  }

  public getFormRendererConfig(): FormRendererConfigService {
    return this.formRendererConfig;
  }
}
