import { Pipe, PipeTransform } from '@angular/core';
import { AssetType } from '../enums/asset-type.enum';

@Pipe({
  name: 'assets'
})
export class AssetsPipe implements PipeTransform {
  public transform(value: string, folder: keyof typeof AssetType = 'images'): string {
    if (AssetType[folder]) {
      return `./assets/${AssetType[folder]}/${value}`;
    }

    return `./assets/${folder}/${value}`;
  }
}
