import { Dexie } from 'dexie';
import { Store } from './store';
import { format } from 'date-fns';

/**
 * Abstract class for models
 * Contains base methods for data logic and defines properties used for database setup (Dexie)
 *
 * A model can contain these default fields:
 * '++id'         field is unique and autoincremented
 * '*_remote_id'  field contains the id of the backend record.
 * 'updated_at'   field contains timestamp when record was updated locally
 * 'synced_at'    field contains timestamp when record was synced
 * 'sync_message' field contains message if sync failed
 */
export abstract class IndexedDbModel {

  public static store: Store = null;

  /**
   * Get a Dexie table instance
   *
   * @return - dexie table instance
   */
  public static query(this: new (attributes: any) => IndexedDbModel): Dexie.Table<any, any> {
    const self = this;

    return IndexedDbModel.store[(new self(null)).table];
  }

  public abstract entity: string;
  public abstract table: string;
  public abstract schema: string;

  public id: string | number;

  public remote_id?: number;
  public synced_at?: Date;
  public synced_message?: string;

  public created_at?: Date;
  public updated_at?: Date;
  public deleted_at?: Date;

  /**
   * Set if model should be synchronised with server
   */
  public sync = false;

  constructor(attributes: any) {
    for (const attributeName in attributes) {
      if (attributes.hasOwnProperty(attributeName)) {
        this[attributeName] = attributes[attributeName];
      }
    }

    // Generate negative unique id if not set
    if (!this.id) {
      this.id = -Date.now();
    }
  }

  /**
   * Get the instance of the current class
   *
   * @return - An instance of the current class
   */
  public getInstance(this: new (attributes: any) => IndexedDbModel): IndexedDbModel {
    return new this({});
  }

  /**
   * Initialize the model
   */
  public async init(): Promise<any> {}
  /**
   * Returns the object representation suitable for the backend
   *
   * @return - data for the backend
   */
  public getData(): any {
    const data = JSON.parse(JSON.stringify(this));

    delete data.entity;
    delete data.table;
    delete data.schema;
    delete data.synced_at;
    delete data.synced_message;
    delete data.sync;

    data.created_at = this.formatDate(data.created_at);
    data.updated_at = this.formatDate(data.updated_at);
    data.deleted_at = this.formatDate(data.deleted_at);

    return data;
  }

  /**
   * Format the date to YYYYY-MM-DD HH:mm:ss format
   *
   * @param date - The date to be formatted
   *
   * @return - The formatted date
   */
  protected formatDate(date?: Date): string {
    return date ? format(date, 'yyyy-MM-dd HH:mm:ss') : null;
  }
}
