import { DOCUMENT } from '@angular/common';
import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Inject,
  Injectable,
  Injector,
  Type,
} from '@angular/core';
import {
  DynamicDialogComponent,
  DynamicDialogConfig,
  DynamicDialogInjector,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';

@Injectable()
export class CapturumDialogService {
  public dialogComponentRefMap: Map<DynamicDialogRef, ComponentRef<DynamicDialogComponent>> = new Map();

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    @Inject(DOCUMENT)
    private document: Document
  ) {}

  public open(
    componentType: Type<any>,
    config: DynamicDialogConfig,
    containerComponent: Type<any> = DynamicDialogComponent
  ): DynamicDialogRef {
    const dialogRef = this.appendDialogComponentToBody(config, containerComponent);

    (this.document.activeElement as HTMLElement)?.blur();

    this.dialogComponentRefMap.get(dialogRef).instance.childComponentType = componentType;

    return dialogRef;
  }

  private appendDialogComponentToBody(
    config: DynamicDialogConfig,
    containerComponent: Type<any> = DynamicDialogComponent
  ): DynamicDialogRef {
    const map = new WeakMap();
    map.set(DynamicDialogConfig, config);

    const dialogRef = new DynamicDialogRef();
    map.set(DynamicDialogRef, dialogRef);

    const sub = dialogRef.onClose.subscribe(() => {
      this.dialogComponentRefMap.get(dialogRef).instance.close();
    });

    const destroySub = dialogRef.onDestroy.subscribe(() => {
      this.removeDialogComponentFromBody(dialogRef);
      destroySub.unsubscribe();
      sub.unsubscribe();
    });

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(containerComponent);
    const componentRef = componentFactory.create(new DynamicDialogInjector(this.injector, map));

    this.appRef.attachView(componentRef.hostView);

    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);

    this.dialogComponentRefMap.set(dialogRef, componentRef);

    return dialogRef;
  }

  private removeDialogComponentFromBody(dialogRef: DynamicDialogRef): void {
    const dialogComponentRef = this.dialogComponentRefMap.get(dialogRef);

    if (!dialogRef || !this.dialogComponentRefMap.has(dialogRef)) {
      return;
    }

    this.appRef.detachView(dialogComponentRef.hostView);
    dialogComponentRef.destroy();
    this.dialogComponentRefMap.delete(dialogRef);
  }
}
