import { CommonModule } from '@angular/common';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem, UserMenu } from '../../interfaces/header-tabs-config.interface';

@Component({
  selector: 'cap-header-tabs-user-menu',
  templateUrl: './user-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, OverlayPanelModule]
})
export class UserMenuComponent {
  @Input()
  public config: UserMenu;

  constructor(private readonly router: Router) {
  }

  public executeCommand(item: MenuItem): void {
    if (item.command) {
      item.command(null);
    } else if (item.routerLink) {
      this.router.navigateByUrl(item.routerLink);
    }
  }
}
