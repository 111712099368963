import { Pipe, PipeTransform } from '@angular/core';
import { forkJoin, Observable, of, isObservable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TableAction } from '../models/table-action.model';

@Pipe({
  name: 'allActionsIsHidden'
})
export class AllActionsIsHiddenPipe implements PipeTransform {
  public transform(value: TableAction[]): Observable<boolean> {
    const hiddenValues: Observable<boolean>[] = value.map(action => {
      return isObservable(action.hidden) ? action.hidden : of(!!action.hidden);
    });

    return forkJoin(hiddenValues).pipe(map(values => {
      return values.every(item => !!item);
    }));
  }
}
