import { CSSVarNames } from '../enums/css-vars.enum';

export interface Theme {
  [CSSVarNames.Primary]: string;
  [CSSVarNames.Secondary]: string;
  [CSSVarNames.Third]: string;
  [CSSVarNames.Text]: string;
  [CSSVarNames.Text2]: string;
  [CSSVarNames.Text3]: string;
  [CSSVarNames.Text4]: string;
  [CSSVarNames.Success]: string;
  [CSSVarNames.Warning]: string;
  [CSSVarNames.Confirm]: string;
  [CSSVarNames.PrimaryShadow]: string;
  [CSSVarNames.SecondaryShadow]: string;
  [CSSVarNames.ErrorShadow]: string;
  [CSSVarNames.ThirdShadow]: string;
  [CSSVarNames.SuccessShadow]: string;
  [CSSVarNames.WarningShadow]: string;
  [CSSVarNames.ConfirmShadow]: string;
  [CSSVarNames.Error]: string;
  [CSSVarNames.Info]: string;
  [CSSVarNames.ToastText]: string;
  [CSSVarNames.Font]?: string;
  [CSSVarNames.InputHeight]?: string;
  [CSSVarNames.DynamicFilterInputHeight]?: string;
}
