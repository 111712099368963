import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Optional,
  Renderer2,
  Type,
  ViewChild,
} from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogcontentDirective } from '@capturum/ui/api';
import { ContainerComponent } from '../../interfaces/container-component.interface';

@Component({
  selector: 'cpb-dynamic-sidebar',
  templateUrl: './dynamic-sidebar.component.html',
  styleUrls: ['./dynamic-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicSidebarComponent implements AfterViewInit, ContainerComponent {
  public visible: boolean;
  @ViewChild(DynamicDialogcontentDirective)
  public insertionPoint: DynamicDialogcontentDirective;

  public componentRef: ComponentRef<any>;
  public childComponentType: Type<any>;
  public title: string;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private cd: ChangeDetectorRef,
    public renderer: Renderer2,
    public config: DynamicDialogConfig,
    @Optional() private dialogRef: DynamicDialogRef,
  ) {
  }

  public ngAfterViewInit(): void {
    if (this.childComponentType) {
      this.loadChildComponent(this.childComponentType);
      this.cd.detectChanges();

      if (this.config) {
        this.title = this.config.header;
      }

      // Make sure the animation is triggered for opening the sidebar
      setTimeout(() => {
        this.visible = true;
        this.cd.detectChanges();
      }, 100);
    }
  }

  public loadChildComponent(componentType: Type<any>): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);
    const viewContainerRef = this.insertionPoint.viewContainerRef;

    viewContainerRef.clear();
    this.componentRef = viewContainerRef.createComponent(componentFactory);
  }
}
