<ng-container *ngIf="(config$ | async)?.cardBreakpoint as cardBreakpoint">
  <tr
    class="cap-info-table__row {{ cardsView ? 'card-view' : '' }} {{ item?.tr_style_class || '' }} {{
      styleClassExpression | resolveStyleClassExpressionPipe : item
    }}"
    [class.cap-table-row-selected]="item | capIsTableRowSelected : selectedValues : dataKey"
    [pEditableRow]="editableRows ? item : null"
    cdkDrag
    [cdkDragDisabled]="!reorderableRows">
    <td *ngIf="reorderableRows" class="reorderable-row">
      <i [class]="reorderableRowIcon" cdkDragHandle [cdkDragDisabled]="!reorderableRows"></i>
    </td>

    <!-- TODO: do not use function in template -->
    <td *ngIf="hasCardHeaderContent(cardBreakpoint)" class="frozen-actions" capFrozenColumn [frozen]="hasFrozenColumns">
      <div
        class="cap-info-table__actions-column selectable-column"
        [ngClass]="{ 'cap-info-table__actions-column--flexed': pageWidth < cardBreakpoint && cardsView }">
        <div class="cap-info-table__column-selectable">
          <div *ngIf="selectable" class="cap-info-table__row-select">
            <p-tableCheckbox #tableCheckbox [value]="item"></p-tableCheckbox>
          </div>

          <div class="cap-info-table__card-header">
            <ng-container
              *ngIf="templates | columnTemplate : 'card-header' as customTemplate"
              [ngTemplateOutletContext]="{ item: item, index: rowIndex }"
              [ngTemplateOutlet]="customTemplate">
            </ng-container>
          </div>
        </div>

        <div
          *ngIf="(columns | columnsByType : DataTableType.Actions) && cardsView"
          class="cap-info-table__column-mob-actions">
          <ng-container *ngFor="let column of columns">
            <ng-container *ngIf="column?.type === DataTableType.Actions">
              <ng-container
                *ngIf="templates | columnTemplate : column?.field as customTemplate"
                [ngTemplateOutletContext]="{ item: item, index: rowIndex }"
                [ngTemplateOutlet]="customTemplate">
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </td>

    <ng-container *ngFor="let column of columns">
      <td
        *ngIf="!column?.hidden"
        class="cap-info-table__column {{ cardsView ? 'cards-view' : '' }} cap-info-table__column-{{ column?.field }} {{
          column?.cellClass
        }}"
        capFrozenColumn
        [attr.data-test]="'cap-info-table__column_' + column?.field"
        [class.cap-info-table__column--clickable]="clickable"
        [frozenColumnWidth]="column?.frozenColumnWidth"
        [frozen]="column?.frozen"
        [ngClass]="{ 'cap-info-table__column-actions': column?.type === DataTableType.Actions }"
        [class.editable-rows-buttons]="editableRows"
        (click)="onElementClick($event, item, column)">
        <ng-container [ngSwitch]="column?.type">
          <ng-container *ngSwitchCase="DataTableType.Boolean">
            <cap-cell-data [column]="column" [isCard]="pageWidth < cardBreakpoint && cardsView">
              <ng-container *ngIf="(config$ | async)?.texts as texts">
                {{ (item | propertyValue : column?.field) ? texts.positive : texts.negative }}
              </ng-container>
            </cap-cell-data>
          </ng-container>

          <ng-container *ngSwitchCase="DataTableType.Template">
            <cap-info-table-cell-editor
              *ngIf="editableRows || editTable; else defaultTemplate"
              [editTable]="editTable"
              [isEdit]="isEdit">
              <ng-template capTemplate="input">
                <ng-container
                  *ngIf="templates | columnTemplate : 'cellEdit_' + column.field as cellEditTemplate"
                  [ngTemplateOutletContext]="{ item: item, index: rowIndex }"
                  [ngTemplateOutlet]="cellEditTemplate">
                </ng-container>
              </ng-template>

              <ng-template capTemplate="output">
                <cap-cell-data [column]="column" [isCard]="pageWidth < cardBreakpoint && cardsView">
                  <ng-container
                    *ngIf="
                      templates | columnTemplate : column?.field as customTemplate;
                      else defaultCellEditOutputTemplate
                    "
                    [ngTemplateOutletContext]="{ item: item, index: rowIndex }"
                    [ngTemplateOutlet]="customTemplate">
                  </ng-container>

                  <ng-template #defaultCellEditOutputTemplate>
                    <span class="cap-cell-tooltip-container">
                      <span [capCellTooltip]="item | propertyValue : column?.field" [loading]="loading">
                        {{ item | propertyValue : column?.field }}
                      </span>
                    </span>
                  </ng-template>
                </cap-cell-data>
              </ng-template>
            </cap-info-table-cell-editor>

            <ng-template #defaultTemplate>
              <cap-cell-data [column]="column" [isCard]="pageWidth < cardBreakpoint && cardsView">
                <ng-container
                  *ngIf="templates | columnTemplate : column?.field as customTemplate"
                  [ngTemplateOutletContext]="{ item: item, index: rowIndex }"
                  [ngTemplateOutlet]="customTemplate">
                </ng-container>
              </cap-cell-data>
            </ng-template>
          </ng-container>

          <ng-container *ngSwitchCase="DataTableType.Actions">
            <cap-cell-data [column]="column" [isCard]="pageWidth < cardBreakpoint && cardsView">
              <ng-container
                *ngIf="templates | columnTemplate : column?.field as customTemplate"
                [ngTemplateOutletContext]="{ item: item, index: rowIndex }"
                [ngTemplateOutlet]="customTemplate">
              </ng-container>
            </cap-cell-data>
          </ng-container>

          <ng-container
            *ngSwitchCase="DataTableType.EditButtons"
            [ngTemplateOutletContext]="{ item: item, index: rowIndex }"
            [ngTemplateOutlet]="editableRowsButtons">
          </ng-container>

          <ng-container *ngSwitchDefault>
            <cap-cell-data [column]="column" [isCard]="pageWidth < cardBreakpoint && cardsView">
              <span class="cap-cell-tooltip-container">
                <span [capCellTooltip]="item | propertyValue : column?.field" [loading]="loading">
                  {{ item | propertyValue : column?.field }}
                </span>
              </span>
            </cap-cell-data>
          </ng-container>
        </ng-container>
      </td>
    </ng-container>

    <ng-template #editableRowsButtons>
      <ng-container *ngIf="(config$ | async)?.editableRows as editableRows">
        <div
          pInitEditableRow
          *ngIf="!editing"
          class="editable-rows-button edit"
          [class.icon-only]="editableRows.editButton.iconOnly"
          (click)="onRowEditInit($event, item)">
          <i [class]="editableRows.editButton.icon"></i>
          <span class="label" *ngIf="!editableRows.editButton.iconOnly">{{ editableRows.editButton.label }}</span>
        </div>

        <div
          pSaveEditableRow
          *ngIf="editing"
          class="editable-rows-button save"
          [class.icon-only]="editableRows.saveButton.iconOnly"
          (click)="onRowEditSave($event, item)">
          <i [class]="editableRows.saveButton.icon"></i>
          <span class="label" *ngIf="!editableRows.saveButton.iconOnly">{{ editableRows.saveButton.label }}</span>
        </div>

        <div
          pCancelEditableRow
          *ngIf="editing"
          class="editable-rows-button cancel"
          [class.icon-only]="editableRows.cancelButton.iconOnly"
          (click)="onRowEditCancel($event, { index: rowIndex, item })">
          <i [class]="editableRows.cancelButton.icon"></i>
          <span class="label" *ngIf="!editableRows.cancelButton.iconOnly">{{ editableRows.cancelButton.label }}</span>
        </div>
      </ng-container>
    </ng-template>
  </tr>
</ng-container>
