<div class="ibox">
  <div class="ibox-title">
    <h5>{{ 'role.select-role.title' | translate }}</h5>
  </div>

  <div class="ibox-content">
    <p-dropdown dataKey="id" [(ngModel)]="selectedRole" optionLabel="name" [options]="roles"
                [placeholder]="'role.select-role.placeholder' | translate">
    </p-dropdown>

    <cap-button label="{{ 'role.select-role.title' | translate }}" icon="fa fa-user-tag" class="mt-3"
               (click)="selectRole()">
    </cap-button>
  </div>
</div>
