<div class="form-element">
    <label *ngIf="label" [style]="{ 'justify-content': label ? 'space-between' : 'flex-end' }">
        <span *ngIf="label">{{ label }}</span>
    </label>

    <div class="p-inputgroup">
        <p-inputNumber [(ngModel)]="value"
                       [format]="format"
                       [showButtons]="showButtons"
                       [buttonLayout]="buttonLayout"
                       [locale]="locale"
                       [localeMatcher]="localeMatcher"
                       [mode]="mode"
                       [prefix]="prefix"
                       [suffix]="suffix"
                       [currency]="currency"
                       [currencyDisplay]="currencyDisplay"
                       [useGrouping]="useGrouping"
                       [minFractionDigits]="minFractionDigits"
                       [maxFractionDigits]="maxFractionDigits"
                       [min]="min"
                       [max]="max"
                       [step]="step"
                       [styleClass]="styleClass"
                       [placeholder]="placeholder"
                       [maxlength]="maxlength"
                       [tabindex]="tabindex"
                       [disabled]="disabled"
                       [autocomplete]="autocomplete"
                       [decrementButtonClass]="'cap-button ' + decrementButtonClass"
                       [incrementButtonClass]="'cap-button ' + incrementButtonClass"
                       [decrementButtonIcon]="decrementButtonIcon"
                       [incrementButtonIcon]="incrementButtonIcon"
                       [readonly]="readonly"
                       (onFocus)="onFocus($event)"
                       (onBlur)="onBlur($event)">
        </p-inputNumber>
    </div>

    <cap-validator *ngIf="control" [control]="control" [customName]="label"></cap-validator>
</div>
