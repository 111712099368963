import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { CapturumCalendarComponent } from './calendar.component';
import { CalendarModule } from 'primeng/calendar';
import { KeyFilterModule } from 'primeng/keyfilter';
import { CapturumTimepickerComponent } from './components/timepicker/timepicker.component';
import { CapturumButtonModule } from '@capturum/ui/button';
import { CapturumSharedModule } from '@capturum/ui/api';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    CapturumCalendarComponent,
    CapturumTimepickerComponent
  ],
  exports: [
    CapturumCalendarComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    CalendarModule,
    KeyFilterModule,
    CapturumSharedModule,
    CapturumButtonModule
  ],
  providers: [
    DatePipe,
  ]
})
export class CapturumCalendarModule {
}
