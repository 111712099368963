<cap-input [type]="to.type || 'text'"
           [class]="to.styleClass"
           [placeholder]="(to.translatePlaceholder || to.placeholder) | observablePipe | async"
           [formlyAttributes]="field"
           [autocomplete]="to.autocomplete || 'off'"
           [formControl]="formControl"
           [class.ng-dirty]="showError"
           [floatLabel]="(to.label_position | observablePipe | async ) === 'floating'"
           [label]="(to.label_position | observablePipe | async ) === 'floating' ? to.label : ''">
</cap-input>
