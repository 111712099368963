import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter, Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import { DynamicFilterComponent, FilterConfigItem, } from '../../interfaces/dynamic-filter.interface';
import { filter, map, startWith } from 'rxjs/operators';

@Component({
  selector: 'cap-input-filter',
  templateUrl: './input-filter.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputFilterComponent
  implements DynamicFilterComponent<string>, OnInit, OnDestroy {
  @Input()
  public filterConfig: FilterConfigItem;
  public updateValue: EventEmitter<string> = new EventEmitter<string>();
  public formControl = new FormControl();
  public value: string;

  private lastUpdatedValue: string;

  private destroy$ = new Subject<boolean>();

  constructor(private cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.formControl.valueChanges
      .pipe(
        debounceTime(this.filterConfig.debounceTime || 300),
        startWith(this.formControl.value),
        map((value) => value ? value?.trim() : value),
        filter((value) => {
          return value !== this.lastUpdatedValue;
        }),
        takeUntil(this.destroy$),
      ).subscribe((value) => {
      this.updateFilterValue(value);
      this.lastUpdatedValue = value;
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public updateFilterValue(value: string): void {
    this.updateValue.emit(value);
  }

  public setValue(value: string): void {
    this.formControl.setValue(value, { emitEvent: false });
    this.lastUpdatedValue = value;
    this.cdr.detectChanges();
  }

  public updateView(): void {
    this.cdr.detectChanges();
  }

  public reset(): void {
    this.formControl.setValue(null);
  }
}
