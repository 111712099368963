import { Injectable } from '@angular/core';
import { Message, MessageService } from 'primeng/api';

/**
 * @deprecated in favor of @capturum/ui/api toastService
 */
@Injectable({
  providedIn: 'root'
})
export class ToastService {
  constructor(private messageService: MessageService) {}

  /**
   * Show error message
   *
   * @param title
   * @param detail
   *
   * @return void
   */
  public error(title: string, detail: string): void {
    this.message({ severity: 'error', summary: title, detail });
  }

  /**
   * Show warning
   *
   * @param title
   * @param detail
   *
   * @return void
   */
  public warning(title: string, detail: string): void {
    this.message({ severity: 'warn', summary: title, detail });
  }

  /**
   * Show success message
   *
   * @param title
   * @param detail
   *
   * @return void
   */
  public success(title: string, detail: string): void {
    this.message({ severity: 'success', summary: title, detail });
  }

  /**
   * Add message to message service
   *
   * @param message
   *
   * @return void
   */
  public message(message: Message) {
    this.messageService.add(message);
  }

  /**
   * Display error message based on json error response
   *
   * @param errorResponse
   * @param title
   * @param detail
   *
   * @return void
   */
  public handleError(errorResponse: any, title: string, detail: string = 'Item'): void {
    let errorMessages = '';
    let status = errorResponse.status;

    if (!errorResponse.status) {
      if (errorResponse.status_code) {
        status = errorResponse.status_code;
      } else if (errorResponse.type === 3) {
        status = 404;
      }
    }

    // Unauthorized status is handled via authError in the api service
    if (status === 401) {
      return;
    }

    switch (status) {
      case 404:
        errorMessages = detail + ' is niet gevonden.';
        break;
      case 500:
        errorMessages = 'Interne fout, neem contact op met de beheerder.';
        break;
      case 422:
        if (errorResponse.errors) {
          for (const error in errorResponse.errors) {
            if (error) {
              errorMessages += `${errorResponse.errors[error]}<br />`;
            }
          }
        } else if (errorResponse.message) {
          errorMessages += errorResponse.message;
        } else {
          errorMessages = 'Niet alle velden zijn juist ingevuld.';
        }

        break;
      default:
        if (errorResponse.errors) {
          for (const error in errorResponse.errors) {
            if (error) {
              errorMessages += `${errorResponse.errors[error]}<br />`;
            }
          }
        }

        if (errorResponse.message) {
          errorMessages += errorResponse.message;
        }

        break;
    }

    if (errorMessages !== '') {
      this.error(title, errorMessages);
    }
  }
}
