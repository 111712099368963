import { Injectable } from '@angular/core';
import { AuthService } from '@capturum/auth';
import { ApiHttpService, ApiService } from '@capturum/api';
import { jwtDecode } from 'jwt-decode';
import { map } from 'rxjs/operators';
import { UserIndexedDbModel } from './user.indexedDb-model';

@Injectable({ providedIn: 'root' })
export class UserService extends ApiService<UserIndexedDbModel> {
  protected endpoint = 'user';

  constructor(public apiHttp: ApiHttpService, private auth: AuthService) {
    super(apiHttp);
  }

  /**
   * Checks if the current user has the given role
   */
  public hasRole(roleCode: string): boolean {
    const jwt = this.getTokenAsObject();

    return jwt.role === roleCode;
  }

  /**
   * Checks if the current user has superuser rights
   */
  public isSuperuser(): boolean {
    return this.hasRole('superuser');
  }

  /**
   * Checks if the current user has administrator rights
   */
  public isAdministrator(): boolean {
    return this.hasRole('superuser') || this.hasRole('administrator');
  }

  /**
   * Get the specialties of the user and store them in the indexedDB
   *
   * @return Promise<void>
   */
  public async loadUsers(): Promise<void> {
    const users = await this.apiHttp
      .get(`/${this.endpoint}/sync`)
      .pipe(map((response: { data: UserIndexedDbModel[] }) => response.data))
      .toPromise();

    await UserIndexedDbModel.query().clear();
    await UserIndexedDbModel.query().bulkAdd(users);
  }

  /**
   * Decodes and returns the current JWT token string as an object
   */
  private getTokenAsObject(): any {
    // Get current JWT token
    const jwtToken = this.auth.getToken();

    // Decode JWT token
    return jwtDecode(jwtToken);
  }
}
