import { PageBuilderWidgetType } from '../enums/page-builder-widget-type.enum';

export interface PageBuilderConfig {
  id?: string;
  key: string;
  type: string;
  comment: null;
  title?: string;
  permissions: string[];
  content: PageBuilderWidget[];
}

export interface PageBuilderWidget<CONFIGURATION_OPTIONS = Record<string, any>> {
  id?: string;
  type: string | PageBuilderWidgetType;
  options?: PageRendererWidgetOptions<CONFIGURATION_OPTIONS>;
  widgets?: PageBuilderWidget[];
  css_class?: string;
  container_id?: string;
  title?: string;
  name?: string;
}

export interface PageBuilderWidgetOptions<T = any> {
  context?: BuilderContext;
  key?: string;
  css_class: string;
  config_id?: string;
}

export type PageRendererWidgetOptions<T = any> = PageBuilderWidgetOptions & T;

export type BuilderContext = {
  [key: string]: any;
};
