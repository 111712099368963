import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { CtaButtonItem } from './interfaces/cta-button-item.interface';

@Component({
  selector: 'cap-cta-button',
  templateUrl: './cta-button.component.html',
  styleUrls: ['./cta-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('listAnimation', [
      transition('* => *', [ // each time the binding value changes
        query(':leave', [
          stagger(50, [
            animate('0.1s', style({
              opacity: 0,
              transform: 'translateY(100%)'
            }))
          ])
        ], { optional: true }),
        query(':enter', [
          style({ opacity: 0, transform: 'translateY(100%)' }),
          stagger(-50, [
            animate('0.1s', style({ opacity: 1, transform: 'translateY(0%)' }))
          ])
        ], { optional: true })
      ])
    ])
  ]
})
export class CtaButtonComponent {
  public showMenuItems = false;
  public ctaButtonIcon = 'fas fa-ellipsis-v';
  public displayedMenuItems: CtaButtonItem[] = [];

  private _menuItems: CtaButtonItem[] = [];

  get menuItems(): CtaButtonItem[] {
    return this._menuItems;
  }

  @Input()
  set menuItems(value: CtaButtonItem[]) {
    this._menuItems = value;

    if (value.length === 1) {
      this.ctaButtonIcon = value[0].icon;
    }
  }

  public handleCtaButtonClick(): void {
    if (this.menuItems.length > 1) {
      if (this.showMenuItems) {
        this.hideMenuItems();
      } else {
        this.displayMenuItems();
      }
    } else if (this.menuItems.length === 1) {
      this.menuItems[0].callback();
    }
  }

  public executeCallback(item: CtaButtonItem): void {
    item.callback();
    this.hideMenuItems();
  }

  private hideMenuItems(): void {
    this.showMenuItems = false;
    this.displayedMenuItems = [];
  }

  private displayMenuItems(): void {
    this.showMenuItems = true;
    this.displayedMenuItems = this.menuItems;
  }
}
