<div class="cap-file-preview-list">
  <div
    class="cap-file-preview-list__item"
    (click)="handleItemClick(file, index)"
    *ngFor="let file of formattedFileList; let index = index; trackBy: trackByIndex">
    <ng-container *ngIf="file.isImage; else defaultTemplate">
      <div class="cap-file-preview-list__preview-image" [style.background-image]="'url(' + file.file + ')'"></div>
    </ng-container>

    <div class="file-name">{{ file.name }}</div>

    <ng-template #defaultTemplate>
      <div class="cap-file-preview-list__preview-icon">
        <i [class]="file.icon"></i>
      </div>
    </ng-template>

    <div class="file-actions">
      <div class="download-file-icon" *ngIf="showDownload && !showDelete">
        <i class="fas fa-download" (click)="handleDownloadButtonClick($event, file)"></i>
      </div>

      <div class="remove-file-icon file-delete" *ngIf="showDelete && !showDownload">
        <i
          class="fas fa-trash-alt"
          (click)="handleDeleteButtonClick($event, file, index)"></i>
      </div>

      <div class="file-actions-menu" *ngIf="showDelete && showDownload">
        <p-overlayPanel #overlayPanel styleClass="cap-file-preview-action-panel">
          <ng-template pTemplate>
            <div class="cap-file-action-item file-download" (click)="handleDownloadButtonClick($event, file)">
              <i class="fas fa-download"></i>
              <span class="cap-file-action-label">Download</span>
            </div>
            
            <div
              class="cap-file-action-item file-delete"
              (click)="handleDeleteButtonClick($event, file, index)">
              <i class="fas fa-trash-alt"></i>
              <span class="cap-file-action-label">Delete</span>
            </div>
          </ng-template>
        </p-overlayPanel>

        <i class="fas fa-ellipsis-v" (click)="overlayPanel.toggle($event)"></i>
      </div>
    </div>

    <div class="loading-overlay" *ngIf="file.uploading">
      <p-progressBar [value]="file.uploadProgress" [showValue]="false"></p-progressBar>
    </div>
  </div>
</div>
