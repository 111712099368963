import { Injectable } from '@angular/core';
import { Store } from './store';
import { CompleteConfig } from '../../complete-config';

@Injectable()
export class IndexedDbService {
  public store: Store;

  constructor(private config: CompleteConfig) {
  }

  /**
   * Create the indexedDB store
   */
  public async createStore(id?: string): Promise<boolean> {
    this.store = this.getStore(id);
    await this.store.setup(this.config, id);

    return true;
  }

  /**
   * Get the store object
   *
   * @return Store
   */
  public getStore(id?: string): Store {
    return Store.getStore(this.config.databaseName, id);
  }

  /**
   * Reset the store
   */
  public async resetStore(id?: string): Promise<void> {
    await this.store.delete();
    await this.store.setup(this.config, id);
  }
}
