import { StorageProvider } from '../interfaces/storage-provider.interface';

export abstract class BaseStorageProvider implements StorageProvider {
  /**
   * The storage to use.
   * @protected
   */
  protected storage: Storage;

  /**
   * Get the item from the storage.
   * If the item has an expiration date, it will be checked and removed if it is expired.
   *
   * @param key - The key of the item to get.
   * @returns The item from the storage.
   */
  public getItem<T = any>(key: string): T | null {
    const now = new Date().getTime();
    const item = this.storage.getItem(key);

    if (item) {
      const parsedItem = JSON.parse(item);

      if (parsedItem?.expiresAt) {
        if (parsedItem?.expiresAt < now) {
          this.removeItem(key);

          return null;
        }

        return parsedItem?.value;
      }
    }

    return JSON.parse(this.storage.getItem(key));
  }

  /**
   * Set the item in the storage. If the expiration is provided, it will be set.
   * The expiration is in minutes.
   * If the expiration is not provided, the item will be set without expiration.
   *
   * @param key - The key of the item to set.
   * @param value - The value of the item to set.
   * @param expiration - The expiration of the item to set in minutes.
   */
  public setItem(key: string, value: unknown, expiration?: number): void {
    if (expiration) {
      const now = new Date().getTime();
      const expiresAt = now + expiration * 60 * 1000;

      this.storage.setItem(key, JSON.stringify({ value, expiresAt }));
    } else {
      this.storage.setItem(key, JSON.stringify(value));
    }
  }

  /**
   * Remove the item from the storage.
   * @param key
   */
  public removeItem(key: string): void {
    return this.storage.removeItem(key);
  }
}
