import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[capAutofocus]'
})
export class CapturumAutofocusDirective implements OnInit {
  @Input() public capAutofocus: boolean = false;

  constructor(private el: ElementRef) {
  }

  public ngOnInit(): void {
    if (this.capAutofocus) {
      this.el.nativeElement.focus();
    }
  }
}
