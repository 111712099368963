<cap-dynamic-filter-layout
  class="dynamic-multiselect-filter"
  [label]="filterConfig.showLabel ? filterConfig.label : ''"
  [iconClass]="filterConfig.icon"
  [showReset]="formControl.value?.length && (filterConfig.resetFilter ?? true)"
  (resetFilter)="reset()">
  <cap-multi-select
    [attr.data-test]="'filter-' + filterConfig.field"
    [filter]="true"
    [formControl]="formControl"
    [appendTo]="'body'"
    [sortBy]="'order'"
    [resetFilterOnHide]="filterConfig.resetFilterOnHide || false"
    [autofocusFilter]="filterConfig.autofocusFilter ?? true"
    [selectedItemsLabel]="filterConfig.selectedItemsLabel || '{0} items selected'"
    [maxSelectedLabels]="filterConfig.maxSelectedLabels ?? 1"
    [options]="filterConfig.options | observablePipe | async"
    [defaultLabel]="filterConfig.placeholder"
    [disabled]="filterConfig.disabled | observablePipe | async"
    [sortAlphabetically]="filterConfig.sortAlphabetically"
    (change)="updateValue.emit($event.value)">
  </cap-multi-select>
</cap-dynamic-filter-layout>
