import { AfterContentInit, Component, ContentChildren, Input, QueryList, TemplateRef } from '@angular/core';
import { CapturumTemplateDirective } from '@capturum/ui/api';

@Component({
  selector: 'cap-layout-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class CapturumLayoutDetailsComponent implements AfterContentInit {
  @Input() public title: string;

  public secondContentTemplate: TemplateRef<string>;
  public headlineTemplate: TemplateRef<string>;
  public firstContentTemplate: TemplateRef<string>;

  @ContentChildren(CapturumTemplateDirective)
  public templates: QueryList<any>;

  public isContentWithTwoColumns(): boolean {
    return !!this.firstContentTemplate && !!this.secondContentTemplate;
  }

  public ngAfterContentInit(): void {
    this.templates.forEach(item => {
      switch (item.getType()) {
        case 'first-content':
          this.firstContentTemplate = item.template;
          break;

        case 'second-content':
          this.secondContentTemplate = item.template;
          break;

        case 'header':
          this.headlineTemplate = item.template;
          break;
      }
    });
  }
}
