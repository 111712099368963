<cap-dynamic-filter-layout
  class="cap-dynamic-checkbox-filter"
  [iconClass]="filterConfig.icon"
  [showReset]="false"
  (resetFilter)="reset()">
  <div class="cap-checkbox-filter-label">
    {{ filterConfig?.label }}
  </div>

  <cap-checkbox
    [formControl]="formControl"
    [attr.data-test]="'filter-' + filterConfig.field"
    [disabled]="filterConfig.disabled | observablePipe | async"
    (change)="updateValue.emit($event.checked)">
  </cap-checkbox>
</cap-dynamic-filter-layout>
