import { Observable, Subject } from 'rxjs';
import { Page } from 'powerbi-client';
import { Injectable } from '@angular/core';
import { ReportActivePage } from '../interfaces/report-active-page.interface';

@Injectable({
  providedIn: 'root',
})
export class PowerBIService {
  public setPage$: Subject<ReportActivePage> = new Subject<ReportActivePage>();
  private _pages$: Subject<Page[]> = new Subject<Page[]>();

  public pages(): Observable<Page[]> {
    return this._pages$.asObservable();
  }

  public setActivePage(): Observable<ReportActivePage> {
    return this.setPage$.asObservable();
  }

  public setPages(pages: Page[]): void {
    this._pages$.next(pages);
  }
}
