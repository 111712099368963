import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { FormRendererApiService } from '../services/form-renderer-api.service';

@Injectable({ providedIn: 'root' })
export class FormBuilderResolver  {
  constructor(private readonly formRendererApiService: FormRendererApiService) {
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
   return this.formRendererApiService.resolveFormBuilders();
  }
}
