<div class="popup-content" *ngIf="flow$ | async as flow">
  <div class="flow-context-content" [innerHTML]="flow.context"></div>

  <ng-container *ngIf="flow.flow_ref">
    <div class="flow-context-content flow_ref">
      <div class="flow-context-content-header">{{ 'connector.action-statistics.flow.flow_ref.label' | translate }}</div>
      <i class="fas fa-link link" (click)="openFlowRef(flow)"></i>
    </div>

    <div class="flow-context-content history">
      <div class="flow-context-content-header">{{ 'connector.action-statistics.history.label' | translate }}</div>
      <i class="fas fa-link link" (click)="openHistory(flow)"></i>
    </div>
  </ng-container>

  <div class="flow-context-table" *ngIf="flowTable$ | async as flowTable">
    <cap-info-table
      [data]="flowTable.tableData"
      [columns]="flowTable.columns"
      [pagination]="false"
      (onColumnClick)="columnClick($event)">
    </cap-info-table>
  </div>
</div>
