import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CapturumDropdownComponent } from './dropdown.component';
import { DropdownModule } from 'primeng/dropdown';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CapturumSharedModule } from '@capturum/ui/api';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    CapturumDropdownComponent
  ],
  exports: [
    CapturumDropdownComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    DropdownModule,
    TranslateModule,
    CapturumSharedModule,
    OverlayPanelModule
  ]
})
export class CapturumDropdownModule {
}
