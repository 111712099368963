import { AfterViewInit, ChangeDetectorRef, Directive, ElementRef, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: '[capButtonLoading]',
})
export class CapturumButtonLoadingDirective implements AfterViewInit {
  @Input() set submitting(value: boolean) {
    if (value === this.isSubmitted) {
      return;
    }

    this.isSubmitted = value;
    this.cdr.detectChanges();
    this.addLoadingStuff();
  }

  get submitting(): boolean {
    return this.isSubmitted;
  }

  @Input() public loadingClass = 'cap-button--loading';
  @Input() public label: string;
  @Input() public keepLabel = true;

  private isSubmitted: boolean;
  private buttonElement: HTMLButtonElement;

  constructor(
    private readonly el: ElementRef,
    private readonly cdr: ChangeDetectorRef,
    private readonly translateService: TranslateService,
  ) {
  }

  public ngAfterViewInit(): void {
    this.buttonElement = (this.el.nativeElement.getElementsByClassName('p-button') as HTMLCollection)
      .item(0) as HTMLButtonElement;
  }

  private addLoadingStuff(): void {
    this.buttonElement = (this.el.nativeElement.getElementsByClassName('p-button') as HTMLCollection)
      .item(0) as HTMLButtonElement;
    
    if (this.buttonElement) {
      const method = this.isSubmitted ? 'add' : 'remove';
      const label = (this.isSubmitted && !this.keepLabel) ?
        this.translateService.instant('component.loading') : (this.label || '&nbsp;');

      this.buttonElement.classList[method](this.loadingClass);

      const span = (this.buttonElement.getElementsByClassName('p-button-label') as HTMLCollection)
        .item(0) as HTMLSpanElement;

      if (span) {
        span.innerText = label;
      }

      // Workaround because Primeng 14 doesn't create a span inside p-button if no label is set
      if (!this.label && !span) {
        const emptySpan = document.createElement('span');
        emptySpan.classList.add('p-button-label');
        emptySpan.innerHTML = '&nbsp;';
        this.buttonElement.appendChild(emptySpan);
      }
    }
  }
}
