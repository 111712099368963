<div [ngClass]="styleClass">
    <label [for]="id" class="prime-file-upload text-center" (dragleave)="onDragLeave($event)"
           [class.dragover]="dragover"
           (dragover)="onDragOver($event)" (drop)="onDrop($event)">

        <ng-container [ngTemplateOutlet]="uploaderContent || defaultUploaderContent"></ng-container>

        <ng-template #defaultUploaderContent>
            <i class="fas fa-upload"></i>

            <p>{{ label }}</p>
        </ng-template>
    </label>

    <input #inputRef
           type="file"
           [multiple]="multiple"
           [id]="id"
           class="d-none"
           [accept]="accept"
           (change)="onChange($event)" />
</div>
