<div class="builder-card">
  <div class="builder-card__title">
    <ng-container *ngIf="collapsable; else defaultTitle">
      <span (click)="toggleCollapse()">{{ title }}</span>
      <i class="fas collapse-toggle" [ngClass]="{'fa-chevron-down': isCollapsed, 'fa-chevron-up': !isCollapsed}" (click)="toggleCollapse()"></i>
    </ng-container>
  </div>

  <div class="builder-card__body" [hidden]="collapsable && isCollapsed">
    <ng-content></ng-content>
  </div>
</div>

<ng-template #defaultTitle>
  {{ title }}
</ng-template>
