export class ApiConfig {
  baseUrl = '';
  production = false;
  dateInterceptor?: {
    dateProperties: string[],
    blackListedURLs: string[],
    blackListedStatusCodes: number[],
    displayFormat: string,
    sendFormat: string
  } = {
    dateProperties: [],
    blackListedURLs: [],
    blackListedStatusCodes: [],
    displayFormat: 'YYYY-MM-DD HH:mm:ss',
    sendFormat: 'YYYY-MM-DDTHH:mm:ssZZ'
  };
  onAuthError = () => {
    if (!this.production) {
      console.warn(
        'onAuthError has to be set in order to redirect to login page'
      );
    }
  }
}
