<div class="content">
    <span class="timepicker-label">{{ timeLabel }}</span>
    <input [value]="hours"
           (blur)="setTime(timeUnits.hour, $event)"
           type="text"
           class="mr-2 p-inputtext"
           pKeyFilter="pint"
           maxlength="2" />:
    <input [value]="minutes"
           (blur)="setTime(timeUnits.minute, $event)"
           type="text"
           class="ml-2 p-inputtext"
           pKeyFilter="pint"
           maxlength="2" />
</div>
