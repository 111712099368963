import {
  AfterViewInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
  TemplateRef,
} from '@angular/core';
import { AccordionActionItem, AccordionConfig } from '../base/accordion-config.model';
import { AccordionActionVisibility } from '../base/accordion-action-visibility.enum';
import { CapturumTemplateDirective } from '@capturum/ui/api';

@Component({
  selector: 'cap-accordion-card',
  templateUrl: './accordion-card.component.html',
})
export class CapturumAccordionCardComponent implements AfterViewInit {
  @Input() public title: string;
  @Input() public icon: string;
  @Input() public clickable: boolean = false;
  @Input() public contentTemplate: TemplateRef<string>;
  @Input() public headerTemplate: TemplateRef<string>;
  @Input() public actions: AccordionActionItem[];
  @Input() public isOpen: boolean = false;
  @Input() public set config(value: AccordionConfig) {
    if (value) {
      this.defaultConfig = { ...this.defaultConfig, ...value };
    }
  }

  @Input() public hideToggle: boolean;
  @Input() public texts: { open: string, close: string } = { open: 'Show data', close: 'Hide data' }; // TODO unused
  @Input() public hideTexts: boolean;
  @Output() public actionClick: EventEmitter<string> = new EventEmitter<string>();
  @Output() public onToggle: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ContentChildren(CapturumTemplateDirective)
  public templates: QueryList<CapturumTemplateDirective>;
  public defaultConfig: AccordionConfig = {
    texts: {
      showText: 'Show data',
      hideText: 'Hide data',
    },
    openIcon: 'fas fa-chevron-up',
    closeIcon: 'fas fa-chevron-down'
  };

  public ngAfterViewInit(): void {
    this.templates.forEach((template) => {
      switch (template.getType()) {
        case 'header':
          this.headerTemplate = template.template;
          break;
        case 'content':
          this.contentTemplate = template.template;
          break;
      }
    });
  }

  public toggleCard(clickable: boolean): void {
    if (clickable) {
      this.isOpen = !this.isOpen;
      this.onToggle.emit(this.isOpen);
    }
  }

  public isVisibleAction(visible: AccordionActionVisibility): boolean {
    switch (visible) {
      case AccordionActionVisibility.always:
        return true;
      case AccordionActionVisibility.closed:
        return this.isOpen === false;
      case AccordionActionVisibility.opened:
        return this.isOpen === true;
    }
  }
}
