import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'cap-headline',
  templateUrl: './headline.component.html',
  styleUrls: ['./headline.component.scss']
})
export class CapturumHeadlineComponent {
  @Input() public contentTemplate: TemplateRef<string>;
  @Input() public title: string = 'Page Title';
}
