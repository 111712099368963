import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { ModuleService } from '../../../module';
import { map } from 'rxjs/operators';
import { UtilService } from '../../../../shared/services/util.service';
import { CompleteBaseForm } from '../../../../shared/components/complete-base-form';
import { noScript, noSpecialChars } from '@capturum/formly';


@Component({
  selector: 'emc-tenant-form',
  templateUrl: './tenant-form.component.html',
  styleUrls: ['./tenant-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TenantFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TenantFormComponent),
      multi: true,
    },
  ],
})
export class TenantFormComponent extends CompleteBaseForm implements AfterViewInit {
  public fields: FormlyFieldConfig[];

  constructor(
    private translateService: TranslateService,
    private utilService: UtilService,
    private changeDetectorRef: ChangeDetectorRef,
    private moduleService: ModuleService,
  ) {
    super(changeDetectorRef);
  }

  public ngAfterViewInit(): void {
    this.listenToFormChanges();

    this.fields = [
      {
        key: 'name',
        type: 'input',
        props: {
          translateLabel: this.translateService.stream('tenant.name'),
          required: true,
          minLength: 2,
          maxLength: 50, 
        },
        validators: {
          validation: [noSpecialChars, noScript],
        },
      },
      {
        key: 'modules',
        type: 'multiSelect',
        props: {
          options: this.moduleService.getModuleList().pipe(
            map((response) => this.utilService.mapToSelectItem(response, 'key', 'label')),
          ),
          filter: true,
          styleClass: 'w-100',
          defaultLabel: this.translateService.instant('placeholder.select'),
          translateLabel: this.translateService.stream('tenant.modules'),
          translatePlaceholder: this.translateService.stream('placeholder.select'),
          required: false,
          appendTo: 'body',
        },
      },
    ];

    this.updateFormValue();
    this.changeDetectorRef.detectChanges();
  }
}
