import { Observable } from 'rxjs';

export interface TableText {
  filtersLabel: string | Observable<string>;
  itemsSelectedMessage?: string | Observable<string>;
  resetSortingLabel: string | Observable<string>;
  descendingLabel: string | Observable<string>;
  ascendingLabel: string | Observable<string>;
  sortButtonLabel: string | Observable<string>;
  resetFilterButtonLabel?: string | Observable<string>;
  noResults: string | Observable<string>;
  footerPaginationText: string | Observable<string>;
  filtersEditHeader: string | Observable<string>;
  filtersEditCloseBtn: string | Observable<string>;
  filtersEditSelectItemsMessage: string | Observable<string>;
  applyFilterMessage?: string | Observable<string>;
}
