export interface FilePreviewListItem {
  name: string;
  url?: string;
  id?: string;
  uploading?: boolean;
  uploadProgress?: number;
}

export interface FormattedFilePreviewListItem {
  id?: string;
  file: string;
  icon?: string;
  name: string;
  isImage: boolean;
  uploading?: boolean;
  uploadProgress?: number;
}
