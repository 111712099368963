import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CapturumTemplateDirective } from '../directives/template.directive';
import { CapturumScrollDirective } from '../directives/scroll.directive';
import { CapturumHostViewDirective } from '../directives/host-view.directive';
import { CapturumKeyfilterDirective } from '../directives/keyfilter.directive';
import { CapturumAutofocusDirective } from '../directives/autofocus.directive';
import { ClickOutsideDirective } from '../directives/click-outside.directive';
import { ObservablePipe } from '../pipes/observable.pipe';
import { SplitPipe } from '../pipes/split.pipe';
import { InterpolationPipe } from '../pipes/interpolation.pipe';
import { CapturumValidatorComponent } from '../validator/validator.component';
import { TranslateModule } from '@ngx-translate/core';
import { DynamicDialogcontentDirective } from '../directives/dynamic-dialogcontent.directive';
import { CapturumColumnTemplatePipe } from '../pipes/column-template.pipe';
import { CapturumRowActionComponent } from '../components/row-action/row-action.component';
import { AllActionsIsHiddenPipe } from '../pipes/all-actions-is-hidden.pipe';
import { AssetsPipe } from '../pipes/assets.pipe';
import { DefaultPipe } from '../pipes/default.pipe';
import { GetOptionPipe } from '../pipes/get-option.pipe';
import { BaseDataValuePipe } from '../pipes/base-data-value.pipe';
import { CapturumButtonLoadingDirective } from '../directives/button-loading.directive';
import { CapturumDatePipe } from '../pipes/capturum-date.pipe';
import { CapturumPropertyValuePipe } from '../pipes/property-value.pipe';
import { ResolveStyleClassExpressionPipe } from '../pipes/resolve-style-class-expression.pipe';

const BASE_DIRECTIVES = [
  CapturumTemplateDirective,
  CapturumScrollDirective,
  CapturumKeyfilterDirective,
  CapturumHostViewDirective,
  CapturumAutofocusDirective,
  ClickOutsideDirective,
  DynamicDialogcontentDirective,
  CapturumButtonLoadingDirective,
];

const BASE_PIPES = [
  InterpolationPipe,
  SplitPipe,
  ObservablePipe,
  CapturumColumnTemplatePipe,
  AllActionsIsHiddenPipe,
  AssetsPipe,
  DefaultPipe,
  GetOptionPipe,
  BaseDataValuePipe,
  CapturumDatePipe,
  CapturumPropertyValuePipe,
  ResolveStyleClassExpressionPipe,
];

@NgModule({
  declarations: [
    CapturumValidatorComponent,
    CapturumRowActionComponent,
    ...BASE_PIPES,
    ...BASE_DIRECTIVES,
  ],
  imports: [
    CommonModule,
    TranslateModule,
  ],
  exports: [
    CapturumValidatorComponent,
    CapturumRowActionComponent,
    ...BASE_PIPES,
    ...BASE_DIRECTIVES,
  ],
  providers: [
    CapturumPropertyValuePipe,
  ],
})
export class CapturumSharedModule {
}
