<div class="form-element">
  <p-chips
    (onAdd)="add.emit($event)"
    (onBlur)="blur.emit($event)"
    (onChipClick)="chipClick.emit($event)"
    (onRemove)="remove.emit($event)"
    [(ngModel)]="value"
    [addOnBlur]="addOnBlur || false"
    [addOnTab]="addOnTab || true"
    [allowDuplicate]="allowDuplicate || true"
    [disabled]="disabled || false"
    [inputId]="inputId"
    [inputStyleClass]="inputStyleClass || null"
    [inputStyle]="inputStyle || null"
    [max]="max || null"
    [field]="field"
    [placeholder]="placeholder || null"
    [styleClass]="'cap-chips ' + styleClass"
    [style]="style || null"
    [tabindex]="tabindex || null">
  </p-chips>

  <cap-validator *ngIf="control" [control]="control" [customName]="label"></cap-validator>
</div>
