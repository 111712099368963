<div class="table-filters-settings">
    <header>
        <h2>{{ texts.filtersEditHeader | observablePipe | async }}</h2>
    </header>

    <div class="content">
        <h5>{{ texts.filtersEditSelectItemsMessage | observablePipe | async }}</h5>

        <div class="items">
            <div class="row mx-0">
                <div *ngFor="let item of items" class="col-6 col-xs-12 mb-3 pl-0">
                    <div class="item" [class.selected]="item.filterable">
                        <cap-checkbox labelStyleClass="item-label"
                                      [label]="item.filterPlaceholder | observablePipe | async"
                                      [(ngModel)]="item.filterable">
                        </cap-checkbox>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <footer>
        <div class="d-flex justify-content-center pt-3">
            <cap-button [label]="texts.filtersEditCloseBtn | observablePipe | async"
                        class="close-filters"
                        styleClass="secondary"
                        (click)="close()">
            </cap-button>
        </div>
    </footer>
</div>
