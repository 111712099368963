import { Pipe, PipeTransform } from '@angular/core';
import { InfoTableColumn } from '../base/info-table-column.model';

@Pipe({
  name: 'sortField'
})
export class SortFieldPipe implements PipeTransform {
  public transform(column: InfoTableColumn): string {
    return column?.sortable?.field || column?.field;
  }
}
