import { FilterMatchMode, MapItem } from '@capturum/ui/api';
import { InputType } from '../enums/input-type.enum';

export interface ListRendererFilterConfig {
  id?: string;
  global_search: ListRendererGlobalSearchConfig;
  resettable?: boolean;
  reset_filter_icon?: string;
  reset_button: ListRendererFilterButton;
  refresh_button: ListRendererFilterButton;
  fields: ListRendererFilterConfigField[];
  remember_filter?: boolean;
  show_filters?: boolean;
}

export interface ListRendererGlobalSearchConfig {
  enabled?: boolean;
  resettable?: boolean;
  reset_filter_icon?: string;
}

export interface ListRendererFilterConfigField {
  id?: string;
  name: string;
  label?: string;
  placeholder?: string;
  icon?: string;
  type?: InputType;
  operator?: FilterMatchMode;
  options?: ListRendererFilterOptions;
  autoDisplayFirst?: boolean;
  default?: any;
  dependencies?: string[];
  prevent_disable_dependency_field?: boolean;
}

export interface ListRendererFilterOptions {
  search_key?: string;
  action?: {
    endpoint?: string;
    options?: MapItem[];
  };
  timezone?: boolean;
  items?: MapItem[];
  sortAlphabetically?: boolean;
  autofocusFilter?: boolean;
  resetFilterOnHide?: boolean;
  selectionLimit?: number;
}

export interface ListRendererFilterButton {
  title: string;
  icon: string;
}
