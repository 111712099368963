import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@capturum/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService  {
  constructor(
    public router: Router,
    private authService: AuthService,
  ) {
  }

  public canActivate(): boolean {
    if (!localStorage.getItem('token')) {
      this.router.navigate(['auth', 'login']);

      return false;
    }

    this.checkUseRefreshToken();

    return true;
  }

  public canLoad(): boolean {
    if (!localStorage.getItem('token')) {
      this.router.navigate(['auth', 'login']);

      return false;
    }

    this.checkUseRefreshToken();

    return true;
  }

  private checkUseRefreshToken(): void {
    if (this.authService.authRefreshToken.useRefreshToken) {
      this.authService.watchRefreshToken();
    }
  }
}
