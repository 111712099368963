import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilderConfig, PageBuilderConfig, PageBuilderWidget, PageBuilderWidgetType, responseData } from '@capturum/builders/core';
import { FormRendererService, FormSaverService, FormRendererApiService } from '@capturum/builders/form-renderer';
import { BuilderUiConfigService } from '@capturum/builders/ui';
import { MapItem, ToastService } from '@capturum/ui/api';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable, of, switchMap, take, tap } from 'rxjs';

import { nestedDnDPatch } from '../../utils/nested-drag-drop-patch';
import { moveItemInNestedArray, removeItemInNestedArray } from '../../utils/page-builder.utils';
import { WidgetTypeApiService } from './../../services/widget-type-api.service';

@Component({
  selector: 'cpb-page-builder',
  templateUrl: './page-builder.component.html',
  styleUrls: ['./page-builder.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CapturumPageBuilderComponent implements OnInit {
  public configId: string;
  public showSidebar: boolean;
  public pageConfigKey = 'form_edit_page';
  public pageFormConfiguration$: Observable<FormBuilderConfig>;
  public modelId: string;
  public widgetTypeList$: Observable<MapItem[]>;
  public config: PageBuilderConfig;
  public widgetConfiguration$: Observable<FormBuilderConfig>;
  public activeWidget: PageBuilderWidget & { widgetIndex: number };
  public rootDropListId = 'widget-content-list';
  public widgetOptionsListId = 'widget-options-list';

  constructor(
    private formRendererApiService: FormRendererApiService,
    private route: ActivatedRoute,
    private router: Router,
    private widgetTypeApiService: WidgetTypeApiService,
    private cdr: ChangeDetectorRef,
    private formSaverService: FormSaverService,
    private formRendererService: FormRendererService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private builderUiConfigService: BuilderUiConfigService,
  ) {
    // this patch is needed due to limitation of cdk DnD nested list, https://github.com/angular/components/issues/16671
    nestedDnDPatch();
  }

  public ngOnInit():void {
    this.configId = this.route.snapshot.paramMap.get('id');
    this.pageFormConfiguration$ = this.formRendererApiService.getFormBuilderByKey(this.pageConfigKey);
    this.widgetTypeList$ = this.widgetTypeApiService.listWidgetTypes();
  }

  public handleConfigChange(config: PageBuilderConfig): void {
    this.config = { content: [], ...this.config, ...config };
  }

  public submitWidgetConfiguration(event: { value: any, formConfig: FormBuilderConfig }): void {
    const url = event.formConfig.submit.endpoint
      .replace('{configId}', this.configId)
      .replace('{type}', this.activeWidget.type as string)
      .replace('{widgetIndex}', this.activeWidget.widgetIndex?.toString() || null);

    if (this.activeWidget.container_id) {
      event.value.container_id = this.activeWidget.container_id;
    }

    this.formSaverService.submitToBackend(url, event.value, this.pageConfigKey).pipe(responseData).subscribe((response) => {
      if (response) {
        this.config = response;

        this.builderUiConfigService.setConfig(response);
        this.showSuccessToast();

        this.showSidebar = false;

        this.cdr.detectChanges();

        this.configId = response.id;
        this.router.navigate([`../${response.id}`], { relativeTo: this.route });
      }
    });
  }

  public cancelConfiguration(): void {
    this.activeWidget = null;
    this.showSidebar = false;
  }

  public widgetDropped(event: CdkDragDrop<string | PageBuilderWidget>): void {
    let containerId: string;

    // Drop new widget from widget options list
    if (event.previousContainer.id === this.widgetOptionsListId) {
      containerId = event.container.id !== this.rootDropListId ? event.container.id : null;

      if (event.previousContainer.id !== event.container.id) {
        this.setActiveWidget(event.currentIndex, null, containerId, event.item.data);
      }
    } else { // drop widget from content
      containerId = event.item.data.container_id;

      moveItemInNestedArray(this.config.content, event.item.data.id, event.container.id, event.previousIndex, event.currentIndex);
    }

    this.cdr.detectChanges();
  }

  public setActiveWidget(widgetIndex: number, widgetId: string, containerId: string, type: PageBuilderWidgetType): void {
    this.widgetConfiguration$ = this.widgetTypeApiService.getWidgetTypeFormConfiguration(type).pipe(
      map(widgetConfig => {
        return {
          ...widgetConfig,
          source: { ...widgetConfig.source, endpoint: widgetConfig.source.endpoint.replace('{{configId}}', this.configId) },
        }
      }),
      tap((data: FormBuilderConfig) => {
        if (data) {
          this.activeWidget = {
            type,
            widgetIndex,
            container_id: containerId,
            id: widgetId,
          };

          this.showSidebar = true;
          this.cdr.detectChanges();
        }
      }),
    );
  }

  public submitConfig(): void {
    this.formRendererService.setIsSubmitted('form_edit_page');
    this.formRendererService.getFormConfigurationByKey('form_edit_page').pipe(
      take(1),
      switchMap((configuration) => {
        if (configuration && configuration.submit) {
          const config = {
            id: this.configId,
            ...this.config,
          };

          return this.formSaverService.submitToBackend(configuration.submit.endpoint, config, configuration.key);
        }

        return of(null);
      }),
      responseData,
    ).subscribe((response) => {
      if (response) {
        this.builderUiConfigService.setConfig(response);
        this.showSuccessToast();

        this.config = response;
        this.cdr.detectChanges();

        this.configId = response.id;
        this.router.navigate([`../${response.id}`], { relativeTo: this.route });
      }
    });
  }

  public handleRemoveWidget(widget: PageBuilderWidget): void {
    removeItemInNestedArray<PageBuilderWidget>(this.config.content, widget.id);

    this.submitConfig();
  }

  public handleEditWidget(event: { widget: PageBuilderWidget, index: number}): void {
    this.setActiveWidget(event.index, event.widget.id, event.widget.container_id, event.widget.type as PageBuilderWidgetType);
  }

  public cancelWidgetConfiguration(): void {
    this.activeWidget = null;
    this.showSidebar = false;

    this.cdr.detectChanges();
  }

  private showSuccessToast(): void {
    this.toastService.success(
      this.translateService.instant('toast.success'),
      this.translateService.instant('text.is-saved', { entity: 'Config' }),
    );
  }
}
