import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'cpb-builder-card',
  templateUrl: './builder-card.component.html',
  styleUrls: ['./builder-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BuilderCardComponent {
  @Input()
  public title: string;
  @Input()
  public collapsable: boolean;
  @Input()
  public isCollapsed: boolean = true;

  public toggleCollapse(): void {
    this.isCollapsed = !this.isCollapsed;
  }
}
