<div *ngIf="config$ | async as config; else loaderSkeleton" class="json-wrap">
  <div class="action-buttons">
    <cap-button class="mr-2" styleClass="tertiary" [icon]="'fas fa-copy'" (onClick)="copyConfig(config)"></cap-button>
    <cap-button styleClass="tertiary" [icon]="'fas fa-download'" (onClick)="downloadConfig(config)"></cap-button>
  </div>

  <pre>{{ config | json }}</pre>
</div>

<ng-template #loaderSkeleton>
  <cap-skeleton></cap-skeleton>
  <cap-skeleton></cap-skeleton>
  <cap-skeleton></cap-skeleton>
  <cap-skeleton></cap-skeleton>
  <cap-skeleton></cap-skeleton>
</ng-template>
