import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from './table.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { TranslateModule } from '@ngx-translate/core';
import { CapturumInputModule } from '@capturum/ui/input';
import { CapturumDropdownModule } from '@capturum/ui/dropdown';
import { RangeFilterComponent } from './components/range-filter/range-filter.component';
import { TableFiltersComponent } from './components/table-filters/table-filters.component';
import { CapturumMultiSelectModule } from '@capturum/ui/multi-select';
import { CapturumCalendarModule } from '@capturum/ui/calendar';
import { TableSortComponent } from './components/table-sort/table-sort.component';
import { CapturumButtonModule } from '@capturum/ui/button';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CapturumSharedModule } from '@capturum/ui/api';
import { TableFiltersSettingsComponent } from './components/table-filters-settings/table-filters-settings.component';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { CapturumCheckboxModule } from '@capturum/ui/checkbox';
import { TrStyleClassPipe } from './pipes/tr-style-class.pipe';
import { TableRowComponent } from './components/table-row/table-row.component';
import { TableFormRowComponent } from './components/table-form-row/table-form-row.component';
import { DropdownModule } from 'primeng/dropdown';
import { TooltipModule } from 'primeng/tooltip';
import { MultiSelectModule } from 'primeng/multiselect';
import { CalendarModule } from 'primeng/calendar';
import { SliderModule } from 'primeng/slider';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { NestedColumnValuePipe } from './pipes/nested-column-value.pipe';
import { IsFrozenPipe } from './pipes/isFrozen.pipe';
import { CapturumInputMaskModule } from '@capturum/ui/input-mask';

@NgModule({
    declarations: [
        TableComponent,
        RangeFilterComponent,
        TableFiltersComponent,
        TableSortComponent,
        TableFiltersSettingsComponent,
        TrStyleClassPipe,
        NestedColumnValuePipe,
        TableRowComponent,
        TableFormRowComponent,
        IsFrozenPipe,
    ],
  imports: [
    CommonModule,
    CapturumSharedModule,
    DragDropModule,
    TooltipModule,
    CapturumInputModule,
    TableModule,
    DropdownModule,
    MultiSelectModule,
    CalendarModule,
    FormsModule,
    SliderModule,
    CapturumDropdownModule,
    OverlayPanelModule,
    ReactiveFormsModule,
    TranslateModule,
    CapturumMultiSelectModule,
    CapturumCalendarModule,
    CapturumButtonModule,
    RadioButtonModule,
    DynamicDialogModule,
    CapturumCheckboxModule,
    CapturumInputMaskModule,
  ],
  exports: [
    TableComponent,
    TrStyleClassPipe,
    CapturumSharedModule,
    NestedColumnValuePipe,
    RangeFilterComponent,
  ],
    providers: [DialogService]
})
export class CapturumTableModule {
}
