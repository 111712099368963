import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { BaseDataService } from '../services/base-data.service';

@Injectable()
export class BaseDataResolver  {
  constructor(private baseDataService: BaseDataService) {
  }

  /**
   * Make sure the base-data is loaded before accessing the route
   */
  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.baseDataService.loadBaseData();
  }
}
