import { TwoFactorAuthProvider } from './two-factor-auth.provider';
import { Type, InjectionToken } from '@angular/core';
import { AuthProvider } from './auth.provider';
import { UserIdleConfig } from './configs/user-idle.config';

export const AUTH_USE_REFRESH_TOKENS = new InjectionToken<AuthRefreshTokenConfig>('use auth refresh token');

export interface AuthRefreshTokenConfig {
  useRefreshToken: boolean;
  offsetSeconds: number;
  intervalSeconds: number;
}

export interface AuthConfigInterface {
  baseUrl: string;
  production: boolean;
  authRejectionRoute: string;
  unauthenticatedRejectionRoute: string;
  authService?: Type<AuthProvider>;
  twoFAService?: Type<TwoFactorAuthProvider>;
  authRefreshToken?: AuthRefreshTokenConfig;
  userIdleConfig?: UserIdleConfig;
}

export class AuthConfig implements AuthConfigInterface {
  baseUrl = '';
  production = false;
  authRejectionRoute = '/login';
  unauthenticatedRejectionRoute = '/';
  authService?: Type<AuthProvider>;
  twoFAService?: Type<TwoFactorAuthProvider>;
  authRefreshToken: AuthRefreshTokenConfig = {
    useRefreshToken: false,
    offsetSeconds: 60,
    intervalSeconds: 3,
  };
  userIdleConfig?: UserIdleConfig;
}
