import { NgModule } from '@angular/core';
import { CapturumCheckboxComponent } from './checkbox.component';
import { CheckboxModule } from 'primeng/checkbox';
import { CapturumSharedModule } from '@capturum/ui/api';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    CapturumCheckboxComponent
  ],
  exports: [
    CapturumCheckboxComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    CapturumSharedModule,
    CheckboxModule,
  ]
})
export class CapturumCheckboxModule {
}
