import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TwoFactorAuthService, TwoFactorMethod } from '@capturum/auth';

@Component({
  selector: 'emc-auth-email',
  templateUrl: './auth-email.component.html',
})
export class AuthEmailComponent implements OnInit {
  @Output()
  public emailCodeEvent: EventEmitter<boolean> = new EventEmitter();

  @Input()
  public method: TwoFactorMethod = TwoFactorMethod.email;

  @Input()
  public paragraphNumbers: Record<string, number>;

  public emailForm: FormGroup;
  public emailCode: boolean;
  public emailSent: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private authService: TwoFactorAuthService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  public ngOnInit(): void {
    this.emailForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
    });

    this.authService.setupEmail().subscribe(({ identifier }) => {
      this.emailForm.setValue({ email: identifier });
      this.cdr.markForCheck();
    });
  }

  public sendEmail(): void {
    this.authService.register('email', this.emailForm.value.email).subscribe(() => {
      this.emailSent = true;
      this.emailCodeEvent.emit(true);
    });
  }
}
