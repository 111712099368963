import { Pipe, PipeTransform } from '@angular/core';
import { CapturumPropertyValuePipe } from './property-value.pipe';

@Pipe({
  name: 'interpolation'
})
export class InterpolationPipe implements PipeTransform {

  constructor(private propertyValuePipe: CapturumPropertyValuePipe) {
  }

  public giveWords(text: string): string[] {
    const regExp = /\{\{(.+?)\}\}/ig;

    return text.match(regExp);
  }

  public giveWord(text: string): string {
    const regExp = /\{{(.*)\}}/i;

    return text.match(regExp)[1];
  }

  public transform(text: string = '', messages: any): string {
    if (!text) {
      return;
    }

    const bracketValues = this.giveWords(text);

    if (!bracketValues) {
      return text;
    }

    bracketValues.forEach(item => {
      const clearWord = this.giveWord(item);

      if (!messages[clearWord]) {
        messages[clearWord] = '';
      }

      text = text.replace(item, this.propertyValuePipe.transform(messages, clearWord));
    });

    return `${text}`;
  }
}
