import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { responseData, toMapItems } from '../converter.utils';
import { MapItem } from '../map-item.interface';
import { UserModel } from '../user-model.interface';
import { catchError, map, tap } from 'rxjs/operators';
import { TwoFactorMethod } from '../two-factor-method.enum';
import { AuthConfig } from '../auth.config';
import { ApiListResult } from '../api-list-result.interface';

@Injectable({
  providedIn: 'root',
})
export class TwoFactorAuthService {
  protected endpoint = 'auth';

  constructor(
    protected config: AuthConfig,
    protected http: HttpClient,
    protected sanitizer: DomSanitizer,
    protected authService: AuthService,
  ) {
    this.endpoint = `${this.config.baseUrl}/auth`;
  }

  public handleSuccessfulLogin(result: { status: string, token: string, user: UserModel }): void {
    if (result.status === 'ok') {
      if (result.user) {
        localStorage.setItem('user', JSON.stringify(result.user));
      }

      localStorage.setItem('token', result.token);

      this.authService.setAuthenticationState(true);
    }
  }

  public list2faMethods(): Observable<MapItem[]> {
    return this.http
      .get<ApiListResult>(`${this.endpoint}/2fa/methods`, {
        headers: this.authService.getHeaders(),
      })
      .pipe(toMapItems);
  }

  public login(postData: { user_id: string, method: string, key: string }): Observable<{ status: string, token: string, user: UserModel }> {
    return this.http
      .post<{ status: string; token: string; user: UserModel & { roles: any } }>(`${this.endpoint}/login2fa`, postData, {
        headers: this.authService.getHeaders(),
      })
      .pipe(
        map(
          (result: { status: string; token: string; user: UserModel & { roles: any } }) => {
            if (result.status === 'ok' && result.user) {
              if (result.user.roles !== undefined && result.user.roles.data !== undefined) {
                result.user.roles = result.user.roles.data;
              }

              return result;
            }

            return null;
          },
          catchError((error) => throwError(error))
        ),
        tap((result) => this.handleSuccessfulLogin(result))
      );
  }

  public getOneTimePasswordQrCode(): Observable<{ qrCode: SafeResourceUrl, secret: string }> {
    return this.http.get<{ data: { qrCode: string, secret: string } }>(
      `${this.endpoint}/2fa/setup/google`,
      { headers: this.authService.getHeaders() },
    ).pipe(
      map((response) => ({
        ...response.data,
        qrCode: this.sanitizer.bypassSecurityTrustResourceUrl(response.data.qrCode),
      })),
    );
  }

  public confirm2fa(method: string, key: string): Observable<{ success: boolean }> {
    return this.http
      .post<{ data: any }>(
        `${this.endpoint}/2fa/confirm`,
        { method, key },
        { headers: this.authService.getHeaders() }
      )
      .pipe(responseData);
  }

  public setupEmail(): Observable<{ identifier: string }> {
    return this.http
      .get<{ data: any }>(`${this.endpoint}/2fa/setup/email`, { headers: this.authService.getHeaders() })
      .pipe(responseData);
  }

  public register(method: string, identifier: string): Observable<{ success: boolean }> {
    return this.http
      .post<{ data: any }>(
        `${this.endpoint}/2fa/register`,
        { method, identifier },
        { headers: this.authService.getHeaders() }
      )
      .pipe(responseData);
  }

  public setupSms(): Observable<{ identifier: string }> {
    return this.http
      .get<{ data: any }>(`${this.endpoint}/2fa/setup/sms`, { headers: this.authService.getHeaders() })
      .pipe(responseData);
  }

  public send2faResetMail(user: { id: string, name: string }): Observable<any> {
    return this.http
      .post<{ data: any }>(
        `${this.endpoint}/2fa/send-disable-email`,
        { user_id: user.id },
        {
          headers: this.authService.getHeaders(),
        }
      )
      .pipe(responseData);
  }

  public disable2fa(token: string): Observable<{ success: boolean }> {
    return this.http.post <{ data: { success: boolean } }>(
      `${this.endpoint}/2fa/disable?token=${token}`,
      { token },
      { headers: this.authService.getHeaders() },
    ).pipe(
      responseData,
    );
  }

  public requestDisable(): Observable<{ success: boolean }> {
    return this.http.get<{ data: { success: boolean } }>(
      `${this.endpoint}/2fa/request-disable`,
      { headers: this.authService.getHeaders() },
    ).pipe(
      responseData,
    );
  }

  public confirmDisable(method: TwoFactorMethod, key: string): Observable<{ success: boolean }> {
    return this.http.post<{ data: { success: boolean } }>(
      `${this.endpoint}/2fa/confirm-disable`,
      { method, key },
      { headers: this.authService.getHeaders() },
    ).pipe(
      responseData,
    );
  }
}
