<cap-dynamic-filter-layout
  class="dynamic-dropdown-filter"
  [iconClass]="filterConfig.icon"
  [label]="showLabel ? filterConfig.label : null"
  [showReset]="formControl.value && (filterConfig.resetFilter ?? true)"
  (resetFilter)="reset()">
  <cap-dropdown
    [filter]="true"
    [attr.data-test]="'filter-' + filterConfig.field"
    [formControl]="formControl"
    [autoDisplayFirst]="false"
    [autofocusFilter]="filterConfig.autofocusFilter ?? true"
    [resetFilterOnHide]="filterConfig.resetFilterOnHide || false"
    [disabled]="filterConfig.disabled | observablePipe | async"
    (changeSelection)="changeSelection($event.value)"
    [options]="filterConfig.options | observablePipe | async"
    [sortAlphabetically]="false"
    [placeholder]="filterConfig.placeholder">
  </cap-dropdown>
</cap-dynamic-filter-layout>
