<cap-dynamic-filter-layout
  class="dynamic-dropdown-filter"
  [label]="filterConfig.showLabel ? filterConfig.label : ''"
  [iconClass]="filterConfig.icon"
  [showReset]="formControl.value && (filterConfig.resetFilter ?? true)"
  (resetFilter)="reset()">
  <cap-dropdown
    [filter]="true"
    [attr.data-test]="'filter-' + filterConfig.field"
    [formControl]="formControl"
    [autofocusFilter]="filterConfig.autofocusFilter ?? true"
    [resetFilterOnHide]="filterConfig.resetFilterOnHide || false"
    [disabled]="filterConfig.disabled | observablePipe | async"
    (change)="updateValue.emit($event.value)"
    [options]="filterConfig.options | observablePipe | async"
    [sortAlphabetically]="filterConfig.sortAlphabetically"
    [placeholder]="filterConfig.placeholder">
  </cap-dropdown>
</cap-dynamic-filter-layout>
