import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { InputTypeBuilder } from '../input/input-type.builder';
import { FormInputConfig } from '../../interfaces/form-input-config';
import { TextareaInputConfiguration } from '../../models/textarea-input-configuration.model';
import { InputType } from '@capturum/builders/core';

@Injectable({ providedIn: 'root' })
export class TextareaInputTypeBuilder extends InputTypeBuilder implements FormInputConfig<TextareaInputConfiguration> {
  protected inputType: string = InputType.textarea;

  protected appendOptions(input: FormlyFieldConfig, configuration: TextareaInputConfiguration): void {
    if (input?.props && configuration?.options) {
      input.props.cols = configuration.options.cols;
      input.props.rows = configuration.options.rows;
    }
  }
}
