import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { CapturumButtonModule } from '@capturum/ui/button';
import { ToastService } from '@capturum/ui/api';
import { ApiHttpService } from '@capturum/api';
import { Observable, map } from 'rxjs';
import { CapturumSkeletonModule } from '@capturum/ui/skeleton';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilderConfig } from '../../interfaces/form-builder-configuration.interface';

@Component({
  selector: 'cpb-configuration-export-dialog',
  templateUrl: './configuration-export-dialog.component.html',
  styleUrls: ['./configuration-export-dialog.component.scss'],
  standalone: true,
  imports: [CommonModule, CapturumButtonModule, CapturumSkeletonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ConfigurationExportDialogComponent implements OnInit {
  public config$: Observable<FormBuilderConfig>;

  constructor(
    private readonly dynamicDialogConfig: DynamicDialogConfig,
    private readonly apiHttpService: ApiHttpService,
    private readonly toastService: ToastService,
    private readonly translateService: TranslateService
  ) {}

  public ngOnInit(): void {
    const endpoint = this.dynamicDialogConfig.data.endpoint.replace(
      '{{configId}}',
      this.dynamicDialogConfig.data.item.id
    );

    this.config$ = this.apiHttpService.get<{ data: FormBuilderConfig }>(endpoint).pipe(map(({ data }) => data));
  }

  public copyConfig(config: FormBuilderConfig): void {
    navigator.clipboard.writeText(JSON.stringify(config));

    const title = this.translateService.instant('general.success');
    const message = this.translateService.instant('config.export.successfully_copied');
    this.toastService.success(title, message);
  }

  public downloadConfig(config: FormBuilderConfig): void {
    const fileName = `Config ${this.dynamicDialogConfig.data.item.type}_${this.dynamicDialogConfig.data.item.key}`;
    const element = document.createElement('a');

    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(JSON.stringify(config)));
    element.setAttribute('download', fileName);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }
}
