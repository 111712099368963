import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'cpb-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss']
})
export class FilePreviewComponent {
  @Input() public styleClass: string = 'col-3';
  @Input() public items: any[];
  @Input() public deletable: boolean = false;
  @Input() public nameProperty: string = 'name';
  @Input() public imagePathProperty: string;
  @Input() public showThumbnail: boolean = true;
  @Output() public removeClick: EventEmitter<string> = new EventEmitter();
  @Output() public cardClick: EventEmitter<string> = new EventEmitter();

  public handleRemoveIconClick(event: Event, filename: string): void {
    event.stopPropagation();
    event.stopImmediatePropagation();

    this.removeClick.next(filename);
  }
}
