import { IndexedDbModel } from './indexedDb.model';

export class FileIndexedDbModel extends IndexedDbModel {
  public entity = 'file';
  public remote_id: number;
  public schema = 'id, _deleted, _changed';
  public table = 'files';

  public id: string;
  public data: string | ArrayBuffer;
  public relatedTable: string;
  public relatedId: string;
  public mime_type: string;
  public filename: string;

  public _deleted: number;
  public _changed: number;

  constructor(attributes: any) {
    super(attributes);
  }

  public getData(): any {
    return {
      id: this.id,
      data: this.data,
      relatedId: this.relatedId,
      relatedTable: this.relatedTable,
      mime_type: this.mime_type,
      filename: this.filename,
      _deleted: this._deleted || 0,
      _changed: this._changed || 0,
    };
  }
}
