import { Component, Input } from '@angular/core';
import { TableAction } from '@capturum/ui/api';

@Component({
  selector: 'cpb-list-renderer-header-actions',
  templateUrl: './list-renderer-header-actions.component.html',
  styleUrls: ['./list-renderer-header-actions.component.scss'],
})
export class ListRendererHeaderActionsComponent {
  @Input()
  public actions: TableAction[] = [];
}
