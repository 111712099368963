import { Injectable } from '@angular/core';
import { ApiHttpService, ApiService } from '@capturum/api';
import { Tenant } from '..';

@Injectable()
export class TenantService extends ApiService<Tenant> {
  protected endpoint = 'tenant';

  constructor(public httpService: ApiHttpService) {
    super(httpService);
  }
}
