import { Injectable } from '@angular/core';
import { ListRendererModuleConfig } from '../interfaces/list-renderer-module-config';

@Injectable({
  providedIn: 'root',
})
export class ListRendererConfigService {
  public config: ListRendererModuleConfig = {};

  public addConfig(config: ListRendererModuleConfig): void {
    if (config) {
      Object.keys(config).filter((key) => {
        return key.startsWith('list_');
      }).forEach((key) => {
        this.config[key] = config[key];
      });
  
      if (config.widgets) {
        this.config.widgets = {
          ...this.config.widgets,
          ...config.widgets,
        };
      }
  
      if (config.filters) {
        this.config.filters = Array.from(new Set([...(this.config.filters || []), ...config.filters]));
      }
    }
  }
}
