import { IdleUserService } from './services/idle-user.service';
import { TwoFactorAuthService } from './services/two-factor-auth.service';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthConfig, AUTH_USE_REFRESH_TOKENS } from './auth.config';
import { AuthGuard } from './guards/auth.guard';
import { AuthService } from './services/auth.service';
import { IfAuthenticatedDirective } from './directives/if-authenticated.directive';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { UserIdleConfig } from './configs/user-idle.config';

@NgModule({
  declarations: [
    IfAuthenticatedDirective,
  ],
  imports: [
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    IfAuthenticatedDirective,
  ],
})
export class AuthModule {
  static forRoot(config: Partial<AuthConfig>): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        AuthGuard,
        AuthService,
        IdleUserService,
        {
          provide: AuthConfig,
          useValue: config
        },
        {
          provide: AuthService,
          useClass: config.authService || AuthService,
        },
        {
          provide: TwoFactorAuthService,
          useClass: config.twoFAService || TwoFactorAuthService,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true,
        },
        {
          provide: UserIdleConfig,
          useValue: config.userIdleConfig,
        },
        {
          provide: AUTH_USE_REFRESH_TOKENS,
          useValue: config.authRefreshToken || new AuthConfig().authRefreshToken,
        },
      ],
    };
  }
}
