import {
  Component,
  Input,
} from '@angular/core';
import { FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { MessagesResponse, ValidationMessages, ValidatorService } from './validator.service';

@Component({
  selector: 'cap-validator',
  templateUrl: './validator.component.html',
  styleUrls: ['./validator.component.scss']
})
export class CapturumValidatorComponent {
  @Input() public control: FormControl;
  @Input() public customName: string;
  @Input() public customValidation: { name: string; text: string };
  @Input() public isMat: boolean = false;

  private messages: ValidationMessages;

  constructor(private validatorService: ValidatorService) {
    this.validatorService.messages$.subscribe((response: MessagesResponse) => (this.messages = response.messages));
  }

  public get containerClassName(): string {
    return this.isMat ? 'mat-validator' : 'validator';
  }

  /**
   * Check if the validator has a custom validation message
   *
   * @param errors
   *
   * @return string
   */
  public message(errors: ValidationErrors | null): string {
    const properties = Object.keys(errors);
    let customMessage: boolean;

    if (this.customValidation) {
      customMessage = properties.indexOf(this.customValidation.name) > -1;
    }

    return customMessage ? this.customValidation.text : this.messages[properties[0]];
  }

  /**
   * Extract control name from formGroup
   *
   * @return string
   */
  public getControlName(): string {
    let controlName = '';
    const parent = this.control ? this.control['parent'] : '';

    if (parent instanceof FormGroup) {
      for (const name in parent.controls) {
        if (this.control === parent.controls[name]) {
          controlName = name;
        }
      }
    }

    return controlName;
  }

  public getInterpolationDataByError(errors: ValidationErrors | null): Object {
    const properties = Object.keys(errors);
    const name = this.customName ? this.customName : this.convertToKebab(this.getControlName());

    switch (properties[0]) {
      case 'min':
        return { field: name, min: errors.min.min };
      case 'max':
        return { field: name, max: errors.max.max };
      case 'minlength':
        return { field: name, length: errors.minlength.requiredLength };
      case 'maxlength':
        return { field: name, length: errors.maxlength.requiredLength };
      default:
        return { field: name };
    }
  }

  /**
   * Convert string to kebab
   *
   * @param name
   *
   * @return string
   */
  private convertToKebab(name: string): string {
    return name
      .replace(/([a-z])([A-Z])/g, '$1-$2')
      .replace(/[\s_]+/g, '-')
      .toLowerCase();
  }
}
