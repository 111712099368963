import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-traffic-light-form-field',
  templateUrl: './traffic-light-form-field.component.html',
  styleUrls: ['./traffic-light-form-field.component.scss']
})
export class TrafficLightFormFieldComponent extends FieldType {
  public trafficLightOptions = ['red', 'orange', 'green'];

  public setOption(option: string): void {
    this.formControl.setValue(option);
  }
}
