import { IndexedDbModel } from '../../shared/indexedDb/indexedDb.model';

export class ModuleTenantIndexedDbModel extends IndexedDbModel {
  public static moduleTenants: ModuleTenantIndexedDbModel[] = [];

  /**
   * Load current modules in static cache
   */
  public static async loadModuleTenants(): Promise<void> {
    ModuleTenantIndexedDbModel.moduleTenants = await ModuleTenantIndexedDbModel.query().toArray();
  }

  // Configuration
  public entity = 'module-tenant';
  public table = 'module_tenant';
  public schema = 'id';
  public sync = false;

  // Fields
  public id: string;
  public name: string;
  public available_offline: boolean;
  public translation_key: string;
  public menu_index: number;

  // Constructor
  constructor(attributes: Partial<ModuleTenantIndexedDbModel>) {
    super(attributes);
  }

  public getData(): any {
    return {
      id: this.id,
      name: this.name,
      available_offline: this.available_offline,
      translation_key: this.translation_key,
      menu_index: this.menu_index
    };
  }
}
