import { Injectable } from '@angular/core';
import { ApiService, ListOptions } from '@capturum/api';
import { map } from 'rxjs/operators';
import { BaseDataApiModel } from '../models/base-data.api-model';
import { BaseDataValueIndexedDbModel } from '../models/base-data-value.indexedDb-model';
import { BaseDataIndexedDbModel } from '../models/base-data.indexedDb-model';
import { forkJoin, from, Observable } from 'rxjs';

@Injectable()
export class BaseDataService extends ApiService<BaseDataApiModel[]> {

  public loadBaseData(): Observable<boolean> {
    return forkJoin([
      from(this.loadBaseDataByRole()),
      from(this.loadNestedBaseData()),
    ]).pipe(map(() => true));
  }

  /**
   * Retrieve baseData and store in indexeddb
   */
  public async loadBaseDataByRole(): Promise<boolean> {
    const baseDataValues = await this.apiHttp.get('/role/base-data').pipe(
      map((response: { data: BaseDataApiModel[] }) => {
        return response.data.map(baseDataValue => this.transformBaseDataValue(baseDataValue));
      }),
    ).toPromise();

    await BaseDataValueIndexedDbModel.query().clear();
    await BaseDataValueIndexedDbModel.query().bulkPut(baseDataValues);

    return true;
  }

  /**
   * Retrieve nested baseData and store in indexeddb
   */
  public async loadNestedBaseData(): Promise<boolean> {
    const options: ListOptions = { include: ['properties', 'values', 'values.propertyValues'], perPage: 99999 };
    const url = `/base-data-key${this.getOptionsQuery(options)}`;
    const baseDataValues = await this.apiHttp.get(url).pipe(
      map((response: { data: BaseDataApiModel[] }) => {
        return response.data.map(baseDataKey => this.transformBaseDataKey(baseDataKey));
      }),
    ).toPromise();

    await BaseDataIndexedDbModel.query().clear();
    await BaseDataIndexedDbModel.query().bulkPut(baseDataValues);

    return true;
  }

  protected transformBaseDataValue(baseDataValue: any): BaseDataApiModel {
    return {
      ...baseDataValue,
      attributes: baseDataValue.attributes.map(baseDataValueAttribute => {
        baseDataValueAttribute = {
          ...baseDataValueAttribute,
          attribute: baseDataValueAttribute.property,
        };

        delete baseDataValueAttribute.property;

        return baseDataValueAttribute;
      }),
    };
  }

  protected transformBaseDataKey(baseDataKey: any): BaseDataApiModel {
    baseDataKey = {
      ...baseDataKey,
      attributes: baseDataKey.properties,
      values: baseDataKey.values.map(baseDataValue => {
        baseDataValue = {
          ...baseDataValue,
          attributes: baseDataValue.propertyValues,
        };

        delete baseDataValue.propertyValues;

        return baseDataValue;
      }),
    };

    delete baseDataKey.properties;

    return baseDataKey;
  }
}
