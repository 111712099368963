import { Message } from 'primeng/api';
import { Observable } from 'rxjs';

export interface MessageProvider {
  messageObserver: Observable<Message | Message[]>;
  clearObserver: Observable<string>;
  add: (message: Message) => void;
  addAll: (messages: Message[]) => void;
  clear: (key?: string) => void;
}
