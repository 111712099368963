<div class="form-element">
    <label *ngIf="label || hasTooltip" [style]="{ 'justify-content': label ? 'space-between' : 'flex-end' }">
        <span *ngIf="label">{{ label }}</span>

        <ng-content select=".tooltip-content"></ng-content>
    </label>

    <p-colorPicker (onChange)="changeColor.emit($event.value)"
                   [(ngModel)]="value"
                   [appendTo]="appendTo || null"
                   [disabled]="disabled || false"
                   [format]="'hex'"
                   [hideTransitionOptions]="hideTransitionOptions || '195ms ease-in'"
                   [inline]="inline"
                   [showTransitionOptions]="showTransitionOptions || '225ms ease-out'"
                   [styleClass]="styleClass || null"
                   [style]="style || null">
    </p-colorPicker>

    <ng-container *ngIf="displayInput" [formGroup]="form">
        <div class="form-element colorpicker-input">
            <div class="p-inputgroup colorpicker-border">
                <input (change)="pickColor($event)"
                       [value]="value ? value: ''"
                       formControlName="colorPickerInput"
                       [placeholder]="placeholder"
                       class="p-inputtext colorpicker-input-border" />
            </div>
        </div>
    </ng-container>

    <cap-validator *ngIf="control" [control]="control" [customName]="label"></cap-validator>
</div>
