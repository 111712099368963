import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { InfoTableColumn } from '../../base/info-table-column.model';

@Component({
  selector: 'cap-cell-data',
  templateUrl: './cell-data.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CellDataComponent {
  @Input() public column: InfoTableColumn;
  @Input() public isCard: boolean;
}
