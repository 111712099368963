import { ChangeDetectorRef, EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';

@Injectable()
export abstract class CompleteBaseForm implements OnDestroy, ControlValueAccessor {
  public form = new FormGroup({});
  public formSubscription: Subscription;
  public formChanged = new EventEmitter<any>();
  public updateForm = new BehaviorSubject<any>(null);
  public updateForm$: Observable<any> = this.updateForm.asObservable();

  protected constructor(private cdr: ChangeDetectorRef) {
  }

  public listenToFormChanges(): void {
    this.formSubscription = this.onFormChange().pipe(delay(0)).subscribe((value) => {
      this.formChanged.emit(value);
    });
  }

  public onFormChange(): Observable<any> {
    return this.form.valueChanges;
  }

  public registerOnChange(fn: any): void {
    this.formChanged.subscribe(fn);
  }

  public writeValue(value: any): void {
    this.updateForm.next(value);
  }

  /**
   * Trigger form value update
   */
  public updateFormValue(): void {
    // Add the delay to make sure formly has created it's formGroup
    this.updateForm$.pipe(delay(0)).subscribe((value) => {
      this.form.patchValue({ ...value }, { emitEvent: true });
      this.cdr.detectChanges();
    });
  }

  public registerOnTouched(fn: any): void {
  }

  public validate({ value }: FormControl): Record<string, boolean> {
    return this.form.invalid && {
      invalid: true,
    };
  }

  public ngOnDestroy(): void {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }
}
