import { Component, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'em-wrapper-form-field',
  templateUrl: './form-field.wrapper.html',
  styleUrls: ['./form-field.wrapper.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FormFieldWrapper extends FieldWrapper {
  @ViewChild('fieldComponent', { read: ViewContainerRef, static: true }) fieldComponent!: ViewContainerRef;
}
