import { ChangeDetectorRef, Component, HostListener, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { ListOptions } from '@capturum/api';
import { FilterMatchMode, LocalStorageService } from '@capturum/ui/api';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BehaviorSubject, filter, take, Subject, tap } from 'rxjs';
import { EDIT_TRANSLATION_MODE_KEY } from '../../constants/edit-translation-mode.constant';
import { TranslationKeyService } from '../../services/translation-key.service';
import { EditTranslationDialogComponent } from '../edit-translation-dialog/edit-translation-dialog.component';

@Component({
  selector: 'app-edit-translations-toggler',
  templateUrl: './edit-translations-toggler.component.html',
  styleUrls: ['./edit-translations-toggler.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EditTranslationsTogglerComponent {
  public editMode = false;
  public dialogRef: DynamicDialogRef;
  public loading$: Subject<boolean> = new Subject();
  public moduleNames: string[] = [];
  public clickUnlistener: () => void;
  public translationClicked$ = new BehaviorSubject(false);

  constructor(
    private readonly translateService: TranslateService,
    private readonly translationKeyService: TranslationKeyService,
    private readonly dialogService: DialogService,
    private readonly localStorageService: LocalStorageService,
    private readonly cdr: ChangeDetectorRef,
    private readonly renderer2: Renderer2
  ) {}

  @HostListener('window:beforeunload ', ['$event'])
  public onBeforeUnload(): void {
    if (this.editMode) {
      this.translationClicked$.next(false);
      this.clickUnlistener();
    }
  }

  public toggleEditMode(isChecked: boolean): void {
    if (isChecked) {
      this.loading$.next(true);

      this.editMode = true;
      this.localStorageService.setItem(EDIT_TRANSLATION_MODE_KEY, true);

      // Enforce reloading the same language
      const currentLanguage = this.translateService.currentLang;
      this.translateService.currentLang = undefined;

      this.translateService.use(currentLanguage).subscribe((translations) => {
        this.setModulesNames(translations);

        setTimeout(() => {
          const rootElement = document.getElementsByTagName('app-root')[0];
          const dialogElement = document.getElementsByTagName('p-dynamicdialog')[0];

          if (dialogElement) {
            this.replaceTranslationsWithLink(dialogElement);
          }

          this.replaceTranslationsWithLink(rootElement);

          this.localStorageService.setItem(EDIT_TRANSLATION_MODE_KEY, false);

          this.clickUnlistener = this.renderer2.listen('document', 'click', (event) => {
            this.openModal(event);
          });
        }, 10);

        this.loading$.next(false);
        this.cdr.detectChanges();
      });
    }
  }

  public openModal({ target }: { target: Element }): void {
    // if the user clicks on the input switch, reload the page
    if (target.tagName === 'P-INPUTSWITCH') {
      target.getElementsByClassName('p-inputswitch-checked')[0].classList.remove('p-inputswitch-checked');
      this.reloadPage();

      return;
    }

    if (this.translationClicked$.getValue()) {
      return;
    }

    const moduleName = target?.attributes?.['module-name']?.value;
    const key = target?.attributes?.['key']?.value;

    if (!key) {
      return;
    }

    const options: ListOptions = {
      filters: [
        {
          field: 'key',
          value: key,
          operator: FilterMatchMode.EQUALS,
        },
      ],
    };

    if (moduleName) {
      options.filters.push({
        field: 'module.key',
        value: moduleName,
        operator: FilterMatchMode.EQUALS,
      });
    }

    this.translationClicked$.next(true);

    this.translationKeyService.getTranslationIdByKey(options).subscribe(({ data }) => {
      if (!data?.length) {
        this.translationClicked$.next(false);

        return;
      }

      this.dialogRef = this.dialogService.open(EditTranslationDialogComponent, {
        header: `${moduleName ? `${moduleName}.` : ''}${key}`,
        data: {
          id: data[0].id,
        },
      });

      this.dialogRef?.onClose
        ?.pipe(
          take(1),
          tap(() => {
            this.translationClicked$.next(false);
            this.dialogRef = null;
          }),
          filter(Boolean)
        )
        .subscribe((newValue) => {
          if (target['innerHTML']) {
            target['innerHTML'] = newValue;
          }
        });
    });
  }

  public replaceTranslationsWithLink(element: Element): void {
    if (!element?.innerHTML) {
      return;
    }

    // Pattern is looking for: !@![demooij.button.add]-[Add]!@!
    // Where: !@![translation key]-[translation value]!@!
    // ([^="]) is looking for any character except =", to not replace the pattern
    // in attributes
    const translationPattern = /([^="])!@!\[([^<>\]\[]+)\]-\[(.*?)\]\!@!/g;

    element.innerHTML = element.innerHTML.replace(translationPattern, (_match, ...args) => {
      const firstElement = args[0];
      const translationKeyArray = args[1]?.split('.');
      const translationValue = args[2];

      // In the key 'demooij.entity.sales-order.singular',
      // 'demooij' is the moduleName, and 'entity.sales-order.singular' is the key
      // *****
      // If there is no module name, send just key.
      const moduleName = this.moduleNames.includes(translationKeyArray[0]) ? translationKeyArray[0] : '';
      const key = moduleName
        ? translationKeyArray.slice(1, translationKeyArray.length).join('.')
        : translationKeyArray.join('.');

      return `${firstElement}<span class="translation-link" editable-translation="true" module-name="${moduleName}" key="${key}">${translationValue}</span>`;
    });
  }

  public setModulesNames(translations: Record<string, any>): void {
    if (!translations) {
      return;
    }

    Object.entries(translations).forEach(([key, value]) => {
      if (typeof value !== 'string') {
        this.moduleNames.push(key);
      }
    });
  }

  public reloadPage(): void {
    location.reload();
  }
}
