/**
 * Transform a string|number to an Observable
 */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitPipe'
})
export class SplitPipe implements PipeTransform {
  public transform(value: string, splitCharacters: string, splitIndex: number): string {
    if (!!value) {
      return value.split(splitCharacters)[splitIndex];
    }

    return '';
  }
}
