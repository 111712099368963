import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@capturum/auth';
import { Observable } from 'rxjs';
import { CompleteConfig } from '../../complete-config';
import { IndexedDbService } from './indexedDb.service';

@Injectable()
export class IndexedDbGuard  {
  constructor(private domainService: IndexedDbService, private config: CompleteConfig, private authService: AuthService) {

  }

  /**
   * Make sure the store is created before accessing the route
   *
   * @param route - the activated route
   * @param state - the current state of the router
   * @return Observable<boolean>
   */
  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return new Observable((observer) => {
      let userId;

      if (this.config.userSpecificDb !== false) {
        const user = this.authService.getUser();

        if (user && user.id) {
          userId = user.id;
        }
      }

      this.domainService.createStore(userId).then(() => {
        observer.next(true);
        observer.complete();
      });
    });
  }
}
