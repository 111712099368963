import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { InfoTableConfig } from '../base/info-table-config.model';

@Injectable({
  providedIn: 'root',
})
export class InfoTableConfigService {
  private defaultConfig: InfoTableConfig = {
    texts: {
      positive: 'Yes',
      negative: 'No',
    },
    cardBreakpoint: 768,
    defaultTexts: {
      filtersLabel: 'Filters',
      itemsSelectedMessage: '{0} items selected',
      resetSortingLabel: 'Reset sorting',
      descendingLabel: 'Descending',
      ascendingLabel: 'Ascending',
      sortButtonLabel: 'Sort table',
      resetFilterButtonLabel: 'Clear filters',
      noResults: 'No results found',
      footerPaginationText: 'showing {0} of {1} entries',
      filtersEditHeader: 'Edit filters',
      filtersEditCloseBtn: 'Close',
      filtersEditSelectItemsMessage: 'Select the filters you want to use',
    },
    sortMode: 'single',
    showVisibilityToggler: false,
    pagination: true,
    autoLayout: false,
    sortable: true,
    dataKey: 'id',
    visibilityTogglerIcon: 'fas fa-columns',
    clickable: true,
    perPageOptions: [
      { label: '10', value: 10 },
      { label: '20', value: 20 },
      { label: '50', value: 50 },
    ],
    virtualScrollItemSize: 46,
    scrollHeight: 'flex',
    editableRows: {
      editButton: {
        label: 'edit',
        icon: 'fas fa-pencil-alt',
        iconOnly: true,
      },
      saveButton: {
        label: 'save',
        icon: 'fas fa-check',
        iconOnly: true,
      },
      cancelButton: {
        label: 'cancel',
        icon: 'fas fa-times',
        iconOnly: true,
      }
    }
  };
  private config: BehaviorSubject<InfoTableConfig> = new BehaviorSubject<InfoTableConfig>(this.defaultConfig);

  public setConfig(config: Partial<InfoTableConfig>): void {
    this.config.next({ ...this.defaultConfig, ...config });
  }

  public getConfig(): Observable<InfoTableConfig> {
    return this.config.asObservable();
  }
}
