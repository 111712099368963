import { Component, Input } from '@angular/core';
import { ListRendererWidgetBase } from '../../base/list-renderer-widget-base';

@Component({
  selector: 'cpb-list-renderer-widget-concatenation',
  template: `
    <div *ngIf="item">
      {{ item | concatenate: parts }}
    </div>
  `,
})
export class ListRendererWidgetConcatenationComponent extends ListRendererWidgetBase {
  @Input() public parts: string[];
}
