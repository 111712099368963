export * from './components/base-data-form/base-data-form.component';
export * from './components/base-data-value-form/base-data-value-form.component';
export * from './services/base-data-value.service';
export * from './services/base-data.service';
export * from './services/base-data-key.service';
export * from './models/base-data-value.api-model';
export * from './models/base-data-value.indexedDb-model';
export * from './models/base-data-key.indexedDb-model';
export * from './models/base-data.indexedDb-model';
export * from './models/base-data.api-model';
export * from './base-data.module';
export * from './resolvers/base-data.resolver';
export * from './pipes/base-data-translation.pipe';
