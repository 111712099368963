import { format } from 'date-fns'

export class RecursiveIteration {
  protected dateProperties: string[] = [];
  protected dateFormat = 'yyyy-MM-dd HH:mm:ss';

  protected recursiveParse(item: any): any {
    if (item instanceof Blob) {
      return item
    } else {
      const mappedItem = { ...item };

      if (item) {
        Object.keys(item).forEach(key => {
          const itemValue = item[key];

          if (Array.isArray(itemValue)) {
            if (itemValue.every(value => typeof value === 'object' && !(value instanceof Date))) {
              mappedItem[key] = itemValue.map(itemObj => this.recursiveParse(itemObj));
            }

            if (this.dateProperties.includes(key)) {
              mappedItem[key] = itemValue.map(itemObj => this.getFormattedValue(itemObj, key));
            }
          } else if (typeof itemValue === 'object' && (itemValue instanceof Date)) {
            mappedItem[key] = this.getFormattedValue(itemValue, key);
          } else if (itemValue) {
            switch (typeof itemValue) {
              case 'object':
                return this.recursiveParse(itemValue);
              default:
                mappedItem[key] = this.getFormattedValue(itemValue, key);
                break;
            }
          }
        });
      }

      return mappedItem;
    }
  }

  private getFormattedValue(item: any, key: string): any {
    if (this.dateProperties.includes(key)) {
      if (isNaN(new Date(item).getTime())) {

        return item;
      }

      return item && format(new Date(item), this.dateFormat);
    }

    return item;
  }
}
