<div>
  <div class="logo">
    <div class="logo__image">
      <img src="/assets/images/capturum-logo.jpeg" alt="capturum-logo" />
    </div>
  </div>


  <div class="sidebar">
    <ul class="sidebar__menus">
      <ng-container *ngFor="let menu of menus">
        <li class="menu-title">
          {{ menu.title }}
        </li>

        <ng-container *ngFor="let item of menu.items">
          <li
            class="sidebar-item"
            routerLinkActive="active"
            [routerLink]="item?.value">
            <i [className]="item?.icon"></i>
            <span class="item-title">{{ item?.label }}</span>
          </li>
        </ng-container>
      </ng-container>

      <li class="logout">
        <i class="fas fa-sign-out-alt"></i>
        <span class="item-title">
          Logout
        </span>
      </li>
    </ul>
  </div>
</div>
