import { Observable } from 'rxjs';


export interface IndexableService {
  getData(): Observable<any>;
  getDataFromApi(): Observable<any>;
  getDataFromIndexDb(): Observable<any>;
  setIndexedDbData(data: any): Observable<any>;
  getLoadingResource(forceNetworkRequest?: boolean): Observable<any>;
}
