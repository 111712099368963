export interface ListSettingsData {
  field: string;
  label?: string;
  reorderable?: boolean;
  hidden?: boolean;
}

export interface SettingsChangeEvent {
  filters?: ListSettingsData[];
  columns?: ListSettingsData[];
}
