import { DatePipe } from '@angular/common';

/**
 * This class is used to declare all common transform methods
 */
export class TransformUtils {
  public static toIsoDate(value: Date): string | Date {
    const datePipe = new DatePipe('en-US');

    return datePipe.transform(value, 'yyyy-MM-ddTHH:mm:ssZZZZZ');
  }
}
