<cap-input [formControl]="formControl"
           [floatLabel]="(to.label_position | observablePipe | async ) === labelPosition.Floating"
           [label]="(to.label_position | observablePipe | async ) === labelPosition.Floating ? to.label : ''"
           [attr.data-test]="'builder-' + field.key"
           [readonly]="to.readonly"
           [hidden]="to.readonly"
           [hasTooltip]="to.tooltip"
           [selectOnFocus]="to?.select_on_focus"
           [placeholder]="to.readonly ? '' : to.placeholder">
</cap-input>

<cpb-readonly-container [hidden]="!to.readonly" [attr.data-test]="'builder-readonly-' + field.key">
  <a [href]="formControl.value" target="_blank">{{ formControl.value | default: to?.defaultEmptyValue }}</a>
</cpb-readonly-container>
