import { Injectable, OnDestroy } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { AuthService } from '@capturum/auth';
import { DependencyOperator } from '@capturum/builders/core';
import { MapItem, ValidatorService } from '@capturum/ui/api';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Store } from '@ngxs/store';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { map, startWith, switchMap, take } from 'rxjs/operators';

import { SetValue } from '../state/form-builder/form-builder.actions';
import { FormBuilderBaseDataService } from './form-builder-base-data.service';

@Injectable({ providedIn: 'root' })
export class FormInputHelperService implements OnDestroy {
  private subscription: Subscription = new Subscription();
  private inputsConfiguration: any = {};

  constructor(
    private readonly store: Store,
    private readonly authService: AuthService,
    private readonly baseDataService: FormBuilderBaseDataService,
    private readonly validatorService: ValidatorService
  ) {
    this.setDefaultStateValues();
    this.addCustomTranslation();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();

    this.inputsConfiguration = {};
  }

  public handleOptionsSourceSets(field: FormlyFieldConfig, configuration: any): void {
    if (field.formControl) {
      this.subscription.add(
        field.formControl.valueChanges
          .pipe(
            startWith(field.formControl.value),
            switchMap<any, Observable<{ fieldValue: any; fieldOptions: any[] }>>((fieldValue) => {
              return field.props
                ? field.props.options$.asObservable().pipe(map((fieldOptions) => ({ fieldValue, fieldOptions })))
                : of({ fieldValue: null, fieldOptions: [] });
            })
          )
          .subscribe(({ fieldValue, fieldOptions }) => {
            this.setValueByKey(
              configuration.sets,
              fieldOptions.find((item) => item[configuration.options.source.value_key] === fieldValue)
            );
          })
      );
    }
  }

  public resolveDependencies(
    field: FormlyFieldConfig,
    dependencies: string[],
    dependencyOperator: DependencyOperator,
    options$?: Observable<MapItem[]>
  ): void {
    if (field.form) {
      const source$ = this.getDependenciesSource(this.getDependencyParts(dependencies, field.form));

      if (source$) {
        this.subscription.add(
          combineLatest(source$)
            .pipe(map((data) => data.filter((value) => value !== null && value !== undefined)))
            .subscribe((data: boolean[]) => {
              const isOrOperator = dependencyOperator === DependencyOperator.or;

              if (field.formControl) {
                field.formControl.reset(field.formControl.value);
              }

              field.hide = isOrOperator ? data.every((isHidden) => isHidden) : data.some((isHidden) => isHidden);

              if (!field.hide) {
                // Set default options
                if (options$) {
                  this.setDefaultDropdownOption(options$, field);
                }
              }
            })
        );
      }
    }
  }

  public getValidations(validations: string[] = []): Record<string, any> {
    const validators: Record<string, any> = {};

    if (validations && validations.length) {
      validations.forEach((validation) => {
        if (validation === 'required') {
          validators.required = true;
        } else if (validation.startsWith('max')) {
          validators.maxLength = validation.split(':')[1];
        }
      });
    }

    return validators;
  }

  public setCustomValidations(validations: any, field: FormlyFieldConfig): void {
    if (field.formControl && field.form) {
      if (validations?.hasOwnProperty('required_if')) {
        const source$ = [
          ...this.getDependenciesSource(this.getDependencyParts(validations.required_if as string[], field.form)),
          field.formControl.valueChanges.pipe(startWith(field.formControl.value)),
        ];

        if (source$) {
          this.subscription.add(
            combineLatest(source$)
              .pipe(map((data) => data.filter((value) => value !== null && value !== undefined)))
              .subscribe((data: boolean[]) => {
                if (field.form) {
                  const required = data.every((isHidden) => !isHidden);
                  const fieldControl = field.form.get(field.key as string);

                  field.props.required = required;

                  if (required) {
                    fieldControl?.setErrors({ required });
                  } else {
                    fieldControl?.clearValidators();
                    fieldControl?.updateValueAndValidity({ onlySelf: true, emitEvent: false });
                  }
                }
              })
          );
        }
      }
    }
  }

  public getLabelKeyBySource(source: any): string {
    let labelKey = source?.label_key;

    if (source?.type === 'base_data') {
      labelKey = 'label';
    }

    return labelKey;
  }

  public getValueKeyBySource(source: any): string {
    let labelKey = source?.value_key;

    if (source?.type === 'base_data') {
      labelKey = 'value';
    }

    return labelKey;
  }

  public setValueByKey(key: string, value: any): void {
    this.store.dispatch(new SetValue(key, value));
  }

  public getValueByKeyOrDefault(key: string, defaultValue: any = null): any {
    const formBuilderState = this.store.selectSnapshot((state) => state.formBuilder);
    const value = formBuilderState[key];

    return value === undefined || value === null ? defaultValue : value;
  }

  public getValueAsArrayByKeyOrDefault(key: string, defaultValue: any = null): any {
    const formBuilderState = this.store.selectSnapshot((state) => state.formBuilder);
    let value = formBuilderState[key];

    if (value !== null && value !== undefined) {
      value = Array.isArray(value) ? value : [value];
    }

    return value === null || value === undefined ? defaultValue : value;
  }

  public getOptionsWithImageKey(options: any[], imageKey: string): any[] {
    if (imageKey) {
      options = options.map((option) => ({
        ...option,
        url: `assets/images/form-builder${imageKey}.png`,
      }));
    }

    return options;
  }

  public setInputConfiguration(name: string, configuration: any): void {
    this.inputsConfiguration[name] = configuration;
  }

  public setDefaultOption(field: FormlyFieldConfig, options: any[]): void {
    const defaultOption = options.find((option) => option.is_default);
    const defaultValue = field.props && defaultOption ? defaultOption[field.props.value_key] : null;

    if (
      field.formControl &&
      (field.defaultValue === null || field.defaultValue === undefined) &&
      defaultValue !== null &&
      defaultValue !== undefined
    ) {
      field.formControl.setValue(defaultValue);
    }
  }

  public setDefaultDropdownOption(options$: Observable<any[]>, field: FormlyFieldConfig): void {
    if (options$) {
      options$.pipe(take(1)).subscribe((options) => {
        this.setDefaultOption(field, options);

        if (field.props) {
          field.props.options$.next(options);
        }
      });
    }
  }

  private setDefaultStateValues(): void {
    this.store.dispatch([new SetValue('currentUser', this.authService.getUser())]);
  }

  private getDependencyParts(
    dependencies: string[],
    form: FormGroup | FormArray
  ): { field: string; values: string[]; control: FormControl }[] {
    return dependencies.map((dependency) => {
      let field: string;
      let values: string[] = [];
      let control: FormControl;

      if (dependency.includes(':')) {
        const dependencyParts: string[] = dependency.split(':');

        field = dependencyParts[0];
        values = dependencyParts[1].split(',');
      } else {
        field = dependency;
      }

      control = form.get(field) as FormControl;

      return { field, values, control };
    });
  }

  private getDependenciesSource(
    dependencyParts: { field: string; values: string[]; control: FormControl }[]
  ): Observable<boolean>[] {
    if (dependencyParts?.length) {
      return dependencyParts.map((dependency) =>
        dependency.control
          ? dependency.control.valueChanges.pipe(
              startWith(dependency.control.value),
              switchMap((value) => {
                const isFieldBaseDataValue = this.inputsConfiguration[dependency.field]?.props?.is_base_data;

                if (value && isFieldBaseDataValue) {
                  return this.baseDataService.getById(value).pipe(map((baseDataItem) => baseDataItem.value));
                } else {
                  return of(value);
                }
              }),
              map((value) => {
                if (dependency.values?.length) {
                  const isEmptyArray = Array.isArray(value) && value.length;

                  return (!value || isEmptyArray) && dependency.values.includes('empty')
                    ? false
                    : !dependency.values.includes(value?.toString());
                } else {
                  return value === null || value === undefined || value === false;
                }
              })
            )
          : of(false)
      );
    }

    return [];
  }

  private addCustomTranslation(): void {
    this.validatorService.setValidationMessages({
      maxlength: 'validation-errors.maxlength',
    });
  }
}
