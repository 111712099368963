import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, forwardRef } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CompleteBaseForm } from '../../../../shared/components/complete-base-form';
import { map } from 'rxjs/operators';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { PermissionService } from '../../../permission';
import { UtilService } from '../../../../shared/services/util.service';
import { noScript, noSpecialChars } from '@capturum/formly';

@Component({
  selector: 'emc-role-form',
  templateUrl: './role-form.component.html',
  styleUrls: ['./role-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RoleFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RoleFormComponent),
      multi: true,
    },
  ],
})
export class RoleFormComponent extends CompleteBaseForm implements AfterViewInit {
  @Input()
  public fields: FormlyFieldConfig[];

  constructor(private changeDetectorRef: ChangeDetectorRef,
              private utilService: UtilService,
              private translateService: TranslateService,
              private permissionService: PermissionService) {
    super(changeDetectorRef);
  }

  public ngAfterViewInit(): void {
    this.listenToFormChanges();

    this.fields = this.fields?.length ? this.fields : [
      {
        key: 'name',
        type: 'input',
        props: {
          translateLabel: this.translateService.stream('role.overview.name'),
          required: true,
          minLength: 2,
          maxLength: 50,
        },
        validators: {
          validation: [noSpecialChars, noScript],
        },
      },
      {
        key: 'key',
        type: 'input',
        props: {
          translateLabel: this.translateService.stream('role.overview.key'),
          required: true,
          minLength: 2,
          maxLength: 50,
        },
        validators: {
          validation: [noSpecialChars, noScript],
        },
      },
      {
        key: 'permissions',
        type: 'multiSelect',
        props: {
          options: this.permissionService.getPermissionsOfCurrentTenant()
            .pipe(
              map((permissions) => this.utilService.mapToSelectItem(permissions, 'id', 'name')),
            ),
          styleClass: 'w-100',
          filter: true,
          defaultLabel: this.translateService.instant('placeholder.select'),
          translateLabel: this.translateService.stream('role.detail.select_permissions'),
          translatePlaceholder: this.translateService.instant('placeholder.select'),
          required: true,
        },
      },
    ];

    this.changeDetectorRef.detectChanges();
    this.updateFormValue();
  }
}
