import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CapturumFormRendererModule } from '@capturum/builders/form-renderer';
import { CapturumBuilderUiModule } from '@capturum/builders/ui';
import { CapturumSharedModule } from '@capturum/ui/api';
import { CapturumButtonModule } from '@capturum/ui/button';
import { CapturumDropdownModule } from '@capturum/ui/dropdown';
import { CapturumSidebarModule } from '@capturum/ui/sidebar';
import { CapturumTextareaModule } from '@capturum/ui/textarea';
import { FormlyModule } from '@ngx-formly/core';
import { TranslateModule } from '@ngx-translate/core';
import { SidebarModule } from 'primeng/sidebar';

import { CapturumPageBuilderWidgetComponent } from './components/page-builder-widget/page-builder-widget.component';
import { CapturumPageBuilderComponent } from './components/page-builder/page-builder.component';
import { DragDropManagerDirective, DragDropManagerRootDirective } from './directives/drag-drop-manager.directive';
import { HasWidgetsPipe } from './pipes/has-widgets.pipe';
import { DragDropManagerService } from './services/drag-drop-manager.service';

// @dynamic
@NgModule({
  declarations: [
    CapturumPageBuilderComponent,
    CapturumPageBuilderWidgetComponent,
    HasWidgetsPipe,
    DragDropManagerDirective,
    DragDropManagerRootDirective,
  ],
  imports: [
    CommonModule,
    SidebarModule,
    ReactiveFormsModule,
    FormlyModule,
    CapturumDropdownModule,
    CapturumFormRendererModule,
    CapturumButtonModule,
    CapturumTextareaModule,
    DragDropModule,
    CapturumBuilderUiModule,
    CapturumSidebarModule,
    TranslateModule,
    CapturumSharedModule,
  ],
  exports: [
    CapturumPageBuilderComponent,
    CapturumPageBuilderWidgetComponent,
  ],
  providers: [DragDropManagerService]
})
export class CapturumPageBuilderModule { }
