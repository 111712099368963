<div class="cap-dynamic-filter-layout">
  <label for="" *ngIf="label">
    {{ label }}
  </label>

  <div class="cap-dynamic-filter-layout-content">
    <ng-content></ng-content>

    <div class="filter-icon">
      <i [class]="iconClass"></i>
    </div>

    <div class="delete-filter" *ngIf="showReset" (click)="resetFilter.emit()">
      <i class="fas fa-trash-alt"></i>
    </div>
  </div>
</div>
