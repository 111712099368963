<div class="page">
    <main class="page__main main">
        <cap-headline [contentTemplate]="headlineTemplate" class="main__headline" [title]="title"></cap-headline>

        <div class="main__content">
            <div class="row">
                <ng-container *ngIf="isContentWithTwoColumns(); else contentWithOneColumn">
                    <div class="main__col main__col--left col-md-6">
                        <ng-container [ngTemplateOutlet]="firstContentTemplate"></ng-container>
                    </div>
                    <div class="main__col main__col--right col-md-6">
                        <ng-container [ngTemplateOutlet]="secondContentTemplate"></ng-container>
                    </div>
                </ng-container>

                <ng-template #contentWithOneColumn>
                    <div class="main__col col">
                        <ng-container [ngTemplateOutlet]="firstContentTemplate"></ng-container>
                    </div>
                </ng-template>
            </div>
        </div>
    </main>
</div>
