<div class="page__content">
  <div class="row">
    <div class="col-12 col-lg-6 col-xl-4">
      <form *ngIf="!loading" [formGroup]="formGroup" (ngSubmit)="submit()">
        <emc-translation-form formControlName="translation" [locales]="locales$ | async" [model]="model">
        </emc-translation-form>
      </form>
    </div>
  </div>
</div>

<div class="separator"></div>

<cap-button
  class="d-flex mt-3"
  styleClass="secondary"
  [ngClass]="{ submitting: submitting | observablePipe | async }"
  [label]="'button.submit' | translate"
  [icon]="(submitting | observablePipe | async) ? 'fas fa-spinner fa-pulse' : 'fas fa-check'"
  (onClick)="submit()">
</cap-button>
