import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CapturumHeadlineComponent } from './headline.component';
import { CapturumSharedModule } from '@capturum/ui/api';

@NgModule({
  declarations: [
    CapturumHeadlineComponent
  ],
  exports: [
    CapturumHeadlineComponent
  ],
  imports: [
    CommonModule,
    CapturumSharedModule
  ]
})
export class CapturumHeadlineModule {
}
