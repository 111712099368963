<emc-multi-factor-enable-form *ngIf="!isEnabled"
                              [canSelectMethod]="canSelectMethod"
                              [selectedMethod]="selectedMethod"
                              (currentForm)="updateCode($event)">
</emc-multi-factor-enable-form>

<emc-multi-factor-disable-form *ngIf="isEnabled"
                               [selectedMethod]="selectedMethod"
                               (currentForm)="updateCode($event)">
</emc-multi-factor-disable-form>

<div class="dialog-footer d-flex justify-content-between">
  <cap-button styleClass="secondary mr-4"
              [label]="'button.cancel' | translate"
              (click)="closeDialog()">
  </cap-button>

  <div class="flex-grow-1">
    <cap-button [styleClass]="submitStyleClass"
                [disabled]="!(isValidFormGroup)"
                [label]="'button.submit' | translate"
                (click)="submitDialog()">
    </cap-button>
  </div>
</div>
