<div class="cap-list-renderer-bulk-actions">
  <div class="bulk-action-title">
    {{ 'builders.ui.rows-selected' | translate : {amount: selectedItems.length} }}
  </div>

  <div *ngIf="contentTemplate" class="bulk-action-content">
    <ng-container [ngTemplateOutlet]="contentTemplate" [ngTemplateOutletContext]="{ items: selectedItems }">
    </ng-container>
  </div>

  <div class="button-container">
    <cap-button styleClass="secondary"
                [attr.data-test]="'builder-bulk-cancel'"
                (click)="handleCancelSelection()"
                [label]="'builders.ui.cancel-selection' | translate">
    </cap-button>

    <cap-button styleClass="primary"
                *ngIf="actions.length > 1"
                [attr.data-test]="'builder-bulk-perform'"
                [label]="'builders.ui.perform-bulk-action' | translate"
                (click)="isOpen = !isOpen"></cap-button>

    <cap-button styleClass="primary"
                *ngIf="actions.length === 1"
                [attr.data-test]="'builder-bulk-' + actions[0].key"
                [label]="actions[0].label | observablePipe | async"
                [icon]="actions[0].icon"
                (click)="executeAction(actions[0])">
    </cap-button>

    <div class="action-panel">
      <ul class="row-action__items bulk-items"
          [ngClass]="{'row-action__items--open': isOpen}">
        <ng-container *ngFor="let action of actions">
          <li *ngIf="(action?.hidden | observablePipe | async) !== true"
              (click)="executeAction(action)"
              [attr.data-test]="action?.key || action?.value">
            <div class="row-action__icon">
              <i [class]="action?.icon"></i>
            </div>

            <div class="row-action__info">
              <p class="title">
                {{ action?.label | observablePipe | async }}
              </p>

              <p *ngIf="action?.description | observablePipe | async as description"
                 class="description">
                {{ description }}
              </p>
            </div>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
</div>
