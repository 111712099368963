import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { DynamicFilterComponent, RangeFilterConfigItem } from '../../interfaces/dynamic-filter.interface';
import { FormControl } from '@angular/forms';
import { RangeFilterComponent } from '@capturum/ui/table';

@Component({
  selector: 'cap-dynamic-range-filter',
  templateUrl: './range-filter.component.html',
  styleUrls: ['./range-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DynamicRangeFilterComponent implements DynamicFilterComponent<string[]> {
  @Output()
  public updateValue = new EventEmitter<string[]>();
  @Input()
  public formControl = new FormControl();
  @Input()
  public filterConfig: RangeFilterConfigItem;
  @Input()
  public showReset = true;
  @Input()
  public showLabel: boolean;

  @Output()
  public onReset = new EventEmitter<void>();

  @ViewChild(RangeFilterComponent)
  public rangeFilter: RangeFilterComponent;

  constructor(private cdr: ChangeDetectorRef) {}
  
  public setValue(value: string[]): void {
    if(!value) {
      this.formControl.setValue(undefined);
    } else {
      this.formControl.setValue({value});
    }
  }

  public reset(): void {
    this.rangeFilter.reset();
    this.formControl.reset();

    this.onReset.emit();
    this.updateValue.emit(null);
  }

  public handleRangeChange(value: { from: string, to: string }): void {
    this.formControl.setValue([value.from, value.to], { emitEvent: false });
    this.updateValue.emit([value.from, value.to]);
  }

  public updateView(): void {
    this.cdr.detectChanges();
  }
}
