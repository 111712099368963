<div class="filter-sidebar">
  <div class="cap-filters">
    <div class="shown-filters filter-list sidebar-filter-list">
      <div
        class="sidebar-filter filter {{ filterStyleClass }} {{ filter?.styleClass }}"
        *ngFor="let filter of shownFilters"
        [ngClass]="{ search: filter?.field === 'global_search' }">
        <cap-dynamic-filter
          [attr.data-test]="'filter-' + filter.field"
          (updateValue)="setSidebarFilter($event)"
          [activeFilter]="activeFilters | filterField : filter.field"
          [filterConfig]="filter">
        </cap-dynamic-filter>
      </div>
    </div>

    <div class="hidden-filters filter-list sidebar-filter-list">
      <div
        class="sidebar-filter filter {{ filterStyleClass }} {{ filter?.styleClass }}"
        *ngFor="let filter of hiddenFilters"
        [ngClass]="{ search: filter?.field === 'global_search' }">
        <cap-dynamic-filter
          [attr.data-test]="'filter-' + filter.field"
          (updateValue)="setSidebarFilter($event)"
          [activeFilter]="activeFilters | filterField : filter.field"
          [filterConfig]="filter">
        </cap-dynamic-filter>
      </div>
    </div>
  </div>

  <div class="sidebar-filter-buttons">
    <cap-button styleClass="secondary" [label]="filterConfig.texts?.cancel || 'Cancel'" (onClick)="cancel()"></cap-button>

    <div class="sidebar-filter-right-buttons">
      <cap-button styleClass="secondary" [label]="filterConfig.texts?.reset || 'Reset'" (onClick)="reset()"></cap-button>
      <cap-button styleClass="primary" icon="fas fa-check" [label]="filterConfig.texts?.apply || 'Apply'" (onClick)="applyFilters()"></cap-button>
    </div>
  </div>
</div>
