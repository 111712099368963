import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BaseDataFormComponent } from './components/base-data-form/base-data-form.component';
import { SharedModule } from '../../shared/shared.module';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { BaseDataValueFormComponent } from './components/base-data-value-form/base-data-value-form.component';
import { BaseDataTranslationPipe } from './pipes/base-data-translation.pipe';

@NgModule({
  declarations: [
    BaseDataFormComponent,
    BaseDataValueFormComponent,
    BaseDataTranslationPipe,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    ProgressSpinnerModule,
  ],
  exports: [
    BaseDataFormComponent,
    BaseDataValueFormComponent,
    BaseDataTranslationPipe,
  ],
})
export class BaseDataModule {
}
