import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CapturumMultiSelectComponent } from './multi-select.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { CapturumSharedModule } from '@capturum/ui/api';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    CapturumMultiSelectComponent
  ],
  exports: [
    CapturumMultiSelectComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    MultiSelectModule,
    CapturumSharedModule
  ]
})
export class CapturumMultiSelectModule {
}
