export enum SnapPositions {
  'open' = 0,
  'gap' = 3,
  'threeQuarter' = 25,
  'half' = 50,
  'quarter' = 75,
  'header' = 90,
  'handle' = 96,
  'closed' = 100
}
