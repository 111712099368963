import { NgModule, ModuleWithProviders } from '@angular/core';

import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

import { BaseDetailComponent } from './core/bases/base-detail/base-detail.component';
import { BaseListComponent } from './core/bases/base-list/base-list.component';
import { SharedConfig } from './core/models/shared-config.model';
import { ToastService } from './toast.service';

@NgModule({
  declarations: [BaseDetailComponent, BaseListComponent],
  imports: [ConfirmDialogModule, ToastModule],
  exports: [BaseDetailComponent, BaseListComponent],
  providers: [MessageService]
})
export class SharedModule {
  static forRoot(config: SharedConfig = {}): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        { provide: MessageService, useClass: config.messageService || MessageService },
        { provide: ToastService, useClass: config.toastService || ToastService }
      ]
    };
  }
}
