import Role from './role.interface';
import Locale from './locale.interface';

export default interface User {
  id: number | string;
  locale_id?: number | string;
  name: string;
  email: string;
  language?: string;
  status?: string;
  roles: Role[];
  current_role?: Role;
  locale?: Locale;
}
