import { Injectable, Type } from '@angular/core';
import { BatchStatusNavigateAction } from '../actions/navigate.action';
import { BatchStatusActionProvider } from '../models/batch-status.model';

@Injectable({
  providedIn: 'root'
})
export class BatchStatusActionsService {
  public get actions(): Record<string, Type<BatchStatusActionProvider>> {
    return this._actions;
  }

  private _actions: Record<string, Type<BatchStatusActionProvider>> = {
    navigate: BatchStatusNavigateAction,
  };

  public addActions(actions: Record<string, Type<BatchStatusActionProvider>>): void {
    if (actions) {
      this._actions = {
        ...this.actions,
        ...actions,
      }
    }
  }
}
