import { FormControl, ValidationErrors } from '@angular/forms';

/**
 * Formly validator for IBAN
 *
 * @param control
 */
export function ibanValidator(control: FormControl): ValidationErrors {

  /**
   * Standard IBAN validation regular expression
   */
  const ibanRegExp: RegExp = new RegExp('^[A-Z]{2}[0-9]{2}[A-Z0-9]{4}[0-9]{0,14}$');

  return !control.value || ibanRegExp.test(control.value) ? null : { 'iban': true };
}
