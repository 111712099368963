import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { ValueAccessorBase } from '@capturum/ui/api';
import { QuillConfig, QuillEditorComponent } from 'ngx-quill';
import { QuillContentChangeEvent, QuillFocusEvent } from './interfaces/quill-content-change-event.interface';
import { ImageData as QuillImageData } from 'quill-image-drop-and-paste';
import { defaultModulesConfig } from './helpers/editor-config.helper';

@Component({
  selector: 'cap-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  providers: [ValueAccessorBase.getProviderConfig(EditorComponent)],
})
export class EditorComponent extends ValueAccessorBase<string> {
  @ViewChild(QuillEditorComponent)
  public quill: QuillEditorComponent;

  @ViewChild(NgModel, { static: true }) public model: NgModel;

  @Input()
  public styleClass: string;
  @Input()
  public allowImageDropOrPaste = true;
  @Input()
  public disabled: boolean;
  @Input()
  public debounceTime: number;
  @Input()
  public inputId: string;
  /**
   * Whether to use the Quill HTML view for uneditable content
   */
  @Input()
  public useQuillHtmlView: boolean;

  @Input()
  public set config(value: QuillConfig) {
    this._config = value;

    // Ensure that allowImageDropOrPaste functionality works
    this._config.modules = {
      ...this._config.modules,
      imageDropAndPaste: {
        handler: this.imageDropOrPasteHandler.bind(this),
      },
    }
  };

  public get config(): QuillConfig {
    return this._config;
  }

  @Output()
  public onChange = new EventEmitter<QuillContentChangeEvent>();
  @Output()
  public onFocus = new EventEmitter<QuillFocusEvent>();
  @Output()
  public onBlur = new EventEmitter<QuillFocusEvent>();

  private _config: QuillConfig = {
    placeholder: '',
    readOnly: false,
    modules: {
      ...defaultModulesConfig,
      imageDropAndPaste: {
        handler: this.imageDropOrPasteHandler.bind(this),
      },
    },
  };

  public imageDropOrPasteHandler(dataUrl: string, type: string, imageData: QuillImageData): void {
    if (this.allowImageDropOrPaste) {
      const caretPosition = this.quill?.quillEditor?.getSelection(true);

      this.quill?.quillEditor?.insertEmbed(caretPosition?.index || 0, 'image', dataUrl);
    }
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
