import { CapturumDialogService } from '@capturum/ui/api';
import { TranslateService } from '@ngx-translate/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ListRendererFilterConfig } from '@capturum/builders/core';
import { SEARCH_BAR } from '../../configs/filters.config';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  ActiveFilters,
  ActiveTokenFilter,
  CapturumDynamicFiltersComponent,
  DynamicFilterConfig,
} from '@capturum/ui/dynamic-filters';

@Component({
  selector: 'cpb-list-renderer-filters',
  templateUrl: './list-renderer-filters.component.html',
  styleUrls: ['./list-renderer-filters.component.scss'],
})
export class ListRendererFiltersComponent implements OnDestroy {
  @Input()
  public key: string;
  @Input()
  public routeFilters: boolean;
  @Input()
  public sidebarFiltering: boolean;
  @Output()
  public filtersChanges: EventEmitter<Record<string, any>> = new EventEmitter();
  @Output()
  public onSearch: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  public filterReset = new EventEmitter<void>();
  @ViewChild(CapturumDynamicFiltersComponent)
  public dynamicFiltersRef: CapturumDynamicFiltersComponent;
  public SEARCH_BAR = SEARCH_BAR;
  public dynamicFilterConfig: DynamicFilterConfig;
  public config: Partial<ListRendererFilterConfig>;
  public activeFilters: ActiveFilters[] = [];
  public maxTokens: number = 5;
  public filteredTokens: ActiveTokenFilter[];
  public handset$: Observable<BreakpointState>;
  public stackedFilters: boolean = false;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  private dialogRef: DynamicDialogRef;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
    @Inject(CapturumDialogService) private dialogService: CapturumDialogService,
    private translateService: TranslateService
  ) {
    this.handset$ = this.breakpointObserver
      .observe([Breakpoints.HandsetPortrait, Breakpoints.HandsetLandscape, Breakpoints.TabletPortrait])
      .pipe(takeUntil(this.destroy$));
  }

  @Input('config')
  public set filtersConfig(value: Partial<ListRendererFilterConfig>) {
    this.config = value;
    this.stackedFilters = this.config?.show_filters;
  }

  @Input('filters')
  public set listFilters(value: DynamicFilterConfig) {
    if (value !== undefined) {
      // To rerender the dynamic filters component
      if (this.dynamicFilterConfig) {
        this.dynamicFilterConfig = null;
        this.changeDetectorRef.detectChanges();
      }

      this.dynamicFilterConfig = value;
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public refreshList(): void {
    const formattedFilters = this.getFormattedFilters(this.dynamicFiltersRef?.activeFilters);

    this.filtersChanges.emit(formattedFilters);
  }

  public resetFilters(): void {
    this.filterReset.emit();
  }

  public onFilterChange(filters: ActiveFilters[]): void {
    const formattedFilters = this.getFormattedFilters(filters);

    this.setActiveFilters(filters);

    this.filtersChanges.emit(formattedFilters);
  }

  public removeFilter(removedFilter: ActiveTokenFilter): void {
    this.dynamicFiltersRef.handleUpdateValue({
      ...removedFilter,
      value: null,
    });
  }

  private setActiveFilters(filters: ActiveFilters[]): void {
    this.activeFilters = filters.filter((filter) => filter.field !== SEARCH_BAR);

    this.filteredTokens = this.activeFilters.slice(0, this.maxTokens).map((filter) => ({
      ...filter,
      label: this.dynamicFilterConfig?.filters?.find((filterConfig) => filterConfig.field === filter.field)?.label,
    }));
  }

  private getFormattedFilters(filters: ActiveFilters[]): Record<string, any> {
    const formattedFilters = {};

    filters?.filter((filter) => filter.value)?.forEach((filter) => (formattedFilters[filter.field] = filter.value));

    return formattedFilters;
  }
}
