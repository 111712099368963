export interface DashboardCard {
  title: string;
  link: {
    path: string;
    label: string;
    routerLink?: any[] | string;
    displayText?: string;
  };
  colWidth?: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
  };
  component: any;
  props?: {
    [keys: string]: any;
  };
}
