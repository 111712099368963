<ng-container *ngIf="props.options$ | async as options">
  <cap-multi-select
    [formControl]="formControl"
    [floatLabel]="(props.label_position | observablePipe | async) === labelPosition.Floating"
    [label]="(props.label_position | observablePipe | async) === labelPosition.Floating ? props.label : ''"
    [attr.data-test]="'builder-' + field.key"
    [options]="options"
    [style.display]="props.readonly ? 'none' : 'block'"
    [optionLabel]="props.label_key"
    [optionValue]="props.value_key"
    [selectionLimit]="props.selectionLimit"
    [panelStyleClass]="field.key"
    [resetFilterOnHide]="props.resetFilterOnHide || true"
    [virtualScroll]="props.virtualScroll"
    [filterBy]="props?.filterBy"
    [virtualScrollItemSize]="props.virtualScrollItemSize || 30"
    [defaultLabel]="props.placeholder || ('placeholder.select' | translate)"
    [appendTo]="props?.appendTo || 'body'"
    [readonly]="props.readonly"
    [sortBy]="'order'">
  </cap-multi-select>

  <ng-container *ngIf="props.readonly">
    <cpb-readonly-container [attr.data-test]="'builder-readonly-' + field.key">
      <div class="multiselect-list__readonly">
        {{ formControl.value | labelsByValue: options | default: props?.defaultEmptyValue }}
      </div>
    </cpb-readonly-container>
  </ng-container>
</ng-container>
