import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { InputTypeBuilder } from '../input/input-type.builder';
import { FormInputConfig } from '../../interfaces/form-input-config';
import { CustomInputConfiguration } from '../../models/custom-input-configuration.model';
import { FormFieldsManipulatorUtil } from '../../utils/form-fields-manipulator.util';

@Injectable({ providedIn: 'root' })
export class CustomInputTypeBuilder extends InputTypeBuilder implements FormInputConfig<CustomInputConfiguration> {
  protected appendOptions(input: FormlyFieldConfig, configuration: CustomInputConfiguration): void {
    if (configuration?.options?.key) {
      input.type = FormFieldsManipulatorUtil.getFormlyTypeName(configuration?.options?.key);
    }
  }

  protected setDefaultValue(input: FormlyFieldConfig, value: any): void {
    input.defaultValue = input.defaultValue || value;
  }
}
