import { BuilderAction, CapturumBuilderActionService } from '@capturum/builders/core';
import { FormSaverService } from '@capturum/builders/form-renderer';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

export class PowerBIManageDetailsBase {
  public item: { [key: string]: any };
  public modelId: string;
  public key: string;
  private _action: BuilderAction;

  constructor(
    protected formSaverService: FormSaverService,
    protected config: DynamicDialogConfig,
    protected dialogRef: DynamicDialogRef,
    protected actionService: CapturumBuilderActionService,
  ) {
    this.item = this.config?.data?.item;
    this.modelId = this.config?.data?.item?.id;
    this._action = this.config?.data?.action;
  }

  public submit(): void {
    this.formSaverService.submit(this.key).subscribe(response => {
      if (response) {
        this.close();
      }
    });
  }

  public close(): void {
    this.dialogRef.close();
  }
}
