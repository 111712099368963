import { Injectable, Injector } from '@angular/core';
import { ActionExecutionMessage, BuilderAction } from '../interfaces/builder-action.interface';
import { isObservable, Observable, Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { CapturumBuildersContextService } from './context.service';
import { ToastService } from '@capturum/ui/api';
import { TranslateService } from '@ngx-translate/core';
import { BuilderCoreConfigService } from './core-config.service';

@Injectable({ providedIn: 'root' })
export class CapturumBuilderActionService {
  private actionExecutionMessages = new Subject<ActionExecutionMessage>();

  constructor(
    private injector: Injector,
    private builderCoreConfig: BuilderCoreConfigService,
    private contextService: CapturumBuildersContextService,
    private translateService: TranslateService,
    private toastService: ToastService,
  ) {
  }

  public executeAction(action: BuilderAction, response: any, configurationKey: string, contextKey?: string): void {
    const context = this.contextService.getContextByKey(contextKey || configurationKey);

    if (action) {
      if (this.builderCoreConfig.actions[action.type]) {
        const instance = this.injector.get(this.builderCoreConfig.actions[action.type]);

        if (instance) {
          const actionResponse = instance.execute({
            ...action.options,
            configurationKey,
            contextKey,
            action
          }, response, context);

          if (isObservable(actionResponse)) {
            actionResponse.pipe(take(1)).subscribe((actionResult) => {
              this.broadcastActionExecuted(action, response, actionResult);
            });
          } else {
            this.broadcastActionExecuted(action, response, actionResponse);
          }
        }
      } else {
        throw Error(`Action "${action.type}" not found. Make sure to provide the action in the forRoot config of the CapturumBuilderCoreModule`);
      }
    }
  }

  /**
   * Broad cast action executed
   *
   * @description This function will broadcast an executeds action. It will send the action, item and response if available
   * @param action - The action which was executed
   * @param item - The item on which the action was executed
   * @param response - The response of the action
   */
  public broadcastActionExecuted(action: BuilderAction, item: any, response: any): void {
    this.actionExecutionMessages.next({ action, item, response });

    if (response) {
      this.actionSuccessNotification(action);
    }
  }

  /**
   * Retrieve action executions
   *
   * @description Every time an action gets executed a message will be broadcasted with the action, the item and the response of the action (if available)
   * Use this function to subscribe to these actions
   *
   * @return Observable<ActionExecutionMessage>
   */
  public getActionExecutions(): Observable<ActionExecutionMessage> {
    return this.actionExecutionMessages.asObservable();
  }

  /**
   * Retrieve action executions by key
   *
   * @description Every time an action gets executed a message will be broadcasted with the action, the item and the response of the action (if available)
   * Use this function to subscribe to a specific action by providing the action key
   *
   * @param actionKey - The key of the action
   *
   * @return Observable<ActionExecutionMessage>
   */
  public getActionExecutionByActionKey(actionKey: string): Observable<ActionExecutionMessage> {
    return this.actionExecutionMessages.asObservable().pipe(filter((actionMessage) => actionMessage.action.key === actionKey));
  }

  /**
   * Retrieve action executions
   *
   * @description Every time an action gets successfully executed an we have message will show as success notification with that message
   *
   */
  public actionSuccessNotification(action: BuilderAction): void {
    const message: string = action?.options?.message || action?.message;

    if (message) {
      const title = this.translateService.instant('toast.success.title');

      this.toastService.success(title, message);
    }
  }
}
