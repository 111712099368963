<div class="back-btn text-left page__main main ml-5 mb-n3 mt-2">
  <a routerLink="/config"><i class="fa fa-chevron-left"></i> {{ 'button.back-to-overview' | translate }}</a>
</div>

<cap-layout-details [title]="'jobs.status.label' | translate">
  <ng-template capTemplate="first-content">
    <div class="jobs-status">
      <h4 class="title">{{ 'jobs.pending.label' | translate }}: {{ pendingJobsCount$ | async }}</h4>
      <h4 class="title">{{ 'jobs.failed.label' | translate }}</h4>
      <cap-info-table [columns]="tableColumns"
                      #infoTable
                      [stateKey]="'failed-jobs-list'"
                      (onLazyLoad)="loadTableData($event)"
                      [data]="data$ | async"
                      [sortable]="true"
                      [loading]="loading"
                      [paginator]="paginator$ | async"
                      [lazyLoading]="true">
        <ng-template capTemplate="failed_at" let-item="item">
          {{ item.failed_at | date: dateFormat.generalWithTime }}
        </ng-template>
      </cap-info-table>
    </div>
  </ng-template>
</cap-layout-details>
