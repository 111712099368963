import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { TranslationService } from '../services/translation.service';

@Injectable()
export class TranslationResolver  {
  constructor(private translationService: TranslationService) {}

  /**
   * Make sure the translations are loaded before accessing the route
   */
  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.translationService.loadTranslations();
  }
}
