import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { PermissionService } from '../services/permission.service';

@Injectable()
export class PermissionGuard  {
  constructor(private permissionService: PermissionService) {
  }

  /**
   * Make sure permissions are loaded before accessing the route
   *
   * @param route - activated route
   * @param state - current state of the router
   */
  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.permissionService.loadPermissions();
  }
}
