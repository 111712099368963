import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ListRendererWidgetBase } from '@capturum/builders/list-renderer';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cap-flow-status-widget',
  templateUrl: './flow-status-widget.component.html',
  styleUrls: ['./flow-status-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TranslateModule],
})
export class CapturumFlowStatusWidgetComponent extends ListRendererWidgetBase {}
