import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { CompleteConfig } from '../../complete-config';


@Injectable({ providedIn: 'root' })
export class VersionCheckService {
  public onUpdateVersion = new EventEmitter<{ version: string, hash: string }>();
  // this will be replaced by actual hash post-build.js
  private currentHash: string;

  constructor(private http: HttpClient, private coreConfig: CompleteConfig) {
    if (this.coreConfig?.environment?.currentHash) {
      this.currentHash = this.coreConfig.environment.currentHash;
    } else {
      console.warn('Could not determine currentHash in environment. Make sure to pass the environment with the currentHash');
    }
  }

  /**
   * Checks in every set frequency the version of frontend application
   * @param url
   * @param {number} frequency - in milliseconds, defaults to 30 minutes
   */
  public initVersionCheck(url: string, frequency: number = 1000 * 60 * 30): void {
    if (this.currentHash) {
      setInterval(() => {
        this.checkVersion(url);
      }, frequency);

      this.checkVersion(url);
    }
  }

  /**
   * Will do the call and check if the hash has changed or not
   * @param url
   */
  private checkVersion(url: string): void {
    // timestamp these requests to invalidate caches
    this.http.get(url + '?t=' + new Date().getTime()).pipe(
      take(1))
      .subscribe(
        (response: any) => {
          const hash = response.hash;
          const hashChanged = this.hasHashChanged(this.currentHash, hash);

          // If new version, do something
          if (hashChanged) {
            this.onUpdateVersion.emit({ version: response.version, hash: response.hash });
          }
          // store the new hash so we wouldn't trigger versionChange again
          // only necessary in case you did not force refresh
          this.currentHash = hash;
        },
        (err) => {
          console.error(err, 'Could not get version');
        },
      );
  }

  /**
   * Checks if hash has changed.
   * This file has the JS hash, if it is a different one than in the version.json
   * we are dealing with version change
   * @param currentHash
   * @param newHash
   * @returns {boolean}
   */
  private hasHashChanged(currentHash: string, newHash: string): boolean {
    const defaultPlaceholder = '[{POST_BUILD_ENTERS_HASH_HERE}}'.replace('[', '{');

    if (!currentHash || currentHash === defaultPlaceholder) {
      return false;
    }

    return currentHash !== newHash;
  }
}
