import { Injectable } from '@angular/core';
import { ApiHttpService, ApiIndexResult, ApiService, ListOptions } from '@capturum/api';
import { ListRendererConfig } from '../models/list-renderer-config.model';
import { Observable, of } from 'rxjs';
import { BuilderApiService, CapturumBuildersContextService, responseData } from '@capturum/builders/core';
import { tap } from 'rxjs/operators';
import { SessionStorageService } from '@capturum/ui/api';

@Injectable({
  providedIn: 'root',
})
export class ListRendererApiService extends ApiService<ListRendererConfig> {
  protected endpoint = 'builder/config';

  /**
   * This redundant things resolve error described in this PR
   * PR - https://bitbucket.org/deindruksoftware/capturum-builders/pull-requests/59/fix-add-constructor-to-avoid-error
   */
  constructor(
    public apiHttp: ApiHttpService,
    private readonly builderApiService: BuilderApiService,
    private readonly sessionStorageService: SessionStorageService,
    private readonly contextService: CapturumBuildersContextService,
  ) {
    super(apiHttp);
  }

  public data(path: string, key: string, options?: ListOptions): Observable<ApiIndexResult<any>> {
    let url = path;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.builderApiService.get(key, url);
  }

  public getConfig<T = ListRendererConfig>(contextKey: string, key: string): Observable<ListRendererConfig> {
    const currentContext = this.contextService.getContextByKey(contextKey);
    const listConfig = this.sessionStorageService.getItem(`list_config_${key}`);

    if (
      listConfig &&
      listConfig.config &&
      ((!listConfig.context && !currentContext) || this.areObjectsEqual(listConfig?.context, currentContext))
    ) {
      return of(listConfig.config);
    }

    return this.builderApiService.get(contextKey, `/${this.endpoint}/${key}`).pipe(
      responseData,
      tap((config) => {
        if (config.options?.cache) {
          this.sessionStorageService.setItem(`list_config_${key}`, { config: config, context: currentContext });
        }
      })
    );
  }

  private areObjectsEqual(object1: object, object2: object): boolean {
    return (
      object1 &&
      object2 &&
      Object.keys(object1).length === Object.keys(object2).length &&
      Object.keys(object1).every((key) => object1[key] === object2[key])
    );
  }
}
