import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { RouterModule } from '@angular/router';
import { SettingFormComponent } from './components/setting-form/setting-form.component';

@NgModule({
  declarations: [
    SettingFormComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
  ],
  exports: [
    SettingFormComponent,
  ],
})
export class SettingModule {
}
