import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgModel } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, skip, takeUntil, tap } from 'rxjs/operators';
import { OverlayPanel } from 'primeng/overlaypanel';
import { FilterMatchMode, NumberUtils, ValueAccessorBase } from '@capturum/ui/api';

@Component({
  selector: 'cap-range-filter',
  templateUrl: './range-filter.component.html',
  providers: [ValueAccessorBase.getProviderConfig(RangeFilterComponent)],
})
export class RangeFilterComponent extends ValueAccessorBase<any> implements OnInit, OnDestroy {
  @Input()
  public placeholder: string;
  @Input()
  public debounceTime = 500;
  @Input()
  public icon: string;
  @Input()
  public fromLabel: string | Observable<string> = 'From';
  @Input()
  public toLabel: string | Observable<string> = 'To';
  @Input()
  public emptyMessage: string | Observable<string> = 'Empty is no limit';
  @Input()
  public defaultFrom: number = 0;
  @Input()
  public defaultTo: number = 0;
  @Input()
  public updateOnClose: boolean;
  @Input()
  public isCurrency: boolean;
  @Input()
  public showConfirmButton: boolean = true;
  @Input()
  public confirmButtonText: string = 'Ok';
  @Input()
  public isRangeBetweenDates: boolean = true;
  @Input()
  public mask: string;
  @Input()
  public isNumbers = true;

  @Output()
  public change = new EventEmitter<{ from: number; to: number }>();

  @ViewChild(NgModel)
  public model: NgModel;

  @ViewChild('panel', { static: true })
  public overlayPanelRef: OverlayPanel;

  public form: FormGroup;

  private destroy$ = new Subject();

  constructor(private formBuilder: FormBuilder) {
    super();
  }

  public ngOnInit(): void {
    this.form = this.formBuilder.group({
      to: [this.defaultTo],
      from: [this.defaultFrom],
    });

    this.form.valueChanges
      .pipe(
        filter(Boolean),
        debounceTime(this.debounceTime),
        distinctUntilChanged(),
        skip(1),
        takeUntil(this.destroy$),
        map((change: any) => {
          if (change) {
            change.from = NumberUtils.convertFromCurrency(change.from);
            change.to = NumberUtils.convertFromCurrency(change.to);

            if (this.isRangeBetweenDates) {
              change.from = ` ${change.from}`;
              change.to = ` ${change.to}`;
            }
          }

          return change;
        }),
        tap(() => {
          this.validateToControl();
        }),
      )
      .subscribe((value) => {
        if (this.form.valid && !this.updateOnClose && !this.showConfirmButton) {
          this.change.emit(this.form.value);
        } else if (!value) {
          this.form.reset();
        }
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  public writeValue(value: { matchMode: string, value: string | string[] }): void {
    if (value === undefined) {
      this.form.setValue({ to: null, from: null });
      this.form.updateValueAndValidity();
    } else if (value && value.value && typeof value.value === 'string') {
      if ([FilterMatchMode.LESS, FilterMatchMode.LESS_OR_EQUAL].includes(value.matchMode as FilterMatchMode)) {
        this.form.setValue({ to: value.value || null, from: null }, { emitEvent: false });
      } else {
        this.form.setValue({ from: value.value || null, to: null }, { emitEvent: false });
      }
    } else if (value && typeof value.value !== 'string' && value.value && value.value.length) {
      this.form.setValue({ to: value.value[1] || null, from: value.value[0] }, { emitEvent: false });
    } else if (value && value.value) {
      if ([FilterMatchMode.LESS, FilterMatchMode.LESS_OR_EQUAL].includes(value.matchMode as FilterMatchMode)) {
        this.form.setValue({ to: value.value, from: null });
      } else {
        this.form.setValue({ from: value.value, to: null });
      }
    }

    if (value !== null) {
      super.writeValue(value);
    }
  }

  public onHidePanel(): void {
    if (this.updateOnClose) {
      this.emitValues();
    }
  }

  public confirm(): void {
    this.overlayPanelRef.hide();

    if (!this.updateOnClose) {
      this.emitValues();
    }
  }

  public reset(): void {
    this.form.setValue({
      from: null,
      to: null,
    });
  }

  private emitValues(): void {
    if (this.form.valid) {
      const formData = this.form.value;

      if (this.isCurrency) {
        formData.from = formData.from ? NumberUtils.convertFromCurrency(formData.from.toString()) : formData.from;
        formData.to = formData.to ? NumberUtils.convertFromCurrency(formData.to.toString()) : formData.to;
      }

      this.change.emit(formData);
    }
  }

  private validateToControl(): void {
    const fromValue = this.form.value?.from;
    const toValue = this.form.value?.to;

    if (this.hasFromAndToValueAndIsInvalidRange(fromValue, toValue)) {
      const min = { min: +fromValue };

      this.form.get('to').setErrors({ min });
    } else {
      const control = this.form.controls.to;

      if (control) {
        control.setErrors(null);
      }
    }
  }

  private hasFromAndToValueAndIsInvalidRange(fromValue: number, toValue: number): boolean {
    const hasNumbers = this.isNumbers ? this.isNumber(+fromValue) && this.isNumber(+toValue) && +toValue < +fromValue : false;

    return fromValue !== null && toValue !== null && hasNumbers;
  }

  private isNumber(value: number | string) {
    return !isNaN(+value) && typeof value === 'number';
  }
}
