import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { filter, map, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { FormRendererService } from '../../services/form-renderer.service';
import { FormFieldsConfig } from '../../interfaces/form-fields-config';
import { FormRendererApiService } from '../../services/form-renderer-api.service';
import { FormBuilderConfig } from '../../models/form-builder-config.model';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { CapturumTemplateDirective } from '@capturum/ui/api';
import { CapturumBuildersContextService, clone } from '@capturum/builders/core';
import { FormManipulatorService } from '../../services/form-manipulator.service';

enum TemplateTypes {
  header = 'header',
  rightButtons = 'rightButtons',
  leftButtons = 'leftButtons',
}

@Component({
  selector: 'cpb-form-renderer',
  templateUrl: './form-renderer.component.html',
  styleUrls: ['./form-renderer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CapturumFormRendererComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input()
  public showHeader: boolean = true;
  @Input()
  public context: Record<string, any>;
  @Input()
  public contextKey: string;
  @Input()
  public useCache: boolean;
  @Input()
  public cacheWithoutCheckingContext: boolean;
  public form: FormGroup = new FormGroup({});
  public description!: string;
  public fields$!: Observable<FormFieldsConfig>;
  public formModel: any;
  public formOptions: FormlyFormOptions = {
    formState: {
      readonly: false,
    },
  };
  public loading = true;
  public leftButtonTemplate: TemplateRef<any>;
  public rightButtonTemplate: TemplateRef<any>;
  @ContentChildren(CapturumTemplateDirective)
  public templates: QueryList<CapturumTemplateDirective>;
  public headerTemplate: TemplateRef<any>;
  private destroy$ = new Subject<void>();
  private fieldsReadySubject = new BehaviorSubject<boolean>(false);
  private updateFormFieldsSubject = new BehaviorSubject<FormlyFieldConfig[]>(null);
  private fieldConfig: FormlyFieldConfig[];
  private contextIsSet: boolean;

  constructor(
    private readonly formRendererService: FormRendererService,
    private readonly formRendererApiService: FormRendererApiService,
    private readonly cdr: ChangeDetectorRef,
    private readonly contextService: CapturumBuildersContextService,
    private readonly formManipulatorService: FormManipulatorService,
  ) {
  }

  private _formKey!: string;

  get formKey(): string {
    return this.contextKey || this._formKey;
  }

  @Input()
  set formKey(value: string) {
    if (value) {
      this._formKey = value;
    }
  }

  private _formConfiguration: FormBuilderConfig;

  get formConfiguration(): FormBuilderConfig {
    return this._formConfiguration;
  }

  @Input()
  set formConfiguration(value: FormBuilderConfig) {
    if (value) {
      this._formConfiguration = value;
      this.fetchFields();
    }
  }

  private _readOnly: boolean = false;

  get readOnly(): boolean {
    return this._readOnly;
  }

  @Input()
  set readOnly(value: boolean) {
    this._readOnly = value;
    this.formOptions.formState.readonly = value;
  }

  private _modelId!: string;

  get modelId(): string {
    return this._modelId;
  }

  @Input()
  set modelId(value: string) {
    if (value) {
      this._modelId = value;
      this.getFormSourceData();
    }
  }

  private _defaultValue!: any;

  get defaultValue(): any {
    return this._defaultValue;
  }

  @Input()
  set defaultValue(value: any) {
    this._defaultValue = value;

    if (!this.modelId) {
      this.formModel = { ...value };
    }
  }

  public ngOnInit(): void {
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.formRendererService.updateFormValueState(this.formKey, this.form.getRawValue());
    });

    this.form.statusChanges.pipe(takeUntil(this.destroy$)).subscribe((status) => {
      this.formRendererService.updateFormStatus(this.formKey, status === 'VALID');
    });
  }

  public ngAfterViewInit(): void {
    this.assignTemplates();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!this.contextIsSet && this.formKey && !!this.context) {
      this.contextService.setContext(this.contextKey || this.formKey, this.context);
      this.contextIsSet = true;

      this.fetchFields();
    } else if (!this.contextIsSet && (this.formKey)) {
      this.fetchFields();
      this.contextIsSet = true;
    } else if (
      this.contextIsSet && changes.formKey
    ) {
      this.fetchFields();
    }
  }

  public ngOnDestroy(): void {
    this.formRendererService.resetFormValue(this._formKey);
    this.contextService.clearContextByKey(this.formKey);
    this.formManipulatorService.resetFormManipulations(this.formKey);
    this.destroy$.next();
    this.destroy$.complete();
    this.formRendererService.setSourceValue(this.formKey, null);
  }

  public getFormSourceData(): void {
    this.loading = true;

    combineLatest([
      this.formRendererService.getFormConfigurationByKey(this.formKey).pipe(
        filter(Boolean),
        take(1),
      ),
      this.fieldsReadySubject.asObservable().pipe(
        filter(Boolean),
        take(1),
        takeUntil(this.destroy$),
      )]).pipe(
      switchMap(([configuration, ready]: [FormBuilderConfig, boolean]) => {
        const formConfiguration = ready && this._formConfiguration ? this._formConfiguration : configuration;
        return this.formRendererApiService.getSource(formConfiguration, this.modelId, this._formKey).pipe(map((source) => [source, configuration]));
      })).subscribe(([response, config]: [FormBuilderConfig, any]) => {
      response = { [config.identifier_field]: this.modelId, ...response };
      if (config && response.hasOwnProperty(config.identifier_field)) {
        this.form.setControl(config.identifier_field, new FormControl(response[config.identifier_field]));
      }

      this.formRendererService.setSourceValue(this.formKey, response);
      this.formModel = {
        ...clone(response),
        ...this.defaultValue,
      };
      this.loading = false;

      this.cdr.detectChanges();
    });
  }

  public fetchFields(): void {
    this.loading = true;
    this.fields$ = !this._formConfiguration ? this.formRendererService.getFields(this._formKey, this.defaultValue, this.contextKey, {
        cache: this.useCache,
        cacheWithoutCheckingContext: this.cacheWithoutCheckingContext
      }).pipe(
        tap((config) => {
          this.fieldConfig = config?.inputs;
          this.fieldsReadySubject.next(true);

          if (this.formModel || !this.modelId) {
            this.loading = false;
          }
        })) :
      of(this.formConfiguration).pipe(
        map((config) => this.formRendererService.getFieldConfig(config, this.formKey, this.defaultValue)),
        switchMap((fields) => {
          return this.updateFormFieldsSubject.asObservable().pipe(
            filter(Boolean),
            startWith(fields?.inputs),
            map((inputs) => ({ ...fields, inputs })),
          );
        }),
        tap((config: FormFieldsConfig) => {
          this.fieldsReadySubject.next(true);
          this.formRendererService.setConfig(this._formConfiguration, this.formKey);
          this.fieldConfig = config?.inputs;
          this.loading = false;
        }),
      );
  }

  public updateFormConfig(callback: (formConfig: FormlyFieldConfig[]) => FormlyFieldConfig[]): void {
    this.updateFormFieldsSubject.next(callback(this.fieldConfig));
  }

  private assignTemplates(): void {
    if (this.templates) {
      this.templates.forEach((template) => {
        switch (template.getType()) {
          case TemplateTypes.header:
            this.headerTemplate = template.template;
            break;
          case TemplateTypes.rightButtons:
            this.rightButtonTemplate = template.template;
            break;
          case TemplateTypes.leftButtons:
            this.leftButtonTemplate = template.template;
            break;
        }
      });
    }
  }
}
