import { Component, Input } from '@angular/core';
import { ListRendererWidgetBase } from '../../base/list-renderer-widget-base';

@Component({
  selector: 'cpb-list-renderer-widget-base-data',
  template: `
    <p>
      {{ (item && field && item[field]) ? (('base-data.' + item[field]) | translate) : default }}
    </p>
  `,
})
export class ListRendererWidgetBaseDataComponent extends ListRendererWidgetBase {
  @Input() public default: string = '-';
}
