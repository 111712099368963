import { Pipe, PipeTransform } from '@angular/core';
import { ListRendererFilterOptions } from '@capturum/builders/core';
import { MapItem } from '@capturum/ui/api';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ListRendererApiService } from '../services/list-renderer-api.service';

@Pipe({
  name: 'extractOptions',
})
export class ExtractOptionsPipe implements PipeTransform {
  constructor(
    private readonly listBuilderApiService: ListRendererApiService,
  ) {
  }

  public transform(options: ListRendererFilterOptions, key?: string): Observable<MapItem[]> {
    if (options?.action?.endpoint) {
      return this.listBuilderApiService.data(options.action.endpoint, key).pipe(
        map((result) => result.data.map(item => ({ label: item.label, value: item.key }))),
      );
    } else if (options?.items) {
      return of(options?.items.map(item => ({ label: item.label, value: item.key })));
    }

    return of(options?.action.options ?? []);
  }
}
