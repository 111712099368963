import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'concatenate',
})
export class ConcatenatePipe implements PipeTransform {
  public transform(value: any, properties: string[]): any {
    if (properties) {
      return properties.reduce((acc, item) => {
        acc += value[item] ? value[item] + ' ' : '';

        return acc;
      }, '').trim();
    }

    return value;
  }
}
