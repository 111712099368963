import { switchMap } from 'rxjs/operators';
import { ApiHttpService, ApiIndexResult, ApiService } from '@capturum/api';
import { Observable, of } from 'rxjs';
import { FormBuilderConfig } from '@capturum/builders/form-renderer';
import { Injectable } from '@angular/core';
import { BuilderApiService, responseData } from '@capturum/builders/core';
import { ListRendererConfig } from '@capturum/builders/list-renderer';

@Injectable({ providedIn: 'root' })
export class BuilderUiApiService extends ApiService<any> {
  protected endpoint = 'builder/ui/render/config';

  constructor(
    apiHttp: ApiHttpService,
    private builderApiService: BuilderApiService,
  ) {
    super(apiHttp);
  }

  public getFormBuilderConfiguration(key: string): Observable<FormBuilderConfig | ListRendererConfig> {
    return this.apiHttp.get(`/${this.endpoint}/${key}`).pipe(responseData);
  }

  public getConfigPreview(id: string, type?: string): Observable<FormBuilderConfig | ListRendererConfig> {
    return this.builderApiService.get('config-preview', `/builder/ui/render/preview/${id}`).pipe(
      switchMap(configuration => {
        if (configuration?.data) {
          const splitEndpoint = configuration.data.source.endpoint.split('/');
          const sourceEndpointKey = splitEndpoint[splitEndpoint.length - (type === 'list' ? 1 : 2)];
          const typeKey = `${type}_${sourceEndpointKey}`;
          configuration.data.source.endpoint = configuration.data.source.endpoint.replace(sourceEndpointKey, typeKey);

          if (configuration.data.filters?.fields.length) {
            configuration.data.filters.fields.map(field => {
              field.options.action.endpoint = field.options.action.endpoint.replace(sourceEndpointKey, typeKey);
              return field;
            });
          }

          if (configuration.data?.groups) {
            configuration.data.groups = configuration.data?.groups.map((group) => {
              group.inputs = group.inputs.map((input) => {
                if (input.options?.action?.endpoint) {
                  input.options.action.endpoint = input.options.action.endpoint.replace(sourceEndpointKey, typeKey);
                }

                return input;
              });

              return group;
            })
          }

          if (configuration.data.submit) {
            configuration.data.submit.endpoint = configuration.data.submit.endpoint.replace(sourceEndpointKey, typeKey);
          }


          return of(configuration);
        }

        return null;
      }),
      responseData,
    );
  }

  public getProjectDatabaseConfigurationList(): Observable<ApiIndexResult<any>> {
    return this.apiHttp.get('/builder/config');
  }
}
