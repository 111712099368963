<div class="builder-detail-template">
  <div class="builder-detail-template__messages">
    <div *ngFor="let message of messages" class="builder-message" [ngClass]="message.type">
      <i
        class="fas"
        [ngClass]="{
          'fa-check-circle': message.type === 'success',
          'fa-exclamation-circle': message.type === 'warning',
          'fa-info-circle': message.type === 'info'
        }"></i>
      {{ message.message }}
    </div>
  </div>

  <div class="builder-detail-template__header">
    <h2>{{ title }}</h2>

    <div class="builder-detail-template__header-actions">
      <cap-button [label]="'button.submit' | translate" styleClass="primary" (click)="submit()"></cap-button>
      <cap-button [label]="'button.cancel' | translate" styleClass="secondary" (click)="cancel()"></cap-button>
      <cap-button
        [label]="'builders.button.set-config' | translate"
        styleClass="secondary"
        *ngIf="!hideSetDevelopmentConfig"
        (click)="setConfig()"></cap-button>
      <cap-button
        [label]="'builders.button.preview' | translate"
        styleClass="secondary"
        icon="fas fa-eye"
        (click)="openPreview()">
      </cap-button>
    </div>
  </div>

  <div class="builder-detail-template__content">
    <div class="builder-detail-template__content-center">
      <ng-content></ng-content>
    </div>

    <div class="builder-detail-template__content-right">
      <div class="general-settings-card">
        <cpb-builder-card [title]="'builders.ui.' + type + '-settings' | translate">
          <cap-button
            styleClass="primary"
            (click)="showConfigSidebar = !showConfigSidebar"
            [label]="'builders.ui.button.show-' + type + '-settings' | translate"></cap-button>
        </cpb-builder-card>
      </div>

      <ng-template [ngTemplateOutlet]="contentRightTemplate"> </ng-template>

      <cpb-builder-card [title]="'builders.edit_input.comment' | translate">
        <form [formGroup]="commentForm">
          <cap-textarea formControlName="comment"></cap-textarea>
        </form>
      </cpb-builder-card>
    </div>
  </div>
</div>

<cap-sidebar
  #sidebar
  [(visible)]="showConfigSidebar"
  [title]="'builders.ui.' + type + '-settings' | translate"
  [style]="{ width: '470px' }">
  <div class="form-container">
    <cpb-form-renderer
      #form
      [formConfiguration]="formConfiguration$ | async"
      [formKey]="key"
      [showHeader]="false"
      [modelId]="configId"></cpb-form-renderer>
  </div>

  <ng-template capTemplate="footer">
    <div class="save-actions" *ngIf="formConfiguration$ | async as config">
      <cap-button styleClass="secondary" [label]="'button.cancel' | translate" (click)="closeSidebar()"></cap-button>
      <cap-button
        styleClass="success"
        [label]="'button.submit' | translate"
        (click)="saveConfig(config, form.form.value)"></cap-button>
    </div>
  </ng-template>
</cap-sidebar>
