import { ConfirmationService } from 'primeng/api';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { ActionOptions } from '../interfaces/action-provider.interface';
import { TranslateService } from '@ngx-translate/core';
import { BuilderApiService } from '../services/builder-api.service';
import { ActionProvider } from './../interfaces/action-provider.interface';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationBackendAction implements ActionProvider {

  constructor(
    protected readonly apiHttp: BuilderApiService,
    protected readonly confirmationService: ConfirmationService,
    protected readonly translateService: TranslateService,
  ) {
  }

  public execute(options: ActionOptions, item: any): Observable<any> {
    if (options.endpoint) {
      return new Observable(observer => {
        this.confirmationService.confirm({
          header: options.confirmation_title ?? this.translateService.instant('builders.confirmation.title'),
          message: options.confirmation_text ?? '',
          accept: () => {
            this.apiHttp.post(options.configurationKey, options.endpoint, Array.isArray(item) ? item : [item]).pipe(take(1)).subscribe(response => {
              observer.next({ response });
              observer.complete();
            });
          },
          reject: () => {
            observer.next(null);
            observer.complete();
          },
        });
      });
    } else {
      throw new Error(`Endpoint not provided for action "${options.action.key}". Please provide an endpoint in the configuration of the action`);
    }
  }
}
