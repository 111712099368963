import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'createObjectUrl'
})
export class CreateObjectUrlPipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) { }

  public transform(file: File): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(file));
  }
}
