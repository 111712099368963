import { CapturumPaginatorModule } from '@capturum/ui/paginator';
import { CapturumButtonModule } from '@capturum/ui/button';
import { ReportsLayoutComponent } from './pages/components/reports-layout/reports-layout.component';
import { CapturumFormRendererModule } from '@capturum/builders/form-renderer';
import { CapturumListRendererModule } from '@capturum/builders/list-renderer';
import { CapturumPowerBiReportComponent } from './components/power-bi-report/power-bi-report.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CapturumPowerBIRoutingModule } from './power-bi-routing.module';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { CapturumPowerBIReportsListComponent } from './pages/components/reports-list/reports-list.component';
import { CapturumPowerBIReportsDetailComponent } from './pages/components/reports-detail/reports-detail.component';
import { TranslateModule } from '@ngx-translate/core';
import {
  CapturumPowerBIReportsManageDetailsComponent,
} from './components/reports-manage-details/reports-manage-details.component';
import {
  CapturumPowerBIClientsManageDetailsComponent,
} from './components/clients-manage-details/clients-manage-details.component';
import { CapturumBuilderCoreModule } from '@capturum/builders/core';
import { CapturumPowerBiPaginatorComponent } from './components/power-bi-paginator/power-bi-paginator.component';
import { ToastService } from '@capturum/ui/api';

const defaultComponents = [
  CapturumPowerBiReportComponent,
  CapturumPowerBIReportsListComponent,
  CapturumPowerBIReportsDetailComponent,
  CapturumPowerBIReportsManageDetailsComponent,
  CapturumPowerBIClientsManageDetailsComponent,
  CapturumPowerBiPaginatorComponent,
];

const builderComponentsMap = {
  list_manage_power_bi_reports_add: CapturumPowerBIReportsManageDetailsComponent,
  list_manage_power_bi_reports_edit: CapturumPowerBIReportsManageDetailsComponent,
  list_manage_power_bi_clients_add: CapturumPowerBIClientsManageDetailsComponent,
  list_manage_power_bi_clients_edit: CapturumPowerBIClientsManageDetailsComponent,
};

@NgModule({
  declarations: [
    ReportsLayoutComponent,
    ...defaultComponents,
  ],
  imports: [
    CommonModule,
    CapturumPowerBIRoutingModule,
    PowerBIEmbedModule,
    CapturumFormRendererModule,
    CapturumListRendererModule,
    CapturumBuilderCoreModule.forChild({
      components: builderComponentsMap,
    }),
    CapturumButtonModule,
    CapturumPaginatorModule,
    TranslateModule,
  ],
  exports: [
    ...defaultComponents,
  ],
  providers: [ToastService],
})
export class CapturumPowerBIModule {
}


