import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '@capturum/auth';
import { ApiConfig } from '../api.config';
import { RequestOptions } from '../interfaces/api.interface';

@Injectable()
export class ApiHttpService {
  constructor(
    private config: ApiConfig,
    private http: HttpClient,
    private authService: AuthService,
  ) {
  }

  public getConfig(): ApiConfig {
    return this.config;
  }

  public get<T>(url: string, options?: RequestOptions): Observable<T> {
    return this.http
      .get<T>(`${this.config.baseUrl}${url}`, {
        params: options?.params,
        headers: options?.headers ?? this.getHeaders(),
        responseType: options?.responseType ?? 'json' as any,
      })
      .pipe(catchError((response: Response) => this.handleError(response)));
  }

  public getBlob(url: string, type: string): Observable<Blob> {
    const headers = this.getHeaders();
    headers.delete('Content-Type');

    return this.http
      .get(`${this.config.baseUrl}${url}`, {
        headers: headers,
        responseType: 'blob',
      })
      .pipe(catchError((response: Response) => this.handleError(response)));
  }

  public post<T>(url: string, data: any, options?: RequestOptions): Observable<T> {
    return this.http
      .post<T>(`${this.config.baseUrl}${url}`, data, {
        params: options?.params,
        headers: options?.headers ?? this.getHeaders(),
        responseType: options?.responseType ?? 'json' as any,
      })
      .pipe(catchError((response: Response) => this.handleError(response)));
  }

  public put<T>(url: string, data: any, options?: RequestOptions): Observable<T> {
    return this.http
      .put<T>(`${this.config.baseUrl}${url}`, data, {
        params: options?.params,
        headers: options?.headers ?? this.getHeaders(),
        responseType: options?.responseType ?? 'json' as any,
      })
      .pipe(catchError((response: Response) => this.handleError(response)));
  }

  public patch<T>(url: string, data: any, options?: RequestOptions): Observable<T> {
    return this.http
      .patch<T>(`${this.config.baseUrl}${url}`, data, {
        params: options?.params,
        headers: options?.headers ?? this.getHeaders(),
        responseType: options?.responseType ?? 'json' as any,
      })
      .pipe(catchError((response: Response) => this.handleError(response)));
  }

  public delete<T>(url: string, options?: RequestOptions): Observable<T> {
    return this.http
      .delete<T>(`${this.config.baseUrl}${url}`, {
        params: options?.params,
        headers: options?.headers ?? this.getHeaders(),
        responseType: options?.responseType ?? 'json' as any,
      })
      .pipe(catchError((response: Response) => this.handleError(response)));
  }

  public uploadFiles<T>(url: string, files: File[]): Observable<T> {
    let headers = this.getHeaders();
    const formData = new FormData();

    headers = headers.delete('Content-Type');

    if (files === null || files.length === 0) {
      return this.handleError('No files were added.');
    }

    files.forEach((file, index) => {
      formData.append(`file[${index}]`, file);
    });

    return this.http.post<T>(`${this.config.baseUrl}/${url}`, formData, {
      headers,
      responseType: 'json',
    });
  }

  public getHeaders(customHeaders?: HttpHeaders): HttpHeaders {
    if (customHeaders) {
      return customHeaders;
    }

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });

    const token = this.authService.getToken();
    if (token) {
      headers = headers.append('Authorization', 'Bearer ' + token);
    }

    return headers;
  }

  private handleError(error: any): Observable<any> {
    if (error.status === 401) {
      this.config.onAuthError();

      return EMPTY;
    }

    return throwError(error);
  }
}
