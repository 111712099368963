import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { Router } from '@angular/router';
import { TableAction, TableActionEvent } from '../../models/table-action.model';

@Component({
  selector: 'cap-row-action',
  templateUrl: './row-action.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CapturumRowActionComponent {
  @Input() public item: any;
  @Input() public icon: string = 'fas fa-ellipsis-v';
  @Input() public actions: TableAction[] = [];
  @Input() public isOpen: boolean = false;
  @Output() public clickAction: EventEmitter<TableActionEvent> = new EventEmitter<TableActionEvent>();

  constructor(private router: Router) {
  }

  public toggleMenu(): void {
    this.isOpen = !this.isOpen;
  }

  public executeAction(action: TableAction, item: any): void {
    this.isOpen = false;
    this.clickAction.emit({ action, item });

    if (action && action.url) {
      this.router.navigate([action.url]);
    } else if (action.callback) {
      action.callback(item);
    }
  }
}
