import { FormControl, ValidationErrors } from '@angular/forms';

/**
 * Formly validator for dutch phone or mobile phone number
 *
 * @param control
 */
export function phoneValidator(control: FormControl): ValidationErrors {

  /**
   * Standard dutch phone or mobile phone regular expression
   */
  const phoneRegExp: RegExp = new RegExp(/(^\+[0-9]{2}|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9\-\s]{10}$)/);

  return !control.value || phoneRegExp.test(control.value) ? null : { 'phone': true };
}
