<tr>
  <th *ngIf="reorderableRows" class="reorderable-rows-header"></th>
  <th *ngIf="selectable"
      class="selectable-table-checkbox-column"
      capFrozenColumn
      [frozen]="hasFrozenColumns">
    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
  </th>

  <ng-container *ngFor="let column of columns; let i = index">
    <th *ngIf="!column?.hidden"
        class="cap-info-table__header {{column?.titleClass ? ('th_' + column?.titleClass) : ''}}"
        capFrozenColumn
        [frozenColumnWidth]="column?.frozenColumnWidth"
        [frozen]="column?.frozen">
      <div *ngIf="sortable && (!column.disableSort && (column?.sortable === undefined || column?.sortable?.enabled))
          && column?.title | observablePipe | async;
          else nonsortable"
        class="cap-info-table__column-header {{ column?.titleClass }}"
        [capCellTooltip]="column?.title | observablePipe | async"
        [attr.data-test]="'cap-info-table__column-header_' + (column | sortField)"
        [ngClass]="{'cap-info-table__head--sortable' : column?.sortable}"
        [pSortableColumn]="column | sortField">
        {{ column?.title | observablePipe | async }}
        <cap-sort-icon [field]="column | sortField"></cap-sort-icon>
      </div>

      <ng-template #nonsortable>
        <div
          [capCellTooltip]="column?.title | observablePipe | async"
          class="cap-info-table__column-header {{ column?.titleClass }}"
          [attr.data-test]="'cap-info-table__column-header_' + (column | sortField)">
          {{ column?.title | observablePipe | async }}
        </div>
      </ng-template>

      <ng-container *ngIf="templates | columnTemplate: ('th_' + column.field) as customHeaderTemplate"
                    [ngTemplateOutletContext]="{ column: column, index: i }"
                    [ngTemplateOutlet]="customHeaderTemplate">
      </ng-container>
    </th>
  </ng-container>
</tr>
