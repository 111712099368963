import { Injectable } from '@angular/core';
import { ActiveFilters } from '@capturum/ui/dynamic-filters';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FlowService {
  private actionLogsFilter$ = new Subject<Omit<ActiveFilters, 'matchMode'>>();

  public getActionLogsFilter(): Observable<Omit<ActiveFilters, 'matchMode'>> {
    return this.actionLogsFilter$.asObservable();
  }

  public setActionLogsFilter(filter: Omit<ActiveFilters, 'matchMode'>): void {
    this.actionLogsFilter$.next(filter);
  }
}
