import { Component, EventEmitter, Input, OnInit, Output, QueryList, TemplateRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { LocaleSettings } from 'primeng/calendar';
import { Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { CapturumTemplateDirective, FilterMatchMode, FilterType, TableColumn, TableFilter } from '@capturum/ui/api';

@Component({
  selector: 'cap-table-filters',
  templateUrl: './table-filters.component.html',
  styleUrls: ['./table-filters.component.scss']
})
export class TableFiltersComponent implements OnInit {
  @Input()
  set tableColumns(columns: TableColumn[]) {
    this._tableColumns = columns.filter(column => column.filterable);
  }

  get tableColumns(): TableColumn[] {
    return this._tableColumns;
  }

  @Input()
  public itemsSelectedMessage: string | Observable<string> = '{0} items selected';
  @Input()
  public applyFilterMessage: string | Observable<string> = 'Apply filter';
  @Input()
  public calendarLocale: LocaleSettings;
  @Input()
  public tableFiltersStyleClass?: string = 'row';
  @Input()
  public wrapperFiltersStyleClass?: string = '';
  @Input()
  public filterWrapperStyleClass?: string = 'col-12 col-sm-6 col-md-4 col-xl-3';
  @Input()
  public filterStyleClass: string;
  @Input()
  public afterFiltersTemplate: TemplateRef<string>;
  @Input()
  public rightFiltersTemplate: TemplateRef<string>;
  @Input()
  public activeFilters: TableFilter = {};
  @Input()
  public resettableFilters = false;
  @Input()
  public resettableFiltersButton = true;
  @Input()
  public searchable: boolean = false;
  @Input()
  public searchPlaceholder: string = '';
  @Input()
  public isRangeStrictMode: boolean = true;
  @Input()
  public calendarDefaultRanges: { label: string | Observable<string>, value: any }[] = [];
  @Input()
  public checkedCheckboxValue: 0 | 1 = 1;
  @Input()
  public usePrimeFiltering: boolean;
  @Input()
  public timeout = 400;
  @Input()
  public templates: QueryList<CapturumTemplateDirective>;
  @Input()
  public stateKey: string;

  @Output()
  public onChange = new EventEmitter<TableFilter>();
  @Output()
  public onSearchChange = new EventEmitter<string>();

  public searchControl: FormControl = new FormControl();
  public filterMatchMode = FilterMatchMode;
  public filterType = FilterType;
  public updateFilters$ = new Subject<TableFilter>();

  private _tableColumns: TableColumn[];
  private destroy$ = new Subject();

  public ngOnInit(): void {
    this.searchControl.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(this.timeout)
      )
      .subscribe((searchStr) => {
        this.setFilter('global', FilterMatchMode.LIKE, searchStr);
        this.onSearchChange.emit(searchStr);
      });

    this.updateFilters$.asObservable().pipe(debounceTime(this.timeout), takeUntil(this.destroy$)).subscribe((filters) => {
      this.onChange.emit(filters);
    });

    if (this.searchable) {
      this.setSearchableFilter();
    }
  }

  public onRangeChange(field: string, range: { from: number | string; to: number | string }): void {
    if (range.from && (this.isNullOrUndefined(range.to) || range.to === '')) {
      this.activeFilters[field] = {
        value: range.from,
        matchMode: this.isRangeStrictMode ? FilterMatchMode.GREATER : FilterMatchMode.GREATER_OR_EQUAL
      };
    } else if ((range.to && this.isNullOrUndefined(range.from)) || range.from === '') {
      this.activeFilters[field] = {
        value: range.to,
        matchMode: this.isRangeStrictMode ? FilterMatchMode.LESS : FilterMatchMode.LESS_OR_EQUAL
      };
    } else {
      this.activeFilters[field] = { value: [range.from, range.to], matchMode: FilterMatchMode.BETWEEN };
    }

    if (this.isNullOrUndefined(range.from) && this.isNullOrUndefined(range.to)) {
      delete this.activeFilters[field];
    }

    this.updateFilters$.next(this.activeFilters);
  }

  public setFilter(field: string, matchMode: FilterMatchMode, value: any, filterTimeout: number = 0): void {
    if ((Array.isArray(value) && (value as string[]).length) || value instanceof Date || (typeof value === 'string' && value.length)) {
      const tableColumn = this.tableColumns.find(col => col.filterField === field);

      if (tableColumn?.filterType === FilterType.DATEPICKER && tableColumn?.filterDateRange === true) {
        if (value[1] === null) {
          value[1] = value[0];
        }
      }

      const filterMatchMode = tableColumn?.filterDateRange === true && tableColumn?.filterMatchMode ? tableColumn?.filterMatchMode : matchMode;
      this.activeFilters[field] = { value, matchMode: filterMatchMode };
    } else {
      if (this.usePrimeFiltering) {
        this.activeFilters[field] = { value: null, matchMode: null };
      } else {
        delete this.activeFilters[field];
      }
    }

    if (filterTimeout === 0) {
      this.updateFilters$.next(this.activeFilters);
    } else {
      setTimeout(() => {
        this.updateFilters$.next(this.activeFilters);
      }, filterTimeout);
    }
  }

  /**
   * Parses the date filter value which can be a string or Date
   */
  public getDateFilterValue(field: string): Date | Date[] | undefined {
    if (!this.activeFilters[field] || !this.activeFilters[field].value) {
      return undefined;
    }

    const dateFilterValue = this.activeFilters[field].value;

    if (!Array.isArray(dateFilterValue)) {
      return undefined;
    }

    // If already a date object, return current value, otherwise cast to date object
    if (typeof dateFilterValue[0] !== 'string') {
      return dateFilterValue;
    }

    this.activeFilters[field].value = [new Date(dateFilterValue[0]), dateFilterValue[1] ? new Date(dateFilterValue[1]) : null];

    return this.activeFilters[field].value;
  }

  public resetTableFilter(field: string): void {
    if (this.usePrimeFiltering) {
      this.activeFilters[field] = { value: null, matchMode: null };
      this.onChange.emit(this.activeFilters);
      delete this.activeFilters[field];
    } else {
      delete this.activeFilters[field];
      this.onChange.emit(this.activeFilters);
    }
  }

  public setCheckboxFilter(field: string, value: boolean, checkedCheckboxValue: 1 | 0): void {
    if (value) {
      this.setFilter(field, FilterMatchMode.IN, [+!checkedCheckboxValue, checkedCheckboxValue]);
    } else {
      this.setFilter(field, FilterMatchMode.IN, [checkedCheckboxValue]);
    }
  }

  public onMultiSelectFilterChange(tableColumn: TableColumn, event: any): void {
    if (tableColumn.filterShowApplyButton) {
      return;
    }

    this.setFilter(tableColumn.filterField, FilterMatchMode.IN, event.value);
  }

  public setMultiSelectFilter(tableColumn: TableColumn, value: string[]): void {
    if (!tableColumn.filterShowApplyButton) {
      return;
    }

    let currentFilterValue = null;

    if (this.activeFilters[tableColumn.filterField]) {
      currentFilterValue = this.activeFilters[tableColumn.filterField].value;
    }

    if (currentFilterValue !== value) {
      this.setFilter(tableColumn.filterField, FilterMatchMode.IN, value);
    }
  }

  public setSearchableFilter(): void {
    const localStorageItem = JSON.parse(localStorage.getItem(this.stateKey));

    if (localStorageItem) {
      this.searchControl.patchValue(localStorageItem?.filters?.global?.value, { emitEvent: false });
    }
  }

  private isNullOrUndefined(value: any): boolean {
    return value === undefined || value === null;
  }
}
