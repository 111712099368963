import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { SettingService } from '../services/setting.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[capIfSetting]',
})
export class IfSettingDirective implements OnInit, OnDestroy {
  @Input()
  set capIfSetting(value: string) {
    this.setting = value;
  }

  get capIfSetting(): string {
    return this.setting;
  }

  private setting: string;
  private hasView: boolean = false;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private settingService: SettingService,
  ) {
  }

  public ngOnInit(): void {
    this.changeDisplay();

    this.settingService.settingsChanged$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.changeDisplay());
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Toggle the display of the element
   *
   * @return void
   */
  private changeDisplay(): void {
    const display = this.settingService.getValue(this.setting);

    if (display && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!display && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}
