<div class="pagination" *ngIf="paginatorForm" [ngClass]="{ 'pagination--hidden': loading }" [formGroup]="paginatorForm">
  <div>
    <div class="pagination__per-page">
      <div class="pagination__showing">
        {{ 'table.showing' | translate }}
      </div>

      <div class="pagination__changer">
        <cap-dropdown
          [options]="perPageOptions"
          optionLabel="label"
          optionValue="value"
          formControlName="perPage"
          (change)="changePerPage?.emit($event.value)"
          [sortAlphabetically]="false"
          [appendTo]="'body'"
          sortBy="value">
        </cap-dropdown>
      </div>

      <div class="pagination__entries">
        {{ 'table.paginator.of' | translate }} {{ paginator?.total }} {{ 'table.entries' | translate }}
      </div>
    </div>
  </div>

  <div>
    <cap-paginator [pagination]="paginator" (changePage)="changePage.emit($event)"> </cap-paginator>
  </div>
</div>

<div class="pagination--skeleton" [ngClass]="{ 'pagination--visible': loading }">
  <div>
    <div class="pagination__per-page">
      <cap-skeleton width="58px" height="18px"></cap-skeleton>
      <cap-skeleton width="90px" height="32px"></cap-skeleton>
      <cap-skeleton width="87px" height="18px"></cap-skeleton>
    </div>

    <div>
      <cap-skeleton width="240px" height="32px"></cap-skeleton>
    </div>
  </div>
</div>
