<ng-container *ngIf="fields$ | async as fields">
  <ng-container [ngTemplateOutlet]="headerTemplate || defaultHeader"
                [ngTemplateOutletContext]="{$implicit: fields}"></ng-container>

  <ng-template #defaultHeader let-config>
    <cpb-form-renderer-header *ngIf="showHeader"
                              [leftButtonTemplate]="leftButtonTemplate"
                              [rightButtonTemplate]="rightButtonTemplate"
                              [formKey]="formKey"
                              [callbacks]="config.callbacks"
                              [title]="config.title">
    </cpb-form-renderer-header>
  </ng-template>

  <ng-container *ngxPermissionsOnly="fields.permissions">
    <formly-form *ngIf="!loading && (!modelId || (modelId && formModel))"
                 [attr.data-test]="'builder-' + formKey"
                 [fields]="fields.inputs"
                 [form]="form"
                 [model]="formModel"
                 [options]="formOptions">
    </formly-form>
  </ng-container>
</ng-container>

<ng-container *ngIf="loading">
  <div class="form__skeleton" #skeletonRef>
    <ng-content select="[skeleton]"></ng-content>
  </div>

  <div *ngIf="!skeletonRef?.children?.length">
    <cap-skeleton height="36px"></cap-skeleton>
    <cap-skeleton height="36px"></cap-skeleton>
    <cap-skeleton height="36px"></cap-skeleton>
  </div>
</ng-container>
