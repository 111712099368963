<div class="dashboard">
  <div class="dashboard__side">
    <app-sidebar></app-sidebar>
  </div>

  <div class="dashboard__content">
    <div class="dashboard__content-page">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
