import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CapturumSelectButtonComponent } from './select-button.component';
import { CapturumSharedModule } from '@capturum/ui/api';
import { SelectButtonModule } from 'primeng/selectbutton';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    CapturumSelectButtonComponent
  ],
  exports: [
    CapturumSelectButtonComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SelectButtonModule,
    CapturumSharedModule
  ]
})
export class CapturumSelectButtonModule {
}
