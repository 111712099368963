import { Injectable } from '@angular/core';
import { ActionProvider } from '../interfaces/action-provider.interface';
import { OpenContainerAction } from './open-container.action';
import { DynamicDialogComponent } from 'primeng/dynamicdialog';

@Injectable({ providedIn: 'root' })
export class OpenDialogAction implements ActionProvider {
  constructor(
    private openContainerAction: OpenContainerAction,
  ) {
  }

  public execute(options: any, item: any): void {
    this.openContainerAction.execute({ ...options, containerType: DynamicDialogComponent }, item);
  }
}
