<div class="range-filter-button" (click)="panel.toggle($event)">
  <div>
    <i *ngIf="icon" [class]="icon + ' pre-value-icon'"></i>

    <ng-container *ngIf="form.value.from || form.value.to; else emptyValue">
      <span *ngIf="form.value.from && form.value.to; else singleValue">
        {{ form.get('from').value }}
        <i class="fas fa-arrow-right"></i> {{ form.get('to').value }}
      </span>

      <ng-template #singleValue>
        <i [class]="'fas ' + (form.value.from ? 'fa-greater-than' : 'fa-less-than')"></i> {{ form.get('to').value }}
        {{ form.get('from').value }}
      </ng-template>
    </ng-container>

    <ng-template #emptyValue>
      <span class="filter-placeholder">
        {{ placeholder }}
      </span>
    </ng-template>

    <i class="pi pi-chevron-down float-right"></i>
  </div>
</div>

<p-overlayPanel styleClass="range-filter-panel" appendTo="body" #panel [baseZIndex]="10" (onHide)="onHidePanel()">
  <form [formGroup]="form">
    <div class="form-group">
      <label>{{ fromLabel | observablePipe | async }}</label>
      <cap-input-mask
        *ngIf="mask"
        [placeholder]="emptyMessage | observablePipe | async"
        formControlName="from"
        [mask]="mask"
        type="text">
      </cap-input-mask>

      <cap-input *ngIf="!mask" [placeholder]="emptyMessage | observablePipe | async" formControlName="from" type="text">
      </cap-input>
    </div>

    <div class="form-group">
      <label>{{ toLabel | observablePipe | async }}</label>
      <cap-input-mask
        *ngIf="mask"
        type="text"
        [placeholder]="emptyMessage | observablePipe | async"
        formControlName="to"
        [mask]="mask">
      </cap-input-mask>

      <cap-input *ngIf="!mask" type="text" [placeholder]="emptyMessage | observablePipe | async" formControlName="to">
      </cap-input>
    </div>

    <cap-button *ngIf="showConfirmButton" [label]="confirmButtonText" styleClass="primary w-100" (click)="confirm()">
    </cap-button>
  </form>
</p-overlayPanel>
