export interface NotificationItem {
  icon?: string;
  iconColor?: string;
  title: string;
  subTitle: string;
  body: string;
  bodyIsCollapsable?: boolean;
  read: boolean;
  date?: Date;
}
