import { InfoTableCellEditorComponent } from './components/info-table-cell-editor/info-table-cell-editor.component';
import { CapturumCellTooltipDirective } from './directives/cell-tooltip.directive';
import { TooltipModule } from 'primeng/tooltip';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CapturumInfoTableComponent } from './info-table.component';
import { TableModule } from 'primeng/table';
import { CapturumSharedModule } from '@capturum/ui/api';
import { CapturumInputModule } from '@capturum/ui/input';
import { CapturumDropdownModule } from '@capturum/ui/dropdown';
import { CapturumSortIconComponent } from './components/sorticon.component';
import { FormsModule } from '@angular/forms';
import { CellDataComponent } from './components/cell-data/cell-data.component';
import { ColumnsByTypePipe } from './pipes/columns-by-type.pipe';
import { InfoTableSkeletonComponent } from './components/info-table-skeleton/info-table-skeleton.component';
import { InfoTableRowComponent } from './components/info-table-row/info-table-row.component';
import { InfoTableHeaderComponent } from './components/info-table-header/info-table-header.component';
import { CapturumSkeletonModule } from '@capturum/ui/skeleton';
import { SortFieldPipe } from './pipes/sort-field.pipe';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CheckboxModule } from 'primeng/checkbox';
import { CapturumFrozenColumnDirective } from './directives/frozen-column.directive';
import { IsTableRowSelectedPipe } from './pipes/is-table-row-selected.pipe';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TranslateModule } from '@ngx-translate/core';

const BASE_PIPES = [
  ColumnsByTypePipe,
  SortFieldPipe,
  IsTableRowSelectedPipe,
];

const BASE_COMPONENTS = [
  CapturumInfoTableComponent,
  CapturumSortIconComponent,
  CellDataComponent,
  InfoTableSkeletonComponent,
  InfoTableRowComponent,
  InfoTableHeaderComponent,
  InfoTableCellEditorComponent,
];

const BASE_DIRECTIVES = [
  CapturumFrozenColumnDirective,
  CapturumCellTooltipDirective,
];

@NgModule({
  declarations: [
    ...BASE_COMPONENTS,
    ...BASE_PIPES,
    ...BASE_DIRECTIVES
  ],
  exports: [
    CapturumInputModule,
    ...BASE_COMPONENTS,
    ...BASE_PIPES,
    CapturumCellTooltipDirective,
  ],
  imports: [
    CommonModule,
    TableModule,
    CapturumInputModule,
    CapturumSharedModule,
    CapturumDropdownModule,
    DragDropModule,
    FormsModule,
    TranslateModule,
    CapturumSkeletonModule,
    OverlayPanelModule,
    CheckboxModule,
    TooltipModule,
  ],
})
export class CapturumInfoTableModule {
}
