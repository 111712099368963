import { Action, createSelector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ListRendererConfig } from '../models/list-renderer-config.model';
import { SetListConfiguration } from './list-renderer.actions';

export interface ListRendererStateModel {
  configurations: Record<string, ListRendererConfig>;
}

@State<ListRendererStateModel>({ name: 'listRenderer', defaults: { configurations: {} } })
@Injectable()
export class ListRendererState {
  public static configurationByKey(key: string): (state: ListRendererStateModel) => ListRendererConfig {
    return createSelector([ListRendererState], (state: ListRendererStateModel) => {
      return state.configurations[key];
    });
  }

  @Action(SetListConfiguration)
  public setFormConfiguration(ctx: StateContext<ListRendererStateModel>, {
    key,
    configuration,
  }: SetListConfiguration): void {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      configurations: {
        ...state.configurations,
        [key]: configuration,
      },
    });
  }
}
