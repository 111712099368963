import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CapturumPaginatorComponent } from './paginator.component';
import { PaginatorModule } from 'primeng/paginator';

@NgModule({
  declarations: [
    CapturumPaginatorComponent
  ],
  exports: [
    CapturumPaginatorComponent
  ],
  imports: [
    CommonModule,
    PaginatorModule
  ]
})
export class CapturumPaginatorModule {
}
