import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CapturumHeaderComponent } from './header.component';
import { CapturumSharedModule } from '@capturum/ui/api';

@NgModule({
  declarations: [
    CapturumHeaderComponent
  ],
  exports: [
    CapturumHeaderComponent
  ],
  imports: [
    CapturumSharedModule,
    CommonModule
  ]
})
export class CapturumHeaderModule {
}
