import { Injectable } from '@angular/core';
import { ApiHttpService, ApiIndexResult, ApiService, ListOptions } from '@capturum/api';
import { TranslationKeyApiModel } from '../models/translation-key.api-model';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { AuthService } from '@capturum/auth';

@Injectable({ providedIn: 'root' })
export class TranslationKeyService extends ApiService<TranslationKeyApiModel> {
  protected endpoint = 'translation-key';

  constructor(
    public apiHttp: ApiHttpService,
    protected authService: AuthService,
  ) {
    super(apiHttp);
  }

  public index<T = TranslationKeyApiModel>(options?: ListOptions): Observable<ApiIndexResult<T>> {
    let search = options.search;
    const globalFilter = options.filters.find((filter) => {
      return filter.field === 'global';
    });

    if (globalFilter && !options.search) {
      search = globalFilter.value;
    }

    return super.index({ ...options, search });
  }

  public export(): Observable<Blob> {
    return this.apiHttp.getBlob(`/translation/export`, '');
  }

  public import(file: File): Observable<HttpResponse<any>> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.authService.getToken());
    headers.delete('Content-Length');

    const data: FormData = new FormData();
    data.append('file', file);

    return this.apiHttp.post(`/translation/import`, data, { headers });
  }

  public getTranslationIdByKey(options: ListOptions): Observable<ApiIndexResult<TranslationKeyApiModel>> {
    const url = `/${this.endpoint}${this.getOptionsQuery(options)}`;

    return this.apiHttp.get(url);
  }
}
