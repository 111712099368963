<div class="form-element">
  <label *ngIf="(label || hasTooltip) && !floatLabel"
         [style]="{ 'justify-content': label ? 'space-between' : 'flex-end' }">
    <span *ngIf="label">{{ label }}</span>

    <ng-content select=".tooltip-content"></ng-content>
  </label>

  <ng-container *ngIf="!floatLabel; else floatingInput">
    <ng-container *ngTemplateOutlet="defaultTemplate"></ng-container>
  </ng-container>

  <ng-template #floatingInput>
      <span class="p-float-label calendar-float-label">
        <ng-container *ngTemplateOutlet="defaultTemplate"></ng-container>
        <label class="cpb-float-label" htmlFor="float-label">{{ label }}</label>
      </span>
  </ng-template>

  <ng-template #defaultTemplate>
    <p-calendar (onBlur)="onBlur($event)"
                [class.readonly]="readOnly"
                [class.populated]="value"
                [defaultDate]="config?.defaultDate | observablePipe | async"
                (onClearClick)="clearClick.emit($event)"
                (onClose)="close.emit($event)"
                (onFocus)="focus.emit($event)"
                [inputId]="inputId"
                [name]="name"
                [placeholder]="config?.placeholder | observablePipe | async"
                (onInput)="input.emit($event)"
                (onMonthChange)="monthChange.emit($event)"
                (onSelect)="onSelect($event)"
                (onTodayClick)="todayClick.emit($event)"
                [selectOtherMonths]="config?.selectOtherMonths | observablePipe | async"
                [showIcon]="config?.showIcon | observablePipe | async"
                [showOnFocus]="config?.showOnFocus | observablePipe | async"
                (onYearChange)="yearOnChange($event)"
                [ngModel]="calendarValue"
                (ngModelChange)="transformValueChanged($event)"
                [appendTo]="config?.appendTo | observablePipe | async"
                [clearButtonStyleClass]="config?.clearButtonStyleClass | observablePipe | async"
                [dataType]="config?.dataType | observablePipe | async"
                [dateFormat]="config?.dateFormat | observablePipe | async"
                [firstDayOfWeek]="config?.firstDayOfWeek | observablePipe | async"
                [disabledDates]="config?.disabledDates | observablePipe | async"
                [disabledDays]="config?.disabledDays | observablePipe | async"
                [disabled]="disabled"
                [hideOnDateTimeSelect]="config?.hideOnDateTimeSelect | observablePipe | async"
                [showTime]="config?.showTime | observablePipe | async"
                [hourFormat]="config?.hourFormat | observablePipe | async"
                [icon]="config?.icon | observablePipe | async"
                [inline]="config?.inline | observablePipe | async"
                [inputStyleClass]="config?.inputStyleClass | observablePipe | async"
                [inputStyle]="config?.inputStyle | observablePipe | async"
                [keepInvalid]="config?.keepInvalid | observablePipe | async"
                [stepHour]="config?.stepHour | observablePipe | async"
                [stepMinute]="config?.stepMinute | observablePipe | async"
                [stepSecond]="config?.stepSecond | observablePipe | async"
                [maxDateCount]="config?.maxDateCount | observablePipe | async"
                [todayButtonStyleClass]="config?.todayButtonStyleClass | observablePipe | async"
                [maxDate]="config?.maxDate | observablePipe | async"
                [minDate]="config?.minDate | observablePipe | async"
                [monthNavigator]="config?.monthNavigator | observablePipe | async"
                [numberOfMonths]="config?.numberOfMonths | observablePipe | async"
                [panelStyleClass]="config?.panelStyleClass | observablePipe | async"
                [panelStyle]="config?.panelStyle | observablePipe | async"
                [readonlyInput]="config?.readonlyInput | observablePipe | async"
                [touchUI]="config?.touchUI | observablePipe | async"
                [required]="config?.required | observablePipe | async"
                [selectionMode]="config?.selectionMode | observablePipe | async"
                [shortYearCutoff]="config?.shortYearCutoff | observablePipe | async"
                [showButtonBar]="config?.showButtonBar | observablePipe | async"
                [showOtherMonths]="config?.showOtherMonths | observablePipe | async"
                [showSeconds]="config?.showSeconds | observablePipe | async"
                [showWeek]="config?.showWeek | observablePipe | async"
                [showTransitionOptions]="config?.showTransitionOptions | observablePipe | async"
                [hideTransitionOptions]="config?.hideTransitionOptions | observablePipe | async"
                [style]="config?.style | observablePipe | async"
                [styleClass]="config?.styleClass | observablePipe | async"
                [tabindex]="config?.tabindex | observablePipe | async"
                [timeOnly]="config?.timeOnly | observablePipe | async"
                [view]="config?.view | observablePipe | async"
                [yearNavigator]="config?.yearNavigator | observablePipe | async"
                [yearRange]="config?.yearRange | observablePipe | async"
                [autoZIndex]="config?.autoZIndex | observablePipe | async"
                [baseZIndex]="config?.baseZIndex | observablePipe | async"
                [timeSeparator]="config?.timeSeparator | observablePipe | async"
                (onClickOutside)="clickOutside.emit($event)"
                #datepicker>
      <p-footer *ngIf="(config?.showTime | observablePipe | async) === true || showApplyButton || defaultRanges || footerTemplate">
        <ng-container [ngTemplateOutlet]="footerTemplate || defaultTemplate">
        </ng-container>

        <ng-template #defaultTemplate>
          <cap-timepicker *ngIf="(config?.showTime | observablePipe | async) === true" [date]="calendarValue" [timeOnly]="config?.timeOnly | observablePipe | async" [timeLabel]="timeLabel" (timeChange)="setValue($event)">
          </cap-timepicker>

          <div *ngIf="showApplyButton" class="row">
            <div class="col m-2">
              <cap-button (click)="datepicker.hideOverlay()" label="OK" styleClass="primary w-100">
              </cap-button>
            </div>
          </div>

          <ul *ngIf="defaultRanges" class="default-ranges">
            <div class="row">
              <div class="col-6 range-item" *ngFor="let range of defaultRanges">
                <li [ngClass]="{active: range?.value === value }"
                    (click)="setDefaultRange(range)">
                  {{ range?.label | observablePipe | async }}
                </li>
              </div>
            </div>
          </ul>
        </ng-template>
      </p-footer>
    </p-calendar>
  </ng-template>

  <cap-validator *ngIf="control" [control]="control" [customName]="label"></cap-validator>
</div>
