import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cpb-radio-input',
  templateUrl: './radio-input.component.html',
  styleUrls: ['./radio-input.component.scss'],
})
export class CapturumBuilderRadioInputComponent extends FieldType implements OnInit, OnDestroy {
  public radioForm: FormGroup = new FormGroup({
    radio: new FormControl(null),
  });

  private destroy$ = new Subject();

  public ngOnInit(): void {
    this.formControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      this.radioForm.get('radio').setValue(value, { emitEvent: false });
    });

    this.radioForm.get('radio').valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      this.formControl.setValue(value, { emitEvent: false });
      this.formControl.updateValueAndValidity();
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
