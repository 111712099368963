import { ApiService, ApiSingleResult } from '@capturum/api';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ConfigResponse } from '../interfaces/config-response.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PowerBiApiService extends ApiService<ConfigResponse> {
  public endpoint = '/power-bi/report';

  public getEmbedConfig(endpoint?: string, reportId?: string): Observable<ConfigResponse> {
    return this.apiHttp.get<ApiSingleResult<ConfigResponse>>(`${endpoint ?? this.endpoint}/${reportId}/get-token`)
      .pipe(
        map(response => response.data),
      );
  }
}
