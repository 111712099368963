import { IndexedDbModel } from '../../../shared/indexedDb/indexedDb.model';

export class TranslationIndexedDbModel extends IndexedDbModel {
  public static translations: TranslationIndexedDbModel[] = [];

  /**
   * Load current translations in static cache
   *
   * @return Promise<void>
   */
  public static async loadTranslations(): Promise<void> {
    TranslationIndexedDbModel.translations = await TranslationIndexedDbModel.query().toArray();
  }

  // Configuration
  public entity = 'translation';
  public table = 'translations';
  public schema = '++id';
  public sync = false;

  // Fields
  public id: string;
  public value: any;

  // Constructor
  constructor(attributes: Partial<TranslationIndexedDbModel>) {
    super(attributes);
  }
}
