import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'capBaseDataTranslation',
})
export class BaseDataTranslationPipe implements PipeTransform {
  constructor(
    private translateService: TranslateService,
  ) { }

  /**
   * Append the "base-data" prefix to the translation key and translate it
   *
   * @param baseDataValueId: string
   * @returns string
   */
  public transform(baseDataValueId: string): string {
    return this.translateService.instant(`base-data.${baseDataValueId}`);
  }
}
