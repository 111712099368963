import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TableText, TableColumn } from '@capturum/ui/api';

@Component({
  selector: 'cap-table-filters-settings',
  templateUrl: './table-filters-settings.component.html'
})
export class TableFiltersSettingsComponent implements OnInit {
  public items: TableColumn[];
  public texts: TableText;

  constructor(private ref: DynamicDialogRef,
              private config: DynamicDialogConfig) {
  }

  public ngOnInit(): void {
    this.items = [...this.config.data.items];
    this.texts = this.config.data.texts;
  }

  public close(): void {
    this.ref.close(this.items);
  }
}
