<cap-dropdown
  [options]="props.options | formlySelectOptions : field | async"
  [formlyAttributes]="field"
  [formControl]="formControl"
  [placeholder]="props.translatePlaceholder || props.placeholder | observablePipe | async"
  [filterPlaceholder]="props.filterPlaceholder || props.placeholder | observablePipe | async"
  [floatLabel]="(props.label_position | observablePipe | async) === 'floating'"
  [label]="(props.label_position | observablePipe | async) === 'floating' ? props.label : ''"
  [style]="props.style"
  [panelStyle]="props.panelStyle"
  [styleClass]="props.styleClass"
  [panelStyleClass]="props.panelStyleClass"
  [sortAlphabetically]="props.sortAlphabetically"
  [sortBy]="props.sortBy"
  [filterBy]="props.filterBy"
  [emptyFilterMessage]="props.emptyFilterMessage"
  [scrollHeight]="props.scrollHeight"
  [name]="props.name"
  [inputId]="props.inputId"
  [selectId]="props.selectId"
  [dataKey]="props.dataKey"
  [readonly]="props.readonly"
  [autofocus]="props.autofocus"
  [resetFilterOnHide]="props.resetFilterOnHide"
  [dropdownIcon]="props.dropdownIcon"
  [optionLabel]="props.optionLabel"
  [autoDisplayFirst]="props.autoDisplayFirst"
  [showClear]="props.showClear"
  [group]="props.group"
  [optionValue]="props.optionValue"
  [ariaFilterLabel]="props.ariaFilterLabel"
  [withImages]="props.withImages"
  [openOnFocus]="props.openOnFocus"
  [hasTooltip]="props.hasTooltip"
  [editable]="props.editable"
  [virtualScroll]="props.virtualScroll || defaultOptions.props.virtualScroll"
  [filter]="props.filter || defaultOptions.props.filter"
  [appendTo]="props.appendTo"
  [autofocusFilter]="props.autofocusFilter"
  [virtualScrollItemSize]="props.virtualScrollItemSize || defaultOptions.props.virtualScrollItemSize">
</cap-dropdown>
