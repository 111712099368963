import { Component, Input } from '@angular/core';

@Component({
  selector: 'cap-offline-indicator',
  templateUrl: './offline-indicator.component.html',
  styleUrls: ['./offline-indicator.component.scss']
})
export class CapturumOfflineIndicatorComponent {
  @Input() public title: string = 'Offline mode';
}
