import { CommonModule } from '@angular/common';
import { Component, Input, ViewEncapsulation, inject, ViewChild, OnInit } from '@angular/core';
import { CapturumListRendererComponent, CapturumListRendererModule } from '@capturum/builders/list-renderer';
import { filter, take } from 'rxjs';
import { FlowListKey } from '../../enums/flow-list-key.enum';
import { FlowService } from '../../services/flow.service';

@Component({
  selector: 'cap-flow-list',
  templateUrl: './flow-list.component.html',
  styleUrls: ['./flow-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CapturumListRendererModule, CommonModule],
})
export class CapturumFlowListComponent implements OnInit {
  @Input() public builderKey: string;
  @Input() public context: Record<string, string>;
  @ViewChild(CapturumListRendererComponent) public listRenderer: CapturumListRendererComponent;

  private flowService = inject(FlowService);

  public ngOnInit(): void {
    this.flowService.getActionLogsFilter()
      .pipe(
        filter(() => {
          return this.builderKey === FlowListKey.actions;
        }),
        take(1),
      )
      .subscribe((filter) => {
        this.listRenderer?.filtersComponent?.dynamicFiltersRef?.handleUpdateValue({
          field: filter.field,
          value: filter.value,
        });
      });
  }
}
