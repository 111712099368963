import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { SettingService } from '../services/setting.service';
import { takeUntil } from 'rxjs/operators';

/**
 * Directive to apply color styling based on given setting
 */
@Directive({
  selector: '[capSettingColor]',
})
export class SettingColorDirective implements OnDestroy, AfterViewInit {
  @Input() public emcSettingColor: string;
  @Input() public styleProperty = 'backgroundColor';
  @Input() public childSelector: string;

  private destroy$: Subject<void>;

  constructor(
    private element: ElementRef,
    private settingService: SettingService,
  ) {
    this.destroy$ = new Subject();
  }

  public ngAfterViewInit(): void {
    this.changeStyleAttribute();

    this.settingService.settingsChanged$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.changeStyleAttribute());
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Set the given style attribute to the given setting color
   *
   * @return void
   */
  private changeStyleAttribute(): void {
    const settingColor = this.settingService.getValueString(this.emcSettingColor) || '#00999c';

    if (this.childSelector) {
      this.element.nativeElement.querySelector('.ui-dropdown').style[this.styleProperty] = settingColor;
    } else {
      this.element.nativeElement.style[this.styleProperty] = settingColor;
    }
  }
}
