import { PublicSettingService } from './../services/public-setting.service';
import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

@Injectable()
export class PublicSettingResolver  {
  constructor(
    protected publicSettingService: PublicSettingService
  ) {
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    return this.publicSettingService.loadAll();
  }
}
