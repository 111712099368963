import { ChangeDetectionStrategy, Component, Input, TemplateRef, ViewEncapsulation } from '@angular/core';
import { FormBuilderCallback } from '../../models/form-builder-config.model';

@Component({
  selector: 'cpb-form-renderer-header',
  templateUrl: './form-renderer-header.component.html',
  styleUrls: ['./form-renderer-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class FormRendererHeaderComponent {
  @Input()
  public title: string;
  @Input()
  public callbacks: FormBuilderCallback;
  @Input()
  public formKey: string;
  @Input()
  public leftButtonTemplate: TemplateRef<any>;
  @Input()
  public rightButtonTemplate: TemplateRef<any>;
}
