import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';

/**
 * Transform a string|number to an Observable
 */
@Pipe({
  name: 'observablePipe'
})
export class ObservablePipe implements PipeTransform {

  transform(value: any, args?: any): Observable<any> {
    if (!(value instanceof Observable)) {
      return of(value);
    }

    return value;
  }
}
