import { FormlyFieldConfig } from '@ngx-formly/core';
import { InputConfiguration } from '@capturum/builders/core';

export interface FormBuilderGroupConfiguration {
  location: string;
  inputs?: InputConfiguration[];
  label?: string;
  description?: string;
  tooltip?: string;
  collapsable: boolean;
  collapsed: boolean;
  name: string;
}

export interface FormBuilderGroup extends FormlyFieldConfig {
  label?: string;
  description?: string;
  tooltip?: string;
}

export interface FormBuilderInput {
  name: string;
  type: string;
  label: string;
}
