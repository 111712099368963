import { NgModule, ModuleWithProviders, Type, Provider } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ApiConfig } from './api.config';
import { ApiHttpService } from './services/api.http-service';
import { ApiService } from './services/api.service';
import { DateTimezoneInterceptor } from './interceptors/date-timezone.interceptor';

@NgModule({
  imports: [HttpClientModule],
  declarations: [],
  exports: []
})
export class ApiModule {
  static forRoot(config: ApiConfig): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        ApiService,
        ApiHttpService,
        DateTimezoneInterceptor,
        {
          provide: ApiConfig,
          useValue: config
        }
      ]
    };
  }
}
