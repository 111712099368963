import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { forwardRef, Provider } from '@angular/core';

export abstract class ValueAccessorBase<T> implements ControlValueAccessor {

  /**
   * Returns provider configuration that should be defined for every component that extends ValueAccessorBase.
   * @param useExisting: Existing token to return. (equivalent to injector.get(useExisting))
   * @returns Provider
   */
  public static getProviderConfig(useExisting: any): Provider {
    return {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => useExisting),
      multi: true
    };
  }

  public disabled: boolean;

  public changed = new Array<(value: T) => void>();
  public touched = new Array<() => void>();

  public innerValue: T;
  public getValueTransform: (value: T) => any;
  public setValueTransform: (value: any) => any;

  get value(): T {
    return this.innerValue;
  }

  set value(value: T) {
    if (this.innerValue !== value) {
      this.innerValue = value;
    }

    this.changed.forEach(f => f(this.getValueTransform ? this.getValueTransform(value) : value));
  }

  public writeValue(value: T): void {
    this.innerValue = this.setValueTransform ? this.setValueTransform(value) : value;
  }

  public registerOnChange(fn: (value: T) => void): void {
    this.changed.push(fn);
  }

  public registerOnTouched(fn: () => void): void {
    this.touched.push(fn);
  }

  public touch(): void {
    this.touched.forEach(f => f());
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
