export interface BuilderAction<T = any> {
  key: string;
  type: string;
  options: Record<string, T>;
  message?: string;
  ctrlKey?: boolean;
}

export interface ActionExecutionMessage {
  action: BuilderAction;
  item: any;
  response?: any;
}
