<div [class]="styleClass" *ngIf="pages$ | async as pages">
  <div class="power-bi-paginator" *ngIf="showAsList; else paginator">
    <div *ngFor="let page of pages; trackBy: trackByFn" class="paginator-page" [class.active]="page.isActive">
      <cap-button [label]="page.displayName"
                  [styleClass]="page.isActive ? 'primary round' : 'secondary round'"
                  (onClick)="setPage(page)">
      </cap-button>
    </div>
  </div>
</div>

<ng-template #paginator>
  <cap-paginator [pagination]="pagination" (changePage)="setPage($event)"></cap-paginator>
</ng-template>
