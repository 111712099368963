export enum TableColumnTypes {
  ARRAY = 'array',
  BOOLEAN = 'boolean',
  DATE = 'date',
  DATETIME = 'datetime',
  ICON = 'icon',
  ICON_TEXT = 'icon-text',
  ENUM = 'enum',
  FLOAT = 'float',
  NUMBER = 'number',
  PERCENTAGE = 'percentage',
  STRING = 'string',
  DROPDOWN = 'dropdown',
  INPUT_TEXT = 'input',
}
