import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CapturumAutocompleteComponent } from './autocomplete.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CapturumSharedModule } from '@capturum/ui/api';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [CapturumAutocompleteComponent],
  exports: [
    CapturumAutocompleteComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    AutoCompleteModule,
    CapturumSharedModule
  ]
})
export class CapturumAutocompleteModule {
}
