import { Injectable } from '@angular/core';
import { TextInputTypeBuilder } from '../text/text-input-type.builder';
import { InputType } from '@capturum/builders/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { NumberInputConfiguration } from '../../models/number-input-configuration.model';

@Injectable({ providedIn: 'root' })
export class NumberFormatTypeBuilder extends TextInputTypeBuilder {
  protected inputType: string = InputType.number;

  protected appendOptions(input: FormlyFieldConfig, configuration: NumberInputConfiguration): void {
    if (input?.props && configuration?.options?.number) {
      input.props = { ...input.props, ...configuration?.options, ...configuration?.options.number };

      delete input.props.number
    }

    super.appendOptions(input, configuration);
  }
}
