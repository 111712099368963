<div class="checkbox-wrapper form-group" [class.cpb-readonly]="to.readonly" [class.has-error]="showError" [class.position-before]="(to.label_position | observablePipe | async ) === labelPosition.Before">
  <div class="checkbox-wrapper-container" [class.readonly-container]="to.readonly">
    <div class="label-wrapper">
      <label *ngIf="to.label && to.hideLabel !== true" [attr.for]="id" class="checkbox-label">
        {{ to.label }} <span class="cpb-required-indicator" *ngIf="to.required">*</span>
      </label>
    </div>

    <div class="field-wrapper">
      <ng-template #fieldComponent></ng-template>
    </div>

    <cpb-form-tooltip [text]="to.tooltip" class="tooltip-content"></cpb-form-tooltip>
  </div>

  <div class="form-invalid-feedback"
       [style.visibility]="((submitted | async) || showError) && !formState.readonly ? 'visible' : 'hidden'">
    <cbp-validation-message [field]="field">
    </cbp-validation-message>
  </div>

  <small *ngIf="to.description" class="form-text text-muted">
    {{ to.description | observablePipe | async }}
  </small>
</div>
