<quill-editor
  *ngIf="(useQuillHtmlView && !disabled) || !useQuillHtmlView"
  [(ngModel)]="value"
  [class]="'cap-editor ' + styleClass"
  [class.use-quill-view-enabled]="useQuillHtmlView && !disabled"
  [id]="inputId"
  [modules]="config?.modules"
  [formats]="config?.formats"
  [debounceTime]="debounceTime || 500"
  [readOnly]="disabled || config?.readOnly || false"
  [placeholder]="config?.placeholder || ''"
  (onContentChanged)="onChange.emit($event)"
  (onFocus)="onFocus.emit($event)"
  (onBlur)="onBlur.emit($event)">
</quill-editor>

<quill-view-html *ngIf="useQuillHtmlView && disabled" class="use-quill-view" [content]="value" theme="snow">
</quill-view-html>
