import { AfterContentInit, Component, ContentChildren, Input, Optional, QueryList, TemplateRef } from '@angular/core';
import { EditableColumn, EditableRow, Table } from 'primeng/table';
import { CapturumTemplateDirective } from '@capturum/ui/api';

@Component({
  selector: 'cap-info-table-cell-editor',
  template: `
    <ng-container *ngIf="editing">
      <ng-container *ngTemplateOutlet="inputTemplate"></ng-container>
    </ng-container>
    <ng-container *ngIf="!editing">
      <ng-container *ngTemplateOutlet="outputTemplate"></ng-container>
    </ng-container>
  `,
})
export class InfoTableCellEditorComponent implements AfterContentInit {
  @ContentChildren(CapturumTemplateDirective)
  public templates: QueryList<CapturumTemplateDirective>;
  @Input()
  public isEdit: boolean;
  @Input()
  public editTable: boolean;

  public inputTemplate: TemplateRef<any>;
  public outputTemplate: TemplateRef<any>;

  constructor(
    private table: Table,
    @Optional() private editableColumn: EditableColumn,
    @Optional() private editableRow: EditableRow
  ) {}

  public ngAfterContentInit(): void {
    this.templates.forEach((item) => {
      switch (item.getType()) {
        case 'input':
          this.inputTemplate = item.template;
          break;

        case 'output':
          this.outputTemplate = item.template;
          break;
      }
    });
  }

  public get editing(): boolean {
    return (
      (this.table.editingCell &&
        this.editableColumn &&
        this.table.editingCell === this.editableColumn.el.nativeElement) ||
      (this.editableRow && this.table.editMode === 'row' && this.table.isRowEditing(this.editableRow.data)) ||
      (this.editTable && this.isEdit)
    );
  }
}
