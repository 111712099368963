import { Injectable } from '@angular/core';
import { ApiService } from '@capturum/api';
import { LocaleApiModel } from '../models/locale.api-model';

@Injectable()
export class LocaleService extends ApiService<LocaleApiModel> {
  /**
   * Get the currently set language
   */
  public static getCurrentLocaleCode(): string {
    const localeId = localStorage.getItem('currentLocaleCode');

    return localeId ? localeId : JSON.parse(localStorage.getItem('user')).locale_id;
  }

  /**
   * Set the current used locale id
   *
   * @param localeId
   */
  public static setCurrentLocaleCode(localeId: string): void {
    localStorage.setItem('currentLocaleCode', localeId);
  }

  protected endpoint = 'tenant/locale';
}
