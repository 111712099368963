/*
 * Public API Surface of form
 */

export * from './lib/form.service';
export * from './lib/form.component';
export * from './lib/form.module';
export * from './lib/formField.model';

// Formly types
export * from './lib/types/autocomplete-dropdown/autocomplete-dropdown.component';
export * from './lib/types/calendar/calendar.component';
export * from './lib/types/checkbox/checkbox.component';
export * from './lib/types/chips/chips.component';
export * from './lib/types/color-picker/color-picker.component';
export * from './lib/types/dropdown/dropdown.component';
export * from './lib/types/image/image.component';
export * from './lib/types/input/input.component';
export * from './lib/types/input-switch/input-switch.component';
export * from './lib/types/multi-select/multi-select.component';
export * from './lib/types/select-button/select-button.component';
export * from './lib/types/textarea/textarea.component';

// Validators
export * from './lib/validators/coc-validator';
export * from './lib/validators/email-validator';
export * from './lib/validators/iban-validator';
export * from './lib/validators/password-validator';
export * from './lib/validators/phone-validator';
export * from './lib/validators/vat-validator';
export * from './lib/validators/zip-code-validator';
export * from './lib/validators/no-script.validator';
export * from './lib/validators/no-special-characters.validator';

// Wrappers
export * from './lib/wrappers/form-field/form-field.wrapper'

// Pipes
export * from './lib/pipes/select-options-pipe.pipe';
