import { Component, Input, OnInit } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { TwoFactorAuthService, TwoFactorMethod } from '@capturum/auth';
import { Observable } from 'rxjs';

@Component({
  selector: 'emc-auth-authenticator',
  templateUrl: './auth-authenticator.component.html',
  styleUrls: ['./auth-authenticator.component.scss'],
})
export class AuthAuthenticatorComponent implements OnInit {
  public qrcode$: Observable<{ qrCode: SafeResourceUrl, secret: string }>;

  @Input()
  public method: TwoFactorMethod = TwoFactorMethod.authenticator;

  @Input()
  public paragraphNumbers: Record<string, number>;

  constructor(
    private authService: TwoFactorAuthService,
  ) {
  }

  public ngOnInit(): void {
    this.qrcode$ = this.authService.getOneTimePasswordQrCode();
  }
}
