import { entityTextFormKey } from './../../configs/entity-text-form-key.config';
import { EntityDialogAction } from './../../enums/entity-dialog-action.enum';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { ChangeDetectorRef, Component, Inject, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { filter } from 'rxjs/operators';
import { CapturumDialogService } from '@capturum/ui/api';
import { EntityTextInputDialogComponent } from '../entity-text-input-dialog/entity-text-input-dialog.component';
import { Subscription } from 'rxjs';
import { LabelPosition } from '@capturum/builders/core';

@Component({
  selector: 'cpb-entity-text-input',
  templateUrl: './entity-text-input.component.html',
  styleUrls: ['./entity-text-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CapturumBuilderEntityTextInputComponent extends FieldType implements OnDestroy {
  public labelPosition: typeof LabelPosition = LabelPosition;
  private dialogRef: DynamicDialogRef;
  private subscription: Subscription = new Subscription();

  constructor(
    @Inject(CapturumDialogService) private dialogService: CapturumDialogService,
    private cdr: ChangeDetectorRef,
  ) {
    super();
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public openEntityBuilderInfoDialog(): void {
    this.dialogRef = this.dialogService.open(EntityTextInputDialogComponent, {
      data: {
        key: entityTextFormKey[this.to.formKey],
        type: this.to.formKey.split('_').slice(-1).toString(),
      },
      width: '600px',
      height: '350px',
      styleClass: 'entity-text-input-dialog',
      closable: true,
      dismissableMask: true,
      showHeader: false,
    });

    this.subscription.add(
      this.dialogRef.onClose.pipe(
        filter(value => value?.action === EntityDialogAction.submit),
      ).subscribe(res => {
        if (res.data) {
          this.formControl.setValue(res.data);
        }

        this.cdr.detectChanges();
        this.dialogRef.destroy();
      }),
    );
  }
}
