import { Pipe, PipeTransform } from '@angular/core';
import { PageBuilderWidget } from '@capturum/builders/core';

@Pipe({
  name: 'hasWidgets',
})
export class HasWidgetsPipe implements PipeTransform {
  public transform(widget: PageBuilderWidget): boolean {
    return Array.isArray(widget?.widgets);
  }
}
