/**
 * Used for Table List component
 */
import { Observable } from 'rxjs';
import { FilterMatchMode } from '../enums/filter-match-mode.enum';
import { FilterType } from '../enums/filter-type.enum';
import { TableColumnTypes } from '../enums/table-column-types.enum';
import { TooltipPositions } from '../enums/tooltip-positions.enum';
import { TableAction } from './table-action.model';

export class TableColumn {
  /**
   * Get and set the columns
   */
  public static getColumns(columns: TableColumn[]): TableColumn[] {
    const setColumns: TableColumn[] = [];

    columns.forEach((column) => {
      setColumns.push(new TableColumn(column));
    });

    return setColumns;
  }

  /**
   * The property which should be displayed in the column
   */
  public field: string;
  /**
   * The text to be displayed in the column header
   */
  public header: string | Observable<string | number>;
  /**
   * The data type of the column
   */
  public type?: TableColumnTypes | string;
  /**
   * Custom style class to be applied to the column
   */
  public styleClass?: string;
  /**
   * Define whether the column should be sortable
   */
  public sortable?: boolean;
  /**
   * Define what property it should sort by
   */
  public sortField?: string;
  /**
   * Define whether the column is filterable
   */
  public filterable?: boolean;
  /**
   * Define whether the column is readonly
   */
  public readonly?: boolean = true;
  /**
   * Define what field to filter on
   */
  public filterField?: string;
  /**
   * Define the filter type. This will determine what kind of filter will be displayed
   */
  public filterType?: FilterType;
  /**
   * The options to be displayed inside a dropdown or multiselect filter
   */
  public filterOptions?: any;
  /**
   * Define the matchmode it should use when filtering
   */
  public filterMatchMode?: FilterMatchMode;
  /**
   * Define what icon to display when type is icon or icon-text
   */
  public icon?: string;
  /**
   * Define whether the value is an observable so that an async pipe will be used to display the value
   */
  public isObservable?: boolean;
  /**
   * Define the placeholder for the column filter
   */
  public filterPlaceholder?: string | Observable<string | number>;
  /**
   * Define whether the dropdown and multiselect will have an input field in them to search through the options
   */
  public filterSearchable?: boolean = true;
  /**
   * Define whether the date filter should be of type range. Default is single.
   */
  public filterDateRange?: boolean = true;
  /**
   * Define whether the dropdown or multiselect filter should have virtual scroll
   */
  public filterVirtualScroll?: boolean = false;
  /**
   * The options to be displayed inside a row actions
   */
  public actions?: TableAction[];
  /**
   * Define the icon that should be displayed at the filter of the column
   */
  public filterIconClass?: string;
  /**
   * Define whether the calendar should show the button bar or not.
   */
  public filterShowButtonBar?: boolean = true;
  /**
   * Define whether the calendar or multi-select should show an apply button or not.
   */
  public filterShowApplyButton?: boolean = true;
  /**
   * Set an optional minimum date for date filter
   */
  public filterMinDate?: Date;
  /**
   * Set an optional maximum date for date filter
   */
  public filterMaxDate?: Date;
  /**
   * The timeout of when the filter should be emitted
   */
  public filterTimeout?: number;
  /**
   * Target element to attach the overlay of a dropdown or multiselect filter, valid values are "body" or a local ng-template variable of another element
   */
  public appendTo?: string;
  public rangeFilterFromLabel?: string | Observable<string>;
  public rangeFilterToLabel?: string | Observable<string>;
  public rangeFilterEmptyLabel?: string | Observable<string>;
  /**
   * Define whether the column should be visible
   */
  public visible?: boolean;
  /**
   * Define whether the column should be manageable
   */
  public manageable?: boolean;
  /**
   * Define whether the column should be reorderable
   */
  public reorderable?: boolean;
  /**
   * Frozen column (required to add input on table frozenWidth, unFrozenColumnWidth and [scrollable]="true")
   */
  public frozen?: boolean;
  /**
   * The tooltip to be displayed when hovering over the column
   */
  public tooltip?: Observable<string> | string;
  /**
   * The position of the tooltip. Could be top, bottom, left or right
   */
  public tooltipPosition?: TooltipPositions;
  /**
   * Define whether the value of the range filter should be transformed to a currency value
   */
  public currencyFilter?: boolean;
  /**
   * Define whether the range filter should update on close instead of on change
   */
  public updateRangeFilterOnClose?: boolean;
  /**
   * Define whether the range filter should show a confirm button
   */
  public showConfirmRangeButton?: boolean;
  /**
   * Define the text to be displayed in the confirm button at the range filter
   */
  public confirmRangeButtonText?: string = 'Ok';
  /**
   * Define the value it should emit when the checkbox filter is checked
   */
  public checkedCheckboxValue?: 1 | 0 = 1;
  /**
   * Define if range is it between dates or simple numbers
   */
  public isRangeBetweenDates?: boolean = true;

  constructor(data: TableColumn) {
    // Set values
    this.field = data.field;
    this.header = data.header;
    this.type = data.type || TableColumnTypes.STRING;
    this.styleClass = data.styleClass || '';
    this.sortable = data.sortable !== undefined ? data.sortable : true;
    this.sortField = data.sortField || data.field;
    this.filterable = data.filterable !== undefined ? data.filterable : true;
    this.readonly = data.readonly !== undefined ? data.readonly : true;
    this.filterField = data.filterField || data.field;
    this.filterType = data.filterType || FilterType.INPUT;
    this.filterOptions = data.filterOptions || null;
    this.filterMatchMode = data.filterMatchMode || (data.filterDateRange ? FilterMatchMode.BETWEEN : FilterMatchMode.LIKE);
    this.icon = data.icon || '';
    this.isObservable = data.isObservable || false;
    this.filterPlaceholder = data.filterPlaceholder || this.header;
    this.filterSearchable = data.filterSearchable;
    this.filterDateRange = data.filterDateRange;
    this.filterVirtualScroll = data.filterVirtualScroll;
    this.filterIconClass = data.filterIconClass;
    this.filterShowButtonBar = data.filterShowButtonBar;
    this.filterShowApplyButton = data.filterShowApplyButton || true;
    this.filterMinDate = data.filterMinDate || null;
    this.filterMaxDate = data.filterMaxDate || null;
    this.filterTimeout = data.filterTimeout || 0;
    this.appendTo = data.appendTo || 'body';
    this.rangeFilterFromLabel = data.rangeFilterFromLabel || 'From';
    this.rangeFilterToLabel = data.rangeFilterToLabel || 'To';
    this.rangeFilterEmptyLabel = data.rangeFilterEmptyLabel || 'Empty is no limit';
    this.reorderable = data.reorderable || false;
    this.frozen = data.frozen || false;

    this.actions = data.actions || null;
    this.tooltip = data.tooltip || null;
    this.tooltipPosition = data.tooltipPosition || null;
    this.manageable = data.manageable;
    this.currencyFilter = data.currencyFilter;
    this.updateRangeFilterOnClose = data.updateRangeFilterOnClose;
    this.showConfirmRangeButton = data.showConfirmRangeButton;
    this.confirmRangeButtonText = data.confirmRangeButtonText || 'Ok';
    this.isRangeBetweenDates = data.isRangeBetweenDates || false;
    this.checkedCheckboxValue = data.checkedCheckboxValue === 0 ? 0 : 1;
    this.visible = data.hasOwnProperty('visible') ? data.visible : true;

    let defaultFilterShowApplyButton = true;

    if (this.filterType === FilterType.MULTISELECT) {
      defaultFilterShowApplyButton = false;
    }

    this.filterShowApplyButton = data.filterShowApplyButton || defaultFilterShowApplyButton;

    // Prefill boolean filter dropdown
    if (this.filterOptions === null && this.type === TableColumnTypes.BOOLEAN) {
      this.filterOptions = [
        { value: '', label: 'general.all' },
        { value: '1', label: 'general.yes' },
        { value: '0', label: 'general.no' }
      ];
      this.filterType = FilterType.SELECT;
      this.filterMatchMode = FilterMatchMode.EQUALS;
    }
  }
}
