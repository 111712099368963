export class NumberUtils {
  public static commaSeperatedCurrencyRegExp = new RegExp('(?=.*?\\d)^\\$?(([1-9]\\d{0,2}(,\\d{3})*)|\\d+)?(\\.\\d{1,2})?$');
  public static periodSeperatedCurrencyRegExp = new RegExp('(?=.*?\\d)^\\$?(([1-9]\\d{0,2}(\\.\\d{3})*)|\\d+)?(,\\d{1,2})?$');

  public static convertFromCurrency(value: string): number | string {
    if (NumberUtils.commaSeperatedCurrencyRegExp.test(value)) {
      return +(value.toString().replace(/,/g, ''));
    } else if (NumberUtils.periodSeperatedCurrencyRegExp.test(value)) {
      return +(value.toString().replace(/\./g, '').replace(',', '.'));
    } else {
      return value;
    }
  }
}
