export function parseValueFromString(text: string): Date | Date[] {
  if (!text || text.trim().length === 0) {
    return null;
  }

  const value = parseDateTime(text);

  return value;
}

export function parseStringTime(value: string): string {
  const separator = ':';

  const timeParts: string[] = value.split(separator);
                      
  if (timeParts && timeParts.length >= 3) {
    const time = timeParts.slice(0, 2).join(separator);

    return time;
  }

  return value;
}

export function parseDateTime(text): Date {
  const parts: string[] = text.split(' ');
  const date = new Date();

  populateTime(date, parts[0]);

  return date;
}

export function populateTime(value, timeString) {
  const time = parseTime(timeString);

  value.setHours(time.hour);
  value.setMinutes(time.minute);
}

export function parseTime(value) {
  const timeParts: string[] = value.split(':');

  if (timeParts.length !== 2) {
    throw 'Invalid time';
  }

  const hours = parseInt(timeParts[0]);
  const minutes = parseInt(timeParts[1]);

  if (isNaN(hours) || isNaN(minutes) || hours > 23 || minutes > 59) {
    throw 'Invalid time';
  }
  else {
    return { hour: hours, minute: minutes };
  }
}
