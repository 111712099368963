import { Directive, Input } from '@angular/core';
import { ListRendererWidget } from '../interfaces/list-renderer-widget';
import { ListRendererColumnConfig } from '../models/list-renderer-config.model';

@Directive()
// tslint:disable-next-line:directive-class-suffix
export class ListRendererWidgetBase implements ListRendererWidget {
  @Input() public item: any = {};
  @Input() public index: number;
  @Input() public field: string;
  @Input() public context: Record<string, any> = {};
  @Input() public column: ListRendererColumnConfig;
}
