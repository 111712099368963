import { VaporUploadVisibility } from '../enums/vapor-upload-visibility.enum';
import { HttpEvent, HttpEventType } from '@angular/common/http';

export interface VaporUploadOptions {
  bucket?: string;
  contentType: string;
  expires?: string;
  visibility: VaporUploadVisibility | string;
}

export interface VaporStorageDataResponse {
  uuid: string;
  bucket: string;
  key: string;
  url: string;
  headers: Record<string, string | string[]>;
}

export interface VaporFileUploadResponse {
  type: HttpEventType,
  data: ({ progress: number } | { uuid: string } | HttpEvent<Object>),
}
