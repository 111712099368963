import { Inject, ModuleWithProviders, NgModule, Optional } from '@angular/core';
import { DynamicSidebarComponent } from './components/dynamic-sidebar/dynamic-sidebar.component';
import { CapturumInputModule } from '@capturum/ui/input';
import { SidebarModule } from 'primeng/sidebar';
import { CapturumDialogService, CapturumPropertyValuePipe, CapturumSharedModule } from '@capturum/ui/api';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { CommonModule } from '@angular/common';
import { CapturumSidebarModule } from '@capturum/ui/sidebar';
import { CoreModuleConfig } from './interfaces/core-module-config.interface';
import { CAPTURUM_BUILDERS_BASE_URL } from './providers/base-url.provider';
import { CAPTURUM_BUILDERS_CONFIG_LIST_URL } from './providers/config-list-url.provider';
import { BuilderCoreConfigService, BUILDERS_CORE_CONFIG } from './services/core-config.service';
import { CAPTURUM_BUILDERS_CONFIG_STORAGE_PROVIDERS } from './providers/config-storage-types.provider';
import { ConfigurationExportDialogComponent } from './components/configuration-export-dialog/configuration-export-dialog.component';

@NgModule({
  declarations: [DynamicSidebarComponent],
  imports: [
    CommonModule,
    SidebarModule,
    CapturumSidebarModule,
    CapturumInputModule,
    CapturumSharedModule,
    DynamicDialogModule,
    ConfirmDialogModule,
  ],
  providers: [CapturumDialogService, CapturumPropertyValuePipe, ConfirmationService],
})
export class CapturumBuilderCoreModule {
  constructor(
    configService: BuilderCoreConfigService,
    @Optional() @Inject(BUILDERS_CORE_CONFIG) configs: CoreModuleConfig[] = []
  ) {
    configService.addConfig({
      components: {
        list_configurations_export: ConfigurationExportDialogComponent,
      },
    });

    if (!configs) {
      return;
    }

    configs.forEach((config) => configService.addConfig(config));
  }

  public static forRoot(config?: CoreModuleConfig): ModuleWithProviders<CapturumBuilderCoreModule> {
    return {
      ngModule: CapturumBuilderCoreModule,
      providers: [
        {
          provide: BUILDERS_CORE_CONFIG,
          useValue: config,
          multi: true,
        },
        BuilderCoreConfigService,
        {
          provide: CAPTURUM_BUILDERS_BASE_URL,
          useValue: config.baseUrl || '/admin/builder',
        },
        {
          provide: CAPTURUM_BUILDERS_CONFIG_LIST_URL,
          useValue: config.configListUrl || '/config',
        },
        {
          provide: CAPTURUM_BUILDERS_CONFIG_STORAGE_PROVIDERS,
          useValue: config.storageProviders || {},
        },
      ],
    };
  }

  public static forChild(config?: CoreModuleConfig): ModuleWithProviders<CapturumBuilderCoreModule> {
    return {
      ngModule: CapturumBuilderCoreModule,
      providers: [
        {
          provide: BUILDERS_CORE_CONFIG,
          useValue: config,
          multi: true,
        },
      ],
    };
  }
}
