<p-accordion [activeIndex]="activeTab" [collapseIcon]="'fa fa-chevron-down'" [expandIcon]="'fa fa-chevron-right'">
    <div *ngFor="let tab of accordionTabs; let i = index">
        <p-accordionTab (onOpen)="onTabOpen($event)"
                        [selected]="i === activeTab" class="tab pt-0">
            <p-header>
              <div class="accordion-tab-header-block">
                <div class="accordion-tab-header-block-inner">
                 <span *ngIf="tab.headerStatus"
                       [ngClass]="determineColor(tab.headerStatus)"
                       class="float-md-right status accordion-tab-header-block-text">
                    <i class="{{ tab?.headerIcon }}"></i>
                   {{ tab?.headerStatus }}
                 </span>
                  <br />
                <span class="ml-md-1 accordion-tab-header-block-text">
                  {{ tab.header }}
                </span>
              </div>
            </div>
            </p-header>
            <div class="dropdown-divider p-0">
            </div>
            <div class="px-2 px-md-3">
                <ng-container [ngTemplateOutlet]="tab?.contentTemplate"></ng-container>
            </div>
        </p-accordionTab>
    </div>
</p-accordion>
