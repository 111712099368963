import { Router } from '@angular/router';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { CAPTURUM_BUILDERS_BASE_URL, ConfigApiService, PageBuilderWidget, PageBuilderWidgetType } from '@capturum/builders/core';

@Component({
  selector: 'cpb-page-builder-widget',
  templateUrl: './page-builder-widget.component.html',
  styleUrls: ['./page-builder-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CapturumPageBuilderWidgetComponent {
  @Input()
  public widgets: PageBuilderWidget[];
  @Input()
  public widget: PageBuilderWidget;
  @Input()
  public index: number;
  @Output()
  public widgetDrop: EventEmitter<CdkDragDrop<string | PageBuilderWidget>> = new EventEmitter<CdkDragDrop<string | PageBuilderWidget>>();
  @Output()
  public removeWidget: EventEmitter<PageBuilderWidget> = new EventEmitter<PageBuilderWidget>();
  @Output()
  public editWidget: EventEmitter<{ widget: PageBuilderWidget, index: number }> = new EventEmitter<{ widget: PageBuilderWidget, index: number }>();

  public widgetType = PageBuilderWidgetType;
  public widgetTypeIncludes = [PageBuilderWidgetType.form, PageBuilderWidgetType.list];

  constructor(
    @Inject(CAPTURUM_BUILDERS_BASE_URL)
    private builderBaseUrl: string,
    private router: Router,
    private configApiService: ConfigApiService,
  ) {
  }

  public handleWidgetDrop(event: CdkDragDrop<string | PageBuilderWidget>): void {
    this.widgetDrop.emit(event);
  }

  public handleWidgetRemove(widget: PageBuilderWidget): void {
    this.removeWidget.emit(widget);
  }

  public widgetTypePredicate(widget: PageBuilderWidget): boolean {
    return widget?.type !== PageBuilderWidgetType.row;
  }

  public canDrop(widget: PageBuilderWidget): () => boolean {
    return () => (widget?.type === PageBuilderWidgetType.row);
  }

  public handleEditWidget(event: { widget: PageBuilderWidget, index }): void {
    this.editWidget.emit(event);
  }

  public openWidgetConfiguration(widget: PageBuilderWidget): void {
    this.configApiService.getConfigByKey(widget.type, widget.options.key).subscribe((config) => {
      this.router.navigate([`${this.builderBaseUrl}/configuration/${widget.type}/${config.id}`]);
    });
  }
}
