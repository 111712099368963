<div class="table-list">
  <div *ngIf="filterable" class="row table-filters" [class.loading]="loading">
    <div class="col-12" [ngClass]="{'col-md-9': hideFilters, 'col-md-12': !hideFilters}">
      <h5 (click)="toggleFilters()" class="filters-label">
        <ng-container *ngIf="collapsibleFilters">
          <i class="filter-toggle mr-1 fas"
             [ngClass]="{'fa-eye': hideFilters, 'fa-eye-slash': !hideFilters}">
          </i>
        </ng-container>

        {{ texts.filtersLabel | observablePipe | async }}
      </h5>

      <span *ngIf="manageableFilters" class="fa-stack ml-2">
                <i class="fas fa-circle fa-stack-2x"></i>
                <i class="fas fa-cog fa-stack-1x fa-inverse" (click)="displayFilterSettings()"></i>
            </span>
    </div>

    <div class="col-12 col-md-9 mt-3" [style.display]="hideFilters ? 'none' : 'block'">
      <div class="row">
        <div *ngIf="columns && filterable"
             class="col-12">
          <cap-table-filters #tableFilters
                             [timeout]="filterTimeout"
                             (onChange)="onTableFilter($event)"
                             [activeFilters]="activeFilters"
                             [calendarLocale]="calendarLocale || (config?.locale | observablePipe | async)"
                             [calendarDefaultRanges]="calendarDefaultRanges"
                             [itemsSelectedMessage]="texts.itemsSelectedMessage"
                             [applyFilterMessage]="texts.applyFilterMessage"
                             [tableFiltersStyleClass]="filtersStyle.tableFiltersStyleClass"
                             [wrapperFiltersStyleClass]="filtersStyle.wrapperFiltersStyleClass"
                             [filterStyleClass]="filtersStyle.filterStyleClass"
                             [filterWrapperStyleClass]="filtersStyle.filterWrapperStyleClass"
                             [tableColumns]="columns"
                             [resettableFilters]="resettableFilters"
                             [resettableFiltersButton]="resettableFiltersButton"
                             [afterFiltersTemplate]="afterFiltersTemplate"
                             [rightFiltersTemplate]="rightFiltersTemplate"
                             [usePrimeFiltering]="usePrimeFiltering"
                             [searchable]="searchable"
                             [searchPlaceholder]="searchPlaceholder"
                             [templates]="templates"
                             [stateKey]="stateKey"
                             (onSearchChange)="onSearchEmitted($event)">
          </cap-table-filters>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-3 mt-3">
      <div class="float-right">
        <ng-container *ngIf="resettableFilters && resettableFiltersButton">
          <ng-container *ngIf="keys(activeFilters).length > 0">
            <cap-button (click)="resetFilters()"
                        [label]="texts.resetFilterButtonLabel | observablePipe | async"
                        [style.display]="collapsibleFilters && hideFilters ? 'none' : 'block'"
                        styleClass="secondary round-button reset-table-filters-button p-0 mb-2"
                        icon="fas fa-trash-alt"
                        iconPosition="right"
                        class="round-button float-left">
            </cap-button>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="tableSortingType === tableSortingMode.DRAG_AND_DROP">
          <cap-table-sort class="float-left ml-3"
                          [ascendingLabel]="texts.ascendingLabel"
                          [columns]="sortOrderFields" [descendingLabel]="texts.descendingLabel"
                          [resetSortingLabel]="texts.resetSortingLabel"
                          [sortButtonLabel]="texts.sortButtonLabel"
                          (change)="onSortChange($event)">
          </cap-table-sort>
        </ng-container>
      </div>
    </div>
  </div>

  <ng-container *ngIf="paginator">
    <p-table #pt
             (onLazyLoad)="loadTableData($event)"
             (onSort)="onTableSort($event)"
             (onPage)="onTablePage($event)"
             [lazyLoadOnInit]="lazyLoadOnInit"
             [(selection)]="selectedValue"
             [columns]="columns"
             [dataKey]="dataKey"
             [frozenColumns]="frozenColumns"
             [scrollable]="scrollable"
             [frozenWidth]="frozenWidth"
             [scrollHeight]="scrollHeight"
             [lazy]="lazyLoading"
             [first]="paginator.first"
             [loading]="loading"
             [reorderableColumns]="reorderableColumns"
             [paginator]="pagination"
             [rowHover]="actions"
             [rows]="paginator?.rows"
             [sortMode]="sortMode"
             [tableStyleClass]="getTableStyleClass(selectedValue)"
             [totalRecords]="paginator?.total"
             [value]="tableValue"
             [resizableColumns]="resizableColumns"
             [stateStorage]="stateKey ? 'local' : undefined"
             [stateKey]="stateKey"
             styleClass="cap-table {{makeRowsTheSameHeight ? 'rowsWithTheSameHeight' : ''}}">
      <ng-template let-columns pTemplate="header">
        <tr [class.bottom-divider]="!filterable" [class.filterable]="filterable" class="headers-row">
          <th *ngIf="actions && iconPosition === directions.left" class="table-actions-col">&nbsp;</th>
          <th *ngIf="selectable" class="table-selectable-col">
            <p-tableHeaderCheckbox *ngIf="selectAllRows"
                                   (click)="onCheckboxToggle.emit(selectedValue)">
            </p-tableHeaderCheckbox>
          </th>
          <ng-container *ngFor="let col of columns">
            <th *ngIf="col.visible && col.reorderable" [ngClass]="col.styleClass" pReorderableColumn
                [ngStyle]="{'pointer-events': sortable || (reorderableColumns && col.reorderable) ? 'auto' : 'none'}"
                [pSortableColumnDisabled]="!sortable || tableSortingType !== tableSortingMode.DEFAULT || !col.sortable"
                [pSortableColumn]="col.sortable ? col.sortField : false"
                class="table-header list-page-table-header">
              <div class="divider">
                                <span>
                                    {{ col.header | observablePipe | async }}
                                  <ng-container>
                                        <p-sortIcon *ngIf="col.sortable" [field]="col.sortField"></p-sortIcon>
                                    </ng-container>
                                </span>
              </div>
            </th>

            <th *ngIf="col.visible && !col.reorderable"
                pResizableColumn
                [pResizableColumnDisabled]="!resizableColumns"
                [ngClass]="[col.styleClass,  col.frozen ? 'frozen-cell' : '']"
                [ngStyle]="{'pointer-events': sortable || (reorderableColumns && col.reorderable) ? 'auto' : 'none',
                                        'width': !col.frozen && unFrozenColumnWidth}"
                [pSortableColumnDisabled]="!sortable || tableSortingType !== tableSortingMode.DEFAULT"
                [pSortableColumn]="col.sortable ? col.sortField : false"
                class="table-header list-page-table-header">
              <div class="divider">
                <span>
                  {{ col.header | observablePipe | async }}
                  <ng-container>
                    <p-sortIcon *ngIf="col.sortable" [field]="col.sortField"></p-sortIcon>
                  </ng-container>
                </span>
              </div>
            </th>
          </ng-container>

          <ng-container *ngIf="!(columns | isFrozen)">
            <th *ngIf="actions && iconPosition === directions.right"
                class="table-actions-col">
            </th>

            <th *ngIf="rowActions" class="table-actions-col">
            </th>

            <th *ngIf="expandable" class="table-expand-col">
            </th>

            <th *ngIf="manageableColumns" class="columns-list">
              <i [ngClass]="manageableColumnsIcon"
                 (click)="columnsVisibility.toggle($event)">
              </i>
            </th>
          </ng-container>
        </tr>
      </ng-template>

      <ng-template let-columns="columns"
                   let-rowData
                   let-i="rowIndex"
                   let-expanded="expanded"
                   pTemplate="body">
        <tr class="table-rows-header" *ngIf="rowData.rows_header">
          <td [attr.colspan]="getFullColspan()">
            <span>{{ rowData.rows_header | observablePipe | async }}</span>
          </td>
        </tr>

        <tr *ngIf="!useFormRows"
            [class.selected]="selectedValue.includes(rowData)"
            [attr.data-test]="'table_row_' + rowData?.id"
            [selectedValue]="selectedValue"
            [clickable]="clickable"
            [selectable]="selectable"
            [rowData]="rowData"
            [columns]="columns"
            [deletable]="deletable"
            [editable]="editable"
            [expandable]="expandable"
            [expanded]="expanded"
            [actions]="actions"
            [rowActions]="rowData?.actions || rowActions"
            [unFrozenColumnWidth]="unFrozenColumnWidth"
            [manageableColumns]="manageableColumns"
            [class]="rowData?.tr_style_class | trStyleClass"
            (onRowClick)="onRowClick.emit($event)"
            (onActionClick)="onActionClick.emit($event)"
            (onIconClick)="onIconClick.emit($event)"
            (onEditRow)="onEditRow.emit($event)"
            (onDeleteRow)="onDeleteRow.emit($event)"
            (onCheckboxToggle)="onCheckboxClick($event)"
            (onRowToggle)="onRowToggle.emit($event)"
            capTableRow>
        </tr>
        <tr *ngIf="useFormRows"
            [class.selected]="selectedValue.includes(rowData)"
            [attr.data-test]="'table_row_' + rowData?.id"
            [selectedValue]="selectedValue"
            [clickable]="clickable"
            [selectable]="selectable"
            [rowData]="rowData"
            [columns]="columns"
            [deletable]="deletable"
            [editable]="editable"
            [actions]="actions"
            [rowActions]="rowData?.actions || rowActions"
            [manageableColumns]="manageableColumns"
            [class]="rowData?.tr_style_class | trStyleClass"
            (onRowClick)="onRowClick.emit($event)"
            (onActionClick)="onActionClick.emit($event)"
            (onIconClick)="onIconClick.emit($event)"
            (onEditRow)="onEditRow.emit($event)"
            (onDeleteRow)="onDeleteRow.emit($event)"
            (onCheckboxToggle)="onCheckboxClick($event)"
            (onRowToggle)="onRowToggle.emit($event)"
            [parentForm]="formGroup"
            [rowIndex]="i"
            capTableFormRow>
        </tr>
      </ng-template>

      <ng-template *ngIf="expansionTemplate" pTemplate="rowexpansion" let-rowData>
        <ng-container [ngTemplateOutlet]="expansionTemplate"
                      [ngTemplateOutletContext]="{$implicit: rowData}"></ng-container>
      </ng-template>

      <ng-template pTemplate="emptymessage">
        <tr *ngIf="columns && !loading" class="text-center">
          <td [attr.colspan]="getFullColspan()" class="empty-message">
                        <span [ngStyle]="{'margin-left': frozenColumns?.length ? '-' + frozenWidth : 'auto'}">
                            {{ texts.noResults | observablePipe | async }}
                        </span>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="paginatorleft">
        <ng-container *ngIf="pagination && !loading">
                <span
                  class="ml-3 mr-2 text-capitalize">{{ texts.footerPaginationText | observablePipe | async | splitPipe : '{0}' : 0 }}</span>
          <cap-dropdown (change)="onPerPageChange()"
                        [formControl]="perPageControl"
                        id="num-rows"
                        inputId="num-rows"
                        name="num_rows"
                        [options]="perPageOptions"
                        styleClass="num-rows-dropdown text-left"
                        [sortAlphabetically]="false"
                        sortBy="value">
          </cap-dropdown>

          <span class="ml-2">
                    {{ texts.footerPaginationText | observablePipe | async | splitPipe : '{0}' : 1 | splitPipe : '{1}' : 0 }}
            {{ paginator.total }}
            {{ texts.footerPaginationText | observablePipe | async | splitPipe : '{1}' : 1 }}
                </span>
        </ng-container>
      </ng-template>
    </p-table>
  </ng-container>
</div>

<p-overlayPanel #columnsVisibility appendTo="body">
  <div *ngIf="manageableTitle" class="manageable-title">
    {{ manageableTitle }}
  </div>

  <ng-container *ngFor="let column of columns">
    <cap-checkbox *ngIf="column.header && column.manageable"
                  class="item"
                  (change)="onManageableColumnChange?.emit({column:column, checked: $event})"
                  [(ngModel)]="column.visible"
                  (ngModelChange)="setDefaultColumns('columns', columns);"
                  [label]="column.filterPlaceholder | observablePipe | async"
                  labelStyleClass="item-label">
    </cap-checkbox>
  </ng-container>
</p-overlayPanel>
