import { Directive, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Directive({
  selector: '[capIfAuthenticated]'
})
export class IfAuthenticatedDirective implements OnInit, OnDestroy {
  private hasView: boolean = false;
  private destroy$: Subject<void>;
  private state$: Observable<boolean>

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService,
  ) {
    this.destroy$ = new Subject<void>();
    this.state$ = this.authService.getAuthenticationState();
  }

  public ngOnInit(): void {
    this.changeDisplay(this.authService.isAuthenticated());

    this.state$
      .pipe(takeUntil(this.destroy$))
      .subscribe((state) => this.changeDisplay(state));
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Toggle the display of the element
   *
   * @param authenticated: boolean
   * @return void
   */
  private changeDisplay(authenticated: boolean): void {
    if (authenticated && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!authenticated && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}
