import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function controlsEqual(controlName: string, equalToName: string, errorKey: string = controlName): ValidatorFn {
  return (form: AbstractControl): ValidationErrors | null => {
    const control = form.get(controlName);

    if (!control) {
      return null;
    }

    if (control.value !== form.get(equalToName).value) {
      control.setErrors({ [errorKey]: true });

      return { [errorKey]: true };
    } else {
      control.setErrors(null);

      return null;
    }
  };
}
