import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { DomHandler } from 'primeng/dom';

@Directive({
  selector: '[capFrozenColumn]',
  host: {
    class: 'p-element',
    '[class.p-frozen-column]': 'frozen',
  },
})
export class CapturumFrozenColumnDirective implements AfterViewInit {
  @Input() public alignFrozen: string = 'left';
  @Input() public frozen: boolean = true;
  @Input() public frozenColumnWidth: number = null;

  constructor(private el: ElementRef) {
  }

  public ngAfterViewInit(): void {
    this.updateStickyPosition();
  }

  public updateStickyPosition(): void {
    if (this.frozen) {
      const filterRow = this.el.nativeElement.parentElement.nextElementSibling;

      this.setStyles(this.alignFrozen);

      if (filterRow) {
        const index = DomHandler.index(this.el.nativeElement);

        filterRow.children[index].style.left = this.el.nativeElement.style.left;
        filterRow.children[index].style.right = this.el.nativeElement.style.right;
      }
    }
  }

  protected setStyles(direction: string): void {
    let offset = 0;
    const sibling = direction === 'right' ? this.el.nativeElement.nextElementSibling : this.el.nativeElement.previousElementSibling;

    if (sibling) {
      offset = DomHandler.getOuterWidth(sibling) + (parseFloat(sibling.style[direction]) || 0);
    }

    this.el.nativeElement.style[direction] = offset + 'px';

    if (this.frozenColumnWidth) {
      this.el.nativeElement.style.width = this.frozenColumnWidth + 'px';
    }
  }
}
