import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { CSSClasses, Directions } from '@capturum/ui/api';

// import { CSSClasses } from '../api/enums/css-classes';

/**
 * A button component
 *
 * Example:
 * ```html
 *  <cap-button label="button"></cap-button>
 * ```
 */
@Component({
  selector: 'cap-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CapturumButtonComponent {
  /**
   * Uniquely identifier for the buttton
   */
  @Input() public identifier: string;
  /**
   * The label to be displayed inside the button
   */
  @Input() public label: string;
  /**
   * The icon class of the icon which should be displayed in the button
   */
  @Input() public icon: string;
  /**
   * The position of the icon. It could either be "left" or "right"
   */
  @Input() public iconPos = 'left' as Directions;
  /**
   * Add a custom css class to the element
   */
  @Input() public styleClass: any;
  /**
   * Type of the button
   */
  @Input() public type = 'button';
  /**
   * When present, it specifies that the component should be disabled
   * To implement this use (onClick)
   */
  @Input() public disabled: boolean;

  /**
   * Callback to execute when button is clicked.
   */
  @Output()
  public onClick = new EventEmitter<void>();

  @HostListener('click', ['$event'])
  public click(event: MouseEvent): void {
    if (this.disabled) {
      event.preventDefault();
    }
  }

  public getStyleClass(): string {
    let styleClasses = `${this.styleClass} ${CSSClasses.EmButton}`;

    if (this.icon) {
      styleClasses += ` p-button-label-icon-${this.iconPos}`;

      if (!this.label) {
        styleClasses += ' p-button-icon-only';
      }
    }

    return styleClasses;
  }
}
