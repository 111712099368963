import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseDataValueIndexedDbModel } from '../../models/base-data-value.indexedDb-model';
import { UtilService } from '../../../../shared/services/util.service';

interface BaseDataFormModel {
  [key: string]: BaseDataFormModel | string;
}

@Component({
  selector: 'emc-base-data-form',
  templateUrl: './base-data-form.component.html'
})
export class BaseDataFormComponent implements OnInit {
  @Input()
  public mainBaseDataKeys: string[];
  @Input()
  public model: any;
  @Input()
  public additionalFields: FormlyFieldConfig[];
  @Output()
  public modelChange: EventEmitter<BaseDataFormModel>;

  public formGroup: FormGroup;
  public baseDataValues: BaseDataValueIndexedDbModel[];
  public fields: FormlyFieldConfig[];
  public submitting: boolean;

  private updateObject: any = {};

  constructor(private utilService: UtilService, private translateService: TranslateService) {
    this.modelChange = new EventEmitter();
    this.formGroup = new FormGroup({});
  }

  public async ngOnInit(): Promise<void> {
    await this.initForm();
    this.updateForm();
  }

  /**
   * Create the fields for the formly-fom
   */
  public async initForm(): Promise<void> {
    const field = { key: 'base_data_values', fieldGroup: [] };

    for (const mainBaseDataKey of this.mainBaseDataKeys) {
      this.baseDataValues = await BaseDataValueIndexedDbModel.getByBaseDataKey(mainBaseDataKey);
      const inventoryItemTypes = this.utilService.mapToSelectItem(
        this.baseDataValues.filter(value => !value.parent_id),
        'id',
        'id',
        true,
        'id',
        'base-data'
      );

      field.fieldGroup.push({
        type: 'dropdown',
        key: mainBaseDataKey.replace('.', '-'),
        props: {
          options: inventoryItemTypes,
          appendTo: 'body',
          required: true,
          label: this.translateService.instant(mainBaseDataKey),
          placeholder: 'select',
          change: (dropdownField, event) => {
            this.onDropdownChange(event.value);
          }
        }
      });
    }

    this.fields = [field, ...this.additionalFields];
    return;
  }

  /**
   * Add or remove fields based on the selected option
   */
  public onDropdownChange(value: string): void {
    this.removeAllChildrenFields(value);
    this.createCompleteFields(value);
    this.modelChange.emit(this.model);
  }

  /**
   * Update the form with given this.model uuid's
   */
  public async updateForm(): Promise<void> {
    if (!this.model.length) {
      return;
    }

    await this.getNestedValues(this.model);

    if (this.formGroup.get('base_data_values')) {
      this.formGroup.get('base_data_values').patchValue(this.updateObject);
    }

    this.updateObject = {};

    return;
  }

  /**
   * Recursively retrieve all the baseDataValues given in this.model
   * 1. Retrieve all values
   * 2. Put them in our this.updateObject
   * 3. Update our form
   * 4. Generate fields for all values
   */
  public async getNestedValues(values: string[]): Promise<void> {
    const nested = [];

    for (const value of values) {
      const baseDataRecord = await BaseDataValueIndexedDbModel.query().get(value);
      let key = baseDataRecord.base_data_key.replace('.', '-');

      if (baseDataRecord.parent_id) {
        key = baseDataRecord.parent_id;
        nested.push(key);
      }

      if (!this.updateObject[key]) {
        this.updateObject[key] = value;

        if (!baseDataRecord.parent_id) {
          await this.generateNestedFields(value);
        }
      }
    }

    if (nested.length > 0) {
      await this.getNestedValues(nested);
    }
  }

  /**
   * Recursively retrieve all the nested fields:
   * 1. Start at the top level, the baseDataValue without a parent_id
   * 2. Recursively search for the dropdown children of the top level value
   * 3. Create fields for each found child
   */
  public async generateNestedFields(value: string): Promise<void> {
    const selectedValue = this.baseDataValues.find(baseDataValue => baseDataValue.id === value);
    const fieldOptions = this.baseDataValues.filter(baseDataValue => baseDataValue.parent_id === selectedValue.id);

    for (const option of fieldOptions) {
      // Get the direct child of the field option
      const directChildren = this.baseDataValues.filter(baseDataValue => baseDataValue.parent_id === option.id);

      if (directChildren.length > 0) {
        this.createCompleteFields(value);

        for (const child of directChildren) {
          this.generateNestedFields(child.id);
        }
      }
    }
  }

  /**
   * Creates fields based on given baseDataValue and their children (options)
   */
  public async createCompleteFields(value: string): Promise<void> {
    // Check if the options has children
    const childValues = this.baseDataValues.filter(baseDataValue => baseDataValue.parent_id === value);

    if (childValues) {
      // Create a formly field for every child
      for (const childValue of childValues) {
        const childOptions = this.baseDataValues.filter(baseDataValue => baseDataValue.parent_id === childValue.id);

        if (childOptions && childOptions.length > 0) {
          this.addField(childValue.id, childOptions);
        }
      }
    }
  }

  /**
   * Add dropdown for given ID
   */
  public addField(childvalueId: string, childOptions: any): void {
    const field = {
      type: 'dropdown',
      key: childvalueId,
      props: {
        options: this.utilService.mapToSelectItem(childOptions, 'id', 'id', true, 'id', 'base-data'),
        label: this.translateService.instant(`base-data.${childvalueId}`),
        placeholder: this.translateService.instant('placeholder.select'),
        appendTo: 'body',
        required: true,
        change: (dropdownField, event) => {
          this.onDropdownChange(event.value);
        }
      }
    };

    const baseDataField = this.fields.find(formField => formField.key === 'base_data_values');
    baseDataField.fieldGroup = [...baseDataField.fieldGroup, field];

    // Add the field to the formly fields
    this.fields = this.fields.map(formField => {
      if (formField.key === 'base_data_values') {
        return baseDataField;
      }

      return formField;
    });
  }

  /**
   * Remove all child fields
   */
  public removeAllChildrenFields(id: string): void {
    // Get the selected value
    const selectedValue = this.baseDataValues.find(baseDataValue => baseDataValue.id === id);
    // Get all the options of the parent of the selected value
    const fieldOptions = this.baseDataValues.filter(baseDataValue => baseDataValue.id === selectedValue.parent_id);
    let baseDataFields = this.fields.find(formField => formField.key === 'base_data_values').fieldGroup;

    if (fieldOptions.length > 0) {
      for (const option of fieldOptions) {
        // Get the direct child of the field option
        const directChildren = this.baseDataValues.filter(baseDataValue => baseDataValue.parent_id === option.id);

        if (directChildren.length > 0) {
          for (const child of directChildren) {
            if (this.model.base_data_values.hasOwnProperty(child.id)) {
              this.model.base_data_values[child.id] = null;
            }

            // Remove the field belonging to the directChild
            baseDataFields = baseDataFields.filter(field => field.key !== child.id);
            const childOptions = this.baseDataValues.filter(baseDataValue => baseDataValue.parent_id === child.id);

            // Set the updates fieldGroup
            this.fields = this.fields.map(formField => {
              if (formField.key === 'base_data_values') {
                formField.fieldGroup = baseDataFields;
              }

              return formField;
            });

            // Remove any other children
            for (const childValue of childOptions) {
              this.removeAllChildrenFields(childValue.id);
            }
          }
        }
      }
    }
  }
}
