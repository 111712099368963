import { FormControl, ValidationErrors } from '@angular/forms';

/**
 * Formly validator for CoC number
 *
 * @param control
 */
export function cocValidator(control: FormControl): ValidationErrors {

  /**
   * Standard CoC number validation regular expression
   * Must be 8 digits long
   */
  const cocRegExp: RegExp = new RegExp('^[0-9]{8}$');

  return !control.value || cocRegExp.test(control.value) ? null : { 'coc': true };
}
