<div *ngIf="actions?.length && (actions | allActionsIsHidden | async) === false" class="row-action" (capClickOutside)="isOpen = false">
    <i [class]="icon" (click)="toggleMenu()" [attr.data-test]="'action_toggler_' + item?.id"></i>

    <ul class="row-action__items"
        [ngClass]="{'row-action__items--open': isOpen}">
        <ng-container *ngFor="let action of actions">
            <li *ngIf="(action?.hidden | observablePipe | async) !== true"
                (click)="executeAction(action, item)"
                [attr.data-test]="action?.key || action?.value">
                <div class="row-action__icon">
                    <i [class]="action?.icon"></i>
                </div>

                <div class="row-action__info">
                    <p class="title">{{ action?.label | observablePipe | async }}</p>
                    <p *ngIf="action?.description | observablePipe | async as description"
                       class="description">
                        {{ description }}
                    </p>
                </div>
            </li>
        </ng-container>
    </ul>
</div>
