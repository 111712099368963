import { Injectable } from '@angular/core';

import { from as observableFrom, Observable, Subject } from 'rxjs';

import { ApiHttpService } from '@capturum/api';
import { AuthService } from '@capturum/auth';
import { ToastService } from '@capturum/shared';
import { UtilService } from './util.service';
import { SettingService } from '../../domain/setting/services/setting.service';
import { PermissionService } from '../../domain/permission/services/permission.service';
import { ModuleService } from '../../domain/module/services/module.service';
import { ModuleIndexedDbModel } from '../../domain/module/models/module.indexedDb-model';
import { UserService } from '../../domain/user/user.service';
import { BaseDataService } from '../../domain/base-data/services/base-data.service';
import { TranslationService } from '../../domain/translation/services/translation.service';
import { IndexedDbService } from '../indexedDb/indexedDb.service';

@Injectable({ providedIn: 'root' })
export class SynchronisationService {
  public SynchronisingCompleted = new Subject<any>();
  public shouldSync: boolean;
  public showSyncToasts = true;
  private store = this.indexedDbService.getStore();
  private state = { added: false, finished: false };

  constructor(
    private api: ApiHttpService,
    private auth: AuthService,
    private toastService: ToastService,
    private utilService: UtilService,
    private settingService: SettingService,
    private baseDataService: BaseDataService,
    private translationService: TranslationService,
    private permissionService: PermissionService,
    private moduleService: ModuleService,
    private userService: UserService,
    private indexedDbService: IndexedDbService,
  ) {
    // Register to internet connection online event
    window.addEventListener(
      'online',
      e => {
        if (this.shouldSync) {
          this.synchronise();
          this.synchroniseStaticData();
          this.shouldSync = false;
        }
      },
      false,
    );
  }

  /**
   * Sync static data attachable in projects
   *
   * Clients, materials, activities, base data
   *
   * @return Promise<any>
   */
  public async synchroniseStaticData(showResultToast: boolean = true): Promise<any> {
    // Do not synchronize when not authenticated
    if (!this.auth.isAuthenticated()) {
      return;
    }

    // Check internet status, if not online, then sync later
    if (!navigator.onLine) {
      this.shouldSync = true;
      return;
    }

    await this.baseDataService.loadBaseData().toPromise();

    if (showResultToast) {
      this.showSyncStartToast('synchronisation.static-data-synced');
    }

    await this.translationService.getTranslations();
    await this.userService.loadUsers();

    // Reload settings
    await this.settingService.loadAll();
    await this.baseDataService.loadBaseData().toPromise();
    await this.moduleService.loadModules();
    await this.permissionService.loadPermissions();
  }

  /**
   * Sync project data
   *
   * @return Promise<any>
   */
  public async synchronise(): Promise<any> {
    // Do not synchronize when not authenticated
    if (!this.auth.isAuthenticated()) {
      return;
    }

    // Check internet status, if not online, then sync later
    if (!navigator.onLine) {
      this.shouldSync = true;
      return;
    }

    this.showSyncStartToast('synchronisation.data-synced');

    // First sync new and changed data to backend
    await this.syncToBackend();

    await this.baseDataService.loadBaseData().toPromise();

    this.showSyncResultToast();

    this.SynchronisingCompleted.next(this.state);
  }

  /**
   * Sync stuff to the backend
   *
   * @param clearOnSuccess boolean
   * @return Promise<boolean>
   */
  public async syncToBackend(clearOnSuccess: boolean = false): Promise<boolean> {
    // Do your sync stuff here
    return true;
  }

  /**
   * Show sync message
   *
   * @param message string
   * @return void
   */
  private showSyncStartToast(message: string): void {
    if (!this.showSyncToasts) {
      return;
    }

    this.toastService.success('synchronisation.title', message);
  }

  /**
   * Show sync result message
   *
   * @return void
   */
  private showSyncResultToast(): void {
    if (!this.showSyncToasts) {
      return;
    }

    if (this.state.added === true) {
      this.toastService.success('synchronisation.title', 'synchronisation.data-imported');
    } else {
      this.toastService.success('synchronisation.title', 'synchronisation.data-uptodate');
    }
  }

  /**
   * State of synchronisation as observable
   *
   * @return Observable<any>
   */
  private synchronisationState(): Observable<any> {
    return observableFrom([this.state]);
  }

  /**
   * Lists all tables containing project data
   *
   * @return Promise<any>
   */
  private async getProjectTables(): Promise<any> {
    const tables = [];
    const modules = await this.getSyncTables();

    for (const module of modules) {
      tables.push(module.table);
    }

    return tables;
  }

  /**
   * Build a sync model from de database modules
   *
   * @return Promise<any>
   */
  private async buildSyncModel(): Promise<any> {
    const syncModel = {};

    for (const syncTable of await this.getSyncTables()) {
      if (!syncModel[syncTable.module]) {
        syncModel[syncTable.module] = {};
      }

      syncModel[syncTable.module][syncTable.key] = [];
    }

    return syncModel;
  }


  /**
   * Build sync Tables from the active modules stored in de database
   *
   * @return Promise<any>
   */
  private async getSyncTables(): Promise<any> {
    const modules = [];
    const activeModules = await ModuleIndexedDbModel.getModules();

    for (const activeModule of activeModules) {
      switch (activeModule.name) {
        case 'inventory-client':
          modules.push({
            module: 'inventory-client',
            key: 'client',
            table: 'clients',
          });

          break;
        case 'inventory-project':
          modules.push({
            module: 'inventory-project',
            key: 'project',
            table: 'projects',
            relations: [
              {
                module: 'address',
                key: 'address',
                table: 'addresses',
                attribute: 'addressable_id',
                identifier: 'project_id',
              },
              {
                module: 'contact',
                key: 'contact',
                table: 'contacts',
                attribute: 'contactable_id',
                identifier: 'project_id',
              },
            ],
          });

          break;
        case 'inventory-inventory':
          modules.push({
            module: 'inventory-inventory',
            key: 'inventory',
            table: 'inventories',
          });

          modules.push({
            module: 'inventory-inventory',
            key: 'inventory_item',
            table: 'inventory_items',
          });

          break;
        case 'inventory-receipt':
          modules.push({
            module: 'inventory-receipt',
            key: 'receipt',
            table: 'receipts',
          });

          break;
        case 'inventory-specialty':
          modules.push({
            module: 'inventory-specialty',
            key: 'project_specialty',
            table: 'project_specialties',
          });

          break;
      }
    }

    return modules;
  }


  /**
   * Store the sync entities
   *
   * @param entities any
   * @param syncTable any
   * @return Promise<any>
   */
  private async storeEntities(entities: any, syncTable: any): Promise<any> {
    // Get the errors
    let errors = [];
    entities.map(entity => (errors = errors.concat(entity.errors)));

    if (errors.length > 0) {
      // TODO: Do something with the errors
      console.log('Entity sync error...');
    }

    // Get mutated entities
    let mutated = [];
    entities.map(entity => (mutated = mutated.concat(entity.mutated)));

    if (syncTable.attribute) {
      mutated.map(entity => {
        entity[syncTable.identifier] = entity[syncTable.attribute];

        return entity;
      });
    }

    // Save mutated
    await this.store.table(syncTable.table).bulkPut(mutated);

    // Get deleted entities
    let deleted = [];
    entities.map(entity => (deleted = deleted.concat(entity.deleted)));

    // Remove deleted
    await this.store.table(syncTable.table).bulkDelete(deleted);

    // Get "new" entities
    let brandNew = [];
    entities.map(entity => (brandNew = brandNew.concat(entity.new)));

    if (syncTable.attribute) {
      brandNew.map(entity => {
        entity[syncTable.identifier] = entity[syncTable.attribute];

        return entity;
      });
    }

    await this.store.table(syncTable.table).bulkPut(brandNew);
  }

  /**
   * Get the Diff between two entities
   *
   * @param newData any
   * @param oldData any
   * @return any
   */
  private getDiff(newData: any, oldData: any): any {
    let result: any;

    if (Array.isArray(newData)) {
      if (!result) {
        result = [];
      }

      for (const item of newData) {
        if (item === null) {
          continue;
        }

        // Find item with same id in old data and compare
        const oldItem = item && oldData ? oldData.find(dataItem => dataItem.id === item.id) : undefined;
        if (!oldItem) {
          // Mark as new
          item._new = true;
          result.push(item);
        } else {
          // Item exists, add differences only
          if (Array.isArray(item)) {
            const itemDiff = this.getDiff(item, oldItem);

            if (itemDiff) {
              // Always add id field
              itemDiff.id = item[0].id;
              result.push(itemDiff);
            }
          }
        }
      }

      // Check if item is deleted and if the old data contains nested data which could have been updated
      if (oldData) {
        for (const oldDataItem of oldData) {
          if (oldDataItem !== null && typeof oldDataItem === 'object') {
            // Compare child elements as well
            for (const objectKey of Object.keys(oldDataItem)) {
              const newDataItem = newData.find(dataItem => dataItem.id === oldDataItem.id);

              if (Array.isArray(oldDataItem[objectKey])) {
                const itemDiff = this.getDiff(newDataItem ? newDataItem[objectKey] : [], oldDataItem[objectKey]);
                if (itemDiff) {
                  if (!result[objectKey]) {
                    result[objectKey] = itemDiff;
                  } else {
                    result[objectKey] = [...result[objectKey], ...itemDiff];
                  }
                }
              } else if (newDataItem && typeof oldDataItem[objectKey] === 'string') {
                if (oldDataItem[objectKey] !== newDataItem[objectKey]) {
                  result.push(newDataItem);
                }
              }
            }
          }

          if (oldDataItem !== null && newData.filter(dataItem => dataItem.id === oldDataItem.id).length === 0) {
            result.push({ _is_deleted: true, id: oldDataItem.id });
          }
        }
      }

      return Object.keys(result).length === 0 ? undefined : result;
    }

    if (!result) {
      result = {};
    }

    for (const key of Object.keys(newData)) {
      const newEntity = newData[key];
      const oldEntity = oldData[key];

      // Always add if old item not exists
      if (newEntity && !oldEntity) {
        result[key] = newEntity;
        // Mark each array entry as _new if array
        if (Array.isArray(result[key])) {
          for (const item of result[key]) {
            if (typeof item === 'object') {
              // Mark as new
              item._new = true;
            }
          }
        } else if (typeof result[key] === 'object') {
          // Mark as new
          result[key]._new = true;
        }

        continue;
      }

      if (Array.isArray(newEntity)) {
        const itemDiff = this.getDiff(newEntity, oldEntity);

        if (itemDiff) {
          result[key] = itemDiff;
        }

        continue;
      }

      if (newEntity && typeof newEntity === 'object') {
        const itemDiff = this.getDiff(newEntity, oldEntity);

        if (itemDiff) {
          result[key] = itemDiff;
        }

        continue;
      }

      if (newEntity !== oldEntity) {
        result[key] = newEntity;
      }
    }

    if (!result || Object.keys(result).length === 0) {
      return undefined;
    }

    // Add id field if available
    if (newData.id) {
      result.id = newData.id;
    }

    return result;
  }
}
