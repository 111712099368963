import { MapItem } from '@capturum/ui/api';
import { ApiIndexResult } from '@capturum/api';

// @dynamic
export class ConverterHelper {
  public static listToOption(items: { key: string, label: string }[]): MapItem[] {
    return items?.map((item: any) => {
      return { label: item.label, value: item.key };
    });
  }

  public static customObjectToOption<T>(response: T[], label: string[] = ['name'], value: string = 'id', delimiter: string = ' '): MapItem[] {
    return response?.map(item => ({
      label: label.reduce((acc, labelField) => acc += `${(item[labelField] ? item[labelField] + delimiter : '')}`, '').slice(0, -delimiter.length),
      value: item[value]
    }));
  }

  public static indexToOption(response: ApiIndexResult<any>, label: string = 'name', value: string = 'id'): MapItem[] {
    return response?.data.map(item => {
      return {
        label: item[label],
        value: item[value]
      };
    });
  }
}
