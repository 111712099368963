import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  DoCheck,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ListOptions, Meta } from '@capturum/api';
import {
  BuilderAction,
  CapturumBuilderActionService,
  CapturumBuildersContextService,
  ListRendererFilterConfig,
  ListRendererFilterConfigField
} from '@capturum/builders/core';
import {
  CapturumTemplateDirective,
  FilterMatchMode,
  ItemExpression,
  LocalStorageService,
  MapItem,
  TableAction,
  TableRouteConfigService,
  TableText
} from '@capturum/ui/api';
import {
  ActiveFilters,
  DynamicFilterConfig,
  DynamicFiltersUtils,
  DynamicFilterType
} from '@capturum/ui/dynamic-filters';
import { CapturumInfoTableComponent, InfoTableColumn, InfoTableConfigService } from '@capturum/ui/info-table';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { LazyLoadEvent } from 'primeng/api';
import { BehaviorSubject, combineLatest, fromEvent, merge, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, filter, map, shareReplay, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ListRendererFiltersComponent } from '../../components/list-renderer-filters/list-renderer-filters.component';
import { SEARCH_BAR } from '../../configs/filters.config';
import { ListRendererBreakpoints } from '../../enums/breakpoints.enum';
import { HeaderType } from '../../enums/header-type.enum';
import { ListRendererActionType } from '../../enums/list-renderer-action-types.enum';
import { PaginationType } from '../../enums/pagination-type.enum';
import { SettingsChangeEvent } from '../../interfaces/list-renderer-settings.interface';
import { ListRendererConfig } from '../../models/list-renderer-config.model';
import { ListRendererFilter } from '../../models/list-renderer-filter.model';
import { ListRendererActionsService } from '../../services/list-renderer-actions.service';
import { ListRendererApiService } from '../../services/list-renderer-api.service';
import { ListRendererColumnsService } from '../../services/list-renderer-columns.service';
import { ListRendererFiltersService } from '../../services/list-renderer-filters.service';
import { ListRendererService } from '../../services/list-renderer.service';
import { SetListConfiguration } from '../../state/list-renderer.actions';
import { ListRendererTableAction } from '../../types/list-renderer-table-action.type';
import { ListRendererUtils } from '../../utils/list-renderer.utils';
import { ListRendererConfigService } from './../../services/list-renderer-config.service';

@Component({
  selector: 'cpb-list-renderer',
  templateUrl: './list-renderer.component.html',
  styleUrls: ['./list-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CapturumListRendererComponent implements AfterViewInit, AfterContentInit, DoCheck, OnChanges, OnDestroy {
  @Input() public context: Record<string, any>;
  @Input() public contextKey: string;
  @Input()
  public noDataOnInit: boolean;
  @Input()
  public saveFiltersInUrl = false;
  @Input()
  public alwaysResetFilterOnHide: boolean;
  @Input()
  public dataKey: string;
  @Input()
  public scrollSelector = '.p-datatable-scrollable-body';
  @Input()
  public noDataOnInitText: string | Observable<string> = 'Start filtering to show results';
  @Input()
  public resizableColumns: boolean;
  @Output() public listRendererDataChange: EventEmitter<boolean> = new EventEmitter();
  @ViewChild(CapturumInfoTableComponent)
  public infoTable: CapturumInfoTableComponent;
  @ContentChildren(CapturumTemplateDirective)
  public templates: QueryList<any>;
  @ViewChild('header')
  public headerElement: ElementRef<HTMLDivElement>;
  @ViewChild(ListRendererFiltersComponent)
  public filtersComponent: ListRendererFiltersComponent;
  @Input()
  public perPageOptions: MapItem[];
  @Input()
  public storageKeyPrefix: string;
  @Input()
  public sidebarFiltering: boolean;
  @Input()
  public configureTableSettings: boolean;
  @Output()
  public filterChange = new EventEmitter<Record<string, any>>();
  @Output()
  public selectedRowsChange = new EventEmitter<any[]>();

  public data: any[] = [];
  public apiOptions: ListOptions = { page: 1, perPage: 10 };
  public tableActions: ListRendererTableAction[] = [];
  public paginator: Meta['pagination'];
  public topScroll = 0;
  public paginationType: PaginationType = PaginationType.Paginated;
  public PaginationType: typeof PaginationType = PaginationType;
  public headerType: HeaderType = HeaderType.Static;
  public HeaderType: typeof HeaderType = HeaderType;
  public filters: ListRendererFilter[];
  public columns: InfoTableColumn[] = [];
  public filtersConfig: Omit<ListRendererFilterConfig, 'fields'>;
  public clickAction: BuilderAction;
  public tableStyleClass: string;
  public loading = true;
  public loadingData: boolean;
  public selectedRows: any[] = [];
  public bulkActions: TableAction[] = [];
  public dragDropAction: BuilderAction;
  public columnActions: BuilderAction[];
  public headerActions: TableAction[] = [];
  public scrollable = false;
  public apiOptionsStateKey: string;
  public tableSettingsStateKey: string;
  public cardsView = true;
  public rows: number;
  public scrollHeight: string;
  public infoTableTexts: Partial<TableText> = {};
  public dynamicFilters: DynamicFilterConfig;
  public bulkActionsTemplate: TemplateRef<any>;
  public tableActionsTemplate: TemplateRef<any>;
  public rowStyleClassExpression: ItemExpression;
  public stateKey: string;
  public footerTemplate: TemplateRef<any>;
  public showSettingsSidebar: boolean;

  private routeFilters: ActiveFilters[];
  private lazyLoadEvent: LazyLoadEvent;
  private verticalScroll = 0;
  private scrollOffset = 80; // When user scroll down 80% of element height
  private config: BehaviorSubject<ListRendererConfig> = new BehaviorSubject<ListRendererConfig>(null);
  private config$: Observable<ListRendererConfig> = this.config.asObservable();
  private apiOptions$: BehaviorSubject<ListOptions>;
  private contextIsSet: boolean;
  private destroy$: Subject<boolean> = new Subject();
  private filtersReadySubject = new BehaviorSubject<boolean>(false);
  private listeningForScrollEvents = false;
  private parameters: {
    field: string;
    value: any;
  }[];
  private _defaultNoResultsText: string | Observable<string>;

  constructor(
    private readonly listBuilderActionsService: ListRendererActionsService,
    private readonly listBuilderColumnsService: ListRendererColumnsService,
    private readonly listBuilderFiltersService: ListRendererFiltersService,
    private readonly listBuilderApiService: ListRendererApiService,
    private readonly actionService: CapturumBuilderActionService,
    private readonly cdr: ChangeDetectorRef,
    private readonly contextService: CapturumBuildersContextService,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly store: Store,
    private readonly translateService: TranslateService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly tableRouteConfigService: TableRouteConfigService,
    private readonly localStorageService: LocalStorageService,
    private infoTableConfigService: InfoTableConfigService,
    private listRendererConfigService: ListRendererConfigService,
    private listRendererService: ListRendererService
  ) {
    this.apiOptions$ = new BehaviorSubject<ListOptions>(this.apiOptions);
    this.infoTableConfigService
      .getConfig()
      .pipe(take(1))
      .subscribe((config) => {
        this._defaultNoResultsText = config.defaultTexts.noResults;
      });
  }

  private _key: string;

  get key(): string {
    return this._key;
  }

  @Input()
  set key(value: string) {
    this._key = value;
    this.stateKey = `${this.storageKeyPrefix || ''}_state_${value}`;
    this.apiOptionsStateKey = `apiOptions-${value}`;
    this.tableSettingsStateKey = `tableSettings-${value}`;
  }

  private _configuration: ListRendererConfig;

  public get configuration(): ListRendererConfig {
    return this._configuration;
  }

  public get currentApiOptions(): ListOptions {
    return this.apiOptions$.getValue();
  }

  @Input()
  set configuration(value: ListRendererConfig) {
    this._configuration = value;
    if (value) {
      this.config.next(value);
      this.prepareDependencies();
      this.setFiltersReady();
    }
  }

  public ngAfterViewInit(): void {
    this.infoTable.templates.reset([...this.infoTable.templates, ...this.templates]);
    this.listenForRefreshEvents();
    this.buildFilters();
  }

  public ngAfterContentInit(): void {
    this.bulkActionsTemplate = this.templates.find((template) => template.getType() === 'bulkActions')?.template;
    this.tableActionsTemplate = this.templates.find((template) => template.getType() === 'tableActions')?.template;
    this.footerTemplate = this.templates.find((template) => template.getType() === 'footer')?.template;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!this.contextIsSet && this.key && !!this.context) {
      this.contextIsSet = true;

      this.contextService.setContext(this.contextKey || this.key, this.context);

      this.fetchListConfig();
    } else if (!this.contextIsSet && this.key) {
      this.fetchListConfig();
      this.contextIsSet = true;
    } else if (this.contextIsSet && changes.key) {
      this.fetchListConfig();
    }

    if (changes?.context?.currentValue) {
      this.contextService.setContext(this.contextKey || this.key, this.context);
    }
  }

  public ngOnDestroy(): void {
    this.contextService.clearContextByKey(this.contextKey || this.key);
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public ngDoCheck(): void {
    const filterHeight = 40;

    if (this.headerElement?.nativeElement) {
      this.tableStyleClass = `cpb-filter-height-${Math.ceil(
        this.headerElement.nativeElement.clientHeight / filterHeight
      )}`;
    }
  }

  public buildFilters(): void {
    combineLatest([
      this.activatedRoute?.queryParams?.pipe(filter(Boolean), take(1)),
      this.config$.pipe(filter(Boolean), take(1))
    ])
      .pipe(
        tap(([queryParams, { filters }]) => {
          if (queryParams) {
            this.routeFilters = this.retrieveStaticQueryParamFilters(queryParams);
          }
        }),
        map(([_, { filters, options }]) => ({ filters, options })),
        takeUntil(this.destroy$)
      )
      .subscribe(({ filters, options }: Partial<ListRendererConfig>) => {
        this.setDynamicFilters(filters, options);
      });
  }

  public setDynamicFilters(filters: ListRendererFilterConfig, options: { [key: string]: any }): void {
    const tableFilterSettings = this.configureTableSettings
      ? this.localStorageService.getItem(this.tableSettingsStateKey)?.filters || []
      : [];

    this.dynamicFilters = {
      filters: filters.fields
        .filter(
          (filter) =>
            !this.configureTableSettings ||
            !tableFilterSettings.find((setting) => setting.field === filter.name)?.hidden
        )
        .map((field) => ({
          field: field.name,
          type: field.type === 'text' ? DynamicFilterType.input : field.type,
          icon: field.icon,
          label: field.label,
          default: field.default,
          placeholder: field.placeholder,
          showLabel: false,
          matchMode: field.operator,
          selectionLimit: field?.options?.selectionLimit,
          resetFilterOnHide: this.alwaysResetFilterOnHide || field?.options?.resetFilterOnHide,
          autofocusFilter: field?.options?.autofocusFilter,
          dependsOn: field.dependencies,
          preventDisableDependency: field.prevent_disable_dependency_field,
          options: field?.options?.action?.endpoint
            ? this.listBuilderApiService
                .data(field.options.action.endpoint, this.key)
                .pipe(map((result) => result.data.map((item) => ({ label: item.label, value: item.key }))))
            : field?.options?.items
            ? field.options.items.map((item) => ({ label: item.label, value: item.key }))
            : null,
          ...field.options,
          ...([DynamicFilterType.multiselect, DynamicFilterType.dropdown].includes(
            field.type as unknown as DynamicFilterType
          ) && field.dependencies
            ? {
                updateListOptions: (listOptions: ListOptions) => {
                  return this.listBuilderApiService
                    .data(field.options.action.endpoint, this.key, listOptions)
                    .pipe(map((result) => result.data.map((item) => ({ label: item.label, value: item.key }))));
                }
              }
            : null)
        })),
      routeFilters: this.routeFilters,
      texts: {
        refresh: filters.refresh_button?.title || '',
        reset: filters.reset_button?.title || this.translateService.instant('reset.button'),
        apply: this.translateService.instant('button.submit'),
        cancel: this.translateService.instant('button.cancel')
      },
      icons: {
        reset: filters.reset_button?.icon || '',
        refresh: filters.refresh_button?.icon || ''
      }
    };

    if (filters.global_search?.enabled) {
      this.dynamicFilters.filters.unshift({
        field: SEARCH_BAR,
        label: this.translateService.instant('search.placeholder'),
        type: DynamicFilterType.input,
        matchMode: FilterMatchMode.LIKE,
        debounceTime: options?.searchDebounceTime,
        icon: 'fas fa-search',
        placeholder: this.translateService.instant('search.placeholder')
      });
    }

    this.cdr.detectChanges();
  }

  public setColumnsByConfig(config: ListRendererConfig): void {
    let columns = config.columns.map((column) => {
      if (this.columnActions.find((action) => action.options.column_key === column.field)) {
        column.disableRowClick = true;
      }

      return column;
    });

    if (this.configureTableSettings) {
      const storageColumns = this.localStorageService.getItem(this.tableSettingsStateKey)?.columns || [];

      columns = columns.filter(
        (column) => !storageColumns.find((settingsColumn) => settingsColumn.field === column.column)?.hidden
      );
    }

    this.columns = this.listBuilderColumnsService.prepareColumns(columns);

    this.cdr.detectChanges();
  }

  public loadTableData(event?: LazyLoadEvent): void {
    this.config$.pipe(take(1)).subscribe(() => {
      if (event) {
        this.lazyLoadEvent = event;

        if (this.paginationType === PaginationType.VirtualScroll) {
          this.parameters = [{ field: '_meta[offset]', value: event.first }];

          this.patchApiOptions({ perPage: event.rows, parameters: this.parameters });
        }

        this.setSorting();
      }
    });
  }

  public setTableData(): void {
    combineLatest([this.filtersReadySubject.pipe(filter(Boolean), take(1)), this.apiOptions$])
      .pipe(
        tap(() => (this.loadingData = true)),
        debounceTime(50),
        switchMap(([ready, apiOptions]) =>
          this.config$.pipe(
            filter(Boolean),
            map((config) => ({ apiOptions, config }))
          )
        ),
        switchMap(({ config, apiOptions }) => {
          const url = config.source.endpoint;
          const apiOptionsSort =
            apiOptions?.sort &&
            apiOptions.sort.every((sort) => {
              return !!sort.field;
            })
              ? apiOptions?.sort
              : null;
          const sort = apiOptionsSort ?? config?.source?.options?.sort ?? [];

          return this.listBuilderApiService
            .data(url, this.contextKey || this.key, {
              ...apiOptions,
              filters: [...(apiOptions?.filters || []), ...(config.source?.options?.filters || [])],
              sort: sort && Array.isArray(sort) ? sort.filter((sortOption) => sortOption.field) : []
            })
            .pipe(
              catchError(() => {
                return of({ meta: { pagination: this.paginator }, data: [] });
              })
            );
        }),
        tap((result) => {
          if (result?.meta) {
            this.paginator = {
              ...this.paginator,
              ...result.meta.pagination
            };
          }
        }),
        map((result) => {
          const { filters, search, page } = this.apiOptions$.getValue();

          if (this.noDataOnInit && !filters?.length && !search) {
            this.infoTableTexts = { ...this.infoTableTexts, noResults: this.noDataOnInitText };
            this.paginator = {
              current_page: 1,
              per_page: this.paginator.per_page,
              total_pages: 0,
              total: 0,
              count: 0
            };

            return [];
          }

          this.infoTableTexts = { ...this.infoTableTexts, noResults: this._defaultNoResultsText };

          if (this.paginationType === PaginationType.InfiniteScroll) {
            // Ensure the previous table data is empty if current page is 1
            if (page === 1) {
              this.data = [];
            }

            return [...(this.data ?? []), ...result.data];
          }

          return result.data;
        }),
        tap(() => {
          this.loading = false;
        }),
        shareReplay(1),
        takeUntil(this.destroy$)
      )
      .subscribe((result) => {
        if (!this.listeningForScrollEvents && this.paginationType === PaginationType.InfiniteScroll) {
          this.listenForScrollEvents();
        }

        if (this.paginationType === PaginationType.VirtualScroll) {
          Array.prototype.splice.apply(this.data, [...[this.lazyLoadEvent.first, this.lazyLoadEvent.rows], ...result]);

          this.data = [...this.data];
        } else {
          this.data = result;
        }

        this.listRendererDataChange.emit(true);

        this.loadingData = false;

        this.cdr.detectChanges();
      });
  }

  public fetchFilters(filtersValue: Record<string, any>): void {
    this.config$.pipe(filter(Boolean), take(1)).subscribe(({ filters }) => {
      this.parameters = null;
      this.loadingData = true;
      this.deleteSelectedRows();
      this.filtersWereUpdated(filtersValue, filters.fields);
      this.cdr.detectChanges();
    });
  }

  public filtersWereUpdated(filters: any, filterConfigs: ListRendererFilterConfigField[]): void {
    const routeConfig = this.activatedRoute?.snapshot?.queryParams;

    const page = this.filtersReadySubject.getValue() ? 1 : this.apiOptions$.getValue().page;

    const apiOptions = ListRendererUtils.setApiOptions(
      {
        ...this.apiOptions$.getValue(),
        page: this.saveFiltersInUrl && routeConfig?.page ? routeConfig?.page : page,
        perPage:
          this.saveFiltersInUrl && routeConfig?.perPage ? routeConfig?.perPage : this.apiOptions$.getValue()?.perPage,
        parameters: this.parameters
      },
      filters,
      filterConfigs
    );

    this.resetInfiniteScrollDependencies();
    this.setApiOptions(apiOptions);
    this.setFiltersReady();
    this.setApiOptionsState();

    this.filterChange.emit(filters);

    this.cdr.detectChanges();
  }

  public changePage(currentPage: number): void {
    if (this.paginationType === PaginationType.Paginated) {
      this.loading = true;
    }

    this.patchApiOptions({ page: currentPage + 1 });

    if (this.paginationType === PaginationType.Paginated) {
      this.setApiOptionsState();
    }
  }

  public changePerPage(perPage: number): void {
    this.loading = true;

    this.patchApiOptions({ perPage, page: 1 });
    this.setApiOptionsState();
  }

  public handleOnRowClick(item: any, ctrlKey: boolean): void {
    if (this.clickAction) {
      this.clickAction = {
        ...this.clickAction,
        ctrlKey
      };

      this.actionService.executeAction(this.clickAction, item, this.contextKey || this.key);
    }
  }

  public handleRowToggle(selectedRows: any[]): void {
    this.selectedRows = selectedRows;

    this.selectedRowsChange.emit(selectedRows);
  }

  public handleCancelSelection(): void {
    this.deleteSelectedRows();
  }

  public deleteSelectedRows(): void {
    this.selectedRows = [];

    const localTableConfig = this.localStorageService.getItem(this.stateKey) || {};

    localTableConfig.selection = [];

    this.localStorageService.setItem(this.stateKey, localTableConfig);
  }

  public handleDropEvent(): void {
    if (this.dragDropAction) {
      this.actionService.executeAction(this.dragDropAction, this.data, this.key, this.contextKey);
    }
  }

  public handleColumnClick(event: { column: string; row: any }): void {
    const action = this.columnActions.find((action) => action.options.column_key === event.column);
    this.actionService.executeAction(action, event.row, this.key, this.contextKey);
  }

  public updateTableData(): void {
    this.resetInfiniteScrollDependencies();
    this.refresh();
    this.cdr.detectChanges();
  }

  public resetFilters(): void {
    this.filtersComponent?.dynamicFiltersRef?.resetFilters();
    this.infoTable.primeNGTable.reset();
  }

  public handleResetFilter(): void {
    this.infoTable?.primeNGTable?.reset();
    this.infoTable?.primeNGTable?.saveState();
    this.setApiOptions({ ...this.apiOptions$.getValue(), page: 1, search: null });
    this.filterChange.emit(null);
    this.deleteSelectedRows();
  }

  public refresh(): void {
    this.filtersComponent?.refreshList();
  }

  // Static filters in queryParam should be in this order:
  // [staticFilters][order_id][in]: id-one, id-two
  // or when we need to specfy for which specific list renderer (last parameter is optional)
  // [staticFilters][order_id][in][list_key?]: id-one, id-two
  private retrieveStaticQueryParamFilters(queryParams: Params): ActiveFilters[] {
    return DynamicFiltersUtils.retrieveQueryParamFilters(queryParams, true, this.key);
  }

  private setSorting(): void {
    if (this.lazyLoadEvent?.sortField) {
      this.resetInfiniteScrollDependencies();

      const sortingApiOptions: Partial<ListOptions> = {
        sort: [
          {
            field: this.lazyLoadEvent?.sortField,
            direction: this.lazyLoadEvent?.sortOrder === -1 ? 'desc' : 'asc'
          }
        ]
      };

      if (this.paginationType === PaginationType.InfiniteScroll) {
        sortingApiOptions.page = 1;
        sortingApiOptions.perPage = this.currentApiOptions.perPage || 20;
      }

      this.patchApiOptions(sortingApiOptions);
    } else {
      this.patchApiOptions({ sort: null });
    }
  }

  private prepareDependencies(): void {
    this.config$.pipe(filter(Boolean)).subscribe((config: ListRendererConfig) => {
      const { fields, ...rest }: ListRendererFilterConfig = config.filters ?? {
        fields: [],
        global_search: null,
        reset_button: null,
        refresh_button: null
      };

      if (config.actions) {
        this.tableActions = this.listBuilderActionsService.prepareActions(
          config?.actions?.filter((action) => action.type === ListRendererActionType.dotMenu) || [],
          this.key,
          this.contextKey
        );
        this.bulkActions = this.listBuilderActionsService.prepareActions(
          config?.actions?.filter((action) => action.type === ListRendererActionType.bulkAction) || [],
          this.key,
          this.contextKey
        );

        this.columnActions = config?.actions
          ?.filter((action) => action.type === ListRendererActionType.onColumnClick)
          .map((action) => {
            return {
              key: action.key,
              options: action.action,
              type: action.action.type
            };
          });

        const dragDropAction = config?.actions?.find((action) => action.type === ListRendererActionType.drop);
        if (dragDropAction) {
          this.dragDropAction = {
            key: dragDropAction.key,
            options: dragDropAction.action,
            type: dragDropAction.action.type
          };
        }

        this.headerActions = this.listBuilderActionsService.prepareActions(
          config?.actions?.filter((action) => action.type === ListRendererActionType.headerButton) || [],
          this.key,
          this.contextKey
        );

        this.setColumnsByConfig(config);

        this.filters = this.listBuilderFiltersService.prepareFilters(fields || []);
        const clickAction = config?.actions?.find((action) => action.type === ListRendererActionType.onRowClick);

        if (clickAction) {
          this.clickAction = {
            key: clickAction.key,
            options: clickAction.action,
            type: clickAction.action.type,
            ctrlKey: false
          };
        }

        this.filtersConfig = rest;

        if (config?.pagination) {
          this.paginationType = config.pagination;
        }

        if (this.paginationType === PaginationType.VirtualScroll) {
          this.rows = 20;
          this.scrollHeight = '800px';
          this.scrollable = true;
        }

        if (this.paginationType === PaginationType.InfiniteScroll) {
          this.apiOptions = { perPage: config?.options?.perPage || 20, page: 1 };
          this.patchApiOptions({ perPage: config?.options?.perPage || 20 });

          if (config?.options?.scrollable) {
            this.scrollable = config.options.scrollable;
          }
        }

        if (config?.noDataOnInit) {
          this.noDataOnInit = config?.noDataOnInit;
        }

        if (config?.headerPosition) {
          this.headerType = config.headerPosition;
        }

        if (typeof config?.options?.cards_view !== 'undefined') {
          this.cardsView = config.options?.cards_view;
        }

        if (this.tableActions?.length) {
          this.columns.push(this.listBuilderColumnsService.actionColumn);
        }

        if (this.paginationType === PaginationType.Paginated) {
          if (this.perPageOptions?.length) {
            const routeConfigPerPage = this.activatedRoute?.snapshot?.queryParams?.perPage;

            this.apiOptions = {
              ...this.apiOptions$.value,
              perPage: this.saveFiltersInUrl ? routeConfigPerPage : this.perPageOptions[0].value,
              page: 1
            };

            this.apiOptions$.next(this.apiOptions);
          }

          this.patchApiOptions(this.getApiOptionsState());
        }

        this.setTableData();

        if (!this.filters.length && !config.filters?.global_search?.enabled) {
          this.setFiltersReady();
        }

        if (this.listRendererConfigService.config && this.listRendererConfigService.config[this.key]) {
          this.rowStyleClassExpression = this.listRendererConfigService.config[this.key].styleClassExpression;
        }

        this.cdr.detectChanges();
        this.setColumns(config);
      }
    });
  }

  public openTableSettingsSidebar(): void {
    this.showSettingsSidebar = true;
  }

  public onSettingsChange(settings: SettingsChangeEvent): void {
    this.showSettingsSidebar = false;
    this.localStorageService.setItem(this.tableSettingsStateKey, settings);

    if (settings.filters) {
      this.resetFilters();

      this.setDynamicFilters(this.config.getValue().filters, this.config.getValue().columns);
    }

    if (settings.columns) {
      this.setColumnsByConfig(this.config.getValue());
    }
  }

  private setApiOptionsState(): void {
    const { sort, ...apiOptions } = this.apiOptions$.value;

    if (this.saveFiltersInUrl) {
      this.tableRouteConfigService.saveConfigInParams({
        page: apiOptions.page,
        perPage: apiOptions.perPage
      });
    }

    localStorage.setItem(this.apiOptionsStateKey, JSON.stringify(apiOptions));
  }

  private getApiOptionsState(): { perPage: number; page: number } {
    return JSON.parse(localStorage.getItem(this.apiOptionsStateKey));
  }

  private fetchListConfig(): void {
    this.listBuilderApiService.getConfig(this.contextKey || this.key, this.key).subscribe((config) => {
      this.config.next(config);
      this.listenForScreenResize(config);

      this.store.dispatch(new SetListConfiguration(this.key, config));
    });

    this.prepareDependencies();
  }

  private setApiOptions(apiOptions: ListOptions): void {
    this.apiOptions$.next(apiOptions);
  }

  private patchApiOptions(apiOptions: Partial<ListOptions>): void {
    this.apiOptions$.next({ ...this.apiOptions$.value, ...apiOptions });
  }

  private resetInfiniteScrollDependencies(): void {
    this.verticalScroll = 0;
    this.data = [];
    this.loading = true;
  }

  private listenForRefreshEvents(): void {
    merge(
      this.listRendererService.getRefreshTable(),
      this.actionService.getActionExecutions().pipe(filter((message) => !!message?.action?.options?.refresh))
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.updateTableData());
  }

  private setFiltersReady(): void {
    if (!this.filtersReadySubject.value) {
      this.filtersReadySubject.next(true);
    }
  }

  private listenForScrollEvents(): void {
    const target = this.scrollable ? document.querySelector(this.scrollSelector) : window;

    if (target) {
      fromEvent(target, 'scroll')
        .pipe(
          filter(() => !this.loadingData), // do not listen to scroll since we already have request in progress for getting data
          takeUntil(this.destroy$)
        )
        .subscribe((event) => {
          const eventTarget = this.scrollable ? event.target : event.target['scrollingElement'];
          this.topScroll = eventTarget.scrollTop;
          const height = eventTarget.scrollHeight;

          if (this.topScroll > this.verticalScroll && this.apiOptions$?.value?.page < this.paginator?.total_pages) {
            this.verticalScroll = (height * this.scrollOffset) / 100;
            this.changePage(this.apiOptions$?.value?.page || 1);
            this.cdr.detectChanges();
          }
        });
    }

    this.listeningForScrollEvents = true;
  }

  private listenForScreenResize(config: ListRendererConfig): void {
    this.breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Tablet, Breakpoints.Web])
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.setColumns(config);
      });
  }

  private setColumns(config: ListRendererConfig): void {
    if (this.breakpointObserver.isMatched([Breakpoints.XSmall])) {
      this.columns = this.mapColumnsByBreakpoint(this.columns, ListRendererBreakpoints.mobile, config);
    } else if (this.breakpointObserver.isMatched([Breakpoints.Web])) {
      this.columns = this.mapColumnsByBreakpoint(this.columns, ListRendererBreakpoints.desktop, config);
    } else if (this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.columns = this.mapColumnsByBreakpoint(this.columns, ListRendererBreakpoints.tablet, config);
    }
  }

  private mapColumnsByBreakpoint(
    columns: InfoTableColumn[],
    breakpoint: ListRendererBreakpoints,
    listRendererConfig: ListRendererConfig
  ): InfoTableColumn[] {
    return columns.map((column) => {
      const columnConfig = listRendererConfig.columns.find((config) => config.column === column.field);

      if (columnConfig) {
        column.hidden = columnConfig.breakpoints && columnConfig.breakpoints[breakpoint] === false;
      }

      return column;
    });
  }
}
