import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ModuleTenantService } from '../module-tenant.service';
import { ModuleTenantIndexedDbModel } from '../module-tenant.indexedDb-model';

@Injectable()
export class ModuleTenantResolver  {
  constructor(private moduleTenantService: ModuleTenantService) {
  }

  /**
   * Make sure the module tenants are loaded before accessing the route
   *
   * @param route
   * @param state
   *
   * @return Observable<ModuleTenant[]>
   */
  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ModuleTenantIndexedDbModel[]> {
    return this.moduleTenantService.loadModuleTenants();
  }
}
