import { ChangeDetectionStrategy, Component, input, ViewEncapsulation } from '@angular/core';
import { CapturumSplitButtonModule, SplitButtonItem } from '@capturum/ui/split-button';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cap-three-dot-menu-button',
  standalone: true,
  imports: [CapturumSplitButtonModule, TranslateModule, CapturumSplitButtonModule],
  templateUrl: './three-dot-menu-button.component.html',
  styleUrl: './three-dot-menu-button.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThreeDotMenuButtonComponent {
  public items = input.required<SplitButtonItem[]>();
  public dataTestId = input.required<string>();
}
