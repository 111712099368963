import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, Injector, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiIndexResult, ApiService, ListOptions } from '@capturum/api';
import { LocalStorageService, TableColumn, TableFilter, TablePaginator } from '@capturum/ui/api';
import { TranslateService } from '@ngx-translate/core';
import { format, isDate, isMatch } from 'date-fns';
import { ConfirmationService, FilterMetadata, LazyLoadEvent } from 'primeng/api';
import { Table } from 'primeng/table';
import { Observable } from 'rxjs';

import { ToastService } from '../../../toast.service';

interface Sort {
  field: string;
  direction: 'asc' | 'desc';
}

interface Filter {
  [s: string]: FilterMetadata;
}

interface ApiFilter {
  field: string;
  value: any;
  operator: string;
}

interface ItemNames {
  plural: string;
  singular: string;
}

@Component({
  template: ''
})
export class BaseListComponent<T> {
  @ViewChild('tt') public table: Table;

  // Add your apiService to your child constructor to make use of this
  public apiService: ApiService<T>;

  /**
   * Api service method used to retrieve data
   */
  public indexMethod: string;

  /**
   * The router instance
   */
  public baseRouter: Router;

  /**
   * The confirmation service instance
   */
  public confirmationService: ConfirmationService;

  /**
   * The toast service instance
   */
  public toastService: ToastService;

  /**
  * The localStorageService service instance
  */
  public localStorageService: LocalStorageService;

  /**
   * Event for lazy loading table data
   */
  public lazyLoadEvent: LazyLoadEvent;

  /**
   * Property indicating if the table is visible
   */
  public tableVisible: boolean;

  /**
   * Data of the table
   */
  public tableData: any;

  /**
   * Column definition
   */
  public columns: TableColumn[];
  /**
   * Paginator object for the table
   */
  public paginator: TablePaginator;

  /**
   * Sorting properties for the table
   */
  public sort: Sort[] = [];

  /**
   * The relational fields that will be included when fetching a resource
   */
  public includes: string[];

  /**
   * Search query for the table
   */
  public search: string[] = null;

  /**
   * Search parameters for the table
   */
  public parameters?: { field: string; value: any }[];

  /**
   * Optional filters for the table
   */
  public filters: Filter;

  /**
   * Property indicating if the table is loading
   */
  public loading = true;

  /**
   * The frontend route
   */
  public routePath: string;

  /**
   * The local storage key of the saved table filters
   */
  public stateKey: string;

  /**
   * Translations of the resource
   */
  public itemNames: ItemNames;

  /**
   * Optional callback after getting resources from backend
   */
  public loadTableDataCallback: (data: any) => void;


  /**
   * Optional callback after error is returned from backend
   */
  public onTableError: (data: any) => void;

  /**
   * Request options
   */
  public requestOptions: ListOptions;

  /**
   * The Count list option to be added to the index request
   */
  public count: string[] = [];

  /**
   * Extra arguments to pass the the index method besides the api listoptions
   */
  public indexMethodArguments: any[];

  public cdr: ChangeDetectorRef;

  /**
   * BaseListComponent constructor
   *
   * @return void
   */
  constructor(
    public injector: Injector,
    public translateService: TranslateService
  ) {
    this.baseRouter = injector.get(Router);
    this.confirmationService = injector.get(ConfirmationService);
    this.toastService = injector.get(ToastService);
    this.localStorageService = injector.get(LocalStorageService);
    this.cdr = this.injector.get(ChangeDetectorRef);

    this.indexMethod = 'index';
    this.tableVisible = true;
    this.routePath = null;
    this.includes = [];

    this.paginator = {
      rows: null,
      total: null,
      current_page: 1,
      total_pages: 1,
      per_page: null,
      first: 0
    };

    this.filters = {};

    this.itemNames = {
      plural: 'Items',
      singular: 'Item'
    };
  }

  /**
   * Set the list columns
   *
   * @param columns
   *
   * @return void
   */
  public setColumns(columns: TableColumn[]): void {
    this.columns = TableColumn.getColumns(columns);
  }

  /**
   * Load the table data if received event is set
   *
   * @param event
   *
   * @return void
   */
  public loadTableData(event?: LazyLoadEvent): void {
    if (event) {
      this.lazyLoadEvent = event;
      this.loadTableDataFromCurrentLazyLoadEvent();
    }
  }

  /**
   * Get the items
   *
   * @param event
   * @param items Optional object of item names
   *
   * @return Observable<any>
   */
  public readItems(event: LazyLoadEvent, items: any = this.itemNames): Observable<any> {
    return new Observable((observer) => {
      this.loading = true;

      const options = this.getListOptions();
      const indexMethod = this.indexMethodArguments ? this.apiService[this.indexMethod](options, ...this.indexMethodArguments) : this.apiService[this.indexMethod](options)

      this.requestOptions = options;

      indexMethod.subscribe((response: ApiIndexResult<any>) => {
        if (response?.meta?.pagination) {
          this.paginator = { ...response.meta.pagination, rows: response.meta.pagination.per_page };

          if (Number.isInteger(event?.first)) {
            this.paginator.first = event.first;
          }
        }

        this.loading = false;

        observer.next(response?.data);
      }, errorResponse => {
        this.loading = false;
        observer.error(errorResponse);
      });
    });
  }

  /**
   * Open item detail screen
   *
   * @param id
   * @param routePath Optionally override default base route path
   *
   * @return Promise<boolean>
   */
  public editItem(id: number | string, routePath: string = this.routePath): Promise<boolean> {
    if (routePath === null) {
      return;
    }

    if (routePath === this.routePath) {
      return this.baseRouter.navigate([`/${routePath}/${id}`]);
    }

    return this.baseRouter.navigate([`${routePath}`]);
  }

  /**
   * Delete an item
   *
   * @param id
   * @param item Optional
   *
   * @return void
   */
  public deleteItem(id: number | string, item: string = this.itemNames.singular): void {
    if (this.apiService === null) {
      return;
    }

    this.loading = true;

    this.apiService.delete(id).subscribe({
      next: () => {
        this.tableVisible = false;

        this.loadTableData(this.lazyLoadEvent);

        this.toastService.success(this.translateService.instant('toast.success.title'), this.translateService.instant('list.item_deleted'));
      },
      error: (error) => {
        this.handleError(error);

        this.loading = false;
      },
    });
  }

  /**
   * Delete an item with confirmation dialog
   *
   * @param id
   * @param item
   * @param message
   *
   * @returns void
   */
  public deleteItemWithConfirm(id: number | string, item: string = this.itemNames.singular, message?: string): void {
    if (message === undefined) {
      message = this.translateService.instant('list.delete_confirmation');
    }

    this.confirmationService.confirm({
      header: item,
      message,
      accept: () => {
        this.deleteItem(id, item);
      },
      reject: () => {
        this.loading = false;
      }
    });
  }

  public setFilter(value: string[] | Date, field: string, matchMode: string): void {
    if (!Array.isArray(value) && value || (Array.isArray(value) && (value as string[]).length)) {
      this.filters[field] = { value, matchMode };
    } else {
      delete this.filters[field];
    }
  }

  public filterTable(event: TableFilter): void {
    this.filters = {};

    for (const field in event) {
      if (event.hasOwnProperty(field)) {
        this.setFilter(event[field].value, field, event[field].matchMode);
      }
    }

    this.loadTableData({ ...event, ...this.paginator });
  }

  public onSearchEvent(searchStr: string, reloadFlag: boolean): void {
    this.search = [searchStr];

    if (reloadFlag) {
      this.loadTableData({ globalFilter: searchStr });
    }
  }

  protected handleError(response: HttpErrorResponse): void {
    this.toastService.error(this.translateService.instant('toast.error.title'), this.translateService.instant('toast.error.message'));
  }

  /**
   * Format given filters
   *
   * @param filters
   */
  protected formatFilters(filters: any): ApiFilter[] {
    const apiFilters = [];

    for (const field in filters) {
      if (filters[field] === null) {
        return;
      }

      if (Array.isArray(filters[field])) {
        for (const f in filters[field]) {
          apiFilters.push(this.parseFilter(field, filters[field][f]));
        }
      } else {
        apiFilters.push(this.parseFilter(field, filters[field]));
      }
    }

    return apiFilters;
  }

  protected parseFilter(field: string, filter: { value: any, matchMode?: string }): ApiFilter {
    let value = filter.value;
    const operator = filter.matchMode;

    // Add percent signs to value for like operators
    if (['like', 'notlike'].indexOf(operator) !== -1) {
      value = `%${value}%`;
    }

    // Format date values
    if (Array.isArray(value)) {
      value = value.map((val) => {
        if (isMatch(String(val), 'dd-MM-yyyy') || isDate(val)) {
          return format(new Date(val), 'yyyy-MM-dd');
        }

        return val;
      });
    }

    return {
      field: field,
      value: value,
      operator: operator
    };
  }

  /**
   * Load the table data from existing this.lazyLoadEvent object
   * @return void
   */
  protected loadTableDataFromCurrentLazyLoadEvent(): void {
    this.readItems(this.lazyLoadEvent).subscribe(data => {
      // Delete variable before recreating (IE11 fix)
      delete this.tableData;

      this.tableVisible = true;

      if (this.loadTableDataCallback !== undefined) {
        this.loadTableDataCallback(data);
      } else {
        this.tableData = data;
      }

      this.cdr.detectChanges();
    }, error => {
      this.handleError(error);

      this.sort = [];
      this.filters = {};
      this.lazyLoadEvent = null;
      this.tableVisible = true;

      if (this.onTableError !== undefined) {
        this.onTableError(error);
      }

      this.cdr.detectChanges();
    });
  }

  protected getListOptions(): ListOptions {
    const event = this.lazyLoadEvent;
    const storedFilters = this.stateKey && this.localStorageService.getItem(this.stateKey);

    this.setSorting();

    return {
      search: event.globalFilter ? [event.globalFilter] : this.search,
      sort: this.sort,
      include: this.includes,
      perPage: event.rows || storedFilters?.rows || 10,
      page: Math.floor(event.first / (event.rows || storedFilters?.rows || 10) + 1),
      parameters: this.parameters || null,
      count: this.count,
      // Merge component filters with table filters.
      filters: this.formatFilters({ ...this.filters, ...event.filters }),
    };
  }

  protected setSorting(): void {
    const event = this.lazyLoadEvent;
    const lastSortOptions: Sort = { field: event.sortField, direction: event.sortOrder === -1 ? 'desc' : 'asc' };
    const isLastSortOptionsSet = Boolean(event.sortField && event.sortOrder);

    // Merge sorting options when table's sortMode is multiple.
    if (this.table && this.table.sortMode === 'multiple') {
      if (Array.isArray(this.sort) && isLastSortOptionsSet) {
        this.sort = [...this.sort, lastSortOptions];
      }
    } else {
      // Replace default sorting options with lastSortOptions when table's sort mode is single.
      if (isLastSortOptionsSet) {
        this.sort = [lastSortOptions];
      }
    }
  }
}
