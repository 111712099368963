import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'cap-pbi-reports-detail',
  templateUrl: './reports-detail.component.html',
  styleUrls: ['./reports-detail.component.scss'],
})
export class CapturumPowerBIReportsDetailComponent implements OnInit {
  public reportId$: Observable<string>;

  constructor(
    private route: ActivatedRoute,
  ) {
  }

  public ngOnInit(): void {
    this.reportId$ = this.route.params.pipe(
      filter(params => params.id),
      map(({ id }) => id),
    );
  }
}
