import { Pipe, PipeTransform, QueryList, TemplateRef } from '@angular/core';

/**
 * @jsdoc Pipe
 *
 * @description
 * This pipe is used to extract TemplateRef from QueryList instance.
 *
 * @example
 *  templates | columnTemplate: 'templateName'
 */
@Pipe({
  name: 'columnTemplate',
})
export class CapturumColumnTemplatePipe implements PipeTransform {
  public transform(value: QueryList<any>, name: string): TemplateRef<any> {
    const node = value?.find(item => item.name === name);

    return node ? node.template : null;
  }
}
