import { UrlInputTypeBuilder } from './../builders/url/url-input-type.builder';
import { HiddenInputTypeBuilder } from './../builders/hidden/hidden-input-type.builder';
import { EntityTextInputTypeBuilder } from './../builders/entity-text/entity-text-input-type.builder';
import { TranslatedInputTypeBuilder } from './../builders/translated/translated-input-type.builder';
import { Type } from '@angular/core';
import { InputType } from '@capturum/builders/core';
import { CheckboxInputTypeBuilder } from '../builders/checkbox/checkbox-input-type.builder';
import { CheckboxListInputTypeBuilder } from '../builders/checkbox-list/checkbox-list-input-type.builder';
import { TextInputTypeBuilder } from '../builders/text/text-input-type.builder';
import { TextareaInputTypeBuilder } from '../builders/textarea/textarea-input-type.builder';
import { LabelInputTypeBuilder } from '../builders/label/label-input-type.builder';
import { EmailInputTypeBuilder } from '../builders/email/email-input-type.builder';
import { DateInputTypeBuilder } from '../builders/date/date-input-type.builder';
import { RadioInputTypeBuilder } from '../builders/radio/radio-input-type.builder';
import { DropdownInputTypeBuilder } from '../builders/dropdown/dropdown-input-type.builder';
import { CustomInputTypeBuilder } from '../builders/custom/custom-input-type.builder';
import { FileInputTypeBuilder } from '../builders/file/file-input-type.builder';
import { MultiSelectInputTypeBuilder } from '../builders/multi-select/multi-select-input-type.builder';
import { ToggleInputTypeBuilder } from '../builders/toggle/toggle-input-type.builder';
import { RepeatInputTypeBuilder } from '../builders/repeat/repeat-input-type.builder';
import { ImageInputTypeBuilder } from '../builders/image/image-input-type.builder';
import { NumberFormatTypeBuilder } from '../builders/number-format/number-format-type.builder';
import { CheckboxValueInputTypeBuilder } from '../builders/checkbox-value/checkbox-value-input-type.builder';
import { IconInputTypeBuilder } from '../builders/icon/icon-input-type.builder';
import { EditorInputTypeBuilder } from '../builders/editor/editor-input-type.builder';

export const inputTypeBuilders: Record<string, Type<any>> = {
  [InputType.checkbox]: CheckboxInputTypeBuilder,
  [InputType.checkboxList]: CheckboxListInputTypeBuilder,
  [InputType.text]: TextInputTypeBuilder,
  [InputType.textarea]: TextareaInputTypeBuilder,
  [InputType.label]: LabelInputTypeBuilder,
  [InputType.email]: EmailInputTypeBuilder,
  [InputType.image]: ImageInputTypeBuilder,
  [InputType.number]: NumberFormatTypeBuilder,
  [InputType.date]: DateInputTypeBuilder,
  [InputType.radio]: RadioInputTypeBuilder,
  [InputType.dropdown]: DropdownInputTypeBuilder,
  [InputType.custom]: CustomInputTypeBuilder,
  [InputType.file]: FileInputTypeBuilder,
  [InputType.multiSelect]: MultiSelectInputTypeBuilder,
  [InputType.toggle]: ToggleInputTypeBuilder,
  [InputType.repeat]: RepeatInputTypeBuilder,
  [InputType.translated]: TranslatedInputTypeBuilder,
  [InputType.entityText]: EntityTextInputTypeBuilder,
  [InputType.editor]: EditorInputTypeBuilder,
  [InputType.checkboxValue]: CheckboxValueInputTypeBuilder,
  [InputType.hidden]: HiddenInputTypeBuilder,
  [InputType.url]: UrlInputTypeBuilder,
  [InputType.icon]: IconInputTypeBuilder,
};
