import { Observable } from 'rxjs';

export class GeolocationHelper {
  public static getGeoData(): Observable<{ lat: number; lon: number }> {
    if (navigator.geolocation) {
      return new Observable((observer) => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const lat = position.coords.latitude;
            const lon = position.coords.longitude;

            observer.next({ lat, lon });
          },
          () => {
            observer.error();
          });
      });
    }
  }
}
