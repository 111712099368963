<div class="cpb-tooltip-arrow"
     [ngClass]="{
       'arrow-top': ['bottom-right', 'bottom-left'].includes(position),
       'arrow-bottom': ['top-right', 'top-left'].includes(position),
       short: isShort
     }">
</div>

<div [class]="'cpb-tooltip-body ' + position"
     [ngClass]="{
       'body-bottom': ['bottom-right', 'bottom-left'].includes(position),
       'body-top': ['top-right', 'top-left'].includes(position),
       short: isShort
     }">
  <ng-content></ng-content>
</div>
