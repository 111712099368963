import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { TableAction } from '@capturum/ui/api';

@Component({
  selector: 'cpb-list-renderer-bulk-actions',
  templateUrl: './list-renderer-bulk-actions.component.html',
  styleUrls: ['./list-renderer-bulk-actions.component.scss'],
})
export class ListRendererBulkActionsComponent {
  public isOpen: boolean = false;
  @Input()
  public actions: TableAction[] = [];
  @Input()
  public selectedItems: any[] = [];
  @Input()
  public contentTemplate: TemplateRef<any>;
  @Output()
  public cancelSelection = new EventEmitter<void>()
  @Output()
  public actionExecuted = new EventEmitter<void>();

  public executeAction(action: TableAction): void {
    action.callback(this.selectedItems);
    this.actionExecuted.emit();
  }

  public handleCancelSelection(): void {
    this.cancelSelection.emit();
  }
}
