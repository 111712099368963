<div (capClickOutside)="closeContainer($event)">
  <cap-button styleClass="secondary round-button sort-table-button w-100"
             icon="fas fa-sort" [label]="sortButtonLabel | observablePipe | async"
             class="round-button"
             (click)="showSortContainer = !showSortContainer;">
  </cap-button>

  <div class="sort-container example-list" [class.d-none]="!showSortContainer" cdkDropList
       (cdkDropListDropped)="drop($event)">
    <div class="row example-box sort-item m-0" cdkDrag [cdkDragDisabled]="sortField.sortDirection === null"
         [cdkDragData]="sortField" *ngFor="let sortField of columns; let index = index; trackBy: trackByFn">
      <div class="col-1">
        <i class="fas fa-ellipsis-v" *ngIf="sortField.sortDirection"></i>
      </div>

      <div class="col-3">
        {{ sortField.header | observablePipe | async }}
      </div>

      <div class="col-4">
        <p-radioButton *ngIf="hiddenIndex !== index" (onClick)="onRadioChange()"
                       name="sortOrder-{{ index }}"
                       value="asc"
                       [label]="ascendingLabel | observablePipe | async"
                       [(ngModel)]="sortField.sortDirection">
        </p-radioButton>
      </div>

      <div class="col-3">
        <p-radioButton *ngIf="hiddenIndex !== index" (onClick)="onRadioChange()"
                       name="sortOrder-{{ index }}"
                       value="desc"
                       [label]="descendingLabel | observablePipe | async"
                       [(ngModel)]="sortField.sortDirection">
        </p-radioButton>
      </div>

      <div class="col-1">
        <i class="fas fa-trash text-danger"
           (click)="removeSorting(index)" *ngIf="sortField.sortDirection">
        </i>
      </div>
    </div>
  </div>
</div>
