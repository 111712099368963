import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@capturum/auth';
import { Role } from '../../../domain/role/models/role.model';
import { RoleService } from '../../../domain/role/services/role.service';

@Component({
  selector: 'emc-role-selector',
  templateUrl: './role-selector.component.html'
})
export class RoleSelectorComponent implements OnInit {
  public selectedRole: Role;
  public roles: Role[];

  constructor(private authService: AuthService,
              private roleService: RoleService,
              private router: Router) {
  }

  public ngOnInit(): void {
    const user: any = this.authService.getUser();

    if (user) {
      this.roles = user.roles;

      if (user.currentRole) {
        this.selectedRole = user.currentRole;
      }
    } else {
      this.roles = [];
    }
  }

  /**
   * Set the current user role
   */
  public selectRole(): void {
    if (this.selectedRole) {
      this.roleService.setUserRole(this.selectedRole).subscribe(() => {
        this.router.navigate(['/']).then(() => {
          this.router.navigateByUrl('/admin/users/profile');
        });
      });
    }
  }

  /**
   * Hide role selector popup
   */
  public onCancel(): void {
    this.router.navigateByUrl('/admin/users/profile');
  }
}
