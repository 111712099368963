import { Injectable } from '@angular/core';
import { FormControl, FormGroup, NgControl } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';

export const ValidationMessage = {
  required: 'validation-errors.required',
  email: 'validation-errors.email',
  minlength: 'validation-errors.minlength',
  maxlength: 'validation-errors.maxlength',
  min: 'validation-errors.min',
  max: 'validation-errors.max',
  pattern: 'validation-errors.pattern',
  password: 'validation-errors.password',
  fileType: 'validation-errors.file-type',
  requiredFileType: 'validation-errors.required-file-type',
  requiredFileSize: 'validation-errors.required-file-size',
  specialChars: 'validation-errors.no-special-characters',
  script: 'validation-errors.no-scripts',
};

export interface ValidationMessages {
  required?: string;
  email?: string;
  minlength?: string;
  maxlength?: string;
  min?: string;
  max?: string;
  pattern?: string;
  password?: string;
  fileType?: string;
  requiredFileType?: string;
  requiredFileSize?: string;

  [key: string]: string;
}

export interface MessagesResponse {
  messages: ValidationMessages;
}

@Injectable({
  providedIn: 'root'
})
export class ValidatorService {
  public messages: BehaviorSubject<MessagesResponse> = new BehaviorSubject<MessagesResponse>({
    messages: ValidationMessage
  });
  public messages$: Observable<MessagesResponse> = this.messages.asObservable();
  public backendValidation: BehaviorSubject<Record<string, string[]>> = new BehaviorSubject(null);
  public backendValidation$: Observable<Record<string, string[]>> = this.backendValidation.asObservable();

  public extractFormControl(ngControl: NgControl): FormControl | null {
    if (ngControl) {
      return ngControl.control as FormControl;
    }

    return null;
  }

  public setValidationMessages(messages: ValidationMessages): void {
    this.messages.next({ messages: { ...ValidationMessage, ...messages } });
  }

  public setBackendErrorsOnForm(form: FormGroup, backendErrors: Record<string, string[]>): void {
    const generatedErrors = {};

    Object.keys(backendErrors).forEach(key => {
      const errorKey = `BE_${key}`;

      if (form.controls[key]) {
        if (Array.isArray(backendErrors[key])) {
          form.controls[key].setErrors({ [errorKey]: true });
          generatedErrors[errorKey] = backendErrors[key].join(' | ');
        }
      }
    });

    this.setValidationMessages(generatedErrors);
  }
}
