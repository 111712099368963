import { MenuItem as CompleteMenuItem } from 'primeng/api';
import { SplitButtonItem } from '@capturum/ui/split-button';

export interface HeaderTabsConfig {
  title: string;
  backRoute?: string;
  backButtonLabel?: string;
  backButtonIconClass?: string;
  userMenu?: UserMenu;
  headerMenuItems?: MenuItem[];
  buttonLabel?: string;
  buttonIcon?: string;
  buttonStyleClass?: string;
  showHeader?: boolean;
}

export interface UserMenu {
  items: MenuItem[];
  title: string;
  subTitle?: string;
}

export interface ButtonConfig {
  label?: string;
  styleClass?: string;
  icon?: string;
  visible: boolean;

  onClick?(event: any): void;
}

export interface MenuItem extends CompleteMenuItem {
  requiredPermission?: string;
  menuButtonItems?: SplitButtonItem[];
}
