import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'baseDataValue'
})
export class BaseDataValuePipe implements PipeTransform {
  constructor(
    private readonly translateService: TranslateService
  ) {
  }

  public transform(value: string, notTranslated: boolean = false): string {
    const key = `base-data.${value}`;

    if (notTranslated) {
      return key;
    }

    return value ? this.translateService.instant(key) : null;
  }
}
