import { NgModule } from '@angular/core';
import { CapturumHeaderModule } from './header/header.module';
import { CapturumHeadlineModule } from './headline/headline.module';
import { CapturumLayoutDetailsModule } from './details/details.module';
import { CapturumSharedModule } from '@capturum/ui/api';

@NgModule({
  imports: [
    CapturumSharedModule
  ],
  exports: [
    CapturumHeaderModule,
    CapturumHeadlineModule,
    CapturumLayoutDetailsModule,
  ]
})
export class CapturumLayoutModule {
}
