<cap-accordion-card #accordionCard [isOpen]="!to.collapsed" [clickable]="clickable" [headerTemplate]="accordionHeader">
  <ng-template capTemplate="content">
    <ng-container #fieldComponent></ng-container>
  </ng-template>
</cap-accordion-card>

<ng-template #accordionHeader>
    <div class="ac-header__left">
      <p class="accordion-card__title" (click)="toggleCard()">
          {{ to.title }}
      </p>

      <div class="accordion-card__actions">
          <cpb-form-tooltip [text]="to.tooltip" class="tooltip-content"></cpb-form-tooltip>
      </div>
  </div>
</ng-template>
