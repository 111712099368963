import { FieldType } from '@ngx-formly/core';
import { Component } from '@angular/core';

@Component({
    selector: 'em-formly-autocomplete-dropdown',
    templateUrl: './autocomplete-dropdown.component.html'
})
export class AutocompleteDropdownComponent extends FieldType {
    /**
     * Filtered results of autocomplete dropdown component
     */
    public filteredResults: any[] = [];

    /**
     * Filters results
     *
     * @param event: any
     * @param options: any[]
     * @returns void
     */
    public filterResults(event: any, options: any[]): void {
        this.filteredResults = [];
        const field = (this.to.additionalProperties && this.to.additionalProperties['filterField']) ? this.to.additionalProperties['filterField'] : 'label';

        options.forEach((option: any) => {
            if (!option[field] || option[field] === undefined) {
                return;
            }

            if (option[field].toLowerCase().indexOf(event.query.toLowerCase()) === 0) {
                this.filteredResults.push(option);
            }
        });
    }
}
