import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SplitButtonModule } from 'primeng/splitbutton';

import { CapturumSplitButtonComponent } from './split-button.component';

@NgModule({
  imports: [
    CommonModule,
    SplitButtonModule,
  ],
  exports: [
    CapturumSplitButtonComponent,
  ],
  declarations: [CapturumSplitButtonComponent]
})
export class CapturumSplitButtonModule { }
