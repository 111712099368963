import { Store } from '@ngxs/store';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayValueByCurrentLocale'
})
export class DisplayValueByCurrentLocalePipe implements PipeTransform {

  constructor(private readonly store: Store) {
  }

  public transform(value: { [locale: string]: string }[]): any {
    const currentLocale = this.store.selectSnapshot(state => state.formBuilder.currentUser.locale.code);

    return value[0].hasOwnProperty(currentLocale) ? value[0][currentLocale] : null;
  }

}
