import { ComponentFactoryResolver, ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import { ComponentType } from '@angular/cdk/overlay';
import { ListRendererWidget } from '../interfaces/list-renderer-widget';
import {
  ListRendererWidgetBaseDataComponent,
  ListRendererWidgetConcatenationComponent,
  ListRendererWidgetImageComponent,
  ListRendererWidgetLinkComponent,
} from '../widgets';
import {
  ListRendererWidgetBooleanComponent
} from '../widgets/list-renderer-widget-boolean/list-renderer-widget-boolean.component';
import { ListRendererConfigService } from './list-renderer-config.service';

@Injectable()
export class ListRendererWidgetsService {
  public widgets: Record<string, ComponentType<ListRendererWidget>> = {
    image: ListRendererWidgetImageComponent,
    link: ListRendererWidgetLinkComponent,
    fullName: ListRendererWidgetConcatenationComponent,
    baseData: ListRendererWidgetBaseDataComponent,
    ['base-data-value']: ListRendererWidgetBaseDataComponent,
    boolean: ListRendererWidgetBooleanComponent,
  };

  constructor(
    private listRendererConfigService: ListRendererConfigService,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {
    this.widgets = {
      ...this.widgets,
      ...this.listRendererConfigService.config?.widgets,
    };
  }

  public loadWidget(viewContainerRef: ViewContainerRef,
                    component: ComponentType<ListRendererWidget>,
                    data?: Record<string, any>): ComponentRef<ListRendererWidget> {
    viewContainerRef.clear();

    const widgetComponent = viewContainerRef.createComponent(this.componentFactoryResolver.resolveComponentFactory(component));

    if (data) {
      Object.keys(data).forEach(key => {
        widgetComponent.instance[key] = data[key];
      });
    }

    return widgetComponent;
  }
}
