<cap-input
  [formControl]="formControl"
  [floatLabel]="(to.label_position | observablePipe | async) === labelPosition.Floating"
  [label]="(to.label_position | observablePipe | async) === labelPosition.Floating ? to.label : ''"
  [readonly]="to.readonly"
  [selectOnFocus]="to?.select_on_focus"
  [hasTooltip]="to.tooltip"
  [attr.data-test]="'builder-' + field.key"
  [hidden]="to.readonly"
  [placeholder]="formState.readonly ? '' : to.placeholder"
  type="number">
</cap-input>

<cpb-readonly-container [hidden]="!to.readonly" [attr.data-test]="'builder-readonly-' + field.key">
  {{ formControl.value | default : to?.defaultEmptyValue }}
</cpb-readonly-container>
