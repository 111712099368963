import { CapturumInfoTableModule } from '@capturum/ui/info-table';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CapturumListRendererModule } from '@capturum/builders/list-renderer';
import { BuilderDetailTemplateComponent } from './components/builder-detail-template/builder-detail-template.component';
import { BuilderCardComponent } from './components/builder-card/builder-card.component';
import { CapturumButtonModule } from '@capturum/ui/button';
import { CapturumFormRendererModule } from '@capturum/builders/form-renderer';
import { CapturumSidebarModule } from '@capturum/ui/sidebar';
import { CapturumSharedModule } from '@capturum/ui/api';
import { TranslateModule } from '@ngx-translate/core';
import { CapturumBuilderCoreModule } from '@capturum/builders/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CapturumInputModule } from '@capturum/ui/input';
import { CapturumDropdownModule } from '@capturum/ui/dropdown';
import { CapturumTextareaModule } from '@capturum/ui/textarea';
import { CapturumInputSwitchModule } from '@capturum/ui/input-switch';

@NgModule({
  declarations: [
    BuilderDetailTemplateComponent,
    BuilderCardComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CapturumInputModule,
    CapturumButtonModule,
    CapturumFormRendererModule,
    CapturumSidebarModule,
    CapturumSharedModule,
    CapturumFormRendererModule,
    CapturumListRendererModule,
    TranslateModule,
    CapturumBuilderCoreModule,
    CapturumDropdownModule,
    CapturumTextareaModule,
    CapturumInputSwitchModule,
    CapturumInfoTableModule,
  ],
  exports: [
    BuilderDetailTemplateComponent,
    BuilderCardComponent,
  ],
})
export class CapturumBuilderUiModule {
}
