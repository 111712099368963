<cap-calendar styleClass="p-inputgroup"
              [hidden]="props.readonly"
              [formControl]="formControl"
              [floatLabel]="(props.label_position | observablePipe | async ) === labelPosition.Floating"
              [label]="(props.label_position | observablePipe | async ) === labelPosition.Floating ? props.label : ''"
              [maxDate]="props.max_date"
              [minDate]="props.min_date"
              [showIcon]="true"
              [attr.data-test]="'builder-' + field.key"
              [name]="field.key"
              [dataType]="!props?.date ? 'string' : 'date'"
              [placeholder]="formState.readonly ? '' : props.placeholder"
              [hasTooltip]="props.propsoltip"
              [selectionMode]="props?.selectionMode ?? 'single'"
              [readOnly]="!!formState.readonly"
              [showTime]="props?.time"
              [appendTo]="props?.appendprops"
              [timeOnly]="!props?.date"
              [setTimeZone]="props?.timezone ?? true"
              [hideOnDateTimeSelect]="true"
              (clickOutside)="onClickOutside()">
</cap-calendar>

<cpb-readonly-container [hidden]="!props.readonly" [attr.data-test]="'builder-readonly-' + field.key">
  <ng-container *ngIf="!props?.date; else showDate">
    {{ formControl.value | default: props?.defaultEmptyValue }}
  </ng-container>

  <ng-template #showDate>
    <ng-container
      *ngIf="(!props.selectionMode || props?.selectionMode === 'single') || (props?.selectionMode !== 'single' && !formControl?.value?.length); else rangeDate">
      {{ formControl.value | date : (props.time ? 'dd-MM-yyyy HH:mm' : 'dd-MM-yyyy') }}
    </ng-container>

    <ng-template #rangeDate>
      {{ formControl.value[0] | date : 'dd-MM-yyyy' }} - {{ formControl.value[1] | date : 'dd-MM-yyyy' }}
    </ng-template>
  </ng-template>
</cpb-readonly-container>

