export enum InputNumberButtonLayout {
  stacked = 'stacked',
  horizontal = 'horizontal',
  vertical = 'vertical',
}

export enum InputNumberLocaleMatcher {
  lookup = 'lookup',
  bestFit = 'best fit',
}

export enum InputNumberMode {
  decimal = 'decimal',
  currency = 'currency',
}

export enum InputNumberCurrencyDisplay {
  symbol = 'symbol',
  name = 'name',
}
