import { map } from 'rxjs/operators';
import { ApiService } from '@capturum/api';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiHttpService } from '@capturum/api';
import { EntityInformation, EntityInfoRequest } from '../interfaces/entity-builder-config.interface';

@Injectable({ providedIn: 'root' })
export class BuilderUiEntityService extends ApiService<EntityInformation> {
  protected endpoint = 'builder/ui/entity';

  constructor(
    apiHttp: ApiHttpService,
  ) {
    super(apiHttp);
  }

  public searchEntityInformation(data: EntityInfoRequest): Observable<Record<string, EntityInformation>> {
    return this.apiHttp.post(`/${this.endpoint}/info`, data)
  }
}
