import { Component, OnDestroy, OnInit } from '@angular/core';
import { CalendarSelectionMode } from '@capturum/ui/calendar';
import { FieldType } from '@ngx-formly/core';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { LabelPosition } from '@capturum/builders/core';

@Component({
  selector: 'cpb-date-input',
  templateUrl: './date-input.component.html',
})
export class CapturumBuilderDateInputComponent extends FieldType implements OnInit, OnDestroy {
  public separator: string = ':';
  public innerValue: string;
  public labelPosition: typeof LabelPosition = LabelPosition;

  private destroy$: Subject<boolean> = new Subject();

  public ngOnInit(): void {
    this.formControl.valueChanges.pipe(take(1))
      .subscribe(value => {
        this.innerValue = value;

        if (typeof value === 'string') {
          if ((new Date(value)).getTime() > 0 || this.props?.timezone) {
            this.formControl.patchValue(new Date(value), { onlySelf: true });
          } else if (!this.props?.date && this.props?.time) {
            const timeParts: string[] = value.split(this.separator);

            if (timeParts && timeParts.length >= 3) {
              const time = timeParts.slice(0, 2).join(this.separator);

              this.formControl.patchValue(time, { onlySelf: true });
            }
          }
        }
      });
  }

  public onClickOutside(): void {
    const dates: string[] = this.formControl.value;

    if (this.props?.selectionMode === CalendarSelectionMode.range && dates?.some((date) => !!date)) {
      if (dates.some((date) => !date)) {
        this.formControl.setValue(dates.map((date) => date || dates.find(date => !!date)));
      }
    }
  }

  public ngOnDestroy(): void {
    if (this.destroy$) {
      this.destroy$.next(true);
      this.destroy$.complete();
    }
  }
}
