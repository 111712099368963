import { CapturumBuilderContentWrapperComponent } from '../wrappers/content-wrapper/content-wrapper.component';
import { CapturumBuilderDefaultInputWrapperComponent } from '../wrappers/default-input-wrapper/default-input-wrapper.component';
import { CapturumBuilderCollapsableWrapperComponent } from '../wrappers/collapsable-wrapper/collapsable-wrapper.component';
import { CapturumBuilderCheckboxWrapperComponent } from '../wrappers/checkbox-wrapper/checkbox-wrapper.component';
import { CapturumBuilderHiddenWrapperComponent } from '../wrappers/hidden-wrapper.component';

export const inputTypeWrappers = [
  { name: 'content', component: CapturumBuilderContentWrapperComponent },
  { name: 'default-input-wrapper', component: CapturumBuilderDefaultInputWrapperComponent },
  { name: 'collapsable-wrapper', component: CapturumBuilderCollapsableWrapperComponent },
  { name: 'checkbox-wrapper', component: CapturumBuilderCheckboxWrapperComponent },
  { name: 'hidden', component: CapturumBuilderHiddenWrapperComponent },
];
