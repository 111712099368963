import { ApiHttpService, ApiService } from '@capturum/api';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { responseData } from '../utils/operators.util';
import { FormBuilderConfig } from '../interfaces/form-builder-configuration.interface';

@Injectable({ providedIn: 'root' })
export class ConfigApiService extends ApiService<any> {
  protected endpoint = '/builder/ui/config';

  constructor(apiHttp: ApiHttpService) {
    super(apiHttp);
  }

  public setConfig(configId: string): Observable<void> {
    return this.apiHttp.get(`${this.endpoint}/${configId}/set`);
  }

  public getConfigByKey(type: string, key: string): Observable<Record<string, any>> {
    return this.apiHttp.get(`${this.endpoint}/by-key/${type}_${key}`).pipe(
      responseData,
      map(data => data[0]),
    );
  }
}
