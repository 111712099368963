<div class="dialog-content">
  <cpb-form-renderer #form formKey="form_edit_power_bi_client"
                     [modelId]="modelId">
  </cpb-form-renderer>
</div>

<div class="dialog-footer">
  <div class="btn-groups">
    <div class="btn-left">
      <cap-button styleClass="secondary"
                  [label]="'button.cancel' | translate"
                  (onClick)="close()">
      </cap-button>
    </div>

    <div class="btn-right">
      <cap-button styleClass="primary"
                  [label]="'button.submit' | translate"
                  (onClick)="submit()">
      </cap-button>
    </div>
  </div>
</div>
