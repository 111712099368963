import { Injectable } from '@angular/core';
import { ApiHttpService, ApiService } from '@capturum/api';
import { NgxPermissionsService } from 'ngx-permissions';
import { concat, from, Observable } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Permission } from '../models/permission.api-model';
import { PermissionIndexedDbModel } from '../models/permission.indexedDb-model';

@Injectable()
export class PermissionService extends ApiService<Permission> {
  protected endpoint = 'permission';

  constructor(private api: ApiHttpService, private ngxPermissionService: NgxPermissionsService) {
    super(api);
  }

  /**
   * Retrieve all permissions of the current tenant
   */
  public getPermissionsOfCurrentTenant(): Observable<Permission[]> {
    return this.apiHttp.get('/tenant/permission').pipe(map((response: { data: Permission[] }) => response.data));
  }

  /**
   * Load the permissions of the current user
   */
  public loadPermissions(): Observable<boolean> {
    return this.apiHttp
      .get(`/role/${this.endpoint}`)
      .pipe(
        catchError((error) => {
          return from(PermissionIndexedDbModel.query().toArray()).pipe(
            map((permissions) => {
              return {
                data: permissions.map((permission) => permission.name),
              }
            })
          );
        }),
        map((response: { data: string[] }) => response.data),
        switchMap((permissions) => {
          const permissionData = permissions.map(permission => {
            return { name: permission };
          });

          return concat(
            from(PermissionIndexedDbModel.query().clear()),
            from(PermissionIndexedDbModel.query().bulkAdd(permissionData)),
            from(PermissionIndexedDbModel.loadPermissions())
          ).pipe(
            tap(() => {
              this.ngxPermissionService.flushPermissions();
              this.ngxPermissionService.loadPermissions(permissions);
            }))
        }),
        map(() => {
          return true;
        }),
      )
  }
}
