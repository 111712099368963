<div class="body-part">
  <h5 class="multi-factor-form--section-title">
    <span>{{ paragraphNumbers?.description }}.</span>&nbsp;{{ 'auth.2fa.' + method + '.title' | translate: {'method': method | titlecase} }}
  </h5>

  <p class="multi-factor-form--section-description"
     [innerHTML]="'auth.2fa.' + method + '.description' | translate: { 'method': method | titlecase }">
  </p>
</div>

<div class="body-part">
  <h5 class="multi-factor-form--section-title">
    <span>{{ paragraphNumbers?.description + 1 }}.</span>&nbsp;{{ 'auth.2fa.' + method + '.qr.title' | translate }}
  </h5>

  <p class="multi-factor-form--section-description">
    {{ 'auth.2fa.' + method + '.qr.description' | translate: {'method': method| titlecase} }}
  </p>

  <div class="qr-code d-flex flex-column align-items-center justify-content-center">
    <ng-container *ngIf="(qrcode$ | async) as qrCodeData; else loadingTemplate">
      <img alt="QR code" [src]="qrCodeData?.qrCode" />

      <p class="text-center">
        {{ 'auth.2fa.' + method + '.qr.fail-scan-message' | translate }}
        <br />
        <strong class="secret-code">{{ qrCodeData?.secret }}</strong>
      </p>
    </ng-container>

    <ng-template #loadingTemplate>
      <p-progressSpinner styleClass="cap-progress-spinner"></p-progressSpinner>
    </ng-template>
  </div>
</div>
