import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '../services/auth.service';
import { AuthConfig, AuthRefreshTokenConfig, AUTH_USE_REFRESH_TOKENS } from '../auth.config';

@Injectable({ providedIn: 'root' })
export class AuthGuard  {
  constructor(
    private authService: AuthService,
    private router: Router,
    private config: AuthConfig,
    @Inject(AUTH_USE_REFRESH_TOKENS)
    public authRefreshToken: AuthRefreshTokenConfig,
  ) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authService.isAuthenticated()) {
      this.checkUseRefreshToken();

      return true;
    }

    this.router.navigate([this.config.authRejectionRoute], { queryParams: { redirectUrl: window.location.pathname } });

    return false;
  }

  public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }

  public canLoad(): boolean {
    if (!this.authService.isAuthenticated()) {
      this.router.navigate([this.config.authRejectionRoute], { queryParams: { redirectUrl: window.location.pathname } });

      return false;
    }

    this.checkUseRefreshToken();

    return true;
  }

  private checkUseRefreshToken(): void {
    if (this.authRefreshToken.useRefreshToken || this.config?.authRefreshToken?.useRefreshToken) {
      this.authService.watchRefreshToken();
    }
  }
}
