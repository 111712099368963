import { CapturumBuilderFileInputComponent } from './../file-input/file-input.component';
import { Component } from '@angular/core';

@Component({
  selector: 'cpb-image-input',
  templateUrl: './image-input.component.html',
  styleUrls: ['./image-input.component.scss'],
})
export class CapturumBuilderImageInputComponent extends CapturumBuilderFileInputComponent {
}
