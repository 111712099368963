import { FilterMatchMode } from '@capturum/ui/api';
import { Observable } from 'rxjs';
import { InputType, ListRendererFilterOptions } from '@capturum/builders/core';

export interface ListRendererFilter {
  field: string;
  type: InputType;
  label?: string;
  placeholder?: string | Observable<string>;
  icon?: string;
  options?: ListRendererFilterOptions;
  wrapperStyleClass?: string;
  isSearch?: boolean;
  resettable?: boolean;
  defaultValue?: any;
  autoDisplayFirst?: boolean;
  dataTestAttribute?: string;
  matchMode?: FilterMatchMode;
  value?: any;
  reset_filter_icon?: string;
}
