import { FieldType } from '@ngx-formly/core';
import { Component } from '@angular/core';

@Component({
  selector: 'em-input-switch-formly',
  templateUrl: './input-switch.component.html'
})
export class InputSwitchTypeComponent extends FieldType {

}
