import { Pipe, PipeTransform } from '@angular/core';
import { InfoTableColumn } from '../base/info-table-column.model';
import { InfoTableColumnType } from '../base/info-table-column-type.enum';

@Pipe({
  name: 'columnsByType'
})
export class ColumnsByTypePipe implements PipeTransform {
  public transform(columns: InfoTableColumn[], type: InfoTableColumnType): number {
    return columns?.filter(item => item.type === type).length ?? 0;
  }
}
