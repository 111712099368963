import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormSaverService } from '../../services/form-saver.service';
import { catchError, map, shareReplay , switchMap} from 'rxjs/operators';
import { concat, Observable, of, Subject } from 'rxjs';

interface FormRendererButton {
  icon: string;
  key: string;
  name: string;
  options: any;
  permissions: string[] | null;
}

@Component({
  selector: 'cpb-form-submit-button',
  template: `
    <ng-container *ngxPermissionsOnly="actionConfiguration.permissions">
      <cap-button *ngIf="actionConfiguration" [label]="actionConfiguration.name"
                  [icon]="(loading$ | async) ? 'fas fa-spinner fa-spin' : actionConfiguration.icon"
                  styleClass="primary"
                  [disabled]="loading$ | async"
                  (click)="submit()">
      </cap-button>
    </ng-container>
  `,
  styleUrls: ['./submit-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubmitButtonComponent {
  @Input()
  public actionConfiguration: FormRendererButton;
  @Input()
  public key: string;

  public loading$: Observable<boolean>;

  private submitSubject = new Subject<boolean>();

  constructor(
    private readonly formSaverService: FormSaverService,
  ) {

    this.loading$ = this.submitSubject.asObservable().pipe(
      switchMap(() => {
        return concat(
          of(true),
          this.formSaverService.submit(this.key).pipe(
            catchError(() => of(false)),
            map(() => false)),
        );
      }),
      shareReplay(1),
    );
  }

  public submit(): void {
    this.submitSubject.next(true);
  }
}
