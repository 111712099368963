<div class="form-element">
  <label *ngIf="(label || hasTooltip) && !floatLabel"
         [style]="{ 'justify-content': label ? 'space-between' : 'flex-end' }">
    <span *ngIf="label">{{ label }}</span>

    <ng-content select=".tooltip-content"></ng-content>
  </label>

  <ng-container *ngIf="!floatLabel; else floatingInput">
    <ng-container *ngTemplateOutlet="defaultTemplate"></ng-container>
  </ng-container>

  <ng-template #floatingInput>
      <span class="p-float-label textarea-float-label">
        <ng-container *ngTemplateOutlet="defaultTemplate"></ng-container>
        <label class="cpb-float-label" htmlFor="float-label">{{ label }}</label>
      </span>
  </ng-template>

  <ng-template #defaultTemplate>
     <textarea class="cap-textarea"
               #textarea
               [(ngModel)]="value"
               [class.populated]="value"
               [cols]="cols"
               [disabled]="disabled"
               [ngStyle]="{'width.%': !label ? '100' : null}"
               [placeholder]="placeholder"
               [readOnly]="readonly"
               [rows]="rows"
               (focus)="onFocus()">
    </textarea>
  </ng-template>

  <cap-validator *ngIf="control" [control]="control" [customName]="label"></cap-validator>
</div>
