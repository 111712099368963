export enum FilterType {
  INPUT = 'input',
  DATEPICKER = 'datepicker',
  MULTISELECT = 'multiselect',
  SELECT = 'select',
  RANGE = 'range',
  CHECKBOX = 'checkbox',
  INPUT_SWITCH = 'inputSwitch',
  TEMPLATE = 'template',
  DROPDOWN = 'dropdown',
}
