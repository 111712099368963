<div class="form-group cbp-default-input-wrapper"
     [class.cpb-readonly]="formState.readonly"
     [class.has-error]="showError"
     [class.position-before]="(to.label_position | observablePipe | async) === labelPosition.Before"
     [ngClass]="to.css_class">
  <div *ngIf="!((to.label_position | observablePipe | async) === labelPosition.Floating)" class="label-wrapper">
    <label *ngIf="to.label && to.hideLabel !== true" [attr.for]="id" class="d-block">
      {{ to.label }} <span class="cpb-required-indicator" *ngIf="to.required">*</span>
    </label>

    <cpb-form-tooltip
      [text]="to.tooltip"
      class="tooltip-content"
      [position]="
        (to.labelPosition | observablePipe | async) === labelPosition.Before ? 'bottom-left' : 'bottom-right'
      ">
    </cpb-form-tooltip>
  </div>

  <div class="field-wrapper">
    <ng-template #fieldComponent></ng-template>
    <div
      class="form-invalid-feedback"
      [style.visibility]="((submitted | async) || showError) && !formState.readonly ? 'visible' : 'hidden'">
      <cbp-validation-message [field]="field"></cbp-validation-message>
    </div>
  </div>

  <small *ngIf="to.description" class="form-text text-muted">
    {{ to.description | observablePipe | async }}
  </small>
</div>
