import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { FormInputConfig } from '../../interfaces/form-input-config';
import { InputConfiguration, InputType } from '@capturum/builders/core';
import { FormInputTypeFacade } from '../../facades/form-input-type.facade';
import { takeUntil } from 'rxjs/operators';
import { FormFieldsManipulatorUtil } from '../../utils/form-fields-manipulator.util';

@Injectable({ providedIn: 'root' })
export class InputTypeBuilder implements FormInputConfig<InputConfiguration> {
  protected inputType!: string;
  private destroy$ = new Subject<void>();

  constructor(protected readonly formInputTypeFacade: FormInputTypeFacade) {}

  public getInput(
    configuration: InputConfiguration,
    formKey: string,
    defaultValue: any,
    defaultEmptyValue: string = ''
  ): FormlyFieldConfig {
    let options$: Observable<any[]>;

    if (configuration?.options?.action?.endpoint || configuration?.options?.items) {
      options$ = this.formInputTypeFacade.getTransformedOptions(configuration.options, formKey);
    }

    const input: FormlyFieldConfig = this.getInitialFormlyConfig(
      configuration,
      formKey,
      defaultValue,
      defaultEmptyValue
    );

    if (configuration.name) {
      this.formInputTypeFacade.setInputConfiguration(configuration.name, { ...input });
    }

    if (input?.props?.css_class) {
      input.className = `${input.className || ''} ${input.props.css_class}`;
    }

    input.hooks = {
      onInit: (field: FormlyFieldConfig | undefined) => {
        if (field) {
          if (configuration.sets) {
            this.formInputTypeFacade.handleOptionsSourceSets(field, configuration);
          }

          if (configuration.options?.disabled) {
            field.formControl.disable();
          }

          if (configuration.dependencies && configuration?.dependencies?.length) {
            this.formInputTypeFacade.resolveDependencies(field, configuration, options$);
          } else {
            if (options$) {
              this.formInputTypeFacade.setDefaultDropdownOption(options$, field);
            }
          }

          this.formInputTypeFacade.setCustomValidations(configuration.validations, field);
        }

        if (input.props.options$) {
          input.props.options = input.props.options$.asObservable();
        }

        if (configuration.trigger) {
          this.checkFieldTriggers(formKey, field, configuration);
        }

        this.formInputTypeFacade
          .getFormManipulations(formKey, field.key as string)
          .pipe(takeUntil(this.destroy$))
          .subscribe((manipulations) => {
            if (manipulations.hasOwnProperty('read_only')) {
              field.props.readonly = manipulations.read_only;
            }

            if (manipulations.hasOwnProperty('options')) {
              if (this.inputType === InputType.dropdown || this.inputType === InputType.multiSelect) {
                field.props.options$ = new BehaviorSubject(manipulations.options);
              } else {
                field.props = { ...field.props, ...manipulations.options };
              }
            }

            setTimeout(() => {
              if (manipulations.hasOwnProperty('value')) {
                field.formControl.setValue(manipulations.value);
              }
            }, 0);

            if (manipulations.hasOwnProperty('source_endpoint')) {
              field.props.options$ = this.formInputTypeFacade.getTransformedOptions(
                {
                  action: {
                    endpoint: manipulations.source_endpoint,
                    options: null,
                  },
                },
                formKey
              );
            }
          });
      },
      onDestroy: (field) => {
        field.props.destroy.next();
        this.destroy$.next();
      },
    };

    this.appendOptions(input, configuration);
    this.setDefaultValue(input, defaultValue);

    return input;
  }

  protected appendOptions(input: FormlyFieldConfig, configuration: InputConfiguration): void {
    // do nothing
  }

  protected setDefaultValue(input: FormlyFieldConfig, value: any): void {
    if (value && !input.defaultValue) {
      input.defaultValue = value[input.key as string] ?? null;
    }
  }

  private setDefaultOption(field: FormlyFieldConfig, options: any[]): void {
    const defaultOption = options.find((option) => option.is_default);
    const defaultValue = field.props && defaultOption ? defaultOption[field.props.value_key] : null;

    if (
      field.formControl &&
      (field.defaultValue === null || field.defaultValue === undefined) &&
      defaultValue !== null &&
      defaultValue !== undefined
    ) {
      field.formControl.setValue(defaultValue);
    }
  }

  private getInitialFormlyConfig(
    configuration: InputConfiguration,
    formKey: string,
    defaultValue: any,
    defaultEmptyValue: string = ''
  ): FormlyFieldConfig {
    const config = this.formInputTypeFacade.getFormRendererConfig();
    const input = config.types.find((configInputType) => configInputType.name === configuration.type);
    const wrappers = input?.wrappers || ['default-input-wrapper'];
    const defaultValueForField = defaultValue?.[configuration?.name] || configuration.default;

    return {
      key: configuration.name,
      defaultValue: defaultValueForField,
      wrappers,
      props: {
        forcedReadonly: !!configuration.readonly,
        readonly: !!configuration.readonly,
        label: configuration.label,
        tooltip: configuration.tooltip,
        placeholder: configuration.placeholder,
        label_key: configuration.options?.label_key || 'label',
        value_key: configuration.options?.value_key || 'key',
        image_key: configuration.options?.source?.image_key,
        options$: new BehaviorSubject([]),
        description: configuration.options?.description,
        sort_asc: configuration.options?.sort_asc,
        css_class: configuration.options?.css_class,
        formKey: formKey,
        disabled: configuration.options?.disabled,
        configurationOptions: configuration.options,
        label_position: this.formInputTypeFacade.getLabelPosition(formKey),
        is_base_data: configuration?.options?.source?.type === 'base_data',
        ...this.formInputTypeFacade.getValidations(configuration),
        destroy: new Subject<void>(),
        defaultEmptyValue,
      },
      type: FormFieldsManipulatorUtil.getFormlyTypeName(configuration.type),
      expressionProperties: {
        'props.readonly': 'formState.readonly || field.props.forcedReadonly',
      },
    };
  }

  private checkFieldTriggers(formKey: string, field: FormlyFieldConfig, configuration: InputConfiguration): void {
    this.formInputTypeFacade.registerTriggerHandler(field, formKey, configuration);

    this.formInputTypeFacade
      .getFormManipulations(formKey, field.key as string)
      .pipe(takeUntil(this.destroy$))
      .subscribe((manipulations) => {
        if (manipulations.hasOwnProperty('readonly')) {
          field.props.readonly = manipulations.readonly;
        }
      });
  }
}
