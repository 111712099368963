import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { InputTypeBuilder } from '../input/input-type.builder';
import { FileInputConfiguration } from '../../models/file-input-configuration.model';
import { FormInputConfig } from '../../interfaces/form-input-config';
import { InputType } from '@capturum/builders/core';
import { FilePreviewListItem } from '@capturum/ui/file-preview-list';

@Injectable({ providedIn: 'root' })
export class FileInputTypeBuilder extends InputTypeBuilder implements FormInputConfig<FileInputConfiguration> {
  protected inputType: string = InputType.file;

  protected appendOptions(input: FormlyFieldConfig, configuration: FileInputConfiguration): void {
    if (input.props && configuration) {
      input.props.multiple = configuration?.options?.is_multiple ?? true;
      input.props.validations = this.prepareValidations(configuration);
      input.props.availableExtensions = this.prepareAvailableExtensions(configuration);
      input.props.isVapor = configuration.options?.is_vapor;

      if (configuration?.options?.tag || configuration.options?.tag?.length) {
        input.props.tag = configuration.options.tag;
      }

      input.props.fileableType = configuration?.options?.fileable ?? null;
    }
  }

  protected prepareValidations(configuration: FileInputConfiguration): Record<string, string | number> {
    const validations: Record<string, string | number> = { mimes: '', max: null };

    for (const validation in configuration.validations) {
      if (configuration.validations.hasOwnProperty(validation)) {
        if (typeof configuration.validations[validation] === 'string') {
          const validationParts = (configuration.validations[validation] as string).split(':');
          const validationName: string = validationParts[0];
          let validationRules: string | number = validationParts[1];

          if (validationName === 'max') {
            validationRules = +validationRules * 1024; // Convert KB to B.
          }

          if (validations.hasOwnProperty(validationName)) {
            validations[validationName] = validationRules;
          } else {
            validations[validation] = configuration.validations[validation];
          }
        }
      }
    }

    return validations;
  }

  protected prepareAvailableExtensions(configuration: FileInputConfiguration): string {
    return (this.prepareValidations(configuration).mimes as string).split(',')
      .map((extension: string) => `.${extension}`)
      .join(',');
  }

  protected setDefaultValue(input: FormlyFieldConfig, value: any): void {
    if (value) {
      if (Array.isArray(value)) {
        input.defaultValue = value.map((val) => this.getDefaultValueData(val));
        input.props = {
          ...input.props,
          previewData: value.map((val) => this.getFilePreviewData(val)),
        };
      } else {
        input.defaultValue = this.getDefaultValueData(value);
        input.props = {
          ...input.props,
          previewData: this.getFilePreviewData(value),
        };
      }
    }
  }

  private getDefaultValueData(value: any): { data: string, filename: string, mime_type: string } {
    return {
      data: value.id,
      filename: value.filename,
      mime_type: value.mime_type,
    };
  }

  private getFilePreviewData(value: any): FilePreviewListItem {
    return {
      url: value.public_url,
      name: value.filename,
      id: value.id,
    };
  }
}
