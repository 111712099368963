import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostListener,
  Inject,
  Input,
  NgZone,
  OnChanges,
  PLATFORM_ID,
  Renderer2,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { Tooltip } from 'primeng/tooltip';
import { take } from 'rxjs/operators';

@Directive({
  selector: '[capCellTooltip]',
  providers: [
    {
      provide: Tooltip,
      useExisting: CapturumCellTooltipDirective,
    },
  ],
  host: {
    class: 'cap-cell-tooltip',
  },
})
export class CapturumCellTooltipDirective extends Tooltip implements AfterViewInit, OnChanges {
  @Input('capCellTooltip')
  public ellipsisText: string;
  @Input() public loading: boolean;

  public isDisabled: boolean = false;
  public domElement: HTMLElement;

  constructor(
    @Inject(PLATFORM_ID) private platform: any,
    public elementRef: ElementRef,
    private renderer2: Renderer2,
    public zone: NgZone,
    public config: PrimeNGConfig,
    public viewContainerRef: ViewContainerRef
  ) {
    super(platform, elementRef, zone, config, renderer2, viewContainerRef);
    this.domElement = elementRef.nativeElement;
    this.tooltipPosition = 'bottom';
    this.tooltipStyleClass = 'cap-info-table-cell-tooltip';
  }

  public ngAfterViewInit(): void {
    this.content = this.ellipsisText;
    this.setOption({ tooltipLabel: this.ellipsisText });
    this.renderer2.setProperty(this.domElement, 'scrollTop', 1);
    super.ngAfterViewInit();

    this.zone.onStable.pipe(take(1)).subscribe(() => {
      this.setToolTip();
    });

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.contentRect.width !== 0) {
          this.setToolTip();
          resizeObserver.disconnect();
        }
      }
    });

    resizeObserver.observe(this.domElement);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes?.loading) {
      if (changes.loading.currentValue === false && !!changes?.ellipsisText?.currentValue) {
        this.setToolTip();
      }
    }

    super.ngOnChanges(changes);
  }

  @HostListener('window:resize', ['$event.target'])
  public setToolTip(): void {
    this.setOption({ disabled: this.domElement.scrollWidth <= this.domElement.offsetWidth });
  }
}
