import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'cpb-checkbox-value-input',
  templateUrl: './checkbox-value-input.component.html',
  styleUrls: ['./checkbox-value-input.component.scss'],
})
export class CapturumBuilderCheckboxValueInputComponent extends FieldType {
}
