import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BatchStatusActionProvider, BatchStatusAction } from '../models/batch-status.model';

@Injectable({ providedIn: 'root' })
export class BatchStatusNavigateAction implements BatchStatusActionProvider {
  constructor(
    private readonly router: Router,
  ) {
  }

  public execute(action: BatchStatusAction): { response: any } | Observable<any> | void {
    if (action?.action) {
      this.router.navigateByUrl(action.action);
    }
  }
}
