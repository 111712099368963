import { Injectable } from '@angular/core';
import { InputTypeBuilder } from '../input/input-type.builder';
import { FormInputConfig } from '../../interfaces/form-input-config';
import { DropdownInputConfiguration } from '../../models/dropdown-input-configuration.model';
import { InputType } from '@capturum/builders/core';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Injectable({ providedIn: 'root' })
export class MultiSelectInputTypeBuilder extends InputTypeBuilder implements FormInputConfig<DropdownInputConfiguration> {
  protected inputType: string = InputType.multiSelect;

  protected appendOptions(input: FormlyFieldConfig, configuration: DropdownInputConfiguration): void {
    input.props.virtualScroll = configuration.options.virtualScroll;
    input.props.virtualScrollItemSize = configuration.options.virtualScrollItemSize;
    input.props.resetFilterOnHide = configuration.options.resetFilterOnHide;
    input.props.sourceValueKey = configuration.options.sourceValueKey;
    input.props.appendTo = configuration.options?.appendTo ?? null;
    input.props.selectionLimit = configuration.options?.selectionLimit ?? null;
    input.props.filterBy = configuration.options.filterBy || null;

    super.appendOptions(input, configuration);
  }
}
