export enum CSSVarNames {
  Primary = '--cap-primary-color',
  Secondary = '--secondary-color',
  Third = '--third-color',
  Text = '--primary-text',
  Text2 = '--text-color-2',
  Text3 = '--text-color-3',
  Text4 = '--text-color-4',
  ToastText = '--text-toast',
  Success = '--success-color',
  Warning = '--warning-color',
  Confirm = '--confirm-color',
  PrimaryShadow = '--cap-primary-color-shadow',
  SecondaryShadow = '--secondary-color-shadow',
  ThirdShadow = '--divider-color-shadow',
  SuccessShadow = '--success-color-shadow',
  WarningShadow = '--warning-color-shadow',
  ErrorShadow = '--error-color-shadow',
  ConfirmShadow = '--confirm-shadow-color',
  Error = '--error-color',
  Info = '--info-color',
  Font = '--primary-font',
  InputHeight = '--cap-input-height',

  // Dynamic Filters Settings
  DynamicFilterInputHeight = '--cap-dynamic-filter-input-height',
  DynamicFilterBorderRadius = '--cap-dynamic-filter-border-radius',
  DynamicFilterBorderWidth = '--cap-dynamic-filter-border-width',
  DynamicFilterHideFiltersOnMobile = '--cap-dynamic-filters-hide-filters-on-mobile',
  DynamicFilterWidth = '--cap-dynamic-filter-width',
  DynamicFilterLabelFontSize = '--cap-dynamic-filter-label-font-size',
  DynamicFilterLabelFontWeight = '--cap-dynamic-filter-label-font-weight',
  DynamicFilterLabelFontFamily = '--cap-dynamic-filter-label-font-family',
  DynamicFilterLabelFontColor = '--cap-dynamic-filter-label-font-color',
  DynamicFilterIconColor = '--cap-dynamic-filter-icon-color',
  DynamicFilterIconOpacity = '--cap-dynamic-filter-icon-opacity',
  DynamicFilterPlaceholderFontColor = '--cap-dynamic-filter-placeholder-font-color',
  DynamicFilterPlaceholderFontWeight = '--cap-dynamic-filter-placeholder-font-weight',
}
