<div class="form-element">
    <label *ngIf="label || hasTooltip" [style]="{ 'justify-content': label ? 'space-between' : 'flex-end' }">
        <span *ngIf="label">{{ label }}</span>

        <ng-content select=".tooltip-content"></ng-content>
    </label>

    <div class="p-inputgroup">
        <p-inputMask [(ngModel)]="value"
                     [disabled]="disabled"
                     [mask]="mask"
                     [unmask]="true"
                     [placeholder]="placeholder"
                     (blur)="onBlur()"
                     (onComplete)="complete?.emit()"
                     (focus)="onFocus()">
        </p-inputMask>
    </div>

    <cap-validator *ngIf="control" [control]="control" [customName]="label"></cap-validator>
</div>
