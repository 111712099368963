import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ToastService, ValidatorService } from '@capturum/ui/api';

@Injectable()
export class CapturumHttpCodeInterceptor implements HttpInterceptor {
  constructor(
    private readonly toastService: ToastService,
    private readonly validatorService: ValidatorService,
    private readonly translateService: TranslateService,
  ) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        () => null,
        (error: any) => {
          if (error instanceof HttpErrorResponse) {
            switch (error.status) {
              case 400:
              case 403:
              case 422:
              case 401:
                this.parseValidations(error.error);

                break;
              default:
                break;
            }
          }
        },
      ),
    );
  }

  protected parseValidations(response: { errors: Record<string, string[]>, message: string }): void {
    const keys = response.errors && Object.keys(response.errors);
    const nonField = response.errors && (+Object.keys(response.errors)[0] === 0);

    if (keys && keys.length) {
      const fieldErrors = keys.reduce((accumulator, key) =>
        ({ ...accumulator, [key]: response.errors[key] }), {},
      );

      this.validatorService.backendValidation.next(fieldErrors);
    }

    if (nonField) {
      const message = response.errors[0].join('\n');

      this.onNonFieldError(message);
    }
  }

  protected onNonFieldError(message: string): void {
    this.toastService.error(this.translateService.instant('toast.error.title'), message);
  }
}
