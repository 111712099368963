import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { takeUntil, startWith } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'cpb-checkbox-list-input',
  templateUrl: './checkbox-list-input.component.html',
  styleUrls: ['./checkbox-list-input.component.scss'],
})
export class CapturumBuilderCheckboxListInputComponent extends FieldType implements OnInit, OnDestroy {
  public readonlyString: Observable<string[]>;
  private destroy$ = new Subject<void>();

  constructor() {
    super();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
  }

  public setChecked(option: any, checked: boolean): void {
    let controlValue = this.formControl.value;
    const optionValue = option.key;

    if (checked) {
      if (Array.isArray(controlValue)) {
        controlValue.push(optionValue);
      } else {
        controlValue = [optionValue];
      }
    } else {
      controlValue = controlValue.filter((item: any) => item !== optionValue);
    }

    this.formControl.setValue(controlValue);
  }

  public ngOnInit(): void {
    this.readonlyString = this.formControl.valueChanges.pipe(
      startWith(this.formControl.value), takeUntil(this.destroy$));
  }
}
