import { AudioMimeTypes, DocumentMimeTypes, ImageMimeTypes, MulitpartMimeTypes, VideoMimeTypes } from '../../enums/mime-types.enum';

export function concatMimeTypes(mimeTypes: Array<ImageMimeTypes | AudioMimeTypes | VideoMimeTypes | MulitpartMimeTypes | DocumentMimeTypes>)
  : string | undefined {
  try {
    return mimeTypes.join(',');
  } catch {
    console.error('Failed to concat following mimeTypes:', mimeTypes);

    return undefined;
  }
}
