import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
  ) { }

  /**
   * Intercept the Http request and check for a 401 Unauthorized code
   *
   * @param request: HttpRequest<any>
   * @param next: HttpHandler
   * @return Observable<HttpEvent<any>>
   */
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(catchError((error: HttpErrorResponse) => {
        if (error.status && error.status === 401) {
          this.authService.setAuthenticationState(false);
          this.authService.removeStaleCredentials();
        }

        return throwError(error);
      }));
  }
}
