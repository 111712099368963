import { Action, createSelector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
  ResetFormFieldValidationErrors,
  ResetFormValue,
  SetFormConfiguration,
  SetFormStatus,
  SetFormValidationErrors,
  SetFormValue,
  SetSourceValue,
  SetSubmitted,
} from './form-renderer.actions';
import { FormBuilderConfig } from '../../models/form-builder-config.model';
import { FormValidationMessageType } from '../../types/form-validation-message.type';
import { deepEqual } from '../../utils/object.utils';

interface FormConfigurationStateModel {
  configuration: FormBuilderConfig,
  context?: Record<string, any>,
  value: any,
  valid: boolean,
  submitted: boolean,
  sourceValue?: any;
  validationErrors: FormValidationMessageType;
}

export interface FormRendererStateModel {
  forms: Record<string, FormConfigurationStateModel>;
}

// @dynamic
@State<FormRendererStateModel>({ name: 'formRenderer', defaults: { forms: {} } })
@Injectable()
export class FormRendererState {
  public static configurationByKey(key: string): (state: FormRendererStateModel) => FormBuilderConfig {
    return createSelector([FormRendererState], (state: FormRendererStateModel) => {
      return state.forms[key] && state.forms[key].configuration;
    });
  }

  public static contextByKey(key: string): (state: FormRendererStateModel) => Record<string, any> {
    return createSelector([FormRendererState], (state: FormRendererStateModel) => {
      return state.forms[key] && state.forms[key].context;
    });
  }

  public static formByKeyAndContext(key: string, context: Record<string, any>, skipCacheCheck: boolean): (state: FormRendererStateModel) => FormConfigurationStateModel {
    return createSelector([FormRendererState], (state: FormRendererStateModel) => {
      if (state.forms[key] && (skipCacheCheck || (!context && !state.forms[key].context) || deepEqual(context, state.forms[key].context))) {
        return state.forms[key];
      }

      return null;
    });
  }

  public static formValueByKey(key: string): (state: FormRendererStateModel) => any {
    return createSelector([FormRendererState], (state: FormRendererStateModel) => {
      return state.forms[key] && state.forms[key].value;
    });
  }

  public static sourceValueByKey(key: string): (state: FormRendererStateModel) => any {
    return createSelector([FormRendererState], (state: FormRendererStateModel) => {
      return state.forms[key] && state.forms[key].sourceValue;
    });
  }

  public static isFormValid(key: string): (state: FormRendererStateModel) => boolean {
    return createSelector([FormRendererState], (state: FormRendererStateModel) => {
      return state.forms[key] && state.forms[key].valid;
    });
  }

  public static isSubmitted(key: string): (state: FormRendererStateModel) => boolean {
    return createSelector([FormRendererState], (state: FormRendererStateModel) => {
      return state.forms[key] && state.forms[key].submitted;
    });
  }

  public static validationErrorsByKeyAndControlName(key: string, controlName: string): (state: FormRendererStateModel) => any {
    return createSelector([FormRendererState], (state: FormRendererStateModel) => {
      return state.forms[key] && state.forms[key]?.validationErrors?.[controlName];
    });
  }

  public static validationErrorsByKey(key: string): (state: FormRendererStateModel) => any {
    return createSelector([FormRendererState], (state: FormRendererStateModel) => {
      return state.forms[key] && state.forms[key]?.validationErrors;
    });
  }

  @Action(SetFormValue)
  public setFormValue(ctx: StateContext<FormRendererStateModel>, { key, value }: SetFormValue): void {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      forms: {
        ...state.forms,
        [key]: {
          ...state.forms[key],
          value,
        },
      },
    });
  }

  @Action(SetFormConfiguration)
  public setFormConfiguration(ctx: StateContext<FormRendererStateModel>, {
    key,
    configuration,
    context
  }: SetFormConfiguration): void {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      forms: {
        ...state.forms,
        [key]: {
          ...state.forms[key],
          configuration,
          context,
        },
      },
    });
  }

  @Action(SetFormStatus)
  public setFormStatus(ctx: StateContext<FormRendererStateModel>, {
    key,
    valid,
  }: SetFormStatus): void {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      forms: {
        ...state.forms,
        [key]: {
          ...state.forms[key],
          valid,
        },
      },
    });
  }

  @Action(ResetFormValue)
  public resetFormValue(ctx: StateContext<FormRendererStateModel>, {
    key,
  }: ResetFormValue): void {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      forms: {
        ...state.forms,
        [key]: {
          ...state.forms[key],
          valid: false,
          value: null,
          submitted: false,
        },
      },
    });
  }

  @Action(SetSubmitted)
  public setSubmitted(ctx: StateContext<FormRendererStateModel>, { key }: SetSubmitted): void {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      forms: {
        ...state.forms,
        [key]: {
          ...state.forms[key],
          submitted: true,
        },
      },
    });
  }

  @Action(SetSourceValue)
  public setSourceValue(ctx: StateContext<FormRendererStateModel>, {
    key,
    value,
  }: SetSourceValue): void {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      forms: {
        ...state.forms,
        [key]: {
          ...state.forms[key],
          sourceValue: value,
        },
      },
    });
  }

  @Action(SetFormValidationErrors)
  public setValidationErrors(ctx: StateContext<FormRendererStateModel>, {
    key,
    validationErrors
  }: SetFormValidationErrors): void {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      forms: {
        ...state.forms,
        [key]: {
          ...state.forms[key],
          validationErrors: validationErrors,
        },
      },
    });
  }

  @Action(ResetFormFieldValidationErrors)
  public resetFormFieldValidationErrors(ctx: StateContext<FormRendererStateModel>, {
    key,
    field
  }: ResetFormFieldValidationErrors): void {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      forms: {
        ...state.forms,
        [key]: {
          ...state.forms[key],
          validationErrors: {
            ...state.forms[key]?.validationErrors,
            [field]: null
          },
        },
      },
    });
  }
}
