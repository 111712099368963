import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormSaverService } from '../../services/form-saver.service';

interface FormRendererButton {
  icon: string;
  key: string;
  name: string;
  options: any;
  permissions: string[] | null;
}

@Component({
  selector: 'cpb-form-cancel-button',
  template: `
    <ng-container *ngxPermissionsOnly="actionConfiguration.permissions">
      <cap-button *ngIf="actionConfiguration" [label]="actionConfiguration.name"
                  [icon]="actionConfiguration.icon"
                  styleClass="secondary"
                  [disabled]="submitting"
                  (click)="submit()">
      </cap-button>
    </ng-container>
  `,
  styleUrls: ['./cancel-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CancelButtonComponent {
  @Input()
  public actionConfiguration: FormRendererButton;

  @Input()
  public key: string;

  public submitting: boolean = false;
  public loadingIconClass = 'fas fa-spinner fa-spin';

  constructor(
    private readonly formSaverService: FormSaverService,
  ) {
  }

  public submit(): void {
    this.formSaverService.cancel(this.key);
  }
}
