import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { Subscription } from 'rxjs';
import { Table } from 'primeng/table';

@Component({
  selector: 'cap-sort-icon',
  template: `
        <i class="fas " [ngClass]="{'fa-sort-up': sortOrder === 1, 'fa-sort-down': sortOrder === -1, 'fa-sort': sortOrder === 0}"></i>
    `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class CapturumSortIconComponent implements OnInit, OnDestroy {
  @Input() public field: string;

  public subscription: Subscription;
  public sortOrder: number;

  constructor(public dt: Table, public cd: ChangeDetectorRef) {
    this.subscription = this.dt.tableService.sortSource$.subscribe(sortMeta => {
      this.updateSortState();
    });
  }

  public ngOnInit(): void {
    this.updateSortState();
  }

  public onClick(event: any): void {
    event.preventDefault();
  }

  public updateSortState(): void {
    if (this.dt.sortMode === 'single') {
      this.sortOrder = this.dt.isSorted(this.field) ? this.dt.sortOrder : 0;
    } else if (this.dt.sortMode === 'multiple') {
      const sortMeta = this.dt.getSortMeta(this.field);
      this.sortOrder = sortMeta ? sortMeta.order : 0;
    }

    this.cd.markForCheck();
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
