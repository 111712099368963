import { reportsRoutes } from '@capturum/powerbi';
import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { AuthGuardService } from './core/guards/auth-guard.service';
import { CompleteResolver, IndexedDbGuard, TranslationResolver } from '@capturum/complete';
import { MakerLayoutComponent } from './features/maker/layouts/maker-layout/maker-layout.component';

const routes: Route[] = [
  {
    path: '',
    redirectTo: 'maker',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadChildren: () => import('./features/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'maker',
    canActivate: [IndexedDbGuard],
    canLoad: [AuthGuardService],
    resolve: {
      complete: CompleteResolver,
      translation: TranslationResolver,
    },
    loadChildren: () => import('./features/maker/maker.module').then(m => m.MakerModule),
  },
  {
    path: 'rental-cars',
    component: MakerLayoutComponent,
    loadChildren: () => import('./practice-assignment/rental-car/rental-car.module').then((m) => m.RentalCarModule),
  },
  {
    path: 'reports',
    component: MakerLayoutComponent,
    resolve: {
      complete: CompleteResolver,
      translation: TranslationResolver,
    },
    children: [
      ...reportsRoutes[0].children,
    ],
  },
  {
    path: 'power-bi',
    component: MakerLayoutComponent,
    loadChildren: () => import('@capturum/powerbi/pages').then(m => m.CapturumPowerBIPagesModule),
  },
  {
    path: 'flow',
    component: MakerLayoutComponent,
    loadChildren: () => import('@capturum/flow').then(m => m.CapturumFlowModule),
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top',
  })],
  exports: [RouterModule],
})
export class AppRoutingModule {

}
