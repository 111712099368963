import { Component, OnDestroy, OnInit } from '@angular/core';
import { CapturumBuilderActionService } from '@capturum/builders/core';
import { FormSaverService } from '@capturum/builders/form-renderer';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { PowerBIManageDetailsBase } from '../../base/power-bi-manage-details-base.class';
import { Subscription } from 'rxjs';

@Component({
  selector: 'cap-pbi-reports-manage-details',
  templateUrl: './reports-manage-details.component.html',
  styleUrls: ['./reports-manage-details.component.scss'],
})
export class CapturumPowerBIReportsManageDetailsComponent extends PowerBIManageDetailsBase implements OnInit, OnDestroy {
  public key: string = 'form_edit_power_bi_report';
  private subscription: Subscription = new Subscription();

  constructor(
    protected formSaverService: FormSaverService,
    protected config: DynamicDialogConfig,
    protected dialogRef: DynamicDialogRef,
    protected actionService: CapturumBuilderActionService,
  ) {
    super(formSaverService, config, dialogRef, actionService);
  }

  public ngOnInit(): void {
    this.subscription.add(
      this.dialogRef.onClose.subscribe(() => {
          this.actionService.broadcastActionExecuted(this.config?.data?.action, this.item, null);
        },
      ),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
