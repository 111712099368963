import { AuthSmsComponent } from './components/auth-sms/auth-sms.component';
import { CapturumButtonModule } from '@capturum/ui/button';
import { AuthEmailComponent } from './components/auth-email/auth-email.component';
import { AuthAuthenticatorComponent } from './components/auth-authenticator/auth-authenticator.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../shared/shared.module';
import { RouterModule } from '@angular/router';
import { UserFormComponent } from './components/user-form/user-form.component';
import { PasswordFormComponent } from './components/password-form/password-form.component';
import { MultiFactorFormComponent } from './components/multi-factor-form/multi-factor-form.component';
import { CapturumInputModule } from '@capturum/ui/input';
import { KeyFilterModule } from 'primeng/keyfilter';
import { MultiFactorDialogComponent } from './components/multi-factor-dialog/multi-factor-dialog.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import {
  MultiFactorDisableFormComponent,
} from './components/multi-factor-disable-form/multi-factor-disable-form.component';
import {
  MultiFactorEnableFormComponent,
} from './components/multi-factor-enable-form/multi-factor-enable-form.component';
import { CapturumInputCodeModule } from '@capturum/ui/input-code';

@NgModule({
  declarations: [
    UserProfileComponent,
    UserFormComponent,
    PasswordFormComponent,
    MultiFactorFormComponent,
    MultiFactorDialogComponent,
    MultiFactorEnableFormComponent,
    MultiFactorDisableFormComponent,
    AuthAuthenticatorComponent,
    AuthEmailComponent,
    AuthSmsComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    RouterModule,
    CapturumInputModule,
    CapturumInputCodeModule,
    KeyFilterModule,
    CapturumButtonModule,
    ProgressSpinnerModule,
  ],
  exports: [
    UserProfileComponent,
    UserFormComponent,
    PasswordFormComponent,
    MultiFactorFormComponent,
    MultiFactorDialogComponent,
    MultiFactorEnableFormComponent,
    MultiFactorDisableFormComponent,
    AuthAuthenticatorComponent,
  ],
})
export class UserModule {
}
