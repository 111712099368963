import { Component, Input } from '@angular/core';
import { SkeletonShape } from '@capturum/ui/api';

@Component({
  selector: 'cap-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss']
})
export class CapturumSkeletonComponent {
  /**
   * Skeleton shape type: circle, square or bar (default)
   */
  @Input()
  public type: SkeletonShape = SkeletonShape.bar;

  /**
   * Width css value (e.g. 30% or 200px)
  */
  @Input()
  public width?: string;

  /**
   * Height css value (e.g. 30% or 200px)
   */
  @Input()
  public height?: string;

  public SkeletonShape: typeof SkeletonShape = SkeletonShape;
}
