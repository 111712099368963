import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { AuthService } from '@capturum/auth';
import User from '@capturum/auth/lib/user.interface';
import { DestroyBase } from '@capturum/shared';
import { ToastService } from '@capturum/ui/api';
import { TranslateService } from '@ngx-translate/core';
import { IReportEmbedConfiguration, models, Report, service } from 'powerbi-client';
import { PowerBIReportEmbedComponent } from 'powerbi-client-angular';
import { from } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { EventHandlers } from '../../enums/event-handlers.enum';
import { ReportType } from '../../enums/report-type.enum';
import { PowerBiApiService } from '../../services/power-bi-api.service';
import { PowerBIService } from './../../services/power-bi.service';

const defaultLanguageSettings = { language: 'en', locale: 'en-en' };

@Component({
  selector: 'cap-power-bi-report',
  templateUrl: './power-bi-report.component.html',
  styleUrls: ['./power-bi-report.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CapturumPowerBiReportComponent extends DestroyBase implements OnChanges, OnInit {
  @Input()
  public reportId: string;
  @Input()
  public styleClass: string = '';
  @Input()
  public reportPage: string;
  @Input()
  public tokenEndpoint?: string;
  @Input()
  public localeSettings?: { locale: string, language: string };

  @ViewChild(PowerBIReportEmbedComponent)
  public reportComponent!: PowerBIReportEmbedComponent;

  public isEmbedded = false;
  public report: Report;
  public eventHandlersMap = new Map<string, (event?: service.ICustomEvent<any>) => void>([
    [EventHandlers.loaded, this.reportLoaded.bind(this)],
    [EventHandlers.rendered, this.reportRenderered.bind(this)],
    [EventHandlers.error, this.reportError.bind(this)],
  ]);

  constructor(
    private apiService: PowerBiApiService,
    private authService: AuthService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private powerBIservice: PowerBIService,
  ) {
    super();

  }

  private _reportConfig: IReportEmbedConfiguration = {
    type: ReportType.report,
    embedUrl: undefined,
    tokenType: models.TokenType.Embed,
    accessToken: undefined,
    settings: {
      panes: {
        bookmarks: {
          visible: false,
        },
        fields: {
          visible: false,
          expanded: false,
        },
        filters: {
          expanded: false,
          visible: false,
        },
        pageNavigation: {
          visible: false,
          position: models.PageNavigationPosition.Left,
        },
        selection: {
          visible: false,
        },
        syncSlicers: {
          visible: false,
        },
        visualizations: {
          visible: false,
          expanded: false,
        },
      },
      localeSettings: {
        formatLocale: defaultLanguageSettings.locale,
        language: defaultLanguageSettings.language,
      },
    },
  };

  /**
   * To bootstrap powerbi entity, pass the property embedConfig to the component without accessToken
   */
  public get reportConfig(): IReportEmbedConfiguration {
    return this._reportConfig;
  }

  @Input()
  public set reportConfig(config: Partial<IReportEmbedConfiguration>) {
    this._reportConfig = {
      ...this._reportConfig,
      ...config,
    };
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.reportPage?.previousValue !== changes.reportPage?.currentValue || changes.reportId?.previousValue !== changes.reportId?.currentValue) {
      this.setReportActivePage();
      this.getNewReportConfig();
    }
  }

  public ngOnInit(): void {
    this.getNewReportConfig();
  }

  private getNewReportConfig(): void {
    const user: User = this.authService.getUser();
    const userFormatLocale: string = user?.locale?.code + '-' + user?.locale?.code;

    this.reportConfig = {
      ...this.reportConfig,
      settings: {
        ...this.reportConfig.settings,
        localeSettings: {
          formatLocale: userFormatLocale || this.localeSettings?.locale || this.reportConfig.settings.localeSettings.formatLocale,
          language: user?.locale?.code || this.localeSettings?.language || defaultLanguageSettings.language,
        },
      },
    };

    if (this.reportId) {
      this.apiService
        .getEmbedConfig(this.tokenEndpoint, this.reportId)
        .pipe(takeUntil(this.destroy$))
        .subscribe((config) => {
          if (config) {
            this.reportConfig = {
              ...this.reportConfig,
              id: config.report_id,
              embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${config.report_id}`,
              accessToken: config.token,
            };

            if (config.page_name) {
              this.reportPage = config.page_name;
            }
          }
        });
    }

    this.powerBIservice
      .setActivePage()
      .pipe(
        takeUntil(this.destroy$),
        filter((page) => page.reportKey === this.reportId),
      )
      .subscribe((page) => {
        this.report.setPage(page.pageName);
      });
  }

  /**
   * Event Handler when report is initialized
   */
  private reportLoaded(): void {
    this.setReportActivePage();
  }

  private setReportActivePage(): void {
    const report = this.reportComponent?.getReport();

    if (report) {
      from(report.getPages())
        .pipe(
          map((pages) =>
            pages
              .filter((page) => page.visibility === 0)
              .find((page) => page.displayName === this.reportPage),
          ),
          filter(Boolean),
          takeUntil(this.destroy$),
        )
        .subscribe((page) => page.setActive());
    }
  }

  /**
   * Event handler for errors of report
   * @param event error
   */
  private reportError(event?: service.ICustomEvent<any>): void {
    if (event) {
      this.toastService.error(
        this.translateService.instant('toast.error.title'),
        event.detail,
      );
    }
  }

  /**
   * Report has rendered
   */
  private reportRenderered(): void {
    // Update embed status
    this.isEmbedded = true;

    this.report = this.reportComponent.getReport();

    if (this.report) {
      from(this.report.getPages())
        .pipe(
          map((pages) => pages.filter((page) => page.visibility === 0)),
          takeUntil(this.destroy$),
        )
        .subscribe((pages) => this.powerBIservice.setPages(pages));
    }
  }
}
