import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getOption'
})
export class GetOptionPipe implements PipeTransform {
  public transform(value: string, options: any[], property: string = 'value'): any {
    const option = (options || []).find(item => item[property] === value);

    return option ? option : null;
  }
}
