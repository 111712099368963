import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CapturumLayoutDetailsComponent } from './details.component';
import { CapturumHeadlineModule } from '../headline/headline.module';
import { CapturumSharedModule } from '@capturum/ui/api';

@NgModule({
  declarations: [
    CapturumLayoutDetailsComponent
  ],
  exports: [
    CapturumLayoutDetailsComponent
  ],
  imports: [
    CommonModule,
    CapturumSharedModule,
    CapturumHeadlineModule
  ]
})
export class CapturumLayoutDetailsModule {
}
