import { MapItem, TwoFactorAuthService, TwoFactorMethod } from '@capturum/auth';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DestroyBase } from '@capturum/shared';
import { takeUntil } from 'rxjs/operators';
import { CapturumInputCodeComponent } from '@capturum/ui/input-code';

@Component({
  selector: 'emc-multi-factor-disable-form',
  templateUrl: './multi-factor-disable-form.component.html',
  styleUrls: ['./multi-factor-disable-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MultiFactorDisableFormComponent extends DestroyBase implements OnInit {
  public types$: Observable<MapItem[]>;
  public formGroup: FormGroup;
  public paragraphNumbers: Record<string, number>;

  @Input()
  public selectedMethod: TwoFactorMethod;

  @Output()
  public autoSubmit = new EventEmitter<{ code: string, method: TwoFactorMethod }>();

  @Output()
  public currentForm = new EventEmitter<{ code: string, method: TwoFactorMethod, isValid: boolean }>();

  @ViewChild(CapturumInputCodeComponent)
  private inputCodeComponent: CapturumInputCodeComponent;

  public get controlMethod(): AbstractControl {
    return this.formGroup.get('method');
  }

  public get controlCode(): AbstractControl {
    return this.formGroup.get('code');
  }

  constructor(
    protected twoFactorAuthService: TwoFactorAuthService,
    private formBuilder: FormBuilder,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.types$ = this.twoFactorAuthService.list2faMethods();
    this.formGroup = this.generateForm();

    this.updateParagraphNumbers();
    this.onFormGroupChange();
  }

  public appInputCodeFilled(): void {
    this.autoSubmit.emit(this.formGroup.value);
  }

  public updateParagraphNumbers(): void {
    this.paragraphNumbers = {
      'code': 1,
    };
  }

  public showAndFocusInputCode(): void {
    this.resetCode();

    Promise.resolve(null).then(() => {
      this.inputCodeComponent.focus();
    });
  }

  private resetCode(): void {
    this.controlCode.setValue(null);
  }

  private generateForm(): FormGroup {
    return this.formBuilder.group({
      code: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
      method: [this.selectedMethod, Validators.required],
    });
  }

  private onFormGroupChange(): void {
    this.formGroup.valueChanges.pipe(
      takeUntil(this.destroy$),
    ).subscribe(() => {
      this.currentForm.emit({
        code: this.formGroup.value.code,
        method: this.formGroup.value.method,
        isValid: this.formGroup.valid,
      });
    });
  }
}
