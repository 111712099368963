<cap-input [formControl]="formControl"
           [floatLabel]="(to.label_position | observablePipe | async ) === labelPosition.Floating"
           [label]="(to.label_position | observablePipe | async ) === labelPosition.Floating ? to.label : ''"
           [hasTooltip]="to.tooltip"
           [hidden]="to.readonly"
           [readonly]="to.readonly"
           [attr.data-test]="'builder-' + field.key"
           [placeholder]="to.readonly ? '' : to.placeholder"
           type="email">
</cap-input>

<cpb-readonly-container [hidden]="!to.readonly" [attr.data-test]="'builder-readonly-' + field.key">
  {{ formControl.value | default: to?.defaultEmptyValue }}
</cpb-readonly-container>
