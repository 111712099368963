import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ApiHttpService } from '@capturum/api';
import { BuilderApiService, InputConfiguration } from '@capturum/builders/core';
import { FormManipulation, FormManipulations } from '../interfaces/form-manipulator.interface';

@Injectable({ providedIn: 'root' })
export class FormManipulatorService {
  private formManipulations = new BehaviorSubject<Record<string, FormManipulation>>(null);

  constructor(private apiHttpService: BuilderApiService) {
  }

  public get formManipulations$(): Observable<FormManipulations> {
    return this.formManipulations.asObservable();
  }

  public getFormManipulation(formKey: string): Observable<FormManipulation> {
    return this.formManipulations$.pipe(
      filter((manipulations) => !!manipulations[formKey]),
      map((manipulations) => manipulations[formKey]),
    );
  }

  public getFieldManipulations(formKey: string, fieldKey: string): Observable<Record<string, any>> {
    return this.formManipulations$.pipe(
      filter((manipulations) => !!manipulations?.[formKey]?.[fieldKey]),
      map((manipulations) => manipulations[formKey][fieldKey]),
    );
  }

  public setFieldManipulation(formKey: string, fieldKey: string, manipulations: Record<string, any>): void {
    this.formManipulations.next({
      [formKey]: {
        [fieldKey]: manipulations
      }
    });
  }

  public registerTriggerHandler(field: FormlyFieldConfig, formKey: string, configuration: InputConfiguration): void {
    if (field?.formControl) {
      field.formControl.valueChanges.pipe(
        debounceTime(400),
        switchMap(() => {
          return this.apiHttpService.post(formKey, configuration.trigger.action.endpoint, field.form.getRawValue());
        }),
        takeUntil(field.props.destroy),
      ).subscribe((response) => {
        this.formManipulations.next({
          [formKey]: response,
        });
      });
    }
  }

  public resetFormManipulations(formKey: string): void {
    this.formManipulations.next({
      [formKey]: null,
    });
  }
}
