import { Injectable } from '@angular/core';
import { InputTypeBuilder } from '../input/input-type.builder';
import { TextInputConfiguration } from '../../models/text-input-configuration.model';
import { FormInputConfig } from '../../interfaces/form-input-config';
import { InputConfiguration, InputType } from '@capturum/builders/core';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Injectable({ providedIn: 'root' })
export class TextInputTypeBuilder extends InputTypeBuilder implements FormInputConfig<TextInputConfiguration> {
  protected inputType: string = InputType.text;

  protected appendOptions(input: FormlyFieldConfig, configuration: InputConfiguration): void {
    input.props = { ...input.props, content_left: configuration?.options?.content_left ?? null };
  }
}
