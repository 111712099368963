import { AfterViewInit, Component, ElementRef, EventEmitter, Injector, Input, Output, ViewChild } from '@angular/core';
import { FormControl, NgControl, NgModel } from '@angular/forms';
import { ValidatorService, ValueAccessorBase } from '@capturum/ui/api';

@Component({
  selector: 'cap-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  providers: [ValueAccessorBase.getProviderConfig(CapturumTextareaComponent)],
})
export class CapturumTextareaComponent extends ValueAccessorBase<string> implements AfterViewInit {
  @ViewChild(NgModel, { static: true }) public model: NgModel;
  @Input() public autoResize: boolean;
  @Input() public cols = 30;
  @Input() public rows = 10;
  @Input() public disabled: boolean;
  @Input() public placeholder = '';
  @Input() public label: string;
  @Input() public readonly: boolean;
  /**
   * Specifies if the tooltip is present
   */
  @Input() public hasTooltip: boolean;
  /**
   * Select current value from input when will activate it
   */
  @Input() public selectOnFocus: boolean;
  /**
   * Float label
   */
  @Input() public floatLabel = false;
  /**
   * When present, it specifies that an input field must be filled out before submitting the form
   */
  @Input() public required: boolean;

  @Output() public resize: EventEmitter<any> = new EventEmitter();

  @ViewChild('textarea') public textarea: ElementRef<HTMLTextAreaElement>;

  public control: FormControl;

  constructor(
    private injector: Injector,
    private validatorService: ValidatorService,
  ) {
    super();
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      const ngControl: NgControl = this.injector.get(NgControl, null);
      this.control = this.validatorService.extractFormControl(ngControl);
    });
  }

  public onFocus(): void {
    this.control.markAsTouched();

    if (this.selectOnFocus) {
      this.textarea?.nativeElement?.select();
    }
  }
}
