export enum Colors {
  Primary = '#dd0053',
  Secondary = '#333',
  Third = '#dddddd',
  Text = '#333',
  Text2 = '#5f6062',
  Text3 = 'rgba(51,51,51,0.5)',
  Text4 = '#ffffff',
  ToastText = '#212121',
  Success = '#4cd964',
  Warning = '#ff9500',
  Confirm = '#35c44d',
  PrimaryShadow = 'rgba(221, 0, 83, 0.5)',
  SecondaryShadow = 'rgba(255, 255, 255, 0.25)',
  ThirdShadow = 'rgba(221, 221, 221, 0.25)',
  SuccessShadow = 'rgba(76, 217, 100, 0.5)',
  WarningShadow = 'rgba(255, 149, 0, 0.25)',
  ErrorShadow = 'rgba(255, 59, 48, 0.25)',
  ConfirmShadow = 'rgba(53, 196, 77, 0.5)',
  Error = '#ff3b30',
  Info = '#dd0053'
}
