import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DateFormat, TablePaginator } from '@capturum/ui/api';
import { InfoTableColumn, InfoTableColumnType } from '@capturum/ui/info-table';
import { TranslateService } from '@ngx-translate/core';
import { LazyLoadEvent } from 'primeng/api';
import { map, Observable } from 'rxjs';
import { skip, switchMap, tap } from 'rxjs/operators';

import { FailedBatchJob } from './../../../../shared/models/batch-status.model';
import { BatchStatusService } from './../../../../shared/services/batch-status.service';
import { TableService } from './../../../../shared/services/table.service';

@Component({
  selector: 'cap-jobs-list',
  templateUrl: './jobs-list.component.html',
  styleUrls: ['./jobs-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    TableService,
  ]
})
export class JobsListComponent implements OnInit {
  public loading: boolean = true;
  public tableColumns: InfoTableColumn[] = [];
  public data$: Observable<FailedBatchJob[]>;
  public pendingJobsCount$: Observable<number>;
  public paginator$: Observable<TablePaginator>;
  public dateFormat = DateFormat;

  constructor(
    private batchStatusService: BatchStatusService,
    private tableService: TableService,
    private translateService: TranslateService,
  ) { }

  public ngOnInit(): void {
    this.paginator$ = this.tableService.getPaginator();
    this.tableColumns = this.generateColumns();
    this.pendingJobsCount$ = this.batchStatusService.getBatchSize().pipe(map(response => response.pending_jobs));

    this.data$ = this.tableService.getUpdateTable().pipe(
      skip(1),
      switchMap(options => {
        return this.batchStatusService.getFailedJobs(options).pipe(
          tap(({ meta }) => {
            this.tableService.setPaginator(meta);
          }),
          map(response => response?.data),
        );
      }),
      tap(() => {
        this.loading = false;
      }),
    );
  }

  public loadTableData(event?: LazyLoadEvent): void {
    if (!event?.sortField) {
      event.sortField = 'failed_at';
      event.sortOrder = 0;
    }

    this.tableService.updateTableByLazyLoadEvent(event);
  }

  private generateColumns(): InfoTableColumn[] {
    return [
      {
        field: 'connection',
        title: this.translateService.instant('jobs.list.column.connection.label'),
      },
      {
        field: 'queue',
        title: this.translateService.instant('jobs.list.column.queue.label'),
      },
      {
        field: 'failed_at',
        title: this.translateService.instant('jobs.list.column.failed-at.label'),
        type: InfoTableColumnType.Template,
      },
    ];
  }
}
