import { Injectable } from '@angular/core';
import { ActionProvider } from '../interfaces/action-provider.interface';
import { DynamicSidebarComponent } from '../components/dynamic-sidebar/dynamic-sidebar.component';
import { OpenContainerAction } from './open-container.action';

@Injectable({ providedIn: 'root' })
export class OpenSidebarAction implements ActionProvider {
  constructor(
    private openContainerAction: OpenContainerAction,
  ) {
  }

  public execute(options: any, item: any): void {
    this.openContainerAction.execute({ ...options, containerType: DynamicSidebarComponent }, item);
  }
}

