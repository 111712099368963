import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ActiveFilters, DynamicFilterConfig, FilterConfigItem } from '../../interfaces/dynamic-filter.interface';
import { LocalStorageService } from '@capturum/ui/api';

@Component({
  selector: 'cap-dynamic-filters-sidebar',
  templateUrl: './dynamic-filters-sidebar.component.html',
  styleUrls: ['./dynamic-filters-sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DynamicFiltersSidebarComponent implements OnInit {
  @Input()
  public storageKey: string;
  @Input()
  public filterStyleClass: string;
  @Output()
  public filterChange = new EventEmitter<ActiveFilters[]>();
  @Output()
  public cancelClick = new EventEmitter<void>();
  public hiddenFilters: FilterConfigItem[];
  public shownFilters: FilterConfigItem[];
  public draggable: boolean;
  private _originalFilterValue: ActiveFilters[];

  constructor(private readonly localStorageService: LocalStorageService) {}

  private _filterConfig: DynamicFilterConfig;

  get filterConfig(): DynamicFilterConfig {
    return this._filterConfig;
  }

  @Input()
  set filterConfig(value: DynamicFilterConfig) {
    this._filterConfig = {
      ...value,
      filters: value.filters.map((filterConfig) => {
        return {
          ...filterConfig,
          showLabel: true,
        };
      }),
    };
  }

  private _activeFilters: ActiveFilters[] = [];

  get activeFilters(): ActiveFilters[] {
    return this._activeFilters;
  }

  @Input()
  set activeFilters(value: ActiveFilters[]) {
    this._activeFilters = value;
    this._originalFilterValue = value;
  }

  public ngOnInit(): void {
    const hiddenFilterKeys = this.localStorageService.getItem(this.getShownFiltersStorageKey());

    if (!hiddenFilterKeys) {
      this.shownFilters = this._filterConfig.filters.filter((filter) => {
        return !filter.hidden;
      });

      this.hiddenFilters = this._filterConfig.filters.filter((filter) => {
        return filter.hidden;
      });
    } else {
      this.shownFilters = this._filterConfig.filters.filter((filter) => {
        return !hiddenFilterKeys.includes(filter.field);
      });

      this.hiddenFilters = this._filterConfig.filters.filter((filter) => {
        return hiddenFilterKeys.includes(filter.field);
      });
    }
  }

  public setSidebarFilter(updatedFilter: { field: string; value: unknown }): void {
    if (!this._activeFilters.some((filter) => filter.field === updatedFilter.field)) {
      this._activeFilters = [
        ...this._activeFilters,
        {
          ...updatedFilter,
          matchMode: this._filterConfig.filters.find((filter) => filter.field === updatedFilter.field)?.matchMode,
        },
      ];
    } else {
      this._activeFilters = this._activeFilters.reduce((acc, filter) => {
        if (filter.field === updatedFilter.field) {
          return [...acc, { ...filter, value: updatedFilter.value }];
        }

        return [...acc, filter];
      }, []);
    }
  }

  public applyFilters(): void {
    this.filterChange.emit(this._activeFilters);
  }

  public cancel(): void {
    this._activeFilters = this._originalFilterValue;

    this.cancelClick.emit();
  }

  public reset(): void {
    this._activeFilters = this._originalFilterValue;
  }

  private getShownFiltersStorageKey(): string {
    return `${this.storageKey}-hiddenFilters`;
  }
}
