export interface ToastOptions {
  /**
   * Whether the message should be automatically closed based on life property or kept visible
   */
  sticky: boolean;
  /**
   * When enabled, displays a close icon to hide a message manually
   */
  closable: boolean;
  /**
   * Number of time in milliseconds to wait before closing the message
   */
  life: number;
  /**
   * Key of the message in case message is targeted to a specific toast component
   */
  key: string;
}
