import { Injectable } from '@angular/core';
import { ListRendererActionConfig } from '../models/list-renderer-config.model';
import { CapturumBuilderActionService } from '@capturum/builders/core';
import { ListRendererTableAction } from '../types/list-renderer-table-action.type';
import { ListRendererConfigService } from './list-renderer-config.service';

@Injectable()
export class ListRendererActionsService {
  constructor(
    private readonly actionService: CapturumBuilderActionService,
    private listRendererConfigService: ListRendererConfigService,
  ) {
  }

  public prepareActions(configActions: ListRendererActionConfig[], configurationKey: string, contextKey?: string): ListRendererTableAction[] {
    const moduleConfig = this.listRendererConfigService.config?.[configurationKey];

    return configActions?.map(configAction => {
      let additionalProperties = {};
      const moduleActionConfig = moduleConfig?.actions?.[configAction.key];

      if (moduleActionConfig) {
        additionalProperties = {
          hiddenExpression: moduleActionConfig.hiddenExpression ?? false,
          component: moduleActionConfig.component,
        }
      }

      return {
        label: configAction.name,
        key: configAction.key,
        icon: configAction.icon,
        callback: (item) => {
          this.actionService.executeAction({
              key: configAction.key,
              options: configAction.action,
              type: configAction.action.type,
            },
            item,
            configurationKey,
            contextKey,
          );
        },
        ...additionalProperties,
      };
    });
  }
}
