import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'em-fomrly-textarea',
  templateUrl: './textarea.component.html',
})
export class TextareaTypeComponent extends FieldType {

}
