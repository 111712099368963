import { IndexedDbModel } from '../../../shared/indexedDb/indexedDb.model';

export class TenantIndexedDbModel extends IndexedDbModel {
  // Configuration
  public entity = 'tenant';
  public table = 'tenants';
  public schema = '++id, name';
  public sync = false;

  // Fields
  public name: string;
  public modules: any[];

  // Constructor
  constructor(attributes: Partial<TenantIndexedDbModel>) {
    super(attributes);
  }
}
