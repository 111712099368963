import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { LayoutTemplateConfig } from './interfaces/template-config.interface';
import { ConfigOption } from '@ngx-formly/core';
import { InputBuilderType } from './types/input-builder.type';
import { TypeOption, WrapperOption } from '@ngx-formly/core/lib/models';

export interface DynamicFormConfig {
  key: string;
  callback: (injector: Injector) => Observable<any>;
}

export interface FormRendererConfig {
  translationKeyPrefix?: string;
  formBuilderApi?: string;
  dynamicForms?: DynamicFormConfig[];
  layoutTemplates?: LayoutTemplateConfig;
  types?: TypeOption[];
  builders?: InputBuilderType;
  wrappers?: WrapperOption[];
  defaultEmptyValue?: string;
}

@Injectable({ providedIn: 'root' })
export class FormRendererConfigService implements FormRendererConfig {
  public translationKeyPrefix = '';
  public formBuilderApi!: string;
  public dynamicForms!: DynamicFormConfig[];
  public layoutTemplates?: LayoutTemplateConfig = {};
  public types?: TypeOption[];
  public builders?: InputBuilderType;
  public wrappers?: WrapperOption[];
  public defaultEmptyValue?: string;

  public addConfig(config: FormRendererConfig): void {
    if (config?.translationKeyPrefix) {
      this.translationKeyPrefix = config.translationKeyPrefix;
    }

    if (config?.formBuilderApi) {
      this.formBuilderApi = config.formBuilderApi;
    }

    if (config?.dynamicForms) {
      this.dynamicForms = [
        ...(config.dynamicForms || []),
        ...(this.dynamicForms || []),
      ];
    }

    if (config?.layoutTemplates) {
      this.layoutTemplates = {
        ...this.layoutTemplates,
        ...config.layoutTemplates,
      };
    }

    if (config?.types) {
      this.types = [
        ...(this.types || []),
        ...(config.types || []),
      ];
    }

    if (config?.builders) {
      this.builders = {
        ...this.builders,
        ...config.builders,
      };
    }

    if (config?.defaultEmptyValue) {
      this.defaultEmptyValue = config.defaultEmptyValue;
    }
  }
}
