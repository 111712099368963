<ng-container *ngIf="historyItemsArray && historyItemsArray.length; else emptyResultsTemplate || emptyMessage">
  <div class="history">
    <div class="timeline">
        <ng-container *ngFor="let historyItem of historyItemsArray; let i = index; trackBy: trackByIndex">
          <ng-container *ngIf="historyItem.value?.modelLogs as logs">
            <div class="timeline__card">
              <div *ngFor="let item of logs; trackBy: trackByIndex" class="timeline__content">
                <div class="timeline__title">
                  <ng-container *ngTemplateOutlet="headerTemplate || defaultTitle;context: {$implicit: item}">
                  </ng-container>

                  <ng-template #defaultTitle>
                    {{ ('model-log.action.' + item.action | lowercase) | translate : {user: item.user.name} }}

                    <div class="timeline__created">
                      {{ 'model-log.created-by' | translate : {
                      user: item.user.name,
                      date: item.created_on | date: headerDateFormat
                    } }}
                    </div>
                  </ng-template>
                </div>

                <div class="timeline__body mt-2">
                  <ng-container *ngIf="item?.diffs | keyvalue as diffArray">
                    <div class="body">
                      <hr />

                      <div class="row body-row" *ngFor="let diff of diffArray; trackBy: trackByIndex">
                        <ng-container
                          *ngTemplateOutlet="bodyTemplate || defaultBody; context: { diff: diff, item: item }"></ng-container>
                      </div>

                      <ng-container *ngIf="item.comment || item.reason">
                        <hr />

                        <div class="row">
                          <div class="col-md-6 semi-bold dont-break-out">
                            {{ 'model-log.comment' | translate }}
                          </div>

                          <div class="col-md-6 dont-break-out">
                            {{ item.comment ? item.comment : item.reason }}
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
        </ng-container>
      </ng-container>
    </div>
  </div>

</ng-container>

<ng-template #defaultBody let-diff="diff" let-item="item">
  <ng-container *ngIf="(diff.value.hideNew === undefined || !(diff.value.hideNew === true))
                        || (diff.value.hideOld === undefined || !(diff.value.hideOld === true))">
    <div class="col-md-6 semi-bold dont-break-out diff-value">
      {{ ((translationKeyPrefix ? (translationKeyPrefix + '.') : '') + (item.entity_key + '.' + diff.key + '.label' | lowercase)) | translate }}
    </div>

    <div class="col-md-6 dont-break-out">
      <ng-container *ngIf="diff.value.hideNew === undefined || !(diff.value.hideNew === true)">
        <span>
          {{ diff.value.new | cacModelLogDiff : dateFormat || ('model-log.empty' | translate) }}
        </span>
      </ng-container>

      <ng-container *ngIf="diff.value.hideOld === undefined || !(diff.value.hideOld === true)">
        <br />
        <span
          class="strikethrough">{{ diff.value.old  | cacModelLogDiff : dateFormat || ('model-log.empty' | translate) }}
        </span>
      </ng-container>
    </div>
  </ng-container>
</ng-template>

<ng-template #emptyMessage>
  <div class="timeline__no-results">
    {{ emptyResultsTranslationKey | translate }}
  </div>
</ng-template>
