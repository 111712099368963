import { Injectable } from '@angular/core';
import { InputTypeBuilder } from '../input/input-type.builder';
import { FormInputConfig } from '../../interfaces/form-input-config';
import { InputType } from '@capturum/builders/core';
import { InputConfiguration } from '@capturum/builders/core';

@Injectable({ providedIn: 'root' })
export class RadioInputTypeBuilder extends InputTypeBuilder implements FormInputConfig<InputConfiguration> {
  protected inputType: string = InputType.radio;
}
