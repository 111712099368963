import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ListRendererWidgetBase } from '@capturum/builders/list-renderer';

@Component({
  selector: 'cap-flow-action-widget',
  templateUrl: './flow-action-widget.component.html',
  styleUrls: ['./flow-action-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
})
export class CapturumFlowActionWidgetComponent extends ListRendererWidgetBase {}
