import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CapturumInputComponent } from './input.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { InputTextModule } from 'primeng/inputtext';
import { KeyFilterModule } from 'primeng/keyfilter';
import { TooltipModule } from 'primeng/tooltip';
import { CapturumSharedModule } from '@capturum/ui/api';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    CapturumInputComponent
  ],
  exports: [
    CapturumInputComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    InputTextModule,
    KeyFilterModule,
    CapturumSharedModule,
    TooltipModule,
    OverlayPanelModule,
    PanelModule,
  ]
})
export class CapturumInputModule {
}
