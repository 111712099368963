import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModelLogTimelineComponent } from './components/model-log-timeline/model-log-timeline.component';
import { TranslateModule } from '@ngx-translate/core';
import { ModelLogDiffPipe } from './pipes/model-log-diff.pipe';

@NgModule({
  declarations: [
    ModelLogTimelineComponent,
    ModelLogDiffPipe,
  ],
  imports: [
    CommonModule,
    TranslateModule,
  ],
  exports: [
    ModelLogTimelineComponent,
  ]
})
export class ModelLogModule { }
