<div class="d-flex">
  <cap-checkbox [hidden]="to.readonly" [formControl]="formControl" [trueValue]="to.trueValue" [falseValue]="to.falseValue" [attr.data-test]="'builder-' + field.key">
  </cap-checkbox>

  <cpb-readonly-container [hidden]="!to.readonly" [attr.data-test]="'builder-readonly-' + field.key">
    {{ !!formControl.value ? ('builders.checkbox.active' | translate) : ('builders.checkbox.inactive' | translate) }}
  </cpb-readonly-container>
</div>

<span *ngIf="to?.description" class="small-description">{{ to?.description }}</span>
