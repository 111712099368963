import { ApplicationRef, ComponentFactoryResolver, EmbeddedViewRef, Injectable, Injector } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { LoaderComponent } from '../loader.component';

/**
 * LoadingService
 *
 * @description
 * This is the loading service. This service can be used to display a loading spinner across the entire screen. To display a loading spinner simply call the toggleLoading method.
 * Here you can pass whether to show or hide the loader,
 * what text you want to display, what image to display (by default it uses the PrimeNG spinner) and you can add a styleClass to the loader to apply your own styles.
 */
@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  public loading$ = new BehaviorSubject<{ loading: boolean, text: string, image: string, styleClass: string }>({
    loading: false,
    text: null,
    image: null,
    styleClass: null
  });

  private isLoaderShown: boolean;

  constructor(private injector: Injector,
              private appRef: ApplicationRef,
              private componentFactoryResolver: ComponentFactoryResolver) {
    this.loading$.subscribe((loading) => {
      if (loading.loading === true && !this.isLoaderShown) {
        this.showLoader(loading.text, loading.image, loading.styleClass);
      }
    });
  }

  /**
   * Display or hide the loading spinner
   *
   * @param loading - whether to show or hide the loading spinner
   * @param text - the text displayed under the loading icon/image
   * @param image - the image you want to display as the loading icon. (By default it uses primeNG circle spinner)
   * @param styleClass - styleClass to add your own custom styles to the loader
   */
  public toggleLoading(loading?: boolean, text?: string, image?: string, styleClass?: string): void {
    this.loading$.next({ loading: (loading === undefined ? !this.loading$.getValue().loading : loading), text, image, styleClass });
  }

  public hideLoader(): void {
    this.loading$.next({ loading: false, text: null, image: null, styleClass: null });
  }

  private showLoader(text?: string, image?: string, styleClass?: string): void {
    this.isLoaderShown = true;
    const componentRef = this.componentFactoryResolver
      .resolveComponentFactory<LoaderComponent>(LoaderComponent)
      .create(this.injector);

    componentRef.instance.text = text;
    componentRef.instance.image = image;
    componentRef.instance.styleClass = styleClass;

    // Attach component to the appRef so that it's inside the ng component tree
    this.appRef.attachView(componentRef.hostView);

    // Get DOM element from component
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;

    // Append DOM element to the body
    document.body.appendChild(domElem);

    // Destroy element when loading ends
    this.loading$.pipe(
      filter((loading) => !loading.loading),
      take(1))
      .subscribe(() => {
        this.isLoaderShown = false;

        this.appRef.detachView(componentRef.hostView);
        componentRef.destroy();
      });
  }


}
