import { CAPTURUM_BUILDERS_BASE_URL } from './../providers/base-url.provider';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ActionProvider } from '../interfaces/action-provider.interface';
import { InterpolationPipe } from '@capturum/ui/api';

@Injectable({ providedIn: 'root' })
export class ConfigurationNavigateAction implements ActionProvider {
  constructor(
    private readonly router: Router,
    @Inject(CAPTURUM_BUILDERS_BASE_URL) private baseUrl: string,
    private readonly interpolationPipe: InterpolationPipe,
  ) {
  }

  public execute(options: any, item: { type: 'form' | 'list', id: string }): void {
    const url = this.interpolationPipe.transform(options.route, item);

    this.router.navigate([`${this.baseUrl}${url}`]);
  }
}
