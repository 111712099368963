import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TimeUnits } from '../../enums/time.enum';
import { parseValueFromString } from '../../utils/calendar.utils';

const timeLimits = {
  [TimeUnits.hour]: 23,
  [TimeUnits.minute]: 59
};

@Component({
  selector: 'cap-timepicker',
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.scss']
})
export class CapturumTimepickerComponent implements OnInit {
  get date(): Date {
    return this._date;
  }
  @Input()
  set date(value: Date) {
    this._date = value;
    this.initTime();
  }
  @Input() public timeLabel: string;
  @Input()
  public timeOnly: boolean;
  @Output() public timeChange: EventEmitter<{ item: TimeUnits, value: number }> = new EventEmitter();

  public readonly timeUnits = TimeUnits;
  public hours: number = null;
  public minutes: number = null;

  private _date: Date;

  public ngOnInit(): void {
    this.initTime();
  }

  public setTime(item: TimeUnits, event: any): void {
    let value = +event.target.value;

    if (value > timeLimits[item]) {
      value = 0;
      this[item] = 0;
    }

    this.timeChange.emit({ item, value });
  }

  private initTime(): void {
    if (this._date) {
      if (this._date && typeof this._date === 'string') {
        this._date = parseValueFromString(this._date) as unknown as Date;
      }
      this.hours = this._date.getHours();
      this.minutes = this._date.getMinutes();
    }
  }


}
