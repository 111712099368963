<cap-input
  [formControl]="formControl"
  [floatLabel]="(to.label_position | observablePipe | async) === labelPosition.Floating"
  [label]="(to.label_position | observablePipe | async) === labelPosition.Floating ? to.label : ''"
  [readonly]="to.readonly"
  [hidden]="to.readonly"
  [hasTooltip]="to.tooltip"
  [attr.data-test]="'builder-' + field.key"
  [selectOnFocus]="to?.select_on_focus"
  [placeholder]="to.readonly ? '' : to.placeholder">
  <ng-container *ngIf="to?.content_left">
    <ng-template capTemplate="content-left">{{ to?.content_left }}</ng-template>
  </ng-container>
</cap-input>

<cpb-readonly-container [hidden]="!to.readonly" [attr.data-test]="'builder-readonly-' + field.key">
  {{ formControl.value | default: to?.defaultEmptyValue }}
</cpb-readonly-container>
