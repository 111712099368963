import { FormControl, ValidationErrors } from '@angular/forms';

/**
 * Formly validator for ZIP code
 *
 * @param control
 */
export function zipCodeValidator(control: FormControl): ValidationErrors {

  /**
   * Standard ZIP code validation regular expression
   * Must have 1 digit between 1-9, 3 digits between 0-9 an optional whitespace and 2 letters
   */
  const zipRegExp: RegExp = new RegExp('^[1-9][0-9]{3}[\\s]?[A-Za-z]{2}$');

  return !control.value || zipRegExp.test(control.value) ? null : { 'zip': true };
}
