<div class="form-element">
  <label *ngIf="(label || hasTooltip) && !floatLabel"
         [style]="{ 'justify-content': label ? 'space-between' : 'flex-end' }">
    <span *ngIf="label">{{ label }}</span>

    <ng-content select=".tooltip-content"></ng-content>
  </label>

  <div>
    <ng-container *ngIf="!floatLabel; else floatingInput">
      <ng-container *ngTemplateOutlet="defaultTemplate"></ng-container>
    </ng-container>

    <ng-template #floatingInput>
      <span class="p-float-label autocomplete-float-label">
        <ng-container *ngTemplateOutlet="defaultTemplate"></ng-container>
        <label class="cpb-float-label" htmlFor="float-label">{{ label }}</label>
      </span>
    </ng-template>

    <ng-template #defaultTemplate>
      <p-autoComplete [(ngModel)]="value"
                      [appendTo]="appendTo"
                      [ariaLabel]="ariaLabel"
                      [ariaLabelledBy]="ariaLabelledBy"
                      [autoHighlight]="autoHighlight"
                      [autoZIndex]="autoZIndex"
                      [autofocus]="autofocus"
                      [baseZIndex]="baseZIndex"
                      [dataKey]="dataKey"
                      [delay]="delay"
                      [disabled]="disabled"
                      [dropdownIcon]="dropdownIcon"
                      [dropdownMode]="dropdownMode"
                      [dropdown]="dropdown"
                      [emptyMessage]="emptyMessage"
                      [field]="field"
                      [forceSelection]="forceSelection"
                      [hideTransitionOptions]="hideTransitionOptions"
                      [inputId]="inputId"
                      [inputStyleClass]="inputStyleClass"
                      [inputStyle]="inputStyle"
                      [maxlength]="maxlength"
                      [minLength]="minLength"
                      [multiple]="multiple"
                      [placeholder]="placeholder"
                      [readonly]="readonly"
                      [required]="required"
                      [scrollHeight]="scrollHeight"
                      [showTransitionOptions]="showTransitionOptions"
                      [size]="size"
                      [styleClass]="styleClass"
                      [style]="style"
                      [suggestions]="suggestionsFilter"
                      [tabindex]="tabindex"
                      [type]="type"
                      (completeMethod)="completeMethod.emit($event)"
                      (onBlur)="blur.emit($event)"
                      (onClear)="onClear.emit($event)"
                      (onDropdownClick)="onDropdownClick.emit($event)"
                      (onFocus)="focus.emit($event)"
                      (onKeyUp)="onKeyUp.emit($event)"
                      (onSelect)="onSelect.emit($event)"
                      (onUnselect)="onUnselect.emit($event)">
        <ng-container *ngIf="itemTemplate">
          <ng-template let-item pTemplate="item">
            <ng-container [ngTemplateOutletContext]="{$implicit: item}"
                          [ngTemplateOutlet]="itemTemplate"></ng-container>
          </ng-template>
        </ng-container>
      </p-autoComplete>
    </ng-template>
  </div>

  <cap-validator *ngIf="control" [control]="control" [customName]="label"></cap-validator>
</div>
