import { User } from '../../user/user.api-model';

export interface Diff {
  old: number | string;
  new: number | string;
  type?: string;
  hideNew?: boolean;
  hideOld?: boolean;
}

export interface ModelLog {
  id: string;
  model_loggable_type: string;
  original_model: string;
  new_model: string;
  user: User;
  reason: string;
  reason_type_base_data_value_id?: string;
  created_at?: string | Date;
  created_on?: string | Date;
  entity_key?: string;
  action?: string;
  diffs: {[key: string]: Diff};
  comment?: string;
  showDetails?: boolean;
}

export interface HistoryItem {
  hasDiff: boolean;
  showDetails: boolean;
  modelLogs: ModelLog[];
}
