import { Injectable } from '@angular/core';
import { Message, MessageService } from 'primeng/api';
import { ToastOptions } from '../models/toast-options.model';
import { ToastProvider } from '../models/toast-provider.model';

@Injectable({
  providedIn: 'root'
})
export class ToastService implements ToastProvider {
  constructor(private messageService: MessageService) {
  }

  /**
   * Show error message
   *
   * @param title
   * @param detail
   * @param options
   *
   * @return void
   */
  public error(title: string, detail: string, options?: Partial<ToastOptions>): void {
    this.message({ severity: 'error', summary: title, detail, ...options });
  }

  /**
   * Show warning
   *
   * @param title
   * @param detail
   * @param options
   *
   * @return void
   */
  public warning(title: string, detail: string, options?: Partial<ToastOptions>): void {
    this.message({ severity: 'warn', summary: title, detail, ...options });
  }

  /**
   * Show success message
   *
   * @param title
   * @param detail
   * @param options
   *
   * @return void
   */
  public success(title: string, detail: string,  options?: Partial<ToastOptions>): void {
    this.message({ severity: 'success', summary: title, detail, ...options });
  }

  /**
   * Show info message
   *
   * @param title
   * @param detail
   * @param options
   *
   * @return void
   */
  public info(title: string, detail: string,  options?: Partial<ToastOptions>): void {
    this.message({ severity: 'info', summary: title, detail, ...options });
  }

  /**
   * Add message to message service
   *
   * @param message
   *
   * @return void
   */
  public message(message: Message): void {
    this.messageService.add(message);
  }
}
