<div class="d-flex align-items-center" (click)="menu?.toggle($event)">
  <div class="user-info d-none d-lg-inline-block">
    <span class="d-block title">{{ config?.title }}</span>
    <span class="d-block subtitle">{{ config?.subTitle }}</span>
  </div>

  <div class="menu-cta">
    <i class="fas fa-ellipsis-v"></i>
  </div>

  <p-overlayPanel #menu appendTo="body" styleClass="cap-overlay-panel header-menu-bar">
    <div class="user-menu">
      <ul class="user-menu__items">
        @for (item of config?.items; track item) {
          <li (click)="executeCommand(item); menu?.hide()">
            <i [className]="item?.icon"></i>
            <span>{{ item?.label }}</span>
          </li>
        }
      </ul>
    </div>
  </p-overlayPanel>
</div>
