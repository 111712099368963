import { ChangeDetectorRef, Component, EventEmitter, Input, ViewEncapsulation } from '@angular/core';
import { DynamicFilterComponent, MultiSelectFilterConfigItem } from '../../interfaces/dynamic-filter.interface';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'cap-multi-select-filter',
  templateUrl: './multi-select-filter.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class CapturumMultiSelectFilterComponent implements DynamicFilterComponent<string[]> {
  @Input()
  public filterConfig: MultiSelectFilterConfigItem;
  public updateValue: EventEmitter<string[]> = new EventEmitter<string[]>();
  public formControl = new FormControl();

  constructor(private cdr: ChangeDetectorRef) {}

  public setValue(value: string[]): void {
    this.formControl.setValue(value);
    this.cdr.detectChanges();
  }

  public reset(): void {
    this.formControl.reset();
    this.updateValue.emit(this.formControl.value);
  }

  public updateView(): void {
    this.cdr.detectChanges();
  }
}
