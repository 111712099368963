import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subject, concatMap, from, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TableRouteConfigService {
  private routeParams$ = new Subject<Params>();
  private destroy$ = new Subject<boolean>();
  
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.routeParams$.asObservable().pipe(
      concatMap((params) => from(this.router.navigate([], {
        relativeTo: this.activatedRoute, 
        queryParams: params,
        queryParamsHandling: 'merge',
      }))),
      takeUntil(this.destroy$)
    ).subscribe();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public saveConfigInParams(params: Params): void {
    this.routeParams$.next(params);
  }
}