import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { InfoTableColumnType } from '@capturum/ui/info-table';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ListSettingsData, SettingsChangeEvent } from '../../interfaces/list-renderer-settings.interface';
import { ListRendererConfig } from '../../models/list-renderer-config.model';
import { LocalStorageService, ToastService } from '@capturum/ui/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'cpb-list-renderer-settings-sidebar',
  templateUrl: './list-renderer-settings-sidebar.component.html',
  styleUrls: ['./list-renderer-settings-sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ListRendererSettingsSidebarComponent implements OnInit {
  @Input({ required: true })
  public config: ListRendererConfig;

  @Input({ required: true })
  public stateKey: string;

  @Output()
  public onSettingsChange = new EventEmitter<SettingsChangeEvent>();

  @Output()
  public onSidebarClose = new EventEmitter<void>();

  public globalSearch: boolean;
  public columnsData: ListSettingsData[];
  public filtersData: ListSettingsData[];

  constructor(
    private readonly localStorage: LocalStorageService,
    private readonly toastService: ToastService,
    private readonly translateService: TranslateService
  ) { }

  public ngOnInit(): void {
    const localStorageSettings = this.localStorage.getItem(this.stateKey);

    this.columnsData = localStorageSettings?.columns || this.config?.columns?.filter((column) => {
      return column.type !== InfoTableColumnType.Actions && column.type !== InfoTableColumnType.EditButtons
    })?.map((column) => {
      return {
        field: column.column,
        label: `${column.title || ''}`,
        reorderable: false,
        hidden: false,
      }
    });

    if (this.config?.filters?.global_search?.enabled) {
      this.globalSearch = true;
    }

    this.filtersData = localStorageSettings?.filters || this.config?.filters?.fields?.map((filter) => {
      return {
        field: filter.name,
        label: filter.label || '',
        reorderable: true,
        hidden: false,
      }
    });
  }

  public onListReorder(event: CdkDragDrop<ListSettingsData[]>, list: ListSettingsData[]): void {
    moveItemInArray(list, event.previousIndex, event.currentIndex);
  }

  public applySettingsChanges(): void {
    const visibleColumns = this.columnsData.filter((column) => !column.hidden);
    
    if (visibleColumns.length === 0) {
      this.toastService.warning(this.translateService.instant('toast.warning.title'), this.translateService.instant('table-settings.columns.choose-one-column.warning'));
      
      return;
    }

    this.onSettingsChange.emit({
      filters: this.filtersData,
      columns: this.columnsData,
    });
  }

  public cancel(): void {
    this.onSidebarClose.emit();
  }
}
