<p-splitButton [label]="label" #splitButton
               [icon]="icon"
               [iconPos]="iconPos"
               [style]="style"
               [styleClass]="'cap-splitbutton ' + styleClass"
               [menuStyle]="menuStyle"
               [menuStyleClass]="'cap-splitbutton-tieredmenu ' + menuStyleClass"
               [appendTo]="appendTo"
               [disabled]="disabled"
               [tabIndex]="tabIndex"
               (onClick)="onClick.emit($event)"
               (onDropdownClick)="onDropdownClick.emit($event)"
               [model]="items">
</p-splitButton>
