<div *ngIf="isCard; else cellData" class="cap-info-table__mobile-column">
    <span class="cap-info-table__mobile-header {{ column?.titleClass }}">
        {{ column?.title | observablePipe | async }}
    </span>

    <span class="cap-info-table__mobile-cell">
         <ng-container *ngTemplateOutlet="cellData"></ng-container>
    </span>
</div>

<ng-template #cellData>
    <ng-content></ng-content>
</ng-template>
