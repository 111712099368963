import { Component, Input } from '@angular/core';

@Component({
  selector: 'cpb-form-tooltip',
  templateUrl: './form-tooltip.component.html',
})
export class FormTooltipComponent {
  @Input() public text: string;
  @Input() public position: 'bottom-right' | 'bottom-left' | 'top-right' | 'top-left' = 'bottom-right';
}
