import { ListRendererHeaderActionsComponent } from './components/list-renderer-header-actions/list-renderer-header-actions.component';
import { Inject, ModuleWithProviders, NgModule, Optional } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CapturumDialogService, CapturumSharedModule, InterpolationPipe } from '@capturum/ui/api';
import { ListRendererActionsService } from './services/list-renderer-actions.service';
import { ListRendererColumnsService } from './services/list-renderer-columns.service';
import { ListRendererFiltersComponent } from './components/list-renderer-filters/list-renderer-filters.component';
import { ListRendererResetFilterComponent } from './components/list-renderer-reset-filter/list-renderer-reset-filter.component';
import { ListRendererPaginatorComponent } from './components/list-renderer-paginator/list-renderer-paginator.component';
import { CapturumInfoTableModule } from '@capturum/ui/info-table';
import { CapturumSkeletonModule } from '@capturum/ui/skeleton';
import { CapturumDropdownModule } from '@capturum/ui/dropdown';
import { ReactiveFormsModule } from '@angular/forms';
import { PaginatorModule } from 'primeng/paginator';
import { CapturumButtonModule } from '@capturum/ui/button';
import { CapturumCheckboxModule } from '@capturum/ui/checkbox';
import { CapturumCalendarModule } from '@capturum/ui/calendar';
import { CapturumCtaButtonModule } from '@capturum/ui/cta-button';
import { CapturumMultiSelectModule } from '@capturum/ui/multi-select';
import { HttpClientModule } from '@angular/common/http';
import { CapturumPaginatorModule } from '@capturum/ui/paginator';
import { ComponentHostDirective } from './directives/component-host.directive';
import { ListRendererDynamicWidgetComponent } from './components/list-renderer-dynamic-widget/list-renderer-dynamic-widget.component';
import { ListRendererModuleConfig } from './interfaces/list-renderer-module-config';
import { ListRendererFiltersService } from './services/list-renderer-filters.service';
import { ListRendererWidgetsService } from './services/list-renderer-widgets.service';
import { TranslateModule } from '@ngx-translate/core';
import { ConcatenatePipe } from './pipes/concatenate.pipe';
import { CapturumListRendererComponent } from './containers/list-renderer/list-renderer.component';
import {
  ListRendererWidgetBaseDataComponent,
  ListRendererWidgetBooleanComponent,
  ListRendererWidgetConcatenationComponent,
  ListRendererWidgetImageComponent,
  ListRendererWidgetLinkComponent,
} from './widgets';
import { ExtractOptionsPipe } from './pipes/extract-options.pipe';
import { CapturumAutocompleteModule } from '@capturum/ui/autocomplete';
import { CapturumBuilderCoreModule } from '@capturum/builders/core';
import { ListRendererBulkActionsComponent } from './components/list-renderer-bulk-actions/list-renderer-bulk-actions.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { DisplayResetFilterPipe } from './pipes/display-reset-filter.pipe';
import { DateFilterSelectionModePipe } from './pipes/date-filter-selection-mode.pipe';
import { ChipsModule } from 'primeng/chips';
import { TableActionToCtaButtonItemPipe } from './pipes/table-action-to-cta-button-item.pipe';
import { ListRendererHeaderActionComponent } from './components/list-renderer-header-action/list-renderer-header-action.component';
import { NgxsModule } from '@ngxs/store';
import { ListRendererState } from './state/list-renderer.state';
import {
  CapturumDynamicFiltersModule,
  DYNAMIC_FILTER_COMPONENTS,
  dynamicFilterComponents,
} from '@capturum/ui/dynamic-filters';
import { LIST_RENDERER_MODULE_CONFIG } from './providers/list-renderer-module-config.provider';
import { FilterByHiddenExpressionPipe } from './pipes/filter-by-hidden-expression.pipe';
import { ListRendererConfigService } from './services/list-renderer-config.service';
import { CapturumSidebarModule } from '@capturum/ui/sidebar';
import { ListRendererSettingsSidebarComponent } from './components/list-renderer-settings-sidebar/list-renderer-settings-sidebar.component';
import { TabViewModule } from 'primeng/tabview';
import { DragDropModule } from '@angular/cdk/drag-drop';

export const translateModule = TranslateModule;

// @dynamic
@NgModule({
  declarations: [
    ListRendererFiltersComponent,
    ListRendererResetFilterComponent,
    ListRendererPaginatorComponent,
    CapturumListRendererComponent,
    ListRendererWidgetImageComponent,
    ListRendererWidgetLinkComponent,
    ListRendererWidgetBooleanComponent,
    ComponentHostDirective,
    ListRendererDynamicWidgetComponent,
    ListRendererWidgetConcatenationComponent,
    ConcatenatePipe,
    ListRendererWidgetBaseDataComponent,
    ExtractOptionsPipe,
    ListRendererBulkActionsComponent,
    DisplayResetFilterPipe,
    ListRendererHeaderActionsComponent,
    DateFilterSelectionModePipe,
    TableActionToCtaButtonItemPipe,
    ListRendererHeaderActionComponent,
    FilterByHiddenExpressionPipe,
    ListRendererSettingsSidebarComponent,
  ],
  exports: [CapturumListRendererComponent],
  imports: [
    CommonModule,
    CapturumInfoTableModule,
    NgxsModule.forFeature([ListRendererState]),
    CapturumDynamicFiltersModule,
    CapturumSharedModule,
    CapturumSkeletonModule,
    CapturumDropdownModule,
    ReactiveFormsModule,
    PaginatorModule,
    CapturumButtonModule,
    CapturumCheckboxModule,
    CapturumCalendarModule,
    CapturumMultiSelectModule,
    HttpClientModule,
    CapturumPaginatorModule,
    translateModule,
    CapturumBuilderCoreModule,
    CapturumAutocompleteModule,
    OverlayPanelModule,
    CapturumCtaButtonModule,
    ChipsModule,
    CapturumSharedModule,
    CapturumSidebarModule,
    TabViewModule,
    DragDropModule,
  ],
  providers: [
    ListRendererActionsService,
    ListRendererColumnsService,
    ListRendererFiltersService,
    ListRendererWidgetsService,
    InterpolationPipe,
    ConcatenatePipe,
    CapturumDialogService,
  ],
})
export class CapturumListRendererModule {
  constructor(
    private listRendererConfigService: ListRendererConfigService,
    @Optional()
    @Inject(LIST_RENDERER_MODULE_CONFIG)
    private readonly listRendererModuleConfig: ListRendererModuleConfig[] = []
  ) {
    if (!listRendererModuleConfig) {
      return;
    }

    listRendererModuleConfig.forEach((config) => {
      this.listRendererConfigService.addConfig(config);
    });
  }

  public static forRoot(config?: ListRendererModuleConfig): ModuleWithProviders<CapturumListRendererModule> {
    return {
      ngModule: CapturumListRendererModule,
      providers: [
        {
          provide: LIST_RENDERER_MODULE_CONFIG,
          useValue: config,
          multi: true,
        },
        {
          provide: DYNAMIC_FILTER_COMPONENTS,
          useValue: [...(config?.filters || []), ...dynamicFilterComponents],
        },
      ],
    };
  }

  public static forChild(config?: ListRendererModuleConfig): ModuleWithProviders<CapturumListRendererModule> {
    return {
      ngModule: CapturumListRendererModule,
      providers: [
        {
          provide: LIST_RENDERER_MODULE_CONFIG,
          useValue: config,
          multi: true,
        },
      ],
    };
  }
}
