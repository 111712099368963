import { Component, Input, OnInit } from '@angular/core';
import { TableAction } from '@capturum/ui/api';
import { CapturumBuilderActionService } from '@capturum/builders/core';
import { merge, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'cpb-list-renderer-header-action',
  templateUrl: './list-renderer-header-action.component.html',
  styleUrls: ['./list-renderer-header-action.component.scss'],
})
export class ListRendererHeaderActionComponent implements OnInit {
  @Input()
  public action: TableAction;

  public loading$: Observable<boolean>;

  private executeAction = new Subject<boolean>();

  constructor(
    private actionService: CapturumBuilderActionService,
  ) {
  }

  public ngOnInit(): void {
    this.loading$ = merge(
      this.executeAction,
      this.actionService.getActionExecutionByActionKey(this.action.key)
        .pipe(
          map(() => false),
        ),
    );
  }

  public handleAction(action: TableAction): void {
    this.executeAction.next(true);
    action.callback();
  }
}
