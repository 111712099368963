import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CompleteBaseForm } from '../../../../shared/components/complete-base-form';
import { FormField } from '@capturum/formly';
import { TranslateService } from '@ngx-translate/core';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'emc-setting-form',
  templateUrl: './setting-form.component.html',
  styleUrls: ['./setting-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SettingFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SettingFormComponent),
      multi: true,
    },
  ],
})
export class SettingFormComponent extends CompleteBaseForm implements AfterViewInit {
  @Input()
  public settingType: string;

  @Input()
  public fields: FormlyFieldConfig[];

  constructor(private changeDetectorRef: ChangeDetectorRef, private translateService: TranslateService) {
    super(changeDetectorRef);
  }

  public ngAfterViewInit(): void {
    this.listenToFormChanges();
    this.createForm();
    this.changeDetectorRef.detectChanges();
  }

  public createForm(): void {
    if (!this.fields) {
      const field = new FormField({
        key: 'value',
        type: 'input',
        props: {
          label: this.translateService.instant('setting.overview.value'),
        },
      });

      // Change the input type based on the type of setting
      switch (this.settingType) {
        case 'boolean':
          field.type = 'inputSwitch';
          break;
        case 'number':
          field.props.type = 'number';
          break;
        case 'hex_color':
          field.type = 'colorPicker';
          field.props.selectedColorLabel = this.translateService.instant('color.selected.label');
          break;
        case 'image':
          field.type = 'image';
          field.props.placeholder = this.translateService.instant('button.upload-image');
          break;
        case 'array':
          field.type = 'chips';
          break;
        case 'date':
          field.type = 'calendar';
          field.props.dataType = 'string';
          break;
        case 'dateTime':
          field.type = 'calendar';
          field.props.dataType = 'string';
          field.props.showTime = true;
          break;
      }

      this.fields = [field];
    }

    this.changeDetectorRef.detectChanges();
    this.updateFormValue();
  }
}
