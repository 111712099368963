import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Input,
  OnChanges,
  QueryList,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { Diff, HistoryItem, ModelLog } from '../..';
import { KeyValue, KeyValuePipe } from '@angular/common';
import { CapturumTemplateDirective } from '@capturum/ui/api';

@Component({
  selector: 'emc-model-log-timeline',
  templateUrl: './model-log-timeline.component.html',
  styleUrls: ['./model-log-timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [KeyValuePipe],
})
export class ModelLogTimelineComponent implements AfterViewInit, OnChanges {
  /**
   * The model log items
   */
  @Input()
  public items: any;
  /**
   * The translation key of the title to be displayed
   */
  @Input()
  public title: string = 'model-log.title';
  /**
   * The header template to be used instead of the default header
   */
  @Input()
  public headerTemplate: TemplateRef<any>;
  /**
   * The body template to be used instead of the default body
   */
  @Input()
  public bodyTemplate: TemplateRef<any>;

  /**
   * Theempty results template to be used instead of the default empty results template
   */
  @Input()
  public emptyResultsTemplate: TemplateRef<any>;
  /**
   * The date format to be used in the body of the timeline item
   */
  @Input()
  public dateFormat = 'dd-MM-yyyy HH:mm';
  /**
   * The date format to be used in the header of the timeline item
   */
  @Input()
  public headerDateFormat = 'dd - MM - yyyy @ HH:mm';
  /**
   * Prefix of the translation keys
   */
  @Input()
  public translationKeyPrefix: string;

  /**
   * The translation key to use for the empty results message when there are no results
   */
  @Input()
  public emptyResultsTranslationKey: string = 'table.no_results';

  @ContentChildren(CapturumTemplateDirective)
  public templates: QueryList<CapturumTemplateDirective>;

  public historyItems: Record<string, HistoryItem>;
  public historyItemsArray: KeyValue<string, HistoryItem>[];

  constructor(
    private cdr: ChangeDetectorRef,
    private keyValuePipe: KeyValuePipe,
  ) {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes?.items?.previousValue !== changes?.items?.currentValue) {
      this.historyItems = this.transformItems(changes.items.currentValue);
      this.historyItemsArray = this.keyValuePipe.transform(this.historyItems);
    }
  }

  public ngAfterViewInit(): void {
    this.templates.forEach((template) => {
      switch (template.getType()) {
        case 'header':
          this.headerTemplate = template.template;
          break;
        case 'body':
          this.bodyTemplate = template.template;
          break;
        case 'empty-results':
          this.emptyResultsTemplate = template.template;
          break;
        default:
          break;
      }

      this.cdr.detectChanges();
    });
  }

  public originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  }

  public trackByIndex(index: number, item: Diff): number {
    return index;
  }

  protected transformItems(items: { [key: string]: ModelLog[] }): Record<string, HistoryItem> {
    const historyItems = {};

    if (items) {
      for (const dateTime of Object.keys(items)) {
        let hasDiff = false;

        const modelLogs = items[dateTime].map(item => {
          item.entity_key = this.getEntityKey(item.model_loggable_type);

          if (!item.action) {
            const newModel = JSON.parse(item.new_model);
            item.action = item.original_model ? 'updated' : 'created';
          }

          if (Object.keys(item.diffs).length) {
            hasDiff = true;
          }

          return item;
        });

        historyItems[dateTime] = {
          showDetails: false,
          hasDiff: hasDiff,
          modelLogs: modelLogs,
        } as HistoryItem;
      }
    }

    return historyItems;
  }

  protected getEntityKey(modelType: string): string {
    const stringParts = modelType.split('\\');
    return stringParts[stringParts.length - 1];
  }
}
