import Quill, { DeltaOperation } from "quill"

export interface QuillContentChangeEvent {
    editor: Quill,
    html: string,
    text: string,
    content: DeltaOperation,
    delta: DeltaOperation,
    oldDelta: DeltaOperation,
    source: 'user' | 'api' | 'silent' | undefined,
}

export interface QuillFocusEvent {
    editor: Quill,
    source: 'user' | 'api' | 'silent' | undefined,
}
