import { Observable } from 'rxjs';

export interface TableAction {
  label: string | Observable<string>;
  value?: any;
  description?: string | Observable<string>;
  key?: string;
  icon?: string;
  url?: string;
  hidden?: boolean | Observable<boolean>;
  callback?: (item?: any) => void;
  permissions?: string | string[];
}

export interface TableActionEvent {
  action: TableAction;
  item: any;
}
