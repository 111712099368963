import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { VaporFileUploadService } from '@capturum/api';
import { forkJoin, Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { HttpEventType } from '@angular/common/http';
import { FilePreviewListItem } from '@capturum/ui/file-preview-list';
import { FormRendererState } from '../../state/form-renderer/form-renderer.state';
import { Store } from '@ngxs/store';

interface FileWithId extends File {
  id: string;
  file: File;
}

@Component({
  selector: 'cpb-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
})
export class CapturumBuilderFileInputComponent extends FieldType implements OnInit, OnDestroy {
  public previewFiles: FilePreviewListItem[] = [];
  private destroy$ = new Subject<boolean>();

  constructor(
    private readonly vaporFileUploadService: VaporFileUploadService,
    private store: Store,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super();
  }

  public static getRandomId(length: number = 5): string {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  }

  public ngOnInit(): void {
    if (this.props.previewData) {
      this.previewFiles = Array.isArray(this.props.previewData) ? this.props.previewData : [this.props.previewData];
    }

    this.store.select(FormRendererState.sourceValueByKey(this.props.formKey))
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        let files = value?.[this.key as string];

        if (files) {
          files = Array.isArray(files) ? files : [files];

          this.previewFiles = files.map((file) => {
            const currentFile = this.previewFiles.find((previewFile) => file.data === previewFile.id || file.id === previewFile.id);
            
            return {
              ...currentFile,
              url: file?.public_url || currentFile?.url,
              name: file?.filename,
              id: file?.id || file?.data || currentFile?.id,
            }
          });
        }
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public handleFileChange(files: File[]): void {
    const formattedFiles = [];
    files.forEach((file) => {
      formattedFiles.push({ file, id: CapturumBuilderFileInputComponent.getRandomId(5) });
    });

    for (const file of formattedFiles) {
      this.setPreviewFile(file, this.props.multiple, 1);
    }

    this.setVaporFile(formattedFiles);

    this.cdr.detectChanges();
  }

  public removeFile(fileItem: FilePreviewListItem): void {
    if (this.props.multiple) {
      this.formControl.setValue(
        this.formControl.value.filter((file) => {
          return file.data !== fileItem.id && file.id !== fileItem.id;
        }),
      );
    } else {
      this.formControl.setValue(null);
    }

    this.previewFiles = this.previewFiles.filter((file) => file.id !== fileItem?.id);
  }

  private setVaporFile(files: FileWithId[]): void {
    forkJoin(files.map(file => {
      return this.vaporFileUploadService.uploadFile(file.file).pipe(
        tap((response) => {
          if (response.data['progress']) {
            this.updatePreviewFiles(file.id, { uploadProgress: response.data['progress'] });
          }
        }),
        filter((response) => response.type === HttpEventType.Response),
        map((response) => {
          return {
            uuid: response.data['uuid'],
            file,
          };
        }),
      );
    })).subscribe((response) => {
      for (const fileResponse of response) {
        this.updatePreviewFiles(fileResponse.file.id, {
          id: fileResponse.uuid,
          uploadProgress: null,
          uploading: false,
        });
      }

      this.setFormControlForVaporFileUpload(response, this.props.multiple);

      this.cdr.detectChanges();
    });
  }

  private setPreviewFile(file: FileWithId, multiple: boolean = false, uploadProgress?: number): void {
    const fileReader = new FileReader();

    fileReader.onloadend = (result) => {
      if (multiple) {
        this.previewFiles = [
          ...this.previewFiles,
          {
            url: result.target.result as string,
            name: file.file.name,
            uploading: !!uploadProgress,
            uploadProgress,
            id: file.id,
          },
        ];
      } else {
        this.previewFiles = [
          {
            url: result.target.result as string,
            name: file.file.name,
            uploading: !!uploadProgress,
            uploadProgress,
            id: file.id,
          },
        ];
      }
    };

    fileReader.readAsDataURL(file.file);
  }

  private setFormControl(files: File[], multiple: boolean): void {
    if (multiple) {
      this.formControl.setValue([...(this.formControl.value || []), ...files]);
    } else {
      this.formControl.setValue(files);
    }
  }

  private setFormControlForVaporFileUpload(files: { uuid: string, file: FileWithId }[], multiple: boolean): void {
    if (files) {
      if (multiple) {
        this.formControl.setValue([
          ...(this.formControl.value || []),
          ...files.map((file) => {
            return {
              data: file.uuid,
              mime_type: file.file.file.type,
              filename: file.file.file.name,
            };
          })]);
      } else {
        if (files[0].file.file) {
          this.formControl.setValue({
            data: files[0].uuid,
            mime_type: files[0].file.file.type,
            filename: files[0].file.file.name,
          });
        }
      }
    }
  }

  private updatePreviewFiles(id: string, updatedProperties: Partial<FilePreviewListItem>): void {
    this.previewFiles = this.previewFiles.map((file) => {
      if (file.id === id) {
        file = { ...file, ...updatedProperties };
      }

      return file;
    });

    this.cdr.detectChanges();
  }
}
