import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ApiHttpService } from './api.http-service';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import {
  VaporFileUploadResponse,
  VaporStorageDataResponse,
  VaporUploadOptions,
} from '../interfaces/vapor-file-upload.interface';

/**
 * VaporFileUploadService
 *
 * @description
 *
 * This service provides the functionality to upload a file to an S3 bucket as specified in the Laravel Vapor documentation.
 * The uploadFile function will first retrieve a storage location url from the backend.
 * This will return an url, and the file will be uploaded to the returned url.
 *
 * @example
 * ```
 *  this.vaporUploadService.uploadFile(file).subscribe((event) => {
 *    if (event.type === HttpEventType.UploadProgress) {
 *      // Do something with the progress inside event.data.progress
 *    } else if (event.type === HttpEventType.Response) {
 *      // DO something with the result in event.data
 *    }
 *  });
 *  ```
 */
@Injectable({
  providedIn: 'root',
})
export class VaporFileUploadService {
  /**
   * The url used to retrieve the storage location url
   */
  public vaporSignedStorageUrl = '/vapor/signed-storage-url';

  constructor(
    protected apiHttpService: ApiHttpService,
    protected http: HttpClient,
  ) {
  }

  /**
   * Upload the file to an AWS S3 bucket and return the uuid of the storage location
   *
   * @param file - the file to upload
   * @param options - extra options to retrieve the storage location
   */
  public uploadFile(file: File, options?: VaporUploadOptions): Observable<VaporFileUploadResponse> {
    return this.getStorageData(file, options).pipe(
      switchMap((storageDataResponse) => {
        return this.uploadToVapor(storageDataResponse.url, file, new HttpHeaders(storageDataResponse.headers), storageDataResponse.uuid);
      }),
    );
  }

  /**
   * Get the storage url of where to store the file
   *
   * @param file - the file to upload
   * @param options - extra options to retrieve the storage location
   */
  protected getStorageData(file: File, options: VaporUploadOptions): Observable<VaporStorageDataResponse> {
    return this.apiHttpService.post(this.vaporSignedStorageUrl, {
      bucket: options?.bucket || '',
      content_type: options?.contentType || file.type,
      expires: options?.expires || '',
      visibility: options?.visibility || '',
    });
  }

  /**
   * Upload the file to the given url and report the progress
   *
   * @param url - the url to which the file should be uploaded
   * @param file - the file to be uploaded
   * @param headers - http headers to be used with the request
   * @param uuid - the uuid of the storage location
   */
  protected uploadToVapor(url: string, file: File, headers: HttpHeaders, uuid: string): Observable<VaporFileUploadResponse> {
    if (headers.get('Host')) {
      headers = headers.delete('Host');
    }

    return this.http.put(url, file, { headers, reportProgress: true, observe: 'events' }).pipe(
      map((event) => {
        if (event.type === HttpEventType.UploadProgress) {
          return {
            type: HttpEventType.UploadProgress,
            data: { progress: Math.round(100 * event.loaded / event.total) },
          }
        } else if (event.type === HttpEventType.Response) {
          return { type: HttpEventType.Response, data: { uuid } };
        }

        return { type: event.type, data: event as HttpEvent<Object> }
      }),
    );
  }
}
