import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { TranslationService } from '../services/translation.service';

@Injectable({ providedIn: 'root' })
export class FilteredTranslationResolver  {
  constructor(private translationService: TranslationService) {
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Observable<any>> | Promise<Observable<any>> | Observable<any> {
    return this.translationService.loadFilteredTranslations(route?.data?.groups, route?.data?.locale);
  }
}
