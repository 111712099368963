/* tslint:disable:variable-name */
import { IndexedDbModel } from '../../../shared/indexedDb/indexedDb.model';
import { BaseDataValueAttribute } from './base-data-value.api-model';

export class BaseDataKeyIndexedDbModel extends IndexedDbModel {
  // Configuration
  public entity = 'base-data-key';
  public table = 'base_data_keys';
  public schema = '++id, module_id, key';
  public sync = true;

  // Fields
  public module_id?: string;
  public key: string;
  public editable_by_tenant: boolean;
  public values: BaseDataKeyIndexedDbModel[];
  public attr?: BaseDataValueAttribute[];
  public properties?: BaseDataValueAttribute[];

  // Constructor
  constructor(attributes: Partial<BaseDataKeyIndexedDbModel>) {
    super(attributes);
  }

  public async init(): Promise<void> {
    await BaseDataKeyIndexedDbModel.query().where({ base_data_key_id: this.id }).toArray().then(result => {
      this.values = result;
    });
  }
}
