import { CapturumPaginatorModule } from '@capturum/ui/paginator';
import { CapturumButtonModule } from '@capturum/ui/button';
import { CapturumListRendererModule } from '@capturum/builders/list-renderer';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CapturumBuilderCoreModule } from '@capturum/builders/core';
import { CapturumFlowListComponent } from './pages/flow-list/flow-list.component';
import { CapturumFlowRoutingModule } from './flow-routing.module';
import { CapturumFlowStatusWidgetComponent } from './components/flow-status-widget/flow-status-widget.component';
import { CapturumFlowOverviewComponent } from './pages/flow-overview/flow-overview.component';
import { TabViewModule } from 'primeng/tabview';
import { CapturumFlowActionWidgetComponent } from './components/flow-action-widget/flow-action-widget.component';
import {
  CapturumNumberPercentageWidgetComponent,
} from './components/number-percentage-widget/number-percentage-widget.component';
import { CapturumFlowHistoryComponent } from './components/history/history.component';
import { CapturumNumberWithoutDecimalsWidgetComponent } from './components/number-without-decimals-widget/number-without-decimals-widget.component';
import { CapturumFlowErrorDetailsComponent } from './components/flow-error-details/flow-error-details.component';
import { CapturumFlowStatisticDetailsComponent } from './components/flow-statistic-details/flow-statistic-details.component';

const components = [
  CapturumFlowHistoryComponent,
  CapturumNumberPercentageWidgetComponent,
  CapturumNumberWithoutDecimalsWidgetComponent,
  CapturumFlowErrorDetailsComponent,
  CapturumFlowStatusWidgetComponent,
  CapturumFlowActionWidgetComponent,
  CapturumFlowOverviewComponent,
  CapturumFlowListComponent,
];

@NgModule({
  imports: [
    CommonModule,
    CapturumFlowRoutingModule,
    CapturumBuilderCoreModule.forChild({
      components: {
        list_action_statistics_history: CapturumFlowHistoryComponent,
        list_flow_statistics_history: CapturumFlowHistoryComponent,
        list_action_logs_context: CapturumFlowErrorDetailsComponent,
        list_flow_logs_context: CapturumFlowErrorDetailsComponent,
        list_action_logs_statistics: CapturumFlowStatisticDetailsComponent,
        list_flow_logs_statistics: CapturumFlowStatisticDetailsComponent,
      },
    }),
    CapturumListRendererModule.forChild({
      widgets: {
        'flow-status': CapturumFlowStatusWidgetComponent,
        'flow-action': CapturumFlowActionWidgetComponent,
        numberWithoutDecimals: CapturumNumberWithoutDecimalsWidgetComponent,
        numberPercentage: CapturumNumberPercentageWidgetComponent,
      },
      list_action_statistics: {
        actions: {
          context: {
            hiddenExpression: (row: any) => {
              return row.success === true;
            },
          },
        },
      },
      list_flow_statistics: {
        actions: {
          context: {
            hiddenExpression: (row: any) => {
              return row.success === true;
            },
          },
        },
      },
    }),
    ...components,
  ],
  exports: [...components],
})
export class CapturumFlowModule {}
