import { NgModule } from '@angular/core';
import { FormComponent } from './form.component';
import { FormlyModule } from '@ngx-formly/core';

import { DropdownTypeComponent } from './types/dropdown/dropdown.component';
import { SelectButtonTypeComponent } from './types/select-button/select-button.component';
import { MultiSelectTypeComponent } from './types/multi-select/multi-select.component';
import { InputTypeComponent } from './types/input/input.component';
import { CheckboxTypeComponent } from './types/checkbox/checkbox.component';
import { CalendarTypeComponent } from './types/calendar/calendar.component';
import { ChipsTypeComponent } from './types/chips/chips.component';
import { FormFieldWrapper } from './wrappers/form-field/form-field.wrapper';
import { InputSwitchTypeComponent } from './types/input-switch/input-switch.component';
import { ColorPickerTypeComponent } from './types/color-picker/color-picker.component';
import { TextareaTypeComponent } from './types/textarea/textarea.component';
import { ImageTypeComponent } from './types/image/image.component';
import { FormlySelectOptionsPipe } from './pipes/select-options-pipe.pipe';
import { ObservablePipe } from './pipes/observable.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutocompleteDropdownComponent } from './types/autocomplete-dropdown/autocomplete-dropdown.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CapturumSharedModule } from '@capturum/ui/api';
import { CapturumAutocompleteModule } from '@capturum/ui/autocomplete';
import { CapturumInputModule } from '@capturum/ui/input';
import { CapturumDropdownModule } from '@capturum/ui/dropdown';
import { CapturumMultiSelectModule } from '@capturum/ui/multi-select';
import { CapturumTextareaModule } from '@capturum/ui/textarea';
import { CapturumColorPickerModule } from '@capturum/ui/color-picker';
import { CapturumInputSwitchModule } from '@capturum/ui/input-switch';
import { CapturumChipsModule } from '@capturum/ui/chips';
import { CapturumCalendarModule } from '@capturum/ui/calendar';
import { CapturumCheckboxModule } from '@capturum/ui/checkbox';
import { CapturumSelectButtonModule } from '@capturum/ui/select-button';
import { CapturumButtonModule } from '@capturum/ui/button';
import { CommonModule } from '@angular/common';
import { noSpecialChars } from './validators/no-special-characters.validator';
import { noScript } from './validators/no-script.validator';

@NgModule({
  declarations: [
    FormComponent,
    MultiSelectTypeComponent,
    DropdownTypeComponent,
    SelectButtonTypeComponent,
    InputTypeComponent,
    CheckboxTypeComponent,
    CalendarTypeComponent,
    ChipsTypeComponent,
    InputSwitchTypeComponent,
    FormFieldWrapper,
    ColorPickerTypeComponent,
    TextareaTypeComponent,
    ImageTypeComponent,
    FormlySelectOptionsPipe,
    ObservablePipe,
    AutocompleteDropdownComponent,
  ],
  imports: [
    CommonModule,
    CapturumButtonModule,
    CapturumAutocompleteModule,
    CapturumInputModule,
    CapturumDropdownModule,
    CapturumMultiSelectModule,
    CapturumTextareaModule,
    CapturumColorPickerModule,
    CapturumInputSwitchModule,
    CapturumChipsModule,
    CapturumCalendarModule,
    CapturumCheckboxModule,
    CapturumSelectButtonModule,
    FormsModule,
    CapturumSharedModule,
    ReactiveFormsModule,
    AutoCompleteModule,
    FormlyModule.forChild({
      types: [
        {
          name: 'multiSelect',
          component: MultiSelectTypeComponent,
          wrappers: ['cap-form-field']
        },
        {
          name: 'dropdown',
          component: DropdownTypeComponent,
          wrappers: ['cap-form-field']
        },
        {
          name: 'selectButton',
          component: SelectButtonTypeComponent,
          wrappers: ['cap-form-field']
        },
        {
          name: 'input',
          component: InputTypeComponent,
          wrappers: ['cap-form-field']
        },
        {
          name: 'checkbox',
          component: CheckboxTypeComponent,
          wrappers: ['cap-form-field']
        },
        {
          name: 'calendar',
          component: CalendarTypeComponent,
          wrappers: ['cap-form-field']
        },
        {
          name: 'chips',
          component: ChipsTypeComponent,
          wrappers: ['cap-form-field']
        },
        {
          name: 'inputSwitch',
          component: InputSwitchTypeComponent,
          wrappers: ['cap-form-field']
        },
        {
          name: 'colorPicker',
          component: ColorPickerTypeComponent,
          wrappers: ['cap-form-field']
        },
        {
          name: 'textarea',
          component: TextareaTypeComponent,
          wrappers: ['cap-form-field']
        },
        {
          name: 'image',
          component: ImageTypeComponent,
          wrappers: ['cap-form-field']
        },
        {
          name: 'autocomplete-dropdown',
          component: AutocompleteDropdownComponent,
          wrappers: ['cap-form-field']
        },
      ],
      wrappers: [
        {
          name: 'cap-form-field',
          component: FormFieldWrapper
        }
      ],
      validators: [
        { name: 'noSpecialChars', validation: noSpecialChars },
        { name: 'noScript', validation: noScript },
      ],

      validationMessages: [{ name: 'required', message: 'This field is required' }]
    }),
  ],
  exports: [FormComponent, CapturumButtonModule]
})
export class FormModule {
}
