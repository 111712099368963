import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ComponentHostDirective } from '../../directives/component-host.directive';
import { ListRendererWidgetsService } from '../../services/list-renderer-widgets.service';
import { InfoTableColumn } from '@capturum/ui/info-table';

@Component({
  selector: 'cpb-list-renderer-dynamic-widget',
  template: `
    <ng-template cpbComponentHost></ng-template>`
})
export class ListRendererDynamicWidgetComponent implements OnChanges {
  @Input() public column: InfoTableColumn;
  @Input() public index: number;
  @Input()
  public widget: string;
  @Input()
  public widgetOptions: Record<string, unknown>;
  @Input()
  public context: Record<string, unknown>;
  @ViewChild(ComponentHostDirective, { static: true }) public componentHost: ComponentHostDirective;
  private widgetLoaded: boolean;

  constructor(private listBuilderWidgetsService: ListRendererWidgetsService) {
  }

  private _item: any;

  get item(): any {
    return this._item;
  }

  @Input() set item(value: any) {
    this._item = value;

    if (value) {
      this.loadWidgetComponent();
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.widget && this.item && !this.widgetLoaded) {
      this.loadWidgetComponent();
      this.widgetLoaded = true;
    } else if (!!changes?.context?.currentValue && this.widgetLoaded) {
      this.loadWidgetComponent();
    }
  };

  private loadWidgetComponent(): void {
    if (this.listBuilderWidgetsService.widgets[this.widget]) {
      this.listBuilderWidgetsService.loadWidget(this.componentHost.viewContainerRef, this.listBuilderWidgetsService.widgets[this.widget], {
        item: this.item,
        field: this.column.field,
        context: this.context,
        index: this.index,
        column: this.column,
        ...this.widgetOptions
      });
    }
  }
}
