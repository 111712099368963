import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '@capturum/api';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ToastService } from '../../../toast.service';

/**
 * This is a base detail component.
 * The purpose of this component is to create a base class for generic detail pages.
 */
@Component({
  template: ''
})
export class BaseDetailComponent<T> implements OnInit, OnDestroy {

  /**
   * The formGroup of the detail form
   */
  public formGroup: FormGroup;

  /**
   * The model that will be bound to the form
   */
  public model: T;

  /**
   * The formly fields of the formly form
   */
  public fields: FormlyFieldConfig[];

  /**
   * Define if the resource will be edited or added
   */
  public isEdit: boolean;

  /**
   * The service to which the resource will be stored/updated
   */
  public apiService: ApiService<T>;

  /**
   * The method of the api service used to retrieve the model
   */
  public getMethod = 'get';

  /**
   * The relational fields that will be included when fetching a resource
   */
  public includes: string[];

  /**
   * Remove data wrapper for includes
   */
  public removeDataWrappers: boolean;

  /**
   * The message that will be displayed when the resource could not be found
   */
  public notFoundMessage: string;

  /**
   * The message that will be displayed when the resource is stored
   */
  public saveMessage: string;

  /**
   * The message that will be displayed in a toast when an error occurs when storing/fetching the resource
   */
  public errorMessage: string;

  /**
   * The translation of the resource name
   */
  public modelTranslation: string;

  /**
   * The url to redirect to when canceled
   */
  public cancelRedirectRoute: string;

  /**
   * The router instance
   */
  protected baseRouter: Router;

  /**
   * The activatedRoute instance
   */
  public route: ActivatedRoute;

  /**
   * The toast service instance
   */
  protected toastService: ToastService;

  /**
   * The id field of the resource
   */
  protected idField = 'id';

  /**
   * The callback function that get's called after submitting the form
   */
  protected saveResourceCallback: (response: T) => void;

  /**
   * Indicates whether submitting is in progress
   */
  public submitting: boolean;

  public errorHeaderMessage: string;

  /**
   * Subject that will determine when the observables need to be unsubscribed from
   */
  private destroy$: Subject<boolean>;

  constructor(
    public injector: Injector,
    public translateService: TranslateService
  ) {
    this.baseRouter = injector.get(Router);
    this.toastService = injector.get(ToastService);

    this.destroy$ = new Subject();
    this.includes = [];
    this.fields = [];
    this.model = {} as T;
    this.removeDataWrappers = true;

    this.modelTranslation = this.translateService.instant('detail.model');
    this.errorMessage = this.translateService.instant('toast.error.message');
    this.errorHeaderMessage = this.translateService.instant('toast.error.title');
    this.notFoundMessage = `${this.modelTranslation} ${this.translateService.instant('detail.not_found')}`;
    this.saveMessage = this.translateService.instant('detail.item_saved');
  }

  public ngOnInit(): void {
    this.buildForm();

    this.route.params.pipe(
      filter((params) => !!params && params.id),
      takeUntil(this.destroy$)
    ).subscribe((params) => {
      this.isEdit = true;
      this.model[this.idField] = params.id;
      this.getResource(params.id);
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  /**
   * Submit form to backend
   */
  public submit(): void {
    if (this.formGroup.valid) {
      this.submitting = true;
      const saveFunction = this.isEdit ?
        this.apiService.update(this.model, { include: this.includes }) :
        this.apiService.create(this.model, { include: this.includes });

      saveFunction.pipe().subscribe((response) => {
        this.toastService.success(this.modelTranslation, this.saveMessage);
        if (this.saveResourceCallback) {
          this.saveResourceCallback(response);
        }

        this.onCancel();
      }, (error) => {
        this.submitting = false;
        this.toastService.error(this.modelTranslation, this.errorMessage);
      });
    }
  }

  /**
   * Redirect to list page
   */
  public onCancel(): void {
    if (this.cancelRedirectRoute) {
      this.baseRouter.navigateByUrl(this.cancelRedirectRoute);
    } else {
      this.baseRouter.navigate(['../'], { relativeTo: this.route });
    }
  }

  /**
   * Function to run after the resource has been retrieved
   *
   * @param response
   */
  protected getResourceCallback(response: any): T {
    return response;
  }

  /**
   * Get the resource and add model data to the form
   *
   * @param id
   */
  protected getResource(id: number | string): void {
    this.apiService[this.getMethod](id, { include: this.includes }).subscribe((response) => {
      if (this.removeDataWrappers) {
        // Remove wrapping 'data' object for includes
        this.includes.forEach((include) => {
          if (response[include] !== undefined && response[include]?.data) {
            const includeData = response[include].data;

            if (Array.isArray(includeData)) {
              response[include] = [...includeData];
            } else {
              response[include] = { ...includeData };
            }
          }
        });
      }

      this.model = this.getResourceCallback(response);

      this.formGroup.patchValue(this.model);
    }, (error) => {
      this.toastService.error(this.errorHeaderMessage, this.errorMessage);

      this.onCancel();
    });
  }

  /**
   * Build initial form
   */
  private buildForm(): void {
    this.formGroup = new FormGroup({});
  }
}
