import { NotificationBadgeOptions } from './notification-badge-options.model';

export interface NavBarItem {
  /**
   * Title of the nav-item
   */
  title: string;
  /**
   * The route it should use when clicking on the item
   */
  routerUrl: string;
  /**
   * Should it be an exact route match
   */
  exact?: boolean;
  /**
   * The icon to be displayed in the nav-item
   */
  icon?: string;
  /**
   * The children of the menu-item (only applicable at sidebar)
   */
  children?: NavBarItem[];
  /**
   * Should the nav-item be shown when there is no active network connection
   */
  offlineAvailable?: boolean;
  /**
   * Configuration of the notification badge
   */
  notificationBadgeOptions?: NotificationBadgeOptions;
  /**
   * Define a unique key to use as identifier for custom templates
   */
  key?: string;
  /**
   * The style class given to the nav-item
   */
  styleClass?: string;
  /**
   * Property to indicate at what sub-route the active state should be applied to the nav-item
   */
  activeSubRoute?: string;
}
