import { BehaviorSubject } from 'rxjs';
import { FormRendererService } from './../../services/form-renderer.service';
import { InputType } from '@capturum/builders/core';
import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';
import { SplitButton } from 'primeng/splitbutton';

interface SplitButtonOptions {
  label: string;
  command: (event: SplitButtonCommand) => void;
}

interface SplitButtonCommand {
  item: any;
  originalEvent: PointerEvent;
}

interface LocaleOption {
  locale_id: string;
  locale_code: string;
  translation?: string;
}

@Component({
  selector: 'cpb-translated-input',
  templateUrl: './translated-input.component.html',
  styleUrls: ['./translated-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CapturumBuilderTranslatedInputComponent extends FieldArrayType implements OnInit, AfterViewInit {
  @ViewChild('localeButton')
  public localeButton: SplitButton;
  public selectedLocale: string;
  public localeElements: NodeList;
  public localeOptions: SplitButtonOptions[];

  constructor(
    private formRendererService: FormRendererService,
    private elementRef: ElementRef,
    private readonly renderer: Renderer2
  ) {
    super();
  }

  public ngOnInit(): void {
    if (this.field.props) {
      const locales = (this.field.props.options$ as BehaviorSubject<LocaleOption[]>).getValue();

      this.localeOptions = locales.map((locale) => {
        return {
          label: locale.locale_code,
          command: (event: SplitButtonCommand) => this.selectLocale(event),
        };
      });
      this.selectedLocale = locales[0].locale_code;

      const fields = locales.map((locale) => {
        const configuration = {
          type: InputType.text,
          name: locale.locale_code,
          label: locale.locale_code,
          validations: [],
        };
        const input = this.formRendererService.getInputByConfigs(configuration, '', [{}]);
        input.className = `locales locale_${locale.locale_code}`;

        return input;
      });

      this.field.fieldArray = { fieldGroup: fields };

      if (!this.field.defaultValue || !this.field?.defaultValue?.length) {
        this.add();
      }
    }
  }

  public ngAfterViewInit(): void {
    this.localeElements = this.elementRef.nativeElement.querySelectorAll('.locales');

    if (this.localeElements.length) {
      this.renderer.setStyle(this.localeElements[0], 'display', 'block');
    }
  }

  public selectLocale(event: SplitButtonCommand): void {
    this.selectedLocale = event.item.label;
    this.setActiveLocaleFormControl();
  }

  public toggleMenu(event: MouseEvent): void {
    if (this.localeButton) {
      this.localeButton.onDropdownButtonClick(event);
    }
  }

  private setActiveLocaleFormControl(): void {
    this.localeElements.forEach((element) => this.renderer.removeStyle(element, 'display'));
    const activeLocale = this.elementRef.nativeElement.querySelector(`.locale_${this.selectedLocale}`);
    this.renderer.setStyle(activeLocale, 'display', 'block');
  }
}
