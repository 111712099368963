import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BuilderMessage,
  CAPTURUM_BUILDERS_BASE_URL,
  clone,
  ConfigApiService,
  FormBuilderConfig,
  PageBuilderConfig,
  CapturumBuilderType,
} from '@capturum/builders/core';
import { FormRendererService, FormSaverService } from '@capturum/builders/form-renderer';
import { ListRendererConfig } from '@capturum/builders/list-renderer';
import { ToastService } from '@capturum/ui/api';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { debounceTime, filter, take, shareReplay, takeUntil } from 'rxjs/operators';

import { BuilderUiApiService } from '../../services/builder-ui-api.service';
import { BuilderUiConfigService } from '../../services/builder-ui-config.service';
import { vi } from 'date-fns/locale';
import { CapturumSidebarComponent } from '@capturum/ui/sidebar';

@Component({
  selector: 'cpb-builder-detail-template',
  templateUrl: './builder-detail-template.component.html',
  styleUrls: ['./builder-detail-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class BuilderDetailTemplateComponent implements OnInit, OnDestroy {
  @ViewChild('sidebar')
  public sidebar: CapturumSidebarComponent;

  @Input()
  public key: string;
  @Input()
  public title: string;
  @Input()
  public contentRightTemplate: TemplateRef<any>;
  @Input()
  public configId: string;
  @Input()
  public translationKey: string;
  @Output()
  public configChange = new EventEmitter<FormBuilderConfig | ListRendererConfig | PageBuilderConfig>();
  @Output()
  public onSubmit = new EventEmitter<void>();
  @Input()
  public type = CapturumBuilderType.form;

  public showConfigSidebar: boolean;
  public formConfiguration$: Observable<FormBuilderConfig | ListRendererConfig>;
  public hideSetDevelopmentConfig: boolean = false;
  public commentForm: FormGroup;
  public messages: BuilderMessage[] = [];
  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private readonly formBuilderService: BuilderUiApiService,
    private readonly formSaverService: FormSaverService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly store: Store,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly formRendererService: FormRendererService,
    private readonly configApiService: ConfigApiService,
    private readonly toastService: ToastService,
    private readonly translateService: TranslateService,
    private readonly builderUiConfigService: BuilderUiConfigService,
    private formBuilder: FormBuilder,
    @Inject(CAPTURUM_BUILDERS_BASE_URL)
    private readonly baseUrl: string,
  ) {
    this.commentForm = this.formBuilder.group({
      comment: null,
    });
  }

  public ngOnInit(): void {
    this.formConfiguration$ = this.formBuilderService.getFormBuilderConfiguration(this.key).pipe(
      shareReplay(1),
    );

    this.formRendererService.getSourceValueByKey<FormBuilderConfig>(this.key).pipe(
      filter(Boolean),
      take(1),
    ).subscribe((value: FormBuilderConfig) => {
      if (value.key) {
        this.title = value.key;
      }

      this.commentForm.patchValue({
        comment: value.comment ?? null,
      });

      if (value.messages) {
        this.messages = value.messages;
      }
      const config: FormBuilderConfig = clone(value);

      this.builderUiConfigService.setConfig(value);
      this.configChange.emit(config);
    });

    this.showConfigSidebar = !this.route.snapshot.paramMap.get('id');
    this.hideSetDevelopmentConfig = !this.route.snapshot.paramMap.get('id');
    this.commentForm.valueChanges.pipe(
      debounceTime(200),
      takeUntil(this.destroy$),
    ).subscribe(value => {
      this.builderUiConfigService.patchConfig(value);
    })
  }

  public ngOnDestroy(): void {
    this.formRendererService.setSourceValue(this.key, null);
    this.builderUiConfigService.resetConfig();
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public saveConfig(config: FormBuilderConfig, value: any): void {
    const stateConfig = this.builderUiConfigService.getConfigSnapshot();
    this.formRendererService.setIsSubmitted(this.key);

    const submitValue = {
      ...stateConfig,
      ...value,
      filters: {
        ...stateConfig?.filters,
        ...value.filters
      },
    };

    this.formSaverService.submitToBackend(config.submit.endpoint, submitValue, this.key).pipe(take(1)).subscribe((response) => {
      if (response?.data) {
        this.showSuccessToast('builders.set-config.toast.message');
        this.builderUiConfigService.setConfig(response.data);
        this.configChange.emit(response.data);
      }

      this.router.navigate([`../${response.data.id}`], { relativeTo: this.route }).then(() => {
        this.showConfigSidebar = false;

        this.closeSidebar();
      });

      this.changeDetectorRef.detectChanges();
    });
  }

  public submit(): void {
    this.onSubmit.emit();
  }

  public cancel(): void {
    this.router.navigate(['../..'], { relativeTo: this.route });
  }

  public setConfig(): void {
    this.configApiService.setConfig(this.configId).pipe(take(1)).subscribe(() => {
      this.showSuccessToast('builders.set-config.toast.message');
    });
  }


  public openPreview(): void {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(
        [`${this.baseUrl}/config-preview/${this.type}/${this.configId}`],
        { relativeTo: this.route }
      )
    );

    window.open(url, '_blank');
  }

  public closeSidebar(): void {
    this.showConfigSidebar = false;
    this.sidebar.closeSidebar();
  }

  private showSuccessToast(key: string): void {
    this.toastService.success(
      this.translateService.instant('toast.success.title'),
      this.translateService.instant(key),
    );
  }
}
