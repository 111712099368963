import { AfterViewInit, Component, EventEmitter, Injector, Input, Output, ViewChild } from '@angular/core';
import { FormControl, NgControl, NgModel } from '@angular/forms';
import { ValueAccessorBase, ValidatorService } from '@capturum/ui/api';

@Component({
  selector: 'cap-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [ValueAccessorBase.getProviderConfig(CapturumCheckboxComponent)]
})
export class CapturumCheckboxComponent extends ValueAccessorBase<boolean | string> implements AfterViewInit {
  @ViewChild(NgModel, { static: true }) public model: NgModel;
  /**
   * Value of the checkbox
   */
  @Input() public checkboxValue: any;
  /**
   * Name of the checkbox group
   */
  @Input() public name: string;
  /**
   * When present, it specifies that the element should be disabled
   */
  @Input() public disabled: boolean = false;
  /**
   * Allows to select a boolean value instead of multiple values
   */
  @Input() public binary: boolean = true;
  /**
   * Label of the checkbox
   */
  @Input() public label: string;
  /**
   * Index of the element in tabbing order
   */
  @Input() public tabindex: number = null;
  /**
   * Identifier of the focus input to match a label defined for the component
   */
  @Input() public inputId: string;
  /**
   * Inline style of the component
   */
  @Input() public style: any;
  /**
   * Style class of the component
   */
  @Input() public styleClass: string = '';
  /**
   * Style class of the label
   */
  @Input() public labelStyleClass: string;
  /**
   * Icon class of the checkbox icon
   */
  @Input() public checkboxIcon: string = 'pi pi-check';
  /**
   * Value in checked state.
   */
  @Input() public falseValue: any = false;
  /**
   * Value in unchecked state.
   */
  @Input() public trueValue: any = true;
  /**
   * Callback to invoke on checkbox click
   */
  @Output() public change: EventEmitter<any> = new EventEmitter();

  public control: FormControl;

  public setValueTransform = (value: boolean | string) => {
    return (this.trueValue !== true || this.falseValue !== false) ? value : Boolean(+value);
  }

  constructor(private injector: Injector,
              private validatorService: ValidatorService) {
    super();
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      const ngControl: NgControl = this.injector.get(NgControl, null);
      this.control = this.validatorService.extractFormControl(ngControl);
    });
  }
}
