import { Injectable } from '@angular/core';
import { ApiService } from '@capturum/api';
import { Observable, Subject } from 'rxjs';
import { SettingIndexedDbModel } from '../models/setting.indexedDb-model';
import { SettingApiModel } from '../models/setting.api-model';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class SettingService extends ApiService<SettingApiModel> {
  public settingsChanged$: Subject<void> = new Subject();
  protected endpoint = 'setting';
  protected settingResolverEndpoint = '/role/setting';

  /**
   * Retrieve the settings and store them in de local database
   */
  public async loadAll(): Promise<any> {
    const settings = await this.apiHttp
      .get(this.settingResolverEndpoint)
      .pipe(
        map((response: any) => response.data),
      )
      .toPromise();

    if (settings) {
      await SettingIndexedDbModel.query().clear();

      const settingRecords = [];
      for (const key in settings) {
        if (settings.hasOwnProperty(key)) {
          settingRecords.push({ id: key, value: settings[key] });
        }
      }

      await SettingIndexedDbModel.query().bulkAdd(settingRecords);
      await SettingIndexedDbModel.loadSettings();
      this.setGlobalStyles();
      this.settingsChanged$.next(null);
    }

    return Promise.resolve(settings);
  }

  /**
   * Set global styles
   */
  public setGlobalStyles(): void {
    const colorSettings = SettingIndexedDbModel.settings.filter(setting => (setting.id as string).includes('color'));

    for (const setting of colorSettings) {
      let key = setting.id as string;

      if (key.includes('general.tenant_')) {
        key = key.split('general.tenant_')[1];
      }

      document.documentElement.style.setProperty(`--cap-${key.replace(/([a-z])([A-Z])/g, '$1-$2')
        .replace(/[\s_]+/g, '-')
        .toLowerCase()}`, setting.value as string);

      document.documentElement.style.setProperty(`--${key.replace(/([a-z])([A-Z])/g, '$1-$2')
        .replace(/[\s_]+/g, '-')
        .toLowerCase()}`, setting.value as string);
    }
  }

  /**
   * Set a setting for a tenant
   */
  public set(settingId: string, data: { value: any; tenant_id?: string }): Observable<any> {
    if (typeof data.value !== 'string') {
      data.value = JSON.stringify(data.value);
    }

    this.settingsChanged$.next();

    return this.apiHttp.post(`/setting/set/${settingId}`, data).pipe(
      tap(response => {
        SettingIndexedDbModel.query().put({ id: response.data.key, value: response.data.value });
        SettingIndexedDbModel.loadSettings().then(() => {
          this.settingsChanged$.next();
          this.setGlobalStyles();
        });
      }),
    );
  }

  /**
   * Get a setting value
   *
   * @param setting: string
   * @return any
   */
  public getValue(setting: string): any {
    return SettingIndexedDbModel.getBooleanValueByName(setting);
  }

  /**
   * Get a setting value
   *
   * @param setting: string
   * @return any
   */
  public getValueString(setting: string): any {
    return SettingIndexedDbModel.getValue(setting);
  }
}
