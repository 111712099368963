import { Injectable } from '@angular/core';
import { HttpParams, HttpUrlEncodingCodec } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class CapturumBuildersContextService {
  protected context: Record<string, any> = {};

  public setContext(key: string, context: Record<string, any>): void {
    this.context[key] = context;
  }

  public getContextByKey(key: string): Record<string, any> {
    return this.context[key];
  }

  public clearContextByKey(key: string): void {
    this.context[key] = null;
  }

  public getHttpParamsContext(key: string): HttpParams {
    const context = this.context[key];
    if (context) {
      let httpParams = new HttpParams({ encoder: new MyCustomHttpUrlEncodingCodec() });

      for (const objectKey in context) {
        if (context.hasOwnProperty(objectKey)) {
          httpParams = httpParams.append(`_context[${objectKey}]`, context[objectKey]);
        }
      }

      return httpParams;
    }

    return null;
  }
}

export class MyCustomHttpUrlEncodingCodec extends HttpUrlEncodingCodec {
  public encodeKey(k: string): string {
    return super.encodeKey(k)
      .replace(new RegExp('%5B', 'g'), '[')
      .replace(new RegExp('%5D', 'g'), ']');
  }
}
