import { BuilderAction } from './builder-action.interface';
import { DependencyOperator } from '../enums/dependency-operator.enum';
import { ListRendererFilterConfig } from './list-renderer-filter-config.interface';
import { ToastMessageTypes } from '@capturum/ui/api';

export interface FormBuilderConfig {
  key?: string;
  type?: string;
  template?: string;
  permissions: string[];
  identifier_field?: string;
  source?: FormBuilderSource;
  submit?: FormBuilderSource;
  callbacks?: Record<string, BuilderAction>;
  groups: InputGroup[];
  label?: string;
  messages?: BuilderMessage[],
  actions?: {
    [key: string]: {
      key: string;
      options?: any;
    }
  };
  filters?: ListRendererFilterConfig;
  comment?: string;
}

export interface FormBuilderSource {
  endpoint: string;
  options: any;
}

export interface InputGroup {
  inputs: InputConfiguration[];
  location: string;
}

export interface InputConfiguration {
  readonly?: boolean;
  default?: string;
  name?: string;
  type?: string;
  label?: string;
  tooltip?: string;
  placeholder?: string;
  validations?: string[] | object;
  dependencies?: string[];
  dependency_operator?: DependencyOperator;
  sets?: string;
  options?: FormBuilderSource | any;
  trigger?: InputConfigurationTrigger;

  [key: string]: any;
}

export interface InputConfigurationTrigger {
  action: {
    endpoint: string;
  }
}

export interface BuilderMessage {
  message: string;
  type: ToastMessageTypes;
}
