<div class="preview-image mb-3" *ngIf="formControl.value">
  <img [src]="formControl.value" [class]="to.previewImageClass" alt="preview" />
</div>

<div class="d-flex">
  <label *ngIf="!to.readOnly" for="file" [class]="to.buttonClass || 'btn btn-primary'">
    {{ ((to.translatePlaceholder || to.placeholder) | observablePipe | async) || 'Upload' }} <i *ngIf="to.showIcon" [class]="to.iconClass || 'fa fa-upload'"></i>
  </label>

  <label *ngIf="!to.readOnly" class="btn btn-danger ml-2" (click)="deleteImage()">
    <i class="fa fa-trash"></i>
  </label>
</div>

<input  id="file"
        type="file"
        accept="image/*"
        class="hidden d-none"
        (change)="readURL($event)"
        [required]="to.required" />

<input type="hidden"
       [formControl]="formControl"
       [formlyAttributes]="field" />

