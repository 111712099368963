export enum TimeUnits {
  hour = 'hours',
  minute = 'minutes'
}

export enum CalendarDataType {
  date = 'date',
  string = 'string',
}

export enum CalendarSelectionMode {
  single = 'single',
  multiple = 'multiple',
  range = 'range',
}

export enum CalendarViewMode {
  date = 'date',
  month ='month',
}
