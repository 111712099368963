import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FilePreviewListItem, FormattedFilePreviewListItem } from './models/file-preview-list-item.model';

@Component({
  selector: 'cap-file-preview-list',
  templateUrl: './file-preview-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilePreviewListComponent {
  @Input()
  public showDelete: boolean;
  @Input()
  public showDownload: boolean;
  @Output()
  public onDeleteClick = new EventEmitter<{ id: string, name: string; url: string; index: number }>();
  @Output()
  public onDownloadClick = new EventEmitter<FilePreviewListItem>();
  @Output()
  public itemClick = new EventEmitter<{ file: FilePreviewListItem; index: number }>();
  public formattedFileList: FormattedFilePreviewListItem[];
  private fileIconMap: { fileTypes: string[]; fileIcon: string }[] = [
    {
      fileTypes: ['docx', 'doc', 'docm'],
      fileIcon: 'fas fa-file-word',
    },
    {
      fileTypes: ['pdf'],
      fileIcon: 'fas fa-file-pdf',
    },
    {
      fileTypes: ['xlsx', 'xlsm', 'xlsb', 'xltx', 'xls'],
      fileIcon: 'fas fa-file-excel',
    },
    {
      fileTypes: ['csv'],
      fileIcon: 'fas fa-file-csv',
    },
    {
      fileTypes: ['png', 'jpg', 'jpeg', 'gif'],
      fileIcon: 'fas fa-file-image',
    },
  ];
  private imageFileTypes = ['png', 'jpg', 'jpeg', 'gif'];

  private _files: FilePreviewListItem[];

  get files(): FilePreviewListItem[] {
    return this._files;
  }

  @Input()
  set files(value: FilePreviewListItem[]) {
    this._files = value;

    if (value) {
      this.formattedFileList = this.getFormattedFileList(value);
    }
  }

  public getFormattedFileList(files: FilePreviewListItem[]): FormattedFilePreviewListItem[] {
    return files.map((file) => {
      const splitFileName = file.name.split('.');
      const extension = splitFileName[splitFileName.length - 1];

      // check if it is an image
      if (file.url && this.imageFileTypes.includes(extension)) {
        return {
          file: file.url,
          isImage: true,
          name: file.name,
          uploading: file.uploading,
          uploadProgress: file.uploadProgress,
          id: file.id,
        };
      } else {
        const icon = this.fileIconMap.find((fileIconMap) => {
          return fileIconMap.fileTypes.includes(extension);
        });

        return {
          file: file.url,
          isImage: false,
          icon: icon ? icon.fileIcon : 'fas fa-file-alt',
          name: file.name,
          uploading: file.uploading,
          uploadProgress: file.uploadProgress,
          id: file.id,
        };
      }
    });
  }

  public trackByIndex(index): number {
    return index;
  }

  public handleItemClick(file: FilePreviewListItem, index: number): void {
    this.itemClick.emit({ file, index });
  }

  public handleDeleteButtonClick(event: PointerEvent, file: FormattedFilePreviewListItem, index: number): void {
    event.stopPropagation();

    this.onDeleteClick.emit({ id: file.id, index: index, name: file.name, url: file.file });
  }

  public handleDownloadButtonClick(event: PointerEvent, file: FormattedFilePreviewListItem): void {
    event.stopPropagation();

    this.onDownloadClick.emit(file);
  }
}
