import { InputType } from '@capturum/builders/core';

import { CapturumBuilderCheckboxInputComponent } from '../components/checkbox-input/checkbox-input.component';
import {
  CapturumBuilderCheckboxListInputComponent,
} from '../components/checkbox-list-input/checkbox-list-input.component';
import {
  CapturumBuilderCheckboxValueInputComponent,
} from '../components/checkbox-value-input/checkbox-value-input.component';
import { CapturumBuilderDateInputComponent } from '../components/date-input/date-input.component';
import { CapturumBuilderDropdownInputComponent } from '../components/dropdown-input/dropdown-input.component';
import { CapturumBuilderEmailInputComponent } from '../components/email-input/email-input.component';
import { CapturumBuilderEntityTextInputComponent } from '../components/entity-text-input/entity-text-input.component';
import { CapturumBuilderFileInputComponent } from '../components/file-input/file-input.component';
import { CapturumBuilderGroupInputComponent } from '../components/group-input/group-input.component';
import { CapturumBuilderImageInputComponent } from '../components/image-input/image-input.component';
import { CapturumBuilderLabelInputComponent } from '../components/label-input/label-input.component';
import { CapturumMultiSelectInputComponent } from '../components/multi-select-input/multi-select-input.component';
import {
  CapturumBuilderNumberFormatInputComponent,
} from '../components/number-format-input/number-format-input.component';
import { CapturumBuilderRadioInputComponent } from '../components/radio-input/radio-input.component';
import { CapturumRepeatInputTypeComponent } from '../components/repeat/repeat-input-type.component';
import { CapturumBuilderTextInputComponent } from '../components/text-input/text-input.component';
import { CapturumBuilderTextareaInputComponent } from '../components/textarea-input/textarea-input.component';
import { CapturumBuilderToggleInputComponent } from '../components/toggle-input/toggle-input.component';
import { CapturumBuilderUrlInputComponent } from '../components/url-input/url-input.component';
import { FormFieldsManipulatorUtil } from '../utils/form-fields-manipulator.util';
import { CapturumBuilderHiddenTypeComponent } from './../components/hidden-input/hidden-input.component';
import { CapturumBuilderPasswordTypeComponent } from './../components/password-type/password-type.component';
import { CapturumBuilderTranslatedInputComponent } from './../components/translated-input/translated-input.component';
import { CapturumBuilderIconInputComponent } from '../components/icon-input/icon-input.component';
import { CapturumBuilderEditorInputComponent } from '../components/editor-input/editor-input.component';

export const inputTypeComponents = [
  {
    name: FormFieldsManipulatorUtil.getFormlyTypeName(InputType.checkbox),
    component: CapturumBuilderCheckboxInputComponent,
    wrappers: ['checkbox-wrapper'],
  },
  {
    name: FormFieldsManipulatorUtil.getFormlyTypeName(InputType.checkboxList),
    component: CapturumBuilderCheckboxListInputComponent,
  },
  {
    name: FormFieldsManipulatorUtil.getFormlyTypeName(InputType.text),
    component: CapturumBuilderTextInputComponent,
  },
  {
    name: FormFieldsManipulatorUtil.getFormlyTypeName(InputType.textarea),
    component: CapturumBuilderTextareaInputComponent,
  },
  {
    name: FormFieldsManipulatorUtil.getFormlyTypeName(InputType.label),
    component: CapturumBuilderLabelInputComponent,
    wrappers: ['content'],
  },
  { name: FormFieldsManipulatorUtil.getFormlyTypeName(InputType.email), component: CapturumBuilderEmailInputComponent },
  {
    name: FormFieldsManipulatorUtil.getFormlyTypeName(InputType.dropdown),
    component: CapturumBuilderDropdownInputComponent,
  },
  { name: FormFieldsManipulatorUtil.getFormlyTypeName(InputType.image), component: CapturumBuilderImageInputComponent },
  {
    name: FormFieldsManipulatorUtil.getFormlyTypeName(InputType.number),
    component: CapturumBuilderNumberFormatInputComponent,
  },
  { name: FormFieldsManipulatorUtil.getFormlyTypeName(InputType.date), component: CapturumBuilderDateInputComponent },
  { name: FormFieldsManipulatorUtil.getFormlyTypeName(InputType.group), component: CapturumBuilderGroupInputComponent },
  {
    name: FormFieldsManipulatorUtil.getFormlyTypeName(InputType.multiSelect),
    component: CapturumMultiSelectInputComponent,
  },
  { name: FormFieldsManipulatorUtil.getFormlyTypeName(InputType.radio), component: CapturumBuilderRadioInputComponent },
  {
    name: FormFieldsManipulatorUtil.getFormlyTypeName(InputType.toggle),
    component: CapturumBuilderToggleInputComponent,
  },
  { name: FormFieldsManipulatorUtil.getFormlyTypeName(InputType.repeat), component: CapturumRepeatInputTypeComponent },
  { name: FormFieldsManipulatorUtil.getFormlyTypeName(InputType.file), component: CapturumBuilderFileInputComponent },
  {
    name: FormFieldsManipulatorUtil.getFormlyTypeName(InputType.translated),
    component: CapturumBuilderTranslatedInputComponent,
  },
  {
    name: FormFieldsManipulatorUtil.getFormlyTypeName(InputType.entityText),
    component: CapturumBuilderEntityTextInputComponent,
  },
  {
    name: FormFieldsManipulatorUtil.getFormlyTypeName(InputType.editor),
    component: CapturumBuilderEditorInputComponent,
  },
  {
    name: FormFieldsManipulatorUtil.getFormlyTypeName(InputType.checkboxValue),
    component: CapturumBuilderCheckboxValueInputComponent,
    wrappers: ['checkbox-wrapper'],
  },
  {
    name: FormFieldsManipulatorUtil.getFormlyTypeName(InputType.hidden),
    component: CapturumBuilderHiddenTypeComponent,
    wrappers: ['hidden'],
  },
  { name: FormFieldsManipulatorUtil.getFormlyTypeName(InputType.url), component: CapturumBuilderUrlInputComponent },
  {
    name: FormFieldsManipulatorUtil.getFormlyTypeName(InputType.password),
    component: CapturumBuilderPasswordTypeComponent,
  },
  {
    name: FormFieldsManipulatorUtil.getFormlyTypeName(InputType.icon),
    component: CapturumBuilderIconInputComponent,
  },
];
