import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { PublicTranslationService } from '../services/public-translation.service';

@Injectable()
export class PublicTranslationResolver  {
  constructor(
    protected publicTranslationService: PublicTranslationService
  ) {
  }

  /**
   * Make sure the translations are loaded before accessing the route
   */
  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.publicTranslationService.loadTranslations();
  }
}
