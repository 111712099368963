export enum PanningStates {
  start = 'start',
  move = 'move',
  end = 'end',
  cancel = 'cancel',
  left = 'left',
  right = 'right',
  up = 'up',
  down = 'down'
}
