import { Injectable } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { format, isDate } from 'date-fns';

@Injectable()
export class UtilService {

  constructor(private translateService: TranslateService) {
  }

  /**
   * Map array to SelectItem array
   *
   * @param array any[]
   * @param value string
   * @param label string
   * @param translate boolean
   * @param translateProperty string
   * @param translationKeyPrefix string
   * @return array
   */
  public mapToSelectItem(array: any[],
                         value: string,
                         label: string,
                         translate: boolean = false,
                         translateProperty: string = 'key',
                         translationKeyPrefix: string = ''): SelectItem[] {


    return array.map((item) => {
      let selectItemLabel = item[label];
      if (translate) {
        selectItemLabel = this.translateService.instant(`${translationKeyPrefix}.${item[translateProperty]}`);
      }

      return { value: item[value], label: selectItemLabel };
    });
  }

  /**
   * Set date in expected format for the sync
   *
   * @param value string|Date
   * @return string
   */
  public toDateTime(value: string | Date): string {
    const date: Date = isDate(value) ? value as Date : new Date(value);

    return format(date, 'yyyy-MM-dd HH:mm:ss');
  }

  /**
   * Set date in expected format for (grid) presentation
   *
   * @param value string|Date
   * @return string
   */
  public toDateString(value: string | Date): string {
    const date: Date = isDate(value) ? value as Date : new Date(value);

    return format(date, 'dd-MM-yyyy');
  }

  /**
   * Convert an (associative) array to object array
   *
   * @param inputArray any
   * @return any
   */
  public convertArrayToObject(inputArray: any): any {
    let objectArray = {};

    if (inputArray && typeof inputArray === 'object') {
      for (const inputArrayItem of Object.keys(inputArray)) {
        objectArray[inputArrayItem] = this.convertArrayToObject(inputArray[inputArrayItem]);
      }
    } else {
      objectArray = inputArray;
    }

    return objectArray;
  }
}
