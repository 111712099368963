<div class="widget-card widget" cdkDrag [cdkDragData]="widget" [id]="widget?.id">
  <div class="widget-card-header">
    <h5 class="header">
      <i class="fas fa-grip-vertical drag" cdkDragHandle></i>
      <span>{{ widget?.title }}</span>
    </h5>
    <div class="actions">
      <i class="fas fa-cog"
         *ngIf="widgetTypeIncludes.includes(widget?.type)"
         (click)="openWidgetConfiguration(widget)"></i>
      <i class="fas fa-pencil edit" (click)="handleEditWidget({ widget, index })"></i>
      <i class="fas fa-trash-alt delete" (click)="handleWidgetRemove(widget)"></i>
    </div>
  </div>

  <div class="widget-card-content">
    <div cdkDropList cpbDragDropManager
         [id]="widget?.id"
         [ngClass]="widget?.options.css_class"
         [class.g-0]="widget?.type === widgetType.row"
         (cdkDropListDropped)="handleWidgetDrop($event)"
         [cdkDropListData]="widget?.type"
         [cdkDropListEnterPredicate]="canDrop(widget)">
      <ng-container *ngIf="widget | hasWidgets">
        <cpb-page-builder-widget [widget]="subWidget" class="subwidget"
                                 *ngFor="let subWidget of widget?.widgets; let subIndex=index"
                                 [ngClass]="subWidget.options.css_class"
                                 [class.g-0]="widget.type === widgetType.row"
                                 [index]="subIndex"
                                 (widgetDrop)="handleWidgetDrop($event)"
                                 (editWidget)="handleEditWidget($event)"
                                 (removeWidget)="handleWidgetRemove($event)">
        </cpb-page-builder-widget>
      </ng-container>

      <div class="empty-row-widget-message" *ngIf="widget?.type === widgetType.row && !widget?.widgets?.length">
        <span>
          {{ 'builders.ui.form.drag-drop-message' | translate }}
        </span>
      </div>
    </div>
  </div>
</div>
