<div class="accordion-card">
    <div class="accordion-card__header">
        <ng-container *ngIf="!headerTemplate">
            <div class="ac-header__left">
                <p class="accordion-card__title" (click)="toggleCard(clickable)">
                    <i *ngIf="icon" class="ac-icon {{ icon }}"></i>
                    {{ title }}
                </p>

                <div *ngIf="actions" class="accordion-card__actions">
                    <ng-container *ngFor="let action of actions">
                        <i *ngIf="isVisibleAction(action?.visible)"
                           class="ac-action {{ action?.icon }} {{ action?.key }}"
                           (click)="action?.action?.call(this); actionClick?.emit(action?.key)"></i>
                    </ng-container>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="headerTemplate" [ngTemplateOutlet]="headerTemplate">
        </ng-container>

        <div class="ac-header__right" (click)="toggleCard(clickable)" [style.visibility]="hideToggle ? 'hidden' : 'visible'">
            <p class="accordion-card__toggler" (click)="toggleCard(!clickable)">
                <ng-container *ngIf="!hideTexts">{{ isOpen ? config?.texts?.hideText : config?.texts?.showText }}</ng-container>
                <i class="fas {{ isOpen ?  config?.openIcon || 'fa-chevron-up' : config?.closeIcon || 'fa-chevron-down' }}"></i>
            </p>
        </div>
    </div>

    <div class="accordion-card__content" [ngClass]="{'accordion-card__content--open': isOpen}">
        <div class="accordion-card__delimiter"></div>
        <ng-container [ngTemplateOutlet]="contentTemplate"></ng-container>
    </div>
</div>
