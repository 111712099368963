import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ApiService } from '@capturum/api';
import { ModuleTenant } from './module-tenant.api-model';
import { ModuleTenantIndexedDbModel } from './module-tenant.indexedDb-model';

@Injectable()
export class ModuleTenantService extends ApiService<ModuleTenant> {
  public endpoint = 'tenant/module';

  /**
   * Get the modules of the tenant and store them in the indexedDB
   *
   * @return Observable<ModuleTenant[]>
   */
  public loadModuleTenants(): Observable<ModuleTenantIndexedDbModel[]> {
    return this.apiHttp.get(`/${this.endpoint}`)
      .pipe(
        map((response: any) => response.data),
        tap(async (moduleTenants) => {
          await ModuleTenantIndexedDbModel.query().clear();
          await ModuleTenantIndexedDbModel.query().bulkAdd(moduleTenants);
          await ModuleTenantIndexedDbModel.loadModuleTenants();
        }),
        catchError(async () => {
          await ModuleTenantIndexedDbModel.loadModuleTenants();
        })
      );
  }
}
