<div class="form-renderer-header">
    <span class="form-renderer-title">
      {{ title }}
    </span>

  <div class="form-renderer-actions">
    <ng-container [ngTemplateOutlet]="leftButtonTemplate">
    </ng-container>

    <ng-container *ngIf="callbacks">
      <cpb-form-submit-button *ngIf="callbacks?.submit" [key]="formKey" [actionConfiguration]="callbacks.submit"></cpb-form-submit-button>
      <cpb-form-cancel-button *ngIf="callbacks?.cancel" [key]="formKey" [actionConfiguration]="callbacks.cancel"></cpb-form-cancel-button>
    </ng-container>

    <ng-container [ngTemplateOutlet]="rightButtonTemplate">
    </ng-container>
  </div>
</div>
