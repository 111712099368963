import Dexie from 'dexie';
import { Subject } from 'rxjs';
import { CompleteConfig } from '../../complete-config';
import { BaseDataIndexedDbModel, BaseDataKeyIndexedDbModel } from '../../domain/base-data';
import { BaseDataValueIndexedDbModel } from '../../domain/base-data/models/base-data-value.indexedDb-model';
import { ModuleIndexedDbModel } from '../../domain/module';
import { ModuleTenantIndexedDbModel } from '../../domain/module-tenant';
import { PermissionIndexedDbModel } from '../../domain/permission';
import { SettingIndexedDbModel } from '../../domain/setting/models/setting.indexedDb-model';
import { TenantIndexedDbModel } from '../../domain/tenant';
import { TranslationIndexedDbModel } from '../../domain/translation';
import { UserIndexedDbModel } from '../../domain/user/user.indexedDb-model';
import { FileIndexedDbModel } from './file.indexeddb.model';
import { IndexedDbModel } from './indexedDb.model';

export class Store extends Dexie {
  public static onReady: Subject<boolean> = new Subject<boolean>();

  /**
   * Return singleton instance
   */
  public static getStore(name: string, id?: string): Store {
    if (Store._store === null || (Store._store && id && !Store._store.name.includes(id))) {
      if (Store._store) {
        if (Store._store.isOpen()) {
          // close if open
          Store._store.close();
        } else {
          // delete if not open
          Store._store.delete();
        }
      }

      if (id) {
        name = `${name}-${id}`;
      }

      Store._store = new Store(name);
      Store._isReady = false;
    }

    return Store._store;
  }

  private static _store: Store = null;
  private static _isReady: boolean = false;
  private static _isLoading: boolean = false;
  public entities: Dexie.Table<IndexedDbModel, number>[];

  /**
   * Create indexedDB tables
   */
  constructor(name: string) {
    super(name, { autoOpen: true });
  }

  /**
   * Set up the indexedDB based on the given config
   *
   * @param config - configuration of module
   * @param id - unique id for the db name
   */
  public async setup(config: CompleteConfig, id: string): Promise<boolean> {
    if (Store._isReady) {
      return true;
    }

    if (Store._isLoading) {
      return await Store.onReady.toPromise();
    }

    Store._isLoading = true;

    let databaseName = config.databaseName;

    if (id) {
      databaseName += '-' + id;
    }

    if (await Dexie.exists(databaseName)) {
      await this.open();

      if (this.verno < config.version) {
        // Delete current outdated database if applicable
        await this.delete();
        await this.version(1).stores({});
      } else {
        await this.close();
      }
    }

    // Generate schema object
    const schema: { [key: string]: string } = {};
    const customIndexedDbModels = config.indexedDbModels || [];
    const models = [
      PermissionIndexedDbModel,
      SettingIndexedDbModel,
      TenantIndexedDbModel,
      ModuleTenantIndexedDbModel,
      ModuleIndexedDbModel,
      TranslationIndexedDbModel,
      BaseDataIndexedDbModel,
      BaseDataValueIndexedDbModel,
      BaseDataValueIndexedDbModel,
      BaseDataKeyIndexedDbModel,
      UserIndexedDbModel,
      FileIndexedDbModel,
      ...customIndexedDbModels,
    ];

    // Add each model
    models.forEach(model => {
      const m = new model();
      schema[m.table] = m.schema;
    });

    // Create schema
    await this.version(config.version).stores(schema);
    await this.open();

    IndexedDbModel.store = this;

    // Map each table to class model
    models.forEach(model => {
      const m = new model();
      this[m.table].mapToClass(model);
    });

    Store._isReady = true;
    Store._isLoading = false;
    Store.onReady.next(true);
    Store.onReady.complete();

    return true;
  }
}
