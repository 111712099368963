import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CapturumSharedModule } from '@capturum/ui/api';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CapturumInputCodeComponent } from './input-code.component';
import { CapturumInputModule } from '@capturum/ui/input';
import { KeyFilterModule } from 'primeng/keyfilter';

@NgModule({
  declarations: [
    CapturumInputCodeComponent,
  ],
  exports: [
    CapturumInputCodeComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    CapturumSharedModule,
    CapturumInputModule,
    ReactiveFormsModule,
    KeyFilterModule,
  ],
})
export class CapturumInputCodeModule {
}
